import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const studentServices = {};

studentServices.getAllStudents = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/students',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

studentServices.getStudents = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/students', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

studentServices.getStudentDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/students/student/${id}`, {
        id: id
    });
    // console.log('service');
    // console.log(data);
    // console.log(data.student.first_name);
    // console.log(JSON.parse(data.student));
    // console.log(data.student.first_name);

    return data;
};

studentServices.addStudent = async (student) => {
    const { data } = await jwtAuthAxios.post(`/students`, student);
    return data;
};

studentServices.addEventStudent = async (student) => {
    const { data } = await jwtAuthAxios.post(
        `/students/online-registration`,
        student
    );
    return data;
};

studentServices.uploadStudentsCSV = async (csvData) => {
    const { data } = await jwtAuthAxios.post(`/students/upload-csv`, csvData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return data;
};

studentServices.updateStudent = async (student) => {
    const { data } = await jwtAuthAxios.patch(
        `/students/${student.id}`,
        student
    );
    return data;
};

studentServices.updatePreferredCourses = async (student) => {
    const { data } = await jwtAuthAxios.patch(
        `/students/preferred-courses/${student.id}`,
        student
    );
    return data;
};

studentServices.deleteStudent = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/students/${id}`, {
        id: id
    });
    return data;
};

studentServices.deleteMultipleStudents = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/students`, {
        data: ids
    });
    return data;
};

export { studentServices };
