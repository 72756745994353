import React from 'react';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import ArticleIcon from '@mui/icons-material/Article';
import { useMutation } from 'react-query';
import { coursesServices } from 'app/services/courses-services';
import { institutionsServices } from 'app/services/institutions-services';
import { MuiFileInput } from 'mui-file-input';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import axios from 'axios';
import { capitalizeAllWords } from 'app/utils/appHelpers';
import JumboFileInput from '@jumbo/components/JumboFormik/JumboFileInput';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const validationSchema = yup.object({
    institutionId: yup
        .string('Select an institution')
        .required('Related Institution is required')
});

const TEMPLATE_LINK = `${process.env.REACT_APP_BACKEND}/uploads/templates/csv/courses_csv_template.csv`;

const UploadCSVForm = ({
    item: courseItem,
    onSave,
    hideDialog,
    userDetails
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const [institutionId, setInstitutionId] = React.useState('');
    const [institutions, setInstitutions] = React.useState(false);
    const [file, setFile] = React.useState('');
    const [array, setArray] = React.useState([]);
    const [checked, setChecked] = React.useState(false);

    const fileReader = new FileReader();

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    const { mutate: uploadCoursesCSVMutation, isLoading } = useMutation(
        coursesServices.uploadCoursesCSV,
        {
            onSuccess: (res) => {
                console.log(res);
                onSave();
                enqueueSnackbar(res.message, {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (data) => {
        if (!file) {
            enqueueSnackbar('Please upload a csv file.', {
                variant: 'error'
            });
            return;
        }

        // console.log('data');
        // console.log(data);
        // return;

        try {
            const formData = new FormData();
            formData.append('institutionId', data?.institutionId);
            // formData.append('schoolId', data.schoolId);
            formData.append('countryId', data?.countryId);
            formData.append('courses_csv', file);
            uploadCoursesCSVMutation(formData);
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelTokenInstitutions = axios.CancelToken.source();
        const cTokenInstitutions = {
            cancelToken: cancelTokenInstitutions.token
        };
        const fetchInstitutions = async () => {
            try {
                const res = await institutionsServices.getAllInstitutions(
                    cTokenInstitutions
                );
                let finalInstitutions = res.institutions;

                if (
                    userDetails?.institutionIds &&
                    userDetails?.role_name !== 'administrator'
                ) {
                    finalInstitutions = _.filter(
                        res.institutions,
                        (institution) =>
                            _.includes(
                                userDetails.institutionIds,
                                institution.id
                            )
                    );
                }

                setInstitutions(finalInstitutions);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchInstitutions();

        return () => {
            cancelTokenInstitutions.cancel();
        };
    }, []);

    React.useEffect(() => {
        const csvFileToArray = (string) => {
            const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
            const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

            const array = csvRows.map((i) => {
                const values = i.split(',');
                const obj = csvHeader.reduce((object, header, index) => {
                    object[header] = values[index];
                    return object;
                }, {});
                return obj;
            });

            setArray(array);
        };

        if (file) {
            fileReader.onload = function (event) {
                const text = event.target.result;
                csvFileToArray(text);
            };

            fileReader.readAsText(file);
        }
    }, [file]);

    const headerKeys = Object.keys(Object.assign({}, ...array));

    React.useEffect(() => {
        const cancelInstToken = axios.CancelToken.source();
        const cInstToken = { cancelToken: cancelInstToken.token };
        const fetchInstitutions = async () => {
            try {
                const res = await institutionsServices.getAllInstitutions(
                    cInstToken
                );

                setInstitutions(res.institutions);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchInstitutions();

        return () => {
            cancelInstToken.cancel();
        };
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            <Div sx={{ flexGrow: 1 }}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                        {!institutions && (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                width={'100%'}
                            >
                                <CircularProgress color="inherit" />
                            </Box>
                        )}

                        {institutions && (
                            <Formik
                                validateOnChange={true}
                                initialValues={{
                                    institutionId: '',
                                    countryId: '',
                                    lead: '',
                                    csv: ''
                                }}
                                validationSchema={validationSchema}
                                onSubmit={async (data, { setSubmitting }) => {
                                    await onCreate(data);
                                }}
                            >
                                {({
                                    isSubmitting,
                                    errors,
                                    touched,
                                    values,
                                    setFieldValue,
                                    setErrors,
                                    ...restProps
                                }) => (
                                    <Form
                                        style={{ textAlign: 'left' }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <Div sx={{ flexGrow: 1 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={12}>
                                                    <Field name="institutionId">
                                                        {({ field }) => (
                                                            <Autocomplete
                                                                {...field}
                                                                // id="institutionId"
                                                                sx={{
                                                                    width: '100%',
                                                                    maxWidth:
                                                                        '100%'
                                                                }}
                                                                size="small"
                                                                options={
                                                                    institutions
                                                                }
                                                                autoHighlight
                                                                getOptionLabel={(
                                                                    option
                                                                ) =>
                                                                    option?.institution_name
                                                                        ? option.institution_name
                                                                        : ''
                                                                }
                                                                isOptionEqualToValue={(
                                                                    option,
                                                                    value
                                                                ) =>
                                                                    option.id ===
                                                                    value.id
                                                                }
                                                                renderOption={(
                                                                    props,
                                                                    option
                                                                ) => (
                                                                    <Box
                                                                        component="li"
                                                                        sx={{
                                                                            '& > img':
                                                                                {
                                                                                    mr: 2,
                                                                                    flexShrink: 0
                                                                                }
                                                                        }}
                                                                        {...props}
                                                                    >
                                                                        {
                                                                            option.institution_name
                                                                        }
                                                                    </Box>
                                                                )}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Institution"
                                                                        placeholder="Choose a institution"
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            autoComplete:
                                                                                'new-password'
                                                                        }}
                                                                        error={
                                                                            touched?.institutionId &&
                                                                            !!errors.institutionId
                                                                        }
                                                                        helperText={
                                                                            touched?.institutionId &&
                                                                            errors.institutionId
                                                                        }
                                                                    />
                                                                )}
                                                                onChange={
                                                                    (
                                                                        event,
                                                                        newValue
                                                                    ) => {
                                                                        values.institutionId =
                                                                            newValue?.id;
                                                                        values.institution_name =
                                                                            newValue?.institution_name;
                                                                        values.countryId =
                                                                            newValue?.countryId?.id;
                                                                    }
                                                                    // Set the new value in the form field
                                                                }
                                                                value={
                                                                    values?.institutionId
                                                                        ? institutions.find(
                                                                              (
                                                                                  option
                                                                              ) =>
                                                                                  option.id ===
                                                                                  values?.institutionId
                                                                          ) ||
                                                                          null
                                                                        : null
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <Typography
                                                        variant={'h6'}
                                                        color={
                                                            'text.   primary'
                                                        }
                                                        mb={0.5}
                                                    >
                                                        Select CSV File
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        size={'small'}
                                                        id="csv"
                                                        name="csv"
                                                        type="file"
                                                        accept=".csv"
                                                        placeholder="Upload CSV file"
                                                        onChange={
                                                            handleOnChange
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                sx={{
                                                    margin: '30px auto'
                                                }}
                                                container
                                                alignItems="right"
                                                justify="flex-end"
                                                justifyContent="right"
                                            >
                                                <Button
                                                    size="large"
                                                    sx={{ mb: 3 }}
                                                    onClick={() => hideDialog()}
                                                >
                                                    Cancel
                                                </Button>
                                                <LoadingButton
                                                    type="submit"
                                                    variant="contained"
                                                    size="large"
                                                    sx={{ mb: 3 }}
                                                    loading={isLoading}
                                                >
                                                    Upload
                                                </LoadingButton>
                                            </Grid>
                                        </Div>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Alert variant="filled" severity="info">
                            <Typography
                                variant={'h2'}
                                color={'inherit'}
                                mb={0.5}
                            >
                                Instructions
                            </Typography>
                            <Div>
                                <Typography variant={'body1'}>
                                    {' '}
                                    1. Download the attached template
                                </Typography>
                                <Typography variant={'body1'}>
                                    2. Fill in the rows with data accordingly.
                                </Typography>
                                {/* <Div>
                                    <List
                                        sx={{
                                            height: '150px',
                                            overflowY: 'scroll',
                                            background: '#aaa',
                                            borderRadius: '5px'
                                        }}
                                    >
                                        <ListItem
                                            sx={{
                                                paddingY: '2px',
                                                my: '2px'
                                            }}
                                        >
                                            <ListItemText
                                                sx={{
                                                    paddingY: '5px',
                                                    my: '0px'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        paddingY: '2px',
                                                        my: '2px',
                                                        background: '#101010'
                                                    }}
                                                >
                                                    Certificate
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                paddingY: '2px',
                                                my: '0px'
                                            }}
                                        >
                                            <ListItemText
                                                sx={{
                                                    paddingY: '0px',
                                                    my: '0px'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        paddingY: '2px',
                                                        my: '2px',
                                                        background: '#101010'
                                                    }}
                                                >
                                                    Diploma
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                paddingY: '2px',
                                                my: '0px'
                                            }}
                                        >
                                            <ListItemText
                                                sx={{
                                                    paddingY: '5px',
                                                    my: '0px',
                                                    my: '0px'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        paddingY: '2px',
                                                        my: '2px',
                                                        background: '#101010'
                                                    }}
                                                >
                                                    Applied Degree
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                paddingY: '2px',
                                                my: '0px'
                                            }}
                                        >
                                            <ListItemText
                                                sx={{
                                                    paddingY: '5px',
                                                    my: '0px'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        paddingY: '2px',
                                                        my: '2px',
                                                        background: '#101010'
                                                    }}
                                                >
                                                    Masters
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                paddingY: '2px',
                                                my: '0px'
                                            }}
                                        >
                                            <ListItemText
                                                sx={{
                                                    paddingY: '5px',
                                                    my: '0px'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        paddingY: '2px',
                                                        my: '2px',
                                                        background: '#101010'
                                                    }}
                                                >
                                                    Non-Credit
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                paddingY: '2px',
                                                my: '0px'
                                            }}
                                        >
                                            <ListItemText
                                                sx={{
                                                    paddingY: '5px',
                                                    my: '0px'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        paddingY: '2px',
                                                        my: '2px',
                                                        background: '#101010'
                                                    }}
                                                >
                                                    Phd
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                paddingY: '2px',
                                                my: '0px'
                                            }}
                                        >
                                            <ListItemText
                                                sx={{
                                                    paddingY: '5px',
                                                    my: '0px'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        paddingY: '2px',
                                                        my: '2px',
                                                        background: '#101010'
                                                    }}
                                                >
                                                    Phd or Post-Diploma
                                                    Certificate & Certificate
                                                    bundle
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Div> */}
                                <Typography variant={'body1'} mb={0.5}>
                                    3. Upload the filled CSV file on the form on
                                    the left
                                </Typography>
                                <Typography variant={'body1'}>
                                    NOTE: DO NOT modify/insert/delete the
                                    columns
                                </Typography>
                            </Div>

                            <Button
                                component={Link}
                                target="_blank"
                                rel="noreferrer"
                                to={TEMPLATE_LINK}
                                role="button"
                                variant={'outlined'}
                                color="primary"
                                sx={{
                                    marginTop: '20px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    verticalAlign: 'bottom',
                                    cursor: 'pointer'
                                    // '&:hover': {
                                    //     color: 'white'
                                    // }
                                }}
                            >
                                <ArticleIcon />
                                Download Courses CSV Template
                            </Button>
                        </Alert>
                    </Grid>
                </Grid>
            </Div>
        </Div>
    );
};
/* Todo courseItem, onSave prop define */
export default UploadCSVForm;
