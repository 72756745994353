import React from 'react';
import { Alert, CircularProgress, Grid } from '@mui/material';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import DealsAnalyticsGraph from './DealsAnalyticsGraph';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import List from '@mui/material/List';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { dashboardsServices } from 'app/services/dashboards-services';
import axios from 'axios';

const ListItemInline = styled(ListItem)(({ theme }) => ({
    width: 'auto',
    display: 'inline-flex',
    padding: theme.spacing(0, 0.5)
}));

const DealsAnalytics = () => {
    const { t } = useTranslation();
    const [allSums, setAllSums] = React.useState({
        month: 'Total',
        leads: 0,
        closed: 0
    });

    const [loadingLeadsSummary, setLoadingLeadsSummary] = React.useState(true);
    const [leadsSummary, setLeadsSummary] = React.useState(false);
    const [selectedYear, setSelectedYear] = React.useState(
        new Date().getFullYear()
    );

    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const cancelTokenAgents = axios.CancelToken.source();
        const agentToken = { cancelToken: cancelTokenAgents.token };
        const fetchLeadsSummary = async () => {
            try {
                const res = await dashboardsServices.getLeadsSummary(
                    agentToken
                );

                console.log('leads Summary Agents');
                console.log(res);

                setLeadsSummary(res.monthlyData);
            } catch (error) {
                // console.log(error);
                // enqueueSnackbar(error, {
                //     variant: 'error'
                // });
            }

            setLoadingLeadsSummary(false);
        };

        fetchLeadsSummary();

        return () => {
            cancelTokenAgents.cancel();
        };
    }, [dashboardsServices]);

    React.useEffect(() => {
        if (leadsSummary && leadsSummary.length > 0) {
            const sumAllValues = leadsSummary.reduce(
                (accumulator, currentValue) => {
                    accumulator.leads += currentValue.leads;
                    accumulator.closed += currentValue.closed;
                    return accumulator;
                },
                { month: 'Total', leads: 0, closed: 0 }
            );

            setAllSums(sumAllValues);
        }
    }, [leadsSummary]);

    // console.log('Leads Summary ----------->');
    // console.log(leadsSummary);

    return (
        <JumboCardQuick
            title={`Leads Summary ${selectedYear}`}
            subheader={
                `${allSums.closed}` + ' ' + t('widgets.title.leadsClosed')
            }
            action={
                <List disablePadding>
                    <ListItemInline>
                        <ListItemIcon sx={{ minWidth: 16 }}>
                            <FiberManualRecordIcon
                                fontSize={'10px'}
                                sx={{ color: '#1E88E5' }}
                            />
                        </ListItemIcon>
                        <ListItemText secondary="Total Leads" />
                    </ListItemInline>
                    <ListItemInline>
                        <ListItemIcon sx={{ minWidth: 16 }}>
                            <FiberManualRecordIcon
                                fontSize={'10px'}
                                sx={{ color: '#E91E63' }}
                            />
                        </ListItemIcon>
                        <ListItemText secondary="Closed Leads" />
                    </ListItemInline>
                </List>
            }
            sx={{
                '& .MuiCardHeader-action': {
                    alignSelf: 'flex-end',
                    mb: -1
                }
            }}
            wrapperSx={{ pt: 3.75 }}
        >
            {loadingLeadsSummary && (
                <Grid sx={{ width: '100%', justifyContent: 'center' }}>
                    <CircularProgress />
                </Grid>
            )}

            {!loadingLeadsSummary && leadsSummary && (
                <DealsAnalyticsGraph leadsAnalyticsData={leadsSummary} />
            )}

            {!loadingLeadsSummary && !leadsSummary && (
                <Alert severity="info">No data avaialable</Alert>
            )}
        </JumboCardQuick>
    );
};
export default DealsAnalytics;
