import jwtAxios from 'axios';

const jwtAuthAxios = jwtAxios.create({
    baseURL: process.env.REACT_APP_BACKEND_API,
    headers: {
        'Content-Type': 'application/json'
    }
});

// jwtAuthAxios.interceptors.response.use(
//     (res) => res,
//     (err) => {
//         console.log('JWTAXIOS');
//         console.log(err);
//         console.log(err?.response);
//         console.log(err?.response?.status);
//         console.log(err?.response?.data?.message);

//         if (err.response && err.response.status === '403') {
//             throw err?.response?.data?.message || 'Authentication failed!';
//             setAuthToken(null);
//         }
//         return Promise.reject(
//             err?.response?.data?.message || 'A network error occured'
//         );
//     }
// );

export const setAuthToken = (token) => {
    console.log();
    if (token) {
        jwtAuthAxios.defaults.headers.common['Authorization'] =
            'Bearer ' + token;
        sessionStorage.setItem('token', token);
    } else {
        delete jwtAuthAxios.defaults.headers.common['Authorization'];
        sessionStorage.removeItem('token');
    }
};

export const getAuthToken = () => {
    return sessionStorage.getItem('token');
};

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default jwtAuthAxios;
