import React from 'react';
import Stack from '@mui/material/Stack';
import LabelsSelectControl from '../LabelsSelectControl';
import { useMutation } from 'react-query';
import { expensesServices } from '../../../../../services/expenses-services';
import useExpensesApp from '../../hooks/useExpensesApp';
import IconButton from '@mui/material/IconButton';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { useSnackbar } from 'notistack';
import { Tooltip } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const BulkActions = () => {
    const { selectedExpenses, setExpensesListRefresh } = useExpensesApp();
    const { showDialog, hideDialog } = useJumboDialog();
    const { enqueueSnackbar } = useSnackbar();

    const assignLabelMutation = useMutation(expensesServices.assignLabel, {
        onSuccess: () => {
            hideDialog();
            setExpensesListRefresh(true);
            enqueueSnackbar('Labels has been applied successfully.', {
                variant: 'success'
            });
        }
    });
    const deleteExpensesMutation = useMutation(
        expensesServices.deleteMultiple,
        {
            onSuccess: () => {
                hideDialog();
                setExpensesListRefresh(true);
                enqueueSnackbar('Expenses has been deleted successfully.', {
                    variant: 'success'
                });
            }
        }
    );
    const handleBulkDelete = React.useCallback(() => {
        showDialog({
            variant: 'confirm',
            title: 'Are you sure?',
            onYes: () =>
                deleteExpensesMutation.mutate(
                    selectedExpenses.map((expense) => expense?.id)
                ),
            onNo: hideDialog
        });
    }, [selectedExpenses, deleteExpensesMutation, hideDialog]);

    const applyLabels = React.useCallback(
        (selectedLabels) => {
            assignLabelMutation.mutate({
                expenseIDs: selectedExpenses.map((expense) => expense?.id),
                labelIDs: selectedLabels.map((label) => label?.id)
            });
        },
        [selectedExpenses]
    );

    return (
        <Stack
            direction={'row'}
            sx={{ backgroundColor: 'transparent', ml: -2 }}
        >
            <Tooltip title={'Delete'}>
                <IconButton onClick={handleBulkDelete}>
                    <DeleteOutlineIcon />
                </IconButton>
            </Tooltip>
            <LabelsSelectControl onDone={applyLabels} />
        </Stack>
    );
};

export default BulkActions;
