import styled from '@emotion/styled';
import List from '@mui/material/List';
import {
    AvatarGroup,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import moment from 'moment';

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 24,
    height: 48,
    width: 48,
    borderRadius: '50%',
    minWidth: 42,
    marginRight: 16,
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    border: `solid 1px ${theme.palette.divider}`
}));

const DetailsTab = ({ userDetails }) => {
    return (
        <List
            disablePadding
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                margin: (theme) => theme.spacing(0, -2)
            }}
        >
            <ListItem
                sx={{
                    width: { xs: '100%', sm: '50%', xl: '33.33%' }
                }}
            >
                <StyledListItemIcon>
                    <ApartmentIcon fontSize={'inherit'} />
                </StyledListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            fontSize={'12px'}
                            variant="h6"
                            color="text.secondary"
                            mb={0.5}
                        >
                            Works at(Office)
                        </Typography>
                    }
                    secondary={
                        <Typography variant="body1" color="text.primary">
                            {'Nairobi Office'}
                        </Typography>
                    }
                />
            </ListItem>
            <ListItem
                sx={{
                    width: { xs: '100%', sm: '50%', xl: '33.33%' }
                }}
            >
                <StyledListItemIcon>
                    <CakeOutlinedIcon fontSize={'inherit'} />
                </StyledListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            fontSize={'12px'}
                            variant="h6"
                            color="text.secondary"
                            mb={0.5}
                        >
                            Birthday
                        </Typography>
                    }
                    secondary={
                        <Typography variant="body1" color="text.primary">
                            {moment(userDetails?.dob).format('MMM Do YYYY')}
                            {/* {userDetails.dob} */}
                        </Typography>
                    }
                />
            </ListItem>
            <ListItem
                sx={{
                    width: { xs: '100%', sm: '50%', xl: '33.33%' }
                }}
            >
                <StyledListItemIcon>
                    <SchoolOutlinedIcon fontSize={'inherit'} />
                </StyledListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            fontSize={'12px'}
                            variant="h6"
                            color="text.secondary"
                            mb={0.5}
                        >
                            Went to
                        </Typography>
                    }
                    secondary={
                        <Typography variant="body1" color="text.primary">
                            {userDetails.university_attended}
                        </Typography>
                    }
                />
            </ListItem>
            <ListItem
                sx={{
                    width: { xs: '100%', sm: '50%', xl: '33.33%' }
                }}
            >
                <StyledListItemIcon>
                    <CottageOutlinedIcon fontSize={'inherit'} />
                </StyledListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            fontSize={'12px'}
                            variant="h6"
                            color="text.secondary"
                            mb={0.5}
                        >
                            Lives In
                        </Typography>
                    }
                    secondary={
                        <Typography variant="body1" color="text.primary">
                            {userDetails.address}
                        </Typography>
                    }
                />
            </ListItem>
            <ListItem
                sx={{
                    width: { xs: '100%', xl: '66.67%' }
                }}
            >
                <StyledListItemIcon>
                    <GroupsOutlinedIcon fontSize={'inherit'} />
                </StyledListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            fontSize={'12px'}
                            variant="h6"
                            color="text.secondary"
                            mb={0.5}
                        >
                            {userDetails.leads.length} Leads
                        </Typography>
                    }
                    secondary={
                        <Typography component={'div'} variant={'body1'}>
                            <Stack direction={'row'} flexWrap={'wrap'} sx={{}}>
                                <AvatarGroup
                                    max={5}
                                    sx={{
                                        '.MuiAvatar-root': {
                                            height: 32,
                                            width: 32,
                                            fontSize: 13,
                                            background: (theme) =>
                                                theme.palette.grey[600]
                                        }
                                    }}
                                >
                                    {userDetails.leads.map((lead) => (
                                        <Avatar
                                            alt={lead.first_name}
                                            src={
                                                process.env.REACT_APP_BACKEND +
                                                '/' +
                                                lead.profile_pic
                                            }
                                        />
                                    ))}
                                </AvatarGroup>
                            </Stack>
                        </Typography>
                    }
                />
            </ListItem>
        </List>
    );
};

export default DetailsTab;
