import React from 'react';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CancelIcon from '@mui/icons-material/Cancel';
import CardHeader from '@mui/material/CardHeader';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
    alpha,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from '@mui/material';
import { eventsServices } from 'app/services/events-services';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Div from '@jumbo/shared/Div';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Edit from '@mui/icons-material/Edit';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import FeedbackIcon from '@mui/icons-material/Feedback';
import InfoIcon from '@mui/icons-material/Info';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import EditForm from '../EditForm';
import StudentCreateForm from '../StudentCreateForm';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';

import { emailService } from 'app/services/email-services';
import { LoadingButton } from '@mui/lab';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const Detail = ({ item, onClose, refreshEvents, userDetails }) => {
    const { hideDialog, showDialog } = useJumboDialog();
    const { enqueueSnackbar } = useSnackbar();

    const pastEvent = new Date() > new Date(item?.end);

    const handleOnSave = () => {
        hideDialog();
        setTimeout(() => {
            handleRegisterForm();
        }, 1000);
    };

    const handleUpdateForm = React.useCallback(
        (data) => {
            showDialog({
                title: 'Update Event',
                content: (
                    <EditForm
                        event={item}
                        hideDialog={hideDialog}
                        refreshEvents={refreshEvents}
                        start={item?.start_date}
                        end={item?.end_date}
                    />
                ),
                maxWidth: 'sm',
                TransitionComponent: Transition,
                disableDefaultClose: true,
                closeAction: true
            });
        },
        [showDialog]
    );

    const handleRegisterForm = React.useCallback(
        (data) => {
            showDialog({
                title: `Register Student - ${item?.eventName}`,
                content: (
                    <StudentCreateForm
                        eventDetails={item}
                        hideDialog={hideDialog}
                        refreshEvents={refreshEvents}
                        start={item?.start_date}
                        end={item?.end_date}
                        onSave={handleOnSave}
                    />
                ),
                maxWidth: 'sm',
                TransitionComponent: Transition,
                disableDefaultClose: true,
                closeAction: true
            });
        },
        [showDialog]
    );

    const { mutate: handleSurveyInvitation, isLoading } = useMutation(
        emailService.sendSurveyInvitations,
        {
            onSuccess: () => {
                hideDialog();
                refreshEvents();
                enqueueSnackbar('Invitations sent successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                console.log('Error Object');
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const { mutate: handleCancelEvent, isLoading: isCancelling } = useMutation(
        eventsServices.cancelEvent,
        {
            onSuccess: () => {
                hideDialog();
                refreshEvents();
                enqueueSnackbar('Event cancelled successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const cancelEventHandler = (id) => {
        showDialog({
            variant: 'confirm',
            title: 'Cancel Event!',
            content:
                'This action cannot be undone. Are you sure you want to proceed?  Select Yes to Continue',
            onYes: () => {
                handleCancelEvent(id);
            },
            onNo: hideDialog
        });
    };

    return (
        <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
            <CardHeader
                title={item?.eventName}
                subheader={''}
                // avatar={<Avatar src={item?.profile_pic} />}

                action={
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
            />
            <List disablePadding>
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <DateRangeIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                Date
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {`${moment(item?.start).format(
                                    'MMMM DD YYYY HH:mm'
                                )} to ${moment(item?.end).format(
                                    'MMMM DD YYYY HH:mm'
                                )}`}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <LocationOnIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                Location
                            </Typography>
                        }
                        secondary={
                            <Div>
                                <Typography variant={'h5'} mb={1}>
                                    {item?.location}
                                </Typography>
                                {item?.meetingLink && (
                                    <Button
                                        component={Link}
                                        className={
                                            'hover:text-pink-300 font-light font-normal'
                                        }
                                        target="_blank"
                                        to={item?.meetingLink}
                                        role="button"
                                        size="small"
                                    >
                                        Click here to Join Meeting/Event
                                    </Button>
                                )}
                            </Div>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <LocationCityIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                City
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {item?.city_name}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <LocationCityIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                School Name
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {item?.school_name ? (
                                    item?.school_name
                                ) : (
                                    <em>Not Applicable</em>
                                )}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <InfoIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                Description
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {item?.description ? (
                                    item?.description
                                ) : (
                                    <em>No Description</em>
                                )}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
            </List>
            <Div sx={{ flexGrow: 1, width: '100%', mt: '16px', px: '24px' }}>
                {item?.userId === userDetails?.userId &&
                    (!pastEvent ? (
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{ textAlign: 'center' }}
                            >
                                <Button
                                    disableElevation
                                    variant={'contained'}
                                    startIcon={<Edit />}
                                    sx={{
                                        mb: 0.5,
                                        '& .MuiSvgIcon-root': {
                                            fontSize: '1.5rem'
                                        },
                                        width: '100%'
                                    }}
                                    color="primary"
                                    onClick={handleUpdateForm}
                                >
                                    Update Event
                                </Button>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{ textAlign: 'center' }}
                            >
                                <LoadingButton
                                    disableElevation
                                    variant={'contained'}
                                    startIcon={<CancelIcon />}
                                    sx={{
                                        mb: 0.5,
                                        '& .MuiSvgIcon-root': {
                                            fontSize: '1.5rem'
                                        },
                                        width: '100%'
                                    }}
                                    loading={isCancelling}
                                    color="error"
                                    onClick={() => cancelEventHandler(item?.id)}
                                >
                                    Cancel Event
                                </LoadingButton>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                sx={{ textAlign: 'center' }}
                            >
                                <Button
                                    disableElevation
                                    variant={'contained'}
                                    startIcon={<PersonAddAltIcon />}
                                    sx={{
                                        mb: 2,
                                        '& .MuiSvgIcon-root': {
                                            fontSize: '1.5rem'
                                        },
                                        width: '100%'
                                    }}
                                    color="info"
                                    // loading={isRegistering}
                                    onClick={handleRegisterForm}
                                >
                                    Register Student
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                sx={{ textAlign: 'center' }}
                            >
                                {!item?.survey_invitations && (
                                    <LoadingButton
                                        disableElevation
                                        variant={'contained'}
                                        startIcon={<FeedbackIcon />}
                                        sx={{
                                            mb: 1.5,
                                            '& .MuiSvgIcon-root': {
                                                fontSize: '1.5rem'
                                            },
                                            width: '95%'
                                        }}
                                        color="info"
                                        onClick={() =>
                                            handleSurveyInvitation({
                                                eventId: item?.id
                                            })
                                        }
                                        loading={isLoading}
                                    >
                                        Send Survey Invitations
                                    </LoadingButton>
                                )}
                            </Grid>
                        </Grid>
                    ))}
            </Div>
        </Div>
    );
};

export default Detail;
