import React from 'react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { useMutation } from 'react-query';
import { uniapplicationsServices } from 'app/services/uniapplications-services';
import useUniapplicationsApp from '../../hooks/useUniapplicationsApp';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { useSnackbar } from 'notistack';
import { CircularProgress, Tooltip } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import _ from 'lodash';

const BulkActions = () => {
    const { selectedList, setListRefresh } = useUniapplicationsApp();
    const { showDialog, hideDialog } = useJumboDialog();
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: removeUniapplications, isLoading } = useMutation(
        uniapplicationsServices.deleteMultipleUniapplications,
        {
            onSuccess: () => {
                hideDialog();
                setListRefresh(true);
                enqueueSnackbar(
                    'Uni-application(s) have been deleted successfully.',
                    {
                        variant: 'success'
                    }
                );
            },
            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const handleBulkDelete = React.useCallback(() => {
        // enqueueSnackbar('Deletion has been disabled for this view', {
        //     variant: 'warning'
        // });

        // return;
        showDialog({
            variant: 'confirm',
            title: 'Delete Application(s)!',
            content:
                'This action cannot be undone. Are you sure you want to proceed?  Select Yes to Continue',
            onYes: () => {
                removeUniapplications(_.map(selectedList, (item) => item.id));
                hideDialog();
            },
            onNo: hideDialog
        });
    }, [selectedList, removeUniapplications, hideDialog]);

    return (
        <Stack
            direction={'row'}
            sx={{ backgroundColor: 'transparent', ml: -2 }}
        >
            <Tooltip title={'Delete'}>
                {isLoading ? (
                    <CircularProgress size={24} />
                ) : (
                    <IconButton onClick={handleBulkDelete}>
                        <DeleteOutlineIcon />
                    </IconButton>
                )}
            </Tooltip>
        </Stack>
    );
};

export default BulkActions;
