import React, { useState } from 'react';
import _ from 'lodash';
import { isValidEmail } from 'app/utils/appHelpers';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { useField, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';

export default function ContactAutocomplete({
    label = 'Recipients',
    name,
    prefix,
    contacts,
    variant = 'standard'
}) {
    const { setFieldValue } = useFormikContext();
    const { enqueueSnackbar } = useSnackbar();

    const [field, meta, helpers] = useField(name);
    const errorText = meta.error && meta.touched ? meta.error : '';

    return (
        <Autocomplete
            {...field}
            multiple
            id={name}
            options={contacts || []}
            // options={_.map(
            //     contacts,
            //     (contact) =>
            //         `${
            //             contact?.name
            //                 ? `${contact?.name} <${contact?.email_address}>`
            //                 : `${contact?.email_address}`
            //         } `
            // )}
            // defaultValue={field.value || []}
            value={field.value || []}
            getOptionLabel={(option) => {
                return option?.email_address || '';
            }}
            freeSolo
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        variant="outlined"
                        label={option?.email_address}
                        {...getTagProps({ index })}
                        size="small"
                    />
                ))
            }
            onChange={(_, newValue) => {
                const formattedContacts = newValue.map((contact) => {
                    if (typeof contact === 'object' && contact !== null) {
                        return contact; // If it's already an object, keep it as is
                    } else if (
                        typeof contact === 'string' &&
                        isValidEmail(contact)
                    ) {
                        return {
                            id: `CUSTOM`,
                            name: `CUSTOM`,
                            type: `CUSTOM`,
                            email_address: contact
                        };
                    } else {
                        enqueueSnackbar('Please enter a valid email', {
                            variant: 'warning'
                        });
                    }
                });

                // Remove elements that were converted to custom contacts
                const filteredContacts = formattedContacts.filter(
                    (contact) => typeof contact === 'object'
                );

                helpers.setValue(filteredContacts);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={variant}
                    label={label}
                    placeholder="Type name/email and press enter or select from the available options"
                    error={meta?.touched && meta?.error}
                    helperText={errorText}
                    // InputProps={{
                    //     startAdornment: (
                    //         <InputAdornment position="start">
                    //             {prefix}
                    //         </InputAdornment>
                    //     )
                    // }}
                />
            )}
        />
    );
}
