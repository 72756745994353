import React from 'react';
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemAvatar,
    Card,
    CardHeader,
    CardContent,
    Checkbox,
    Divider,
    Tooltip,
    Typography,
    Avatar,
    Stack,
    Alert,
    Chip
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import IconButton from '@mui/material/IconButton';
import BusinessIcon from '@mui/icons-material/Business';
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import styled from '@emotion/styled';
import Slide from '@mui/material/Slide';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import Div from '@jumbo/shared/Div';
import { requestsServices } from '../../../../../services/requests-services';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import ExpenseForm from '../ExpenseForm';
import useRequestsApp from '../../hooks/useRequestsApp';
import { useMutation } from 'react-query';
import RequestDetail from '../RequestDetail';
import JumboGridItem from '@jumbo/components/JumboList/components/JumboGridItem';
import { useSnackbar } from 'notistack';
import { capitalizeAllWords, formatNumber } from 'app/utils/appHelpers';
import _ from 'lodash';
import RequestStatus from 'app/pages/statuses/RequestStatus';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useNavigate } from 'react-router-dom';

const Item = styled(Span)(({ theme }) => ({
    minWidth: 0,
    flexGrow: 0,
    padding: theme.spacing(0, 1)
}));

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const RequestItem = ({ request, view }) => {
    const { authUser } = useJumboAuth();
    const [requestStatus, setRequestStatus] = React.useState([
        {
            id: 0,
            name: 'Inactive',
            slug: 'inactive',
            color: '#ca404f'
        }
    ]);
    const { enqueueSnackbar } = useSnackbar();

    const profilePic = request?.profile_pic
        ? process.env.REACT_APP_BACKEND + '/' + request?.employeeId?.profile_pic
        : null;

    const { showDialog, hideDialog } = useJumboDialog();
    const { setRequestsListRefresh } = useRequestsApp();
    const [favorite, setFavorite] = React.useState(request?.starred);

    const navigate = useNavigate();

    const handleItemClick = () => {
        navigate(`/app/expenses/requests/${request.id}`);
        return;
    };

    const deleteRequestMutation = useMutation(requestsServices.delete, {
        onSuccess: () => {
            hideDialogAndRefreshRequestsList();
            enqueueSnackbar('Request has been deleted successfully.', {
                variant: 'success'
            });
        },
        onError: (error) => {
            enqueueSnackbar(error, {
                variant: 'error'
            });
        }
    });

    const hideDialogAndRefreshRequestsList = React.useCallback(() => {
        hideDialog();
        setRequestsListRefresh(true);
    }, [hideDialog, setRequestsListRefresh]);

    const handleRequestUpdate = React.useCallback(() => {
        hideDialog();
        setRequestsListRefresh(true);
    }, [hideDialog, setRequestsListRefresh]);

    const showRequestDetail = React.useCallback(() => {
        showDialog({
            content: (
                <RequestDetail
                    onSave={handleRequestUpdate}
                    authUser={authUser}
                    request={request}
                    onClose={hideDialog}
                />
            ),
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true,
            maxWidth: 'lg'
        });
    }, [showDialog, request]);

    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case 'review':
                showRequestDetail(request);
                break;
            case 'edit':
                showDialog({
                    title: 'Update request detail',
                    content: (
                        <ExpenseForm
                            request={request}
                            onSave={hideDialogAndRefreshRequestsList}
                        />
                    )
                });
                break;
            case 'delete':
                showDialog({
                    variant: 'confirm',
                    title: 'Are you sure about deleting this request?',
                    content: "You won't be able to recover this request later",
                    onYes: () => deleteRequestMutation.mutate(request),
                    onNo: hideDialog
                });
        }
    };

    React.useEffect(() => {
        setRequestStatus(
            _.find(RequestStatus, (status) => status.id === request?.status)
        );
    }, [request]);

    return (
        <React.Fragment>
            <JumboListItem
                componentElement={'div'}
                itemData={request}
                secondaryAction={
                    <JumboDdMenu
                        icon={<MoreHorizIcon />}
                        menuItems={[
                            // {
                            //     icon: <EditIcon />,
                            //     title: 'Edit',
                            //     action: 'edit'
                            // },
                            {
                                icon: <PreviewIcon />,
                                title: 'Review',
                                action: 'review'
                            }
                        ]}
                        onClickCallback={handleItemAction}
                    />
                }
                sx={{
                    cursor: 'pointer',
                    borderTop: 1,
                    borderColor: 'divider',
                    '&:hover': {
                        bgcolor: 'action.hover'
                    }
                }}
            >
                <ListItemAvatar onClick={handleItemClick}>
                    <Avatar alt={request?.first_name} src={profilePic} />
                </ListItemAvatar>
                <ListItemText
                    onClick={handleItemClick}
                    primary={
                        <Typography variant={'body1'} component={'div'}>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                sx={{ minWidth: 0 }}
                            >
                                <Item
                                    sx={{
                                        flexBasis: {
                                            xs: '100%',
                                            sm: '50%',
                                            md: '26%'
                                        }
                                    }}
                                >
                                    <Typography
                                        variant={'h5'}
                                        fontSize={14}
                                        lineHeight={1.25}
                                        mb={0}
                                        noWrap
                                    >
                                        {request?.employeeId?.first_name}{' '}
                                        {request?.employeeId?.last_name}
                                    </Typography>
                                    <Typography
                                        variant={'body1'}
                                        noWrap
                                        color={'text.secondary'}
                                        sx={{
                                            display: { sm: 'none' }
                                        }}
                                    >
                                        {request?.employeeId?.email_address}
                                    </Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { md: '17%' },
                                        display: { xs: 'none', md: 'block' },
                                        minWidth: '17%'
                                    }}
                                >
                                    <Typography
                                        variant={'body1'}
                                        sx={{
                                            color: 'primary.main'
                                        }}
                                        noWrap
                                    >
                                        {capitalizeAllWords(request?.module)}
                                    </Typography>
                                </Item>
                                {request?.module === 'travel' && (
                                    <Item
                                        sx={{
                                            flexBasis: { sm: '50%', md: '42%' },
                                            display: { xs: 'flex', sm: 'flex' },
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            minWidth: '42%'
                                        }}
                                    >
                                        <Typography variant={'body1'}>
                                            {capitalizeAllWords(
                                                request?.travel?.travel_type
                                            )}{' '}
                                            {capitalizeAllWords(
                                                request?.travel?.trip_type
                                            )}{' '}
                                            From{' '}
                                            <Span
                                                sx={{
                                                    color: 'primary.main'
                                                }}
                                            >
                                                {request?.travel?.origin}
                                            </Span>{' '}
                                            {request?.travel?.destination ? (
                                                <>
                                                    {'to '}
                                                    <Span
                                                        sx={{
                                                            color: 'primary.main'
                                                        }}
                                                    >
                                                        {
                                                            request?.travel
                                                                ?.destination
                                                        }
                                                    </Span>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </Typography>
                                    </Item>
                                )}

                                {(request?.module === 'purchase' ||
                                    request?.module === 'report') && (
                                    <Item
                                        sx={{
                                            flexBasis: {
                                                sm: '50%',
                                                md: '42%'
                                            },
                                            display: {
                                                xs: 'flex',
                                                sm: 'flex'
                                            },
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            minWidth: '42%'
                                        }}
                                    >
                                        <Typography
                                            variant={''}
                                            sx={{
                                                textAlign: 'left'
                                            }}
                                        >
                                            {request?.expense_name}
                                        </Typography>
                                    </Item>
                                )}

                                {request?.module === 'advance' && (
                                    <Item
                                        sx={{
                                            flexBasis: { sm: '50%', md: '42%' },
                                            display: { xs: 'flex', sm: 'flex' },
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            minWidth: '42%'
                                        }}
                                    >
                                        <Typography
                                            variant={''}
                                            sx={{
                                                textAlign: 'left'
                                            }}
                                        >
                                            {request?.expense_name}
                                        </Typography>
                                    </Item>
                                )}
                                <Item
                                    sx={{
                                        flexBasis: { md: '15%' },
                                        display: { xs: 'none', md: 'block' },
                                        alignItems: 'right'
                                    }}
                                >
                                    <Typography
                                        variant={'body1'}
                                        sx={{
                                            fontWeight: 500,
                                            textAlign: 'right'
                                        }}
                                        noWrap
                                    >
                                        {formatNumber(request?.total_amount)}
                                    </Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { md: '22%' },
                                        display: { xs: 'none', md: 'block' }
                                    }}
                                >
                                    <JumboChipsGroup
                                        chips={
                                            request
                                                ? [requestStatus]
                                                : [
                                                      {
                                                          id: 0,
                                                          name: 'Inactive',
                                                          slug: 'inactive',
                                                          color: '#ca404f'
                                                      }
                                                  ]
                                        }
                                        mapKeys={{ label: 'name' }}
                                        spacing={1}
                                        size={'small'}
                                        max={1}
                                    />
                                </Item>
                            </Stack>
                        </Typography>
                    }
                />
            </JumboListItem>
        </React.Fragment>
    );
};
/* Todo request, view prop define */
export default RequestItem;
