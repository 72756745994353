import React from 'react';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import NotificationBirthday from './NotificationBirthday';
import NotificationInvitation from './NotificationInvitation';
import NotificationSharedPost from './NotificationSharedPost';
import NotificationPost from './NotificationPost';
import NotificationsItem from './NotificationsItem';
// import { notifications } from './fake-data';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Divider from '@mui/material/Divider';
import JumboIconButton from '@jumbo/components/JumboIconButton';
import JumboDdPopover from '@jumbo/components/JumboDdPopover';
import Div from '@jumbo/shared/Div';
import { CardActions, ThemeProvider } from '@mui/material';
import useJumboHeaderTheme from '@jumbo/hooks/useJumboHeaderTheme';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
// import notificationsServices from 'app/services/notifications-services';
import axios from 'axios';
import { notificationsServices } from 'app/services/notifications-services';

const NotificationComponents = {
    UNI_APPLICATION: NotificationPost,
    POSTING: NotificationPost,
    SHARED_POST: NotificationSharedPost,
    INVITATION: NotificationInvitation,
    BIRTHDAY: NotificationBirthday
};

const NotificationsDropdown = () => {
    const { authUser } = useJumboAuth();
    const { theme } = useJumboTheme();
    const { headerTheme } = useJumboHeaderTheme();

    const [notifications, setNotifications] = React.useState([]);

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };
        const fetchNotifications = async () => {
            try {
                const res = await notificationsServices.getAllNotifications(
                    cToken
                );

                // notificationsServices;

                setNotifications(
                    res.notifications && res.notifications.length > 0
                        ? res.notifications.reverse()
                        : []
                );
            } catch (error) {
                console.log(error);
                // enqueueSnackbar(error, {
                //     variant: 'error'
                // });
            }
        };

        fetchNotifications();

        return () => {
            cancelToken.cancel();
        };
    }, []);

    // console.log('Notifications');
    // console.log(notifications);

    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover
                triggerButton={
                    <ThemeProvider theme={headerTheme}>
                        <JumboIconButton
                            badge={{ variant: 'dot' }}
                            elevation={25}
                        >
                            <NotificationImportantOutlinedIcon
                                sx={{ fontSize: '1.25rem' }}
                            />
                        </JumboIconButton>
                    </ThemeProvider>
                }
                disableInsideClick
            >
                <Div sx={{ width: 360, maxWidth: '100%' }}>
                    <CardHeader
                        title={'Notifications'}
                        action={
                            <IconButton sx={{ my: -1 }}>
                                <MoreHorizIcon />
                            </IconButton>
                        }
                    />
                    <List
                        disablePadding
                        sx={{ maxHeight: '300px', overflowY: 'scroll' }}
                    >
                        {notifications &&
                            notifications.length > 0 &&
                            notifications.map((item, index) => {
                                // const NotificationComponent =
                                //     NotificationComponents[item.type];
                                return (
                                    <NotificationsItem
                                        key={index}
                                        item={item}
                                    />
                                );
                            })}
                        {(!notifications || notifications.length === 0) && (
                            <em>No Notifications</em>
                        )}
                    </List>
                    <Divider />
                    <CardActions sx={{ justifyContent: 'center' }}>
                        <Button
                            sx={{
                                textTransform: 'none',
                                fontWeight: 'normal',
                                '&:hover': { bgcolor: 'transparent' }
                            }}
                            size={'small'}
                            variant="text"
                            endIcon={<ArrowForwardIcon />}
                            disableRipple
                        >
                            View All
                        </Button>
                    </CardActions>
                </Div>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

export default NotificationsDropdown;
