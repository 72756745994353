import React, { useState } from 'react';
import _ from 'lodash';
import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import { useMutation } from 'react-query';
import { leadsServices } from 'app/services/leads-services';
import { studentServices } from 'app/services/student-services';
import { contactService } from 'app/services/contact-services';
import { emailService } from 'app/services/email-services';
import { eventsServices } from 'app/services/events-services';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios';
import { capitalizeAllWords } from 'app/utils/appHelpers';
import ContactAutocomplete from './ContactAutocomplete';
import OptionsAutocomplete from './OptionsAutocomplete';
import { convertToRaw, EditorState } from 'draft-js';
import WysiwygEditor from './WysiwygEditor';
import QuillEditor from './QuillEditor';

const validationSchema = yup.object({
    // optionId: yup
    //     .string('Select Recipient category')
    //     .required('Recipient category is required'),
    optionId: yup
        .mixed()
        .test('isObjectOrArray', 'Select Recipient category', function (value) {
            if (Array.isArray(value)) {
                // Check if it's an array of objects
                return value.every(
                    (item) => typeof item === 'object' && !Array.isArray(item)
                );
            } else if (typeof value === 'object' && !Array.isArray(value)) {
                // Check if it's an object
                return true;
            }
            return false;
        }),
    subject: yup.string('Enter Subject').required('Subject is required'),
    message: yup.string('Enter Message').required('Message is required')
});

const categories = [
    { id: 1, type: 'Custom' },
    { id: 2, type: 'Students' },
    { id: 3, type: 'By Event' }
];

const EmailForm = ({ onSave, hideDialog }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [finalContacts, setFinalContacts] = React.useState(false);
    const [studentsContacts, setStudentsContacts] = React.useState(false);
    const [events, setEvents] = React.useState(false);
    const [optionId, setOptionId] = useState(null);
    const [studentId, setStudentId] = useState(null);
    const [eventId, setEventId] = useState(null);

    const [editorHtml, setEditorHtml] = useState('');

    const { mutate: sendEmailMutation, isLoading } = useMutation(
        emailService.send,
        {
            onSuccess: () => {
                hideDialog();
                enqueueSnackbar('Email has been sent successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onSend = async (emailDetails) => {
        if (
            emailDetails.eventId == null &&
            emailDetails.students == null &&
            emailDetails.to == null
        ) {
            enqueueSnackbar('Please enter/select recipients.', {
                variant: 'warning'
            });
            return;
        }

        try {
            sendEmailMutation({ ...emailDetails, message: editorHtml });
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelTokenStudents = axios.CancelToken.source();
        const cTokenStudents = { cancelToken: cancelTokenStudents.token };
        const fetchContacts = async () => {
            try {
                const students = await studentServices.getAllStudents(
                    cTokenStudents
                );
                const contacts = await contactService.getAllContacts(
                    cTokenStudents
                );
                let studentsOptions = _.map(
                    _.filter(
                        students.studentsList,
                        (student) => !!student?.email_address
                    ),
                    (student) => ({
                        name: `${student.given_name} ${student.surname}`,
                        email_address: student.email_address
                    })
                );
                setStudentsContacts([
                    {
                        name: `ALL`,
                        email_address: 'All Students'
                    },
                    ...studentsOptions
                ]);

                let contactsOptions = _.map(
                    _.filter(
                        contacts.contacts,
                        (contact) => !!contact?.email_address
                    ),
                    (contact) => ({
                        name: `${contact.first_name} ${contact.last_name}`,
                        email_address: contact.email_address
                    })
                );

                setFinalContacts(
                    _.uniqBy(
                        [...studentsOptions, ...contactsOptions],
                        'email_address'
                    )
                );
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchContacts();

        return () => {
            cancelTokenStudents.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenEvents = axios.CancelToken.source();
        const cTokenEvents = { cancelToken: cancelTokenEvents.token };
        const fetchEvents = async () => {
            try {
                const events = await eventsServices.getAllEvents(cTokenEvents);

                let eventsOptions = _.map(events.events, (event) => ({
                    id: `${event.id}`,
                    event_name: event.event_name
                }));

                setEvents(eventsOptions);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchEvents();

        return () => {
            cancelTokenEvents.cancel();
        };
    }, []);

    React.useEffect(() => {
        setStudentId(null);
        setEventId(null);
    }, [optionId]);

    // console.log('optionId ------------------> ');
    // console.log(optionId);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {!finalContacts && !studentsContacts && !events && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {finalContacts && studentsContacts && events && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        optionId: optionId,
                        eventId: eventId,
                        students: studentId,
                        to: '',
                        subject: '',
                        message: editorHtml
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onSend(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            {/* <Div>{JSON.stringify(errors)}</Div> */}
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <OptionsAutocomplete
                                            label="Recipient Category"
                                            optionLabel="type"
                                            name="optionId"
                                            prefix="Recipients"
                                            optionId={optionId}
                                            setOptionId={setOptionId}
                                            options={categories}
                                        />
                                    </Grid>

                                    {optionId?.id === 1 && (
                                        <Grid item xs={12} md={12}>
                                            <ContactAutocomplete
                                                label="Recipients"
                                                name="to"
                                                prefix="To"
                                                contacts={finalContacts}
                                            />
                                        </Grid>
                                    )}
                                    {optionId?.id === 2 && (
                                        <Grid item xs={12} md={12}>
                                            <ContactAutocomplete
                                                label="Students"
                                                name="students"
                                                prefix="To"
                                                contacts={studentsContacts}
                                            />
                                        </Grid>
                                    )}
                                    {optionId?.id === 3 && (
                                        <Grid item xs={12} md={12}>
                                            <OptionsAutocomplete
                                                multiple={true}
                                                label="Event"
                                                optionLabel="event_name"
                                                name="eventId"
                                                prefix="Event"
                                                optionId={eventId}
                                                setOptionId={setEventId}
                                                options={events}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="subject"
                                            name="subject"
                                            // label="Subject"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {'Subject'}
                                                    </InputAdornment>
                                                )
                                            }}
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        {/* <WysiwygEditor
                                            editorState={editorState}
                                            setEditorState={setEditorState}
                                        /> */}

                                        <QuillEditor
                                            name="message"
                                            editorHtml={editorHtml}
                                            setEditorHtml={setEditorHtml}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                ></Grid>
                                <Grid
                                    sx={{
                                        margin: '60px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                    >
                                        Send Email
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo studentItem, onSave prop define */
export default EmailForm;
