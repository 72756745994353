import React from 'react';

import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import MenuItem from '@mui/material/MenuItem';

import { useMutation } from 'react-query';
import { leavetypesServices } from 'app/services/leavetypes-services';

import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { useSnackbar } from 'notistack';

const validationSchema = yup.object({
    leave_type_name: yup
        .string('Enter leave type name')
        .required('Leave type name is required'),
    type: yup.string('Select leave type').required('Leave type is required'),
    days_per_year: yup
        .string('Enter no of days available in a year')
        .required('Days available in a year are required')
});

const EditForm = ({ listItem, onUpdate, hideDialog }) => {
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: editLeavetypeMutation, isLoading } = useMutation(
        leavetypesServices.updateLeavetype,
        {
            onSuccess: () => {
                onUpdate();

                enqueueSnackbar('Leavetypes has been updated successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onEdit = async (leavetype) => {
        try {
            editLeavetypeMutation({
                id: listItem.id,
                ...leavetype
            });
        } catch (error) {}
    };

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            <Formik
                validateOnChange={true}
                initialValues={{
                    leave_type_name: listItem.leave_type_name,
                    description: listItem.description,
                    days_per_year: listItem.days_per_year,
                    type: listItem.type
                }}
                validationSchema={validationSchema}
                onSubmit={async (data, { setSubmitting }) => {
                    await onEdit(data);
                }}
            >
                {({ isSubmitting, errors, touched, values, ...restProps }) => (
                    <Form
                        style={{ textAlign: 'left' }}
                        noValidate
                        autoComplete="off"
                    >
                        <Div>{isLoading}</Div>
                        <Div sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="leave_type_name"
                                        name="leave_type_name"
                                        label="Leave type Name"
                                        placeholder="Enter Leave type Name"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <JumboSelectField
                                        width="100%"
                                        size={'small'}
                                        id="type"
                                        name="type"
                                        label="Type"
                                        placeholder="Select type"
                                    >
                                        <MenuItem key={'type'} value="">
                                            <em>Select Type</em>
                                        </MenuItem>

                                        <MenuItem
                                            key={'mandatory'}
                                            value={'Mandatory'}
                                        >
                                            Mandatory
                                        </MenuItem>
                                        <MenuItem
                                            key={'optional'}
                                            value={'Optional'}
                                        >
                                            Optional
                                        </MenuItem>
                                    </JumboSelectField>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="days_per_year"
                                        name="days_per_year"
                                        label="Days Per year"
                                        type="number"
                                        placeholder="Enter No. Of days available in a year"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="description"
                                        name="description"
                                        label="Leave type description"
                                        placeholder="Enter Leave type description"
                                        multiline
                                        rows={3}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    margin: '30px auto'
                                }}
                                container
                                alignItems="right"
                                justify="flex-end"
                                justifyContent="right"
                            >
                                <Button
                                    size="large"
                                    sx={{ mb: 3 }}
                                    onClick={() => hideDialog()}
                                >
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isLoading}
                                >
                                    Update
                                </LoadingButton>
                            </Grid>
                        </Div>
                    </Form>
                )}
            </Formik>
        </Div>
    );
};
/* Todo leavetypesItem, onSave prop define */
export default EditForm;
