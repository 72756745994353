import React from 'react';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'app/AppAbility';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder';
import JumboListWrapper from '@jumbo/components/JumboList/components/JumboListWrapper';
import NotAllowed from 'app/shared/NotAllowed';
import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList';
import { leadsServices } from 'app/services/leads-services';
import ListItem from './ListItem';
import { Card, Slide, Typography } from '@mui/material';
import styled from '@emotion/styled';
import Span from '@jumbo/shared/Span';
import Button from '@mui/material/Button';
import { Add } from '@mui/icons-material';
import JumboListToolbar from '@jumbo/components/JumboList/components/JumboListToolbar';
import { useParams } from 'react-router-dom';
import JumboSearch from '@jumbo/components/JumboSearch';
import useLeadsApp from '../../hooks/useLeadsApp';
import BulkActions from './BulkActions';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import CreateForm from '../CreateForm';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import _ from 'lodash';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import LeadStatus from 'app/pages/statuses/LeadStatus';
import LeadInfo from '../LeadInfo';
import ExcelExport from 'app/shared/ExcelExport';
import GetAppIcon from '@mui/icons-material/GetApp';
import OfficeFilter from 'app/shared/OfficeFilter';
import { Form, Formik, Field } from 'formik';
import LeadsSummary from '../LeadsSummary';
import Div from '@jumbo/shared/Div';

const Item = styled(Span)(({ theme }) => ({
    padding: theme.spacing(0, 1)
}));
const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const List = () => {
    const { authUser } = useJumboAuth();
    const { hideDialog, showDialog } = useJumboDialog();

    const ability = useAbility(AbilityContext);
    const allowReadList = ability.can('read', 'Leads');
    const allowAdd = ability.can('create', 'Lead');
    const allowBulkDel = ability.can('delete', 'Lead');

    const params = useParams();
    const listRef = React.useRef();
    const { refreshList, setListRefresh, setSelectedList } = useLeadsApp();

    const [queryOptions, setQueryOptions] = React.useState({
        queryKey: 'leads-list',
        queryParams: { role: params.roleId, id: params._id, searchText: '' },
        countKey: 'count',
        dataKey: 'leadsList'
    });

    const handleUpdatedItem = React.useCallback(() => {
        hideDialog();
        setListRefresh(true);
    }, [hideDialog]);

    const updateQueryOfficeFilter = React.useCallback((officeId) => {
        if (officeId) {
            setQueryOptions({
                ...queryOptions,
                queryParams: {
                    ...queryOptions.queryParams,
                    officeId: officeId
                }
            });

            setExportOptions({
                ...queryOptions,
                queryParams: {
                    ...queryOptions.queryParams,
                    officeId: officeId
                }
            });

            setListRefresh(true);
        }
    }, []);

    const [leadStatus, setLeadStatus] = React.useState([
        {
            id: 0,
            name: 'Inactive',
            slug: 'inactive',
            color: '#ca404f'
        }
    ]);

    const renderListItem = React.useCallback((listItem) => {
        return <ListItem listItem={listItem} />;
    });

    const [exportOptions, setExportOptions] = React.useState({
        queryKey: 'leads-export-list',
        queryParams: {
            exportAction: true,
            officeId: false,
            status: null,
            limit: null,
            searchText: ''
        },
        countKey: 'count',
        dataKey: 'leadsList'
    });

    const handleOnChange = React.useCallback((value) => {
        setQueryOptions((state) => ({
            ...state,
            queryParams: {
                ...state.queryParams,
                searchText: value
            }
        }));
    }, []);

    React.useEffect(() => {
        if (listRef?.current && refreshList) {
            listRef.current.refresh();
            setListRefresh(false);
        }
    }, [refreshList]);

    React.useEffect(() => {
        setQueryOptions((state) => ({
            ...state,
            queryParams: {
                category: params.category,
                id: params.id,
                ...state.queryParams.searchText
            }
        }));
    }, [params]);

    const handleDataSent = React.useCallback(() => {
        hideDialog();
        setListRefresh(true);
    }, []);

    const handleCreateForm = React.useCallback(() => {
        showDialog({
            title: 'Create Lead',
            content: (
                <CreateForm
                    onSave={handleDataSent}
                    hideDialog={hideDialog}
                    userDetails={authUser}
                />
            ),
            maxWidth: 'sm',
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true
        });
    }, [showDialog]);

    const handleItemClick = React.useCallback((listItem, leadStatus) => {
        // navigate(`/home/students/student/${listItem.studentId.id.toString()}`);
        // return;

        showDialog({
            title: 'Lead Details',
            subheader: (
                <JumboChipsGroup
                    chips={[
                        _.find(
                            LeadStatus,
                            (status) => status.id === listItem?.status
                        )
                    ]}
                    mapKeys={{ label: 'name' }}
                    spacing={1}
                    size={'small'}
                    max={1}
                />
            ),
            content: (
                <LeadInfo
                    listItem={listItem}
                    onUpdate={handleUpdatedItem}
                    hideDialog={hideDialog}
                    userDetails={authUser}
                />
            ),
            maxWidth: 'xl',
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true
        });
    }, []);

    React.useEffect(() => {
        const fetchLead = async (id) => {
            console.log('fetching lead......................');
            try {
                const res = await leadsServices.getLeadDetails(id);
                console.log(res);
                res.lead && handleItemClick(res.lead);
            } catch (error) {
                console.log(error);
                // enqueueSnackbar(error, {
                //     variant: 'error'
                // });
            }
        };

        if (params.id) {
            fetchLead(params.id);
        }
    }, [params.id]);

    const tableActions = authUser && allowBulkDel && <BulkActions />;

    const actionButton = authUser && allowAdd && (
        <>
            <Button
                disableElevation
                variant={'contained'}
                startIcon={<Add />}
                sx={{
                    mb: 1,
                    mr: 0.5,
                    '& .MuiSvgIcon-root': {
                        fontSize: '1.5rem'
                    }
                }}
                onClick={handleCreateForm}
            >
                Create Lead
            </Button>
        </>
    );

    if (!allowReadList) {
        return (
            <JumboListWrapper
                component={Card}
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <JumboListNoDataPlaceholder>
                    <NotAllowed />
                </JumboListNoDataPlaceholder>
            </JumboListWrapper>
        );
    }

    return (
        <>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    my: 2,
                    p: 1,
                    px: 2,
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Div
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        ml: 2,
                        width: '60%',
                        alignItems: 'center'
                    }}
                >
                    <Typography variant="h5">Filter: </Typography>
                    <Div
                        sx={{
                            ml: 2,
                            width: '60%'
                        }}
                    >
                        <OfficeFilter
                            filterOffices={
                                authUser &&
                                !['administrator', 'director'].includes(
                                    authUser?.role_name
                                )
                                    ? authUser.officeIds
                                    : false
                            }
                            updateState={updateQueryOfficeFilter}
                            selectedOfficeId={
                                queryOptions?.queryParams?.officeId
                            }
                        />
                    </Div>
                </Div>
                <ExcelExport
                    queryService={leadsServices?.exportLeads}
                    options={exportOptions}
                    file_name={'Leads_List'}
                />
            </Card>

            <LeadsSummary
                refreshList={refreshList}
                queryOptions={queryOptions}
            />

            <JumboRqList
                ref={listRef}
                wrapperComponent={Card}
                queryOptions={queryOptions}
                primaryKey={'id'}
                service={leadsServices.getLeads}
                renderItem={renderListItem}
                itemsPerPage={8}
                itemsPerPageOptions={[8, 12, 15]}
                componentElement={'div'}
                wrapperSx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
                toolbar={
                    <JumboListToolbar
                        hideItemsPerPage={true}
                        bulkActions={tableActions}
                        addButton={actionButton}
                    >
                        {authUser && (
                            <JumboSearch
                                onChange={handleOnChange}
                                sx={{
                                    display: { xs: 'none', md: 'block' }
                                }}
                                value={queryOptions.queryParams.keyword}
                            />
                        )}
                    </JumboListToolbar>
                }
                onSelectionChange={setSelectedList}
            />
        </>
    );
};

export default List;
