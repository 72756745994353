import React, { useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { Grid } from '@mui/material';
import Chip from '@mui/material/Chip';
import Div from '@jumbo/shared/Div';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import JumboRadioGroup from '@jumbo/components/JumboFormik/JumboRadioGroup';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useMutation } from 'react-query';
import { requestsServices } from 'app/services/requests-services';
import { expensesServices } from 'app/services/expenses-services';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import axios from 'axios';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import ExpenseStatus from 'app/pages/statuses/ExpenseStatus';
import _ from 'lodash';
import Span from '@jumbo/shared/Span';
import { capitalizeAllWords, formatNumber } from 'app/utils/appHelpers';

const validationSchema = yup.object({
    expenseId: yup
        .string('Select expense type')
        .required('Expense type is required'),
    total_amount: yup
        .number('Enter the required amount ')
        .min(1, 'The amount must be at least 1.')
        .required('Advance amount is required'),
    purpose: yup
        .string('Enter advance justification')
        .required('Purpose/Justification is required'),
    required_date: yup
        .string('Select required date')
        .required('Provide the Required by date')
});

const AdvanceForm = ({ onSave, hideDialog, authUser }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [expenseStatus, setExpenseStatus] = React.useState([
        {
            id: 0,
            name: 'Inactive',
            slug: 'inactive',
            color: '#ca404f'
        }
    ]);
    const [expenses, setExpenses] = React.useState(false);
    const [feeCurrency, setFeeCurrency] = React.useState(
        authUser?.primaryCurrency?.currency || 'KES'
    );
    const [feeCurrencyName, setFeeCurrencyName] = React.useState(
        authUser?.primaryCurrency?.currency_name || 'Kenyan Shillings'
    );

    const [requiredDate, setRequiredDate] = React.useState(dayjs(new Date()));
    const [maxAdvanceAmount, setMaxAdvanceAmount] = React.useState(0);

    const { mutate: submitRequestMutation, isLoading } = useMutation(
        requestsServices.addRequest,
        {
            onSuccess: () => {
                enqueueSnackbar('Request has been submitted successfully.', {
                    variant: 'success'
                });
                onSave();
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (request) => {
        if (request.total_amount > maxAdvanceAmount) {
            enqueueSnackbar(
                'The requested advance exceeds the approved budget for expenses.',
                {
                    variant: 'error'
                }
            );

            return false;
        }
        try {
            let postData = {
                formikData: { ...request, module: 'advance' },
                files: false
            };
            submitRequestMutation(postData);
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };
        const fetchExpenses = async () => {
            try {
                const res = await expensesServices.getAllExpenses(
                    { approved: 'APPROVED' },
                    cToken
                );

                const finalOptions = _.map(
                    _.filter(
                        res.expenses,
                        (expense) => !expense.advanceId && expense.status === 1
                    ),
                    (expense) => {
                        const {
                            id,
                            expense_id,
                            expense_name,
                            total_amount,
                            status
                        } = expense;

                        return {
                            id,
                            expense_id,
                            expense_name,
                            total_amount,
                            status
                        };
                    }
                );

                setExpenses(finalOptions);
            } catch (error) {
                console.log('compon', error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchExpenses();

        return () => {
            cancelToken.cancel();
        };
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {!expenses && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {expenses && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        expenseId: '',
                        expenseName: '',
                        total_amount: 0,
                        currency: feeCurrency,
                        currency_name: feeCurrencyName,
                        purpose: '',
                        required_date: requiredDate
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onCreate(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        setFieldValue,
                        setFieldError,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={1.5}>
                                    <Grid item xs={12} md={12}>
                                        <Field name="expenseId">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    sx={{}}
                                                    size="small"
                                                    options={expenses}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.expense_name
                                                            ? `${
                                                                  option.expense_name
                                                              } - Budget: ${formatNumber(
                                                                  option.total_amount
                                                              )}`
                                                            : ''
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => {
                                                        const selStatus =
                                                            _.find(
                                                                ExpenseStatus,
                                                                (status) =>
                                                                    status.id ===
                                                                    option?.status
                                                            );

                                                        console.log(selStatus);
                                                        return (
                                                            <Box
                                                                component="li"
                                                                {...props}
                                                                sx={{
                                                                    width: '100%',
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                    justifyContent:
                                                                        'space-between',
                                                                    '& > img': {
                                                                        mr: 2,
                                                                        flexShrink: 0
                                                                    }
                                                                }}
                                                            >
                                                                ID #{' '}
                                                                <Span
                                                                    sx={{
                                                                        color: 'primary.main'
                                                                    }}
                                                                >
                                                                    {
                                                                        option?.expense_id
                                                                    }
                                                                </Span>
                                                                {' - '}
                                                                {
                                                                    option.expense_name
                                                                }{' '}
                                                                <Chip
                                                                    color={
                                                                        selStatus?.type
                                                                    }
                                                                    size="small"
                                                                    label={
                                                                        selStatus?.status
                                                                    }
                                                                />
                                                                {' - '}
                                                                <Span
                                                                    sx={{
                                                                        color: 'primary.main'
                                                                    }}
                                                                >
                                                                    Budget :{' '}
                                                                    {formatNumber(
                                                                        option?.total_amount
                                                                    )}
                                                                </Span>
                                                            </Box>
                                                        );
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Related Expense"
                                                            placeholder="Choose expense"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.expenseId &&
                                                                !!errors.expenseId
                                                            }
                                                            helperText={
                                                                touched?.expenseId &&
                                                                errors.expenseId
                                                            }
                                                        />
                                                    )}
                                                    onChange={(
                                                        event,
                                                        newValue
                                                    ) => {
                                                        values.expenseId =
                                                            newValue?.id;
                                                        values.expenseName =
                                                            newValue?.expense_name;
                                                        setFeeCurrency(
                                                            newValue?.currency
                                                        );
                                                        setFeeCurrencyName(
                                                            newValue?.currency_name
                                                        );
                                                        values.currency =
                                                            newValue?.currency;
                                                        values.currency_name =
                                                            newValue?.currency_name;
                                                        setMaxAdvanceAmount(
                                                            newValue?.total_amount
                                                        );
                                                    }}
                                                    value={
                                                        expenses.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.expenseId
                                                        ) || null
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            sx={{}}
                                            id="total_amount"
                                            name="total_amount"
                                            type="number"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {feeCurrency}
                                                    </InputAdornment>
                                                ),
                                                max: maxAdvanceAmount,
                                                min: 0
                                            }}
                                            label="Advance Amount"
                                            placeholder="Total"
                                            // disabled={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            sx={{}}
                                            id="purpose"
                                            name="purpose"
                                            label="Purpose"
                                            placeholder="Enter purpose for the advance"
                                            multiline
                                            rows={2}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <MobileDatePicker
                                                label="Required By"
                                                inputFormat="YYYY-MM-DD"
                                                disablePast={true}
                                                closeOnSelect={true}
                                                value={requiredDate}
                                                onChange={(newValue) => {
                                                    setRequiredDate(newValue);
                                                    values.required_date =
                                                        newValue;
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <JumboTextField
                                                            fullWidth
                                                            size={'small'}
                                                            sx={{
                                                                marginTop: '8px'
                                                            }}
                                                            value={requiredDate}
                                                            name="required_date"
                                                            {...params}
                                                        />
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid
                                        sx={{
                                            margin: '30px auto'
                                        }}
                                        container
                                        alignItems="right"
                                        justify="flex-end"
                                        justifyContent="right"
                                    >
                                        <Button
                                            size="large"
                                            sx={{ mb: 3 }}
                                            onClick={() => hideDialog()}
                                        >
                                            Cancel
                                        </Button>
                                        <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{ mb: 3 }}
                                            loading={isLoading}
                                        >
                                            Submit Request
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo officeItem, onSave prop define */
export default AdvanceForm;
