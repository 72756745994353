import React from 'react';
import _ from 'lodash';
import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { useMutation } from 'react-query';
import { studentServices } from 'app/services/student-services';
import { countriesServices } from 'app/services/countries-services';
import { educationlevelsServices } from 'app/services/educationlevels-services';
import { officeServices } from 'app/services/office-services';

import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboRadioGroup from '@jumbo/components/JumboFormik/JumboRadioGroup';
import axios from 'axios';
import {
    capitalizeAllWords,
    getCurrentDate,
    getNextYearDate
} from 'app/utils/appHelpers';

import { COURSE_TYPES } from 'app/utils/constants/variables';

const validationSchema = yup.object({
    given_name: yup
        .string('Enter given name')
        .required('Given name is required'),
    surname: yup.string('Enter surname').required('Surname is required'),
    // dob: yup
    //     .string('Enter date of birth')
    //     .required('Date of birth is required'),
    // gender: yup.string('Select gender').required('Gender is required'),
    email_address: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    phone_number: yup
        .string('Enter phone number')
        // .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone number is required'),
    city_name: yup
        .string('Enter city of residence')
        .required('City of residence is required'),
    preferred_course_level: yup
        .string('Enter preferred course_level')
        .required('Preferred course level is required'),
    preferred_course: yup
        .string('Enter preferred course')
        .required('Preferred course is required'),
    preferred_study_country: yup
        .string('Enter preferred study country')
        .required('Preferred study country is required')
});

const StudentCreateForm = ({
    eventDetails,
    onSave,
    hideDialog,
    userDetails
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const [phone, setPhone] = React.useState('+254');
    const [courseType, setCourseType] = React.useState('');
    const [defaultValues, setDefaultValues] = React.useState({
        given_name: '',
        surname: '',
        gender: '',
        email_address: '',
        phone_number: phone,
        city_name: '',
        preferred_course_level: '',
        preferred_course: '',
        preferred_study_country: '',
        preferred_study_countryId: '',
        eventId: eventDetails?.id,
        officeId: eventDetails?.officeId,
        high_school: eventDetails?.school_name,
        current_school: eventDetails?.school_name,
        reg_mode: 'event'
    });

    const [countries, setCountries] = React.useState(false);
    const [qualifications, setQualifications] = React.useState(false);
    const [offices, setOffices] = React.useState(false);

    const { mutate: addStudentMutation, isLoading } = useMutation(
        studentServices.addEventStudent,
        {
            onSuccess: () => {
                onSave();
                enqueueSnackbar('Details submitted successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (student) => {
        // console.log(student);
        // return;

        try {
            addStudentMutation({ ...student, date_created: new Date() });
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelTokenCountries = axios.CancelToken.source();
        const cTokenCountries = { cancelToken: cancelTokenCountries.token };
        const fetchCountries = async () => {
            try {
                const res = await countriesServices.getAllCountries(
                    cTokenCountries
                );

                setCountries(res.countries);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchCountries();

        return () => {
            cancelTokenCountries.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenEducationlevels = axios.CancelToken.source();
        const cTokenEducationlevels = {
            cancelToken: cancelTokenEducationlevels.token
        };
        const fetchEducationlevels = async () => {
            try {
                const res = await educationlevelsServices.getAllEducationlevels(
                    cTokenEducationlevels
                );
                setQualifications(res.educationlevels);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchEducationlevels();

        return () => {
            cancelTokenEducationlevels.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenOffices = axios.CancelToken.source();
        const cTokenOffices = { cancelToken: cancelTokenOffices.token };
        const fetchOffices = async () => {
            try {
                const res = await officeServices.getAllOffices(cTokenOffices);
                let finalOffices = res.offices;

                if (
                    userDetails?.officeIds &&
                    userDetails?.role_name !== 'administrator'
                ) {
                    finalOffices = _.filter(res.offices, (office) =>
                        _.includes(userDetails.officeIds, office.id)
                    );
                }

                setOffices(finalOffices);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchOffices();

        return () => {
            cancelTokenOffices.cancel();
        };
    }, []);

    console.log('defaultValues');
    console.log(defaultValues);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {(!countries || !offices || !qualifications) && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            <Typography
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={'100%'}
                variant={'h3'}
            ></Typography>
            {countries && offices && qualifications && (
                <Formik
                    validateOnChange={true}
                    initialValues={defaultValues}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting, resetForm }) => {
                        await onCreate(data);
                        // resetForm();
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        setFieldValue,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            {/* <Div>{JSON.stringify(errors)}</Div> */}
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="given_name"
                                            name="given_name"
                                            label="Given Name"
                                            placeholder="Enter first name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="surname"
                                            name="surname"
                                            label="Surname"
                                            placeholder="Enter surname"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="email_address"
                                            name="email_address"
                                            label="Email Address"
                                            placeholder="Enter email address"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MuiTelInput
                                            fullWidth
                                            size={'small'}
                                            id="phone_number"
                                            name="phone_number"
                                            label="Phone Number"
                                            value={phone}
                                            placeholder="Enter phone number"
                                            preferredCountries={['KE', 'ZM']}
                                            defaultCountry="KE"
                                            onChange={(newValue) => {
                                                setPhone(newValue);
                                                values.phone_number = newValue;
                                            }}
                                        />
                                        {touched.phone_number &&
                                        errors.phone_number ? (
                                            <FormHelperText
                                                style={{
                                                    color: 'red'
                                                }}
                                            >
                                                {errors.phone_number}
                                            </FormHelperText>
                                        ) : null}
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <JumboRadioGroup
                                            row
                                            labelid="gender-row"
                                            label="Gender :"
                                            aria-labelledby="gender-row"
                                            name="gender"
                                        >
                                            <FormControlLabel
                                                value="male"
                                                control={<Radio size="small" />}
                                                label="Male"
                                            />
                                            <FormControlLabel
                                                value="female"
                                                control={<Radio size="small" />}
                                                label="Female"
                                            />
                                        </JumboRadioGroup>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="city_name"
                                            name="city_name"
                                            label="City Of Residense"
                                            placeholder="Enter city/town of residence"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboSelectField
                                            width="100%"
                                            size={'small'}
                                            id="preferred_course_level"
                                            name="preferred_course_level"
                                            label="Preferred Course Type"
                                            value={courseType}
                                            placeholder="Select preferred course type"
                                            onChange={(event) =>
                                                setCourseType(
                                                    event.target.value
                                                )
                                            }
                                        >
                                            <MenuItem
                                                key={'preferred_course_level'}
                                                value=""
                                            >
                                                <em>
                                                    Select Preferred Course Type
                                                </em>
                                            </MenuItem>
                                            {COURSE_TYPES.map(
                                                (preferred_course_level) => (
                                                    <MenuItem
                                                        key={
                                                            preferred_course_level.id
                                                        }
                                                        value={
                                                            preferred_course_level.id
                                                        }
                                                    >
                                                        {capitalizeAllWords(
                                                            preferred_course_level.category_name
                                                        )}
                                                    </MenuItem>
                                                )
                                            )}
                                        </JumboSelectField>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="preferred_course"
                                            name="preferred_course"
                                            label="Preferred Course"
                                            placeholder="Enter preferred course"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Field name="preferred_study_countryId">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    id="preferred_study_countryId"
                                                    sx={{
                                                        width: 500,
                                                        maxWidth: '100%'
                                                    }}
                                                    size="small"
                                                    options={countries}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.country_name
                                                            ? option.country_name
                                                            : ''
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                '& > img': {
                                                                    mr: 2,
                                                                    flexShrink: 0
                                                                }
                                                            }}
                                                            {...props}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                                                                alt=""
                                                            />
                                                            {
                                                                option?.country_name
                                                            }
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Preferred country of study"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.preferred_study_countryId &&
                                                                !!errors.preferred_study_countryId
                                                            }
                                                            helperText={
                                                                touched?.preferred_study_countryId &&
                                                                errors.preferred_study_countryId
                                                            }
                                                        />
                                                    )}
                                                    onChange={
                                                        (event, newValue) => {
                                                            values.preferred_study_countryId =
                                                                newValue?.id;
                                                            values.preferred_study_country =
                                                                newValue?.country_name;
                                                        }
                                                        // Set the new value in the form field
                                                    }
                                                    value={
                                                        countries.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.preferred_study_countryId
                                                        ) || null
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                    >
                                        Submit
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo studentItem, onSave prop define */
export default StudentCreateForm;
