import React from 'react';

import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import { useMutation } from 'react-query';
import { designationServices } from 'app/services/designation-services';

import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';

const validationSchema = yup.object({
    designation_name: yup
        .string('Enter designation name')
        .required('Designation name is required')
});

const CreateForm = ({ onSave, hideDialog }) => {
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: addDesignationMutation, isLoading } = useMutation(
        designationServices.addDesignation,
        {
            onSuccess: () => {
                onSave();
                enqueueSnackbar('Designation has been created successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (designation) => {
        try {
            addDesignationMutation({ ...designation });
        } catch (error) {}
    };

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            <Formik
                validateOnChange={true}
                initialValues={{
                    designation_name: '',
                    designation_description: ''
                }}
                validationSchema={validationSchema}
                onSubmit={async (data, { setSubmitting }) => {
                    await onCreate(data);
                }}
            >
                {({ isSubmitting, errors, touched, values, ...restProps }) => (
                    <Form
                        style={{ textAlign: 'left' }}
                        noValidate
                        autoComplete="off"
                    >
                        <Div sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="designation_name"
                                        name="designation_name"
                                        label="Designation Name"
                                        placeholder="Enter Designation Name"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="designation_description"
                                        name="designation_description"
                                        label="Designation Description"
                                        placeholder="Enter designation description"
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    margin: '30px auto'
                                }}
                                container
                                alignItems="right"
                                justify="flex-end"
                                justifyContent="right"
                            >
                                <Button
                                    size="large"
                                    sx={{ mb: 3 }}
                                    onClick={() => hideDialog()}
                                >
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isLoading}
                                >
                                    Save
                                </LoadingButton>
                            </Grid>
                        </Div>
                    </Form>
                )}
            </Formik>
        </Div>
    );
};
/* Todo designationItem, onSave prop define */
export default CreateForm;
