// import React from 'react';
// import JumboCardQuick from '@jumbo/components/JumboCardQuick';
// import {
//     List,
//     ListItem,
//     ListItemIcon,
//     ListItemText,
//     Typography
// } from '@mui/material';
// import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import Link from '@mui/material/Link';
// import Alert from '@mui/material/Alert';
// import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
// import EngineeringIcon from '@mui/icons-material/Engineering';

// const GuardianContacts = ({ studentDetails }) => {
//     return (
//         <JumboCardQuick title={`Guardian's Contacts`} noWrapper sx={{ mb: 1 }}>
//             {!studentDetails.guardian_contacts?.first_name &&
//                 !studentDetails.guardian_contacts?.last_name &&
//                 !studentDetails.guardian_contacts?.phone_number && (
//                     <Alert severity="info">No Info Available</Alert>
//                 )}{' '}
//             {(studentDetails.guardian_contacts?.first_name ||
//                 studentDetails.guardian_contacts?.last_name ||
//                 studentDetails.guardian_contacts?.phone_number) && (
//                 <List disablePadding sx={{ mb: 1 }}>
//                     <ListItem
//                         alignItems="flex-start"
//                         sx={{ p: (theme) => theme.spacing(0, 2) }}
//                     >
//                         <ListItemIcon
//                             sx={{ minWidth: 36, color: 'text.secondary' }}
//                         >
//                             <AccountCircleIcon />
//                         </ListItemIcon>
//                         <ListItemText
//                             primary={
//                                 <Typography
//                                     variant="body1"
//                                     color="text.secondary"
//                                 >
//                                     Name
//                                 </Typography>
//                             }
//                             secondary={
//                                 <Link variant="body1" href="#" underline="none">
//                                     {studentDetails?.guardian_contacts
//                                         ?.first_name +
//                                         ' ' +
//                                         studentDetails.guardian_contacts
//                                             ?.last_name}
//                                 </Link>
//                             }
//                         />
//                     </ListItem>
//                     <ListItem
//                         alignItems="flex-start"
//                         sx={{ p: (theme) => theme.spacing(0.25, 2) }}
//                     >
//                         <ListItemIcon
//                             sx={{ minWidth: 36, color: 'text.secondary' }}
//                         >
//                             <EmailOutlinedIcon />
//                         </ListItemIcon>
//                         <ListItemText
//                             primary={
//                                 <Typography
//                                     variant="body1"
//                                     color="text.secondary"
//                                 >
//                                     Email
//                                 </Typography>
//                             }
//                             secondary={
//                                 <Link variant="body1" href="#" underline="none">
//                                     {
//                                         studentDetails?.guardian_contacts
//                                             ?.email_address
//                                     }
//                                 </Link>
//                             }
//                         />
//                     </ListItem>

//                     <ListItem
//                         alignItems="flex-start"
//                         sx={{ p: (theme) => theme.spacing(0.25, 2) }}
//                     >
//                         <ListItemIcon
//                             sx={{ minWidth: 36, color: 'text.secondary' }}
//                         >
//                             <LocalPhoneOutlinedIcon />
//                         </ListItemIcon>
//                         <ListItemText
//                             primary={
//                                 <Typography
//                                     variant="body1"
//                                     color="text.secondary"
//                                 >
//                                     Phone
//                                 </Typography>
//                             }
//                             secondary={
//                                 <Typography
//                                     variant="body1"
//                                     color="text.primary"
//                                 >
//                                     {
//                                         studentDetails?.guardian_contacts
//                                             ?.phone_number
//                                     }
//                                 </Typography>
//                             }
//                         />
//                     </ListItem>
//                     <ListItem
//                         alignItems="flex-start"
//                         sx={{ p: (theme) => theme.spacing(0.25, 2) }}
//                     >
//                         <ListItemIcon
//                             sx={{ minWidth: 36, color: 'text.secondary' }}
//                         >
//                             <EngineeringIcon />
//                         </ListItemIcon>
//                         <ListItemText
//                             primary={
//                                 <Typography
//                                     variant="body1"
//                                     color="text.secondary"
//                                 >
//                                     Profession
//                                 </Typography>
//                             }
//                             secondary={
//                                 <Typography
//                                     variant="body1"
//                                     color="text.primary"
//                                 >
//                                     {
//                                         studentDetails?.guardian_contacts
//                                             ?.profession
//                                     }
//                                 </Typography>
//                             }
//                         />
//                     </ListItem>
//                 </List>
//             )}
//         </JumboCardQuick>
//     );
// };

// export default GuardianContacts;

import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BadgeIcon from '@mui/icons-material/Badge';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EngineeringIcon from '@mui/icons-material/Engineering';
// import { Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material';
// import { branches } from './data';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import { useTranslation } from 'react-i18next';

const GuardianContacts = ({ studentDetails }) => {
    return (
        <JumboCardQuick title={`Guardian's Contacts`} noWrapper>
            <List disablePadding>
                <ListItem
                    alignItems="flex-start"
                    sx={{ p: (theme) => theme.spacing(1.5, 3) }}
                >
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                        <BadgeIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            studentDetails?.guardian_contacts?.first_name ||
                            studentDetails?.guardian_contacts?.last_name ? (
                                `${
                                    studentDetails?.guardian_contacts
                                        ?.first_name || ''
                                }  ${
                                    studentDetails.guardian_contacts
                                        ?.last_name || ''
                                } `
                            ) : (
                                <em>Not Available</em>
                            )
                        }
                    />
                </ListItem>
                <Divider component="li" />
                <ListItem sx={{ p: (theme) => theme.spacing(1.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                        <PhoneIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            studentDetails?.guardian_contacts?.phone_number || (
                                <em>Not Available</em>
                            )
                        }
                    />
                </ListItem>
                <Divider component="li" />
                <ListItem sx={{ p: (theme) => theme.spacing(1.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                        <MailOutlineIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            studentDetails?.guardian_contacts?.email_address ||
                            ''
                        }
                    />
                </ListItem>
                <Divider component="li" />
                <ListItem sx={{ p: (theme) => theme.spacing(1.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                        <EngineeringIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            studentDetails?.guardian_contacts?.profession || (
                                <em>Not Available</em>
                            )
                        }
                    />
                </ListItem>
            </List>
            {/* <Stack direction="row" spacing={1} sx={{p: theme => theme.spacing(3), mb: .625}}>
                <Fab
                    size="small"
                    aria-label="Twitter"
                    sx={{
                        color: 'common.white',
                        bgcolor: '#2196f3',

                        '&:hover': {
                            bgcolor: '#2196f3'
                        }
                    }}
                >
                    <Twitter/>
                </Fab>
                <Fab
                    size="small"
                    aria-label="Facebook"
                    sx={{
                        color: 'common.white',
                        bgcolor: '#3f51b5',

                        '&:hover': {
                            bgcolor: '#3f51b5'
                        }
                    }}
                >
                    <Facebook/>
                </Fab>
                <Fab
                    size="small"
                    aria-label="Instagram"
                    sx={{
                        color: 'common.white',
                        bgcolor: '#e91e63',

                        '&:hover': {
                            bgcolor: '#e91e63'
                        }
                    }}
                >
                    <Instagram/>
                </Fab>
                <Fab
                    size="small"
                    aria-label="LinkedIn"
                    sx={{
                        color: 'common.white',
                        bgcolor: '#2196f3',

                        '&:hover': {
                            bgcolor: '#2196f3'
                        }
                    }}
                >
                    <LinkedIn/>
                </Fab>
            </Stack> */}
        </JumboCardQuick>
    );
};

export default GuardianContacts;
