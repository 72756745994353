import React from 'react';

import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import { useMutation } from 'react-query';
import { countriesServices } from 'app/services/countries-services';
import { requirementServices } from 'app/services/requirement-services';

import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import _ from 'lodash';

const validationSchema = yup.object({
    country_name: yup
        .string('Enter country name')
        .required('Country name is required'),
    visa_fee: yup.string('Enter visa fee').required('Visa Fee is required'),
    visa_fee_currency: yup
        .string('Select currency')
        .required('Currency is required'),

    visa_requirements: yup.array().min(1, 'Select at least one option')
});

const EditForm = ({ listItem, onUpdate, hideDialog }) => {
    const [countries, setCountries] = React.useState(false);
    const [requirements, setRequirements] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [defaultFeeCurrency, setDefaultFeeCurrency] = React.useState(false);

    const [defaultRequirements, setDefaultRequirements] = React.useState(false);

    const { mutate: editCountryMutation, isLoading } = useMutation(
        countriesServices.updateCountry,
        {
            onSuccess: () => {
                onUpdate();

                enqueueSnackbar('Country has been updated successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onEdit = async (country) => {
        try {
            editCountryMutation({ id: listItem.id, ...country });
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelTokenCountries = axios.CancelToken.source();
        const cTokenCountries = { cancelToken: cancelTokenCountries.token };
        const fetchCountries = async () => {
            try {
                const res = await countriesServices.getAllCountries(
                    cTokenCountries
                );

                setCountries(res.countries);

                const selected = listItem?.visa_fee_currency
                    ? _.find(res.countries, {
                          id: listItem?.visa_fee_currency?.id
                      })
                    : _.find(res.countries, { country_name: 'United States' });

                setDefaultFeeCurrency(selected);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchCountries();

        return () => {
            cancelTokenCountries.cancel();
        };
    }, [listItem, setDefaultFeeCurrency]);

    React.useEffect(() => {
        const cancelTokenRequirements = axios.CancelToken.source();
        const cTokenRequirements = {
            cancelToken: cancelTokenRequirements.token
        };
        const fetchRequirements = async () => {
            try {
                const res = await requirementServices.getAllRequirements(
                    cTokenRequirements
                );

                setRequirements(res.requirements);

                const reqs = listItem?.visa_requirements
                    ? _.filter(res.requirements, (obj) =>
                          _.includes(
                              _.map(listItem.visa_requirements, 'id'),
                              obj.id
                          )
                      )
                    : [];

                setDefaultRequirements(reqs);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchRequirements();

        return () => {
            cancelTokenRequirements.cancel();
        };
    }, [listItem, setDefaultRequirements]);

    // const handleChange = (event, value, field, form) => {
    //     form.setFieldValue(field.name, value);
    // };

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {(!countries ||
                !requirements ||
                !defaultFeeCurrency ||
                !defaultRequirements) && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {countries &&
                requirements &&
                defaultFeeCurrency &&
                defaultRequirements && (
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            country_name: listItem.country_name,
                            visa_fee: listItem.visa_fee || '',
                            visa_fee_currency: defaultFeeCurrency?.id || '',
                            visa_requirements: defaultRequirements
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (data, { setSubmitting }) => {
                            await onEdit(data);
                        }}
                    >
                        {({
                            isSubmitting,
                            errors,
                            touched,
                            values,
                            form,
                            ...restProps
                        }) => (
                            <Form
                                style={{ textAlign: 'left' }}
                                noValidate
                                autoComplete="off"
                            >
                                <Div sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <JumboTextField
                                                fullWidth
                                                size={'small'}
                                                id="country_name"
                                                name="country_name"
                                                label="Country Name"
                                                placeholder="Enter country name"
                                                readOnly
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <JumboTextField
                                                fullWidth
                                                size={'small'}
                                                id="visa_fee"
                                                name="visa_fee"
                                                label="Visa Fee"
                                                type="number"
                                                placeholder="Enter visa fee"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Field name="visa_fee_currency">
                                                {({ field }) => (
                                                    <Autocomplete
                                                        {...field}
                                                        id="visa_fee_currency"
                                                        sx={{
                                                            width: 500,
                                                            maxWidth: '100%'
                                                        }}
                                                        size="small"
                                                        options={countries}
                                                        autoHighlight
                                                        getOptionLabel={(
                                                            option
                                                        ) =>
                                                            option?.currency
                                                                ? option.currency +
                                                                  ' - ' +
                                                                  option.country_name
                                                                : ''
                                                        }
                                                        isOptionEqualToValue={(
                                                            option,
                                                            value
                                                        ) =>
                                                            option.id ===
                                                            value.id
                                                        }
                                                        renderOption={(
                                                            props,
                                                            option
                                                        ) => (
                                                            <Box
                                                                component="li"
                                                                sx={{
                                                                    '& > img': {
                                                                        mr: 2,
                                                                        flexShrink: 0
                                                                    }
                                                                }}
                                                                {...props}
                                                            >
                                                                <img
                                                                    loading="lazy"
                                                                    width="20"
                                                                    src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                                                                    srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                                                                    alt=""
                                                                />
                                                                {option.currency +
                                                                    ' - ' +
                                                                    option.country_name}
                                                            </Box>
                                                        )}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Choose a currency"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    autoComplete:
                                                                        'new-password'
                                                                }}
                                                                error={
                                                                    touched?.visa_fee_currency &&
                                                                    !!errors.visa_fee_currency
                                                                }
                                                                helperText={
                                                                    touched?.visa_fee_currency &&
                                                                    errors.visa_fee_currency
                                                                }
                                                            />
                                                        )}
                                                        onChange={
                                                            (
                                                                event,
                                                                newValue
                                                            ) => {
                                                                values.visa_fee_currency =
                                                                    newValue?.id;
                                                            }
                                                            // Set the new value in the form field
                                                        }
                                                        value={
                                                            countries.find(
                                                                (option) =>
                                                                    option.id ===
                                                                    values.visa_fee_currency
                                                            ) || ''
                                                        }
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Field name="visa_requirements">
                                                {({ field }) => (
                                                    <Autocomplete
                                                        {...field}
                                                        multiple
                                                        id="visa_requirements"
                                                        size={'small'}
                                                        options={requirements}
                                                        getOptionLabel={(
                                                            option
                                                        ) => {
                                                            return (
                                                                option.requirement_name ||
                                                                ''
                                                            );
                                                        }}
                                                        renderOption={(
                                                            props,
                                                            option
                                                        ) => (
                                                            <Box
                                                                component="li"
                                                                sx={{
                                                                    '& > img': {
                                                                        mr: 2,
                                                                        flexShrink: 0
                                                                    }
                                                                }}
                                                                {...props}
                                                            >
                                                                {
                                                                    option.requirement_name
                                                                }
                                                            </Box>
                                                        )}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Choose Visa Requirements for this country"
                                                                placeholder="Visa Requirements"
                                                                error={
                                                                    !!errors.visa_requirements
                                                                }
                                                                helperText={
                                                                    errors.visa_requirements
                                                                }
                                                            />
                                                        )}
                                                        onChange={(
                                                            event,
                                                            newValue
                                                        ) =>
                                                            field.onChange({
                                                                target: {
                                                                    name: field.name,
                                                                    value: newValue
                                                                }
                                                            })
                                                        }
                                                        value={
                                                            values.visa_requirements
                                                        }
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        sx={{
                                            margin: '30px auto'
                                        }}
                                        container
                                        alignItems="right"
                                        justify="flex-end"
                                        justifyContent="right"
                                    >
                                        <Button
                                            size="large"
                                            sx={{ mb: 3 }}
                                            onClick={() => hideDialog()}
                                        >
                                            Cancel
                                        </Button>
                                        <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{ mb: 3 }}
                                            loading={isLoading}
                                        >
                                            Update
                                        </LoadingButton>
                                    </Grid>
                                </Div>
                            </Form>
                        )}
                    </Formik>
                )}
        </Div>
    );
};
/* Todo countryItem, onSave prop define */
export default EditForm;
