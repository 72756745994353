import React from 'react';
import _ from 'lodash';
import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import { useMutation } from 'react-query';
import { leadsServices } from 'app/services/leads-services';
import { studentServices } from 'app/services/student-services';

import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import axios from 'axios';
import { capitalizeAllWords } from 'app/utils/appHelpers';

const validationSchema = yup.object({
    studentId: yup.string('Slect student').required('Student is required')
});

const CreateForm = ({ onSave, hideDialog, userDetails }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [students, setStudents] = React.useState(false);
    const [studentId, setStudentId] = React.useState(false);

    const { mutate: addLeadMutation, isLoading } = useMutation(
        leadsServices.addLead,
        {
            onSuccess: () => {
                onSave();
                enqueueSnackbar('Lead has been created successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (lead) => {
        try {
            addLeadMutation({ ...lead });
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelTokenStudents = axios.CancelToken.source();
        const cTokenStudents = { cancelToken: cancelTokenStudents.token };
        const fetchStudents = async () => {
            try {
                const res = await studentServices.getAllStudents(
                    cTokenStudents
                );
                let finalStudents = res.studentsList;

                console.log('res------->');
                console.log(res);

                setStudents(finalStudents);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchStudents();

        return () => {
            cancelTokenStudents.cancel();
        };
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {!students && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {students && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        studentId: studentId
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onCreate(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <JumboSelectField
                                            width="100%"
                                            size={'small'}
                                            id="studentId"
                                            name="studentId"
                                            label="Select Student"
                                            value={''}
                                            placeholder="Select student"
                                            onChange={(event) =>
                                                setStudentId(event.target.value)
                                            }
                                        >
                                            <MenuItem
                                                key={'studentId'}
                                                value=""
                                            >
                                                <em>Select Student</em>
                                            </MenuItem>

                                            {students.map((student) => (
                                                <MenuItem
                                                    key={student.id}
                                                    value={student.id}
                                                >
                                                    {student.given_name}{' '}
                                                    {student.surname}
                                                </MenuItem>
                                            ))}
                                        </JumboSelectField>
                                    </Grid>
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                    >
                                        Create Lead
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo studentItem, onSave prop define */
export default CreateForm;
