import React from 'react';
import { recentActivities } from './data';
import ActivitiesItem from './ActivitiesItem';
import { Timeline } from '@mui/lab';
import Alert from '@mui/material/Alert';

const ActivitiesList = ({ request, authUser }) => {
    const approvalTrail = [...request?.audit_trail].reverse();
    return (
        <>
            {(!request?.audit_trail || request?.audit_trail.length === 0) && (
                <Alert severity="info" sx={{ marginX: '16px' }}>
                    Pending Approvals
                </Alert>
            )}
            {request?.audit_trail && (
                <Timeline sx={{ m: 0, p: (theme) => theme.spacing(0, 3) }}>
                    {request?.audit_trail.length > 0 &&
                        approvalTrail.map((item, index) => (
                            <ActivitiesItem
                                item={item}
                                key={index}
                                authUser={authUser}
                            />
                        ))}
                </Timeline>
            )}
        </>
    );
};

export default ActivitiesList;
