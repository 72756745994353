import React, { useCallback, useState, useEffect } from 'react';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'app/AppAbility';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder';
import JumboListWrapper from '@jumbo/components/JumboList/components/JumboListWrapper';
import NotAllowed from 'app/shared/NotAllowed';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import _ from 'lodash';

import Slide from '@mui/material/Slide';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import { Add } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CalendarWrapper from './CalendarWrapper';
import { useTranslation } from 'react-i18next';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useSnackbar } from 'notistack';

import { calendarData } from './data';
import { timeoffsServices } from 'app/services/timeoffs-services';
import Detail from './Detail/Detail';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from 'react-query';

import CreateForm from './CreateForm';
import HolidayForm from './HolidayForm';

import { useTheme } from '@mui/material/styles';

// const { events } = calendarData;
const today = new Date();
const currentYear = today.getFullYear();
const currentMonth = today.getMonth();
const currentDate = today.getDate();

const localizer = momentLocalizer(moment);

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const TimeOffCalendar = () => {
    const theme = useTheme(); // Access MUI theme

    const [events, setEvents] = useState([]);

    const { enqueueSnackbar } = useSnackbar();
    const { authUser } = useJumboAuth();
    const { hideDialog, showDialog } = useJumboDialog();

    const ability = useAbility(AbilityContext);
    const allowReadList = ability.can('read', 'Timeoffs');
    const allowRead = ability.can('read', 'Timeoff');
    const allowAdd = ability.can('create', 'Timeoff');
    const allowBulkDel = ability.can('delete', 'Timeoff');
    const allowConfigure = ability.can('configure', 'Timeoffs');

    const { t } = useTranslation();
    // console.log(calendarData);
    const refreshEvents = useCallback(async () => {
        try {
            const res = await timeoffsServices.getAllTimeoff();

            // console.log('res');
            // console.log(res);

            if (res?.timeoffs) {
                const loggedInUserId = authUser?.userId;
                const filteredTimeoffs = _.filter(res.timeoffs, (timeoff) => {
                    // Filter out timeoffs if rejected and not belonging to the logged-in user
                    return (
                        !(
                            timeoff.rejected &&
                            timeoff.userId.id !== loggedInUserId
                        ) || timeoff.approved
                    );
                });

                const timeoffs = _.map(
                    filteredTimeoffs,
                    ({
                        id,
                        userId,
                        public_holiday,
                        holiday_name,
                        leavetypeId,
                        no_of_days,
                        start_date,
                        end_date,
                        approved,
                        rejected,
                        approver
                    }) => ({
                        requestId: id,
                        title: public_holiday
                            ? `${holiday_name} ${moment(start_date).format(
                                  'MMMM DD'
                              )}`
                            : `${leavetypeId.leave_type_name} - ${userId.first_name} ${userId.last_name}`,
                        eventOwnerId: `${userId.id}`,
                        eventOwner: `${userId.first_name} ${userId.last_name}`,
                        eventName: public_holiday
                            ? `${holiday_name}`
                            : `${leavetypeId.leave_type_name}`,
                        eventPeriod: `${no_of_days === 0 ? 1 : no_of_days}`,
                        allDay: true,
                        start: start_date,
                        public_holiday: public_holiday ? true : false,
                        end: end_date,
                        approved,
                        rejected,
                        approver
                    })
                );

                // console.log(timeoffs);
                setEvents(timeoffs);
            }
        } catch (error) {
            enqueueSnackbar(error, {
                variant: 'error'
            });
        }
    }, []);

    const handleCreateForm = React.useCallback(
        (data) => {
            showDialog({
                title: 'Request Leave',
                content: (
                    <CreateForm
                        // onSave={handleRequestTimeoff}
                        hideDialog={hideDialog}
                        refreshEvents={refreshEvents}
                        start={data.start}
                        end={data.end}
                    />
                ),
                maxWidth: 'sm',
                TransitionComponent: Transition,
                disableDefaultClose: true,
                closeAction: true
            });
        },
        [showDialog]
    );

    const handleHolidayForm = React.useCallback(
        (data) => {
            showDialog({
                title: 'Add Public Holiday',
                content: (
                    <HolidayForm
                        // onSave={handleRequestTimeoff}
                        hideDialog={hideDialog}
                        refreshEvents={refreshEvents}
                        start={data.start}
                        end={data.end}
                    />
                ),
                maxWidth: 'sm',
                TransitionComponent: Transition,
                disableDefaultClose: true,
                closeAction: true
            });
        },
        [showDialog]
    );

    const showEventDetails = React.useCallback(
        (event) => {
            showDialog({
                content: (
                    <Detail
                        loggedInUser={authUser}
                        item={event}
                        onClose={hideDialog}
                        refreshEvents={refreshEvents}
                        hideDialog={hideDialog}
                    />
                )
            });
        },
        [showDialog]
    );

    const { mutate: updateHolidaysMutation, isLoading: isUpdatingHolidays } =
        useMutation(timeoffsServices.updatePublicHolidays, {
            onSuccess: () => {
                enqueueSnackbar('Public holidays updated successfully.', {
                    variant: 'success'
                });

                refreshEvents();
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        });

    const handleUpdateHolidays = async () => {
        console.log('Update Holidays');
        updateHolidaysMutation();
    };

    useEffect(() => {
        refreshEvents();
    }, []);

    if (!allowReadList) {
        return (
            <JumboListWrapper
                component={Card}
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <JumboListNoDataPlaceholder>
                    <NotAllowed />
                </JumboListNoDataPlaceholder>
            </JumboListWrapper>
        );
    }

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const eventPropGetter = (event) => {
        const today = new Date();
        const eventEndDate = new Date(event.end);

        // Determine the background color based on the event's status and date
        let backgroundColor;

        if (eventEndDate < today) {
            // Event is in the past
            backgroundColor = 'grey';
        } else {
            // Event is not in the past
            const backgroundColor = event.approved
                ? theme.palette.success.main
                : event.rejected
                ? theme.palette.success.main
                : theme.palette.warning.main;
        }

        return { style: { backgroundColor } };
    };

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'
                    // background: '#333'
                }}
            >
                <Typography variant={'h1'} mb={3}>
                    Leave Calendar
                </Typography>
                <Div>
                    {allowAdd && (
                        <Button
                            disableElevation
                            variant={'contained'}
                            startIcon={<Add />}
                            sx={{
                                mb: 3,
                                '& .MuiSvgIcon-root': {
                                    fontSize: '1.5rem'
                                }
                            }}
                            onClick={() => handleCreateForm(tomorrow, tomorrow)}
                        >
                            Request Leave
                        </Button>
                    )}
                    {allowConfigure && (
                        <>
                            <LoadingButton
                                disableElevation
                                variant={'contained'}
                                startIcon={<CalendarTodayIcon />}
                                sx={{
                                    mb: 3,
                                    ml: 3,
                                    '& .MuiSvgIcon-root': {
                                        fontSize: '1.5rem'
                                    }
                                }}
                                loading={isUpdatingHolidays}
                                color="info"
                                onClick={handleHolidayForm}
                            >
                                Add Holiday
                            </LoadingButton>
                            <LoadingButton
                                disableElevation
                                variant={'contained'}
                                startIcon={<CalendarTodayIcon />}
                                sx={{
                                    mb: 3,
                                    ml: 3,
                                    '& .MuiSvgIcon-root': {
                                        fontSize: '1.5rem'
                                    }
                                }}
                                loading={isUpdatingHolidays}
                                color="info"
                                onClick={handleUpdateHolidays}
                            >
                                Add/Update Holidays (Bulk)
                            </LoadingButton>
                        </>
                    )}
                </Div>
            </Box>
            <Card>
                <CardContent>
                    <CalendarWrapper>
                        <Calendar
                            localizer={localizer}
                            events={events}
                            selectable
                            defaultView="month"
                            scrollToTime={new Date(1970, 1, 1, 6)}
                            defaultDate={
                                new Date(currentYear, currentMonth, currentDate)
                            }
                            onSelectEvent={(event) => showEventDetails(event)}
                            onSelectSlot={(slotInfo) => {
                                allowAdd &&
                                    handleCreateForm({
                                        start: slotInfo.start,
                                        end: slotInfo.end
                                    });
                            }}
                            eventPropGetter={eventPropGetter}
                            style={{ height: 600 }}
                        />
                    </CalendarWrapper>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default TimeOffCalendar;
