import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import RefreshIcon from '@mui/icons-material/Refresh';

const ExpenseStatus = [
    {
        id: 0,
        status: 'Cancelled',
        name: 'Cancelled',
        slug: 'Cancelled',
        color: '#ffd0cd',
        type: 'default',
        icon: <CancelIcon />
    },
    {
        id: 1,
        status: 'Awaiting Expense',
        name: 'Awaiting Expense',
        slug: 'Awaiting Expense',
        color: '#ffd0cd',
        type: 'warning',
        icon: <HourglassEmptyIcon />
    },
    {
        id: 1.4,
        status: 'Advance Review',
        name: 'Advance Review',
        slug: 'Advance Review',
        color: '#ffd0cd',
        type: 'warning',
        icon: <HourglassEmptyIcon />
    },
    {
        id: 1.5,
        status: 'Advance Disbursement',
        name: 'Advance Disbursement',
        slug: 'Advance Disbursement',
        color: '#ffd0cd',
        type: 'warning',
        icon: <HourglassEmptyIcon />
    },
    {
        id: 1.6,
        status: 'Advance Disbursed',
        name: 'Advance Disbursed',
        slug: 'Advance Disbursed',
        color: '#cdffcf',
        type: 'success',
        icon: <HourglassEmptyIcon />
    },
    {
        id: 1.7,
        status: 'Advance Rejected',
        name: 'Advance Rejected',
        slug: 'Advance Rejected',
        color: '#ffd0cd',
        type: 'error',
        icon: <HourglassEmptyIcon />
    },
    {
        id: 2,
        status: 'Expense Review',
        name: 'Expense Review',
        slug: 'Expense Review',
        color: '#ffd0cd',
        type: 'warning',
        icon: <RefreshIcon />
    },

    {
        id: 3,
        status: 'Report Rejected',
        name: 'Report Rejected',
        slug: 'Report Rejected',
        color: '#ffd0cd',
        type: 'error',
        icon: <ClearIcon />
    },
    {
        id: 4,
        status: 'Expense Reimbursement',
        name: 'Expense Reimbursement',
        slug: 'Expense Reimbursement',
        color: '#cdffcf',
        type: 'warning',
        icon: <CheckCircleIcon />
    },
    {
        id: 5,
        status: 'Advance Reimbursement',
        name: 'Advance Reimbursement',
        slug: 'Advance Reimbursement',
        color: '#ffd0cd',
        type: 'warning',
        icon: <CheckCircleIcon />
    },
    {
        id: 6,
        status: 'Closed',
        name: 'Closed',
        slug: 'Closed',
        color: '#cdffcf',
        type: 'success',
        icon: <CheckCircleIcon />
    }
];

export default ExpenseStatus;
