import React from 'react';
import { config } from '../config/main';
import { Navigate } from 'react-router-dom';
import dashboardRoutes from './dashboardRoutes';

// import listViewRoutes from './listViewRoutes';
import homeRoutes from './homeRoutes';
import managementRoutes from './managementRoutes';
import settingsRoutes from './settingsRoutes';
import JumboUtils from '../services/auth/JumboUtils';
import metricsRoutes from './metricsRoutes';
import widgetsRoutes from './widgetRoutes';
import calendarRoutes from './calendarRoutes';
import chartRoutes from './chartRoutes';
import mapRoutes from './mapRoutes';
import extensionsRoutes from './extensionsRoutes';
import appsRoutes from './appsRoutes';
import { muiRoutes } from './muiRoutes';
import authRoutes from './authRoutes';
import userRoutes from './userRoutes';

// import gridViewRoutes from './gridViewRoutes';
import Login2 from '../pages/auth-pages/login2';
import Signup2 from '../pages/auth-pages/signup2';
import ResetPassword from '../pages/auth-pages/reset-password';
import Reset from '../pages/auth-pages/reset';

import Survey from '../pages/public/enrollment-survey';
import TrainingSurvey from '../pages/public/training-survey';

import Page from '@jumbo/shared/Page';

import Error404 from '../pages/Error404';

import { buildRoutes } from '@jumbo/utils';

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
    {
        path: '/error/404', //Put page not found page here
        element: <Page component={Error404} layout={'solo-page'} />
    },
    {
        path: '*', //Put page not found page here
        element: <Page component={Error404} layout={'solo-page'} />
    }
];

/**
 routes only accessible to authenticated users
 **/

/********************************* Amendments *********************************/
const joinedRoutes = [
    ...JumboUtils.generateRoutesFromConfigs(
        [
            dashboardRoutes,
            homeRoutes,
            managementRoutes,
            appsRoutes,
            settingsRoutes,
            userRoutes
        ],
        config.defaultAuth
    )
];

const routesForAuthenticatedOnly = [
    {
        path: '/',
        element: <Navigate to={'/dashboards/overview'} />
    },
    ...joinedRoutes
];

console.log('routesForAuthenticatedOnly - routes - index');
console.log([...routesForAuthenticatedOnly]);

/********************************* Amendments *********************************/

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    {
        path: '/iec-training-survey/:timeCreated?/:studentId?/:leadId?/:eventId?',
        element: (
            <Page
                component={TrainingSurvey}
                layout={'solo-page'}
                disableSmLogin={true}
            />
        )
    },
    {
        path: '/students-survey/:timeCreated?/:studentId?/:leadId?',
        element: (
            <Page
                component={Survey}
                layout={'solo-page'}
                disableSmLogin={true}
            />
        )
    },
    {
        path: '/user/login',
        element: (
            <Page
                component={Login2}
                layout={'solo-page'}
                disableSmLogin={true}
            />
        )
    },
    {
        path: '/user/signup',
        element: (
            <Page
                component={Signup2}
                layout={'solo-page'}
                disableSmLogin={true}
            />
        )
    },
    {
        path: '/user/reset-password',
        element: (
            <Page
                component={ResetPassword}
                layout={'solo-page'}
                disableSmLogin={true}
            />
        )
    },
    {
        path: '/user/reset/:email_address/:token/*',
        element: (
            <Page
                component={Reset}
                layout={'solo-page'}
                disableSmLogin={true}
            />
        )
    }
];

const routes = [
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
    ...routesForPublic
];

export {
    routes as default,
    routesForPublic,
    routesForNotAuthenticatedOnly,
    routesForAuthenticatedOnly
};
