import React from 'react';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'app/AppAbility';
import { ListItemText, Tooltip, Typography } from '@mui/material';
import Div from '@jumbo/shared/Div';
import styled from '@emotion/styled';
import Span from '@jumbo/shared/Span';
import Stack from '@mui/material/Stack';
import Slide from '@mui/material/Slide';
import Edit from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { educationlevelsServices } from 'app/services/educationlevels-services';
import { useMutation } from 'react-query';

import moment from 'moment';
import useEducationlevelsApp from '../../hooks/useEducationlevelsApp';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import EditForm from '../EditForm';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import { useSnackbar } from 'notistack';

import EducationlevelDetail from '../Detail';

const Item = styled(Span)(({ theme }) => ({
    padding: theme.spacing(0, 1)
}));

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const ListItem = ({ listItem }) => {
    const { theme } = useJumboTheme();
    const { setListRefresh } = useEducationlevelsApp();
    const { showDialog, hideDialog } = useJumboDialog();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const ability = useAbility(AbilityContext);
    const allowRead = ability.can('read', 'Educationlevel');
    const allowEdit = ability.can('update', 'Educationlevel');
    const allowDelete = ability.can('delete', 'Educationlevel');

    const deleteMutation = useMutation(
        educationlevelsServices.deleteEducationlevel,
        {
            onSuccess: () => {
                hideDialog();
                setListRefresh(true);
                enqueueSnackbar(
                    'Education level has been deleted successfully.',
                    {
                        variant: 'success'
                    }
                );
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const itemMoveToTrash = React.useCallback(
        (educationlevelsId) => {
            showDialog({
                variant: 'confirm',
                title: 'Delete!',
                content: 'Are you sure? Select Yes to Continue',
                onYes: () => {
                    deleteMutation.mutate(educationlevelsId);
                },
                onNo: hideDialog
            });
        },
        [deleteMutation, hideDialog]
    );

    const handleUpdatedItem = React.useCallback(() => {
        hideDialog();
        setListRefresh(true);
    }, [hideDialog]);

    const handleUpdateForm = (event) => {
        showDialog({
            title: 'Edit Education Level',
            content: (
                <EditForm
                    listItem={listItem}
                    onUpdate={handleUpdatedItem}
                    hideDialog={hideDialog}
                />
            ),
            maxWidth: 'sm',
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true
        });
    };

    const showEducationlevelDetails = React.useCallback(() => {
        showDialog({
            content: (
                <EducationlevelDetail item={listItem} onClose={hideDialog} />
            )
        });
    }, [showDialog, listItem]);

    return (
        <React.Fragment>
            <JumboListItem
                componentElement={'div'}
                itemData={listItem}
                secondaryAction={null}
                sx={{
                    cursor: 'pointer',
                    borderTop: 1,
                    borderColor: 'divider',
                    '&:hover': {
                        bgcolor: 'action.hover',

                        '& .ListAction': {
                            width: { sm: '100%' },
                            opacity: { sm: 1 }
                        },

                        '& .ListTextExtra': {
                            visibility: { sm: 'hidden' },
                            opacity: { sm: 0 }
                        }
                    }
                }}
            >
                <ListItemText
                    onClick={showEducationlevelDetails}
                    primary={
                        <Typography variant={'body1'} component={'div'}>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                sx={{ minWidth: 0 }}
                            >
                                <Item
                                    sx={{
                                        flexBasis: {
                                            xs: '70%',
                                            sm: '80%',
                                            md: '80%'
                                        }
                                        // background: '#333'
                                    }}
                                >
                                    <Typography
                                        variant={'h5'}
                                        fontSize={14}
                                        lineHeight={1.25}
                                        mb={0}
                                        noWrap
                                    >
                                        {listItem?.education_level_name}
                                    </Typography>
                                </Item>
                            </Stack>
                        </Typography>
                    }
                />
                <Div
                    sx={{
                        width: 160,
                        display: 'flex',
                        flexShrink: '0',
                        position: 'relative',
                        justifyContent: 'flex-end',

                        [theme.breakpoints.down('sm')]: {
                            width: '100%',
                            justifyContent: 'space-between'
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            flex: 1,
                            transition: 'all 0.5s ease',

                            [theme.breakpoints.down('sm')]: {
                                justifyContent: 'flex-start',
                                ml: 6
                            }
                        }}
                        className={'ListTextExtra'}
                    >
                        <JumboChipsGroup
                            chips={
                                listItem?.active
                                    ? [
                                          {
                                              id: 1,
                                              name: 'Active',
                                              slug: 'active',
                                              color: '#E0CDFF'
                                          }
                                      ]
                                    : [
                                          {
                                              id: 1,
                                              name: 'Inactive',
                                              slug: 'inactive',
                                              color: '#ca404f'
                                          }
                                      ]
                            }
                            mapKeys={{ label: 'name' }}
                            spacing={1}
                            size={'small'}
                            max={1}
                        />

                        <Typography
                            variant={'body1'}
                            color={'text.secondary'}
                            ml={1}
                        >
                            {moment(listItem?.date_created).format('MMMM DD')}
                        </Typography>
                    </Div>
                    <Div
                        className="ListAction"
                        sx={{
                            display: 'flex',

                            [theme.breakpoints.up('sm')]: {
                                position: 'absolute',
                                top: '50%',
                                width: 0,
                                opacity: 0,
                                overflow: 'hidden',
                                transition: 'all 0.5s ease',
                                justifyContent: 'flex-end',
                                transform: 'translateY(-50%)'
                            }
                        }}
                    >
                        <Span sx={{ height: 36, overflow: 'hidden' }}>
                            {allowRead && (
                                <Tooltip title="View Details">
                                    <IconButton
                                        onClick={showEducationlevelDetails}
                                    >
                                        <VisibilityIcon fontSize={'small'} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {allowEdit && (
                                <Tooltip title="Edit">
                                    <IconButton onClick={handleUpdateForm}>
                                        <Edit fontSize={'small'} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {allowDelete && (
                                <Tooltip title="Delete">
                                    <IconButton
                                        onClick={() =>
                                            itemMoveToTrash(listItem?.id)
                                        }
                                    >
                                        <DeleteIcon
                                            fontSize={'small'}
                                            sx={{ color: '#ca404f' }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Span>
                    </Div>
                </Div>
            </JumboListItem>
        </React.Fragment>
    );
};

export default ListItem;
