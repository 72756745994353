import React from 'react';

import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import JumboRadioGroup from '@jumbo/components/JumboFormik/JumboRadioGroup';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useMutation } from 'react-query';
import { reimbursementsServices } from 'app/services/reimbursements-services';
import { countriesServices } from 'app/services/countries-services';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import axios from 'axios';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import _ from 'lodash';

const validationSchema = yup.object({
    // reimbursement_transaction_id: yup
    //     .string('Select travel reimbursement_transaction_id name')
    //     .required('Comment is required')
});
const IssueForm = ({ onSave, reimbursement, hideDialog }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [countries, setCountries] = React.useState(false);
    const [requiredDate, setRequiredDate] = React.useState(dayjs(new Date()));

    const { mutate: submitIssueMutation, isLoading } = useMutation(
        reimbursementsServices.issueReimbursement,
        {
            onSuccess: () => {
                enqueueSnackbar('Advance has been issued successfully.', {
                    variant: 'success'
                });
                onSave();
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(
                    error?.message ? error.message : error.toString(),
                    {
                        variant: 'error'
                    }
                );

                return false;
            }
        }
    );

    const onIssue = async (data) => {
        try {
            submitIssueMutation({
                reimbursementId: reimbursement.id,
                reimbursement_transaction_id: data.reimbursement_transaction_id,
                reimbursed_amount: data.reimbursed_amount,
                date_reimbursed: data.date_reimbursed
            });
        } catch (error) {}
    };
    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto'
            }}
        >
            <Formik
                validateOnChange={true}
                initialValues={{
                    reimbursement_transaction_id: '',
                    reimbursed_amount: reimbursement.reimbursement_amount,
                    date_reimbursed: requiredDate
                }}
                validationSchema={validationSchema}
                onSubmit={async (data, { setSubmitting }) => {
                    await onIssue(data);
                }}
            >
                {({
                    isSubmitting,
                    errors,
                    touched,
                    values,
                    setFieldValue,
                    setFieldError,
                    ...restProps
                }) => (
                    <Form
                        style={{ textAlign: 'left' }}
                        noValidate
                        autoComplete="off"
                    >
                        <Div sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        // sx={{
                                        //     marginTop: '8px'
                                        // }}
                                        id="reimbursement_transaction_id"
                                        name="reimbursement_transaction_id"
                                        label="Enter Transaction ID"
                                        placeholder="Enter Reimbursement Transaction ID"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        // sx={{
                                        //     marginTop: '8px'
                                        // }}
                                        id="reimbursed_amount"
                                        name="reimbursed_amount"
                                        label="Amount Reimbursed"
                                        type="number"
                                        placeholder="Enter reimbursed amount"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <MobileDatePicker
                                            label="Reimbursement Date"
                                            inputFormat="YYYY-MM-DD"
                                            disablePast={true}
                                            closeOnSelect={true}
                                            value={requiredDate}
                                            onChange={(newValue) => {
                                                setRequiredDate(newValue);
                                                values.date_reimbursed =
                                                    newValue;
                                            }}
                                            renderInput={(params) => {
                                                return (
                                                    <JumboTextField
                                                        fullWidth
                                                        size={'small'}
                                                        sx={{
                                                            marginTop: '8px'
                                                        }}
                                                        value={requiredDate}
                                                        name="date_reimbursed"
                                                        {...params}
                                                    />
                                                );
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12} md={6}></Grid>

                                <Grid item xs={12} md={12}>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        // size="small"
                                        color="success"
                                        sx={{ mr: 1 }}
                                        loading={isLoading}
                                        fullWidth
                                    >
                                        Submit
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Div>
                    </Form>
                )}
            </Formik>
        </Div>
    );
};
/* Todo officeItem, onSave prop define */
export default IssueForm;
