import _ from 'lodash';
import { USE_IMAGE_PLACEHOLDERS } from './constants/paths';

export const getAssetPath = (url, size) => {
    if (USE_IMAGE_PLACEHOLDERS) {
        return `https://via.placeholder.com/${size}.png`;
    }

    return url;
};

export const formatNumber = (val) => {
    if (!val) {
        return '';
    }
    // remove sign if negative
    var sign = 1;
    if (val < 0) {
        sign = -1;
        val = -val;
    }
    // trim the number decimal point if it exists
    let num = val.toString().includes('.')
        ? val.toString().split('.')[0]
        : val.toString();
    let len = num.toString().length;
    let result = '';
    let count = 1;

    for (let i = len - 1; i >= 0; i--) {
        result = num.toString()[i] + result;
        if (count % 3 === 0 && count !== 0 && i !== 0) {
            result = ',' + result;
        }
        count++;
    }

    // add number after decimal point
    if (val.toString().includes('.')) {
        result = result + '.' + val.toString().split('.')[1];
    }
    // return result with - sign if negative
    return sign < 0 ? '-' + result : result;
};

export const capitalizeFirstLetter = (string) => {
    if (!string) {
        return null;
    }
    const str = string.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeAllWords = (string) => {
    if (!string) {
        return null;
    }
    const words = string.toLowerCase().split(' ');
    const excludeArray = ['of'];

    for (let i = 0; i < words.length; i++) {
        if (words[i].length < 3 && !_.includes(excludeArray, words[i])) {
            words[i] = words[i].toUpperCase();
        } else {
            words[i] = words[i][0].toUpperCase() + words[i].slice(1);
        }
    }

    return words.join(' ');
};

export const createHandle = (string) => {
    const words = string.toLowerCase().split(' ');

    return words.join('-');
};

export const getCurrentDate = (datetime = true) => {
    var serverDate = new Date();
    var yr = serverDate.getFullYear();
    var mnth = serverDate.getMonth() + 1;
    if (mnth.toString().length === 1) {
        mnth = '0' + '' + mnth;
    }
    var dy = serverDate.getDate();
    if (dy.toString().length === 1) {
        dy = '0' + '' + dy;
    }
    var hrs = serverDate.getHours();
    if (hrs.toString().length === 1) {
        hrs = '0' + '' + hrs;
    }
    var mins = serverDate.getMinutes();
    if (mins.toString().length === 1) {
        mins = '0' + '' + mins;
    }
    var secs = serverDate.getSeconds();
    if (secs.toString().length === 1) {
        secs = '0' + '' + secs;
    }

    return datetime
        ? `${yr}-${mnth}-${dy} ${hrs}:${mins}:${secs}`
        : `${yr}-${mnth}-${dy}`;
};

export const getNextYearDate = (datetime = true) => {
    var serverDate = new Date();
    var yr = serverDate.getFullYear() + 1;
    var mnth = serverDate.getMonth() + 1;
    if (mnth.toString().length === 1) {
        mnth = '0' + '' + mnth;
    }
    var dy = serverDate.getDate();
    if (dy.toString().length === 1) {
        dy = '0' + '' + dy;
    }
    var hrs = serverDate.getHours();
    if (hrs.toString().length === 1) {
        hrs = '0' + '' + hrs;
    }
    var mins = serverDate.getMinutes();
    if (mins.toString().length === 1) {
        mins = '0' + '' + mins;
    }
    var secs = serverDate.getSeconds();
    if (secs.toString().length === 1) {
        secs = '0' + '' + secs;
    }

    return datetime
        ? `${yr}-${mnth}-${dy}${' '}${hrs}:${mins}:${secs}`
        : `${yr}-${mnth}-${dy}`;
};

export const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};
