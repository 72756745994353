import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
    alpha,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Chip,
    CircularProgress,
    Alert
} from '@mui/material';

import NumbersIcon from '@mui/icons-material/Numbers';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PaymentIcon from '@mui/icons-material/Payment';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'; //Employees
import DescriptionIcon from '@mui/icons-material/Description';
import Span from '@jumbo/shared/Span';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import Div from '@jumbo/shared/Div';
import moment from 'moment';
import _ from 'lodash';
import { capitalizeAllWords, formatNumber } from 'app/utils/appHelpers';
import ReimbursementStatus from 'app/pages/statuses/ReimbursementStatus';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from 'react-query';
import { reimbursementsServices } from 'app/services/reimbursements-services';
import { useSnackbar } from 'notistack';
import Stack from '@mui/material/Stack';
import MySwal from 'app/hooks/MySwal/MySwal';

const ReimbursementDetail = ({
    reimbursement,
    authUser,
    onSave = () => {},
    onClose = () => {}
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const { REACT_APP_BACKEND } = process.env;
    const profilePic = reimbursement?.employeeId?.profile_pic
        ? process.env.REACT_APP_BACKEND +
          '/' +
          reimbursement?.employeeId?.profile_pic
        : null;

    const [reimbursementStatus, setReimbursementStatus] = React.useState([
        {
            id: 0,
            name: 'Inactive',
            slug: 'inactive',
            color: '#ca404f'
        }
    ]);

    const { mutate: validateReimbursementMutation, isLoading: isValidating } =
        useMutation(reimbursementsServices.validateReimbursement, {
            onSuccess: () => {
                enqueueSnackbar(
                    'Reimbursement has been validated issued successfully.',
                    {
                        variant: 'success'
                    }
                );
                onSave();
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(
                    error?.message ? error.message : error.toString(),
                    {
                        variant: 'error'
                    }
                );

                return false;
            }
        });

    const onValidate = async () => {
        try {
            validateReimbursementMutation({
                reimbursementId: reimbursement.id
            });
        } catch (error) {}
    };

    const { mutate: cancelReimbursementMutation, isLoading: isCancelling } =
        useMutation(reimbursementsServices.cancelReimbursement, {
            onSuccess: () => {
                enqueueSnackbar(
                    'Reimbursement status has been updated successfully.',
                    {
                        variant: 'success'
                    }
                );
                onSave();
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(
                    error?.message ? error.message : error.toString(),
                    {
                        variant: 'error'
                    }
                );

                return false;
            }
        });

    const onCancel = async (reimbursementId) => {
        try {
            cancelReimbursementMutation({
                reimbursementId
            });
        } catch (error) {}
    };

    const confirmCancel = (id) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Cancel it!',
            cancelButtonText: 'No, Keep it!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                onCancel(id);
            } else {
                MySwal.fire(
                    'We keeping it',
                    'Your reimbursement is still active :)',
                    'info'
                );
            }
        });
    };

    React.useEffect(() => {
        setReimbursementStatus(
            _.find(
                ReimbursementStatus,
                (status) => status.id === reimbursement?.status
            )
        );
    }, [reimbursement]);

    return (
        <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
            <CardHeader
                title={`${reimbursement?.reimbursement_name}`}
                subheader={
                    <Div
                        sx={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography
                            variant={'body1'}
                            color={'text.secondary'}
                            mr={0.5}
                        >
                            {`${reimbursement?.employeeId?.first_name} ${reimbursement?.employeeId?.last_name}`}
                        </Typography>
                        <JumboChipsGroup
                            chips={
                                reimbursement
                                    ? [reimbursementStatus]
                                    : [
                                          {
                                              id: 0,
                                              name: 'Inactive',
                                              slug: 'inactive',
                                              color: '#ca404f'
                                          }
                                      ]
                            }
                            mapKeys={{ label: 'name' }}
                            spacing={1}
                            size={'small'}
                            max={1}
                        />
                    </Div>
                }
                avatar={<Avatar src={profilePic} />}
                action={
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List disablePadding>
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <NumbersIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Reimbursement ID #
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {`IEC-${String(
                                            reimbursement?.reimbursement_id
                                        ).padStart(8, '0')}`}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <AccountBalanceWalletIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Related Expense
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {`# IEC-${reimbursement?.expenseId?.expense_id} : ${reimbursement?.expenseId?.expense_name}`}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />

                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <MonetizationOnIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Amount To Be{' '}
                                        {reimbursement?.type === 'return'
                                            ? 'Returned'
                                            : 'Reimbursed'}
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {formatNumber(
                                            reimbursement?.reimbursement_amount
                                        )}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />

                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <PaymentIcon
                                        sx={{
                                            color: 'primary.light'
                                        }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Reimbursements
                                    </Typography>
                                }
                                secondary={
                                    <Div
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        {reimbursement?.reimbursements &&
                                            reimbursement?.reimbursements
                                                .length > 0 &&
                                            _.map(
                                                reimbursement.reimbursements,
                                                (payment) => (
                                                    <Div
                                                        key={payment?.id}
                                                        sx={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            verticalAlign:
                                                                'middle',
                                                            alignItems:
                                                                'center',
                                                            my: '5px'
                                                        }}
                                                    >
                                                        <Typography
                                                            variant={'h5'}
                                                            sx={{
                                                                width: '33%',
                                                                fontWeight: 400,
                                                                my: '5px'
                                                            }}
                                                        >
                                                            {
                                                                payment?.reimbursement_transaction_id
                                                            }
                                                        </Typography>
                                                        <Typography
                                                            variant={'h5'}
                                                            color="text.primary"
                                                            sx={{
                                                                width: '33%',
                                                                textAlign:
                                                                    'right',
                                                                my: '5px'
                                                            }}
                                                        >
                                                            {formatNumber(
                                                                payment?.reimbursed_amount ||
                                                                    0
                                                            )}
                                                        </Typography>
                                                        <Span
                                                            sx={{
                                                                width: '33%',
                                                                textAlign:
                                                                    'right'
                                                            }}
                                                        >
                                                            {moment(
                                                                payment?.date_reimbursed
                                                            ).format(
                                                                'DD MMM YYYY'
                                                            )}
                                                        </Span>
                                                    </Div>
                                                )
                                            )}
                                    </Div>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                    </List>
                    <Stack
                        spacing={1}
                        direction={'row'}
                        sx={{ px: 4, py: 2, justifyContent: 'end' }}
                    >
                        {reimbursement?.status === 3 &&
                            _.map(
                                reimbursement?.reportId?.moduleId?.admins,
                                (admin) => admin.id
                            ).includes(authUser?.userId) && (
                                <LoadingButton
                                    color="primary"
                                    variant="contained"
                                    loading={isValidating}
                                    onClick={() => onValidate()}
                                >
                                    Validate Reimbusements
                                </LoadingButton>
                            )}
                    </Stack>
                </Grid>
            </Grid>
        </Div>
    );
};

export default ReimbursementDetail;
