import React from 'react';
import Grid from '@mui/material/Grid';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import SchoolIcon from '@mui/icons-material/School';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PaidIcon from '@mui/icons-material/Paid';
import FlightIcon from '@mui/icons-material/Flight';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import _ from 'lodash';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { useMutation, useQuery } from 'react-query';
import SummaryCard from '../SummaryCard';
import { leadsServices } from 'app/services/leads-services';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const LeadsSummary = ({ refreshList, queryOptions }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [summary, setSummary] = React.useState(false);

    React.useEffect(() => {
        const cancelTokenAgents = axios.CancelToken.source();
        const agentToken = { cancelToken: cancelTokenAgents.token };

        const fetchSummary = async () => {
            try {
                const res = await leadsServices.getLeadsSummary(
                    { ...queryOptions },
                    agentToken
                );

                setSummary(res.summary);
            } catch (error) {
                console.log(error);
            }
        };

        fetchSummary();

        return () => {
            cancelTokenAgents.cancel();
        };
    }, [queryOptions]);

    return (
        summary && (
            <Grid item xs={12} lg={2} sx={{ marginBottom: '20px' }}>
                <Grid container spacing={3.75}>
                    <Grid item xs={12} sm={6} lg={3}>
                        <SummaryCard
                            title={'Total Leads'}
                            value={summary.reduce((total, item) => {
                                total += item.count;
                                return total;
                            }, 0)}
                            icon={<ViewListOutlinedIcon />}
                            color="#38B8F2"
                            colorFaded="#843CF6"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <SummaryCard
                            title={'Under Uni Application'}
                            value={summary.reduce((total, item) => {
                                if (parseInt(item.status) === 4) {
                                    total += item.count;
                                }
                                return total;
                            }, 0)}
                            icon={<SchoolIcon />}
                            color="#fd7f20"
                            colorFaded="#fed4b3"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <SummaryCard
                            title={'Under Visa Application'}
                            value={summary.reduce((total, item) => {
                                if (parseInt(item.status) === 5) {
                                    total += item.count;
                                }
                                return total;
                            }, 0)}
                            icon={<CardTravelIcon />}
                            color="#654e53"
                            colorFaded="#b7a1a6"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <SummaryCard
                            title={'Enrolled'}
                            value={
                                _.find(summary, (state) => state.status === 8)
                                    ?.count || 0
                            }
                            icon={<LibraryBooksIcon />}
                            color="#366353"
                            colorFaded="#7bb7a2"
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    );
};

export default LeadsSummary;
