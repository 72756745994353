import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const timeoffsServices = {};

timeoffsServices.getAllTimeoff = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/timeoffs',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

timeoffsServices.getTimeoff = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/timeoffs', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

timeoffsServices.getTimeoffDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/timeoffs/timeoff/${id}`, {
        id: id
    });

    return data;
};

timeoffsServices.addTimeoff = async (timeoff) => {
    const { data } = await jwtAuthAxios.post(`/timeoffs`, timeoff);
    return data;
};

timeoffsServices.approveTimeoff = async (timeoff) => {
    const { data } = await jwtAuthAxios.patch(
        `/timeoffs/approve-timeoff/${timeoff.requestId}`,
        timeoff
    );
    return data;
};

timeoffsServices.cancelTimeoff = async (timeoff) => {
    const { data } = await jwtAuthAxios.patch(
        `/timeoffs/cancel-timeoff/${timeoff.requestId}`,
        timeoff
    );
    return data;
};

timeoffsServices.updateTimeoff = async (timeoff) => {
    // console.log('Service Timeoff');
    // console.log(timeoff);
    const { data } = await jwtAuthAxios.patch(
        `/timeoffs/${timeoff.id}`,
        timeoff
    );
    return data;
};

timeoffsServices.updatePublicHolidays = async (timeoff) => {
    // console.log('Service Timeoff');
    // console.log(timeoff);
    const { data } = await jwtAuthAxios.patch(
        `/timeoffs/update-public-holidays`,
        {}
    );
    return data;
};

timeoffsServices.deleteTimeoff = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/timeoffs/${id}`, {
        id: id
    });
    return data;
};

timeoffsServices.deleteMultipleTimeoff = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/timeoffs`, {
        data: ids
    });
    return data;
};

export { timeoffsServices };
