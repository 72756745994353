import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
    alpha,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from '@mui/material';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Div from '@jumbo/shared/Div';

import EngineeringIcon from '@mui/icons-material/Engineering';
import DescriptionIcon from '@mui/icons-material/Description';
import { capitalizeAllWords } from 'app/utils/appHelpers';

const Detail = ({ item, onClose }) => {
    return (
        <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
            <CardHeader
                title={capitalizeAllWords(item?.requirement_name)}
                subheader={''}
                // avatar={<Avatar src={item?.profile_pic} />}

                action={
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
            />
            <List disablePadding>
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <EngineeringIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                Requirement
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {capitalizeAllWords(item?.requirement_name)}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <DescriptionIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                Description
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {item?.requirement_description}
                            </Typography>
                        }
                    />
                </ListItem>
            </List>
            <Stack spacing={1} direction={'row'} sx={{ px: 4, py: 2 }}>
                {/* <IconButton
                    size={'large'}
                    sx={{
                        backgroundColor: (theme) => theme.palette.grey[400],
                        color: 'common.white',

                        '&:hover': {
                            backgroundColor: 'primary.main'
                        }
                    }}
                >
                    <ForumOutlinedIcon fontSize={'medium'} color={'inherit'} />
                </IconButton>
                <IconButton
                    size={'large'}
                    sx={{
                        backgroundColor: (theme) => theme.palette.grey[400],
                        color: 'common.white',

                        '&:hover': {
                            backgroundColor: 'primary.main'
                        }
                    }}
                >
                    <LocalPhoneIcon fontSize={'medium'} color={'inherit'} />
                </IconButton> */}
            </Stack>
        </Div>
    );
};

export default Detail;
