import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { useMutation, useQuery } from 'react-query';
import { reportsServices } from 'app/services/reports-services';
import IconButton from '@mui/material/IconButton';
import LabelsSelectControl from '../LabelsSelectControl';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import Div from '@jumbo/shared/Div';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import JumboScrollbar from '@jumbo/components/JumboScrollbar';
import useReportsApp from '../../hooks/useReportsApp';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Span from '@jumbo/shared/Span';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { useSnackbar } from 'notistack';
import ReportDetail from './ReportDetail';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

const ReportDetailWrapper = ({ reportID }) => {
    const { authUser } = useJumboAuth();
    const { setReportsListRefresh, selectedReports } = useReportsApp();
    const { hideDialog } = useJumboDialog();
    const { enqueueSnackbar } = useSnackbar();
    const { category, id } = useParams();
    const navigate = useNavigate();

    const handleReportUpdate = React.useCallback(() => {
        hideDialog();
        setReportsListRefresh(true);
        navigate(`/app/expenses/reports`);
    }, [hideDialog, setReportsListRefresh]);

    const { data, isLoading, isError, error } = useQuery(
        ['reportDetail', reportID],
        () => reportsServices.getReportDetails(reportID),
        {
            enabled: !!reportID
        }
    );

    console.log('Report');
    console.log(data);

    if (isLoading) {
        return (
            <Div
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: (theme) => theme.spacing(3),
                    m: 'auto'
                }}
            >
                <CircularProgress />
                <Typography variant={'h6'} color={'text.secondary'} mt={2}>
                    Loading...
                </Typography>
            </Div>
        );
    }

    if (isError) {
        return error.message;
    }

    const handleNavigateItem = () => {
        console.log(data);
        if (category && id) return navigate(`/app/expenses/reports`);
        return navigate(`/app/expenses/reports/${id}`);
    };

    return (
        <Card>
            <CardHeader
                title={
                    <IconButton onClick={handleNavigateItem} sx={{ ml: -1.25 }}>
                        <ArrowBackIcon />
                    </IconButton>
                }
                // action={
                // <Stack
                //     direction={'row'}
                //     sx={{ backgroundColor: 'transparent' }}
                // >
                //     <Tooltip title={'Report Spam'}>
                //         <IconButton
                //             onClick={() => reportMoveToSpam(reportID)}
                //         >
                //             <ReportGreporterrorredIcon />
                //         </IconButton>
                //     </Tooltip>
                //     <Tooltip title={'Archive'}>
                //         <IconButton
                //             onClick={() => reportMoveToArchived(reportID)}
                //         >
                //             <ArchiveOutlinedIcon />
                //         </IconButton>
                //     </Tooltip>
                //     <LabelsSelectControl onDone={applyLabels} />
                //     <Tooltip title={'Delete'}>
                //         <IconButton
                //             onClick={() => reportMoveToTrash(reportID)}
                //         >
                //             <DeleteOutlineIcon />
                //         </IconButton>
                //     </Tooltip>
                // </Stack>
                // }
            />
            <CardContent sx={{ p: 0 }}>
                <JumboScrollbar
                    style={{ minHeight: 380 }}
                    autoHide
                    autoHideDuration={200}
                    autoHideTimeout={500}
                    autoHeightMin={30}
                >
                    <Div
                        sx={{
                            px: 3,
                            display: 'flex',
                            minHeight: '100%',
                            flexDirection: 'column',
                            minWidth: 0
                        }}
                    >
                        <ReportDetail
                            authUser={authUser}
                            report={data}
                            onSave={handleReportUpdate}
                        />
                    </Div>
                </JumboScrollbar>
            </CardContent>
        </Card>
    );
};

export default ReportDetailWrapper;
