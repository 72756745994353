import React, { useReducer, useRef, useState } from 'react';
import * as yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import { userServices } from 'app/services/user-services';
import { useSnackbar } from 'notistack';
// import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Form, Formik, Field } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboAvatarField from '@jumbo/components/JumboFormik/JumboAvatarField';
import Div from '@jumbo/shared/Div';
import { MuiTelInput } from 'mui-tel-input';
import axios from 'axios';

const validationSchema = yup.object({
    profile_pic: yup
        .string('Enter your first name')
        .required('First name is required')
});
const initialValues = {
    profile_pic: ''
};

const UploadPicture = ({ userDetails, onSave = () => {} }) => {
    const { enqueueSnackbar } = useSnackbar();

    const jumboAvatarRef = useRef(null);
    const [displayCaption, setDisplayCaption] = useState(true);
    const [file, setFile] = useState(null);

    const handleClick = () => {
        if (jumboAvatarRef.current) {
            jumboAvatarRef.current.click(); // Trigger click event on the JumboAvatarField
        }
    };

    const updateProfilePictureMutation = useMutation(
        userServices.updateProfilePicture,
        {
            onSuccess: () => {
                onSave();
                enqueueSnackbar(
                    'Profile picture has been updated successfully.',
                    {
                        variant: 'success'
                    }
                );
            },
            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        }
    );

    const saveMutation = React.useMemo(() => {
        return updateProfilePictureMutation;
    }, [updateProfilePictureMutation]);

    const onPictureSave = (data, { setSubmitting }) => {
        if (!file) {
            enqueueSnackbar('Please upload a photo first.', {
                variant: 'warning'
            });
            return;
        }
        const formData = new FormData();
        formData.append('userId', userDetails?.id);
        formData.append('profile_pic', file);

        setSubmitting(true);
        saveMutation.mutate({ formData, id: userDetails?.id });
        setSubmitting(false);
    };

    return (
        <>
            <Formik
                validateOnChange={true}
                initialValues={{
                    profile_pic: ''
                }}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={onPictureSave}
            >
                {({ isSubmitting, setFieldValue, values, touched, errors }) => (
                    <Form noValidate autoComplete="off">
                        <Div
                            sx={{
                                '& .MuiTextField-root': {
                                    mb: 3
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                                // justifyContent: 'center'
                            }}
                        >
                            {' '}
                            <Div
                                style={{
                                    position: 'relative',
                                    textAlign: 'center'
                                }}
                            >
                                <JumboAvatarField
                                    name={'profile_pic'}
                                    alt={'user profile pic'}
                                    ref={jumboAvatarRef}
                                    onFileSelection={(binaryFile, file) => {
                                        setFieldValue(
                                            'profile_pic',
                                            binaryFile
                                        );
                                        setFile(file[0]);
                                    }}
                                    sx={{
                                        width: 148,
                                        height: 148,
                                        margin: '0 auto 24px'
                                    }}
                                />
                                {/* {!values.profile_pic && ( */}
                                <Div
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        color: '#fff', // Change the text color as needed
                                        background: 'rgba(0, 0, 0, 0.3)', // Change the background color as needed
                                        padding: '16px 24px',
                                        borderRadius: '4px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={handleClick}
                                >
                                    Click to upload
                                </Div>
                                {/* )} */}
                            </Div>
                            {file && (
                                <LoadingButton
                                    // fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3, margin: '0 auto 24px' }}
                                    loading={
                                        isSubmitting || saveMutation.isLoading
                                    }
                                >
                                    Save
                                </LoadingButton>
                            )}
                        </Div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

UploadPicture.defaultProps = {
    onSave: () => {}
};
export default UploadPicture;
