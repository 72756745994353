import React from 'react';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'app/AppAbility';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { useMutation } from 'react-query';
import { institutionsServices } from 'app/services/institutions-services';
import useInstitutionsApp from '../../hooks/useInstitutionsApp';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { useSnackbar } from 'notistack';
import { Tooltip } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import _ from 'lodash';

const BulkActions = () => {
    const { selectedList, setListRefresh } = useInstitutionsApp();
    const { showDialog, hideDialog } = useJumboDialog();

    const ability = useAbility(AbilityContext);
    const allowBulkDel = ability.can('delete', 'Institution');
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: removeInstitutions, isLoading } = useMutation(
        institutionsServices.deleteMultipleInstitutions,
        {
            onSuccess: () => {
                hideDialog();
                setListRefresh(true);
                enqueueSnackbar(
                    'Institutions have been deleted successfully.',
                    {
                        variant: 'success'
                    }
                );
            },
            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const handleBulkDelete = React.useCallback(() => {
        if (!allowBulkDel) {
            enqueueSnackbar('You are not allowed to perform this action', {
                variant: 'warning'
            });

            return;
        }

        showDialog({
            variant: 'confirm',
            title: 'Delete!',
            content:
                'This action cannot be undone. Are you sure you want to proceed?  Select Yes to Continue',
            onYes: () => {
                removeInstitutions(_.map(selectedList, (item) => item.id));
            },
            onNo: hideDialog
        });
    }, [selectedList, removeInstitutions, hideDialog]);

    return (
        <Stack
            direction={'row'}
            sx={{ backgroundColor: 'transparent', ml: -2 }}
        >
            <Tooltip title={'Delete'}>
                <IconButton onClick={handleBulkDelete}>
                    <DeleteOutlineIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};

export default BulkActions;
