import React from 'react';
import { IconButton, OutlinedInput, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import Div from '@jumbo/shared/Div';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { getAssetPath } from 'app/utils/appHelpers';
import { Link } from 'react-router-dom';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

const Error404 = () => {
    const { setAuthToken } = useJumboAuth();
    return (
        <Div
            sx={{
                flex: 1,
                flexWrap: 'wrap',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: (theme) => theme.spacing(4)
            }}
        >
            <Div sx={{ display: 'inline-flex', mb: 3 }}>
                <img
                    src={getAssetPath(
                        `${ASSET_IMAGES}/apps/undraw_page_not_found.svg`,
                        '380x206'
                    )}
                    alt="404"
                    width={380}
                />
            </Div>
            <Typography
                align={'center'}
                component={'h2'}
                variant={'h1'}
                color={'text.secondary'}
                mb={3}
            >
                Oops, an error has occurred. You may not have permissions to
                access the requested page!
            </Typography>
            {/* <FormControl
                fullWidth
                variant="outlined"
                sx={{ maxWidth: 360, mb: 2 }}
            >
                <OutlinedInput
                    id="outlined-search"
                    type="search"
                    placeholder="Search..."
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton aria-label="Search" edge="end">
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                    sx={{ bgcolor: (theme) => theme.palette.background.paper }}
                />
            </FormControl> */}

            <Div
                sx={{
                    flex: 1,
                    flexWrap: 'wrap',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: (theme) => theme.spacing(4)
                }}
            >
                <Button
                    sx={{ mr: '10px' }}
                    component={Link}
                    to={'/'}
                    variant="contained"
                >
                    Go to home
                </Button>
                {/* <Button
                    variant="contained"
                    color={'secondary'}
                    onClick={() => {
                        setAuthToken(null);
                        navigate('/user/login');
                    }}
                >
                    Sign In
                </Button> */}
            </Div>
        </Div>
    );
};

export default Error404;
