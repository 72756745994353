import React, { useState } from 'react';
import { Alert, Card, CardContent, TextField, Typography } from '@mui/material';
import Logo from 'app/shared/Logo';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Div from '@jumbo/shared/Div';
import LoadingButton from '@mui/lab/LoadingButton';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { useMutation } from 'react-query';
import { surveyService } from 'app/services/survey-services';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { useParams } from 'react-router-dom';

const validationSchema = yup.object({
    nps: yup.string('Select rating').required('Rating is required')
    // improvement_comment: yup
    //     .string('Enter comment')
    //     .required('Comment is required')
});

const TrainingSurvey = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [submitted, setSubmitted] = useState(false);
    const params = useParams();
    const { timeCreated, studentId, leadId, eventId } = params;

    const { mutate: submitSurveyMutation, isLoading } = useMutation(
        surveyService.submitSurvey,
        {
            onSuccess: () => {
                enqueueSnackbar('Survey has been submitted successfully.', {
                    variant: 'success'
                });
                setSubmitted(true);
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onSubmitSurvey = async (data) => {
        try {
            submitSurveyMutation({ ...data });
        } catch (error) {}
    };

    return (
        <Div
            sx={{
                width: 820,
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {submitted && (
                <Alert
                    severity="success"
                    sx={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Typography variant="h4">
                        {' '}
                        Thank you for taking the time to complete the survey.{' '}
                    </Typography>
                    <br></br>
                    <br></br>
                    We genuinely value your opinions, and your feedback will
                    help us understand what we're doing well and where we can
                    make improvements. Your insights are incredibly important to
                    us, and we're committed to using them to enhance our
                    services and support.
                    <br></br>
                    <br></br>
                    We look forward to continuing to serve you and striving to
                    meet and exceed your expectations.
                </Alert>
            )}
            {!submitted && (
                <Card
                    sx={{
                        display: 'flex',
                        minWidth: '100%',
                        flexDirection: { xs: 'column', md: 'column' },
                        p: '20px'
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            my: '16px'
                        }}
                    >
                        <Logo />
                    </Div>
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            nps: '',
                            event_rating: '',
                            services_clarity: '',
                            services_value_to_add: '',
                            what_liked_on_training: '',
                            what_disliked_in_training: '',
                            training_organization: '',
                            trainer_topics_knowledge: '',
                            trainer_prepared: '',
                            relevant_topics: '',
                            participation_interaction_encouraged: '',
                            time_sufficient: '',
                            students_training_impact: '',
                            additional_programs: '',
                            improvement_comment: '',
                            additional_comments: '',
                            type: 'event',
                            studentId: studentId,
                            leadId: leadId,
                            eventId: eventId,
                            timeCreated: timeCreated
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            await onSubmitSurvey(values);
                        }}
                    >
                        {({
                            isSubmitting,
                            errors,
                            touched,
                            values,
                            isValid,
                            ...restProps
                        }) => (
                            <Form
                                style={{ textAlign: 'left' }}
                                noValidate
                                autoComplete="off"
                            >
                                <Div sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant={'h5'} mb={0}>
                                                How likely are you to recommend
                                                the training/event to a friend
                                                or colleague?
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                px: '10px'
                                            }}
                                        >
                                            {[...Array(11).keys()].map(
                                                (value) => (
                                                    <label key={value}>
                                                        <Field
                                                            type="radio"
                                                            name="nps"
                                                            value={value.toString()}
                                                        />
                                                        {value}
                                                    </label>
                                                )
                                            )}
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <Typography variant={'h5'} mb={0}>
                                                Are the services provided by IEC
                                                clear?
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'left',
                                                justifyContent: 'start',
                                                px: '10px'
                                            }}
                                        >
                                            {[
                                                'Extremely clear',
                                                'Very clear',
                                                'Somewhat clear',
                                                'Not so clear',
                                                'Not at all clear'
                                            ].map((value) => (
                                                <label key={value}>
                                                    <Field
                                                        type="radio"
                                                        name="services_clarity"
                                                        value={value.toString()}
                                                    />
                                                    {value}
                                                </label>
                                            ))}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant={'h5'} mb={0}>
                                                What services/value can IEC add?
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'left',
                                                justifyContent: 'start',
                                                px: '10px'
                                            }}
                                        >
                                            <JumboTextField
                                                fullWidth
                                                size={'small'}
                                                name="services_value_to_add"
                                                label=""
                                                placeholder="What services/value can we add"
                                                multiline
                                                rows={4}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant={'h5'} mb={0}>
                                                What did you like about the
                                                leadership training?
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'left',
                                                justifyContent: 'start',
                                                px: '10px'
                                            }}
                                        >
                                            <JumboTextField
                                                fullWidth
                                                size={'small'}
                                                name="what_liked_on_training"
                                                label=""
                                                placeholder="Tell us what you like about the leadership training?"
                                                multiline
                                                rows={4}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant={'h5'} mb={0}>
                                                What did you dislike about the
                                                leadership training?
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'left',
                                                justifyContent: 'start',
                                                px: '10px'
                                            }}
                                        >
                                            <JumboTextField
                                                fullWidth
                                                size={'small'}
                                                name="what_disliked_in_training"
                                                label=""
                                                placeholder="Let us know what you dislike about the
                                                leadership training"
                                                multiline
                                                rows={4}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant={'h5'} mb={0}>
                                                How organized was the leadership
                                                training?
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'left',
                                                justifyContent: 'start',
                                                px: '10px'
                                            }}
                                        >
                                            {[
                                                'Extremely organized',
                                                'Very organized',
                                                'Somewhat organized',
                                                'Not so organized',
                                                'Not at all organized'
                                            ].map((value) => (
                                                <label key={value}>
                                                    <Field
                                                        type="radio"
                                                        name="training_organization"
                                                        value={value.toString()}
                                                    />
                                                    {value}
                                                </label>
                                            ))}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant={'h5'} mb={0}>
                                                The trainer was knowledgeable
                                                about the training topics.
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'left',
                                                justifyContent: 'start',
                                                px: '10px'
                                            }}
                                        >
                                            {[
                                                'Extremely knowledgeable',
                                                'Very knowledgeable',
                                                'Somewhat knowledgeable',
                                                'Not so knowledgeable',
                                                'Not at all knowledgeable'
                                            ].map((value) => (
                                                <label key={value}>
                                                    <Field
                                                        type="radio"
                                                        name="trainer_topics_knowledge"
                                                        value={value.toString()}
                                                    />
                                                    {value}
                                                </label>
                                            ))}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant={'h5'} mb={0}>
                                                The trainer was well prepared.
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'left',
                                                justifyContent: 'start',
                                                px: '10px'
                                            }}
                                        >
                                            {[
                                                'Extremely prepared',
                                                'Very prepared',
                                                'Somewhat prepared',
                                                'Not so prepared',
                                                'Not at all prepared'
                                            ].map((value) => (
                                                <label key={value}>
                                                    <Field
                                                        type="radio"
                                                        name="trainer_prepared"
                                                        value={value.toString()}
                                                    />
                                                    {value}
                                                </label>
                                            ))}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant={'h5'} mb={0}>
                                                The topics covered were
                                                relevant.
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'left',
                                                justifyContent: 'start',
                                                px: '10px'
                                            }}
                                        >
                                            {[
                                                'Extremely relevant',
                                                'Very relevant',
                                                'Somewhat relevant',
                                                'Not so relevant',
                                                'Not at all relevant'
                                            ].map((value) => (
                                                <label key={value}>
                                                    <Field
                                                        type="radio"
                                                        name="relevant_topics"
                                                        value={value.toString()}
                                                    />
                                                    {value}
                                                </label>
                                            ))}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant={'h5'} mb={0}>
                                                Participation and interaction
                                                were encouraged.
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'left',
                                                justifyContent: 'start',
                                                px: '10px'
                                            }}
                                        >
                                            {[
                                                'Extremely encouraged',
                                                'Very encouraged',
                                                'Somewhat encouraged',
                                                'Not so encouraged',
                                                'Not at all encouraged'
                                            ].map((value) => (
                                                <label key={value}>
                                                    <Field
                                                        type="radio"
                                                        name="participation_interaction_encouraged"
                                                        value={value.toString()}
                                                    />
                                                    {value}
                                                </label>
                                            ))}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant={'h5'} mb={0}>
                                                Time allocated for the training
                                                was sufficient.
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'left',
                                                justifyContent: 'start',
                                                px: '10px'
                                            }}
                                        >
                                            {[
                                                'Extremely sufficient',
                                                'Very sufficient',
                                                'Somewhat sufficient',
                                                'Not so sufficient',
                                                'Not at all sufficient'
                                            ].map((value) => (
                                                <label key={value}>
                                                    <Field
                                                        type="radio"
                                                        name="time_sufficient"
                                                        value={value.toString()}
                                                    />
                                                    {value}
                                                </label>
                                            ))}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant={'h5'} mb={0}>
                                                How do you hope this training
                                                will impact students?
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'left',
                                                justifyContent: 'start',
                                                px: '10px'
                                            }}
                                        >
                                            <JumboTextField
                                                fullWidth
                                                size={'small'}
                                                name="students_training_impact"
                                                label=""
                                                placeholder="Tell us what you think about the impact this training will have to the students?"
                                                multiline
                                                rows={4}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant={'h5'} mb={0}>
                                                What more programs can we have
                                                on board that will impact
                                                students.
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'left',
                                                justifyContent: 'start',
                                                px: '10px'
                                            }}
                                        >
                                            <JumboTextField
                                                fullWidth
                                                size={'small'}
                                                name="additional_programs"
                                                label=""
                                                placeholder="Tell us what we can add to this training for more value"
                                                multiline
                                                rows={4}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant={'h5'} mb={0}>
                                                What aspects of training can be
                                                improved?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <JumboTextField
                                                fullWidth
                                                size={'small'}
                                                name="improvement_comment"
                                                label=""
                                                placeholder="How can we improve your experience"
                                                multiline
                                                rows={4}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant={'h5'} mb={0}>
                                                Is there anything else you’d
                                                like to share about the event?
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'left',
                                                justifyContent: 'start',
                                                px: '10px'
                                            }}
                                        >
                                            <JumboTextField
                                                fullWidth
                                                size={'small'}
                                                name="additional_comments"
                                                label=""
                                                placeholder="Let us know of anything else you’d like to share about the event"
                                                multiline
                                                rows={4}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sx={{
                                                my: '30px'
                                            }}
                                        >
                                            <LoadingButton
                                                fullWidth
                                                type="submit"
                                                variant="contained"
                                                size="large"
                                                sx={{ mb: 3 }}
                                                disabled={
                                                    _.isEmpty(touched) ||
                                                    !isValid
                                                }
                                                loading={isLoading}
                                            >
                                                Submit Feedback
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Div>
                            </Form>
                        )}
                    </Formik>
                </Card>
            )}
        </Div>
    );
};

export default TrainingSurvey;
