import Span from '@jumbo/shared/Span';
import { getCustomDateTime } from '@jumbo/utils';
import { ASSET_AVATARS, ASSET_IMAGES } from 'app/utils/constants/paths';
import { getAssetPath } from 'app/utils/appHelpers';

export const recentActivities = [
    {
        date: getCustomDateTime(0, 'days', 'MMM DD, YYYY'),
        data: [
            {
                id: 1,
                user: {
                    id: 12,
                    name: 'Sitao Jackson',
                    profilePic: getAssetPath(
                        `${ASSET_AVATARS}/avatar3.jpg`,
                        `44x44`
                    )
                },
                mediaList: [
                    {
                        id: 123,
                        name: 'Media1',
                        mediaUrl: getAssetPath(
                            `${ASSET_AVATARS}/martin-j.jpg`,
                            `44x44`
                        )
                    },
                    {
                        id: 124,
                        name: 'Media1',
                        mediaUrl: getAssetPath(
                            `${ASSET_AVATARS}/jeson-born.jpg`,
                            `44x44`
                        )
                    },
                    {
                        id: 125,
                        name: 'Media1',
                        mediaUrl: getAssetPath(
                            `${ASSET_AVATARS}/jonathan.jpg`,
                            `44x44`
                        )
                    }
                ],
                content: [
                    <Span sx={{ color: '#7352C7', mr: 1 }}>Sitao Jackson</Span>,
                    'Created 3 new leads'
                ]
            },
            {
                id: 2,
                user: {
                    id: 12,
                    name: 'Asha',
                    profilePic: getAssetPath(
                        `${ASSET_AVATARS}/avatar5.jpg`,
                        `44x44`
                    )
                },
                mediaList: [],
                content: [
                    <Span sx={{ color: '#7352C7', mr: 1 }}>Asha</Span>,
                    'Added new students'
                ]
            }
        ]
    },
    {
        date: getCustomDateTime(-1, 'days', 'MMM DD, YYYY'),
        data: [
            {
                id: 4,
                user: {
                    id: 12,
                    name: 'Ali Doe',
                    profilePic: getAssetPath(
                        `${ASSET_AVATARS}/avatar9.jpg`,
                        `44x44`
                    )
                },
                mediaList: [
                    {
                        id: 123,
                        name: 'Media1',
                        mediaUrl: getAssetPath(
                            `${ASSET_IMAGES}/wall/community1.png`,
                            `44x44`
                        )
                    }
                ],
                content: [
                    'Coached new student ',
                    <Span sx={{ color: '#7352C7', mr: 1 }}>Mercy Lui</Span>
                ]
            }
        ]
    }
];
