import React from 'react';
import Stack from '@mui/material/Stack';
import LabelsSelectControl from '../LabelsSelectControl';
import { useMutation } from 'react-query';
import { reportsServices } from 'app/services/reports-services';
import useReportsApp from '../../hooks/useReportsApp';
import IconButton from '@mui/material/IconButton';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { useSnackbar } from 'notistack';
import { Tooltip } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const BulkActions = () => {
    const { selectedReports, setReportsListRefresh } = useReportsApp();
    const { showDialog, hideDialog } = useJumboDialog();
    const { enqueueSnackbar } = useSnackbar();

    const assignLabelMutation = useMutation(reportsServices.assignLabel, {
        onSuccess: () => {
            hideDialog();
            setReportsListRefresh(true);
            enqueueSnackbar('Labels has been applied successfully.', {
                variant: 'success'
            });
        }
    });
    const deleteReportsMutation = useMutation(reportsServices.deleteMultiple, {
        onSuccess: () => {
            hideDialog();
            setReportsListRefresh(true);
            enqueueSnackbar('Reports has been deleted successfully.', {
                variant: 'success'
            });
        }
    });
    const handleBulkDelete = React.useCallback(() => {
        showDialog({
            variant: 'confirm',
            title: 'Are you sure?',
            onYes: () =>
                deleteReportsMutation.mutate(
                    selectedReports.map((report) => report?.id)
                ),
            onNo: hideDialog
        });
    }, [selectedReports, deleteReportsMutation, hideDialog]);

    const applyLabels = React.useCallback(
        (selectedLabels) => {
            assignLabelMutation.mutate({
                reportIDs: selectedReports.map((report) => report?.id),
                labelIDs: selectedLabels.map((label) => label?.id)
            });
        },
        [selectedReports]
    );

    return (
        <Stack
            direction={'row'}
            sx={{ backgroundColor: 'transparent', ml: -2 }}
        >
            {/* <Tooltip title={'Delete'}>
                <IconButton onClick={handleBulkDelete}>
                    <DeleteOutlineIcon />
                </IconButton>
            </Tooltip>
            <LabelsSelectControl onDone={applyLabels} /> */}
        </Stack>
    );
};

export default BulkActions;
