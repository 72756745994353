import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const userServices = {};

userServices.getAllUsers = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/users',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

userServices.getUsers = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/users', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

userServices.getUserDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/users/user/${id}`, {
        id: id
    });
    // console.log('service');
    // console.log(data);
    // console.log(data.user.first_name);
    // console.log(JSON.parse(data.user));
    // console.log(data.user.first_name);

    return data;
};

userServices.addUser = async (user) => {
    const { data } = await jwtAuthAxios.post(`/users/signup`, user);
    return data;
};

userServices.updateUser = async (user) => {
    const { data } = await jwtAuthAxios.patch(`/users/${user.id}`, user);
    return data;
};

userServices.updateUserProfile = async (user) => {
    const { data } = await jwtAuthAxios.patch(`/users/${user.id}`, user);
    return data;
};

userServices.updateProfilePicture = async (user) => {
    const { data } = await jwtAuthAxios.patch(
        `/users/picture/${user.id}`,
        user.formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );
    return data;
};

userServices.changePassword = async (user) => {
    const { data } = await jwtAuthAxios.patch(
        `/users/change-password/${user.id}`,
        user
    );
    return data;
};

userServices.deleteUser = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/users/${id}`, {
        id: id
    });
    return data;
};

userServices.deleteMultipleUsers = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/users`, {
        data: ids
    });
    return data;
};

export { userServices };
