import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const leavetypesServices = {};

leavetypesServices.getAllLeavetypes = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/leave-types',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

leavetypesServices.getLeavetypes = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/leave-types', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

leavetypesServices.getLeavetypeDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/leave-typess/leave-type/${id}`, {
        id: id
    });

    return data;
};

leavetypesServices.addLeavetype = async (leavetypes) => {
    const { data } = await jwtAuthAxios.post(`/leave-types`, leavetypes);
    return data;
};

leavetypesServices.updateLeavetype = async (leavetypes) => {
    // console.log('Service Leavetype');
    // console.log(leavetypes);
    const { data } = await jwtAuthAxios.patch(
        `/leave-types/${leavetypes.id}`,
        leavetypes
    );
    return data;
};

leavetypesServices.deleteLeavetype = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/leave-types/${id}`, {
        id: id
    });
    return data;
};

leavetypesServices.deleteMultipleLeavetypes = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/leave-types`, {
        data: ids
    });
    return data;
};

export { leavetypesServices };
