/**
 * Authorization Roles
 */
const authRoles = {
    student: ['student'],
    consultant: ['consultant', 'regional', 'director', 'administrator'],
    consultantOnly: ['consultant'],
    hr: ['hr', 'regional', 'director', 'administrator'],
    hrOnly: ['hr'],
    regional: ['regional', 'director', 'administrator'],
    director: ['director', 'administrator'],
    administrator: ['administrator'],
    superadmin: ['superadmin'],
    onlyGuest: []
};

export default authRoles;
