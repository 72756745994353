import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import RefreshIcon from '@mui/icons-material/Refresh';

const AdvanceStatus = [
    {
        id: 0,
        status: 'Cancelled',
        name: 'Cancelled',
        slug: 'Cancelled',
        color: '#ffd0cd',
        type: 'default',
        icon: <CancelIcon />
    },
    {
        id: 1,
        status: 'Awaiting Disbursement',
        name: 'Awaiting Disbursement',
        slug: 'Awaiting Disbursement',
        color: '#ffd0cd',
        type: 'warning',
        icon: <HourglassEmptyIcon />
    },
    {
        id: 2,
        status: 'Disbursement',
        name: 'Disbursement',
        slug: 'Disbursement',
        color: '#cdffcf',
        type: 'warning',
        icon: <RefreshIcon />
    },
    {
        id: 3,
        status: 'Rejected',
        name: 'Rejected',
        slug: 'Rejected',
        color: '#ffd0cd',
        type: 'error',
        icon: <ClearIcon />
    },
    {
        id: 4,
        status: 'Disbursed',
        name: 'Disbursed',
        slug: 'Disbursed',
        color: '#cdffcf',
        type: 'success',
        icon: <CheckCircleIcon />
    }
];

export default AdvanceStatus;
