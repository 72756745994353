import React from 'react';
import Grid from '@mui/material/Grid';
import Contacts from './components/Contacts';
import GuardianContacts from './components/GuardianContacts';
import MotherContacts from './components/MotherContacts';

const StudentProfileSidebar = ({ studentDetails, authUser }) => {
    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} md={6} lg={12}>
                <Contacts studentDetails={studentDetails} />
                <GuardianContacts studentDetails={studentDetails} />
                <MotherContacts studentDetails={studentDetails} />
            </Grid>
        </Grid>
    );
};

export default StudentProfileSidebar;
