import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
    alpha,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from '@mui/material';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Div from '@jumbo/shared/Div';

import MailLockIcon from '@mui/icons-material/MailLock';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PasswordIcon from '@mui/icons-material/Password';
import HttpIcon from '@mui/icons-material/Http';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import HtmlIcon from '@mui/icons-material/Html';
import VpnLockIcon from '@mui/icons-material/VpnLock';

const Detail = ({ item, onClose }) => {
    const [showPass, setShowPass] = React.useState(false);
    return (
        <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
            <CardHeader
                title={item?.smtp_name}
                subheader={''}
                // avatar={<Avatar src={item?.profile_pic} />}

                action={
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
            />
            <List disablePadding>
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <MailLockIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                SMTP Name
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {item?.smtp_name}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <AlternateEmailIcon
                                sx={{ color: 'primary.light' }}
                            />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                Email Address/Account
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {item?.email_address}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <PasswordIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                Password
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {showPass
                                    ? item?.password?.encryptedPassword
                                    : '*********************'}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <HttpIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                SMTP Host
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {item?.smtp_host}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <SettingsEthernetIcon
                                sx={{ color: 'primary.light' }}
                            />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                SMTP Port
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {item?.smtp_port}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <HtmlIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                Content Type
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {item?.content_type}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <VpnLockIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                SMTP Encryption
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {item?.smtp_encryption}
                            </Typography>
                        }
                    />
                </ListItem>
            </List>
            <Stack spacing={1} direction={'row'} sx={{ px: 4, py: 2 }}>
                {/* <IconButton
                    size={'large'}
                    sx={{
                        backgroundColor: (theme) => theme.palette.grey[400],
                        color: 'common.white',

                        '&:hover': {
                            backgroundColor: 'primary.main'
                        }
                    }}
                >
                    <ForumOutlinedIcon fontSize={'medium'} color={'inherit'} />
                </IconButton>
                <IconButton
                    size={'large'}
                    sx={{
                        backgroundColor: (theme) => theme.palette.grey[400],
                        color: 'common.white',

                        '&:hover': {
                            backgroundColor: 'primary.main'
                        }
                    }}
                >
                    <LocalPhoneIcon fontSize={'medium'} color={'inherit'} />
                </IconButton> */}
            </Stack>
        </Div>
    );
};

export default Detail;
