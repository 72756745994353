import React from 'react';
import Header from './Header';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder';
import JumboListWrapper from '@jumbo/components/JumboList/components/JumboListWrapper';
import NotAllowed from 'app/shared/NotAllowed';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'app/AppAbility';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import Performance from './components/Events';
import { alpha } from '@mui/material/styles';
import About from './components/About';
import Biography from './components/Biography';
import Territories from './components/Territories';
import UserProfileSidebar from './UserProfileSidebar';

// import JumboListWrapper from '@jumbo/components/JumboList/components/JumboListWrapper';
// import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';

import { CircularProgress, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import Div from '@jumbo/shared/Div';

import { useParams } from 'react-router-dom';

import { useQuery, useQueryClient } from 'react-query';
import { userServices } from 'app/services/user-services';

import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { getAssetPath } from 'app/utils/appHelpers';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

const UserProfile = () => {
    const { authUser, authToken, setAuthToken } = useJumboAuth();

    const urlParts = window.location.pathname.split('/');

    const ability = useAbility(AbilityContext);
    const queryClient = useQueryClient();
    const { theme } = useJumboTheme();
    const params = useParams();
    const userId = params.id.toString();

    const fetchUserDetails = async () => {
        const data = await userServices.getUserDetails(userId);

        return data;
    };

    const { isLoading, data, error } = useQuery(
        `user-${userId}`,
        fetchUserDetails
    );

    const onUpdate = () => {
        setAuthToken(authToken);
        queryClient.invalidateQueries({ queryKey: [`user-${userId}`] });
    };

    let allowRead;
    let showUsersNav;

    if (urlParts[1] === 'user' && urlParts[2] === 'profile') {
        allowRead = userId.toString() === authUser?.userId.toString();
        showUsersNav = false;
    } else {
        allowRead = ability.can('read', 'User');
        showUsersNav = true;
    }

    if (isLoading) {
        return (
            <JumboListWrapper
                component={Card}
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Div
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: (theme) => theme.spacing(3),
                        m: 'auto'
                    }}
                >
                    <CircularProgress />
                    <Typography variant={'h6'} color={'text.secondary'} mt={2}>
                        Loading data
                    </Typography>
                </Div>
            </JumboListWrapper>
        );
    }

    if (error) {
        return (
            <JumboListWrapper
                component={Card}
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Div
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: (theme) => theme.spacing(3),
                        m: 'auto'
                    }}
                >
                    <Typography variant={'h6'} color={'text.secondary'} mt={2}>
                        {error}
                    </Typography>
                </Div>
            </JumboListWrapper>
        );
    }

    if (!allowRead) {
        return (
            <JumboListWrapper
                component={Card}
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <JumboListNoDataPlaceholder>
                    <NotAllowed />
                </JumboListNoDataPlaceholder>
            </JumboListWrapper>
        );
    }

    if (data) {
        return (
            <JumboContentLayout
                header={
                    <Header
                        userDetails={data.user}
                        showUsersNav={showUsersNav}
                    />
                }
                sidebar={
                    <UserProfileSidebar
                        userDetails={data.user}
                        authUser={authUser}
                    />
                }
                layoutOptions={{
                    header: {
                        sx: {
                            mt: -4,
                            mb: -7.25,
                            mx: { xs: -4, lg: -6 },
                            p: {
                                xs: theme.spacing(6, 4, 11),
                                lg: theme.spacing(6, 6, 11)
                            },
                            background: `#002447 url(${getAssetPath(
                                `${ASSET_IMAGES}/profile-bg.jpg`,
                                '1920x580'
                            )}) no-repeat center`,
                            backgroundSize: 'cover',
                            color: 'common.white',
                            position: 'relative',

                            '&::after': {
                                display: 'inline-block',
                                position: 'absolute',
                                content: `''`,
                                inset: 0,
                                backgroundColor: alpha(
                                    theme.palette.common.black,
                                    0.65
                                )
                            }
                        }
                    },
                    sidebar: {
                        sx: {
                            mr: 3.75,
                            width: { xs: '100%', lg: '33%' },
                            [theme.breakpoints.down('lg')]: {
                                minHeight: 0,
                                mr: 0,
                                order: 2
                            }
                        }
                    },
                    wrapper: {
                        sx: {
                            [theme.breakpoints.down('lg')]: {
                                flexDirection: 'column'
                            }
                        }
                    },
                    main: {
                        sx: {
                            [theme.breakpoints.down('lg')]: {
                                minHeight: 0
                            }
                        }
                    }
                }}
            >
                <About
                    userDetails={data.user}
                    onUpdate={onUpdate}
                    authUser={authUser}
                />

                <Territories userDetails={data.user} authUser={authUser} />

                <Biography userDetails={data.user} />
                {(authUser.role_name === 'hr' && data.user.employee) ||
                    (authUser.userId === data.user.id && authUser.employee && (
                        <Performance
                            userDetails={data.user}
                            sx={{ mb: { xs: 3.75, lg: 0 } }}
                        />
                    ))}
            </JumboContentLayout>
        );
    } else {
        return (
            <JumboListNoDataPlaceholder>
                <NoDataPlaceholder />
            </JumboListNoDataPlaceholder>
        );
    }
};

export default UserProfile;
