import React from 'react';

import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import SchoolIcon from '@mui/icons-material/School';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import EngineeringIcon from '@mui/icons-material/Engineering';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Slide from '@mui/material/Slide';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import CourseCreateForm from 'app/pages/home/Students/StudentProfile/components/CourseCreateForm';
import _ from 'lodash';
import moment from 'moment';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const Enrollments = ({ studentDetails, invalidateAndRefetch }) => {
    const { showDialog, hideDialog } = useJumboDialog();

    const enrollments =
        studentDetails?.enrollments && studentDetails?.enrollments.length
            ? studentDetails?.enrollments.reverse()
            : [];

    const handleUpdateEnrollmentsForm = (event) => {
        showDialog({
            title: 'Update Preferred Courses',
            content: (
                <CourseCreateForm
                    studentDetails={studentDetails}
                    onUpdate={invalidateAndRefetch}
                    hideDialog={hideDialog}
                />
            ),
            maxWidth: 'sm',
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true
        });
    };

    return (
        <JumboCardQuick
            title={'Enrollments'}
            subheader={`List Of University/College enrollments`}
            action={
                <Stack
                    direction={'row'}
                    justifyContent={'space-evenly'}
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                    sx={{
                        mx: 1
                    }}
                >
                    {/* <Button
                        variant="contained"
                        size="small"
                        onClick={handleUpdateEnrollmentsForm}
                    >
                        Update Preferred Courses
                    </Button> */}
                </Stack>
            }
            headerSx={{ pb: 0 }}
            sx={{ mb: 3.75 }}
        >
            {(!enrollments || enrollments.length === 0) && (
                <Alert severity="info">No Available Enrollments yet</Alert>
            )}

            {enrollments &&
                enrollments.length > 0 &&
                _.map(enrollments, (enrollment) => (
                    <List disablePadding sx={{ mb: 2 }}>
                        <ListItem
                            alignItems="flex-start"
                            sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                        >
                            <ListItemIcon
                                sx={{ minWidth: 36, color: 'text.secondary' }}
                            >
                                <SchoolIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant="body1"
                                        color="text.secondary"
                                    >
                                        Institution
                                    </Typography>
                                }
                                secondary={
                                    <Div>
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                        >
                                            {enrollment?.institution_name}
                                            {', '}
                                            {enrollment?.country_name}
                                        </Typography>
                                    </Div>
                                }
                            />
                        </ListItem>
                        <ListItem
                            alignItems="flex-start"
                            sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                        >
                            <ListItemIcon
                                sx={{ minWidth: 36, color: 'text.secondary' }}
                            >
                                <HistoryEduIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant="body1"
                                        color="text.secondary"
                                    >
                                        Course
                                    </Typography>
                                }
                                secondary={
                                    <Link
                                        variant="body1"
                                        href="#"
                                        underline="none"
                                    >
                                        {enrollment?.course_name}
                                    </Link>
                                }
                            />
                        </ListItem>

                        <ListItem
                            alignItems="flex-start"
                            sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                        >
                            <ListItemIcon
                                sx={{ minWidth: 36, color: 'text.secondary' }}
                            >
                                <EventAvailableIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant="body1"
                                        color="text.secondary"
                                    >
                                        Date Enrolled
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        variant="body1"
                                        color="text.primary"
                                    >
                                        {moment(
                                            enrollment?.date_enrolled
                                        ).format('DD MMM YYYY')}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                ))}
        </JumboCardQuick>
    );
};

export default Enrollments;
