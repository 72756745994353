import React from 'react';
import { useField } from 'formik';
import TextField from '@mui/material/TextField';

const JumboTextField = (props) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    const onChangeFun = props?.onChange ? { onChange: props.onChange } : {};

    return (
        <TextField
            {...props}
            {...field}
            helperText={errorText}
            error={!!errorText}
            {...onChangeFun}
        />
    );
};

export default JumboTextField;
