import React from 'react';

import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useMutation } from 'react-query';
import { uniapplicationsServices } from 'app/services/uniapplications-services';
import { institutionsServices } from 'app/services/institutions-services';
import { leadsServices } from 'app/services/leads-services';
import { officeServices } from 'app/services/office-services';
import { coursesServices } from 'app/services/courses-services';
import { countriesServices } from 'app/services/countries-services';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';

import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import axios from 'axios';

import {
    capitalizeAllWords,
    getCurrentDate,
    getNextYearDate
} from 'app/utils/appHelpers';

import _ from 'lodash';

const validationSchema = yup.object({
    applicationCode: yup
        .string('Enter application ID/Code')
        .required('Application ID/Code name is required'),
    leadId: yup.string('Select Lead').required('Lead is required'),
    institutionId: yup
        .string('Select Institution')
        .required('Institution is required'),
    courseId: yup
        .array('Please select a course')
        .of(
            yup.object().shape({
                value: yup.string(),
                label: yup.string()
            })
        )
        .min(1, 'Please select atleast 1 course')
        .required('Please select applied courses')
        .typeError('Please select a valid course'),
    // officeId: yup.string('Select Office').required('Office is required'),
    // requirements: yup
    //     .string('Provide/Upload Requirements')
    //     .required('Requirements are required'),
    application_fee: yup
        .string('Enter application fee & provide receipt')
        .required('Application fee & provide receipt name is required'),
    application_date: yup
        .string('Enter date of application')
        .required('Application date is required')
    // comment: yup.string('Enter Comment').required('Comment name is required'),
    // acceptance_documents: yup.string('Provide acceptance documents').required('Acceptance documents name are required'),
    // attachments: yup.string('Enter application ID/Code').required('Application ID/Code name is required'),
    // status: yup.string('Enter application ID/Code').required('Application ID/Code name is required'),
});

const CreateForm = ({ onSave, hideDialog, userDetails }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [leads, setLeads] = React.useState(false);
    const [finalLeads, setFinalLeads] = React.useState(false);
    const [customizingCourses, setCustomizingCourses] = React.useState(false);
    const [institutions, setInstitutions] = React.useState(false);
    const [institutionId, setInstitutionId] = React.useState('');
    const [feeCurrency, setFeeCurrency] = React.useState('');

    const [requirements, setRequirements] = React.useState(false);
    const [courses, setCourses] = React.useState(false);
    const [finalCourses, setFinalCourses] = React.useState(false);

    const [fieldDate, setFieldDate] = React.useState(
        dayjs(getCurrentDate(false))
    );

    const { mutate: addUniapplicationMutation, isLoading } = useMutation(
        uniapplicationsServices.addUniapplication,
        {
            onSuccess: () => {
                onSave();
                enqueueSnackbar('Application has been saved successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (uniapplication) => {
        try {
            addUniapplicationMutation({ ...uniapplication });
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelLeadToken = axios.CancelToken.source();
        const cLeadToken = { cancelToken: cancelLeadToken.token };
        const fetchLeads = async () => {
            try {
                const res = await leadsServices.getAllLeads(cLeadToken);

                setLeads(res.leadsList);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchLeads();

        return () => {
            cancelLeadToken.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelInstToken = axios.CancelToken.source();
        const cInstToken = { cancelToken: cancelInstToken.token };
        const fetchInstitutions = async () => {
            try {
                const res = await institutionsServices.getAllInstitutions(
                    cInstToken
                );

                setInstitutions(res.institutions);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchInstitutions();

        return () => {
            cancelInstToken.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenCourses = axios.CancelToken.source();
        const cTokenCourses = {
            cancelToken: cancelTokenCourses.token
        };
        const fetchCourses = async () => {
            try {
                const res = await coursesServices.getAllCourses(cTokenCourses);

                setCourses(res.courses);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchCourses();

        return () => {
            cancelTokenCourses.cancel();
        };
    }, []);

    React.useEffect(() => {
        if (institutionId) {
            setFinalCourses(
                _.filter(
                    courses,
                    (course) => course?.institutionId?.id === institutionId
                )
            );
        }

        setCustomizingCourses(false);
    }, [institutionId]);

    React.useEffect(() => {
        if (leads && leads.length > 0) {
            setFinalLeads(_.filter(leads, (lead) => lead?.active === true));
        }

        setCustomizingCourses(false);
    }, [leads]);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {(!courses || !institutions || !leads) && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {courses && institutions && leads && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        applicationCode: '',
                        leadId: '',
                        student_name: '',
                        countryId: '',
                        country_name: '',
                        institutionId: '',
                        institution_name: '',
                        courseId: [],
                        offer_waiting_period: '',
                        officeId: '',
                        requirements: [],
                        application_fee: '',
                        application_fee_currency: feeCurrency,
                        application_date: fieldDate,
                        comment: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onCreate(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        setErrors,
                        touched,
                        values,
                        isValid,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>{JSON.stringify(errors)}</div>
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Field name="leadId">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    sx={{
                                                        width: 500,
                                                        maxWidth: '100%'
                                                    }}
                                                    size="small"
                                                    options={finalLeads}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.studentId
                                                            ?.given_name
                                                            ? `Lead ID ${
                                                                  option?.lead_id
                                                              } : ${
                                                                  option
                                                                      ?.studentId
                                                                      ?.given_name
                                                              } ${
                                                                  option
                                                                      ?.studentId
                                                                      ?.surname
                                                              } - ${
                                                                  option
                                                                      ?.studentId
                                                                      ?.passport_number
                                                                      ? option
                                                                            ?.studentId
                                                                            ?.passport_number
                                                                      : option
                                                                            ?.studentId
                                                                            ?.id_no
                                                              }`
                                                            : ``
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                '& > img': {
                                                                    mr: 2,
                                                                    flexShrink: 0
                                                                }
                                                            }}
                                                            {...props}
                                                        >
                                                            {option?.studentId
                                                                ?.given_name
                                                                ? `Lead ID ${
                                                                      option?.lead_id
                                                                  } : ${
                                                                      option
                                                                          ?.studentId
                                                                          ?.given_name
                                                                  } ${
                                                                      option
                                                                          ?.studentId
                                                                          ?.surname
                                                                  } - ${
                                                                      option
                                                                          ?.studentId
                                                                          ?.passport_number
                                                                          ? option
                                                                                ?.studentId
                                                                                ?.passport_number
                                                                          : option
                                                                                ?.studentId
                                                                                ?.id_no
                                                                  }`
                                                                : ``}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Lead"
                                                            placeholder="Choose a lead"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.leadId &&
                                                                !!errors.leadId
                                                            }
                                                            helperText={
                                                                touched?.leadId &&
                                                                errors.leadId
                                                            }
                                                        />
                                                    )}
                                                    onChange={(
                                                        event,
                                                        newValue
                                                    ) => {
                                                        console.log('Lead');
                                                        console.log(newValue);
                                                        values.studentId =
                                                            newValue.studentId.id;
                                                        values.leadId =
                                                            newValue?.id;
                                                        values.officeId =
                                                            newValue?.officeId?.id;
                                                        values.student_name =
                                                            newValue?.studentId
                                                                ?.given_name +
                                                            ' ' +
                                                            newValue?.studentId
                                                                ?.surname;
                                                    }}
                                                    value={
                                                        leads.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.leadId
                                                        ) || ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="applicationCode"
                                            name="applicationCode"
                                            label="Application Code/Serial No./ID"
                                            placeholder="Enter application ID/Serial No./Code/Tracking No. here"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field name="institutionId">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    sx={{
                                                        width: 500,
                                                        maxWidth: '100%'
                                                    }}
                                                    size="small"
                                                    options={institutions}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.institution_name
                                                            ? option.institution_name
                                                            : ''
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                '& > img': {
                                                                    mr: 2,
                                                                    flexShrink: 0
                                                                }
                                                            }}
                                                            {...props}
                                                        >
                                                            {
                                                                option.institution_name
                                                            }
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Institution"
                                                            placeholder="Choose a institution"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.institutionId &&
                                                                !!errors.institutionId
                                                            }
                                                            helperText={
                                                                touched?.institutionId &&
                                                                errors.institutionId
                                                            }
                                                        />
                                                    )}
                                                    onChange={
                                                        (event, newValue) => {
                                                            console.log(
                                                                newValue
                                                            );
                                                            setCustomizingCourses(
                                                                true
                                                            );
                                                            values.courseId =
                                                                [];
                                                            values.institutionId =
                                                                newValue?.id;
                                                            values.institution_name =
                                                                newValue?.institution_name;
                                                            values.countryId =
                                                                newValue?.countryId.id;
                                                            values.country_name =
                                                                newValue?.countryId?.country_name;
                                                            values.application_requirements =
                                                                newValue?.application_requirements;
                                                            values.offer_waiting_period =
                                                                newValue?.offer_waiting_period ||
                                                                10;
                                                            values.application_fee =
                                                                newValue?.application_fee ||
                                                                1;

                                                            setInstitutionId(
                                                                newValue?.id
                                                            );
                                                            setFeeCurrency(
                                                                newValue
                                                                    ?.application_fee_currency
                                                                    ?.currency ||
                                                                    'USD'
                                                            );

                                                            setRequirements(
                                                                newValue?.application_requirements
                                                            );

                                                            setErrors({});
                                                        }
                                                        // Set the new value in the form field
                                                    }
                                                    value={
                                                        institutions.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.institutionId
                                                        ) || ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {customizingCourses && (
                                            <Div
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    width: '100%'
                                                }}
                                            >
                                                <CircularProgress
                                                    color="inherit"
                                                    size={24}
                                                />
                                                <Typography
                                                    sx={{ marginLeft: '16px' }}
                                                    color="default"
                                                >
                                                    Loading Courses please wait
                                                    ...
                                                </Typography>
                                            </Div>
                                        )}
                                        {!customizingCourses && (
                                            <Field name="courseId">
                                                {({ field }) => (
                                                    <Autocomplete
                                                        {...field}
                                                        multiple
                                                        id="courseId"
                                                        size={'small'}
                                                        options={finalCourses}
                                                        getOptionLabel={(
                                                            option
                                                        ) => {
                                                            return (
                                                                option.course_name ||
                                                                ''
                                                            );
                                                        }}
                                                        renderOption={(
                                                            props,
                                                            option
                                                        ) => (
                                                            <Box
                                                                component="li"
                                                                sx={{
                                                                    '& > img': {
                                                                        mr: 2,
                                                                        flexShrink: 0
                                                                    }
                                                                }}
                                                                {...props}
                                                            >
                                                                {
                                                                    option.course_name
                                                                }
                                                            </Box>
                                                        )}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Select Course"
                                                                placeholder="Courses"
                                                                error={
                                                                    touched.courseId &&
                                                                    !!errors.courseId
                                                                }
                                                                helperText={
                                                                    touched.courseId &&
                                                                    errors.courseId
                                                                }
                                                            />
                                                        )}
                                                        onChange={(
                                                            event,
                                                            newValue
                                                        ) =>
                                                            field.onChange({
                                                                target: {
                                                                    name: field.name,
                                                                    value: newValue
                                                                }
                                                            })
                                                        }
                                                        value={values.courseId}
                                                    />
                                                )}
                                            </Field>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="application_fee"
                                            name="application_fee"
                                            label="Application Fee"
                                            placeholder="Enter the application fee here"
                                            readOnly
                                            type={'number'}
                                            inputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {feeCurrency}
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <MobileDatePicker
                                                label="Application Date"
                                                inputFormat="DD/MM/YYYY"
                                                value={fieldDate}
                                                onChange={(newValue) => {
                                                    setFieldDate(newValue);
                                                    values.application_date =
                                                        newValue;
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <JumboTextField
                                                            fullWidth
                                                            size={'small'}
                                                            value={fieldDate}
                                                            name="application_date"
                                                            {...params}
                                                        />
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="comment"
                                            name="comment"
                                            label="Comment"
                                            placeholder="Enter comment here"
                                            multiline
                                            rows={2}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                        disabled={
                                            _.isEmpty(touched) || !isValid
                                        }
                                    >
                                        Save
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo uniapplicationItem, onSave prop define */
export default CreateForm;
