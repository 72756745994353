import React from 'react';
import _ from 'lodash';
import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { useMutation } from 'react-query';
import { depositsServices } from 'app/services/deposits-services';
import { countriesServices } from 'app/services/countries-services';
import { educationlevelsServices } from 'app/services/educationlevels-services';
import { officeServices } from 'app/services/office-services';

import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboRadioGroup from '@jumbo/components/JumboFormik/JumboRadioGroup';
import axios from 'axios';
import {
    capitalizeAllWords,
    getCurrentDate,
    getNextYearDate
} from 'app/utils/appHelpers';

const validationSchema = yup.object({
    deposit_name: yup
        .string('Enter deposit name')
        .required('Deposit name is required'),
    serial_number: yup
        .string('Enter serial number')
        .required('Serial number is required'),
    quantity: yup.string('Enter quantity').required('Qunatity is required'),
    officeId: yup.string('Select office').required('Office is required'),
    description: yup
        .string('Enter deposit description')
        .required('Deposit description is required')
});

const CreateForm = ({ item: depositItem, onSave, hideDialog, userDetails }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [fieldDob, setDob] = React.useState(dayjs(getCurrentDate(false)));
    const [issueDate, setIssueDate] = React.useState(
        dayjs(getCurrentDate(false))
    );
    const [expiryDate, setExpiryDate] = React.useState(
        dayjs(getNextYearDate(false))
    );
    const [officeId, setOfficeId] = React.useState('');
    const [offices, setOffices] = React.useState(false);
    const [designatedOffice, setDesignatedOffice] = React.useState(null);

    const { mutate: addDepositMutation, isLoading } = useMutation(
        depositsServices.addDeposit,
        {
            onSuccess: () => {
                onSave();
                enqueueSnackbar('Deposit has been created successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (deposit) => {
        try {
            addDepositMutation({ ...deposit });
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelTokenOffices = axios.CancelToken.source();
        const cTokenOffices = { cancelToken: cancelTokenOffices.token };
        const fetchOffices = async () => {
            try {
                const res = await officeServices.getAllOffices(cTokenOffices);
                let finalOffices = res.offices;

                if (
                    userDetails?.officeIds &&
                    userDetails?.role_name !== 'administrator'
                ) {
                    finalOffices = _.filter(res.offices, (office) =>
                        _.includes(userDetails.officeIds, office.id)
                    );
                }

                setOffices(finalOffices);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchOffices();

        return () => {
            cancelTokenOffices.cancel();
        };
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {!offices && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {offices && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        officeId: officeId,
                        deposit_name: '',
                        description: '',
                        serial_number: '',
                        quantity: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onCreate(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <JumboSelectField
                                            width="100%"
                                            size={'small'}
                                            id="officeId"
                                            name="officeId"
                                            label="Select Office"
                                            value={designatedOffice}
                                            placeholder="Select office"
                                            onChange={(event) =>
                                                setDesignatedOffice(
                                                    event.target.value
                                                )
                                            }
                                        >
                                            <MenuItem key={'officeId'} value="">
                                                <em>Select Office</em>
                                            </MenuItem>

                                            {offices.map((office) => (
                                                <MenuItem
                                                    key={office.id}
                                                    value={office.id}
                                                >
                                                    {capitalizeAllWords(
                                                        office.office_name
                                                    )}
                                                </MenuItem>
                                            ))}
                                        </JumboSelectField>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="deposit_name"
                                            name="deposit_name"
                                            label="Deposit Name"
                                            placeholder="Enter first name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="description"
                                            name="description"
                                            label="Deposit description"
                                            placeholder="Enter description"
                                            multiline
                                            rows={3}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="serial_number"
                                            name="serial_number"
                                            label="Serial Number"
                                            placeholder="Enter serial number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="quantity"
                                            name="quantity"
                                            label="Quantity"
                                            type="number"
                                            placeholder="Enter Quantity"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                    >
                                        Create Deposit
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo depositItem, onSave prop define */
export default CreateForm;
