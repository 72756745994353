import React from 'react';
import { Button, ListItem, ListItemText, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import Div from '@jumbo/shared/Div';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import CommentIcon from '@mui/icons-material/Comment';
import StarRateIcon from '@mui/icons-material/StarRate';

const EventItem = ({ goal }) => {
    const { theme } = useJumboTheme();
    // console.log(goal);
    return (
        <ListItem
            alignItems="flex-start"
            sx={{
                p: (theme) => theme.spacing(2, 3),
                borderBottom: 1,
                borderBottomColor: 'divider',

                [theme.breakpoints.down('sm')]: {
                    flexWrap: 'wrap'
                }
            }}
        >
            <ListItemText>
                <Typography
                    component={'div'}
                    sx={{
                        display: 'flex',
                        minWidth: 0,
                        [theme.breakpoints.down('md')]: {
                            flexWrap: 'wrap'
                        }
                    }}
                >
                    <Div
                        sx={{
                            flex: 1,
                            [theme.breakpoints.down('md')]: {
                                width: '100%'
                            }
                        }}
                    >
                        <Chip
                            label={goal.category}
                            size={'small'}
                            color={'primary'}
                            sx={{ borderRadius: 1, mb: 1 }}
                        />
                        <Typography variant={'h5'} mb={1.25}>
                            {goal.title}
                        </Typography>
                        <Typography
                            variant={'body1'}
                            color={'text.secondary'}
                            sx={{
                                display: 'flex',
                                minWidth: 0
                            }}
                        >
                            {goal.details}
                        </Typography>
                        <Typography
                            variant={'body1'}
                            color={'text.secondary'}
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                margin: '10px 0'
                            }}
                        >
                            <CommentIcon sx={{ fontSize: 20, mr: 1 }} />
                            {goal.feedback}
                        </Typography>
                        <Typography
                            variant={'h5'}
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                mb: { md: 3.25 },
                                fontSize: { xs: 14, md: 18 }
                            }}
                        >
                            <StarRateIcon
                                sx={{ fontSize: { xs: 14, md: 18 }, mr: 1 }}
                            />
                            Rating: {goal.rating}
                        </Typography>
                    </Div>

                    <Div
                        sx={{
                            textAlign: { md: 'right' },
                            mt: { xs: 2, md: 1 },
                            [theme.breakpoints.down('md')]: {
                                width: '100%'
                            }
                        }}
                    >
                        <Typography
                            variant={'body1'}
                            color={'primary'}
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                mb: { md: 3.25 },
                                fontSize: { xs: 12, md: 16 }
                            }}
                        >
                            <EventNoteOutlinedIcon
                                sx={{ fontSize: { xs: 12, md: 16 }, mr: 1 }}
                            />
                            Due: {goal.due_date}
                        </Typography>
                        <Button
                            variant={'text'}
                            endIcon={
                                <ArrowRightAltOutlinedIcon
                                    sx={{ fontSize: 20, ml: 1 }}
                                />
                            }
                            sx={{
                                p: 0,
                                textTransform: 'none',
                                letterSpacing: 0,

                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        >
                            Check In Detail
                        </Button>
                    </Div>
                </Typography>
            </ListItemText>
        </ListItem>
    );
};

export default EventItem;
