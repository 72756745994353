import React from 'react';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'app/AppAbility';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder';
import JumboListWrapper from '@jumbo/components/JumboList/components/JumboListWrapper';
import NotAllowed from 'app/shared/NotAllowed';
import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList';
import { approvalsServices } from 'app/services/approvals-services';
import ListItem from './ListItem';
import { Card, Slide } from '@mui/material';
import Button from '@mui/material/Button';
import { Add } from '@mui/icons-material';
import JumboListToolbar from '@jumbo/components/JumboList/components/JumboListToolbar';
import { useParams } from 'react-router-dom';
import JumboSearch from '@jumbo/components/JumboSearch';
import useApprovalsApp from '../../hooks/useApprovalsApp';
import BulkActions from './BulkActions';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import CreateForm from '../CreateForm';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import Filter from './Filter';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const List = () => {
    const { authUser } = useJumboAuth();

    const { hideDialog, showDialog } = useJumboDialog();

    const ability = useAbility(AbilityContext);
    const allowReadList = ability.can('read', 'Expenseapprovals');
    const allowAdd = ability.can('create', 'Expenseapproval');
    const allowBulkDel = ability.can('delete', 'Expenseapproval');

    const [filterCountry, setFilterCountry] = React.useState();
    const [filterInstitution, setFilterInstitution] = React.useState();

    const params = useParams();
    const listRef = React.useRef();
    const { refreshList, setListRefresh, setSelectedList } = useApprovalsApp();

    const renderListItem = React.useCallback((listItem) => {
        return <ListItem listItem={listItem} />;
    });
    const [queryOptions, setQueryOptions] = React.useState({
        queryKey: 'approvals-list',
        queryParams: {
            approval: params.approvalId,
            id: params._id,
            searchText: ''
        },
        countKey: 'count',
        dataKey: 'approvals'
    });

    const handleOnChange = (value) => {
        setQueryOptions((state) => ({
            ...state,
            queryParams: {
                ...state.queryParams,
                searchText: value
            }
        }));
    };

    React.useEffect(() => {
        if (listRef?.current && refreshList) {
            listRef.current.refresh();
            setListRefresh(false);
        }
    }, [refreshList]);

    React.useEffect(() => {
        setQueryOptions((state) => ({
            ...state,
            queryParams: {
                ...state.queryParams,
                category: params.category,
                id: params.id
            }
        }));
    }, [params]);

    const handleDataSent = React.useCallback(() => {
        hideDialog();
        setListRefresh(true);
    }, []);

    const handleCreateForm = React.useCallback(() => {
        showDialog({
            title: 'Add New Approval',
            content: (
                <CreateForm onSave={handleDataSent} hideDialog={hideDialog} />
            ),
            maxWidth: 'sm',
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true
        });
    }, [showDialog]);

    if (!allowReadList) {
        return (
            <JumboListWrapper
                component={Card}
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <JumboListNoDataPlaceholder>
                    <NotAllowed />
                </JumboListNoDataPlaceholder>
            </JumboListWrapper>
        );
    }
    const tableActions = authUser && allowBulkDel && <BulkActions />;
    const allowModuleAdd = false;
    const actionButton = allowModuleAdd && authUser && allowAdd && (
        <Button
            disableElevation
            variant={'contained'}
            startIcon={<Add />}
            sx={{
                mb: 1,
                '& .MuiSvgIcon-root': {
                    fontSize: '1.5rem'
                }
            }}
            onClick={handleCreateForm}
        >
            Configure Approval Settings
        </Button>
    );

    console.log('Text Log');
    console.log();
    return (
        <>
            {/* <Filter
                filterCountry={filterCountry}
                setFilterCountry={setFilterCountry}
                filterInstitution={filterInstitution}
                setFilterInstitution={setFilterInstitution}
            /> */}

            <JumboRqList
                ref={listRef}
                wrapperComponent={Card}
                queryOptions={queryOptions}
                primaryKey={'id'}
                service={approvalsServices.getApprovals}
                renderItem={renderListItem}
                itemsPerPage={8}
                itemsPerPageOptions={[8, 12, 15]}
                componentElement={'div'}
                wrapperSx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
                toolbar={
                    <JumboListToolbar
                        hideItemsPerPage={true}
                        bulkActions={tableActions}
                        addButton={actionButton}
                    >
                        {authUser && (
                            <JumboSearch
                                onChange={handleOnChange}
                                sx={{
                                    display: { xs: 'none', md: 'block' }
                                }}
                                value={queryOptions.queryParams.searchText}
                            />
                        )}
                    </JumboListToolbar>
                }
                onSelectionChange={setSelectedList}
            />
        </>
    );
};

export default List;
