import React from 'react';
import {
    IconButton,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Label } from '@mui/icons-material';
import ExpenseForm from '../ExpenseForm';
import useExpensesApp from '../../hooks/useExpensesApp';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import LabelForm from '../LabelForm';

const ExpenseFab = () => {
    const { hideDialog, showDialog } = useJumboDialog();
    const { setExpensesListRefresh, setLabelsListRefresh } = useExpensesApp();

    const refreshListAndCloseDialog = () => {
        setExpensesListRefresh(true);
        setLabelsListRefresh(true);
        hideDialog();
    };

    const showAddLabelDialog = React.useCallback(() => {
        showDialog({
            title: 'Add New Label',
            content: <LabelForm onSave={refreshListAndCloseDialog} />
        });
    }, []);

    const handleExpenseAdd = React.useCallback(() => {
        hideDialog();
        setExpensesListRefresh(true);
    }, [hideDialog, setExpensesListRefresh]);

    const showAddExpenseDialog = React.useCallback(() => {
        showDialog({
            title: 'Add New expense',
            content: <ExpenseForm onSave={handleExpenseAdd} />
        });
    }, [handleExpenseAdd, showDialog]);

    return (
        <SpeedDial
            ariaLabel={'expense-fab'}
            icon={<SpeedDialIcon />}
            sx={{
                position: 'fixed',
                right: 30,
                bottom: 30
            }}
        >
            <SpeedDialAction
                icon={
                    <IconButton onClick={showAddExpenseDialog}>
                        <PersonAddIcon />
                    </IconButton>
                }
                tooltipTitle={'Add Expense'}
            />
            <SpeedDialAction
                icon={
                    <IconButton onClick={showAddLabelDialog}>
                        <Label />
                    </IconButton>
                }
                tooltipTitle={'Add Label'}
            />
        </SpeedDial>
    );
};
export default ExpenseFab;
