import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const incomesServices = {};

incomesServices.getAllIncomesSummary = async (conditions, signal) => {
    const { data } = await jwtAuthAxios.get(
        '/incomes/summary',
        {
            params: {
                page: false,
                limit: false,
                searchText: '',
                approved: conditions?.approved || ''
            }
        },
        { ...signal }
    );

    return data;
};

incomesServices.getAllIncomes = async (conditions, signal) => {
    const { data } = await jwtAuthAxios.get(
        '/incomes',
        {
            params: {
                page: false,
                limit: false,
                searchText: '',
                approved: conditions?.approved || ''
            }
        },
        { ...signal }
    );

    return data;
};

incomesServices.getIncomes = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/incomes', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });

    console.log(data);

    return data;
};

incomesServices.getIncomeDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/incomes/${id}`, {
        id: id
    });
    // console.log('service');
    // console.log(data);
    // console.log(data.income.first_name);
    // console.log(JSON.parse(data.income));
    // console.log(data.income.first_name);

    return data;
};

incomesServices.addIncome = async (income) => {
    const { data } = await jwtAuthAxios.post(`/incomes`, income);
    return data;
};

incomesServices.approveIncome = async (income) => {
    const { data } = await jwtAuthAxios.patch(
        `/incomes/approve/${income.incomeId}`,
        income
    );
    return data;
};

incomesServices.updateIncome = async (income) => {
    const { data } = await jwtAuthAxios.patch(`/incomes/${income.id}`, income);
    return data;
};

incomesServices.updateStatus = async (income) => {
    const { data } = await jwtAuthAxios.patch(
        `/incomes/status/${income.id}`,
        income
    );
    return data;
};

incomesServices.cancelIncome = async (income) => {
    const { data } = await jwtAuthAxios.patch(
        `/incomes/cancel/${income.incomeId}`,
        income
    );
    return data;
};

incomesServices.deleteIncome = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/incomes/${id}`, {
        id: id
    });
    return data;
};

incomesServices.deleteMultipleIncomes = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/incomes`, {
        data: ids
    });
    return data;
};

incomesServices.getLabels = async () => {
    const { data } = await jwtAuthAxios.get('/incomes/labels');
    return data;
};

incomesServices.add = async (contact) => {
    const { data } = await jwtAuthAxios.post('/incomes', contact);
    return data;
};

incomesServices.update = async (contact) => {
    const { data } = await jwtAuthAxios.patch(
        `/incomes/${contact.id}`,
        contact
    );
    return data;
};

incomesServices.addLabel = async (label) => {
    const { data } = await jwtAuthAxios.post('/incomes/add-label', label);
    return data;
};

incomesServices.updateLabel = async (label) => {
    const { data } = await jwtAuthAxios.put('/incomes/update-label', label);
    return data;
};

incomesServices.deleteLabel = async (label) => {
    const { data } = await jwtAuthAxios.delete('/incomes/delete-label', {
        params: { id: label.id }
    });
    return data;
};

incomesServices.assignLabel = async (params) => {
    const { data } = await jwtAuthAxios.put('/incomes/assign-label', {
        contactIDs: params?.contactIDs ?? [],
        labelIDs: params?.labelIDs ?? []
    });
    return data;
};

export { incomesServices };
