import React from 'react';

import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useMutation } from 'react-query';
import { uniapplicationsServices } from 'app/services/uniapplications-services';
import { institutionsServices } from 'app/services/institutions-services';
import { studentServices } from 'app/services/student-services';
import { officeServices } from 'app/services/office-services';
import { coursesServices } from 'app/services/courses-services';
import { countriesServices } from 'app/services/countries-services';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';

import { useFormikContext, Formik, Form, Field } from 'formik';

import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import axios from 'axios';

import {
    capitalizeAllWords,
    getCurrentDate,
    getNextYearDate
} from 'app/utils/appHelpers';

import _ from 'lodash';

const validationSchema = yup.object({
    status: yup.string('Select status').required('Status is required'),
    enrollment_date: yup
        .string('Enter date of application')
        .required('Application date is required')
});

const Enrollment = ({
    listItem,
    userDetails,
    setActiveSchema,
    setInitialFormValues
}) => {
    const readOnlyState = true;

    const { enqueueSnackbar } = useSnackbar();

    const { values, setValues, touched, errors, setErrors, submitForm } =
        useFormikContext();

    const [fieldDate, setFieldDate] = React.useState(
        dayjs(getCurrentDate(false))
    );

    React.useEffect(() => {
        if (fieldDate) {
            values.enrollment_date = fieldDate;
        }
    }, [fieldDate]);

    React.useEffect(() => {
        setActiveSchema(validationSchema);

        setValues({
            status: 8,
            leadId: listItem.id,
            institution_name: listItem.selectedInstitutionId?.institution_name,
            course_name: listItem.selectedCourseId?.course_name,
            registration_number: '',
            enrollment_date: fieldDate
        });

        setErrors({});
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%'
            }}
        >
            <Div sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <JumboTextField
                            fullWidth
                            size={'small'}
                            id="Institution_name"
                            name="institution_name"
                            label="Institution"
                            placeholder="Selected Institution"
                            inputProps={{
                                readOnly: readOnlyState
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <JumboTextField
                            fullWidth
                            size={'small'}
                            id="course_name"
                            name="course_name"
                            label="Course Name"
                            placeholder="Enter application ID/Serial No./Code/Tracking No. here"
                            inputProps={{
                                readOnly: readOnlyState
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <JumboTextField
                            fullWidth
                            size={'small'}
                            id="registration_number"
                            name="registration_number"
                            label="Admission/Registration Number"
                            placeholder="Enter Student Admission/Registration Number"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                                label="Enrollment Date"
                                inputFormat="YYYY-MM-DD"
                                value={fieldDate}
                                onChange={(newValue) => {
                                    setFieldDate(newValue);
                                    values.enrollment_date = newValue;
                                }}
                                renderInput={(params) => {
                                    return (
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            value={fieldDate}
                                            name="enrollment_date"
                                            {...params}
                                        />
                                    );
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Div>
        </Div>
    );
};
/* Todo uniapplicationItem, onSave prop define */
export default Enrollment;
