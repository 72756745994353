import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const assetsServices = {};

assetsServices.getAllAssetsSummary = async (conditions, signal) => {
    const { data } = await jwtAuthAxios.get(
        '/assets/summary',
        {
            params: {
                page: false,
                limit: false,
                searchText: '',
                approved: conditions?.approved || ''
            }
        },
        { ...signal }
    );

    return data;
};

assetsServices.getAllAssets = async (conditions, signal) => {
    const { data } = await jwtAuthAxios.get(
        '/assets',
        {
            params: {
                page: false,
                limit: false,
                searchText: '',
                approved: conditions?.approved || ''
            }
        },
        { ...signal }
    );

    return data;
};

assetsServices.getAssets = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/assets', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });

    console.log(data);

    return data;
};

assetsServices.getAssetDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/assets/${id}`, {
        id: id
    });
    // console.log('service');
    // console.log(data);
    // console.log(data.asset.first_name);
    // console.log(JSON.parse(data.asset));
    // console.log(data.asset.first_name);

    return data;
};

assetsServices.addAsset = async (asset) => {
    const { data } = await jwtAuthAxios.post(`/assets`, asset);
    return data;
};

assetsServices.approveAsset = async (asset) => {
    const { data } = await jwtAuthAxios.patch(
        `/assets/approve/${asset.assetId}`,
        asset
    );
    return data;
};

assetsServices.updateAsset = async (asset) => {
    const { data } = await jwtAuthAxios.patch(`/assets/${asset.id}`, asset);
    return data;
};

assetsServices.cancelAsset = async (asset) => {
    const { data } = await jwtAuthAxios.patch(
        `/assets/cancel/${asset.assetId}`,
        asset
    );
    return data;
};

assetsServices.deleteAsset = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/assets/${id}`, {
        id: id
    });
    return data;
};

assetsServices.deleteMultipleAssets = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/assets`, {
        data: ids
    });
    return data;
};

assetsServices.getLabels = async () => {
    const { data } = await jwtAuthAxios.get('/assets/labels');
    return data;
};

assetsServices.add = async (contact) => {
    const { data } = await jwtAuthAxios.post('/assets', contact);
    return data;
};

assetsServices.update = async (contact) => {
    const { data } = await jwtAuthAxios.patch(`/assets/${contact.id}`, contact);
    return data;
};

assetsServices.addLabel = async (label) => {
    const { data } = await jwtAuthAxios.post('/assets/add-label', label);
    return data;
};

assetsServices.updateLabel = async (label) => {
    const { data } = await jwtAuthAxios.put('/assets/update-label', label);
    return data;
};

assetsServices.deleteLabel = async (label) => {
    const { data } = await jwtAuthAxios.delete('/assets/delete-label', {
        params: { id: label.id }
    });
    return data;
};

assetsServices.assignLabel = async (params) => {
    const { data } = await jwtAuthAxios.put('/assets/assign-label', {
        contactIDs: params?.contactIDs ?? [],
        labelIDs: params?.labelIDs ?? []
    });
    return data;
};

export { assetsServices };
