import React from 'react';
import _ from 'lodash';
import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import { useMutation } from 'react-query';
import { leadsServices } from 'app/services/leads-services';
import { studentServices } from 'app/services/student-services';
import ScheduleCard from './ScheduleCard';
import Contacts from './Contacts';
import GuardianContacts from './GuardianContacts';
import UserProfileCard1 from 'app/shared/widgets/UserProfileCard1';
import Applications from './Applications';
import PreferredCourses from './PreferredCourses';
import Documents from './Documents';
import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import axios from 'axios';
import { capitalizeAllWords } from 'app/utils/appHelpers';

const validationSchema = yup.object({
    studentId: yup.string('Slect student').required('Student is required')
});

const LeadInfo = ({ listItem, onSave, hideDialog, userDetails }) => {
    const { enqueueSnackbar } = useSnackbar();

    console.log('listItem');
    console.log(listItem);

    const profilePic = listItem?.studentId?.profile_pic
        ? process.env.REACT_APP_BACKEND + '/' + listItem?.studentId?.profile_pic
        : null;

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 1,
                flexGrow: 1
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                    <UserProfileCard1
                        bgColor={['#843CF6', '#38B8F2']}
                        cardPic={profilePic}
                        cardName={`${listItem?.studentId?.given_name} ${listItem?.studentId?.surname}`}
                        cardPhone={listItem?.studentId?.phone_number || ''}
                        cardEmail={listItem?.studentId?.email_address || ''}
                        cardDesignation={''}
                        cardLocation={listItem?.studentId?.high_school || ''}
                    />
                    <Div sx={{ my: 2 }} />
                    {/* <Contacts studentDetails={listItem?.studentId} /> */}
                    <GuardianContacts studentDetails={listItem?.studentId} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <ScheduleCard lead={listItem} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={3}
                    sx={
                        {
                            // display: 'flex',
                            // flexDirection: 'column',
                            // justifyContent: 'space-between',
                            // alignItems: 'center'
                        }
                    }
                >
                    <PreferredCourses studentDetails={listItem?.studentId} />
                    <Documents lead={listItem} />
                </Grid>
            </Grid>
            {/* <Grid
                    sx={{
                        margin: '30px auto'
                    }}
                    container
                    alignItems="right"
                    justify="flex-end"
                    justifyContent="right"
                >
                    <Button
                        size="large"
                        sx={{ mb: 3 }}
                        onClick={() => hideDialog()}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isLoading}
                    >
                        Create Lead
                    </LoadingButton>
                </Grid> */}
        </Div>
    );
};
/* Todo studentItem, onSave prop define */
export default LeadInfo;
