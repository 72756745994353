import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const reimbursementsServices = {};

reimbursementsServices.getAllReimbursements = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/reimbursements',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

reimbursementsServices.getReimbursements = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/reimbursements', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });

    console.log(data);

    return data;
};

reimbursementsServices.getReimbursementDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/reimbursements/${id}`, {
        id: id
    });
    // console.log('service');
    // console.log(data);
    // console.log(data.reimbursement.first_name);
    // console.log(JSON.parse(data.reimbursement));
    // console.log(data.reimbursement.first_name);

    return data;
};

reimbursementsServices.addReimbursement = async (reimbursement) => {
    const config = !reimbursement?.files
        ? {}
        : {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          };

    const { data } = await jwtAuthAxios.post(
        `/reimbursements`,
        reimbursement.formikData,
        config
    );
    return data;
};

reimbursementsServices.approveReimbursement = async (reimbursement) => {
    const { data } = await jwtAuthAxios.patch(
        `/reimbursements/approve/${reimbursement.reimbursementId}`,
        reimbursement
    );
    return data;
};

reimbursementsServices.issueReimbursement = async (reimbursement) => {
    const { data } = await jwtAuthAxios.patch(
        `/reimbursements/issue/${reimbursement.reimbursementId}`,
        reimbursement
    );
    return data;
};

reimbursementsServices.validateReimbursement = async (reimbursement) => {
    const { data } = await jwtAuthAxios.patch(
        `/reimbursements/validate/${reimbursement.reimbursementId}`,
        reimbursement
    );
    return data;
};

reimbursementsServices.updateReimbursement = async (reimbursement) => {
    const { data } = await jwtAuthAxios.patch(
        `/reimbursements/${reimbursement.reimbursementId}`,
        reimbursement
    );
    return data;
};

reimbursementsServices.cancelReimbursement = async (reimbursement) => {
    const { data } = await jwtAuthAxios.patch(
        `/reimbursements/cancel/${reimbursement.reimbursementId}`,
        reimbursement
    );
    return data;
};

reimbursementsServices.deleteReimbursement = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/reimbursements/${id}`, {
        id: id
    });
    return data;
};

reimbursementsServices.deleteMultipleReimbursements = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/reimbursements`, {
        data: ids
    });
    return data;
};

export { reimbursementsServices };
