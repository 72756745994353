import React from 'react';
import PropTypes from 'prop-types';

import { useDropzone } from 'react-dropzone';
import Avatar from '@mui/material/Avatar';
import { forwardRef } from 'react';

const JumboAvatarDropzone = forwardRef(
    ({ src, alt, onFileSelection, sx }, ref) => {
        const { getRootProps, getInputProps } = useDropzone({
            accept: 'image/*',
            onDrop: (acceptedFiles) => {
                onFileSelection(
                    URL.createObjectURL(acceptedFiles[0]),
                    acceptedFiles
                );
            }
        });

        return (
            <div {...getRootProps({ className: 'dropzone pointer' })}>
                <input {...getInputProps()} ref={ref} />
                <Avatar alt={alt} src={src} sx={sx} />
            </div>
        );
    }
);

JumboAvatarDropzone.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    onFileSelection: PropTypes.func,
    sx: PropTypes.object
};

export default JumboAvatarDropzone;
