import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const uniapplicationsServices = {};

uniapplicationsServices.getAllUniapplications = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/uni-applications',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

uniapplicationsServices.getUniapplications = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/uni-applications', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

uniapplicationsServices.getUniapplicationDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(
        `/uni-applications/uni-application/${id}`,
        {
            id: id
        }
    );

    return data;
};

uniapplicationsServices.addUniapplication = async (uniapplications) => {
    const { data } = await jwtAuthAxios.post(
        `/uni-applications`,
        uniapplications
    );
    return data;
};

uniapplicationsServices.updateUniapplication = async (uniapplications) => {
    // console.log('Service Uniapplication');
    // console.log(uniapplications);
    const { data } = await jwtAuthAxios.patch(
        `/uni-applications/${uniapplications.id}`,
        uniapplications
    );
    return data;
};

uniapplicationsServices.deleteUniapplication = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/uni-applications/${id}`, {
        id: id
    });
    return data;
};

uniapplicationsServices.deleteMultipleUniapplications = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/uni-applications`, {
        data: ids
    });
    return data;
};

export { uniapplicationsServices };
