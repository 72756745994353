import React from 'react';
import ExpensetypesAppProvider from './ExpensetypesAppProvider';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import PageHeader from '../../../../layouts/shared/headers/PageHeader/PageHeader';
import List from './components/List';
import { useParams } from 'react-router-dom';
// import Detail from './components/Detail';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import { useMediaQuery } from '@mui/material';

const ExpensetypesApp = () => {
    const { theme } = useJumboTheme();
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(
        () => ({
            sidebar: {
                sx: {
                    [theme.breakpoints.up('lg')]: {
                        position: 'sticky',
                        zIndex: 5,
                        top: 96,
                        minHeight: 'auto',
                        display: 'none'
                    },
                    [theme.breakpoints.down('lg')]: {
                        display: 'none'
                    }
                }
            },
            wrapper: {
                sx: {
                    alignItems: 'flex-start'
                }
            }
        }),
        [theme]
    );

    return (
        <ExpensetypesAppProvider>
            <JumboContentLayout
                sidebar={null}
                header={
                    <PageHeader
                        title={'Expense Types'}
                        subheader={'Types of Company/business expenses'}
                    />
                }
                layoutOptions={layoutOptions}
            >
                <List />
            </JumboContentLayout>
        </ExpensetypesAppProvider>
    );
};
export default ExpensetypesApp;
