import React from 'react';

import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import JumboRadioGroup from '@jumbo/components/JumboFormik/JumboRadioGroup';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useMutation } from 'react-query';
import { requestsServices } from 'app/services/requests-services';
import { countriesServices } from 'app/services/countries-services';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import axios from 'axios';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

import _ from 'lodash';

const validationSchema = yup.object({
    // comment: yup
    //     .string('Select travel comment name')
    //     .required('Comment is required')
});
const ApprovalForm = ({ onSave, request, hideDialog }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [countries, setCountries] = React.useState(false);

    const { mutate: submitApprovalMutation, isLoading } = useMutation(
        requestsServices.approveRequest,
        {
            onSuccess: () => {
                enqueueSnackbar(
                    'Request status has been updated successfully.',
                    {
                        variant: 'success'
                    }
                );
                onSave();
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(
                    error?.message ? error.message : error.toString(),
                    {
                        variant: 'error'
                    }
                );

                return false;
            }
        }
    );

    const onApprove = async (data) => {
        if (data?.accept === false && data.comment === '') {
            enqueueSnackbar('Please enter comment', {
                variant: 'warning'
            });
            return;
        }

        try {
            submitApprovalMutation({
                requestId: request.id,
                accept: data.accept,
                comment: data.comment
            });
        } catch (error) {}
    };
    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto'
            }}
        >
            <Formik
                validateOnChange={true}
                initialValues={{
                    comment: ''
                }}
                validationSchema={validationSchema}
                onSubmit={async (data, { setSubmitting }) => {
                    if (data.action === 'approve') {
                        await onApprove({
                            comment: data.comment,
                            accept: true
                        });
                    } else if (data.action === 'reject') {
                        await onApprove({
                            comment: data.comment,
                            accept: false
                        });
                    }
                }}
            >
                {({
                    isSubmitting,
                    errors,
                    touched,
                    values,
                    setFieldValue,
                    setFieldError,
                    ...restProps
                }) => (
                    <Form
                        style={{ textAlign: 'left' }}
                        noValidate
                        autoComplete="off"
                    >
                        <Div sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        sx={{
                                            marginTop: '8px'
                                        }}
                                        id="comment"
                                        name="comment"
                                        label="Enter your Comment"
                                        placeholder="Enter comment/reason here"
                                        multiline
                                        rows={4}
                                    />
                                </Grid>
                                <Field type="hidden" name="action" />
                                <Grid item xs={12} md={6}>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        color="success"
                                        sx={{ mr: 1 }}
                                        loading={
                                            isLoading &&
                                            values.action === 'approve'
                                        }
                                        onClick={() =>
                                            setFieldValue('action', 'approve')
                                        }
                                        fullWidth
                                    >
                                        Approve
                                    </LoadingButton>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        color="error"
                                        size="large"
                                        onClick={() =>
                                            setFieldValue('action', 'reject')
                                        }
                                        loading={
                                            isLoading &&
                                            values.action === 'reject'
                                        }
                                        fullWidth
                                    >
                                        Reject
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Div>
                    </Form>
                )}
            </Formik>
        </Div>
    );
};
/* Todo officeItem, onSave prop define */
export default ApprovalForm;
