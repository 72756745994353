import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const incometypesServices = {};

incometypesServices.getAllIncometypes = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/income-types',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

incometypesServices.getIncometypes = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/income-types', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

incometypesServices.getIncometypeDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(
        `/income-typess/income-type/${id}`,
        {
            id: id
        }
    );

    return data;
};

incometypesServices.addIncometype = async (incometypes) => {
    const { data } = await jwtAuthAxios.post(`/income-types`, incometypes);
    return data;
};

incometypesServices.updateIncometype = async (incometypes) => {
    // console.log('Service Incometype');
    // console.log(incometypes);
    const { data } = await jwtAuthAxios.patch(
        `/income-types/${incometypes.id}`,
        incometypes
    );
    return data;
};

incometypesServices.deleteIncometype = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/income-types/${id}`, {
        id: id
    });
    return data;
};

incometypesServices.deleteMultipleIncometypes = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/income-types`, {
        data: ids
    });
    return data;
};

export { incometypesServices };
