import Page from '@jumbo/shared/Page';
import EmployeesList from 'app/pages/management/Employees/EmployeesList';
import PerformancesList from 'app/pages/management/Performances/PerformancesList';
import TimeOffCalendar from 'app/pages/management/TimeOff/TimeOffCalendar';
import OfficesList from 'app/pages/management/Offices/OfficesList';
import AssetsList from 'app/pages/management/Assets/AssetsList';
import DepositsList from 'app/pages/management/Deposits/DepositsList';
import IncomesList from 'app/pages/management/Incomes/IncomesList';
import authRoles from 'app/config/authRoles';

const managementRoutes = {
    settings: {
        layout: {}
    },
    auth: authRoles?.consultant,
    routes: [
        {
            action: 'read',
            subject: 'Deposits',
            auth: authRoles?.consultant,
            path: '/management/deposits',
            element: <Page component={DepositsList} />
        },
        {
            action: 'read',
            subject: 'Incomes',
            auth: authRoles?.consultant,
            path: '/management/earnings',
            element: <Page component={IncomesList} />
        },
        {
            action: 'read',
            subject: 'Inventory',
            auth: authRoles?.consultant,
            path: '/management/inventory',
            element: <Page component={AssetsList} />
        },
        {
            action: 'read',
            subject: 'Employees',
            auth: authRoles?.hr,
            path: '/management/employees',
            element: <Page component={EmployeesList} />
        },
        {
            action: 'read',
            subject: 'KPIs',
            auth: authRoles?.consultant,
            path: '/management/performances',
            element: <Page component={PerformancesList} />
        },
        {
            action: 'read',
            subject: 'Timeoffs',
            auth: authRoles?.consultant,
            path: '/management/timeoff',
            element: <Page component={TimeOffCalendar} />
        },
        {
            action: 'read',
            subject: 'Offices',
            auth: authRoles?.hr,
            path: '/management/offices',
            element: <Page component={OfficesList} />
        }
    ]
};
export default managementRoutes;
