import React from 'react';
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemAvatar,
    Card,
    CardHeader,
    CardContent,
    Checkbox,
    Divider,
    Tooltip,
    Typography,
    Avatar,
    Stack,
    Alert,
    Chip
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DeleteIcon from '@mui/icons-material/Delete';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import IconButton from '@mui/material/IconButton';
import BusinessIcon from '@mui/icons-material/Business';
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import styled from '@emotion/styled';
import Slide from '@mui/material/Slide';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import Div from '@jumbo/shared/Div';
import { reimbursementsServices } from '../../../../../services/reimbursements-services';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import IssueForm from '../ReimbursementDetail/IssueForm';
import useReimbursementsApp from '../../hooks/useReimbursementsApp';
import { useMutation } from 'react-query';
import ReimbursementDetail from '../ReimbursementDetail';
import JumboGridItem from '@jumbo/components/JumboList/components/JumboGridItem';
import { useSnackbar } from 'notistack';
import { capitalizeAllWords, formatNumber } from 'app/utils/appHelpers';
import _ from 'lodash';
import ReimbursementStatus from 'app/pages/statuses/ReimbursementStatus';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useNavigate } from 'react-router-dom';

const Item = styled(Span)(({ theme }) => ({
    minWidth: 0,
    flexGrow: 0,
    padding: theme.spacing(0, 1)
}));

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const ReimbursementItem = ({ reimbursement, view }) => {
    console.log('reimbursement');
    console.log(reimbursement);

    const { authUser } = useJumboAuth();
    const [reimbursementStatus, setReimbursementStatus] = React.useState([
        {
            id: 0,
            name: 'Inactive',
            slug: 'inactive',
            color: '#ca404f'
        }
    ]);
    const { enqueueSnackbar } = useSnackbar();

    const profilePic = reimbursement?.profile_pic
        ? process.env.REACT_APP_BACKEND +
          '/' +
          reimbursement?.employeeId?.profile_pic
        : null;

    const { showDialog, hideDialog } = useJumboDialog();
    const { setReimbursementsListRefresh } = useReimbursementsApp();
    const [favorite, setFavorite] = React.useState(reimbursement?.starred);

    const navigate = useNavigate();

    const handleItemClick = () => {
        navigate(`/app/expenses/reimbursements/${reimbursement.id}`);
        return;
    };

    const deleteReimbursementMutation = useMutation(
        reimbursementsServices.delete,
        {
            onSuccess: () => {
                hideDialogAndRefreshReimbursementsList();
                enqueueSnackbar(
                    'Reimbursement has been deleted successfully.',
                    {
                        variant: 'success'
                    }
                );
            },
            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        }
    );

    const hideDialogAndRefreshReimbursementsList = React.useCallback(() => {
        hideDialog();
        setReimbursementsListRefresh(true);
    }, [hideDialog, setReimbursementsListRefresh]);

    const handleReimbursementUpdate = React.useCallback(() => {
        hideDialog();
        setReimbursementsListRefresh(true);
    }, [hideDialog, setReimbursementsListRefresh]);

    const showReimbursementDetail = React.useCallback(() => {
        showDialog({
            content: (
                <ReimbursementDetail
                    onSave={handleReimbursementUpdate}
                    authUser={authUser}
                    reimbursement={reimbursement}
                    onClose={hideDialog}
                />
            ),
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true,
            maxWidth: 'md'
        });
    }, [showDialog, reimbursement]);

    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case 'review':
                showReimbursementDetail(reimbursement);
                break;
            case 'reimburse':
                showDialog({
                    title: 'Issue Reimbursement',
                    content: (
                        <IssueForm
                            reimbursement={reimbursement}
                            onSave={hideDialogAndRefreshReimbursementsList}
                        />
                    ),
                    TransitionComponent: Transition,
                    disableDefaultClose: true,
                    closeAction: true,
                    maxWidth: 'sm'
                });
                break;
            case 'delete':
                showDialog({
                    variant: 'confirm',
                    title: 'Are you sure about deleting this reimbursement?',
                    content:
                        "You won't be able to recover this reimbursement later",
                    onYes: () =>
                        deleteReimbursementMutation.mutate(reimbursement),
                    onNo: hideDialog
                });
        }
    };

    React.useEffect(() => {
        setReimbursementStatus(
            _.find(
                ReimbursementStatus,
                (status) => status.id === reimbursement?.status
            )
        );
    }, [reimbursement]);

    return (
        <React.Fragment>
            <JumboListItem
                componentElement={'div'}
                itemData={reimbursement}
                secondaryAction={
                    <JumboDdMenu
                        icon={<MoreHorizIcon />}
                        menuItems={[
                            {
                                icon: <PreviewIcon />,
                                title: 'Review',
                                action: 'review'
                            },
                            reimbursement?.status === 1 &&
                            _.map(
                                reimbursement?.moduleId?.admins,
                                (admin) => admin.id
                            ).includes(authUser?.userId)
                                ? {
                                      icon: <MonetizationOnIcon />,
                                      title: 'Issue Reimbursement',
                                      action: 'reimburse'
                                  }
                                : reimbursement?.status === 1 &&
                                  reimbursement?.employeeId?.id ===
                                      authUser.userId && {
                                      icon: <MonetizationOnIcon />,
                                      title: 'Return Excess',
                                      action: 'reimburse'
                                  }

                            // reimbursement?.status === 1 &&
                            //     (_.map(
                            //         reimbursement?.moduleId?.admins,
                            //         (admin) => admin.id
                            //     ).includes(authUser?.userId) ||
                            //         reimbursement?.employeeId?.id ===
                            //             authUser?.userId) && {
                            //         icon: <MonetizationOnIcon />,
                            //         title: 'Submit Reimbursement',
                            //         action: 'reimburse'
                            //     }
                        ]}
                        onClickCallback={handleItemAction}
                    />
                }
                sx={{
                    cursor: 'pointer',
                    borderTop: 1,
                    borderColor: 'divider',
                    '&:hover': {
                        bgcolor: 'action.hover'
                    }
                }}
            >
                <ListItemAvatar onClick={handleItemClick}>
                    <Avatar alt={reimbursement?.first_name} src={profilePic} />
                </ListItemAvatar>
                <ListItemText
                    onClick={handleItemClick}
                    primary={
                        <Typography variant={'body1'} component={'div'}>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                sx={{ minWidth: 0 }}
                            >
                                <Item
                                    sx={{
                                        flexBasis: {
                                            xs: '100%',
                                            sm: '50%',
                                            md: '26%'
                                        }
                                    }}
                                >
                                    <Typography
                                        variant={'h5'}
                                        fontSize={14}
                                        lineHeight={1.25}
                                        mb={0}
                                        noWrap
                                    >
                                        {reimbursement?.employeeId?.first_name}{' '}
                                        {reimbursement?.employeeId?.last_name}
                                    </Typography>
                                    <Typography
                                        variant={'body1'}
                                        noWrap
                                        color={'text.secondary'}
                                        sx={{
                                            display: { sm: 'block' }
                                        }}
                                    >
                                        {
                                            reimbursement?.employeeId
                                                ?.email_address
                                        }
                                    </Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { md: '17%' },
                                        display: { xs: 'none', md: 'block' },
                                        minWidth: '17%'
                                    }}
                                >
                                    <Typography
                                        variant={'body1'}
                                        sx={{
                                            color: 'primary.main'
                                        }}
                                        noWrap
                                    >
                                        {capitalizeAllWords(
                                            reimbursement?.type
                                        )}
                                    </Typography>
                                </Item>

                                <Item
                                    sx={{
                                        flexBasis: {
                                            sm: '50%',
                                            md: '42%'
                                        },
                                        display: {
                                            xs: 'flex',
                                            sm: 'flex'
                                        },
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        minWidth: '42%'
                                    }}
                                >
                                    <Typography
                                        variant={''}
                                        sx={{
                                            textAlign: 'left'
                                        }}
                                    >
                                        {reimbursement?.reimbursement_name}
                                    </Typography>
                                </Item>

                                <Item
                                    sx={{
                                        flexBasis: { md: '15%' },
                                        display: { xs: 'none', md: 'block' },
                                        alignItems: 'right'
                                    }}
                                >
                                    <Typography
                                        variant={'body1'}
                                        sx={{
                                            fontWeight: 500,
                                            textAlign: 'right'
                                        }}
                                        noWrap
                                    >
                                        {formatNumber(
                                            reimbursement?.reimbursement_amount
                                        )}
                                    </Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { md: '22%' },
                                        display: { xs: 'none', md: 'block' }
                                    }}
                                >
                                    <JumboChipsGroup
                                        chips={
                                            reimbursement
                                                ? [reimbursementStatus]
                                                : [
                                                      {
                                                          id: 0,
                                                          name: 'Inactive',
                                                          slug: 'inactive',
                                                          color: '#ca404f'
                                                      }
                                                  ]
                                        }
                                        mapKeys={{ label: 'name' }}
                                        spacing={1}
                                        size={'small'}
                                        max={1}
                                    />
                                </Item>
                            </Stack>
                        </Typography>
                    }
                />
            </JumboListItem>
        </React.Fragment>
    );
};
/* Todo reimbursement, view prop define */
export default ReimbursementItem;
