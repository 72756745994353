import React from 'react';
import Div from '@jumbo/shared/Div';
import Link from '@mui/material/Link';
import { ASSET_IMAGES } from '../../utils/constants/paths';

const Logo = ({ mini, mode, sx }) => {
    return (
        <Div sx={{ display: 'inline-flex', ...sx }}>
            <Link href={'/dashboards/overview'}>
                {!mini ? (
                    <img
                        src={
                            mode === 'light'
                                ? `${ASSET_IMAGES}/IEC-logo-128.png`
                                : `${ASSET_IMAGES}/IEC-logo-128.png`
                        }
                        alt="IEC International"
                    />
                ) : (
                    <img
                        src={
                            mode === 'light'
                                ? `${ASSET_IMAGES}/IEC-logo-128.png`
                                : `${ASSET_IMAGES}/IEC-logo-128.png`
                        }
                        alt="IEC International"
                    />
                )}
            </Link>
        </Div>
    );
};

Logo.defaultProps = {
    mode: 'light'
};

export default Logo;
