import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useMutation } from 'react-query';
import { coursesServices } from 'app/services/courses-services';
import { institutionsServices } from 'app/services/institutions-services';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { categories } from 'app/utils/constants/variables';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import axios from 'axios';

const validationSchema = yup.object({
    institutionId: yup
        .string('Select institution')
        .required('Institution is required'),
    course_name: yup
        .string('Enter course name')
        .required('Course name is required'),
    course_category: yup
        .string('Enter course category')
        .required('Course category is required')
    // description: yup
    //     .string('Enter course description')
    //     .required('Course description is required is required')
});

const EditForm = ({ listItem, onUpdate, hideDialog }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [institutions, setInstitutions] = React.useState(false);

    const { mutate: editCourseMutation, isLoading } = useMutation(
        coursesServices.updateCourse,
        {
            onSuccess: () => {
                onUpdate();

                enqueueSnackbar('Course has been updated successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onEdit = async (course) => {
        try {
            editCourseMutation({ id: listItem.id, ...course });
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };
        const fetchInstitutions = async () => {
            try {
                const res = await institutionsServices.getAllInstitutions(
                    cToken
                );

                setInstitutions(res.institutions);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchInstitutions();

        return () => {
            cancelToken.cancel();
        };
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {!institutions && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {institutions && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        course_name: listItem.course_name,
                        course_category: listItem?.course_category,
                        institutionId: listItem?.institutionId.id,
                        countryId:
                            listItem?.countryId.id ||
                            listItem?.institutionId.countryId.id,
                        description: listItem?.description || ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onEdit(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <Field name="institutionId">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    id="institutionId"
                                                    sx={{
                                                        width: 500,
                                                        maxWidth: '100%'
                                                    }}
                                                    size="small"
                                                    options={institutions}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.institution_name
                                                            ? option.institution_name
                                                            : ''
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                '& > img': {
                                                                    mr: 2,
                                                                    flexShrink: 0
                                                                }
                                                            }}
                                                            {...props}
                                                        >
                                                            {
                                                                option.institution_name
                                                            }
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Institution"
                                                            placeholder="Choose an institution"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.institutionId &&
                                                                !!errors.institutionId
                                                            }
                                                            helperText={
                                                                touched?.institutionId &&
                                                                errors.institutionId
                                                            }
                                                        />
                                                    )}
                                                    onChange={
                                                        (event, newValue) => {
                                                            values.institutionId =
                                                                newValue?.id;
                                                            values.countryId =
                                                                newValue?.countryId?.id;
                                                        }
                                                        // Set the new value in the form field
                                                    }
                                                    value={
                                                        institutions.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.institutionId
                                                        ) || null
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Field name="course_category">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    id="course_category"
                                                    sx={{
                                                        width: '100%',
                                                        maxWidth: '100%'
                                                    }}
                                                    size="small"
                                                    options={categories}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.category_name
                                                            ? option.category_name
                                                            : ''
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            {...props}
                                                        >
                                                            {
                                                                option.category_name
                                                            }
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Course Category"
                                                            placeholder="Choose a Course Category"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.course_category &&
                                                                !!errors.course_category
                                                            }
                                                            helperText={
                                                                touched?.course_category &&
                                                                errors.course_category
                                                            }
                                                        />
                                                    )}
                                                    onChange={
                                                        (event, newValue) => {
                                                            values.course_category =
                                                                newValue?.id;
                                                        }
                                                        // Set the new value in the form field
                                                    }
                                                    value={
                                                        categories.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.course_category
                                                        ) || null
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="course_name"
                                            name="course_name"
                                            label="Course Name"
                                            placeholder="Enter Course Name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="description"
                                            name="description"
                                            label="Enter description"
                                            placeholder="Enter the course description"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                    >
                                        Update
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo courseItem, onSave prop define */
export default EditForm;
