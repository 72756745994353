import React from 'react';
import { Ability, AbilityBuilder } from '@casl/ability';

import { useAbility } from '@casl/react';
import { AbilityContext } from 'app/AppAbility';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import {
    routesForAuthenticatedOnly,
    routesForNotAuthenticatedOnly
} from '../../../app/routes';
import useRoutePathMatch from '@jumbo/hooks/useRoutePathMatch';
import { removeToken, storeToken } from './authHelpers';
import { config } from '../../../app/config/main';
import { AuthContext } from '@jumbo/components/JumboAuthProvider/JumboAuthContext';
import jwtService from 'app/services/auth-services';
import JumboUtils from 'app/services/auth/JumboUtils';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

import _ from 'lodash';
import defaultAbility2 from 'app/config/defaultAbility2';
import defaultAbility from 'app/config/defaultAbility';
import { CircularProgress } from '@mui/material';

const storedToken = localStorage.getItem('token');
let firstTimePageLoad = true;

const init = () => {
    let authUser = null;

    if (!config?.authSetting) {
        throw Error(
            `You are using AuthProvider but you haven't setup authSetting inside /src/app/config/main.js's config object`
        );
    }

    if (storedToken) {
        storeToken(storedToken); // also sets axios header
    }

    return {
        authToken: storedToken ?? null,
        authUser: authUser,
        isLoading: false,
        fallbackPath: config.authSetting.fallbackPath
    };
};

const authReducer = (state, action) => {
    switch (action.type) {
        case 'set-auth-data':
            return {
                ...state,
                ...action.payload
            };

        case 'start-loading':
            return {
                ...state,
                isLoading: true
            };

        case 'stop-loading':
            return {
                ...state,
                isLoading: false
            };
        default:
            return {
                ...state,
                isLoading: false
            };
    }
};

const JumboAuthProvider = ({ children, ...restProps }) => {
    const { showDialog, hideDialog } = useJumboDialog();
    const ability = useAbility(AbilityContext);

    const [checkingAuth, setCheckingAuth] = React.useState(false);
    const [authOptions, setAuthOptions] = React.useReducer(
        authReducer,
        restProps,
        init
    );

    const [logout, setLogout] = React.useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const matchedAuthenticated = matchRoutes(
        [...routesForAuthenticatedOnly],
        location?.pathname
    );
    const matchedNotAuthenticated = matchRoutes(
        [...routesForNotAuthenticatedOnly],
        location?.pathname
    );

    const isAuthenticatedRouteOnly =
        matchedAuthenticated && matchedAuthenticated.length > 0;
    const isNotAuthenticatedRouteOnly =
        matchedNotAuthenticated && matchedNotAuthenticated.length > 0;

    React.useEffect(() => {
        if (logout) {
            removeToken();
            setAuthOptions({
                type: 'set-auth-data',
                payload: { authToken: null, authUser: null, isLoading: false }
            });
            setLogout(false);
            setAuthOptions({ type: 'stop-loading' });
        }
    }, [logout]);

    const updateAbility = React.useCallback(async (ability, permissions) => {
        ability.update(permissions);
    }, []);

    const setAuthToken = React.useCallback(async (token) => {
        setAuthOptions({ type: 'start-loading' });

        if (!token) {
            setLogout(true);
            return;
        }

        storeToken(token);

        try {
            const authUser = await config?.authSetting?.getAuthUserService();
            if (authUser) {
                updateAbility(ability, authUser.permissions);
                setAuthOptions({
                    type: 'set-auth-data',
                    payload: {
                        authToken: token,
                        authUser: authUser,
                        isLoading: false
                    }
                });

                setAuthOptions({ type: 'stop-loading' });

                return;
            }

            setLogout(true);
        } catch (error) {
            setLogout(true);
        }
    }, []);

    //todo: maybe in next version
    const setRedirectPath = React.useCallback((redirectPath) => {
        setAuthOptions({
            type: 'set-redirect-path',
            payload: { redirectPath }
        });
    }, []);

    const setAuthData = React.useCallback((data) => {
        setAuthOptions({ type: 'set-auth-data', payload: data });
    }, []);

    const contextValue = React.useMemo(() => {
        return {
            ...authOptions,
            setAuthData,
            setRedirectPath,
            setAuthToken,
            setAuthOptions
        };
    }, [authOptions, setAuthData, setRedirectPath, setAuthToken]);

    React.useEffect(() => {
        jwtService.init();

        jwtService.on('onLogout', () => {
            pass();
        });

        jwtService.on('onAutoLogout', (message) => {
            pass();
        });

        function success(user, message) {
            console.log('authOptions?.isLoading');
        }

        function pass() {
            hideDialog();
            setLogout(true);
        }
    }, []);

    React.useEffect(() => {
        if (!authOptions.authToken) {
            if (isAuthenticatedRouteOnly) {
                ability.update([]);
                navigate(authOptions?.fallbackPath);
            }
        } else if (!authOptions.authUser) {
            setAuthToken(authOptions?.authToken);
        } else if (isNotAuthenticatedRouteOnly) {
            if (!firstTimePageLoad) {
                navigate(
                    config.authSetting.redirectNotAuthenticatedPath ?? '/'
                );
            } else firstTimePageLoad = false;
        } else {
            let allowedPagePermission = false;

            if (
                matchedAuthenticated &&
                matchedAuthenticated.length &&
                matchedAuthenticated[0].route.action
            ) {
                allowedPagePermission =
                    JumboUtils.hasPermission(
                        matchedAuthenticated[0]?.route?.auth,
                        authOptions?.authUser?.role_name
                    ) &&
                    ability.can(
                        matchedAuthenticated[0]?.route?.action,
                        matchedAuthenticated[0]?.route?.subject
                    );
            } else if (
                matchedAuthenticated &&
                matchedAuthenticated.length &&
                !matchedAuthenticated[0]?.route?.action
            ) {
                allowedPagePermission = JumboUtils.hasPermission(
                    matchedAuthenticated[0]?.route?.auth,
                    authOptions?.authUser?.role_name
                );
            } else {
                navigate('/error/404');
                return;
            }

            if (!allowedPagePermission) {
                navigate('/error/404');
            }
        }
    }, [
        authOptions.authUser,
        matchedAuthenticated,
        matchedNotAuthenticated,
        ability
    ]);

    if (authOptions?.isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100%'
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export default JumboAuthProvider;
