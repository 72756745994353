import React from 'react';
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemAvatar,
    Card,
    CardHeader,
    CardContent,
    Checkbox,
    Divider,
    Tooltip,
    Typography,
    Avatar,
    Stack,
    Chip
} from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import IconButton from '@mui/material/IconButton';
import BusinessIcon from '@mui/icons-material/Business';
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import styled from '@emotion/styled';
import Slide from '@mui/material/Slide';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import Div from '@jumbo/shared/Div';
import { expensesServices } from '../../../../../services/expenses-services';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import ExpenseForm from '../ExpenseForm';
import useExpensesApp from '../../hooks/useExpensesApp';
import { useMutation } from 'react-query';
import ExpenseDetail from '../ExpenseDetail';
import JumboGridItem from '@jumbo/components/JumboList/components/JumboGridItem';
import { useSnackbar } from 'notistack';
import { capitalizeAllWords, formatNumber } from 'app/utils/appHelpers';
import _ from 'lodash';
import ExpenseStatus from 'app/pages/statuses/ExpenseStatus';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

const Item = styled(Span)(({ theme }) => ({
    minWidth: 0,
    flexGrow: 0,
    padding: theme.spacing(0, 1)
}));

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const ExpenseItem = ({ expense, view }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [expenseStatus, setExpenseStatus] = React.useState([
        {
            id: 0,
            name: 'Inactive',
            slug: 'inactive',
            color: '#ca404f'
        }
    ]);

    const profilePic = expense?.profile_pic
        ? process.env.REACT_APP_BACKEND + '/' + expense?.employeeId?.profile_pic
        : null;

    const { showDialog, hideDialog } = useJumboDialog();
    const { setExpensesListRefresh } = useExpensesApp();
    const [favorite, setFavorite] = React.useState(expense?.starred);
    const deleteExpenseMutation = useMutation(expensesServices.delete, {
        onSuccess: () => {
            hideDialogAndRefreshExpensesList();
            enqueueSnackbar('Expense has been deleted successfully.', {
                variant: 'success'
            });
        },
        onError: (error) => {
            enqueueSnackbar(error, {
                variant: 'error'
            });
        }
    });

    const hideDialogAndRefreshExpensesList = React.useCallback(() => {
        hideDialog();
        setExpensesListRefresh(true);
    }, [hideDialog, setExpensesListRefresh]);

    const showExpenseDetail = React.useCallback(() => {
        showDialog({
            content: <ExpenseDetail expense={expense} onClose={hideDialog} />,
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true
        });
    }, [showDialog, expense]);

    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case 'review':
                showExpenseDetail();
                break;
            case 'edit':
                showDialog({
                    title: 'Update expense detail',
                    content: (
                        <ExpenseForm
                            expense={expense}
                            onSave={hideDialogAndRefreshExpensesList}
                        />
                    ),
                    maxWidth: 'md'
                });
                break;
            case 'delete':
                showDialog({
                    variant: 'confirm',
                    title: 'Are you sure about deleting this expense?',
                    content: "You won't be able to recover this expense later",
                    onYes: () => deleteExpenseMutation.mutate(expense),
                    onNo: hideDialog
                });
        }
    };

    React.useEffect(() => {
        setExpenseStatus(
            _.find(ExpenseStatus, (status) => status.id === expense?.status)
        );
    }, [expense]);

    return (
        <React.Fragment>
            <JumboListItem
                componentElement={'div'}
                itemData={expense}
                secondaryAction={
                    <JumboDdMenu
                        icon={<MoreHorizIcon />}
                        menuItems={[
                            // {
                            //     icon: <EditIcon />,
                            //     title: 'Edit',
                            //     action: 'edit'
                            // },
                            {
                                icon: <PreviewIcon />,
                                title: 'Review',
                                action: 'review'
                            }
                        ]}
                        onClickCallback={handleItemAction}
                    />
                }
                sx={{
                    cursor: 'pointer',
                    borderTop: 1,
                    borderColor: 'divider',
                    '&:hover': {
                        bgcolor: 'action.hover'
                    }
                }}
            >
                <ListItemAvatar onClick={showExpenseDetail}>
                    <Avatar alt={expense?.first_name} src={profilePic} />
                </ListItemAvatar>
                <ListItemText
                    onClick={showExpenseDetail}
                    primary={
                        <Typography variant={'body1'} component={'div'}>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                sx={{ minWidth: 0 }}
                            >
                                <Item
                                    sx={{
                                        flexBasis: {
                                            xs: '100%',
                                            sm: '50%',
                                            md: '25%'
                                        }
                                    }}
                                >
                                    <Typography
                                        variant={'h5'}
                                        fontSize={14}
                                        lineHeight={1.25}
                                        mb={0}
                                        noWrap
                                    >
                                        {expense?.employeeId?.first_name}{' '}
                                        {expense?.employeeId?.last_name}
                                    </Typography>
                                    <Typography
                                        variant={'body1'}
                                        noWrap
                                        color={'text.secondary'}
                                        sx={{
                                            display: { sm: 'none' }
                                        }}
                                    >
                                        {expense?.employeeId?.email_address}
                                    </Typography>
                                </Item>

                                <Item
                                    sx={{
                                        flexBasis: { md: '17%' },
                                        display: { xs: 'none', md: 'block' },
                                        minWidth: '17%'
                                    }}
                                >
                                    <Typography
                                        variant={'body1'}
                                        sx={{
                                            color: 'primary.main'
                                        }}
                                        noWrap
                                    >
                                        {capitalizeAllWords(expense?.module)}
                                    </Typography>
                                </Item>
                                {expense?.module === 'travel' && (
                                    <Item
                                        sx={{
                                            flexBasis: { sm: '50%', md: '42%' },
                                            display: { xs: 'flex', sm: 'flex' },
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            minWidth: '42%'
                                        }}
                                    >
                                        <Typography variant={'body1'}>
                                            {capitalizeAllWords(
                                                expense?.travel?.travel_type
                                            )}{' '}
                                            {capitalizeAllWords(
                                                expense?.travel?.trip_type
                                            )}{' '}
                                            From{' '}
                                            <Span
                                                sx={{
                                                    color: 'primary.main'
                                                }}
                                            >
                                                {expense?.travel?.origin}
                                            </Span>{' '}
                                            {expense?.travel?.destination ? (
                                                <>
                                                    {'to '}
                                                    <Span
                                                        sx={{
                                                            color: 'primary.main'
                                                        }}
                                                    >
                                                        {
                                                            expense?.travel
                                                                ?.destination
                                                        }
                                                    </Span>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </Typography>
                                    </Item>
                                )}

                                {expense?.module === 'purchase' && (
                                    <Item
                                        sx={{
                                            flexBasis: { sm: '50%', md: '42%' },
                                            display: { xs: 'flex', sm: 'flex' },
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            minWidth: '42%'
                                        }}
                                    >
                                        <Typography
                                            variant={''}
                                            sx={{
                                                textAlign: 'left'
                                            }}
                                        >
                                            {expense?.expense_name}
                                        </Typography>
                                    </Item>
                                )}

                                {expense?.module === 'advance' && (
                                    <Item
                                        sx={{
                                            flexBasis: { sm: '50%', md: '42%' },
                                            display: { xs: 'flex', sm: 'flex' },
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            minWidth: '42%'
                                        }}
                                    >
                                        <Typography
                                            variant={''}
                                            sx={{
                                                textAlign: 'left'
                                            }}
                                        >
                                            {expense?.expense_name}
                                        </Typography>
                                    </Item>
                                )}
                                <Item
                                    sx={{
                                        flexBasis: { md: '15%' },
                                        display: { xs: 'none', md: 'block' },
                                        alignItems: 'right'
                                    }}
                                >
                                    <Typography
                                        variant={'body1'}
                                        sx={{
                                            fontWeight: 500,
                                            textAlign: 'right'
                                        }}
                                        noWrap
                                    >
                                        {formatNumber(expense?.total_amount)}
                                    </Typography>
                                </Item>

                                <Item
                                    sx={{
                                        flexBasis: { md: '22%' },
                                        display: { xs: 'none', md: 'block' }
                                    }}
                                >
                                    <JumboChipsGroup
                                        chips={
                                            expense
                                                ? [expenseStatus]
                                                : [
                                                      {
                                                          id: 0,
                                                          name: 'Inactive',
                                                          slug: 'inactive',
                                                          color: '#ca404f'
                                                      }
                                                  ]
                                        }
                                        mapKeys={{ label: 'name' }}
                                        spacing={1}
                                        size={'small'}
                                        max={1}
                                    />
                                </Item>
                            </Stack>
                        </Typography>
                    }
                />
            </JumboListItem>
        </React.Fragment>
    );
};
/* Todo expense, view prop define */
export default ExpenseItem;
