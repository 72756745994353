import React, { useState } from 'react';
import { Alert, Card, CardContent, TextField, Typography } from '@mui/material';
import Logo from 'app/shared/Logo';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Div from '@jumbo/shared/Div';
import LoadingButton from '@mui/lab/LoadingButton';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { useMutation } from 'react-query';
import { surveyService } from 'app/services/survey-services';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { useParams } from 'react-router-dom';

const validationSchema = yup.object({
    nps: yup.string('Select rating').required('Rating is required')
    // improvement_comment: yup
    //     .string('Enter comment')
    //     .required('Comment is required')
});

const Survey = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [submitted, setSubmitted] = useState(false);
    const params = useParams();
    const { timeCreated, studentId, leadId } = params;

    const { mutate: submitSurveyMutation, isLoading } = useMutation(
        surveyService.submitSurvey,
        {
            onSuccess: () => {
                enqueueSnackbar('Survey has been submitted successfully.', {
                    variant: 'success'
                });
                setSubmitted(true);
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onSubmitSurvey = async (data) => {
        try {
            submitSurveyMutation({ ...data });
        } catch (error) {}
    };

    return (
        <Div
            sx={{
                width: 820,
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {submitted && (
                <Alert
                    severity="success"
                    sx={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Typography variant="h4">
                        {' '}
                        Thank you for taking the time to complete the survey.{' '}
                    </Typography>
                    <br></br>
                    <br></br>
                    We genuinely value your opinions, and your feedback will
                    help us understand what we're doing well and where we can
                    make improvements. Your insights are incredibly important to
                    us, and we're committed to using them to enhance our
                    services and support.
                    <br></br>
                    <br></br>
                    We look forward to continuing to serve you and striving to
                    meet and exceed your expectations.
                </Alert>
            )}
            {!submitted && (
                <Card
                    sx={{
                        display: 'flex',
                        minWidth: '100%',
                        flexDirection: { xs: 'column', md: 'column' },
                        p: '20px'
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            my: '16px'
                        }}
                    >
                        <Logo />
                    </Div>
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            nps: '',
                            improvement_comment: '',
                            studentId: studentId,
                            leadId: leadId,
                            type: 'enrollment',
                            timeCreated: timeCreated
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            await onSubmitSurvey(values);
                        }}
                    >
                        {({
                            isSubmitting,
                            errors,
                            touched,
                            values,
                            isValid,
                            ...restProps
                        }) => (
                            <Form
                                style={{ textAlign: 'left' }}
                                noValidate
                                autoComplete="off"
                            >
                                <Div sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant={'h5'} mb={0}>
                                                How likely are you to recommend
                                                our services to a friend or
                                                fellow student?
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                px: '10px'
                                            }}
                                        >
                                            {[...Array(11).keys()].map(
                                                (value) => (
                                                    <label key={value}>
                                                        <Field
                                                            type="radio"
                                                            name="nps"
                                                            value={value.toString()}
                                                        />
                                                        {value}
                                                    </label>
                                                )
                                            )}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant={'h5'} mb={0}>
                                                How can we improve your
                                                experience?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <JumboTextField
                                                fullWidth
                                                size={'small'}
                                                name="improvement_comment"
                                                label=""
                                                placeholder="How can we improve your experience"
                                                multiline
                                                rows={4}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sx={{
                                                my: '30px'
                                            }}
                                        >
                                            <LoadingButton
                                                fullWidth
                                                type="submit"
                                                variant="contained"
                                                size="large"
                                                sx={{ mb: 3 }}
                                                disabled={
                                                    _.isEmpty(touched) ||
                                                    !isValid
                                                }
                                                loading={isLoading}
                                            >
                                                Submit Feedback
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Div>
                            </Form>
                        )}
                    </Formik>
                </Card>
            )}
        </Div>
    );
};

export default Survey;
