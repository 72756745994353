import jwtAuthAxios from './auth/jwtAuth';

const institutionsServices = {};

institutionsServices.getAllInstitutions = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/institutions',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

institutionsServices.getInstitutions = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/institutions', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

institutionsServices.getInstitutionDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/institutions/institution/${id}`, {
        id: id
    });

    return data;
};

institutionsServices.addInstitution = async (institution) => {
    const { data } = await jwtAuthAxios.post(`/institutions`, institution);
    return data;
};

institutionsServices.uploadInstitutionsCSV = async (csvData) => {
    const { data } = await jwtAuthAxios.post(
        `/institutions/upload-csv`,
        csvData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );
    return data;
};

institutionsServices.updateInstitution = async (institution) => {
    // console.log('Service Institution');
    // console.log(institution);
    const { data } = await jwtAuthAxios.patch(
        `/institutions/${institution.id}`,
        institution
    );
    return data;
};

institutionsServices.deleteInstitution = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/institutions/${id}`, {
        id: id
    });
    return data;
};

institutionsServices.deleteMultipleInstitutions = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/institutions`, {
        data: ids
    });
    return data;
};

export { institutionsServices };
