import React from 'react';
import { useField } from 'formik';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormHelperText from '@mui/material/FormHelperText';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';

const JumboRadioGroup = (props) => {
    const { theme } = useJumboTheme();
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';

    return (
        <FormControl
            fullWidth
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}
        >
            <FormLabel
                sx={{
                    margin: '0 20px 0 0'
                }}
                id={props.labelid}
            >
                {props.label}
            </FormLabel>
            <RadioGroup {...props} {...field} />
            {!props.disabled && (
                <FormHelperText style={{ color: theme.palette.error.main }}>
                    {errorText}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default JumboRadioGroup;
