import jwtAuthAxios from './auth/jwtAuth';

const surveyService = {};

surveyService.getAllSurveys = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/surveys',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );
    return data;
};

surveyService.getSurveys = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];
    const { data } = await jwtAuthAxios.get('/surveys', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

surveyService.submitSurvey = async (surveys) => {
    const { data } = await jwtAuthAxios.post(`/surveys`, surveys);
    return data;
};

surveyService.getLabels = async () => {
    const { data } = await jwtAuthAxios.get('/surveys/labels');
    return data;
};

surveyService.add = async (survey) => {
    const { data } = await jwtAuthAxios.post('/surveys', survey);
    return data;
};

surveyService.update = async (survey) => {
    const { data } = await jwtAuthAxios.patch(`/surveys/${survey.id}`, survey);
    return data;
};

surveyService.delete = async (survey) => {
    const { data } = await jwtAuthAxios.delete(`/surveys/${survey.id}`);
    return data;
};

surveyService.deleteMultiple = async (selectedIDs) => {
    const { data } = await jwtAuthAxios.put('/surveys', {
        surveyIds: selectedIDs
    });
    return data;
};

surveyService.addLabel = async (label) => {
    const { data } = await jwtAuthAxios.post('/surveys/add-label', label);
    return data;
};

surveyService.updateLabel = async (label) => {
    const { data } = await jwtAuthAxios.put('/surveys/update-label', label);
    return data;
};

surveyService.deleteLabel = async (label) => {
    const { data } = await jwtAuthAxios.delete('/surveys/delete-label', {
        params: { id: label.id }
    });
    return data;
};

surveyService.assignLabel = async (params) => {
    const { data } = await jwtAuthAxios.put('/surveys/assign-label', {
        surveyIDs: params?.surveyIDs ?? [],
        labelIDs: params?.labelIDs ?? []
    });
    return data;
};

export { surveyService };
