import React from 'react';
import Stack from '@mui/material/Stack';
import LabelsSelectControl from '../LabelsSelectControl';
import { useMutation } from 'react-query';
import { advancesServices } from 'app/services/advances-services';
import useAdvancesApp from '../../hooks/useAdvancesApp';
import IconButton from '@mui/material/IconButton';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { useSnackbar } from 'notistack';
import { Tooltip } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const BulkActions = () => {
    const { selectedAdvances, setAdvancesListRefresh } = useAdvancesApp();
    const { showDialog, hideDialog } = useJumboDialog();
    const { enqueueSnackbar } = useSnackbar();

    const assignLabelMutation = useMutation(advancesServices.assignLabel, {
        onSuccess: () => {
            hideDialog();
            setAdvancesListRefresh(true);
            enqueueSnackbar('Labels has been applied successfully.', {
                variant: 'success'
            });
        }
    });
    const deleteAdvancesMutation = useMutation(
        advancesServices.deleteMultiple,
        {
            onSuccess: () => {
                hideDialog();
                setAdvancesListRefresh(true);
                enqueueSnackbar('Advances has been deleted successfully.', {
                    variant: 'success'
                });
            }
        }
    );
    const handleBulkDelete = React.useCallback(() => {
        showDialog({
            variant: 'confirm',
            title: 'Are you sure?',
            onYes: () =>
                deleteAdvancesMutation.mutate(
                    selectedAdvances.map((advance) => advance?.id)
                ),
            onNo: hideDialog
        });
    }, [selectedAdvances, deleteAdvancesMutation, hideDialog]);

    const applyLabels = React.useCallback(
        (selectedLabels) => {
            assignLabelMutation.mutate({
                advanceIDs: selectedAdvances.map((advance) => advance?.id),
                labelIDs: selectedLabels.map((label) => label?.id)
            });
        },
        [selectedAdvances]
    );

    return (
        <Stack
            direction={'row'}
            sx={{ backgroundColor: 'transparent', ml: -2 }}
        >
            {/* <Tooltip title={'Delete'}>
                <IconButton onClick={handleBulkDelete}>
                    <DeleteOutlineIcon />
                </IconButton>
            </Tooltip>
            <LabelsSelectControl onDone={applyLabels} /> */}
        </Stack>
    );
};

export default BulkActions;
