import React, { useState } from 'react';
import _ from 'lodash';
import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import { useMutation } from 'react-query';
import { leadsServices } from 'app/services/leads-services';
import { studentServices } from 'app/services/student-services';

import { useSnackbar } from 'notistack';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import axios from 'axios';
import { capitalizeAllWords } from 'app/utils/appHelpers';
import LeadStatus from 'app/pages/statuses/LeadStatus';
import Uniapplication from './Uniapplication/Uniapplication';
import RejectUniapplication from './Uniapplication/RejectUniapplication';
import AcceptUniapplication from './Uniapplication/AcceptUniapplication';
import DepositPayment from './Uniapplication/DepositPayment';
import InitiateDepositPayment from './Uniapplication/InitiateDepositPayment';

import Visaapplication from './Visaapplication/Visaapplication';
import RejectVisaapplication from './Visaapplication/RejectVisaapplication';
import AcceptVisaapplication from './Visaapplication/AcceptVisaapplication';

import Accomodationapplication from './Accomodationapplication/Accomodationapplication';
import RejectAccomodationapplication from './Accomodationapplication/RejectAccomodationapplication';
import AcceptAccomodationapplication from './Accomodationapplication/AcceptAccomodationapplication';

import DocumentsUpload from 'app/shared/DocumentsUpload';

import Enrollment from './Enrollment/Enrollment';

const validationSchema = yup.object({
    status: yup.string('Select status').required('Status is required'),
    comment: yup.string('Enter Comment').required('Comment is required')
});

const initialVals = {
    status: '',
    leadId: '',
    applicationId: '',
    uniapplicationId: '',
    uniapplicationCode: '',
    applicationCode: '',
    countryId: '',
    country_name: '',
    institutionId: null,
    institution_name: '',
    rejection_date: '',
    comment: '',
    courseId: [],
    course_name: null,
    option_one: '',
    option_two: '',
    option_three: '',
    offer_waiting_period: '',
    requirements: '',
    application_fee: '',
    application_fee_currency: '',
    application_date: '',
    transactionId: '',
    depositType: '',
    first_year_tuition_fee: '',
    tuition_deposit_amount: '',
    date_lead_created: '',
    deposit_payment_date: ''
};

const re = /(?:\.([^.]+))?$/;

const UpdateForm = ({
    onUpdate,
    hideDialog,
    userDetails,
    listItem,
    errors,
    touched,
    values,
    setFieldValue,
    setFieldError
}) => {
    console.log('LEAD INFO');
    console.log(listItem);

    const { enqueueSnackbar } = useSnackbar();
    const [status, setStatus] = React.useState(false);
    const [statusOptions, setStatusOptions] = React.useState(LeadStatus);
    const [activeSchema, setActiveSchema] = React.useState(validationSchema);
    const [selectedStatus, setSelectedStatus] = React.useState(null);

    const [documentCount, setDocumentCount] = useState(1);
    const [documentIds, setDocumentIds] = useState([]);
    const [documentsInputList, setDocumentsInputList] = useState([]);

    // const [feeCurrency, setFeeCurrency] = React.useState('');
    const [initialFormValues, setInitialFormValues] = React.useState({
        ...initialVals,
        leadId: listItem.id
    });

    const { mutate: updateLeadStatusMutation, isLoading } = useMutation(
        leadsServices.updateLead,
        {
            onSuccess: () => {
                onUpdate();
                enqueueSnackbar('Lead status has been updated successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onUpdateStatus = async (data) => {
        if (data.status === 6.1) {
            enqueueSnackbar('Sorry, no institution/country selected', {
                variant: 'warning'
            });
            return true;
        }

        if (data.status === 4) {
            const courseSet = new Set([
                data.option_one,
                data.option_two,
                data.option_three
            ]);

            if (courseSet.size !== 3) {
                enqueueSnackbar('You must select unique courses', {
                    variant: 'warning'
                });
                return;
            }
        }

        try {
            updateLeadStatusMutation({ ...data });
        } catch (error) {}
    };

    React.useEffect(() => {
        if (listItem) {
            // setStatusOptions(
            //     _.filter(
            //         LeadStatus,
            //         (state) =>
            //             state.id >= Math.floor(listItem?.status) && !state.hide
            //     )
            // );
        }
    }, [listItem]);

    React.useEffect(() => {
        if (
            (selectedStatus >= 7 || selectedStatus < 4) &&
            selectedStatus !== 8
        ) {
            setActiveSchema(validationSchema);
        }
    }, [selectedStatus]);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {!statusOptions && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {statusOptions && (
                <Formik
                    validateOnChange={true}
                    initialValues={initialFormValues}
                    validationSchema={activeSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        let docError = false;
                        let docNameError = '';
                        let docFileError = '';
                        _.forEach(documentsInputList, (doc) => {
                            const docNameFieldName = `document_name_${doc.id}`;
                            const docFileFieldName = `document_file_${doc.id}`;

                            console.log(doc.document_name);
                            console.log(doc.document_file);

                            if (
                                doc.document_name === '' &&
                                doc.document_file !== ''
                            ) {
                                docNameError = 'File name(s)';
                                docError = true;
                            }

                            if (
                                doc.document_name !== '' &&
                                doc.document_file === ''
                            ) {
                                docFileError = 'Valid file(s)';
                                docError = true;
                            }

                            if (
                                doc.document_name !== '' &&
                                !/(pdf|jpg|jpeg|png)$/i.test(
                                    doc.document_file_link
                                )
                            ) {
                                docFileError = 'Valid file(s)';
                                docError = true;
                            }
                        });

                        if (docError) {
                            enqueueSnackbar(
                                `${docNameError ? `${docNameError}` : ''} ${
                                    docFileError
                                        ? `${
                                              docNameError
                                                  ? `& ${docFileError}`
                                                  : `${docFileError}`
                                          }`
                                        : ''
                                } ${
                                    docNameError || docFileError
                                        ? ' are missing.'
                                        : ''
                                } Document names & files in PDF/JPG/JPEG/PNG format are required.`,
                                {
                                    variant: 'warning'
                                }
                            );

                            return;
                        }

                        let postData;

                        if (!documentsInputList[0].document_name) {
                            values.leadId = listItem.id;
                            postData = {
                                id: listItem.id,
                                formikData: values,
                                files: false
                            };
                        } else {
                            const formData = new FormData();

                            formData.append('leadId', listItem.id);

                            Object.keys(values).forEach((key) => {
                                if (!key.startsWith('document_file_')) {
                                    formData.append(key, values[key]);
                                }
                            });

                            if (documentsInputList.length) {
                                _.forEach(documentsInputList, (doc) => {
                                    if (doc.document_name) {
                                        formData.append(
                                            'lead_documents',
                                            doc.document_file
                                        );
                                    }
                                });

                                formData.append(
                                    'doc_names',
                                    JSON.stringify(
                                        _.map(
                                            documentsInputList,
                                            (doc) => doc.document_name
                                        )
                                    )
                                );
                            }

                            postData = {
                                id: listItem.id,
                                formikData: formData,
                                files: true
                            };
                        }

                        await onUpdateStatus(postData);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        isValid,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <Field name="status">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    // id="status"
                                                    sx={{
                                                        width: '100%'
                                                    }}
                                                    size="small"
                                                    options={statusOptions}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.status
                                                            ? option.status
                                                            : null
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                '& > img': {
                                                                    mr: 2,
                                                                    flexShrink: 0
                                                                }
                                                            }}
                                                            key={`Option-${option?.id}`}
                                                            {...props}
                                                        >
                                                            {option?.status}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Select Action/Status"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.status &&
                                                                !!errors.status
                                                            }
                                                            helperText={
                                                                touched?.status &&
                                                                errors.status
                                                            }
                                                        />
                                                    )}
                                                    onChange={
                                                        (event, newValue) => {
                                                            values.status =
                                                                newValue?.id;
                                                            setSelectedStatus(
                                                                newValue?.id
                                                            );
                                                            values.leadId =
                                                                listItem?.id;
                                                        }
                                                        // Set the new value in the form field
                                                    }
                                                    value={
                                                        statusOptions.find(
                                                            (option) =>
                                                                option.id ===
                                                                selectedStatus
                                                        ) || null
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    {(selectedStatus === 4 ||
                                        selectedStatus === 4.1) && (
                                        <Grid item xs={12} md={12}>
                                            <Uniapplication
                                                listItem={listItem}
                                                userDetails={userDetails}
                                                setActiveSchema={
                                                    setActiveSchema
                                                }
                                                setInitialFormValues={
                                                    setInitialFormValues
                                                }
                                            />
                                        </Grid>
                                    )}

                                    {selectedStatus === 4.3 && (
                                        <Grid item xs={12} md={12}>
                                            <RejectUniapplication
                                                listItem={listItem}
                                                userDetails={userDetails}
                                                uniApplications={
                                                    listItem?.uni_applications
                                                }
                                                setActiveSchema={
                                                    setActiveSchema
                                                }
                                                setInitialFormValues={
                                                    setInitialFormValues
                                                }
                                            />
                                        </Grid>
                                    )}

                                    {selectedStatus === 4.4 && (
                                        <Grid item xs={12} md={12}>
                                            <AcceptUniapplication
                                                listItem={listItem}
                                                userDetails={userDetails}
                                                uniApplications={
                                                    listItem?.uni_applications
                                                }
                                                setActiveSchema={
                                                    setActiveSchema
                                                }
                                                setInitialFormValues={
                                                    setInitialFormValues
                                                }
                                            />
                                        </Grid>
                                    )}

                                    {selectedStatus === 4.5 && (
                                        <Grid item xs={12} md={12}>
                                            <InitiateDepositPayment
                                                listItem={listItem}
                                                userDetails={userDetails}
                                                uniApplications={
                                                    listItem?.uni_applications
                                                }
                                                setActiveSchema={
                                                    setActiveSchema
                                                }
                                                setInitialFormValues={
                                                    setInitialFormValues
                                                }
                                            />
                                        </Grid>
                                    )}

                                    {selectedStatus === 4.6 && (
                                        <Grid item xs={12} md={12}>
                                            <DepositPayment
                                                listItem={listItem}
                                                userDetails={userDetails}
                                                uniApplications={
                                                    listItem?.uni_applications
                                                }
                                                setActiveSchema={
                                                    setActiveSchema
                                                }
                                                setInitialFormValues={
                                                    setInitialFormValues
                                                }
                                            />
                                        </Grid>
                                    )}

                                    {(selectedStatus === 5 ||
                                        selectedStatus === 5.1 ||
                                        selectedStatus === 5.2) && (
                                        <Grid item xs={12} md={12}>
                                            <Visaapplication
                                                listItem={listItem}
                                                userDetails={userDetails}
                                                uniApplications={
                                                    listItem?.uni_applications
                                                }
                                                setActiveSchema={
                                                    setActiveSchema
                                                }
                                                setInitialFormValues={
                                                    setInitialFormValues
                                                }
                                            />
                                        </Grid>
                                    )}

                                    {selectedStatus === 5.3 && (
                                        <Grid item xs={12} md={12}>
                                            <RejectVisaapplication
                                                listItem={listItem}
                                                userDetails={userDetails}
                                                visaApplications={
                                                    listItem?.visa_applications
                                                }
                                                setActiveSchema={
                                                    setActiveSchema
                                                }
                                                setInitialFormValues={
                                                    setInitialFormValues
                                                }
                                            />
                                        </Grid>
                                    )}

                                    {selectedStatus === 5.4 && (
                                        <Grid item xs={12} md={12}>
                                            <AcceptVisaapplication
                                                listItem={listItem}
                                                userDetails={userDetails}
                                                visaApplications={
                                                    listItem?.visa_applications
                                                }
                                                setActiveSchema={
                                                    setActiveSchema
                                                }
                                                setInitialFormValues={
                                                    setInitialFormValues
                                                }
                                            />
                                        </Grid>
                                    )}

                                    {selectedStatus === 6 &&
                                        selectedStatus === 6.1 &&
                                        selectedStatus === 6.2 && (
                                            <Grid item xs={12} md={12}>
                                                <Accomodationapplication
                                                    listItem={listItem}
                                                    userDetails={userDetails}
                                                    setActiveSchema={
                                                        setActiveSchema
                                                    }
                                                    setInitialFormValues={
                                                        setInitialFormValues
                                                    }
                                                />
                                            </Grid>
                                        )}

                                    {selectedStatus === 6.3 && (
                                        <Grid item xs={12} md={12}>
                                            <RejectAccomodationapplication
                                                listItem={listItem}
                                                userDetails={userDetails}
                                                accomodationApplications={
                                                    listItem?.accomodation_applications
                                                }
                                                setActiveSchema={
                                                    setActiveSchema
                                                }
                                                setInitialFormValues={
                                                    setInitialFormValues
                                                }
                                            />
                                        </Grid>
                                    )}

                                    {selectedStatus === 6.4 && (
                                        <Grid item xs={12} md={12}>
                                            <AcceptAccomodationapplication
                                                listItem={listItem}
                                                userDetails={userDetails}
                                                accomodationApplications={
                                                    listItem?.accomodation_applications
                                                }
                                                setActiveSchema={
                                                    setActiveSchema
                                                }
                                                setInitialFormValues={
                                                    setInitialFormValues
                                                }
                                            />
                                        </Grid>
                                    )}

                                    {selectedStatus === 8 && (
                                        <Grid item xs={12} md={12}>
                                            <Enrollment
                                                listItem={listItem}
                                                userDetails={userDetails}
                                                setActiveSchema={
                                                    setActiveSchema
                                                }
                                                setInitialFormValues={
                                                    setInitialFormValues
                                                }
                                            />
                                        </Grid>
                                    )}

                                    {selectedStatus && (
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <DocumentsUpload
                                                    item={listItem}
                                                    newItem={false}
                                                    documentCount={
                                                        documentCount
                                                    }
                                                    setDocumentCount={
                                                        setDocumentCount
                                                    }
                                                    documentIds={documentIds}
                                                    setDocumentIds={
                                                        setDocumentIds
                                                    }
                                                    documentsInputList={
                                                        documentsInputList
                                                    }
                                                    setDocumentsInputList={
                                                        setDocumentsInputList
                                                    }
                                                    loggedInUser={userDetails}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <JumboTextField
                                                    fullWidth
                                                    size={'small'}
                                                    id="comment"
                                                    name="comment"
                                                    label="Comment/Reason"
                                                    placeholder="Enter comment/reason here"
                                                    multiline
                                                    rows={3}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        disabled={
                                            _.isEmpty(touched) || !isValid
                                        }
                                        loading={isLoading}
                                    >
                                        Update Status
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo studentItem, onUpdate prop define */
export default UpdateForm;
