import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
    alpha,
    Chip,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from '@mui/material';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Div from '@jumbo/shared/Div';
import { Grid } from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import BusinessIcon from '@mui/icons-material/Business';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import RoomIcon from '@mui/icons-material/Room';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import HandshakeIcon from '@mui/icons-material/Handshake';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import PaymentIcon from '@mui/icons-material/Payment';
import _ from 'lodash';
import { colorForBgColor } from '@jumbo/utils';
import { formatNumber } from 'app/utils/appHelpers';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

const Detail = ({ item, onClose, authUser }) => {
    const bgColor = '#aaa';
    const color = colorForBgColor(bgColor);

    return (
        <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
            <CardHeader
                title={item?.institution_name}
                subheader={''}
                // avatar={<Avatar src={item?.profile_pic} />}

                action={
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
            />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <List disablePadding>
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <BusinessIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Institution
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {item?.institution_name}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <PhoneIphoneIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Phone Number
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {item?.phone_number}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <AlternateEmailIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Email Address
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {item?.email_address}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <RoomIcon sx={{ color: 'primary.light' }} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Physical Address
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {item?.address}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <LocationCityIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        City
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {item?.cityId
                                            ? item?.cityId?.city_name
                                            : item?.city_name}
                                        {', '}
                                        {item?.countryId?.country_name}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                </Grid>

                <Grid item xs={12} md={6}>
                    <List disablePadding>
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <CurrencyExchangeIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Application Fee
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {item?.application_fee ? (
                                            item?.application_fee +
                                            ' ' +
                                            item?.application_fee_currency
                                                ?.currency
                                        ) : (
                                            <em>Not Set</em>
                                        )}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <PaymentIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        First Year Tuition Fees
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {item?.first_year_fees ? (
                                            `$ ${formatNumber(
                                                item?.first_year_fees
                                            )}`
                                        ) : (
                                            <em>Not Set</em>
                                        )}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        {['regional', 'director', 'administrator'].includes(
                            authUser?.role_name
                        ) && (
                            <>
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <HandshakeIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Commission
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h5'} mb={0}>
                                                {item?.commission ? (
                                                    item?.commission + ' %'
                                                ) : (
                                                    <em>Not Set</em>
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                            </>
                        )}
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <RecentActorsIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Application Requirements
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {item?.application_requirements ? (
                                            _.map(
                                                item?.application_requirements,
                                                (req) => (
                                                    <Typography>
                                                        <Chip
                                                            key={req.id}
                                                            label={
                                                                req.requirement_name
                                                            }
                                                            sx={{
                                                                bgcolor:
                                                                    bgColor,
                                                                color: color
                                                            }}
                                                            spacing={5}
                                                            size={'small'}
                                                        />
                                                    </Typography>
                                                )
                                            )
                                        ) : (
                                            <em>Not Set</em>
                                        )}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <Stack spacing={1} direction={'row'} sx={{ px: 4, py: 2 }}>
                {/* <IconButton
                    size={'large'}
                    sx={{
                        backgroundColor: (theme) => theme.palette.grey[400],
                        color: 'common.white',

                        '&:hover': {
                            backgroundColor: 'primary.main'
                        }
                    }}
                >
                    <ForumOutlinedIcon fontSize={'medium'} color={'inherit'} />
                </IconButton>
                <IconButton
                    size={'large'}
                    sx={{
                        backgroundColor: (theme) => theme.palette.grey[400],
                        color: 'common.white',

                        '&:hover': {
                            backgroundColor: 'primary.main'
                        }
                    }}
                >
                    <LocalPhoneIcon fontSize={'medium'} color={'inherit'} />
                </IconButton> */}
            </Stack>
        </Div>
    );
};

export default Detail;
