import React from 'react';
import * as yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import { contactService } from '../../../../../services/contact-services';
import { institutionsServices } from 'app/services/institutions-services';
import { schoolsServices } from 'app/services/schools-services';
import { useSnackbar } from 'notistack';
// import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Form, Formik, Field } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboAvatarField from '@jumbo/components/JumboFormik/JumboAvatarField';
import Div from '@jumbo/shared/Div';
import { MuiTelInput } from 'mui-tel-input';
import axios from 'axios';

const validationSchema = yup.object({
    first_name: yup
        .string('Enter your first name')
        .required('First name is required'),
    last_name: yup
        .string('Enter your last name')
        .required('Last name is required'),
    email_address: yup
        .string('Enter your email address')
        .email('Enter a valid email address')
        .required('Email is required'),
    phone_number: yup
        .string('Enter phone number')
        // .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone number is required')
});
const initialValues = {
    first_name: '',
    last_name: '',
    phone_number: '',
    email_address: '',
    officeId: '',
    email_address: '',
    institutionId: [],
    schoolId: [],
    profile_pic: ''
};

const ContactForm = ({ contact, onSave }) => {
    console.log(contact);
    // const Swal = useSwalWrapper();
    const { enqueueSnackbar } = useSnackbar();

    const [institutionId, setInstitutionId] = React.useState('');
    const [institutions, setInstitutions] = React.useState(false);
    const [schoolId, setSchoolId] = React.useState('');
    const [schools, setSchools] = React.useState(false);
    const [phone, setPhone] = React.useState(contact?.phone_number || '+254');

    const addContactMutation = useMutation(contactService.add, {
        onSuccess: () => {
            onSave();
            // Swal.fire({
            //     position: 'top-end',
            //     icon: 'success',
            //     title: 'Contact has been added successfully.',
            //     showConfirmButton: false,
            //     timer: 1500
            // });
            enqueueSnackbar('Contact has been added successfully.', {
                variant: 'success'
            });
        },
        onError: (error) => {
            console.log(error);
            enqueueSnackbar(error.message, {
                variant: 'error'
            });
        }
    });

    const updateContactMutation = useMutation(contactService.update, {
        onSuccess: () => {
            onSave();
            enqueueSnackbar('Contact has been updated successfully.', {
                variant: 'success'
            });
        },
        onError: (error) => {
            enqueueSnackbar(error, {
                variant: 'error'
            });
        }
    });

    const saveMutation = React.useMemo(() => {
        return contact?.id ? updateContactMutation : addContactMutation;
    }, [contact, updateContactMutation, addContactMutation]);

    const onContactSave = (data, { setSubmitting }) => {
        setSubmitting(true);
        saveMutation.mutate(data);
        setSubmitting(false);
    };

    React.useEffect(() => {
        const cancelInstToken = axios.CancelToken.source();
        const cInstToken = { cancelToken: cancelInstToken.token };
        const fetchInstitutions = async () => {
            try {
                const res = await institutionsServices.getAllInstitutions(
                    cInstToken
                );

                setInstitutions(res?.institutions);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchInstitutions();

        return () => {
            cancelInstToken.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelInstToken = axios.CancelToken.source();
        const cInstToken = { cancelToken: cancelInstToken.token };
        const fetchSchools = async () => {
            try {
                const res = await schoolsServices.getAllSchools(cInstToken);

                setSchools(res.schools);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchSchools();

        return () => {
            cancelInstToken.cancel();
        };
    }, []);

    return (
        <>
            {(!institutions || !schools) && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {institutions && schools && (
                <Formik
                    validateOnChange={true}
                    initialValues={
                        contact?.id
                            ? {
                                  id: contact?.id || '',
                                  first_name: contact?.first_name || '',
                                  last_name: contact?.last_name || '',
                                  phone_number: contact?.phone_number || '',
                                  email_address: contact?.email_address || '',
                                  officeId: contact?.officeId?.id || '',
                                  email_address: contact?.email_address || '',
                                  institutionId: contact?.institutionId || [],
                                  schoolId: contact?.schoolId || [],
                                  profile_pic: contact?.profile_pic || ''
                              }
                            : initialValues
                    }
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={onContactSave}
                >
                    {({
                        isSubmitting,
                        setFieldValue,
                        values,
                        touched,
                        errors
                    }) => (
                        <Form noValidate autoComplete="off">
                            <Div
                                sx={{
                                    '& .MuiTextField-root': {
                                        mb: 3
                                    }
                                }}
                            >
                                {' '}
                                {/* <Div>{JSON.stringify(values)}</Div> */}
                                <JumboAvatarField
                                    name={'profile_pic'}
                                    alt={'user profile pic'}
                                    onFileSelection={(file) =>
                                        setFieldValue('profile_pic', file)
                                    }
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        margin: '0 auto 24px'
                                    }}
                                />
                                <JumboTextField
                                    fullWidth
                                    size={'small'}
                                    id="first_name"
                                    name="first_name"
                                    label="First Name"
                                    placeholder="Enter first name"
                                />
                                <JumboTextField
                                    fullWidth
                                    size={'small'}
                                    id="last_name"
                                    name="last_name"
                                    label="Last Name"
                                    placeholder="Enter last name"
                                />
                                <JumboTextField
                                    fullWidth
                                    size={'small'}
                                    id="email_address"
                                    name="email_address"
                                    label="Email Address"
                                    placeholder="Enter email address"
                                />
                                <MuiTelInput
                                    fullWidth
                                    size={'small'}
                                    id="phone_number"
                                    name="phone_number"
                                    label="Phone Number"
                                    value={phone}
                                    placeholder="Enter phone number"
                                    preferredCountries={['KE', 'ZM']}
                                    defaultCountry="KE"
                                    onChange={(newValue) => {
                                        setPhone(newValue);
                                        values.phone_number = newValue;
                                    }}
                                />
                                <Field name="institutionId">
                                    {({ field }) => (
                                        <Autocomplete
                                            multiple
                                            {...field}
                                            // id="institutionId"
                                            sx={{
                                                width: '100%',
                                                maxWidth: '100%'
                                            }}
                                            size="small"
                                            options={institutions}
                                            autoHighlight
                                            getOptionLabel={(option) =>
                                                option?.institution_name
                                                    ? option.institution_name
                                                    : ''
                                            }
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => option.id === value.id}
                                            renderOption={(props, option) => (
                                                <Box
                                                    component="li"
                                                    sx={{
                                                        '& > img': {
                                                            mr: 2,
                                                            flexShrink: 0
                                                        }
                                                    }}
                                                    {...props}
                                                >
                                                    {option.institution_name}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Institution"
                                                    placeholder="Choose a institution"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete:
                                                            'new-password'
                                                    }}
                                                    error={
                                                        touched?.institutionId &&
                                                        !!errors.institutionId
                                                    }
                                                    helperText={
                                                        touched?.institutionId &&
                                                        errors.institutionId
                                                    }
                                                />
                                            )}
                                            onChange={
                                                (event, newValue) => {
                                                    values.institutionId =
                                                        newValue;
                                                    setFieldValue(
                                                        'schoolId',
                                                        []
                                                    );
                                                }
                                                // Set the new value in the form field
                                            }
                                            // value={
                                            //     values?.institutionId
                                            //         ? institutions.find(
                                            //               (option) =>
                                            //                   option.id ===
                                            //                   values?.institutionId
                                            //           ) || null
                                            //         : null
                                            // }
                                        />
                                    )}
                                </Field>
                                <Grid item xs={12} md={12}>
                                    <Field name="schoolId">
                                        {({ field }) => (
                                            <Autocomplete
                                                multiple
                                                {...field}
                                                // id="schoolId"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: '100%'
                                                }}
                                                size="small"
                                                options={schools}
                                                autoHighlight
                                                getOptionLabel={(option) =>
                                                    option?.school_name
                                                        ? option.school_name
                                                        : ''
                                                }
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) => option.id === value.id}
                                                renderOption={(
                                                    props,
                                                    option
                                                ) => (
                                                    <Box
                                                        component="li"
                                                        sx={{
                                                            '& > img': {
                                                                mr: 2,
                                                                flexShrink: 0
                                                            }
                                                        }}
                                                        {...props}
                                                    >
                                                        {option.school_name}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="School"
                                                        placeholder="Choose a school"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete:
                                                                'new-password'
                                                        }}
                                                        error={
                                                            touched?.schoolId &&
                                                            !!errors.schoolId
                                                        }
                                                        helperText={
                                                            touched?.schoolId &&
                                                            errors.schoolId
                                                        }
                                                    />
                                                )}
                                                onChange={
                                                    (event, newValue) => {
                                                        values.schoolId =
                                                            newValue;
                                                        setFieldValue(
                                                            'institutionId',
                                                            []
                                                        );
                                                    }
                                                    // Set the new value in the form field
                                                }
                                                // value={
                                                //     values?.schoolId
                                                //         ? schools.find(
                                                //               (option) =>
                                                //                   option.id ===
                                                //                   values?.schoolId
                                                //           ) || null
                                                //         : null
                                                // }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={
                                        isSubmitting || saveMutation.isLoading
                                    }
                                >
                                    Save
                                </LoadingButton>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};
ContactForm.defaultProps = {
    onSave: () => {}
};
export default ContactForm;
