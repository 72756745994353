import React, { useEffect, useState } from 'react';
import DocumentComponent from './DocumentComponent';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Div from '@jumbo/shared/Div';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Button, Tooltip } from '@mui/material';
import { useFormikContext } from 'formik'; // Import Formik context
import { useDispatch } from 'react-redux';
import JumboUtils from 'app/services/auth/JumboUtils';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

const documentsFields = [
    'document_name',
    'amount',
    'document_file',
    'document_file_link'
];

function ExpenseDocumentsUpload({
    loggedInUser,
    item,
    options,
    feeCurrency,
    field_name,
    newItem,
    documentCount,
    setDocumentCount,
    documentsIds,
    setDocumentIds,
    documentsInputList,
    setDocumentsInputList
}) {
    const { enqueueSnackbar } = useSnackbar();

    const [selectedItem, setSelectedItem] = useState(false);
    const dispatch = useDispatch();
    const {
        values,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        setErrors,
        clearErrors,
        errors
    } = useFormikContext(); // Access Formik context
    const { REACT_APP_BACKEND } = process.env;

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const Names = documentsInputList.map((el) => el.document_name);
        if (Names.includes(e.target.value.trim())) {
            setErrors((errList) => {
                errList[index] = index;
                return errList;
            });
        } else {
            setErrors((errList) => {
                errList[index] = null;
                return errList;
            });
        }
        const list = [...documentsInputList];
        list[index][name] = value;
        setDocumentsInputList(list);
    };

    const handleRemoveClick = (id) => {
        setDocumentCount(documentCount - 1);
        const list = [...documentsInputList];
        const newList = list.filter((item) => {
            return item.id !== id;
        });

        if (newList.length) {
            _.forEach(documentsFields, (field) => {
                setFieldValue(`${field}_${id}`, ''); // Use setFieldValue to clear the field
            });
            setDocumentIds(_.map(newList, ({ id }) => id));
        }

        setDocumentsInputList(newList);
    };

    const handleAddClick = () => {
        if (documentsInputList.length < 6) {
            let errorDoc = false;
            documentsInputList.forEach((document) => {
                const docNameFieldName = `document_name_${document.id}`;
                const docAmount = `amount_${document.id}`;
                const docFileFieldName = `document_file_${document.id}`;
                if (document.document_name === '') {
                    setFieldError(docNameFieldName, 'File name is required');
                    setFieldTouched(docNameFieldName);
                    errorDoc = true;
                } else {
                    setFieldError(docNameFieldName, '');
                }

                if (document.amount === '') {
                    setFieldError(docAmount, 'File name is required');
                    setFieldTouched(docAmount);
                    errorDoc = true;
                } else {
                    setFieldError(docAmount, '');
                }

                if (document.document_file === '') {
                    setFieldError(docFileFieldName, 'File is required');
                    setFieldTouched(docFileFieldName);
                    errorDoc = true;
                } else {
                    setFieldError(docFileFieldName, '');
                }
            });

            if (errorDoc) {
                enqueueSnackbar(
                    'Available expense/amounts/file fields MUST be filled first',
                    {
                        variant: 'warning'
                    }
                );

                return;
            }

            const newInput = {
                id: JumboUtils.generateGUID(),
                document_name: '',
                amount: '',
                document_file: '',
                document_file_link: ''
            };

            setDocumentCount(documentCount + 1);
            setDocumentsInputList([...documentsInputList, newInput]);
        } else {
            enqueueSnackbar('You cannot add more than 6 documents at a time.', {
                variant: 'info'
            });
            return true;
        }
    };

    useEffect(() => {
        if (documentsInputList.length) {
            setDocumentIds(_.map(documentsInputList, ({ id }) => id));
        }
    }, [documentsInputList]);

    useEffect(() => {
        setDocumentsInputList([
            {
                id: JumboUtils.generateGUID(),
                document_name: '',
                amount: '',
                document_file: '',
                document_file_link: ''
            }
        ]);
    }, []);

    console.log(documentsInputList);

    return (
        <Div className="w-full">
            <Div sx={{ display: 'flex', my: '8px' }}>
                <Typography className="font-medium" color="text.secondary">
                    Upload Receipts
                </Typography>
                <Divider className="flex-auto ml-8" />
            </Div>
            {documentsInputList.map((el, i) => (
                <DocumentComponent
                    key={el.id}
                    documentCount={documentCount}
                    el={el}
                    index={i}
                    totalComponents={documentsInputList.length}
                    handleAddClick={handleAddClick}
                    handleRemoveClick={handleRemoveClick}
                    handleInputChange={handleInputChange}
                    documentsInputList={documentsInputList}
                    setDocumentsInputList={setDocumentsInputList}
                    feeCurrency={feeCurrency}
                    document_id={el.id}
                    options={options}
                    field_name={field_name}
                    document_name={el.document_name}
                    amount={el.amount}
                    document_file={el.document_file}
                    document_file_link={el.document_file_link}
                />
            ))}
        </Div>
    );
}

export default ExpenseDocumentsUpload;
