import React from 'react';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'app/AppAbility';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder';
import JumboListWrapper from '@jumbo/components/JumboList/components/JumboListWrapper';
import NotAllowed from 'app/shared/NotAllowed';
import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList';
import { accomodationapplicationsServices } from 'app/services/accomodationapplications-services';
import ListItem from './ListItem';
import { Card, Slide } from '@mui/material';
import Button from '@mui/material/Button';
import { Add } from '@mui/icons-material';
import JumboListToolbar from '@jumbo/components/JumboList/components/JumboListToolbar';
import { useParams } from 'react-router-dom';
import JumboSearch from '@jumbo/components/JumboSearch';
import useAccomodationapplicationsApp from '../../hooks/useAccomodationapplicationsApp';
import BulkActions from './BulkActions';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import CreateForm from '../CreateForm';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useSnackbar } from 'notistack';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const List = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { authUser } = useJumboAuth();
    const { hideDialog, showDialog } = useJumboDialog();

    const ability = useAbility(AbilityContext);
    const allowReadList = ability.can('read', 'Accomodationapplications');
    const allowAdd = ability.can('create', 'Accomodationapplication');
    const allowBulkDel = ability.can('delete', 'Accomodationapplication');

    const params = useParams();
    const listRef = React.useRef();
    const { refreshList, setListRefresh, setSelectedList } =
        useAccomodationapplicationsApp();

    const renderListItem = React.useCallback((listItem) => {
        return <ListItem listItem={listItem} />;
    });
    const [queryOptions, setQueryOptions] = React.useState({
        queryKey: 'accomodationapplications-list',
        queryParams: {
            accomodationapplication: params.accomodationapplicationId,
            id: params._id,
            searchText: ''
        },
        countKey: 'count',
        dataKey: 'accomodationapplications'
    });

    const handleOnChange = (value) => {
        setQueryOptions((state) => ({
            ...state,
            queryParams: {
                ...state.queryParams,
                searchText: value
            }
        }));
    };

    React.useEffect(() => {
        if (listRef?.current && refreshList) {
            listRef.current.refresh();
            setListRefresh(false);
        }
    }, [refreshList]);

    React.useEffect(() => {
        setQueryOptions((state) => ({
            ...state,
            queryParams: {
                ...state.queryParams,
                category: params.category,
                id: params.id
            }
        }));
    }, [params]);

    const handleDataSent = React.useCallback(() => {
        hideDialog();
        setListRefresh(true);
    }, []);

    const handleCreateForm = React.useCallback(() => {
        showDialog({
            title: 'Add Accomodation Application',
            content: (
                <CreateForm
                    onSave={handleDataSent}
                    hideDialog={hideDialog}
                    userDetails={authUser}
                />
            ),
            maxWidth: 'md',
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true
        });
    }, [showDialog]);

    if (!allowReadList) {
        return (
            <JumboListWrapper
                component={Card}
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <JumboListNoDataPlaceholder>
                    <NotAllowed />
                </JumboListNoDataPlaceholder>
            </JumboListWrapper>
        );
    }

    const tableActions = authUser && allowBulkDel && <BulkActions />;

    const actionButton = authUser && allowAdd && (
        <Button
            disableElevation
            variant={'contained'}
            startIcon={<Add />}
            sx={{
                mb: 1,
                '& .MuiSvgIcon-root': {
                    fontSize: '1.5rem'
                }
            }}
            onClick={handleCreateForm}
        >
            Add Accomodation Application
        </Button>
    );

    return (
        <JumboRqList
            ref={listRef}
            wrapperComponent={Card}
            queryOptions={queryOptions}
            primaryKey={'id'}
            service={
                accomodationapplicationsServices.getAccomodationapplications
            }
            renderItem={renderListItem}
            itemsPerPage={8}
            itemsPerPageOptions={[8, 12, 15]}
            componentElement={'div'}
            wrapperSx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column'
            }}
            toolbar={
                <JumboListToolbar
                    hideItemsPerPage={true}
                    bulkActions={tableActions}
                    addButton={actionButton}
                >
                    {authUser && (
                        <JumboSearch
                            onChange={handleOnChange}
                            sx={{
                                display: { xs: 'none', md: 'block' }
                            }}
                            value={queryOptions.queryParams.searchText}
                        />
                    )}
                </JumboListToolbar>
            }
            onSelectionChange={setSelectedList}
        />
    );
};

export default List;
