import React from 'react';

import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from 'react-query';
import { educationlevelsServices } from 'app/services/educationlevels-services';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';

const validationSchema = yup.object({
    education_level_name: yup
        .string('Enter education level name')
        .required('Education level name is required')
});

const CreateForm = ({ onSave, hideDialog }) => {
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: addEducationlevelMutation, isLoading } = useMutation(
        educationlevelsServices.addEducationlevel,
        {
            onSuccess: () => {
                onSave();
                enqueueSnackbar(
                    'Education level has been created successfully.',
                    {
                        variant: 'success'
                    }
                );
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (educationlevel) => {
        try {
            addEducationlevelMutation({ ...educationlevel });
        } catch (error) {}
    };

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            <Formik
                validateOnChange={true}
                initialValues={{
                    education_level_name: ''
                }}
                validationSchema={validationSchema}
                onSubmit={async (data, { setSubmitting }) => {
                    await onCreate(data);
                }}
            >
                {({ isSubmitting, errors, touched, values, ...restProps }) => (
                    <Form
                        style={{ textAlign: 'left' }}
                        noValidate
                        autoComplete="off"
                    >
                        <Div sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="education_level_name"
                                        name="education_level_name"
                                        label="Education level Name"
                                        placeholder="Enter Education level Name"
                                    />
                                </Grid>
                                {/* <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="educationlevels_description"
                                        name="educationlevels_description"
                                        label="Educationlevels Description"
                                        placeholder="Enter educationlevels description"
                                    />
                                </Grid> */}
                            </Grid>
                            <Grid
                                sx={{
                                    margin: '30px auto'
                                }}
                                container
                                alignItems="right"
                                justify="flex-end"
                                justifyContent="right"
                            >
                                <Button
                                    size="large"
                                    sx={{ mb: 3 }}
                                    onClick={() => hideDialog()}
                                >
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isLoading}
                                >
                                    Save
                                </LoadingButton>
                            </Grid>
                        </Div>
                    </Form>
                )}
            </Formik>
        </Div>
    );
};
/* Todo educationlevelsItem, onSave prop define */
export default CreateForm;
