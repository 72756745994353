import React from 'react';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EngineeringIcon from '@mui/icons-material/Engineering';

const GuardianContacts = ({ studentDetails }) => {
    return (
        <JumboCardQuick
            title={`Father/Guardian's Contacts`}
            noWrapper
            sx={{ mb: 3.75 }}
        >
            {!studentDetails.guardian_contacts?.first_name &&
                !studentDetails.guardian_contacts?.last_name &&
                !studentDetails.guardian_contacts?.phone_number && (
                    <Alert severity="info">No Info Available</Alert>
                )}{' '}
            {(studentDetails.guardian_contacts?.first_name ||
                studentDetails.guardian_contacts?.last_name ||
                studentDetails.guardian_contacts?.phone_number) && (
                <List disablePadding sx={{ mb: 2 }}>
                    <ListItem
                        alignItems="flex-start"
                        sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                    >
                        <ListItemIcon
                            sx={{ minWidth: 36, color: 'text.secondary' }}
                        >
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                >
                                    Name
                                </Typography>
                            }
                            secondary={
                                <Link variant="body1" href="#" underline="none">
                                    {studentDetails?.guardian_contacts
                                        ?.first_name +
                                        ' ' +
                                        studentDetails.guardian_contacts
                                            ?.last_name}
                                </Link>
                            }
                        />
                    </ListItem>
                    <ListItem
                        alignItems="flex-start"
                        sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                    >
                        <ListItemIcon
                            sx={{ minWidth: 36, color: 'text.secondary' }}
                        >
                            <EmailOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                >
                                    Email
                                </Typography>
                            }
                            secondary={
                                <Link variant="body1" href="#" underline="none">
                                    {
                                        studentDetails?.guardian_contacts
                                            ?.email_address
                                    }
                                </Link>
                            }
                        />
                    </ListItem>

                    <ListItem
                        alignItems="flex-start"
                        sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                    >
                        <ListItemIcon
                            sx={{ minWidth: 36, color: 'text.secondary' }}
                        >
                            <LocalPhoneOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                >
                                    Phone
                                </Typography>
                            }
                            secondary={
                                <Typography
                                    variant="body1"
                                    color="text.primary"
                                >
                                    {
                                        studentDetails?.guardian_contacts
                                            .phone_number
                                    }
                                </Typography>
                            }
                        />
                    </ListItem>
                    <ListItem
                        alignItems="flex-start"
                        sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                    >
                        <ListItemIcon
                            sx={{ minWidth: 36, color: 'text.secondary' }}
                        >
                            <EngineeringIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                >
                                    Profession
                                </Typography>
                            }
                            secondary={
                                <Typography
                                    variant="body1"
                                    color="text.primary"
                                >
                                    {
                                        studentDetails?.guardian_contacts
                                            ?.profession
                                    }
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
            )}
        </JumboCardQuick>
    );
};

export default GuardianContacts;
