import React from 'react';

import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { useMutation } from 'react-query';
import { timeoffsServices } from 'app/services/timeoffs-services';
import { leavetypesServices } from 'app/services/leavetypes-services';

import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import axios from 'axios';

import _ from 'lodash';

const validationSchema = yup.object({
    leavetypeId: yup
        .string('Select type of leave')
        .required('Leave type is required'),
    start_date: yup
        .string('Enter start date')
        .required('Start Date is required'),
    end_date: yup.string('Enter end date').required('End Date is required')
    // comment: yup.string('Enter comment').required('comment is required')
});

const CreateForm = ({
    onSave = () => {},
    hideDialog,
    refreshEvents,
    start,
    end
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const [leavetypes, setLeavetypes] = React.useState(false);
    const [leavetypeId, setLeavetype] = React.useState('');

    const [startDate, setStartDate] = React.useState(dayjs(start));
    const [endDate, setEndDate] = React.useState(dayjs(end));

    // const addTimeOff = () => {};

    const { mutate: addTimeoffMutation, isLoading } = useMutation(
        timeoffsServices.addTimeoff,
        {
            onSuccess: () => {
                onSave();
                enqueueSnackbar(
                    'Leave request has been submitted successfully.',
                    {
                        variant: 'success'
                    }
                );

                refreshEvents();
                hideDialog();
                return true;
            },

            onError: (error) => {
                console.log('error on create form');
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (data) => {
        try {
            addTimeoffMutation({ ...data });
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };
        const fetchLeavetypes = async () => {
            try {
                const res = await leavetypesServices.getAllLeavetypes(cToken);

                setLeavetypes(res.leavetypes);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchLeavetypes();

        return () => {
            cancelToken.cancel();
        };
    }, []);

    const handleStartDateChange = (newValue) => {
        setStartDate(newValue);
        // Update end date if start date is later than end date
        if (newValue.isAfter(endDate)) {
            setEndDate(newValue);
        }
    };

    // Handle end date change
    const handleEndDateChange = (newValue) => {
        // Ensure end date is not earlier than start date
        if (newValue.isBefore(startDate)) {
            setEndDate(startDate);
        } else {
            setEndDate(newValue);
        }
    };

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {!leavetypes && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {leavetypes && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        leavetypeId: '',
                        start_date: startDate,
                        end_date: endDate,
                        comment: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onCreate(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <Field name="leavetypeId">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    id="leavetypeId"
                                                    sx={{
                                                        width: 500,
                                                        maxWidth: '100%'
                                                    }}
                                                    size="small"
                                                    options={leavetypes}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.leave_type_name
                                                            ? option.leave_type_name
                                                            : ''
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                '& > img': {
                                                                    mr: 2,
                                                                    flexShrink: 0
                                                                }
                                                            }}
                                                            {...props}
                                                        >
                                                            {
                                                                option.leave_type_name
                                                            }
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Leave Type"
                                                            placeholder="Choose the type of leave"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.leavetypeId &&
                                                                !!errors.leavetypeId
                                                            }
                                                            helperText={
                                                                touched?.leavetypeId &&
                                                                errors.leavetypeId
                                                            }
                                                        />
                                                    )}
                                                    onChange={
                                                        (event, newValue) => {
                                                            values.leavetypeId =
                                                                newValue?.id;
                                                        }
                                                        // Set the new value in the form field
                                                    }
                                                    value={
                                                        leavetypes.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.leavetypeId
                                                        ) || ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <MobileDatePicker
                                                label="Start Date"
                                                inputFormat="DD/MM/YYYY"
                                                value={startDate}
                                                onChange={(newValue) => {
                                                    handleStartDateChange(
                                                        newValue
                                                    );
                                                    values.start_date =
                                                        newValue;
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <JumboTextField
                                                            fullWidth
                                                            size={'small'}
                                                            value={startDate}
                                                            name="start_date"
                                                            {...params}
                                                        />
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <MobileDatePicker
                                                label="End Date"
                                                inputFormat="DD/MM/YYYY"
                                                value={endDate}
                                                onChange={(newValue) => {
                                                    handleEndDateChange(
                                                        newValue
                                                    );
                                                    values.end_date = newValue;
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <JumboTextField
                                                            fullWidth
                                                            size={'small'}
                                                            value={endDate}
                                                            name="end_date"
                                                            {...params}
                                                        />
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="comment"
                                            name="comment"
                                            label="Enter a comment"
                                            placeholder="Enter a comment"
                                            multiline
                                            rows={3}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                    >
                                        Submit
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo officeItem, onSave prop define */
export default CreateForm;
