import React from 'react';
import Stack from '@mui/material/Stack';
import LabelsSelectControl from '../LabelsSelectControl';
import { useMutation } from 'react-query';
import { surveyService } from '../../../../../services/survey-services';
import useSurveysApp from '../../hooks/useSurveysApp';
import IconButton from '@mui/material/IconButton';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { useSnackbar } from 'notistack';
import { Tooltip } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const BulkActions = () => {
    const { selectedSurveys, setSurveysListRefresh } = useSurveysApp();
    const { showDialog, hideDialog } = useJumboDialog();
    const { enqueueSnackbar } = useSnackbar();

    const assignLabelMutation = useMutation(surveyService.assignLabel, {
        onSuccess: () => {
            hideDialog();
            setSurveysListRefresh(true);
            enqueueSnackbar('Labels has been applied successfully.', {
                variant: 'success'
            });
        }
    });
    const deleteSurveysMutation = useMutation(surveyService.deleteMultiple, {
        onSuccess: () => {
            hideDialog();
            setSurveysListRefresh(true);
            enqueueSnackbar('Surveys has been deleted successfully.', {
                variant: 'success'
            });
        }
    });
    const handleBulkDelete = React.useCallback(() => {
        showDialog({
            variant: 'confirm',
            title: 'Are you sure?',
            onYes: () =>
                deleteSurveysMutation.mutate(
                    selectedSurveys.map((survey) => survey?.id)
                ),
            onNo: hideDialog
        });
    }, [selectedSurveys, deleteSurveysMutation, hideDialog]);

    const applyLabels = React.useCallback(
        (selectedLabels) => {
            assignLabelMutation.mutate({
                surveyIDs: selectedSurveys.map((survey) => survey?.id),
                labelIDs: selectedLabels.map((label) => label?.id)
            });
        },
        [selectedSurveys]
    );

    return (
        <Stack
            direction={'row'}
            sx={{ backgroundColor: 'transparent', ml: -2 }}
        >
            {/* <Tooltip title={'Delete'}>
                <IconButton onClick={handleBulkDelete}>
                    <DeleteOutlineIcon />
                </IconButton>
            </Tooltip>
            <LabelsSelectControl onDone={applyLabels} /> */}
        </Stack>
    );
};

export default BulkActions;
