import React from 'react';
import { Typography, Alert } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import ScheduleItem from './ScheduleItem';
import { scheduleData } from './data';

const ScheduleList = ({ lead }) => {
    const progress =
        lead?.progress && lead.progress.length > 0
            ? [...lead.progress].reverse()
            : false;

    return (
        <React.Fragment>
            {/* <Typography variant={'h5'} color={'text.secondary'} mb={2}>
                Lead's Progress
            </Typography> */}
            <Timeline
                sx={{
                    m: 0,
                    p: 0
                }}
            >
                {progress &&
                    progress.map((schedule, index) => (
                        <ScheduleItem item={schedule} key={index} />
                    ))}
                {!progress && (
                    <Alert severity="info">
                        No progress status available yet
                    </Alert>
                )}
            </Timeline>
        </React.Fragment>
    );
};

export default ScheduleList;
