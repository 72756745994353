import React, { useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import JumboRadioGroup from '@jumbo/components/JumboFormik/JumboRadioGroup';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useMutation } from 'react-query';
import { requestsServices } from 'app/services/requests-services';
import { expensetypesServices } from 'app/services/expensetypes-services';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import axios from 'axios';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import _ from 'lodash';

const validationSchema = yup.object({
    // expense_name: yup
    //     .string('Select travel type name')
    //     .required('Travel type is required'),
    merchant: yup
        .string('Enter the merchant')
        .required('Merchant name is required'),
    expense_type: yup
        .string('Select expense type')
        .required('Expense type is required'),
    required_date: yup
        .string('Select required date')
        .required('Required date is required'),
    quantity: yup
        .number('Enter the required quantity ')
        .min(1)
        .required('Quantity is required'),
    unit_price: yup
        .number('Enter unit price')
        .min(1)
        .required('Price per unit is required'),
    // total_amount: yup
    //     .number('Enter purchase amount')
    //     .required('Purchase amount is required'),
    purpose: yup
        .string('Enter purchase purpose')
        .required('Purpose is required')
    // description: yup
    //     .string('Enter purchase description')
    //     .required('Description is required'),
});

const PurchaseForm = ({ onSave, hideDialog, authUser }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [expensetypes, setExpensetypes] = React.useState(false);
    const [feeCurrency, setFeeCurrency] = React.useState(
        authUser?.primaryCurrency?.currency || 'KES'
    );
    const [feeCurrencyName, setFeeCurrencyName] = React.useState(
        authUser?.primaryCurrency?.currency_name || 'Kenyan Shillings'
    );
    const [requiredDate, setRequiredDate] = React.useState(dayjs(new Date()));
    const [totalAmount, setTotalAmount] = React.useState(0);
    const [totalQuantity, setTotalQuantity] = React.useState(0);
    const [unitPrice, setUnitPrice] = React.useState(0);

    const { mutate: submitRequestMutation, isLoading } = useMutation(
        requestsServices.addRequest,
        {
            onSuccess: () => {
                enqueueSnackbar('Request has been submitted successfully.', {
                    variant: 'success'
                });
                onSave();
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error?.message, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (request) => {
        try {
            let postData = {
                formikData: { ...request, module: 'purchase' },
                files: false
            };
            submitRequestMutation(postData);
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };
        const fetchExpensetypes = async () => {
            try {
                const res = await expensetypesServices.getAllExpensetypes(
                    cToken
                );

                const finalOptions = _.map(res.expensetypes, (expensetype) => {
                    const { id, expense_type_name } = expensetype;

                    return {
                        id,
                        expense_type_name
                    };
                });

                setExpensetypes(finalOptions);
            } catch (error) {
                console.log('compon', error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchExpensetypes();

        return () => {
            cancelToken.cancel();
        };
    }, []);

    const amountChangeHandler = (price, quantity) => {
        const total =
            price && quantity ? parseFloat(price) * parseFloat(quantity) : 0;
        setTotalAmount(total);
    };

    useEffect(() => {
        amountChangeHandler(unitPrice, totalQuantity);
    }, [unitPrice, totalQuantity]);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {!expensetypes && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {expensetypes && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        merchant: '',
                        expense_type: '',
                        expense_type_name: '',
                        total_amount: totalAmount,
                        currency: feeCurrency,
                        currency_name: feeCurrencyName,
                        quantity: 0,
                        unit_price: 0,
                        required_date: requiredDate,
                        purpose: '',
                        description: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onCreate(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        setFieldValue,
                        setFieldError,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={1.5}>
                                    <Grid item xs={12} md={12}>
                                        <Field name="expense_type_name">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    sx={{}}
                                                    size="small"
                                                    options={expensetypes}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.expense_type_name
                                                            ? option.expense_type_name
                                                            : ''
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                '& > img': {
                                                                    mr: 2,
                                                                    flexShrink: 0
                                                                }
                                                            }}
                                                            {...props}
                                                        >
                                                            {
                                                                option.expense_type_name
                                                            }
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Expense Type"
                                                            placeholder="Choose expense type"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.expense_type_name &&
                                                                !!errors.expense_type_name
                                                            }
                                                            helperText={
                                                                touched?.expense_type_name &&
                                                                errors.expense_type_name
                                                            }
                                                        />
                                                    )}
                                                    onChange={(
                                                        event,
                                                        newValue
                                                    ) => {
                                                        values.expense_type =
                                                            newValue?.id;
                                                        values.expense_type_name =
                                                            newValue?.expense_type_name;
                                                    }}
                                                    value={
                                                        expensetypes.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.expense_type
                                                        ) || null
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            sx={{}}
                                            id="merchant"
                                            name="merchant"
                                            type="text"
                                            label="Merchant"
                                            placeholder="Enter the merchant's name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            sx={{}}
                                            id="description"
                                            name="description"
                                            label="Enter Item(s) description"
                                            placeholder="Enter the purchase description"
                                            multiline
                                            rows={2}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            sx={{}}
                                            id="purpose"
                                            name="purpose"
                                            label="Purpose"
                                            placeholder="Enter purpose for the purchase"
                                            multiline
                                            rows={2}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            sx={{}}
                                            onChange={(e) => {
                                                values.quantity =
                                                    e.target.value;

                                                setTotalQuantity(
                                                    e.target.value
                                                );
                                                setFieldValue(
                                                    'total_amount',
                                                    parseFloat(unitPrice) *
                                                        parseFloat(
                                                            e.target.value
                                                        )
                                                );
                                            }}
                                            id="quantity"
                                            name="quantity"
                                            type="number"
                                            label="Quantity"
                                            placeholder="Quantity"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            sx={{}}
                                            id="unit_price"
                                            name="unit_price"
                                            type="number"
                                            label="Unit Price (Price per item)"
                                            placeholder="Unit Price"
                                            onChange={(e) => {
                                                values.unit_price =
                                                    e.target.value;
                                                setUnitPrice(e.target.value);

                                                setFieldValue(
                                                    'total_amount',
                                                    parseFloat(totalQuantity) *
                                                        parseFloat(
                                                            e.target.value
                                                        )
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            sx={{}}
                                            id="total_amount"
                                            name="total_amount"
                                            type="number"
                                            InputProps={{
                                                readOnly: true,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {feeCurrency}
                                                    </InputAdornment>
                                                )
                                            }}
                                            label="Purchase Amount"
                                            placeholder="Total"
                                            // disabled={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <MobileDatePicker
                                                label="Required By"
                                                inputFormat="YYYY-MM-DD"
                                                disablePast={true}
                                                closeOnSelect={true}
                                                value={requiredDate}
                                                onChange={(newValue) => {
                                                    setRequiredDate(newValue);
                                                    values.required_date =
                                                        newValue;
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <JumboTextField
                                                            fullWidth
                                                            size={'small'}
                                                            sx={{
                                                                marginTop: '8px'
                                                            }}
                                                            value={requiredDate}
                                                            name="required_date"
                                                            {...params}
                                                        />
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid
                                        sx={{
                                            margin: '30px auto'
                                        }}
                                        container
                                        alignItems="right"
                                        justify="flex-end"
                                        justifyContent="right"
                                    >
                                        <Button
                                            size="large"
                                            sx={{ mb: 3 }}
                                            onClick={() => hideDialog()}
                                        >
                                            Cancel
                                        </Button>
                                        <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{ mb: 3 }}
                                            loading={isLoading}
                                        >
                                            Submit Request
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo officeItem, onSave prop define */
export default PurchaseForm;
