import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const expensetypesServices = {};

expensetypesServices.getAllExpensetypes = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/expense-types',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

expensetypesServices.getExpensetypes = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/expense-types', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

expensetypesServices.getExpensetypeDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(
        `/expense-typess/expense-type/${id}`,
        {
            id: id
        }
    );

    return data;
};

expensetypesServices.addExpensetype = async (expensetypes) => {
    const { data } = await jwtAuthAxios.post(`/expense-types`, expensetypes);
    return data;
};

expensetypesServices.updateExpensetype = async (expensetypes) => {
    // console.log('Service Expensetype');
    // console.log(expensetypes);
    const { data } = await jwtAuthAxios.patch(
        `/expense-types/${expensetypes.id}`,
        expensetypes
    );
    return data;
};

expensetypesServices.deleteExpensetype = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/expense-types/${id}`, {
        id: id
    });
    return data;
};

expensetypesServices.deleteMultipleExpensetypes = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/expense-types`, {
        data: ids
    });
    return data;
};

export { expensetypesServices };
