import React from 'react';
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator
} from '@mui/lab';
import _ from 'lodash';
import { Typography } from '@mui/material';
import moment from 'moment';
import Span from '@jumbo/shared/Span';
import LeadStatus from 'app/pages/statuses/LeadStatus';

const ScheduleItem = ({ item }) => {
    const STATUS = _.find(
        LeadStatus,
        (status) => status.id === parseFloat(item?.status)
    );

    // console.log('item');
    // console.log('STATUS');
    // console.log("Statuses Count", item?.progress && item?.progress.length);
    // console.log(STATUS);

    const currentDate = moment();
    const inputDate = item?.date_created ? moment(item?.date_created) : false;
    const hoursDiff = currentDate.diff(inputDate, 'hours');
    const thresholdHours = 48;

    let displayText = '';
    let updater = item?.creator?.first_name
        ? `${item?.creator?.first_name} ${item?.creator?.last_name}`
        : '';

    if (inputDate) {
        if (hoursDiff < 24) {
            displayText = 'Today';
        } else if (hoursDiff < thresholdHours) {
            displayText = inputDate.fromNow();
        } else {
            displayText = inputDate.format('MMM DD, YYYY, h:mm A');
        }
    }

    return (
        <TimelineItem
            sx={{
                '&::before': {
                    display: 'none'
                }
            }}
        >
            <TimelineSeparator>
                <TimelineDot
                    sx={{
                        m: (theme) => theme.spacing(0.5, 0),
                        bgcolor: STATUS?.color || '#eee',
                        width: 20,
                        height: 20,
                        boxShadow:
                            '0 0 0 4px #fff,inset 0 2px 0 rgba(0,0,0,.08),0 3px 0 4px rgba(0,0,0,.05)'
                    }}
                >
                    {item?.icon}
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
                sx={{
                    p: (theme) => theme.spacing(0.5, 0),
                    ml: 2.5
                }}
            >
                {item?.stage && (
                    <Typography
                        variant={'h6'}
                        color={'text.primary'}
                        lineHeight={1.5}
                    >
                        {item?.stage || STATUS?.name}
                    </Typography>
                )}

                {displayText && (
                    <Typography
                        variant={'body1'}
                        color={'text.secondary'}
                        fontSize={12}
                    >
                        {displayText}
                    </Typography>
                )}
                {updater && (
                    <Typography
                        variant={'body1'}
                        color={'text.secondary'}
                        fontSize={12}
                    >
                        Updated By:
                        <Span sx={{ ml: 1, color: 'primary.main' }}>
                            {updater}
                        </Span>
                    </Typography>
                )}

                {item?.comment && (
                    <Typography
                        variant={'h6'}
                        color={'text.secondary'}
                        sx={{ mt: 1, mb: 2 }}
                    >
                        {item?.comment}
                    </Typography>
                )}
            </TimelineContent>
        </TimelineItem>
    );
};

/* Todo item prop define */
export default ScheduleItem;
