import Div from '@jumbo/shared/Div';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { useField, useFormikContext } from 'formik';

function QuillEditor({ name, editorHtml, setEditorHtml }) {
    const { setFieldValue } = useFormikContext();

    const [helpers] = useField(name);
    const handleEditorChange = (html) => {
        setEditorHtml(html);
        setFieldValue(name, html);
    };

    const quillStyles = {
        height: '150px',
        position: 'relative'
    };

    return (
        <Div>
            <ReactQuill
                value={editorHtml}
                onChange={handleEditorChange}
                style={quillStyles}
            />
        </Div>
    );
}

export default QuillEditor;
