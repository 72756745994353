import React from 'react';
import TabContext from '@mui/lab/TabContext';
import Div from '@jumbo/shared/Div';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';

import DetailsTab from './DetailsTab/DetailsTab';
import UpdateProfileTab from './UpdateProfileTab/UpdateProfileTab';
import ChangePasswordTab from './ChangePasswordTab/ChangePasswordTab';
import UploadPicture from './UploadPicture/UploadPicture';

const About = ({ authUser, userDetails, onUpdate }) => {
    const [value, setValue] = React.useState('1');

    // console.log('userDOB', userDetails.dob);

    const handleChange = (event, newValue) => {
        console.log(newValue);
        setValue(newValue);
    };

    // console.log('Value', value);

    return (
        <JumboCardQuick
            title={'About'}
            action={
                <TabContext value={value}>
                    <Div
                        sx={{
                            marginTop: -2.25,
                            marginBottom: -2.5,

                            '& .MuiTab-root': {
                                py: 2.5
                            }
                        }}
                    >
                        <TabList onChange={handleChange} aria-label="">
                            <Tab label="Overview" value="1" />
                            {authUser.userId === userDetails.id && (
                                <Tab label="Update Details" value="2" />
                            )}
                            {authUser.userId === userDetails.id && (
                                <Tab label="Change Password" value="3" />
                            )}

                            {authUser.userId === userDetails.id && (
                                <Tab label="Upload Picture" value="4" />
                            )}

                            {/* <Tab label="Education" value="3" /> */}
                        </TabList>
                    </Div>
                </TabContext>
            }
            headerSx={{
                borderBottom: 1,
                borderColor: 'divider'
            }}
            sx={{ mb: 3.75 }}
        >
            {value === '1' && (
                <DetailsTab userDetails={userDetails} onUpdate={onUpdate} />
            )}
            {authUser.userId === userDetails?.id && value === '2' && (
                <UpdateProfileTab
                    userDetails={userDetails}
                    onUpdate={onUpdate}
                />
            )}

            {authUser.userId === userDetails?.id && value === '3' && (
                <ChangePasswordTab userDetails={userDetails} />
            )}

            {authUser.userId === userDetails?.id && value === '4' && (
                <UploadPicture userDetails={userDetails} onSave={onUpdate} />
            )}
        </JumboCardQuick>
    );
};

export default About;
