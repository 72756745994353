const defaultAbility = [
    {
        action: 'read',
        subject: 'Home'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Dashboard'
    },
    //Home
    {
        humanName: '',
        action: 'read',
        subject: 'Students'
    },
    {
        humanName: '',
        action: 'create',
        subject: 'Student'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Student'
    },
    {
        humanName: '',
        action: 'update',
        subject: 'Student'
    },
    {
        humanName: '',
        action: 'delete',
        subject: 'Student'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Leads'
    },
    {
        humanName: '',
        action: 'create',
        subject: 'Lead'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Lead'
    },
    {
        humanName: '',
        action: 'update',
        subject: 'Lead'
    },
    {
        humanName: '',
        action: 'delete',
        subject: 'Lead'
    },
    {
        humanName: 'Uni Applications',
        action: 'read',
        subject: 'Uniapplications'
    },
    {
        humanName: 'Uni Application',
        action: 'create',
        subject: 'Uniapplication'
    },
    {
        humanName: 'Uni Application',
        action: 'read',
        subject: 'Uniapplication'
    },
    {
        humanName: 'Uni Application',
        action: 'update',
        subject: 'Uniapplication'
    },
    {
        humanName: 'Uni Application',
        action: 'delete',
        subject: 'Uniapplication'
    },
    {
        humanName: 'Accomodation Applications',
        action: 'read',
        subject: 'Accomodationapplications'
    },
    {
        humanName: 'Accomodation Application',
        action: 'create',
        subject: 'Accomodationapplication'
    },
    {
        humanName: 'Accomodation Application',
        action: 'read',
        subject: 'Accomodationapplication'
    },
    {
        humanName: 'Accomodation Application',
        action: 'update',
        subject: 'Accomodationapplication'
    },
    {
        humanName: 'Accomodation Application',
        action: 'delete',
        subject: 'Accomodationapplication'
    },
    {
        humanName: 'Visa Applications',
        action: 'read',
        subject: 'Visaapplications'
    },
    {
        humanName: 'Visa Application',
        action: 'create',
        subject: 'Visaapplication'
    },
    {
        humanName: 'Visa Application',
        action: 'read',
        subject: 'Visaapplication'
    },
    {
        humanName: 'Visa Application',
        action: 'update',
        subject: 'Visaapplication'
    },
    {
        humanName: 'Visa Application',
        action: 'delete',
        subject: 'Visaapplication'
    },
    {
        humanName: 'Events',
        action: 'read',
        subject: 'Events'
    },
    {
        humanName: 'Events Calendar',
        action: 'read',
        subject: 'Eventscalendar'
    },
    {
        humanName: 'Events',
        action: 'read',
        subject: 'Eventslist'
    },
    {
        humanName: '',
        action: 'create',
        subject: 'Event'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Event'
    },
    {
        humanName: '',
        action: 'update',
        subject: 'Event'
    },
    {
        humanName: '',
        action: 'delete',
        subject: 'Event'
    },
    //Apps
    {
        humanName: '',
        action: 'read',
        subject: 'Apps'
    },
    {
        humanName: 'Expenses',
        action: 'read',
        subject: 'Expenses'
    },
    {
        humanName: '',
        action: 'create',
        subject: 'Expense'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Expense'
    },
    {
        humanName: '',
        action: 'update',
        subject: 'Expense'
    },
    {
        humanName: '',
        action: 'delete',
        subject: 'Expense'
    },
    {
        humanName: '',
        action: 'approve',
        subject: 'Expense'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Contacts'
    },
    {
        humanName: '',
        action: 'create',
        subject: 'Contact'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Contact'
    },
    {
        humanName: '',
        action: 'update',
        subject: 'Contact'
    },
    {
        humanName: '',
        action: 'delete',
        subject: 'Contact'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Surveys'
    },
    {
        humanName: '',
        action: 'create',
        subject: 'Survey'
    },
    {
        humanName: '',
        action: 'initiate',
        subject: 'Survey'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Survey'
    },
    {
        humanName: '',
        action: 'update',
        subject: 'Survey'
    },
    {
        humanName: '',
        action: 'delete',
        subject: 'Survey'
    },

    //Management
    {
        humanName: '',
        action: 'read',
        subject: 'Management'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Finances'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Earnings'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Inventories'
    },
    {
        humanName: '',
        action: 'create',
        subject: 'Inventory'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Inventory'
    },
    {
        humanName: '',
        action: 'update',
        subject: 'Inventory'
    },
    {
        humanName: '',
        action: 'delete',
        subject: 'Inventory'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Incomes'
    },
    {
        humanName: '',
        action: 'create',
        subject: 'Income'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Income'
    },
    {
        humanName: '',
        action: 'update',
        subject: 'Income'
    },
    {
        humanName: '',
        action: 'delete',
        subject: 'Income'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Deposits'
    },
    {
        humanName: '',
        action: 'create',
        subject: 'Deposit'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Deposit'
    },
    {
        humanName: '',
        action: 'update',
        subject: 'Deposit'
    },
    {
        humanName: '',
        action: 'delete',
        subject: 'Deposit'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Employees'
    },
    {
        humanName: '',
        action: 'create',
        subject: 'Employee'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Employee'
    },
    {
        humanName: '',
        action: 'update',
        subject: 'Employee'
    },
    {
        humanName: '',
        action: 'delete',
        subject: 'Employee'
    },
    { humanName: 'KPIs', action: 'read', subject: 'KPIs' },
    {
        humanName: '',
        action: 'create',
        subject: 'KPI'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'KPI'
    },
    {
        humanName: '',
        action: 'update',
        subject: 'KPI'
    },
    {
        humanName: '',
        action: 'delete',
        subject: 'KPI'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Timeoffs'
    },
    {
        humanName: '',
        action: 'create',
        subject: 'Timeoff'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Timeoff'
    },
    {
        humanName: '',
        action: 'update',
        subject: 'Timeoff'
    },
    {
        humanName: '',
        action: 'delete',
        subject: 'Timeoff'
    },
    {
        humanName: 'Configure',
        action: 'configure',
        subject: 'Timeoffs'
    },
    {
        humanName: '',
        action: 'approve',
        subject: 'Timeoff'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Offices'
    },
    {
        humanName: '',
        action: 'create',
        subject: 'Office'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Office'
    },
    {
        humanName: '',
        action: 'update',
        subject: 'Office'
    },
    {
        humanName: '',
        action: 'delete',
        subject: 'Office'
    },

    //Settings
    { humanName: '', action: 'read', subject: 'Settings' },
    {
        humanName: 'Access Control',
        action: 'read',
        subject: 'Accesscontrol'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Users'
    },
    {
        humanName: '',
        action: 'create',
        subject: 'User'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'User'
    },
    {
        humanName: '',
        action: 'update',
        subject: 'User'
    },
    {
        humanName: '',
        action: 'delete',
        subject: 'User'
    },
    {
        humanName: 'Roles',
        action: 'read',
        subject: 'Roles'
    },
    {
        humanName: 'Role',
        action: 'create',
        subject: 'Role'
    },
    {
        humanName: 'Role',
        action: 'read',
        subject: 'Role'
    },
    {
        humanName: 'Role',
        action: 'update',
        subject: 'Role'
    },
    {
        humanName: 'Role',
        action: 'delete',
        subject: 'Role'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Designations'
    },
    {
        humanName: '',
        action: 'create',
        subject: 'Designation'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Designation'
    },
    {
        humanName: '',
        action: 'update',
        subject: 'Designation'
    },
    {
        humanName: '',
        action: 'delete',
        subject: 'Designation'
    },
    {
        humanName: 'Education Levels',
        action: 'read',
        subject: 'Educationlevels'
    },
    {
        humanName: 'Education Level',
        action: 'create',
        subject: 'Educationlevel'
    },
    {
        humanName: 'Education Level',
        action: 'read',
        subject: 'Educationlevel'
    },
    {
        humanName: 'Education Level',
        action: 'update',
        subject: 'Educationlevel'
    },
    {
        humanName: 'Education Level',
        action: 'delete',
        subject: 'Educationlevel'
    },
    {
        humanName: 'Requirements',
        action: 'read',
        subject: 'Requirements'
    },
    {
        humanName: '',
        action: 'create',
        subject: 'Requirement'
    },
    {
        humanName: '',
        action: 'read',
        subject: 'Requirement'
    },
    {
        humanName: '',
        action: 'update',
        subject: 'Requirement'
    },
    {
        humanName: 'Requirement',
        action: 'delete',
        subject: 'Requirement'
    },
    {
        humanName: 'Institutions',
        action: 'read',
        subject: 'Institutions'
    },
    {
        humanName: 'Institution',
        action: 'create',
        subject: 'Institution'
    },
    {
        humanName: 'Institution',
        action: 'read',
        subject: 'Institution'
    },
    {
        humanName: 'Institution',
        action: 'update',
        subject: 'Institution'
    },
    {
        humanName: 'Institution',
        action: 'delete',
        subject: 'Institution'
    },
    {
        humanName: 'Schools',
        action: 'read',
        subject: 'Schools'
    },
    {
        humanName: 'School',
        action: 'create',
        subject: 'School'
    },
    {
        humanName: 'School',
        action: 'read',
        subject: 'School'
    },
    {
        humanName: 'School',
        action: 'update',
        subject: 'School'
    },
    {
        humanName: 'School',
        action: 'delete',
        subject: 'School'
    },
    {
        humanName: 'Countries',
        action: 'read',
        subject: 'Countries'
    },
    {
        humanName: 'Country',
        action: 'create',
        subject: 'Country'
    },
    {
        humanName: 'Country',
        action: 'read',
        subject: 'Country'
    },
    {
        humanName: 'Country',
        action: 'update',
        subject: 'Country'
    },
    {
        humanName: 'Country',
        action: 'delete',
        subject: 'Country'
    },
    {
        humanName: 'Courses',
        action: 'read',
        subject: 'Courses'
    },
    {
        humanName: 'Course',
        action: 'create',
        subject: 'Course'
    },
    {
        humanName: 'Course',
        action: 'read',
        subject: 'Course'
    },
    {
        humanName: 'Course',
        action: 'update',
        subject: 'Course'
    },
    {
        humanName: 'Course',
        action: 'delete',
        subject: 'Course'
    },

    {
        humanName: 'Expenses Settings',
        action: 'read',
        subject: 'Expensessettings'
    },
    {
        humanName: 'Expenses Policies',
        action: 'read',
        subject: 'Expensepolicies'
    },

    {
        humanName: 'Expenses Rules',
        action: 'read',
        subject: 'Expenserules'
    },
    {
        humanName: 'Expenses Approvals',
        action: 'read',
        subject: 'Expenseapprovals'
    },
    {
        humanName: 'Expenses Approvals',
        action: 'read',
        subject: 'Expenseapproval'
    },
    {
        humanName: 'Expenses Approval',
        action: 'create',
        subject: 'Expenseapproval'
    },
    {
        humanName: 'Expenses Approval',
        action: 'update',
        subject: 'Expenseapproval'
    },
    {
        humanName: 'Expenses Approval',
        action: 'delete',
        subject: 'Expenseapproval'
    },
    {
        humanName: 'Expense Types',
        action: 'read',
        subject: 'Expensetypes'
    },
    {
        humanName: 'Expense Type',
        action: 'create',
        subject: 'Expensetype'
    },
    {
        humanName: 'Expense Type',
        action: 'read',
        subject: 'Expensetype'
    },
    {
        humanName: 'Expense Type',
        action: 'update',
        subject: 'Expensetype'
    },
    {
        humanName: 'Expense Type',
        action: 'delete',
        subject: 'Expensetype'
    },
    { humanName: 'System Settings', action: 'read', subject: 'Systemsettings' },
    {
        humanName: 'Leave Types',
        action: 'read',
        subject: 'Leavetypes'
    },
    {
        humanName: 'Leave Type',
        action: 'create',
        subject: 'Leavetype'
    },
    {
        humanName: 'Leave Type',
        action: 'read',
        subject: 'Leavetype'
    },
    {
        humanName: 'Leave Type',
        action: 'update',
        subject: 'Leavetype'
    },
    {
        humanName: 'Leave Type',
        action: 'delete',
        subject: 'Leavetype'
    },

    {
        humanName: 'Income Types',
        action: 'read',
        subject: 'Incometypes'
    },
    {
        humanName: 'Income Type',
        action: 'create',
        subject: 'Incometype'
    },
    {
        humanName: 'Income Type',
        action: 'read',
        subject: 'Incometype'
    },
    {
        humanName: 'Income Type',
        action: 'update',
        subject: 'Incometype'
    },
    {
        humanName: 'Income Type',
        action: 'delete',
        subject: 'Incometype'
    },
    {
        humanName: 'API Keys',
        action: 'read',
        subject: 'Apikeys'
    },
    {
        humanName: 'API Key',
        action: 'create',
        subject: 'Apikey'
    },
    {
        humanName: 'API Key',
        action: 'read',
        subject: 'Apikey'
    },
    {
        humanName: 'API Key',
        action: 'update',
        subject: 'Apikey'
    },
    {
        humanName: 'API Key',
        action: 'delete',
        subject: 'Apikey'
    },
    {
        humanName: 'SMTPS',
        action: 'read',
        subject: 'Smtps'
    },
    {
        humanName: 'SMTP',
        action: 'create',
        subject: 'Smtp'
    },
    {
        humanName: 'SMTP',
        action: 'read',
        subject: 'Smtp'
    },
    {
        humanName: 'SMTP',
        action: 'update',
        subject: 'Smtp'
    },
    {
        humanName: 'SMTP',
        action: 'delete',
        subject: 'Smtp'
    }
];

export default defaultAbility;
