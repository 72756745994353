import React from 'react';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
// import { authUser } from './fake-db';
import {
    ListItemIcon,
    ListItemText,
    ThemeProvider,
    Typography
} from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
// import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import JumboDdPopover from '@jumbo/components/JumboDdPopover';
import Div from '@jumbo/shared/Div';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

const AuthUserDropdown = () => {
    const navigate = useNavigate();
    const { theme } = useJumboTheme();
    const { setAuthToken, authUser: userDetails } = useJumboAuth();
    // console.log(userDetails);
    const onLogout = () => {
        setAuthToken(null);
        navigate('/user/login');
    };

    const userPic = userDetails?.profile_pic
        ? process.env.REACT_APP_BACKEND + '/' + userDetails?.profile_pic
        : null;

    // console.log(userPic);

    return (
        <ThemeProvider theme={theme}>
            {userDetails && (
                <JumboDdPopover
                    triggerButton={
                        <Avatar
                            src={userPic}
                            sizes={'small'}
                            sx={{ boxShadow: 25, cursor: 'pointer' }}
                        />
                    }
                >
                    <Div
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            p: (theme) => theme.spacing(2.5)
                        }}
                    >
                        {userDetails ? (
                            <Avatar
                                src={userPic}
                                alt={userDetails.first_name}
                                sx={{ width: 60, height: 60, mb: 2 }}
                            />
                        ) : null}
                        <Typography variant={'h5'}>
                            {userDetails
                                ? userDetails?.first_name +
                                  ' ' +
                                  userDetails?.last_name
                                : null}
                        </Typography>
                        <Typography variant={'body1'} color="text.secondary">
                            {userDetails ? userDetails.email_address : null}
                        </Typography>
                    </Div>
                    <Divider />
                    <nav>
                        <List disablePadding sx={{ pb: 1 }}>
                            <ListItemButton>
                                <Link
                                    href={`/user/profile/${userDetails.userId}`}
                                >
                                    <Div sx={{ display: 'inline-flex' }}>
                                        <ListItemIcon sx={{ minWidth: 36 }}>
                                            <PersonOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Profile"
                                            sx={{ my: 0 }}
                                        />
                                    </Div>
                                </Link>
                            </ListItemButton>

                            <ListItemButton onClick={onLogout}>
                                <ListItemIcon sx={{ minWidth: 36 }}>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary="Logout" sx={{ my: 0 }} />
                            </ListItemButton>
                        </List>
                    </nav>
                </JumboDdPopover>
            )}
        </ThemeProvider>
    );
};

export default AuthUserDropdown;
