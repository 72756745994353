import React from 'react';

import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useMutation } from 'react-query';
import { studentServices } from 'app/services/student-services';
import { coursesServices } from 'app/services/courses-services';

import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import FreeAutocomplete from 'app/shared/FreeAutocomplete';
import axios from 'axios';

import _ from 'lodash';

const validationSchema = yup.object({});

const CourseCreateForm = ({ onUpdate, hideDialog, studentDetails }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [courses, setCourses] = React.useState([]);
    const [finalCourses, setFinalCourses] = React.useState([]);

    const { mutate: updatePreferredCoursesMutation, isLoading } = useMutation(
        studentServices.updatePreferredCourses,
        {
            onSuccess: () => {
                onUpdate();
                hideDialog();
                enqueueSnackbar('Course(s) have been created successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (courses) => {
        if (
            (courses.option_three &&
                (!courses.option_one || !courses.option_two)) ||
            (courses.option_two && !courses.option_one)
        ) {
            enqueueSnackbar('You must select 1st and 2nd Option', {
                variant: 'warning'
            });
            return;
        }

        const courseSet = new Set([
            courses.option_one,
            courses.option_two,
            courses.option_three
        ]);

        if (courseSet.size !== 3) {
            enqueueSnackbar('You must select unique courses', {
                variant: 'warning'
            });
            return;
        }

        try {
            updatePreferredCoursesMutation({
                id: studentDetails?.id,
                ...courses
            });
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelTokenCourses = axios.CancelToken.source();
        const cTokenCourses = {
            cancelToken: cancelTokenCourses.token
        };
        const fetchCourses = async () => {
            try {
                const res = await coursesServices.getAllCourses(cTokenCourses);

                setCourses(res.courses);
                setFinalCourses(res.courses);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchCourses();

        return () => {
            cancelTokenCourses.cancel();
        };
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            <Formik
                validateOnChange={true}
                initialValues={{
                    option_one: studentDetails?.preferred_courses?.option_one,
                    option_two: studentDetails?.preferred_courses?.option_two,
                    option_three:
                        studentDetails?.preferred_courses?.option_three
                }}
                validationSchema={validationSchema}
                onSubmit={async (data, { setSubmitting }) => {
                    await onCreate(data);
                }}
            >
                {({ isSubmitting, errors, touched, values, ...restProps }) => (
                    <Form
                        style={{ textAlign: 'left' }}
                        noValidate
                        autoComplete="off"
                    >
                        <Div sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <FreeAutocomplete
                                        fullWidth
                                        size={'small'}
                                        id="option_one"
                                        name="option_one"
                                        field_name="course_name"
                                        label="1st Choice"
                                        options={finalCourses}
                                    />
                                </Grid>
                                {(values.option_one || values.option_two) && (
                                    <Grid item xs={12} md={12}>
                                        <FreeAutocomplete
                                            fullWidth
                                            size={'small'}
                                            id="option_two"
                                            name="option_two"
                                            field_name="course_name"
                                            label="2nd Choice"
                                            options={finalCourses}
                                        />
                                    </Grid>
                                )}
                                {(values.option_two || values.option_three) && (
                                    <Grid item xs={12} md={12}>
                                        <FreeAutocomplete
                                            fullWidth
                                            size={'small'}
                                            id="option_three"
                                            name="option_three"
                                            field_name="course_name"
                                            label="3rd Choice"
                                            options={finalCourses}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid
                                sx={{
                                    margin: '30px auto'
                                }}
                                container
                                alignItems="right"
                                justify="flex-end"
                                justifyContent="right"
                            >
                                <Button
                                    size="large"
                                    sx={{ mb: 3 }}
                                    onClick={() => hideDialog()}
                                >
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isLoading}
                                >
                                    Update
                                </LoadingButton>
                            </Grid>
                        </Div>
                    </Form>
                )}
            </Formik>
        </Div>
    );
};

export default CourseCreateForm;
