import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SchoolIcon from '@mui/icons-material/School';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

const folders = [
    {
        icon: <FeedbackIcon fontSize={'small'} />,
        label: 'All Surveys',
        slug: 'all',
        path: '/app/surveys/all'
    },
    {
        icon: <SchoolIcon fontSize={'small'} />,
        label: 'Enrollment Surveys',
        slug: 'enrollments',
        path: '/app/surveys/enrollments'
    },
    {
        icon: <EventAvailableIcon fontSize={'small'} />,
        label: 'Events/Training Surveys',
        slug: 'events',
        path: '/app/surveys/events'
    }
];
const FolderDropdown = () => {
    const navigate = useNavigate();
    const [folder, setFolder] = React.useState('');
    return (
        <FormControl sx={{ width: 120 }} size={'small'}>
            <InputLabel>Folder</InputLabel>
            <Select
                value={folder}
                label="Folder"
                onChange={(event) => setFolder(event.target.value)}
            >
                <MenuItem value="">
                    <em>Select Folder</em>
                </MenuItem>
                {folders.map((folder, index) => (
                    <MenuItem
                        key={index}
                        value={folder}
                        onClick={() => navigate(`/app/contacts/${folder.slug}`)}
                    >
                        {folder.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
export default FolderDropdown;
