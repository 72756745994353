import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import Span from '@jumbo/shared/Span';
import Button from '@mui/material/Button';
import {
    alpha,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Chip,
    CircularProgress,
    Alert
} from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InfoIcon from '@mui/icons-material/Info';
import NumbersIcon from '@mui/icons-material/Numbers';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import HotelIcon from '@mui/icons-material/Hotel';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import DescriptionIcon from '@mui/icons-material/Description';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import Grid from '@mui/material/Grid';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Divider from '@mui/material/Divider';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import List from '@mui/material/List';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Stack from '@mui/material/Stack';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import Div from '@jumbo/shared/Div';
import moment from 'moment';
import _ from 'lodash';
import { capitalizeAllWords, formatNumber } from 'app/utils/appHelpers';
import RequestStatus from 'app/pages/statuses/RequestStatus';
import RecentActivities from './RecentActivities';
import ApprovalForm from './ApprovalForm';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from 'react-query';
import { requestsServices } from 'app/services/requests-services';
import { useSnackbar } from 'notistack';
import MySwal from 'app/hooks/MySwal/MySwal';

const RequestDetail = ({
    request,
    authUser,
    onSave = () => {},
    onClose = () => {}
}) => {
    const { enqueueSnackbar } = useSnackbar();

    // console.log('request');
    // console.log(request);

    const { REACT_APP_BACKEND } = process.env;
    const profilePic = request?.employeeId?.profile_pic
        ? process.env.REACT_APP_BACKEND + '/' + request?.employeeId?.profile_pic
        : null;

    const handleDownload = (file) => {
        const link = document.createElement('a');
        link.href = `${REACT_APP_BACKEND}/${file}`;
        // link.download = file;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
    };

    const { mutate: cancelRequestMutation, isLoading: isCancelling } =
        useMutation(requestsServices.cancelRequest, {
            onSuccess: () => {
                enqueueSnackbar(
                    'Request status has been updated successfully.',
                    {
                        variant: 'success'
                    }
                );
                onSave();
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(
                    error?.message ? error.message : error.toString(),
                    {
                        variant: 'error'
                    }
                );

                return false;
            }
        });

    const onCancel = async (requestId) => {
        try {
            cancelRequestMutation({
                requestId
            });
        } catch (error) {}
    };

    const confirmCancel = (id) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Cancel it!',
            cancelButtonText: 'No, Keep it!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                onCancel(id);
            } else {
                MySwal.fire(
                    'We keeping it',
                    'Your request is still active :)',
                    'info'
                );
            }
        });
    };

    return (
        <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
            <CardHeader
                title={`${request?.expense_name} ${
                    request?.module === 'travel'
                        ? `(${moment(request?.travel?.departure_date).format(
                              'DD MMM YYYY'
                          )} - ${moment(request?.travel?.arrival_date).format(
                              'DD MMM YYYY'
                          )})`
                        : ''
                }`}
                subheader={
                    <Div
                        sx={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography
                            variant={'body1'}
                            color={'text.secondary'}
                            mr={0.5}
                        >
                            {`${request?.employeeId?.first_name} ${request?.employeeId?.last_name}`}
                        </Typography>
                    </Div>
                }
                avatar={<Avatar src={profilePic} />}
                action={
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
            />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <List disablePadding>
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <NumbersIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Request ID #
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {`IECR-${String(
                                            request?.request_id
                                        ).padStart(8, '0')}`}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        {request?.module === 'travel' && (
                            <>
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <LocalAirportIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>

                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Trip Details
                                            </Typography>
                                        }
                                        secondary={
                                            <Div
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '100%'
                                                }}
                                            >
                                                <Div
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'left'
                                                    }}
                                                >
                                                    <Div
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                            fontSize: '14px',
                                                            fontColor: '#333'
                                                        }}
                                                        variant="h5"
                                                        mb={0.5}
                                                    >
                                                        {capitalizeAllWords(
                                                            request?.travel
                                                                ?.travel_type
                                                        )}{' '}
                                                        {capitalizeAllWords(
                                                            request?.travel
                                                                ?.trip_type
                                                        )}{' '}
                                                        From{' '}
                                                        <Typography
                                                            sx={{
                                                                color: 'primary.main',
                                                                fontSize:
                                                                    '14px',
                                                                px: '3px'
                                                            }}
                                                        >
                                                            {
                                                                request?.travel
                                                                    ?.origin
                                                            }
                                                        </Typography>{' '}
                                                        {request?.travel
                                                            ?.destination ? (
                                                            <>
                                                                {' to '}
                                                                <Span
                                                                    sx={{
                                                                        color: 'primary.main',
                                                                        fontSize:
                                                                            '14px',
                                                                        px: '3px'
                                                                    }}
                                                                >
                                                                    {
                                                                        request
                                                                            ?.travel
                                                                            ?.destination
                                                                    }
                                                                </Span>
                                                            </>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Div>
                                                </Div>
                                            </Div>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <MonetizationOnIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Fare Amount
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h5'} mb={0}>
                                                {formatNumber(
                                                    request?.travel?.fare_amount
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <HotelIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Accomodation (Hotel Charges)
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h5'} mb={0}>
                                                {formatNumber(
                                                    request?.travel
                                                        ?.accomodation_fees
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <LocalTaxiIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Taxi Charges
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h5'} mb={0}>
                                                {formatNumber(
                                                    request?.travel?.taxi_fees
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <MailOutlineIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Per Diem
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h5'} mb={0}>
                                                {formatNumber(
                                                    request?.travel?.per_diem
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <MoreHorizIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Miscellaneous
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h5'} mb={0}>
                                                {formatNumber(
                                                    request?.travel
                                                        ?.miscellaneous
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                            </>
                        )}
                        {request?.module === 'purchase' &&
                            request?.purchase &&
                            request?.purchase?.length > 0 &&
                            _.map(request?.purchase, (purchase) => (
                                <Div key={purchase?.id}>
                                    <ListItem sx={{ px: 4 }}>
                                        <ListItemAvatar sx={{ minWidth: 66 }}>
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    height: 48,
                                                    width: 48,
                                                    bgcolor: (theme) =>
                                                        alpha(
                                                            theme.palette
                                                                .primary.main,
                                                            0.15
                                                        )
                                                }}
                                            >
                                                <AttachMoneyIcon
                                                    sx={{
                                                        color: 'primary.light'
                                                    }}
                                                />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant={'body1'}
                                                    color={'text.secondary'}
                                                    mb={0.5}
                                                >
                                                    Expense Type
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    variant={'h5'}
                                                    mb={0}
                                                >
                                                    {
                                                        purchase?.expenseType
                                                            ?.expense_type_name
                                                    }
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Divider component={'li'} />
                                    <ListItem sx={{ px: 4 }}>
                                        <ListItemAvatar sx={{ minWidth: 66 }}>
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    height: 48,
                                                    width: 48,
                                                    bgcolor: (theme) =>
                                                        alpha(
                                                            theme.palette
                                                                .primary.main,
                                                            0.15
                                                        )
                                                }}
                                            >
                                                <InfoIcon
                                                    sx={{
                                                        color: 'primary.light'
                                                    }}
                                                />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant={'body1'}
                                                    color={'text.secondary'}
                                                    mb={0.5}
                                                >
                                                    Details
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    variant={'h5'}
                                                    mb={0}
                                                >
                                                    {purchase?.item_name}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Divider component={'li'} />

                                    <ListItem sx={{ px: 4 }}>
                                        <ListItemAvatar sx={{ minWidth: 66 }}>
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    height: 48,
                                                    width: 48,
                                                    bgcolor: (theme) =>
                                                        alpha(
                                                            theme.palette
                                                                .primary.main,
                                                            0.15
                                                        )
                                                }}
                                            >
                                                <MonetizationOnIcon
                                                    sx={{
                                                        color: 'primary.light'
                                                    }}
                                                />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant={'body1'}
                                                    color={'text.secondary'}
                                                    mb={0.5}
                                                >
                                                    Total Amount requested
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    variant={'h5'}
                                                    mb={0}
                                                >
                                                    {formatNumber(
                                                        request?.total_amount
                                                    )}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Divider component={'li'} />
                                </Div>
                            ))}
                        {(request?.module === 'advance' ||
                            request?.module === 'report') && (
                            <>
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <MonetizationOnIcon
                                                sx={{
                                                    color: 'primary.light'
                                                }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Related Expense
                                            </Typography>
                                        }
                                        secondary={
                                            <Div
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <Typography
                                                    variant={'h5'}
                                                    mb={0}
                                                >
                                                    {request?.module ===
                                                    'advance'
                                                        ? request?.advance
                                                              ?.expenseId
                                                              ?.expense_name
                                                        : request?.expenses
                                                              ?.expenseId
                                                              ?.expense_name}
                                                </Typography>
                                                <Divider sx={{ my: '5px' }} />

                                                <Div
                                                    sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        verticalAlign: 'middle',
                                                        alignItems: 'center',
                                                        my: '5px'
                                                    }}
                                                >
                                                    <Typography
                                                        variant={'h5'}
                                                        sx={{
                                                            width: '50%',
                                                            // fontWeight: 300,
                                                            my: '5px',
                                                            color: 'primary.main'
                                                        }}
                                                    >
                                                        Expense Amount
                                                    </Typography>
                                                    <Typography
                                                        variant={'h5'}
                                                        mb={0}
                                                        color="text.secondary"
                                                        sx={{
                                                            width: '50%',
                                                            textAlign: 'right',
                                                            my: '5px',
                                                            color: 'primary.main'
                                                        }}
                                                    >
                                                        {request?.module ===
                                                        'advance'
                                                            ? formatNumber(
                                                                  request
                                                                      ?.advance
                                                                      ?.expenseId
                                                                      ?.total_amount
                                                              )
                                                            : formatNumber(
                                                                  request
                                                                      ?.expenses
                                                                      ?.expenseId
                                                                      ?.total_amount
                                                              )}
                                                    </Typography>
                                                </Div>
                                            </Div>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />

                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <AccountBalanceWalletIcon
                                                sx={{
                                                    color: 'primary.light'
                                                }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                {request?.module === 'advance'
                                                    ? 'Requested Advance Amount'
                                                    : 'Submitted Expenses'}
                                            </Typography>
                                        }
                                        secondary={
                                            <Div
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                {request?.module === 'report' &&
                                                    _.map(
                                                        request?.expenses
                                                            .expenses,
                                                        (expense) => (
                                                            <Div
                                                                key={
                                                                    expense?.id
                                                                }
                                                                sx={{
                                                                    width: '100%',
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                    verticalAlign:
                                                                        'middle',
                                                                    alignItems:
                                                                        'center',
                                                                    my: '5px'
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant={
                                                                        'h5'
                                                                    }
                                                                    sx={{
                                                                        width: '33%',
                                                                        fontWeight: 400,
                                                                        my: '5px'
                                                                    }}
                                                                >
                                                                    {
                                                                        expense?.expense_type_name
                                                                    }
                                                                </Typography>
                                                                <Typography
                                                                    variant={
                                                                        'h5'
                                                                    }
                                                                    color="text.secondary"
                                                                    sx={{
                                                                        width: '33%',
                                                                        textAlign:
                                                                            'right',
                                                                        my: '5px'
                                                                    }}
                                                                >
                                                                    {expense?.amount
                                                                        ? formatNumber(
                                                                              expense?.amount
                                                                          )
                                                                        : ''}
                                                                </Typography>
                                                                <Span
                                                                    sx={{
                                                                        width: '33%',
                                                                        textAlign:
                                                                            'right'
                                                                    }}
                                                                >
                                                                    <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        color="info"
                                                                        onClick={() =>
                                                                            handleDownload(
                                                                                expense?.document_file
                                                                            )
                                                                        }
                                                                    >
                                                                        Receipt
                                                                    </Button>
                                                                </Span>
                                                            </Div>
                                                        )
                                                    )}
                                            </Div>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <MonetizationOnIcon
                                                sx={{
                                                    color: 'primary.light'
                                                }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                {request?.module === 'advance'
                                                    ? 'Total Amount Requested'
                                                    : 'Total Submitted Expenses'}
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h5'} mb={0}>
                                                {formatNumber(
                                                    request?.total_amount
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                            </>
                        )}
                        {request?.violations &&
                            request?.violations.length > 0 && (
                                <>
                                    <ListItem sx={{ px: 4 }}>
                                        <ListItemAvatar sx={{ minWidth: 66 }}>
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    height: 48,
                                                    width: 48,
                                                    bgcolor: (theme) =>
                                                        alpha(
                                                            theme.palette
                                                                .primary.main,
                                                            0.15
                                                        )
                                                }}
                                            >
                                                <ReportProblemIcon
                                                    sx={{
                                                        color: 'primary.light'
                                                    }}
                                                />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant={'body1'}
                                                    color={'text.secondary'}
                                                    mb={0.5}
                                                >
                                                    Violations
                                                </Typography>
                                            }
                                            secondary={
                                                <Div
                                                    sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    {_.map(
                                                        request.violations,
                                                        (violation) => (
                                                            <Alert severity="warning">
                                                                {
                                                                    violation.description
                                                                }
                                                            </Alert>
                                                        )
                                                    )}
                                                </Div>
                                            }
                                        />
                                    </ListItem>
                                    <Divider component={'li'} />
                                </>
                            )}
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <DescriptionIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Purpose
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {request?.purpose}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                    </List>
                </Grid>
                <Grid item xs={6}>
                    <List disablePadding>
                        {request?.status === 1 &&
                            request?.employeeId?.id !== authUser?.userId &&
                            request?.currentApproverId?.id ===
                                authUser?.userId && (
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            ></Typography>
                                        }
                                        secondary={
                                            <Div
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                    // background: '#333'
                                                }}
                                            >
                                                <ApprovalForm
                                                    onSave={onSave}
                                                    request={request}
                                                />
                                            </Div>
                                        }
                                    />
                                </ListItem>
                            )}
                        {/* <Divider component={'li'} /> */}
                        <ListItem sx={{ px: 4 }}>
                            {/* <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <MailOutlineIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar> */}
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    ></Typography>
                                }
                                secondary={
                                    <RecentActivities
                                        authUser={authUser}
                                        request={request}
                                    />
                                }
                            />
                        </ListItem>
                        {/* <Divider component={'li'} /> */}
                        {[1, 2].includes(request.status) &&
                            request?.employeeId?.id === authUser?.userId && (
                                <ListItem sx={{ px: 4 }}>
                                    <Div sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={12}>
                                                <LoadingButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="error"
                                                    size="large"
                                                    onClick={() =>
                                                        confirmCancel(
                                                            request?.id
                                                        )
                                                    }
                                                    loading={isCancelling}
                                                    fullWidth
                                                >
                                                    Cancel Request
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </Div>
                                </ListItem>
                            )}
                    </List>
                </Grid>
            </Grid>
        </Div>
    );
};

export default RequestDetail;
