import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const educationlevelsServices = {};

educationlevelsServices.getAllEducationlevels = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/education-levels',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

educationlevelsServices.getEducationlevels = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/education-levels', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

educationlevelsServices.getEducationlevelDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(
        `/education-levelss/education-level/${id}`,
        {
            id: id
        }
    );

    return data;
};

educationlevelsServices.addEducationlevel = async (educationlevels) => {
    const { data } = await jwtAuthAxios.post(
        `/education-levels`,
        educationlevels
    );
    return data;
};

educationlevelsServices.updateEducationlevel = async (educationlevels) => {
    // console.log('Service Educationlevel');
    // console.log(educationlevels);
    const { data } = await jwtAuthAxios.patch(
        `/education-levels/${educationlevels.id}`,
        educationlevels
    );
    return data;
};

educationlevelsServices.deleteEducationlevel = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/education-levels/${id}`, {
        id: id
    });
    return data;
};

educationlevelsServices.deleteMultipleEducationlevels = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/education-levels`, {
        data: ids
    });
    return data;
};

export { educationlevelsServices };
