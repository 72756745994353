import React from 'react';
import styled from '@emotion/styled';
import Slide from '@mui/material/Slide';
import Span from '@jumbo/shared/Span';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList';
import { reimbursementsServices } from '../../../../../services/reimbursements-services';
import ReimbursementItem from './ReimbursementItem';
import JumboListToolbar from '@jumbo/components/JumboList/components/JumboListToolbar';
import BulkActions from './BulkActions';
import { Card } from '@mui/material';
import JumboSearch from '@jumbo/components/JumboSearch';
import useReimbursementsApp from '../../hooks/useReimbursementsApp';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import ListIcon from '@mui/icons-material/List';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import ReimbursementDetail from '../ReimbursementDetail';

const Item = styled(Span)(({ theme }) => ({
    minWidth: 0,
    flexGrow: 0,
    padding: theme.spacing(0, 1)
}));

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const ReimbursementsList = () => {
    const { showDialog, hideDialog } = useJumboDialog();
    const navigate = useNavigate();
    const params = useParams();
    const listRef = React.useRef();
    const {
        refreshReimbursementsList,
        setReimbursementsListRefresh,
        setSelectedReimbursements
    } = useReimbursementsApp();
    const [view, setView] = React.useState('list');

    const [queryOptions, setQueryOptions] = React.useState({
        queryKey: 'reimbursements',
        queryParams: { category: params.category, id: params.id },
        countKey: 'count',
        dataKey: 'reimbursements'
    });

    React.useEffect(() => {
        setQueryOptions((state) => ({
            ...state,
            queryParams: {
                ...state.queryParams,
                category: params.category,
                id: params.id
            }
        }));
    }, [params]);

    const renderReimbursement = React.useCallback(
        (reimbursement) => {
            return (
                <ReimbursementItem reimbursement={reimbursement} view={view} />
            );
        },
        [view]
    );

    React.useEffect(() => {
        if (refreshReimbursementsList) {
            listRef.current.refresh();
            setReimbursementsListRefresh(false);
        }
    }, [refreshReimbursementsList]);

    const handleReimbursementUpdate = React.useCallback(() => {
        hideDialog();
        setReimbursementsListRefresh(true);
    }, [hideDialog, setReimbursementsListRefresh]);

    const handleOnChange = React.useCallback((keywords) => {
        setQueryOptions((state) => ({
            ...state,
            queryParams: {
                ...state.queryParams,
                keywords: keywords
            }
        }));
    }, []);

    return (
        <JumboRqList
            ref={listRef}
            wrapperComponent={Card}
            service={reimbursementsServices.getReimbursements}
            primaryKey={'id'}
            queryOptions={queryOptions}
            itemsPerPage={8}
            itemsPerPageOptions={[8, 15, 20]}
            renderItem={renderReimbursement}
            componentElement={'div'}
            sx={view === 'grid' ? { p: (theme) => theme.spacing(1, 3, 3) } : {}}
            wrapperSx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column'
            }}
            toolbar={
                <JumboListToolbar
                    hideItemsPerPage={true}
                    bulkActions={<BulkActions />}
                    // actionTail={
                    //     <ButtonGroup
                    //         variant="outlined"
                    //         disableElevation
                    //         sx={{
                    //             '& .MuiButton-root': {
                    //                 px: 1
                    //             }
                    //         }}
                    //     >
                    //         <Button
                    //             variant={
                    //                 view === 'list' ? 'contained' : 'outlined'
                    //             }
                    //             onClick={() => setView('list')}
                    //         >
                    //             <ListIcon />
                    //         </Button>
                    //         <Button
                    //             variant={
                    //                 view === 'grid' ? 'contained' : 'outlined'
                    //             }
                    //             onClick={() => setView('grid')}
                    //         >
                    //             <ViewComfyIcon />
                    //         </Button>
                    //     </ButtonGroup>
                    // }
                >
                    <JumboSearch
                        onChange={handleOnChange}
                        value={queryOptions?.queryParams?.keywords || ''}
                        sx={{
                            display: { xs: 'none', md: 'block' }
                        }}
                    />
                </JumboListToolbar>
            }
            onSelectionChange={setSelectedReimbursements}
            view={view}
        />
    );
};

export default ReimbursementsList;
