import React from 'react';
import { Avatar, Typography } from '@mui/material';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import JumboContent from '@jumbo/components/JumboContent';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import { ASSET_AVATARS } from '../../../utils/constants/paths';
import { getAssetPath } from '../../../utils/appHelpers';

const UserProfileCard1 = ({
    cardPic = 'url',
    cardName = 'Dummy Name',
    cardPhone = '',
    cardEmail = '',
    cardDesignation = 'User Role',
    cardLocation = '',
    cardDetails = false,
    actions = false,
    bgColor = false
}) => {
    return (
        <JumboCardQuick noWrapper sx={{ width: '100%' }} bgColor={bgColor}>
            <JumboContent
                action={
                    actions ? actions : <></>
                    // <JumboDdMenu
                    //     menuItems={[
                    //         { title: 'Profile', slug: 'profile' },
                    //         { title: 'Friends', slug: 'friends' }
                    //     ]}
                    // />
                }
            />
            <JumboContent
                sx={{
                    p: (theme) => theme.spacing(0, 3, 3),
                    textAlign: 'center'
                }}
            >
                <Avatar
                    sx={{
                        width: 90,
                        height: 90,
                        boxShadow: 2,
                        m: '0 auto 20px'
                    }}
                    src={cardPic}
                />
                <Typography variant={'h5'}>{cardName}</Typography>
                {cardPhone && (
                    <Typography
                        variant={'h6'}
                        color={bgColor ? '#fff' : 'text.secondary'}
                        mb={1}
                    >
                        {cardPhone}
                    </Typography>
                )}
                {cardEmail && (
                    <Typography
                        variant={'h6'}
                        color={bgColor ? '#fff' : 'text.secondary'}
                        mb={1}
                    >
                        {cardEmail.toLowerCase()}
                    </Typography>
                )}
                {cardDesignation && (
                    <Typography
                        variant={'h6'}
                        color={bgColor ? '#fff' : 'text.secondary'}
                        mb={1}
                    >
                        {cardDesignation.toUpperCase()}
                    </Typography>
                )}
                {cardLocation && (
                    <Typography
                        variant={'h6'}
                        color={bgColor ? '#fff' : 'text.secondary'}
                        sx={{ fontWeight: 700 }}
                        mb={1}
                    >
                        {cardLocation.toUpperCase()}
                    </Typography>
                )}
            </JumboContent>
            {cardDetails && (
                <JumboContent
                    sx={{
                        p: 3,
                        textAlign: 'center',
                        backgroundColor: (theme) => theme.palette.action.hover
                    }}
                >
                    <Typography variant={'body1'}>{cardDetails}</Typography>
                </JumboContent>
            )}
        </JumboCardQuick>
    );
};

export default UserProfileCard1;
