import React from 'react';
import { Button, ListItem, ListItemText, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import Div from '@jumbo/shared/Div';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import CommentIcon from '@mui/icons-material/Comment';
import StarRateIcon from '@mui/icons-material/StarRate';
import _ from 'lodash';
import ApplicationStatus from 'app/pages/statuses/application-status/ApplicationStatus';

import moment from 'moment';

const EventItem = ({ item }) => {
    const { theme } = useJumboTheme();

    const selectedStatus = _.find(
        ApplicationStatus,
        (status) => status.id === item.status
    );

    return (
        <ListItem
            alignItems="flex-start"
            sx={{
                p: (theme) => theme.spacing(2, 3),
                borderBottom: 1,
                borderBottomColor: 'divider',

                [theme.breakpoints.down('sm')]: {
                    flexWrap: 'wrap'
                }
            }}
        >
            <ListItemText>
                <Typography
                    component={'div'}
                    sx={{
                        display: 'flex',
                        minWidth: 0,
                        [theme.breakpoints.down('md')]: {
                            flexWrap: 'wrap'
                        }
                    }}
                >
                    <Div
                        sx={{
                            flex: 1,
                            [theme.breakpoints.down('md')]: {
                                width: '100%'
                            }
                        }}
                    >
                        <Typography
                            variant={'h5'}
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                mb: { md: 3.25 },
                                fontSize: { xs: 14, md: 18 }
                            }}
                        >
                            Application ID: {item?.applicationCode}
                        </Typography>
                        <Typography
                            variant={'body1'}
                            color={'inherit'}
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                mb: { md: 0.5 },
                                fontSize: { xs: 12, md: 14 }
                            }}
                        >
                            <strong>Application Date: </strong>
                            {moment(item?.application_date).format(
                                'DD MMM YYYY'
                            )}
                        </Typography>
                        <Typography
                            variant={'body1'}
                            color={'inherit'}
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                mb: { md: 3.25 },
                                fontSize: { xs: 12, md: 14 }
                            }}
                        >
                            <strong>Submitted By: </strong>
                            {item?.creator?.first_name}
                        </Typography>
                        <Typography
                            variant={'body1'}
                            color={'text.secondary'}
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                margin: '10px 0'
                            }}
                        >
                            <CommentIcon sx={{ fontSize: 20, mr: 1 }} />
                            {item?.comment ? (
                                item?.comment
                            ) : (
                                <em>No Comment</em>
                            )}
                        </Typography>
                    </Div>

                    <Div
                        sx={{
                            textAlign: { md: 'right' },
                            mt: { xs: 2, md: 1 },
                            [theme.breakpoints.down('md')]: {
                                width: '100%'
                            }
                        }}
                    >
                        <Typography
                            variant={'body1'}
                            color={'secondary'}
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                mb: { md: 3.25 },
                                fontSize: { xs: 12, md: 14 }
                            }}
                        >
                            <EventNoteOutlinedIcon
                                sx={{ fontSize: { xs: 12, md: 14 }, mr: 1 }}
                            />
                            Due: {moment(item?.due_date).format('DD MMM YYYY')}
                        </Typography>
                        <Typography>
                            <Chip
                                label={
                                    selectedStatus?.status
                                        ? selectedStatus?.status
                                        : 'Unknown'
                                }
                                size={'small'}
                                color={
                                    selectedStatus
                                        ? selectedStatus.type
                                        : 'secondary'
                                }
                                sx={{ borderRadius: 1, mb: 1 }}
                            />
                        </Typography>
                        <Button
                            variant={'text'}
                            endIcon={
                                <ArrowRightAltOutlinedIcon
                                    sx={{ fontSize: 20, ml: 1 }}
                                />
                            }
                            sx={{
                                p: 0,
                                textTransform: 'none',
                                letterSpacing: 0,

                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        >
                            Check Details
                        </Button>
                    </Div>
                </Typography>
            </ListItemText>
        </ListItem>
    );
};

export default EventItem;
