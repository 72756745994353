import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import Span from '@jumbo/shared/Span';
import Stack from '@mui/material/Stack';
import useJumboLayoutSidebar from '@jumbo/hooks/useJumboLayoutSidebar';
import AuthUserDropdown from '../../../../shared/widgets/AuthUserDropdown';
import NotificationsDropdown from '../../../../shared/NotificationsDropdown';
import MessagesDropdown from '../../../../shared/MessagesDropdown';
import SearchGlobal from '../../../../shared/SearchGlobal';
import { IconButton, Slide, useMediaQuery } from '@mui/material';
import Div from '@jumbo/shared/Div';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import JumboIconButton from '@jumbo/components/JumboIconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Logo from '../../../../shared/Logo';
import { SIDEBAR_STYLES, SIDEBAR_VARIANTS } from '@jumbo/utils/constants';
import useJumboHeaderTheme from '@jumbo/hooks/useJumboHeaderTheme';
import { useSnackbar } from 'notistack';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import EmailForm from './EmailForm';

const Item = styled(Span)(({ theme }) => ({
    padding: theme.spacing(0, 1)
}));

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const Header = () => {
    const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
    const [dropdownSearchVisibility, setDropdownSearchVisibility] =
        React.useState(false);
    const { headerTheme } = useJumboHeaderTheme();
    const { authUser } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();

    const showDropdownSearch = true; //useMediaQuery('(max-width:575px)');

    const handleEmailForm = () => {
        showDialog({
            title: 'New Message',
            content: (
                <EmailForm hideDialog={hideDialog} userDetails={authUser} />
            ),
            maxWidth: 'md',
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true
        });
    };

    return (
        <React.Fragment>
            {(sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ||
                sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY ||
                (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT &&
                    !sidebarOptions.open)) && (
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{
                        ml:
                            sidebarOptions.style ===
                            SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                                ? -2
                                : 0,
                        mr: 3
                    }}
                    onClick={() =>
                        setSidebarOptions({ open: !sidebarOptions.open })
                    }
                >
                    {sidebarOptions?.open ? <MenuOpenIcon /> : <MenuIcon />}
                </IconButton>
            )}
            {sidebarOptions?.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
                <Logo sx={{ mr: 3 }} mode={headerTheme.type ?? 'light'} />
            )}

            <Button
                variant="contained"
                startIcon={<EmailIcon />}
                color="secondary"
                size="large"
                onClick={() => handleEmailForm()}
            >
                Send Email
            </Button>

            {showDropdownSearch && (
                <Slide in={dropdownSearchVisibility}>
                    <Div
                        sx={{
                            zIndex: 5,
                            left: 0,
                            right: 0,
                            position: 'absolute',
                            height: '100%'
                        }}
                    >
                        <SearchGlobal
                            sx={{
                                maxWidth: 'none',
                                height: '100%',
                                display: 'flex',

                                '& .MuiInputBase-root': {
                                    flex: 1,
                                    borderRadius: 0,
                                    background: (theme) =>
                                        theme.palette.background.default
                                },
                                '& .MuiInputBase-input': {
                                    pr: 6
                                }
                            }}
                        />
                        <IconButton
                            sx={{
                                position: 'absolute',
                                right: 15,
                                top: '50%',
                                color: 'inherit',
                                transform: 'translateY(-50%)'
                            }}
                            onClick={() => setDropdownSearchVisibility(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Div>
                </Slide>
            )}
            {/* {!showDropdownSearch && (
                <SearchGlobal
                    sx={{
                        maxWidth: { xs: 240, md: 320 }
                    }}
                />
            )} */}
            <Stack
                direction="row"
                alignItems="center"
                spacing={1.25}
                sx={{ ml: 'auto' }}
            >
                {/* {showDropdownSearch && (
                    <JumboIconButton
                        elevation={25}
                        onClick={() => setDropdownSearchVisibility(true)}
                    >
                        <SearchIcon fontSize={'small'} />
                    </JumboIconButton>
                )} */}
                {/* <MessagesDropdown/> */}
                <NotificationsDropdown />
                <AuthUserDropdown />
            </Stack>
        </React.Fragment>
    );
};

export default Header;
