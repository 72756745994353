import React from 'react';
import {
    IconButton,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Label } from '@mui/icons-material';
import SurveyForm from '../SurveyForm';
import useSurveysApp from '../../hooks/useSurveysApp';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import LabelForm from '../LabelForm';

const SurveyFab = () => {
    const { hideDialog, showDialog } = useJumboDialog();
    const { setSurveysListRefresh, setLabelsListRefresh } = useSurveysApp();

    const refreshListAndCloseDialog = () => {
        setSurveysListRefresh(true);
        setLabelsListRefresh(true);
        hideDialog();
    };

    const showAddLabelDialog = React.useCallback(() => {
        showDialog({
            title: 'Add New Label',
            content: <LabelForm onSave={refreshListAndCloseDialog} />
        });
    }, []);

    const handleSurveyAdd = React.useCallback(() => {
        hideDialog();
        setSurveysListRefresh(true);
    }, [hideDialog, setSurveysListRefresh]);

    const showAddSurveyDialog = React.useCallback(() => {
        showDialog({
            title: 'Add New survey',
            content: <SurveyForm onSave={handleSurveyAdd} />
        });
    }, [handleSurveyAdd, showDialog]);

    return (
        <SpeedDial
            ariaLabel={'survey-fab'}
            icon={<SpeedDialIcon />}
            sx={{
                position: 'fixed',
                right: 30,
                bottom: 30
            }}
        >
            <SpeedDialAction
                icon={
                    <IconButton onClick={showAddSurveyDialog}>
                        <PersonAddIcon />
                    </IconButton>
                }
                tooltipTitle={'Add Survey'}
            />
            <SpeedDialAction
                icon={
                    <IconButton onClick={showAddLabelDialog}>
                        <Label />
                    </IconButton>
                }
                tooltipTitle={'Add Label'}
            />
        </SpeedDial>
    );
};
export default SurveyFab;
