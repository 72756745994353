import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
    alpha,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from '@mui/material';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import FeedbackIcon from '@mui/icons-material/Feedback';
import Divider from '@mui/material/Divider';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import List from '@mui/material/List';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Stack from '@mui/material/Stack';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import Div from '@jumbo/shared/Div';

const SurveyDetail = ({ survey, onClose }) => {
    return (
        <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
            <CardHeader
                title={`${survey?.studentId?.given_name} ${survey?.studentId?.surname}`}
                subheader={
                    survey?.type === 'enrollment'
                        ? `Enrolled At: ${
                              survey?.institutionId?.institution_name || ''
                          }, ${
                              survey?.institutionCountryId?.country_name || ''
                          }`
                        : `Event: ${survey?.eventId?.event_name}`
                }
                // avatar={<Avatar src={survey?.profile_pic} />}
                action={
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
            />
            <List disablePadding>
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <FeedbackIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                Net Promoter Score
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {survey?.nps}
                            </Typography>
                        }
                    />
                </ListItem>
                {survey?.type === 'enrollment' && (
                    <>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <MailOutlineIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        How can we improve your experience?
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {survey?.improvement_comment}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </>
                )}
                {survey?.type === 'event' && (
                    <>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <LabelOutlinedIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Are the services provided by IEC clear?
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {survey?.services_clarity}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <LabelOutlinedIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        What services/value can IEC add?
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {survey?.services_value_to_add}
                                    </Typography>
                                }
                            />
                        </ListItem>

                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <LabelOutlinedIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        What did you like about the leadership
                                        training?
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {survey?.what_liked_on_training}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <LabelOutlinedIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        What did you dislike about the
                                        leadership training?
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {survey?.what_disliked_in_training}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <LabelOutlinedIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        How organized was the leadership
                                        training?
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {survey?.training_organization}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <LabelOutlinedIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        The trainer was knowledgeable about the
                                        training topics.
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {survey?.trainer_topics_knowledge}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <LabelOutlinedIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        The trainer was well prepared.
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {survey?.trainer_prepared}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <LabelOutlinedIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        The topics covered were relevant.
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {survey?.relevant_topics}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <LabelOutlinedIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Participation and interaction were
                                        encouraged.
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {
                                            survey?.participation_interaction_encouraged
                                        }
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <LabelOutlinedIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Time allocated for the training was
                                        sufficient.
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {survey?.time_sufficient}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <LabelOutlinedIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        How do you hope this training will
                                        impact students?
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {survey?.students_training_impact}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <LabelOutlinedIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        What aspects of training can be
                                        improved?
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {survey?.improvement_comment}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <LabelOutlinedIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        What more programs can we have on board
                                        that will impact students.
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {survey?.additional_programs}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <LabelOutlinedIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Is there anything else you’d like to
                                        share about the event?
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {survey?.additional_comments}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </>
                )}
            </List>
            <Stack spacing={1} direction={'row'} sx={{ px: 4, py: 2 }}></Stack>
        </Div>
    );
};

export default SurveyDetail;
