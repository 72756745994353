import jwtAuthAxios from './auth/jwtAuth';

const schoolsServices = {};

schoolsServices.getAllSchools = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/schools',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

schoolsServices.getSchools = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/schools', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

schoolsServices.getSchoolDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/schools/school/${id}`, {
        id: id
    });

    return data;
};

schoolsServices.addSchool = async (school) => {
    const { data } = await jwtAuthAxios.post(`/schools`, school);
    return data;
};

schoolsServices.uploadSchoolsCSV = async (csvData) => {
    const { data } = await jwtAuthAxios.post(`/schools/upload-csv`, csvData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return data;
};

schoolsServices.updateSchool = async (school) => {
    // console.log('Service School');
    // console.log(school);
    const { data } = await jwtAuthAxios.patch(`/schools/${school.id}`, school);
    return data;
};

schoolsServices.deleteSchool = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/schools/${id}`, {
        id: id
    });
    return data;
};

schoolsServices.deleteMultipleSchools = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/schools`, {
        data: ids
    });
    return data;
};

export { schoolsServices };
