import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Autocomplete, Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useMutation } from 'react-query';
import { userServices } from 'app/services/user-services';
import { roleServices } from 'app/services/role-services';
import { designationServices } from 'app/services/designation-services';
import { officeServices } from 'app/services/office-services';
import { countriesServices } from 'app/services/countries-services';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import TextField from '@mui/material/TextField';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboRadioGroup from '@jumbo/components/JumboFormik/JumboRadioGroup';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { capitalizeAllWords } from 'app/utils/appHelpers';

const validationSchema = yup.object({
    first_name: yup
        .string('Enter first name')
        .required('First name is required'),
    middle_name: yup
        .string('Enter middle name')
        .required('Middle name is required'),
    last_name: yup.string('Enter last name').required('Last name is required'),
    dob: yup
        .string('Enter date of birth')
        .required('Date of birth is required'),
    gender: yup.string('Select gender').required('Gender is required'),
    email_address: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    phone_number: yup
        .string('Enter phone number')
        // .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone number is required'),
    address: yup
        .string('Enter residential address')
        .required('City/Residential address is required'),
    university_attended: yup
        .string('Enter the university attended')
        .required('University/College attended is required'),
    id_no: yup
        .string('Enter Governement Identification number')
        .required('ID No. is required'),
    kra_pin: yup.string('Enter KRA Pin').required('KRA Pin is required'),
    roleId: yup.string('Select Role').required('Role is required'),
    designationId: yup
        .string('Select Designation')
        .required('Designation is required'),
    officeId: yup
        .string('Select designated working office')
        .required('Designated Office is required'),
    employee: yup
        .string('Select whether user is employee or not')
        .required('Employee status is required'),
    employment_date: yup
        .string('Enter employment date')
        .required('Employee date is required'),
    salary: yup
        .string('Enter employee salary')
        .required('Employee salary is required')
});

const EditForm = ({ listItem, authUser, onUpdate, hideDialog }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [employmentDate, setEmploymentDate] = React.useState(
        dayjs('2023-01-01')
    );
    const [showEmpDetails, setShowEmpDetails] = React.useState(
        listItem.employee
    );

    const [fieldDob, setDob] = React.useState(dayjs(listItem.dob));
    const [phone, setPhone] = React.useState(listItem.phone_number);
    const [role, setRole] = React.useState(
        listItem.roleId?.id ? listItem.roleId.id : ''
    );
    const [designatedOffice, setDesignatedOffice] = React.useState(
        listItem.officeId?.id
            ? listItem.officeId.id.length > 1
                ? 'All'
                : listItem.officeId[0].id
            : ''
    );
    const [designation, setDesignation] = React.useState(
        listItem.designationId?.id ? listItem.designationId.id : ''
    );

    const [roles, setRoles] = React.useState(false);
    const [designations, setDesignations] = React.useState(false);
    const [offices, setOffices] = React.useState(false);
    const [countries, setCountries] = React.useState(false);

    const { mutate: editUserMutation, isLoading } = useMutation(
        userServices.updateUser,
        {
            onSuccess: () => {
                onUpdate();

                enqueueSnackbar('User has been updated successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onEdit = async (user) => {
        try {
            editUserMutation({ id: listItem.id, ...user });
        } catch (error) {}
    };

    const employeeRadioChangeHandler = (event) => {
        if (event.target.value === 'yes') {
            setShowEmpDetails(true);
            setDesignatedOffice('none');
        } else {
            setShowEmpDetails(false);
            setDesignatedOffice('');
        }
    };

    React.useEffect(() => {
        const cancelTokenRoles = axios.CancelToken.source();
        const cTokenRoles = { cancelToken: cancelTokenRoles.token };
        const fetchRoles = async () => {
            try {
                const res = await roleServices.getAllRoles(cTokenRoles);
                setRoles(res.roles);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchRoles();

        return () => {
            cancelTokenRoles.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenDesignations = axios.CancelToken.source();
        const cTokenDesignations = {
            cancelToken: cancelTokenDesignations.token
        };
        const fetchDesignations = async () => {
            try {
                const res = await designationServices.getAllDesignations(
                    cTokenDesignations
                );
                setDesignations(res.designations);
            } catch (error) {
                console.log('--------> error');
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchDesignations();

        return () => {
            cancelTokenDesignations.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenOffices = axios.CancelToken.source();
        const cTokenOffices = { cancelToken: cancelTokenOffices.token };
        const fetchOffices = async () => {
            try {
                const res = await officeServices.getAllOffices(cTokenOffices);
                setOffices(res.offices);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchOffices();

        return () => {
            cancelTokenOffices.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenCountries = axios.CancelToken.source();
        const cTokenCountries = { cancelToken: cancelTokenCountries.token };
        const fetchCountries = async () => {
            try {
                const res = await countriesServices.getAllCountries(
                    cTokenCountries
                );

                setCountries(res.countries);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchCountries();

        return () => {
            cancelTokenCountries.cancel();
        };
    }, []);

    // console.log('listItem');
    // console.log(listItem);
    // console.log(designatedOffice);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {(!roles || !designations || !offices) && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {roles && designations && offices && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        first_name: listItem.first_name,
                        middle_name: listItem.middle_name,
                        last_name: listItem.last_name,
                        dob: fieldDob,
                        gender: listItem.gender.toLowerCase(),
                        email_address: listItem.email_address,
                        phone_number: phone,
                        address: listItem?.address ? listItem?.address : '',
                        university_attended: listItem?.university_attended
                            ? listItem?.university_attended
                            : '',
                        id_no: listItem.id_no,
                        kra_pin: listItem.kra_pin,
                        designationId: listItem.designationId?.id,
                        roleId: listItem.roleId?.id,
                        officeId: !listItem.employee
                            ? 'none'
                            : listItem?.officeId
                            ? listItem.officeId.length > 1
                                ? 'All'
                                : listItem.officeId[0].id
                            : '',
                        employee: !listItem.employee ? 'no' : 'yes',
                        employment_date: employmentDate,
                        salary: listItem.salary,
                        assignedTerritories: listItem?.assignedTerritories
                            ? listItem?.assignedTerritories
                            : []
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onEdit(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="first_name"
                                            name="first_name"
                                            label="First Name"
                                            placeholder="Enter first name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="middle_name"
                                            name="middle_name"
                                            label="Middle Name"
                                            placeholder="Enter middle name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="last_name"
                                            name="last_name"
                                            label="Last Name"
                                            placeholder="Enter last name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <MobileDatePicker
                                                label="Date Of Birth"
                                                inputFormat="DD/MM/YYYY"
                                                value={fieldDob}
                                                onChange={(newValue) => {
                                                    console.log(fieldDob);
                                                    setDob(newValue);
                                                    values.dob = newValue;
                                                    console.log(newValue);
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <JumboTextField
                                                            fullWidth
                                                            size={'small'}
                                                            value={fieldDob}
                                                            name="dob"
                                                            {...params}
                                                        />
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboRadioGroup
                                            row
                                            labelid="gender-row"
                                            label="Gender :"
                                            aria-labelledby="gender-row"
                                            name="gender"
                                        >
                                            <FormControlLabel
                                                value="male"
                                                control={<Radio size="small" />}
                                                label="Male"
                                            />
                                            <FormControlLabel
                                                value="female"
                                                control={<Radio size="small" />}
                                                label="Female"
                                            />
                                        </JumboRadioGroup>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="email_address"
                                            name="email_address"
                                            label="Email Address"
                                            placeholder="Enter email address"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MuiTelInput
                                            fullWidth
                                            size={'small'}
                                            id="phone_number"
                                            name="phone_number"
                                            label="Phone Number"
                                            value={phone}
                                            placeholder="Enter phone number"
                                            preferredCountries={['KE', 'ZM']}
                                            defaultCountry="KE"
                                            onChange={(newValue) => {
                                                console.log('phone', phone);
                                                setPhone(newValue);
                                                values.phone_number = newValue;

                                                console.log(
                                                    'newValue',
                                                    newValue
                                                );
                                            }}
                                        />
                                        {touched.phone_number &&
                                        errors.phone_number ? (
                                            <FormHelperText
                                                style={{
                                                    color: 'red'
                                                }}
                                            >
                                                {errors.phone_number}
                                            </FormHelperText>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="address"
                                            name="address"
                                            label="City/Residential address"
                                            placeholder="Enter residential address"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="university_attended"
                                            name="university_attended"
                                            label="University/College Attended"
                                            placeholder="Enter University/College name attended"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="id_no"
                                            name="id_no"
                                            label="ID No."
                                            placeholder="Enter ID Number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="kra_pin"
                                            name="kra_pin"
                                            label="KRA Pin"
                                            placeholder="Enter KRA Pin"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboSelectField
                                            width="100%"
                                            size={'small'}
                                            id="roleId"
                                            name="roleId"
                                            label="Role"
                                            value={role}
                                            placeholder="Select role"
                                            onChange={(event) =>
                                                setRole(event.target.value)
                                            }
                                        >
                                            <MenuItem key={'roleId'} value="">
                                                <em>Select Role</em>
                                            </MenuItem>
                                            {roles.map((role) => (
                                                <MenuItem
                                                    key={role.id}
                                                    value={role.id}
                                                >
                                                    {capitalizeAllWords(
                                                        role.role_name
                                                    )}
                                                </MenuItem>
                                            ))}
                                        </JumboSelectField>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboSelectField
                                            width="100%"
                                            size={'small'}
                                            id="designationId"
                                            name="designationId"
                                            label="Designation"
                                            value={designation}
                                            placeholder="Select designation"
                                            onChange={(event) =>
                                                setDesignation(
                                                    event.target.value
                                                )
                                            }
                                        >
                                            <MenuItem
                                                key={'designationId'}
                                                value=""
                                            >
                                                <em>
                                                    Select designation/title
                                                </em>
                                            </MenuItem>
                                            {designations.map((designation) => (
                                                <MenuItem
                                                    key={designation.id}
                                                    value={designation.id}
                                                >
                                                    {capitalizeAllWords(
                                                        designation.designation_name
                                                    )}
                                                </MenuItem>
                                            ))}
                                        </JumboSelectField>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboRadioGroup
                                            row
                                            labelid="employee-row"
                                            label="User is company Employee ?"
                                            aria-labelledby="employee-row"
                                            name="employee"
                                        >
                                            <FormControlLabel
                                                value="yes"
                                                control={<Radio size="small" />}
                                                label="Yes"
                                                onChange={
                                                    employeeRadioChangeHandler
                                                }
                                            />
                                            <FormControlLabel
                                                value="no"
                                                control={<Radio size="small" />}
                                                label="No"
                                                onChange={
                                                    employeeRadioChangeHandler
                                                }
                                            />
                                        </JumboRadioGroup>
                                    </Grid>
                                    {showEmpDetails && (
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <MobileDatePicker
                                                        label="Employment Date"
                                                        inputFormat="DD/MM/YYYY"
                                                        value={employmentDate}
                                                        onChange={(newValue) =>
                                                            setEmploymentDate(
                                                                newValue
                                                            )
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <JumboTextField
                                                                fullWidth
                                                                size={'small'}
                                                                id="employment_date"
                                                                name="employment_date"
                                                                value={
                                                                    employmentDate
                                                                }
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <JumboSelectField
                                                    width="100%"
                                                    size={'small'}
                                                    id="officeId"
                                                    name="officeId"
                                                    label="Designated Office"
                                                    value={designatedOffice}
                                                    placeholder="Select designated office"
                                                    onChange={(event) =>
                                                        setDesignatedOffice(
                                                            event.target.value
                                                        )
                                                    }
                                                >
                                                    <MenuItem
                                                        key={'officeId'}
                                                        value=""
                                                    >
                                                        <em>
                                                            Select designated
                                                            Office
                                                        </em>
                                                    </MenuItem>
                                                    {!showEmpDetails && (
                                                        <MenuItem
                                                            key={'officeIdn'}
                                                            value="none"
                                                        >
                                                            <em>
                                                                Select
                                                                designated
                                                                Office
                                                            </em>
                                                        </MenuItem>
                                                    )}
                                                    <MenuItem
                                                        key={'all'}
                                                        value="All"
                                                    >
                                                        Assign To All Offices
                                                    </MenuItem>

                                                    {offices.map((office) => (
                                                        <MenuItem
                                                            key={office.id}
                                                            value={office.id}
                                                        >
                                                            {capitalizeAllWords(
                                                                office.office_name
                                                            )}
                                                        </MenuItem>
                                                    ))}
                                                </JumboSelectField>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <JumboTextField
                                                    fullWidth
                                                    size={'small'}
                                                    id="salary"
                                                    name="salary"
                                                    label="Salary"
                                                    type="number"
                                                    min="0"
                                                    step="any"
                                                    placeholder="Enter employee salary"
                                                />
                                            </Grid>
                                        </>
                                    )}

                                    {!countries && (
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            width={'100%'}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Box>
                                    )}
                                    {countries &&
                                        (authUser.role_name === 'regional' ||
                                            authUser.role_name ===
                                                'administrator') && (
                                            <Grid item xs={12} md={12}>
                                                <Field name="assignedTerritories">
                                                    {({ field }) => (
                                                        <Autocomplete
                                                            multiple
                                                            {...field}
                                                            id="assignedTerritories"
                                                            sx={{
                                                                width: '100%',
                                                                maxWidth: '100%'
                                                            }}
                                                            size="small"
                                                            options={countries}
                                                            autoHighlight
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option?.country_name
                                                                    ? option.country_name
                                                                    : ''
                                                            }
                                                            isOptionEqualToValue={(
                                                                option,
                                                                value
                                                            ) =>
                                                                option.id ===
                                                                value.id
                                                            }
                                                            renderOption={(
                                                                props,
                                                                option
                                                            ) => (
                                                                <Box
                                                                    component="li"
                                                                    sx={{
                                                                        '& > img':
                                                                            {
                                                                                mr: 2,
                                                                                flexShrink: 0
                                                                            }
                                                                    }}
                                                                    {...props}
                                                                >
                                                                    <img
                                                                        loading="lazy"
                                                                        width="20"
                                                                        src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                                                                        srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                                                                        alt=""
                                                                    />
                                                                    {
                                                                        option?.country_name
                                                                    }
                                                                </Box>
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Assigned Territories"
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        autoComplete:
                                                                            'new-password'
                                                                    }}
                                                                    error={
                                                                        touched?.assignedTerritories &&
                                                                        !!errors.assignedTerritories
                                                                    }
                                                                    helperText={
                                                                        touched?.assignedTerritories &&
                                                                        errors.assignedTerritories
                                                                    }
                                                                />
                                                            )}
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) =>
                                                                field.onChange({
                                                                    target: {
                                                                        name: field.name,
                                                                        value: newValue
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                            </Grid>
                                        )}
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                        disabled={!!errors.length}
                                    >
                                        Update
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo userItem, onSave prop define */
export default EditForm;
