import jwtAuthAxios from './auth/jwtAuth';

const emailService = {};

emailService.send = async (email) => {
    const { data } = await jwtAuthAxios.post('/emails/send', email);
    return data;
};

emailService.sendSurveyInvitations = async (email) => {
    // console.log('entity');
    // console.log(email);
    // return;
    const { data } = await jwtAuthAxios.post(
        '/emails/send-surveys-invitations',
        email
    );

    return data;
};

export { emailService };
