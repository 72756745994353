import React from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { officeServices } from 'app/services/office-services';
import _ from 'lodash';
import axios from 'axios';

const OfficeFilter = ({
    field = {},
    filterOffices = false,
    updateState,
    selectedOfficeId
}) => {
    const [offices, setOffices] = React.useState([]);
    const [selectedOffice, setSelectedOffice] = React.useState(null);

    React.useEffect(() => {
        let isMounted = true;

        const cancelTokenOffices = axios.CancelToken.source();
        const cTokenOffices = { cancelToken: cancelTokenOffices.token };

        const fetchOffices = async () => {
            try {
                const res = await officeServices.getAllOffices(cTokenOffices);
                const offices = filterOffices
                    ? _.filter(
                          _.map(res.offices, (office) => ({
                              id: office.id,
                              office_name: office.office_name
                          })),
                          (office) => filterOffices.includes(office?.id)
                      )
                    : _.map(res.offices, (office) => ({
                          id: office.id,
                          office_name: office.office_name
                      }));

                if (isMounted) {
                    setOffices(offices);

                    // Set the initial selected office based on selectedOfficeId
                    const initialSelectedOffice = offices.find(
                        (office) => office.id === selectedOfficeId
                    );

                    setSelectedOffice(initialSelectedOffice || null);
                }
            } catch (error) {
                console.log(error);
                // Handle error, e.g., show a snackbar
            }
        };

        fetchOffices();

        return () => {
            isMounted = false;
            cancelTokenOffices.cancel();
        };
    }, [filterOffices, selectedOfficeId]);

    return (
        <Autocomplete
            {...field}
            id="officeId"
            sx={{
                width: '100%'
            }}
            size="small"
            options={offices}
            autoHighlight
            getOptionLabel={(option) =>
                option?.office_name ? option.office_name : ''
            }
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            renderOption={(props, option) => (
                <Box
                    component="li"
                    sx={{
                        '& > img': {
                            mr: 2,
                            flexShrink: 0
                        }
                    }}
                    {...props}
                >
                    {option.office_name}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select  Office"
                    placeholder="Choose office"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password'
                    }}
                />
            )}
            onChange={(event, newValue) => {
                setSelectedOffice(newValue);
                updateState(newValue?.id || null);
            }}
            value={selectedOffice}
        />
    );
};

export default OfficeFilter;
