import React from 'react';

import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { useMutation } from 'react-query';
import { roleServices } from '../../../../../../services/role-services';

import * as yup from 'yup';
import _ from 'lodash';
import { Form, Formik } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboRadioGroup from '@jumbo/components/JumboFormik/JumboRadioGroup';
import { useSnackbar } from 'notistack';
import PermissionsComponent from '../PermissionsComponent';

import defaultAbility from 'app/config/defaultAbility';

const validationSchema = yup.object({
    role_name: yup.string('Enter role name').required('Role name is required')
    // role_description: yup
    //     .string('Enter role description name')
    //     .required('Role description is required')
});

const EditForm = ({ listItem, onUpdate, hideDialog }) => {
    const allPermissions = _.map(defaultAbility, (ability) => ({
        ...ability,
        status: false
    }));

    const assignedPermissions =
        listItem?.permissions && listItem.permissions.length
            ? listItem?.permissions
            : [];

    let mergedPermissions = allPermissions;

    if (assignedPermissions.length > 0) {
        const mergedAbilities = _.mergeWith(
            _.groupBy(
                allPermissions,
                ({ action, subject }) => `${action}:${subject}`
            ),
            _.groupBy(
                assignedPermissions,
                ({ action, subject }) => `${action}:${subject}`
            ),
            (array1, array2) =>
                _.map(array1, (item1) =>
                    _.mergeWith(
                        item1,
                        _.find(array2, (item2) =>
                            _.isEqual(
                                _.pick(item1, ['action', 'subject']),
                                _.pick(item2, ['action', 'subject'])
                            )
                        ),
                        (value, srcValue, key) =>
                            key === 'status' ? srcValue : undefined
                    )
                )
        );

        // Flatten the mergedAbilities object back into an array
        mergedPermissions = _.flatten(Object.values(mergedAbilities));
    }

    const [permissions, setPermissions] = React.useState(mergedPermissions);
    const [checked, setChecked] = React.useState(
        _.filter(permissions, (permission) => permission.status === true)
    );

    const { enqueueSnackbar } = useSnackbar();

    const { mutate: editRoleMutation, isLoading } = useMutation(
        roleServices.updateRole,
        {
            onSuccess: () => {
                onUpdate();

                enqueueSnackbar('Role has been updated successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onEdit = async (role) => {
        try {
            editRoleMutation({
                id: listItem.id,
                ...role,
                permissions: checked
            });
        } catch (error) {}
    };

    React.useEffect(() => {
        setChecked(
            _.filter(permissions, (permission) => permission.status === true)
        );
    }, [permissions]);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            <Formik
                validateOnChange={true}
                initialValues={{
                    role_name: listItem.role_name,
                    role_description: listItem.role_description
                }}
                validationSchema={validationSchema}
                onSubmit={async (data, { setSubmitting }) => {
                    await onEdit(data);
                }}
            >
                {({ isSubmitting, errors, touched, values, ...restProps }) => (
                    <Form
                        style={{ textAlign: 'left' }}
                        noValidate
                        autoComplete="off"
                    >
                        <Div>{isLoading}</Div>
                        <Div sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="role_name"
                                        name="role_name"
                                        label="Role Name"
                                        placeholder="Enter role name"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="role_description"
                                        name="role_description"
                                        label="Role Description"
                                        placeholder="Enter role description"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <PermissionsComponent
                                        permissions={permissions}
                                        setPermissions={setPermissions}
                                        checked={checked}
                                        setChecked={setChecked}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    margin: '30px auto'
                                }}
                                container
                                alignItems="right"
                                justify="flex-end"
                                justifyContent="right"
                            >
                                <Button
                                    size="large"
                                    sx={{ mb: 3 }}
                                    onClick={() => hideDialog()}
                                >
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isLoading}
                                >
                                    Update
                                </LoadingButton>
                            </Grid>
                        </Div>
                    </Form>
                )}
            </Formik>
        </Div>
    );
};
/* Todo roleItem, onSave prop define */
export default EditForm;
