import React from 'react';
import ActivitiesList from './ActivitiesList';
import { Chip } from '@mui/material';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import { useTranslation } from 'react-i18next';
import JumboScrollbar from '@jumbo/components/JumboScrollbar';
import _ from 'lodash';
import RequestStatus from 'app/pages/statuses/RequestStatus';

const RecentActivities = ({ request, scrollHeight, authUser }) => {
    const { t } = useTranslation();

    const [requestStatus, setRequestStatus] = React.useState(
        _.find(RequestStatus, (status) => status.id === request?.status)
    );

    return (
        <JumboCardQuick
            title={t('widgets.title.recentActivities')}
            action={
                <Chip
                    color={requestStatus.type}
                    size={'small'}
                    label={requestStatus.status}
                />
            }
            wrapperSx={{ px: 0 }}
        >
            <JumboScrollbar
                autoHeight
                autoHeightMin={scrollHeight ? scrollHeight : 200}
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
            >
                <ActivitiesList request={request} authUser={authUser} />
            </JumboScrollbar>
        </JumboCardQuick>
    );
};
/* Todo scrollHeight prop define */
export default RecentActivities;
