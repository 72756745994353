import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
    alpha,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Chip,
    CircularProgress,
    Alert
} from '@mui/material';

import NumbersIcon from '@mui/icons-material/Numbers';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'; //Employees
import DescriptionIcon from '@mui/icons-material/Description';
import Span from '@jumbo/shared/Span';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import Div from '@jumbo/shared/Div';
import moment from 'moment';
import _ from 'lodash';
import { capitalizeAllWords, formatNumber } from 'app/utils/appHelpers';
import AdvanceStatus from 'app/pages/statuses/AdvanceStatus';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from 'react-query';
import { advancesServices } from 'app/services/advances-services';
import { useSnackbar } from 'notistack';
import MySwal from 'app/hooks/MySwal/MySwal';

const AdvanceDetail = ({
    advance,
    authUser,
    onSave = () => {},
    onClose = () => {}
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const { REACT_APP_BACKEND } = process.env;
    const profilePic = advance?.employeeId?.profile_pic
        ? process.env.REACT_APP_BACKEND + '/' + advance?.employeeId?.profile_pic
        : null;

    const [advanceStatus, setAdvanceStatus] = React.useState([
        {
            id: 0,
            name: 'Inactive',
            slug: 'inactive',
            color: '#ca404f'
        }
    ]);

    const { mutate: cancelAdvanceMutation, isLoading: isCancelling } =
        useMutation(advancesServices.cancelAdvance, {
            onSuccess: () => {
                enqueueSnackbar(
                    'Advance status has been updated successfully.',
                    {
                        variant: 'success'
                    }
                );
                onSave();
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(
                    error?.message ? error.message : error.toString(),
                    {
                        variant: 'error'
                    }
                );

                return false;
            }
        });

    const onCancel = async (advanceId) => {
        try {
            cancelAdvanceMutation({
                advanceId
            });
        } catch (error) {}
    };

    const confirmCancel = (id) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Cancel it!',
            cancelButtonText: 'No, Keep it!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                onCancel(id);
            } else {
                MySwal.fire(
                    'We keeping it',
                    'Your advance is still active :)',
                    'info'
                );
            }
        });
    };

    React.useEffect(() => {
        setAdvanceStatus(
            _.find(AdvanceStatus, (status) => status.id === advance?.status)
        );
    }, [advance]);

    return (
        <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
            <CardHeader
                title={`${advance?.advance_name}`}
                subheader={
                    <Div
                        sx={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography
                            variant={'body1'}
                            color={'text.secondary'}
                            mr={0.5}
                        >
                            {`${advance?.employeeId?.first_name} ${advance?.employeeId?.last_name}`}
                        </Typography>
                        <JumboChipsGroup
                            chips={
                                advance
                                    ? [advanceStatus]
                                    : [
                                          {
                                              id: 0,
                                              name: 'Inactive',
                                              slug: 'inactive',
                                              color: '#ca404f'
                                          }
                                      ]
                            }
                            mapKeys={{ label: 'name' }}
                            spacing={1}
                            size={'small'}
                            max={1}
                        />
                    </Div>
                }
                avatar={<Avatar src={profilePic} />}
                action={
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List disablePadding>
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <NumbersIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Advance ID #
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {`IEC-${String(
                                            advance?.advance_id
                                        ).padStart(8, '0')}`}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <AccountBalanceWalletIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Related Expense
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {`# IEC-${advance?.expenseId?.expense_id} : ${advance?.expenseId?.expense_name}`}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />

                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <MonetizationOnIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Amount Requested
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {formatNumber(advance?.total_amount)}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        {advance?.issue_transaction_id && (
                            <>
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <MonetizationOnIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Disbursment Transaction #
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h5'} mb={0}>
                                                {advance?.issue_transaction_id}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <MonetizationOnIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Amount Disbursed
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h5'} mb={0}>
                                                {formatNumber(
                                                    advance?.advance_amount_issued
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <EventAvailableIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Date Issued
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h5'} mb={0}>
                                                {moment(
                                                    advance?.date_advance_issued
                                                ).format('DD MMM YYYY')}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <SupervisedUserCircleIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Issuer
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h5'} mb={0}>
                                                {`${advance?.issuer?.first_name} ${advance?.issuer?.last_name}`}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                            </>
                        )}
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <DescriptionIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Purpose
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {advance?.purpose}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                    </List>
                </Grid>
            </Grid>
        </Div>
    );
};

export default AdvanceDetail;
