import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const eventsServices = {};

eventsServices.getAllEvents = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/events',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

eventsServices.getEvents = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/events', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

eventsServices.getEventDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/eventss/event/${id}`, {
        id: id
    });

    return data;
};

eventsServices.addEvent = async (events) => {
    const { data } = await jwtAuthAxios.post(`/events`, events);
    return data;
};

eventsServices.updateEvent = async (events) => {
    // console.log('Service Event');
    // console.log(events);
    const { data } = await jwtAuthAxios.patch(`/events/${events.id}`, events);
    return data;
};

eventsServices.cancelEvent = async (id) => {
    const { data } = await jwtAuthAxios.patch(`/events/cancel/${id}`, {
        id: id
    });
    return data;
};

eventsServices.deleteEvent = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/events/${id}`, {
        id: id
    });
    return data;
};

eventsServices.deleteMultipleEvents = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/events`, {
        data: ids
    });
    return data;
};

export { eventsServices };
