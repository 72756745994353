import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
    alpha,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Chip,
    CircularProgress,
    Alert
} from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InfoIcon from '@mui/icons-material/Info';
import NumbersIcon from '@mui/icons-material/Numbers';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import HotelIcon from '@mui/icons-material/Hotel';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import DescriptionIcon from '@mui/icons-material/Description';
import Span from '@jumbo/shared/Span';
import Grid from '@mui/material/Grid';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Divider from '@mui/material/Divider';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import List from '@mui/material/List';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Stack from '@mui/material/Stack';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import Div from '@jumbo/shared/Div';
import moment from 'moment';
import _ from 'lodash';
import { capitalizeAllWords, formatNumber } from 'app/utils/appHelpers';
import ExpenseStatus from 'app/pages/statuses/ExpenseStatus';
// import RecentActivities from './RecentActivities';
// import ApprovalForm from './ApprovalForm';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from 'react-query';
import { expensesServices } from 'app/services/expenses-services';
import { useSnackbar } from 'notistack';
import MySwal from 'app/hooks/MySwal/MySwal';

const ExpenseDetail = ({
    expense,
    authUser,
    onSave = () => {},
    onClose = () => {}
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const { REACT_APP_BACKEND } = process.env;
    const profilePic = expense?.employeeId?.profile_pic
        ? process.env.REACT_APP_BACKEND + '/' + expense?.employeeId?.profile_pic
        : null;

    const { mutate: cancelExpenseMutation, isLoading: isCancelling } =
        useMutation(expensesServices.cancelExpense, {
            onSuccess: () => {
                enqueueSnackbar(
                    'Expense status has been updated successfully.',
                    {
                        variant: 'success'
                    }
                );
                onSave();
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(
                    error?.message ? error.message : error.toString(),
                    {
                        variant: 'error'
                    }
                );

                return false;
            }
        });

    const onCancel = async (expenseId) => {
        try {
            cancelExpenseMutation({
                expenseId
            });
        } catch (error) {}
    };

    const confirmCancel = (id) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Cancel it!',
            cancelButtonText: 'No, Keep it!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                onCancel(id);
            } else {
                MySwal.fire(
                    'We keeping it',
                    'Your expense is still active :)',
                    'info'
                );
            }
        });
    };

    return (
        <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
            <CardHeader
                title={`${expense?.expense_name} ${
                    expense?.module === 'travel'
                        ? ` (${moment(expense?.travel?.departure_date).format(
                              'DD MMM YYYY'
                          )} -
                          ${moment(expense?.travel?.arrival_date).format(
                              'DD MMM YYYY'
                          )})`
                        : ''
                }`}
                subheader={
                    <Div
                        sx={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography
                            variant={'body1'}
                            color={'text.secondary'}
                            mr={0.5}
                        >
                            {`${expense?.employeeId?.first_name} ${expense?.employeeId?.last_name}`}
                        </Typography>
                        {/* <JumboChipsGroup
                            chips={
                                expense
                                    ? [expenseStatus]
                                    : [
                                          {
                                              id: 0,
                                              name: 'Inactive',
                                              slug: 'inactive',
                                              color: '#ca404f'
                                          }
                                      ]
                            }
                            mapKeys={{ label: 'name' }}
                            spacing={1}
                            size={'small'}
                            max={1}
                        /> */}
                    </Div>
                }
                avatar={<Avatar src={profilePic} />}
                action={
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List disablePadding>
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <NumbersIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Expense ID #
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {`IEC-${String(
                                            expense?.expense_id
                                        ).padStart(8, '0')}`}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        {expense?.module === 'travel' && (
                            <>
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <LocalAirportIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>

                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Trip Details
                                            </Typography>
                                        }
                                        secondary={
                                            <Div
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <Div
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        alignItems: 'middle'
                                                    }}
                                                >
                                                    <Typography
                                                        variant="h5"
                                                        mb={0.5}
                                                    >
                                                        {capitalizeAllWords(
                                                            expense?.travel
                                                                ?.travel_type
                                                        )}{' '}
                                                        {capitalizeAllWords(
                                                            expense?.travel
                                                                ?.trip_type
                                                        )}{' '}
                                                        From{' '}
                                                        <Span
                                                            sx={{
                                                                color: 'primary.main'
                                                            }}
                                                        >
                                                            {
                                                                expense?.travel
                                                                    ?.origin
                                                            }
                                                        </Span>{' '}
                                                        {expense?.travel
                                                            ?.destination ? (
                                                            <>
                                                                {'to '}
                                                                <Span
                                                                    sx={{
                                                                        color: 'primary.main'
                                                                    }}
                                                                >
                                                                    {
                                                                        expense
                                                                            ?.travel
                                                                            ?.destination
                                                                    }
                                                                </Span>
                                                            </>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Typography>
                                                    {/* <Typography
                                                        variant={'h5'}
                                                        sx={{
                                                            textAlign: 'left',
                                                            marginX: '16px'
                                                        }}
                                                    >
                                                        {capitalizeAllWords(
                                                            expense?.travel
                                                                ?.travel_type
                                                        )}{' '}
                                                        {capitalizeAllWords(
                                                            expense?.travel
                                                                ?.trip_type
                                                        )}{' '}
                                                        From
                                                    </Typography>
                                                    <Chip
                                                        label={
                                                            expense?.travel
                                                                ?.origin
                                                        }
                                                        color="info"
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                    <Typography
                                                        variant={'h5'}
                                                        sx={{
                                                            textAlign: 'left',
                                                            marginX: '16px'
                                                        }}
                                                    >
                                                        To
                                                    </Typography>
                                                    <Chip
                                                        label={
                                                            expense?.travel
                                                                ?.destination
                                                        }
                                                        color="info"
                                                        variant="outlined"
                                                        size="small"
                                                    /> */}
                                                </Div>
                                            </Div>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <MonetizationOnIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Fare Amount
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h5'} mb={0}>
                                                {formatNumber(
                                                    expense?.travel?.fare_amount
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <HotelIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Accomodation (Hotel Charges)
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h5'} mb={0}>
                                                {formatNumber(
                                                    expense?.travel
                                                        ?.accomodation_fees
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <LocalTaxiIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Taxi Charges
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h5'} mb={0}>
                                                {formatNumber(
                                                    expense?.travel?.taxi_fees
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <MailOutlineIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Per Diem
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h5'} mb={0}>
                                                {formatNumber(
                                                    expense?.travel?.per_diem
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <MoreHorizIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Miscellaneous
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h5'} mb={0}>
                                                {formatNumber(
                                                    expense?.travel
                                                        ?.miscellaneous
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                            </>
                        )}
                        {expense?.module === 'purchase' &&
                            expense?.purchase &&
                            expense?.purchase?.length > 0 &&
                            _.map(expense?.purchase, (purchase) => (
                                <>
                                    <ListItem sx={{ px: 4 }}>
                                        <ListItemAvatar sx={{ minWidth: 66 }}>
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    height: 48,
                                                    width: 48,
                                                    bgcolor: (theme) =>
                                                        alpha(
                                                            theme.palette
                                                                .primary.main,
                                                            0.15
                                                        )
                                                }}
                                            >
                                                <AttachMoneyIcon
                                                    sx={{
                                                        color: 'primary.light'
                                                    }}
                                                />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant={'body1'}
                                                    color={'text.secondary'}
                                                    mb={0.5}
                                                >
                                                    Expense Type
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    variant={'h5'}
                                                    mb={0}
                                                >
                                                    {
                                                        purchase?.expenseType
                                                            ?.expense_type_name
                                                    }
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Divider component={'li'} />
                                    <ListItem sx={{ px: 4 }}>
                                        <ListItemAvatar sx={{ minWidth: 66 }}>
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    height: 48,
                                                    width: 48,
                                                    bgcolor: (theme) =>
                                                        alpha(
                                                            theme.palette
                                                                .primary.main,
                                                            0.15
                                                        )
                                                }}
                                            >
                                                <InfoIcon
                                                    sx={{
                                                        color: 'primary.light'
                                                    }}
                                                />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant={'body1'}
                                                    color={'text.secondary'}
                                                    mb={0.5}
                                                >
                                                    Details
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    variant={'h5'}
                                                    mb={0}
                                                >
                                                    {purchase?.item_name}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Divider component={'li'} />

                                    <ListItem sx={{ px: 4 }}>
                                        <ListItemAvatar sx={{ minWidth: 66 }}>
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    height: 48,
                                                    width: 48,
                                                    bgcolor: (theme) =>
                                                        alpha(
                                                            theme.palette
                                                                .primary.main,
                                                            0.15
                                                        )
                                                }}
                                            >
                                                <MonetizationOnIcon
                                                    sx={{
                                                        color: 'primary.light'
                                                    }}
                                                />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant={'body1'}
                                                    color={'text.secondary'}
                                                    mb={0.5}
                                                >
                                                    Total Amount expenseed
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    variant={'h5'}
                                                    mb={0}
                                                >
                                                    {formatNumber(
                                                        expense?.total_amount
                                                    )}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Divider component={'li'} />
                                </>
                            ))}
                        {expense?.module === 'advance' && (
                            <>
                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <MonetizationOnIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Related Expense
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h5'} mb={0}>
                                                {
                                                    expense?.advance?.expenseId
                                                        ?.expense_name
                                                }
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />

                                <ListItem sx={{ px: 4 }}>
                                    <ListItemAvatar sx={{ minWidth: 66 }}>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                height: 48,
                                                width: 48,
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.15
                                                    )
                                            }}
                                        >
                                            <MonetizationOnIcon
                                                sx={{ color: 'primary.light' }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'body1'}
                                                color={'text.secondary'}
                                                mb={0.5}
                                            >
                                                Total Amount
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h5'} mb={0}>
                                                {formatNumber(
                                                    expense?.total_amount
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component={'li'} />
                            </>
                        )}
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <DescriptionIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Purpose
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {expense?.purpose}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                    </List>
                </Grid>
            </Grid>
        </Div>
    );
};

export default ExpenseDetail;
