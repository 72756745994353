import { ASSET_IMAGES } from '../../../../../../utils/constants/paths';
import { getAssetPath } from '../../../../../../utils/appHelpers';

export const goals = [
    {
        id: 1,
        profile_Pic: getAssetPath(`${ASSET_IMAGES}/event1.jpg`, '420x380'),
        title: 'Sundance Film Festival',
        details: '',
        category: 'Compliance',
        feedback: '',
        rating: '',
        due_date: 'Feb 23, 2020'
    },
    {
        id: 2,
        profile_Pic: getAssetPath(`${ASSET_IMAGES}/event2.jpg`, '420x380'),
        title: 'Do more than 50 hours of learning',
        details: '',
        category: 'Career Development',
        feedback: '',
        rating: '',
        due_date: 'Feb 11, 2020'
    },
    {
        id: 3,
        profile_Pic: getAssetPath(`${ASSET_IMAGES}/event3.jpg`, '420x380'),
        title: 'Develop 20 leads every quarter',
        details: '',
        category: 'Business Results',
        feedback: '',
        rating: '',
        due_date: 'Jan 02, 2020'
    }
];
