import React from 'react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { useMutation } from 'react-query';
import { performanceServices } from '../../../../../../services/performance-services';
import usePerformancesApp from '../../hooks/usePerformanceApp';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { useSnackbar } from 'notistack';
import { Tooltip } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import _ from 'lodash';

const BulkActions = () => {
    const { selectedList, setListRefresh } = usePerformancesApp();
    const { showDialog, hideDialog } = useJumboDialog();
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: removePerformances, isLoading } = useMutation(
        performanceServices.deleteMultiplePerformances,
        {
            onSuccess: () => {
                hideDialog();
                setListRefresh(true);
                enqueueSnackbar(
                    'KPI data for the selected employee(s) has been deleted successfully.',
                    {
                        variant: 'success'
                    }
                );
            }
        }
    );

    const handleBulkDelete = React.useCallback(() => {
        // enqueueSnackbar('Deletion has been disabled for this view', {
        //     variant: 'warning'
        // });

        // return;

        showDialog({
            variant: 'confirm',
            title: 'Delete!',
            content:
                'This action cannot be undone. Are you sure you want to proceed?  Select Yes to Continue',
            onYes: () => {
                // console.log('selectedList');
                // console.log(selectedList);
                removePerformances(_.map(selectedList, (item) => item._id));
            },
            onNo: hideDialog
        });
    }, [selectedList, removePerformances, hideDialog]);

    return (
        <Stack
            direction={'row'}
            sx={{ backgroundColor: 'transparent', ml: -2 }}
        >
            <Tooltip title={'Delete'}>
                <IconButton onClick={handleBulkDelete}>
                    <DeleteOutlineIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};

export default BulkActions;
