import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useMutation } from 'react-query';
import { uniapplicationsServices } from 'app/services/uniapplications-services';
import { countriesServices } from 'app/services/countries-services';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import axios from 'axios';

const validationSchema = yup.object({
    uniapplication_name: yup
        .string('Enter uniapplication name')
        .required('Uniapplication name is required'),
    phone_number: yup
        .string('Enter uniapplication the uniapplication contact phone number')
        .required('Phone number is required'),
    email_address: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    // tel: yup
    //     .string('Enter uniapplication telephone number')
    //     .required('Telephone number is required'),
    address: yup
        .string('Enter the uniapplication address')
        .required('Uniapplication address is required'),
    countryId: yup.string('Select country').required('Country is required'),
    city_name: yup
        .string('Enter the uniapplication location city')
        .required('City is required')
});

const EditForm = ({ listItem, onUpdate, hideDialog, userDetails }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [countries, setCountries] = React.useState(false);
    const [countryId, setCountry] = React.useState('');
    const [phone, setPhone] = React.useState(listItem?.phone_number);
    const [telNo, setTelNo] = React.useState(listItem?.tel);

    const { mutate: editUniapplicationMutation, isLoading } = useMutation(
        uniapplicationsServices.updateUniapplication,
        {
            onSuccess: () => {
                onUpdate();

                enqueueSnackbar(
                    'Uniapplication has been updated successfully.',
                    {
                        variant: 'success'
                    }
                );
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onEdit = async (uniapplication) => {
        try {
            editUniapplicationMutation({ id: listItem.id, ...uniapplication });
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };
        const fetchCountries = async () => {
            try {
                const res = await countriesServices.getAllCountries(cToken);

                setCountries(res.countries);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchCountries();

        return () => {
            cancelToken.cancel();
        };
    }, []);

    React.useEffect(() => {
        if (countries) {
            setCountry(listItem?.countryId?.id);
        }
    }, [countries, setCountry]);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {!countries && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}
            {countries && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        uniapplication_name: listItem.uniapplication_name,
                        phone_number: listItem.phone_number,
                        email_address: listItem.email_address,
                        tel: listItem.tel,
                        address: listItem.address,
                        countryId: listItem.countryId.id,
                        city_name: listItem.city_name
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onEdit(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="uniapplication_name"
                                            name="uniapplication_name"
                                            label="Uniapplication Name"
                                            placeholder="Enter Uniapplication Name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Field name="countryId">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    id="countryId"
                                                    sx={{
                                                        width: 500,
                                                        maxWidth: '100%'
                                                    }}
                                                    size="small"
                                                    options={countries}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.country_name
                                                            ? option.country_name
                                                            : ''
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                '& > img': {
                                                                    mr: 2,
                                                                    flexShrink: 0
                                                                }
                                                            }}
                                                            {...props}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                                                                alt=""
                                                            />
                                                            {
                                                                option.country_name
                                                            }
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Country"
                                                            placeholder="Choose a country"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.countryId &&
                                                                !!errors.countryId
                                                            }
                                                            helperText={
                                                                touched?.countryId &&
                                                                errors.countryId
                                                            }
                                                        />
                                                    )}
                                                    onChange={
                                                        (event, newValue) => {
                                                            values.countryId =
                                                                newValue?.id;
                                                        }
                                                        // Set the new value in the form field
                                                    }
                                                    value={
                                                        countries.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.countryId
                                                        ) || ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="city_name"
                                            name="city_name"
                                            label="Enter City"
                                            placeholder="Type the uniapplication location city"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <MuiTelInput
                                            fullWidth
                                            size={'small'}
                                            id="phone_number"
                                            name="phone_number"
                                            label="Phone Number"
                                            value={phone}
                                            placeholder="Enter phone number"
                                            preferredCountries={['KE', 'ZM']}
                                            defaultCountry="KE"
                                            onChange={(newValue) => {
                                                setPhone(newValue);
                                                values.phone_number = newValue;
                                            }}
                                        />
                                        {touched.phone_number &&
                                        errors.phone_number ? (
                                            <FormHelperText
                                                style={{
                                                    color: 'red'
                                                }}
                                            >
                                                {errors.phone_number}
                                            </FormHelperText>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <MuiTelInput
                                            fullWidth
                                            size={'small'}
                                            id="tel"
                                            name="tel"
                                            label="Telephone Number"
                                            value={telNo}
                                            placeholder="Enter Telephone number"
                                            preferredCountries={['KE', 'ZM']}
                                            defaultCountry="KE"
                                            onChange={(newValue) => {
                                                setTelNo(newValue);
                                                values.tel = newValue;
                                            }}
                                        />
                                        {touched.tel && errors.tel ? (
                                            <FormHelperText
                                                style={{
                                                    color: 'red'
                                                }}
                                            >
                                                {errors.tel}
                                            </FormHelperText>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="email_address"
                                            name="email_address"
                                            label="Email Address"
                                            placeholder="Enter Uniapplication Name"
                                            type="email"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="address"
                                            name="address"
                                            label="Physical Address"
                                            placeholder="Enter the physical address here"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                    >
                                        Update
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo uniapplicationItem, onSave prop define */
export default EditForm;
