import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const designationServices = {};

designationServices.getAllDesignations = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/designations',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

designationServices.getDesignations = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/designations', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

designationServices.getDesignationDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/designations/designation/${id}`, {
        id: id
    });

    return data;
};

designationServices.addDesignation = async (designation) => {
    const { data } = await jwtAuthAxios.post(`/designations`, designation);
    return data;
};

designationServices.updateDesignation = async (designation) => {
    // console.log('Service Designation');
    // console.log(designation);
    const { data } = await jwtAuthAxios.patch(
        `/designations/${designation.id}`,
        designation
    );
    return data;
};

designationServices.deleteDesignation = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/designations/${id}`, {
        id: id
    });
    return data;
};

designationServices.deleteMultipleDesignations = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/designations`, {
        data: ids
    });
    return data;
};

export { designationServices };
