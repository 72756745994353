import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import CardHeader from '@mui/material/CardHeader';
import { Grid } from '@mui/material';
// import Div from '@jumbo/shared/Div';
import moment from 'moment';
import {
    alpha,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Div from '@jumbo/shared/Div';

import { Chip } from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';

import { useMutation } from 'react-query';
import { timeoffsServices } from 'app/services/timeoffs-services';
import { leavetypesServices } from 'app/services/leavetypes-services';
import { useSnackbar } from 'notistack';
import MySwal from 'app/hooks/MySwal/MySwal';
import { useTheme } from '@mui/material/styles';

const Detail = ({
    item,
    onClose,
    refreshEvents = () => {},
    hideDialog,
    loggedInUser
}) => {
    const theme = useTheme(); // Access MUI theme
    const { enqueueSnackbar } = useSnackbar();
    // console.log('item');
    // console.log(item);

    const { mutate: approveTimeoffMutation, isLoading: isApproving } =
        useMutation(timeoffsServices.approveTimeoff, {
            onSuccess: () => {
                // onSave();
                enqueueSnackbar(
                    'Leave request has been approved successfully.',
                    {
                        variant: 'success'
                    }
                );

                refreshEvents();
                hideDialog();
                return true;
            },

            onError: (error) => {
                console.log('error on approving request');
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        });

    const { mutate: rejectTimeoffMutation, isLoading: isRejecting } =
        useMutation(timeoffsServices.approveTimeoff, {
            onSuccess: () => {
                // onSave();
                enqueueSnackbar(
                    'Leave request has been rejected successfully.',
                    {
                        variant: 'success'
                    }
                );

                refreshEvents();
                hideDialog();
                return true;
            },

            onError: (error) => {
                console.log('error on rejecting request');
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        });

    const { mutate: cancelTimeoffMutation, isLoading: isCancelling } =
        useMutation(timeoffsServices.cancelTimeoff, {
            onSuccess: () => {
                // onSave();
                enqueueSnackbar(
                    'Leave request has been cancelled successfully.',
                    {
                        variant: 'success'
                    }
                );

                refreshEvents();
                hideDialog();
                return true;
            },

            onError: (error) => {
                console.log('error on cancelling request');
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        });

    const onApprove = async (data) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Accept it!',
            confirmButtonColor: theme.palette.success.main,
            cancelButtonText: 'No!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    approveTimeoffMutation({ ...data, approved: true });
                } catch (error) {}
            } else {
                MySwal.fire('Pending', 'Request is still pending :)', 'info');
            }
        });
    };

    const onReject = async (data) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Reject it!',
            confirmButtonColor: theme.palette.error.main,
            cancelButtonText: 'No, Keep it!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    rejectTimeoffMutation({ ...data, approved: false });
                } catch (error) {}
            } else {
                MySwal.fire(
                    'We keeping it',
                    'Request is still pending :)',
                    'info'
                );
            }
        });
    };

    const onCancel = async (data) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Cancel it!',
            confirmButtonColor: theme.palette.error.main,
            cancelButtonText: 'No, Keep it!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    cancelTimeoffMutation({ ...data, active: false });
                } catch (error) {}
            } else {
                MySwal.fire(
                    'We keeping it',
                    'Your request is still active :)',
                    'info'
                );
            }
        });
    };

    // console.log('loggedInUser');
    // console.log(loggedInUser);
    // console.log('approver');
    // console.log(item?.approver);
    // console.log('RequestId');
    // console.log(item?.requestId);

    const getStatusChip = (item) => {
        const chipStyle = {
            approved: { backgroundColor: 'green', color: 'white' },
            rejected: { backgroundColor: 'red', color: 'white' },
            pending: { backgroundColor: 'gray', color: 'white' }
        };

        if (item?.approved) {
            return (
                <Chip
                    label="Approved"
                    size="small"
                    style={chipStyle.approved}
                />
            );
        } else if (item?.rejected) {
            return (
                <Chip
                    label="Rejected"
                    size="small"
                    style={chipStyle.rejected}
                />
            );
        } else {
            return (
                <Chip label="Pending" size="small" style={chipStyle.pending} />
            );
        }
    };

    return (
        <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
            {/*<Div>Event owner: {item?.eventOwnerId}</Div>
            // <Div>Logged In User: {loggedInUser?.userId}</Div>
            // <Div>Approving User: {item?.approver?.id}</Div>*/}
            <CardHeader
                title={item?.approved ? item?.eventName : 'Time Off Request'}
                subheader={getStatusChip(item)}
                // avatar={<Avatar src={item?.profile_pic} />}

                action={
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
            />

            <List disablePadding>
                {item?.approved && (
                    <ListItem sx={{ px: 4 }}>
                        <ListItemAvatar sx={{ minWidth: 66 }}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    height: 48,
                                    width: 48,
                                    bgcolor: (theme) =>
                                        alpha(theme.palette.primary.main, 0.15)
                                }}
                            >
                                <InfoIcon sx={{ color: 'primary.light' }} />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography
                                    variant={'body1'}
                                    color={'text.secondary'}
                                    mb={0.5}
                                >
                                    Details
                                </Typography>
                            }
                            secondary={
                                <Typography variant={'h5'} mb={0}>
                                    {item?.public_holiday
                                        ? `${item?.title}`
                                        : `${
                                              item?.eventOwner
                                          } is on leave for ${
                                              item?.eventPeriod
                                          } day${
                                              item?.eventPeriod > 1 ? 's' : ''
                                          } from ${moment(item?.start).format(
                                              'MMMM DD'
                                          )} ${
                                              item?.eventPeriod > 1
                                                  ? `to ${moment(
                                                        item?.end
                                                    ).format('MMMM DD')}`
                                                  : ''
                                          }`}
                                </Typography>
                            }
                        />
                    </ListItem>
                )}
                {!item?.approved && (
                    <>
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <InfoIcon sx={{ color: 'primary.light' }} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Employee
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {item?.eventOwner}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <InfoIcon sx={{ color: 'primary.light' }} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Type
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {item?.eventName}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <InfoIcon sx={{ color: 'primary.light' }} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Dates
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {`From ${moment(item?.start).format(
                                            'MMMM DD'
                                        )} to ${moment(item?.end).format(
                                            'MMMM DD'
                                        )}`}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </>
                )}
            </List>
            <Div sx={{ flexGrow: 1 }}>
                {!item?.approved &&
                    !item.rejected &&
                    item?.approver?.id === loggedInUser?.userId && (
                        <Grid container spacing={2} padding={2}>
                            <Grid item xs={6} md={6}>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isApproving}
                                    fullWidth
                                    color={'success'}
                                    onClick={() => onApprove(item)}
                                >
                                    Approve
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isRejecting}
                                    color={'error'}
                                    onClick={() => onReject(item)}
                                >
                                    Reject
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    )}
                {!item?.approved &&
                    !item.rejected &&
                    item?.eventOwnerId === loggedInUser?.userId && (
                        <Grid container spacing={2} padding={2}>
                            <Grid item xs={12} md={12}>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isCancelling}
                                    fullWidth
                                    color={'warning'}
                                    onClick={() => onCancel(item)}
                                >
                                    Cancel
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    )}
            </Div>
        </Div>
    );
};

export default Detail;
