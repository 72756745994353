import React from 'react';
import Grid from '@mui/material/Grid';
import Div from '@jumbo/shared/Div';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from 'react-query';
import { userServices } from 'app/services/user-services';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { useSnackbar } from 'notistack';

const validationSchema = yup.object({
    old_password: yup.string().required('Enter Current password'),
    new_password: yup
        .string()
        .min(8, 'Password MUST have a minimum of 8 characters')
        .required('Enter New Password'),
    confirm_password: yup
        .string()
        .oneOf([yup.ref('new_password'), null], 'Passwords must match')
});

const ChangePasswordTab = ({ userDetails }) => {
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: changePasswordMutation, isLoading } = useMutation(
        userServices.changePassword,
        {
            onSuccess: () => {
                enqueueSnackbar('Password changed successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onSubmit = async (data) => {
        changePasswordMutation({ id: userDetails?.id, ...data });
    };

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            <Formik
                validateOnChange={true}
                initialValues={{
                    old_password: '',
                    new_password: '',
                    confirm_password: ''
                }}
                validationSchema={validationSchema}
                onSubmit={async (data, { setSubmitting }) => {
                    await onSubmit(data);
                }}
            >
                {({ isSubmitting, errors, touched, values, ...restProps }) => (
                    <Form
                        style={{ textAlign: 'left' }}
                        noValidate
                        autoComplete="off"
                    >
                        <Div sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        id="old_password"
                                        name="old_password"
                                        inputProps={{
                                            autoComplete: 'new-password'
                                        }}
                                        label="Enter Current Password"
                                        placeholder="Current Password"
                                        type="password"
                                        fullWidth
                                        size={'small'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        id="new_password"
                                        name="new_password"
                                        inputProps={{
                                            autoComplete: 'new-password'
                                        }}
                                        label="Enter New Password"
                                        placeholder="New Password"
                                        type="password"
                                        fullWidth
                                        size={'small'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        id="confirm_password"
                                        name="confirm_password"
                                        inputProps={{
                                            autoComplete: 'new-password'
                                        }}
                                        label="Enter Confirm Password"
                                        placeholder="Confirm Password"
                                        type="password"
                                        fullWidth
                                        size={'small'}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    margin: '10px auto'
                                }}
                                container
                                alignItems="right"
                                justify="flex-end"
                                justifyContent="right"
                            >
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isLoading}
                                    disabled={!!errors.length}
                                >
                                    Change Password
                                </LoadingButton>
                            </Grid>
                        </Div>
                    </Form>
                )}
            </Formik>
        </Div>
    );
};

export default ChangePasswordTab;
