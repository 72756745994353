import React from 'react';
import UserProfile from 'app/pages/settings/Users/UserProfile';
import Page from '@jumbo/shared/Page';
import authRoles from 'app/config/authRoles';

const userRoutes = {
    settings: {
        layout: {}
    },
    auth: authRoles?.consultant,
    routes: [
        {
            path: '/user/profile/:id',
            element: <Page component={UserProfile} />
        }
    ]
};

export default userRoutes;
