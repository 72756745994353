import React from 'react';
import Grid from '@mui/material/Grid';
import Div from '@jumbo/shared/Div';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useMutation } from 'react-query';
import { userServices } from 'app/services/user-services';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboRadioGroup from '@jumbo/components/JumboFormik/JumboRadioGroup';
import { useSnackbar } from 'notistack';

const validationSchema = yup.object({
    first_name: yup
        .string('Enter first name')
        .required('First name is required'),
    middle_name: yup
        .string('Enter middle name')
        .required('Middle name is required'),
    last_name: yup.string('Enter last name').required('Last name is required'),
    dob: yup
        .string('Enter date of birth')
        .required('Date of birth is required'),
    gender: yup.string('Select gender').required('Gender is required'),
    email_address: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    phone_number: yup
        .string('Enter phone number')
        // .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone number is required'),
    address: yup
        .string('Enter residential address')
        .required('City/Residential address is required'),
    university_attended: yup
        .string('Enter the university attended')
        .required('University/College attended is required'),
    id_no: yup
        .string('Enter Governement Identification number')
        .required('ID No. is required'),
    kra_pin: yup.string('Enter KRA Pin').required('KRA Pin is required')
});

const UpdateProfileTab = ({
    userDetails,
    onUpdate = () => {},
    hideDialog = () => {}
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [fieldDob, setDob] = React.useState(dayjs(userDetails?.dob));
    const [phone, setPhone] = React.useState(userDetails?.phone_number);

    const { mutate: editUserMutation, isLoading } = useMutation(
        userServices.updateUserProfile,
        {
            onSuccess: () => {
                onUpdate();

                enqueueSnackbar('User has been updated successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onEdit = async (user) => {
        editUserMutation({ id: userDetails?.id, ...user });
    };

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            <Formik
                validateOnChange={true}
                initialValues={{
                    first_name: userDetails?.first_name,
                    middle_name: userDetails?.middle_name,
                    last_name: userDetails?.last_name,
                    dob: fieldDob,
                    gender: userDetails?.gender.toLowerCase(),
                    email_address: userDetails?.email_address,
                    phone_number: phone,
                    address: userDetails?.address ? userDetails?.address : '',
                    university_attended: userDetails?.university_attended
                        ? userDetails?.university_attended
                        : '',
                    id_no: userDetails?.id_no,
                    kra_pin: userDetails?.kra_pin
                }}
                validationSchema={validationSchema}
                onSubmit={async (data, { setSubmitting }) => {
                    await onEdit(data);
                }}
            >
                {({ isSubmitting, errors, touched, values, ...restProps }) => (
                    <Form
                        style={{ textAlign: 'left' }}
                        noValidate
                        autoComplete="off"
                    >
                        <Div sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="first_name"
                                        name="first_name"
                                        label="First Name"
                                        placeholder="Enter first name"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="middle_name"
                                        name="middle_name"
                                        label="Middle Name"
                                        placeholder="Enter middle name"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="last_name"
                                        name="last_name"
                                        label="Last Name"
                                        placeholder="Enter last name"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <MobileDatePicker
                                            label="Date Of Birth"
                                            inputFormat="DD/MM/YYYY"
                                            value={fieldDob}
                                            onChange={(newValue) => {
                                                console.log(fieldDob);
                                                setDob(newValue);
                                                values.dob = newValue;
                                                console.log(newValue);
                                            }}
                                            renderInput={(params) => {
                                                return (
                                                    <JumboTextField
                                                        fullWidth
                                                        size={'small'}
                                                        value={fieldDob}
                                                        name="dob"
                                                        {...params}
                                                    />
                                                );
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <JumboRadioGroup
                                        row
                                        labelid="gender-row"
                                        label="Gender :"
                                        aria-labelledby="gender-row"
                                        name="gender"
                                    >
                                        <FormControlLabel
                                            value="male"
                                            control={<Radio size="small" />}
                                            label="Male"
                                        />
                                        <FormControlLabel
                                            value="female"
                                            control={<Radio size="small" />}
                                            label="Female"
                                        />
                                    </JumboRadioGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="email_address"
                                        name="email_address"
                                        label="Email Address"
                                        placeholder="Enter email address"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <MuiTelInput
                                        fullWidth
                                        size={'small'}
                                        id="phone_number"
                                        name="phone_number"
                                        label="Phone Number"
                                        value={phone}
                                        placeholder="Enter phone number"
                                        preferredCountries={['KE', 'ZM']}
                                        defaultCountry="KE"
                                        onChange={(newValue) => {
                                            console.log('phone', phone);
                                            setPhone(newValue);
                                            values.phone_number = newValue;

                                            console.log('newValue', newValue);
                                        }}
                                    />
                                    {touched.phone_number &&
                                    errors.phone_number ? (
                                        <FormHelperText
                                            style={{
                                                color: 'red'
                                            }}
                                        >
                                            {errors.phone_number}
                                        </FormHelperText>
                                    ) : null}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="address"
                                        name="address"
                                        label="City/Residential address"
                                        placeholder="Enter residential address"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="university_attended"
                                        name="university_attended"
                                        label="University/College Attended"
                                        placeholder="Enter University/College name attended"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="id_no"
                                        name="id_no"
                                        label="ID No."
                                        placeholder="Enter ID Number"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="kra_pin"
                                        name="kra_pin"
                                        label="KRA Pin"
                                        placeholder="Enter KRA Pin"
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    margin: '10px auto'
                                }}
                                container
                                alignItems="right"
                                justify="flex-end"
                                justifyContent="right"
                            >
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isLoading}
                                    disabled={!!errors.length}
                                >
                                    Update
                                </LoadingButton>
                            </Grid>
                        </Div>
                    </Form>
                )}
            </Formik>
        </Div>
    );
};
/* Todo userItem, onSave prop define */
export default UpdateProfileTab;
