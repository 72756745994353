import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import { institutionsServices } from 'app/services/institutions-services';
import { countriesServices } from 'app/services/countries-services';
import CircularProgress from '@mui/material/CircularProgress';
import { Form, Formik, Field } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { categories } from 'app/utils/constants/variables';
import Grid from '@mui/material/Grid';
import Div from '@jumbo/shared/Div';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

const Filter = ({
    filterCountry,
    setFilterCountry,
    filterInstitution,
    setFilterInstitution,
    filterCategory,
    setFilterCategory
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [countries, setCountries] = React.useState(false);
    const [institutions, setInstitutions] = React.useState(false);

    const [countriesOptions, setCountriesOptions] = React.useState('');
    const [institutionsOptions, setInstitutionsOptions] = React.useState('');

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };
        const fetchCountries = async () => {
            try {
                const res = await countriesServices.getAllCountries(cToken);

                setCountries(res.countries);
                setCountriesOptions(res.countries);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchCountries();

        return () => {
            cancelToken.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };
        const fetchInstitutions = async () => {
            try {
                const res = await institutionsServices.getAllInstitutions(
                    cToken
                );

                setInstitutions(res.institutions);
                setInstitutionsOptions(res.institutions);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchInstitutions();

        return () => {
            cancelToken.cancel();
        };
    }, []);

    React.useEffect(() => {
        setInstitutionsOptions(
            _.filter(
                institutions,
                (institution) =>
                    institution?.countryId?.id === filterCountry?.id
            )
        );
    }, [filterCountry]);

    return (
        <Div sx={{ flexGrow: 1, marginBottom: '16px' }}>
            <Card sx={{ p: '10px' }}>
                <Grid container spacing={2}>
                    {(!institutions || !countries) && (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width={'100%'}
                        >
                            <CircularProgress color="inherit" />
                        </Box>
                    )}

                    {institutions && countries && (
                        <>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    name="countryId"
                                    sx={{
                                        width: 500,
                                        maxWidth: '100%'
                                    }}
                                    size="small"
                                    options={countries}
                                    autoHighlight
                                    getOptionLabel={(option) =>
                                        option?.country_name
                                            ? option.country_name
                                            : ''
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{
                                                '& > img': {
                                                    mr: 2,
                                                    flexShrink: 0
                                                }
                                            }}
                                            {...props}
                                        >
                                            <img
                                                loading="lazy"
                                                width="20"
                                                src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                                                srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                                                alt=""
                                            />
                                            {option.country_name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country"
                                            placeholder="Choose a country"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password'
                                            }}
                                        />
                                    )}
                                    onChange={
                                        (event, newValue) => {
                                            setFilterCountry(newValue);
                                            setFilterInstitution(null);
                                        }
                                        // Set the new value in the form field
                                    }
                                    value={
                                        countries.find(
                                            (option) =>
                                                option.id === filterCountry?.id
                                        ) || null
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    name="institutionId"
                                    sx={{
                                        width: '100%',
                                        maxWidth: '100%'
                                    }}
                                    size="small"
                                    options={institutionsOptions}
                                    autoHighlight
                                    getOptionLabel={(option) =>
                                        option?.institution_name
                                            ? option.institution_name
                                            : ''
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                            {option.institution_name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Insititution"
                                            placeholder="Choose an institution"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password'
                                            }}
                                            // error={
                                            //     touched?.institutionId &&
                                            //     !!errors.institutionId
                                            // }
                                            // helperText={
                                            //     touched?.institutionId &&
                                            //     errors.institutionId
                                            // }
                                        />
                                    )}
                                    onChange={
                                        (event, newValue) => {
                                            setFilterInstitution(newValue);
                                        }
                                        // Set the new value in the form field
                                    }
                                    value={
                                        institutions.find(
                                            (option) =>
                                                option.id ===
                                                filterInstitution?.id
                                        ) || null
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    name="course_category"
                                    sx={{
                                        width: '100%',
                                        maxWidth: '100%'
                                    }}
                                    size="small"
                                    options={categories}
                                    autoHighlight
                                    getOptionLabel={(option) =>
                                        option?.category_name
                                            ? option.category_name
                                            : ''
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                            {option.category_name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Course Category"
                                            placeholder="Choose a Course Category"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password'
                                            }}
                                        />
                                    )}
                                    onChange={
                                        (event, newValue) => {
                                            setFilterCategory(newValue);
                                        }
                                        // Set the new value in the form field
                                    }
                                    value={
                                        categories.find(
                                            (option) =>
                                                option.id === filterCategory?.id
                                        ) || null
                                    }
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Card>
        </Div>
    );
};

export default Filter;
