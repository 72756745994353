import React from 'react';

import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { useMutation } from 'react-query';
import { institutionsServices } from 'app/services/institutions-services';
import { countriesServices } from 'app/services/countries-services';
import { requirementServices } from 'app/services/requirement-services';

import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import axios from 'axios';

import CircularProgress from '@mui/material/CircularProgress';

import _ from 'lodash';

const validationSchema = yup.object({
    institution_name: yup
        .string('Enter institution name')
        .required('Institution name is required'),
    phone_number: yup
        .string('Enter the institution contact phone number')
        .required('Phone number is required'),
    email_address: yup
        .string('Enter institution email')
        .email('Enter a valid email')
        .required('Email is required'),
    address: yup
        .string('Enter the institution address')
        .required('Institution address is required'),
    countryId: yup.string('Select country').required('Country is required'),
    city_name: yup
        .string('Enter the institution location city')
        .required('City is required'),
    first_year_fees: yup
        .string('Enter first year fee')
        .required('First year fee is required'),
    application_fee: yup
        .string('Enter application fee')
        .required('Application fee is required'),
    application_fee_currency: yup
        .string('Enter application fee currency')
        .required('Application fee currency is required'),
    offer_waiting_period: yup
        .string('Enter offer waiting period')
        .required('offer waiting period is required'),
    commission: yup
        .string('Enter commission')
        .required('commission is required'),
    application_requirements: yup
        .array()
        .min(1, 'Select at least one requirement'),
    contact_first_name: yup
        .string('Enter contact person first name')
        .required('Contact person first name is required'),
    contact_last_name: yup
        .string('Enter contact person last name')
        .required('Contact person last name is required'),
    contact_phone_number: yup
        .string('Enter contact person phone number')
        .required('Contact person Phone number is required'),
    contact_email_address: yup
        .string('Enter contact person email address')
        .email('Enter a valid email address')
        .required('Contact person email address is required')
});

const CreateForm = ({ onSave, hideDialog }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [countries, setCountries] = React.useState(false);
    const [requirements, setRequirements] = React.useState(false);
    const [countryId, setCountry] = React.useState('');
    const [phone, setPhone] = React.useState('+254');
    const [contactPhone, setContactPhone] = React.useState('+254');

    const { mutate: addInstitutionMutation, isLoading } = useMutation(
        institutionsServices.addInstitution,
        {
            onSuccess: () => {
                onSave();
                enqueueSnackbar('Institution has been created successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (institution) => {
        try {
            addInstitutionMutation({ ...institution });
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };
        const fetchCountries = async () => {
            try {
                const res = await countriesServices.getAllCountries(cToken);

                setCountries(res.countries);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchCountries();

        return () => {
            cancelToken.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenRequirements = axios.CancelToken.source();
        const cTokenRequirements = {
            cancelToken: cancelTokenRequirements.token
        };
        const fetchRequirements = async () => {
            try {
                const res = await requirementServices.getAllRequirements(
                    cTokenRequirements
                );

                setRequirements(res.requirements);

                // const reqs = listItem?.application_requirements
                //     ? _.filter(res.requirements, (obj) =>
                //           _.includes(
                //               _.map(listItem.application_requirements, 'id'),
                //               obj.id
                //           )
                //       )
                //     : [];

                // setDefaultRequirements(reqs);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchRequirements();

        return () => {
            cancelTokenRequirements.cancel();
        };
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {!countries ||
                (!requirements && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width={'100%'}
                    >
                        <CircularProgress color="inherit" />
                    </Box>
                ))}

            {countries && requirements && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        institution_name: '',
                        phone_number: '',
                        email_address: '',
                        address: '',
                        countryId: '',
                        city_name: '',
                        first_year_fees: '',
                        application_fee: '',
                        application_fee_currency: '',
                        offer_waiting_period: '',
                        first_year_fees: '',
                        commission: '',
                        application_requirements: [],
                        contact_first_name: '',
                        contact_last_name: '',
                        contact_phone_number: '',
                        contact_email_address: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onCreate(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            {/* <div>{JSON.stringify(errors)}</div> */}
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="institution_name"
                                            name="institution_name"
                                            label="Institution Name"
                                            placeholder="Enter Institution Name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field name="countryId">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    id="countryId"
                                                    sx={{
                                                        width: 500,
                                                        maxWidth: '100%'
                                                    }}
                                                    size="small"
                                                    options={countries}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.country_name
                                                            ? option.country_name
                                                            : ''
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                '& > img': {
                                                                    mr: 2,
                                                                    flexShrink: 0
                                                                }
                                                            }}
                                                            {...props}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                                                                alt=""
                                                            />
                                                            {
                                                                option.country_name
                                                            }
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Country"
                                                            placeholder="Choose a country"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.countryId &&
                                                                !!errors.countryId
                                                            }
                                                            helperText={
                                                                touched?.countryId &&
                                                                errors.countryId
                                                            }
                                                        />
                                                    )}
                                                    onChange={
                                                        (event, newValue) => {
                                                            values.countryId =
                                                                newValue?.id;
                                                        }
                                                        // Set the new value in the form field
                                                    }
                                                    value={
                                                        countries.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.countryId
                                                        ) || ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="city_name"
                                            name="city_name"
                                            label="Enter City"
                                            placeholder="Type the institution location city"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="address"
                                            name="address"
                                            label="Physical Address"
                                            placeholder="Enter the physical address here"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MuiTelInput
                                            fullWidth
                                            size={'small'}
                                            id="phone_number"
                                            name="phone_number"
                                            label="Phone Number"
                                            value={phone}
                                            placeholder="Enter phone number"
                                            preferredCountries={['KE', 'ZM']}
                                            defaultCountry="KE"
                                            onChange={(newValue) => {
                                                setPhone(newValue);
                                                values.phone_number = newValue;
                                            }}
                                        />
                                        {touched.phone_number &&
                                        errors.phone_number ? (
                                            <FormHelperText
                                                style={{
                                                    color: 'red'
                                                }}
                                            >
                                                {errors.phone_number}
                                            </FormHelperText>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="email_address"
                                            name="email_address"
                                            label="Email Address"
                                            placeholder="Enter Institution Name"
                                            type="email"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="application_fee"
                                            name="application_fee"
                                            label="Application Fee"
                                            placeholder="Enter application fee amount"
                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    min: 0
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Field name="application_fee_currency">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    id="application_fee_currency"
                                                    sx={{
                                                        width: 500,
                                                        maxWidth: '100%'
                                                    }}
                                                    size="small"
                                                    options={countries}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.currency
                                                            ? option.currency +
                                                              ' - ' +
                                                              option.country_name
                                                            : ''
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                '& > img': {
                                                                    mr: 2,
                                                                    flexShrink: 0
                                                                }
                                                            }}
                                                            {...props}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                                                                alt=""
                                                            />
                                                            {option.currency +
                                                                ' - ' +
                                                                option.country_name}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Application Fee Currency"
                                                            placeholder="Choose a currency"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.application_fee_currency &&
                                                                !!errors.application_fee_currency
                                                            }
                                                            helperText={
                                                                touched?.application_fee_currency &&
                                                                errors.application_fee_currency
                                                            }
                                                        />
                                                    )}
                                                    onChange={
                                                        (event, newValue) => {
                                                            values.application_fee_currency =
                                                                newValue?.id;
                                                        }
                                                        // Set the new value in the form field
                                                    }
                                                    value={
                                                        countries.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.application_fee_currency
                                                        ) || ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="offer_waiting_period"
                                            name="offer_waiting_period"
                                            label="Offer Waiting Period (Estimated no. Of Days after Application)"
                                            placeholder="Enter estimated waiting period in days."
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="first_year_fees"
                                            name="first_year_fees"
                                            label="First Year Fees"
                                            placeholder="Enter first year fees fees"
                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    min: 0
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="commission"
                                            name="commission"
                                            label="Commission %"
                                            placeholder="Enter commission in percentage: 0 - 100"
                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    max: 100,
                                                    min: 0
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Field name="application_requirements">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    multiple
                                                    id="application_requirements"
                                                    size={'small'}
                                                    options={requirements}
                                                    getOptionLabel={(
                                                        option
                                                    ) => {
                                                        return (
                                                            option.requirement_name ||
                                                            ''
                                                        );
                                                    }}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                '& > img': {
                                                                    mr: 2,
                                                                    flexShrink: 0
                                                                }
                                                            }}
                                                            {...props}
                                                        >
                                                            {
                                                                option.requirement_name
                                                            }
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Choose Uni/College applications requirements for this Institution"
                                                            placeholder="Uni/College Application Requirements"
                                                            error={
                                                                touched.application_requirements &&
                                                                !!errors.application_requirements
                                                            }
                                                            helperText={
                                                                touched.application_requirements &&
                                                                errors.application_requirements
                                                            }
                                                        />
                                                    )}
                                                    onChange={(
                                                        event,
                                                        newValue
                                                    ) =>
                                                        field.onChange({
                                                            target: {
                                                                name: field.name,
                                                                value: newValue
                                                            }
                                                        })
                                                    }
                                                    value={
                                                        values.application_requirements
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                                <Divider
                                    component={'div'}
                                    sx={{ margin: '20px auto' }}
                                />
                                <Typography variant="h5" component="h5">
                                    Contact Person Details
                                </Typography>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="contact_first_name"
                                            name="contact_first_name"
                                            label="First Name"
                                            placeholder="Enter Contact First Name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="contact_last_name"
                                            name="contact_last_name"
                                            label="contact_Last Name"
                                            placeholder="Enter Contact Last Name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MuiTelInput
                                            fullWidth
                                            size={'small'}
                                            id="contact_phone_number"
                                            name="contact_phone_number"
                                            label="Phone Number"
                                            value={contactPhone}
                                            placeholder="Enter Contact person phone number"
                                            preferredCountries={[
                                                'KE',
                                                'ZM',
                                                'AU',
                                                'US',
                                                'UK',
                                                'CA',
                                                'MY'
                                            ]}
                                            defaultCountry="KE"
                                            onChange={(newValue) => {
                                                setContactPhone(newValue);
                                                values.contact_phone_number =
                                                    newValue;
                                            }}
                                        />
                                        {touched.contact_phone_number &&
                                        errors.contact_phone_number ? (
                                            <FormHelperText
                                                style={{
                                                    color: 'red'
                                                }}
                                            >
                                                {errors.contact_phone_number}
                                            </FormHelperText>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="contact_email_address"
                                            name="contact_email_address"
                                            label="Email Address"
                                            placeholder="Enter contact person email address"
                                            type="email"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                    >
                                        Save
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo institutionItem, onSave prop define */
export default CreateForm;
