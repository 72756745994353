import React from 'react';
import { Typography } from '@mui/material';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';

const Biography = ({ userDetails }) => {
    return (
        <JumboCardQuick
            title={'Biography'}
            subheader={`A little flash back of ${userDetails?.first_name}`}
            headerSx={{ pb: 0 }}
            sx={{ mb: 3.75 }}
        >
            {/* <Typography variant="h3" fontWeight="300" mb={2}>
                {userDetails?.biography?.title}
            </Typography> */}
            <Typography variant="body1" mb={2}></Typography>
            <Typography variant="body1" mb={2}>
                {userDetails?.biography ? (
                    userDetails?.biography
                ) : (
                    <i>{'NO BIOGRAPHY YET'}</i>
                )}
            </Typography>
        </JumboCardQuick>
    );
};

export default Biography;
