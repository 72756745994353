import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const notificationsServices = {};

notificationsServices.getAllNotifications = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/notifications',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

notificationsServices.getNotifications = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/notifications', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

notificationsServices.getNotificationDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(
        `/notifications/notification/${id}`,
        {
            id: id
        }
    );

    return data;
};

notificationsServices.addNotification = async (notifications) => {
    const { data } = await jwtAuthAxios.post(`/notifications`, notifications);
    return data;
};

notificationsServices.updateNotification = async (notifications) => {
    // console.log('Service Notification');
    // console.log(notifications);
    const { data } = await jwtAuthAxios.patch(
        `/notifications/${notifications.id}`,
        notifications
    );
    return data;
};

notificationsServices.deleteNotification = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/notifications/${id}`, {
        id: id
    });
    return data;
};

notificationsServices.deleteMultipleNotifications = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/notifications`, {
        data: ids
    });
    return data;
};

export { notificationsServices };
