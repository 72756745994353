import React from 'react';
import ReimbursementsAppContext from './ReimbursementsAppContext';
import { CONTACT_APP_ACTIONS } from './utils/constants';

const init = (appState) => appState;

const reimbursementsAppReducer = (state, action) => {
    switch (action.type) {
        case CONTACT_APP_ACTIONS.SET_SELECTED_ITEMS:
            return {
                ...state,
                selectedReimbursements: action?.payload
            };
        case CONTACT_APP_ACTIONS.SET_CONTACTS_LIST_REFRESH:
            return {
                ...state,
                refreshReimbursementsList: action.payload
            };
        case CONTACT_APP_ACTIONS.SET_LABELS_LIST_REFRESH:
            return {
                ...state,
                refreshLabelsList: action.payload
            };
        default:
            return state;
    }
};

const ReimbursementsAppProvider = ({ children }) => {
    const [reimbursementsApp, setReimbursementsApp] = React.useReducer(
        reimbursementsAppReducer,
        {
            selectedReimbursements: [],
            refreshReimbursementsList: false,
            refreshLabelsList: false
        },
        init
    );

    const setSelectedReimbursements = React.useCallback(
        (reimbursements) => {
            setReimbursementsApp({
                type: CONTACT_APP_ACTIONS.SET_SELECTED_ITEMS,
                payload: reimbursements
            });
        },
        [setReimbursementsApp]
    );

    const setReimbursementsListRefresh = React.useCallback(
        (refresh) => {
            setReimbursementsApp({
                type: CONTACT_APP_ACTIONS.SET_CONTACTS_LIST_REFRESH,
                payload: refresh
            });
        },
        [setReimbursementsApp]
    );

    const setLabelsListRefresh = React.useCallback(
        (refresh) => {
            setReimbursementsApp({
                type: CONTACT_APP_ACTIONS.SET_LABELS_LIST_REFRESH,
                payload: refresh
            });
        },
        [setReimbursementsApp]
    );

    const contextValue = React.useMemo(
        () => ({
            ...reimbursementsApp,
            setSelectedReimbursements: setSelectedReimbursements,
            setReimbursementsListRefresh: setReimbursementsListRefresh,
            setLabelsListRefresh: setLabelsListRefresh
        }),
        [reimbursementsApp]
    );
    return (
        <ReimbursementsAppContext.Provider value={contextValue}>
            {children}
        </ReimbursementsAppContext.Provider>
    );
};

export default ReimbursementsAppProvider;
