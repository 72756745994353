import React from 'react';
import { useSnackbar } from 'notistack';
import { Button, Grid } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import PaidIcon from '@mui/icons-material/Paid';
import FlightIcon from '@mui/icons-material/Flight';
import MoneyIcon from '@mui/icons-material/Money';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import SummaryCard from '../SummaryCard';
import Slide from '@mui/material/Slide';
import { Link } from 'react-router-dom';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import TravelForm from './TravelForm';
import PurchaseForm from './PurchaseForm';
import AdvanceForm from './AdvanceForm';
import ExpenseReportForm from './ExpenseReportForm';
import useExpensesApp from '../../hooks/useExpensesApp';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const ExpenseForm = ({ onSave, authUser }) => {
    const { enqueueSnackbar } = useSnackbar();

    const { showDialog, hideDialog } = useJumboDialog();

    const showTravelDialog = React.useCallback(() => {
        hideDialog();
        showDialog({
            title: 'Travel Request',
            content: (
                <TravelForm
                    onSave={onSave}
                    authUser={authUser}
                    hideDialog={hideDialog}
                />
            ),
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true,
            maxWidth: 'md'
        });
    }, [onSave, showDialog]);

    const showPurchaseDialog = React.useCallback(() => {
        hideDialog();
        showDialog({
            title: 'Purchase Request',
            content: (
                <PurchaseForm
                    onSave={onSave}
                    authUser={authUser}
                    hideDialog={hideDialog}
                />
            ),
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true,
            maxWidth: 'sm'
        });
    }, [onSave, showDialog]);

    const showAdvanceDialog = React.useCallback(() => {
        hideDialog();
        showDialog({
            title: 'Advance Request',
            content: (
                <AdvanceForm
                    onSave={onSave}
                    authUser={authUser}
                    hideDialog={hideDialog}
                />
            ),
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true,
            maxWidth: 'sm'
        });
    }, [onSave, showDialog]);

    const showExpenseReportDialog = React.useCallback(() => {
        hideDialog();
        showDialog({
            title: 'Expense Report Submission',
            content: (
                <ExpenseReportForm
                    onSave={onSave}
                    authUser={authUser}
                    hideDialog={hideDialog}
                />
            ),
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true,
            maxWidth: 'md'
        });
    }, [onSave, showDialog]);

    return (
        <Grid item xs={12} lg={2} sx={{ marginBottom: '20px' }}>
            <Grid container spacing={3.75}>
                <Grid item xs={12} sm={6} lg={6}>
                    <SummaryCard
                        component={Button}
                        cardSx={{
                            '&:hover': {
                                border: '2px solid #7352C7'
                            }
                        }}
                        title={'Travel Request'}
                        value={''}
                        onClick={() => {
                            showTravelDialog();
                        }}
                        icon={<FlightIcon />}
                        color="#fd7f20"
                        colorFaded="#fed4b3"
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <SummaryCard
                        cardSx={{
                            '&:hover': {
                                border: '2px solid #7352C7'
                            }
                        }}
                        title={'Advance Request'}
                        value={''}
                        onClick={() => {
                            showAdvanceDialog();
                        }}
                        icon={<PaidIcon />}
                        color="#654e53"
                        colorFaded="#b7a1a6"
                    />
                </Grid>

                <Grid item xs={12} sm={6} lg={6}>
                    <SummaryCard
                        component={Button}
                        cardSx={{
                            '&:hover': {
                                border: '2px solid #7352C7'
                            }
                        }}
                        title={'Requisition Request'}
                        onClick={() => {
                            showPurchaseDialog();
                        }}
                        value={''}
                        icon={<ShoppingCartCheckoutIcon />}
                        color="#366353"
                        colorFaded="#7bb7a2"
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <SummaryCard
                        component={Button}
                        cardSx={{
                            '&:hover': {
                                border: '2px solid #7352C7'
                            }
                        }}
                        title={'Expense Report'}
                        onClick={() => {
                            showExpenseReportDialog();
                        }}
                        value={''}
                        icon={<MoneyIcon />}
                        color="#3366ff"
                        colorFaded="#99b3ff"
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
ExpenseForm.defaultProps = {
    onSave: () => {}
};
export default ExpenseForm;
