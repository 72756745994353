import React from 'react';
import { Avatar, Card, CardContent, Rating, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

const AgentDetail = ({ agentDetail }) => {
    const userPic = agentDetail?._id?.profile_pic
        ? process.env.REACT_APP_BACKEND + '/' + agentDetail?._id?.profile_pic
        : null;
    const leadsDesc =
        agentDetail?.count === 1 ? `1 Lead` : `${agentDetail?.count} Leads`;

    return (
        <Card sx={{ overflow: 'visible', mt: 4 }}>
            <CardContent>
                <Avatar
                    alt={`${agentDetail?._id?.first_name} ${agentDetail?._id?.last_name}`}
                    src={userPic}
                    sx={{
                        boxShadow: 26,
                        width: 60,
                        height: 60,
                        mb: 2,
                        mt: '-54px'
                    }}
                />
                <Typography
                    variant={'h6'}
                >{`${agentDetail?._id?.first_name} ${agentDetail?._id?.last_name}`}</Typography>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Rating
                        name="feedback"
                        value={1}
                        max={1}
                        readOnly
                        precision={1}
                        size={'small'}
                        emptyIcon={<StarIcon />}
                    />
                    <Typography
                        component={'div'}
                        variant={'body1'}
                        color={'text.secondary'}
                        sx={{
                            display: 'flex',
                            fontSize: 12
                        }}
                    >
                        {agentDetail?._id?.rating}
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ mx: 1, my: 0.5 }}
                        />
                        {leadsDesc}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    );
};
/* Todo agent detail prop */
export default AgentDetail;
