import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const apikeysServices = {};

apikeysServices.getAllApikeys = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/apikeys',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

apikeysServices.getApikeys = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/apikeys', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

apikeysServices.getApikeyDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/apikeys/apikey/${id}`, {
        id: id
    });

    return data;
};

apikeysServices.addApikey = async (apikeys) => {
    const { data } = await jwtAuthAxios.post(`/apikeys`, apikeys);
    return data;
};

apikeysServices.updateApikey = async (apikeys) => {
    // console.log('Service Apikey');
    // console.log(apikeys);
    const { data } = await jwtAuthAxios.patch(
        `/apikeys/${apikeys.id}`,
        apikeys
    );
    return data;
};

apikeysServices.deleteApikey = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/apikeys/${id}`, {
        id: id
    });
    return data;
};

apikeysServices.deleteMultipleApikeys = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/apikeys`, {
        data: ids
    });
    return data;
};

export { apikeysServices };
