import React from 'react';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import EventNoteIcon from '@mui/icons-material/EventNote';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import LockResetIcon from '@mui/icons-material/LockReset';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import ScreenLockRotationIcon from '@mui/icons-material/ScreenLockRotation';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RunningWithErrorsOutlinedIcon from '@mui/icons-material/RunningWithErrorsOutlined';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import StreamOutlinedIcon from '@mui/icons-material/StreamOutlined';
import ListIcon from '@mui/icons-material/List';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import CallEndIcon from '@mui/icons-material/CallEnd';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import FeedbackIcon from '@mui/icons-material/Feedback';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import GridViewIcon from '@mui/icons-material/GridView';
import Grid3x3OutlinedIcon from '@mui/icons-material/Grid3x3Outlined';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';

import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'; //Employees
import PeopleIcon from '@mui/icons-material/People';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import SchoolIcon from '@mui/icons-material/School';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import WorkIcon from '@mui/icons-material/Work';
import SecurityIcon from '@mui/icons-material/Security';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

import FileOpenIcon from '@mui/icons-material/FileOpen';
import BedIcon from '@mui/icons-material/Bed';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

// import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const menus = [
    {
        label: 'sidebar.menu.home',
        type: 'section',
        action: 'read',
        subject: 'Home',
        children: [
            {
                action: 'read',
                subject: 'Dashboard',
                uri: '/dashboards/overview',
                label: 'sidebar.menuItem.overview',
                type: 'nav-item',
                icon: <GraphicEqIcon sx={{ fontSize: 20 }} />
            },

            {
                action: 'read',
                subject: 'Leads',
                uri: '/home/leads',
                label: 'sidebar.menuItem.leads',
                type: 'nav-item',
                icon: <ViewListOutlinedIcon sx={{ fontSize: 20 }} />
            },
            {
                action: 'read',
                subject: 'Students',
                uri: '/home/students',
                label: 'sidebar.menuItem.students',
                type: 'nav-item',
                icon: <PeopleIcon sx={{ fontSize: 20 }} />
            },
            {
                // action: 'read',
                // subject: 'Applications',
                uri: '/list-views/applications',
                label: 'sidebar.menuItem.applications',
                type: 'collapsible',
                icon: <FileOpenIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        action: 'read',
                        subject: 'Uniapplications',
                        uri: '/home/uni-applications/*',
                        label: 'sidebar.menuItem.uni-applications',
                        type: 'nav-item'
                    },
                    {
                        action: 'read',
                        subject: 'Accomodationapplications',
                        uri: '/home/accomodation-applications',
                        label: 'sidebar.menuItem.accomodation-applications',
                        type: 'nav-item'
                    },
                    {
                        action: 'read',
                        subject: 'Visaapplications',
                        uri: '/home/visa-applications',
                        label: 'sidebar.menuItem.visa-applications',
                        type: 'nav-item'
                    }
                ]
            },
            {
                action: 'read',
                subject: 'Events',
                uri: '/list-views/events',
                label: 'sidebar.menuItem.events',
                type: 'collapsible',
                icon: <EventAvailableIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        action: 'read',
                        subject: 'Eventscalendar',
                        uri: '/home/events-calender',
                        label: 'sidebar.menuItem.events-calender',
                        type: 'nav-item'
                    }
                    // {
                    //     uri: '/home/events-list',
                    //     label: 'sidebar.menuItem.events-list',
                    //     type: 'nav-item'
                    // }
                ]
            },

            {
                action: 'read',
                subject: 'Institutions',
                uri: '/home/institutions',
                label: 'sidebar.menuItem.institutions',
                type: 'nav-item',
                icon: <SchoolIcon sx={{ fontSize: 20 }} />
            }
            // {
            //     action: 'read',
            //     subject: 'Courses',
            //     uri: '/home/courses',
            //     label: 'sidebar.menuItem.courses',
            //     type: 'nav-item',
            //     icon: <HistoryEduIcon sx={{ fontSize: 20 }} />
            // }
        ]
    },

    {
        label: 'sidebar.menu.apps',
        type: 'section',
        children: [
            // {
            //     uri: '/app/chats',
            //     label: 'sidebar.menuItem.chat',
            //     type: 'nav-item',
            //     icon: <ChatOutlinedIcon sx={{ fontSize: 20 }} />
            // },
            {
                action: 'read',
                subject: 'Expenses',
                uri: '/app/expenses/all',
                label: 'sidebar.menuItem.expenses',
                type: 'nav-item',
                icon: <AccountBalanceWalletIcon sx={{ fontSize: 20 }} />
            },
            {
                action: 'read',
                subject: 'Contacts',
                uri: '/app/contacts/all',
                label: 'sidebar.menuItem.contacts',
                type: 'nav-item',
                icon: <ContactsOutlinedIcon sx={{ fontSize: 20 }} />
            },
            {
                action: 'read',
                subject: 'Surveys',
                uri: '/app/surveys/all',
                label: 'sidebar.menuItem.surveys',
                type: 'nav-item',
                icon: <FeedbackIcon sx={{ fontSize: 20 }} />
            }
            // {
            //     uri: '/app/mails/inbox',
            //     label: 'sidebar.menuItem.mail',
            //     type: 'nav-item',
            //     icon: <EmailOutlinedIcon sx={{ fontSize: 20 }} />
            // }
        ]
    },

    {
        label: 'sidebar.menu.management',
        type: 'section',
        action: 'read',
        subject: 'Management',
        children: [
            {
                action: 'read',
                subject: 'Finances',
                uri: '/management/finances',
                label: 'sidebar.menuItem.finances',
                type: 'collapsible',
                icon: <AccountBalanceIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        action: 'read',
                        subject: 'Earnings',
                        uri: '/management/earnings',
                        label: 'sidebar.menuItem.earnings',
                        type: 'nav-item'
                    },
                    {
                        action: 'read',
                        subject: 'Earnings',
                        uri: '/management/deposits',
                        label: 'sidebar.menuItem.deposits',
                        type: 'nav-item'
                    },
                    {
                        action: 'read',
                        subject: 'Inventory',
                        uri: '/management/inventory',
                        label: 'sidebar.menuItem.inventory',
                        type: 'nav-item'
                    }
                ]
            },
            {
                // action: 'read',
                // subject: 'Hr',
                uri: '/management/hr-performance',
                label: 'sidebar.menuItem.hr-performance',
                type: 'collapsible',
                icon: <SelfImprovementIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        action: 'read',
                        subject: 'KPIs',
                        uri: '/management/performances',
                        label: 'sidebar.menuItem.scorecard-kpis',
                        type: 'nav-item'
                    },
                    {
                        action: 'read',
                        subject: 'Timeoffs',
                        uri: '/management/timeoff',
                        label: 'sidebar.menuItem.leave',
                        type: 'nav-item',
                        icon: <MoreTimeIcon sx={{ fontSize: 20 }} />
                    }
                    // {
                    //     uri: '/management/career',
                    //     label: 'sidebar.menuItem.career',
                    //     type: 'nav-item'
                    // }
                ]
            },
            {
                action: 'read',
                subject: 'Employees',
                uri: '/management/employees',
                label: 'sidebar.menuItem.employees',
                type: 'nav-item',
                icon: <SupervisedUserCircleIcon sx={{ fontSize: 20 }} />
            },
            {
                action: 'read',
                subject: 'Offices',
                uri: '/management/offices',
                label: 'sidebar.menuItem.offices',
                type: 'nav-item',
                icon: <BusinessIcon sx={{ fontSize: 20 }} />
            }
        ]
    },

    {
        label: 'sidebar.menu.settings',
        type: 'section',
        action: 'read',
        subject: 'Settings',
        children: [
            {
                action: 'read',
                subject: 'Accesscontrol',
                uri: '/management/access-control',
                label: 'sidebar.menuItem.access-control',
                type: 'collapsible',
                icon: <SecurityIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        action: 'read',
                        subject: 'Users',
                        uri: '/settings/users',
                        label: 'sidebar.menuItem.users',
                        type: 'nav-item'
                    },

                    {
                        action: 'read',
                        subject: 'Designations',
                        uri: '/settings/designations',
                        label: 'sidebar.menuItem.designations',
                        type: 'nav-item'
                    },
                    {
                        action: 'read',
                        subject: 'Roles',
                        uri: '/settings/roles',
                        label: 'sidebar.menuItem.roles',
                        type: 'nav-item'
                    }
                ]
            },
            {
                uri: '/settings/institutions-settings',
                label: 'sidebar.menuItem.institutionssettings',
                type: 'collapsible',
                icon: <RoomPreferencesIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        action: 'read',
                        subject: 'Educationlevels',
                        uri: '/settings/education-levels',
                        label: 'sidebar.menuItem.education-levels',
                        type: 'nav-item'
                    },
                    {
                        action: 'read',
                        subject: 'Requirements',
                        uri: '/settings/requirements',
                        label: 'sidebar.menuItem.requirements',
                        type: 'nav-item'
                    },

                    {
                        action: 'read',
                        subject: 'Institutions',
                        uri: '/settings/institutions',
                        label: 'sidebar.menuItem.institutions',
                        type: 'nav-item'
                    },
                    {
                        action: 'read',
                        subject: 'Schools',
                        uri: '/settings/schools',
                        label: 'sidebar.menuItem.schools',
                        type: 'nav-item'
                    },
                    // {
                    //     action: 'read',
                    //     subject: 'Courses',
                    //     uri: '/settings/courses',
                    //     label: 'sidebar.menuItem.courses',
                    //     type: 'nav-item'
                    // },
                    {
                        action: 'read',
                        subject: 'Countries',
                        uri: '/settings/countries',
                        label: 'sidebar.menuItem.set-countries',
                        type: 'nav-item'
                    }
                ]
            },
            {
                action: 'read',
                subject: 'Expensessettings',
                uri: '/settings/expenses',
                label: 'sidebar.menuItem.expenses-settings',
                type: 'collapsible',
                icon: <AccountBalanceWalletIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        action: 'read',
                        subject: 'Expensetypes',
                        uri: '/settings/expense-types',
                        label: 'sidebar.menuItem.expense-types',
                        type: 'nav-item'
                    },
                    // {
                    //     action: 'read',
                    //     subject: 'Expensepolicies',
                    //     uri: '/settings/expenses-policies',
                    //     label: 'sidebar.menuItem.expenses-policies',
                    //     type: 'nav-item'
                    // },
                    // {
                    //     action: 'read',
                    //     subject: 'Expenserules',
                    //     uri: '/settings/expenses-rules',
                    //     label: 'sidebar.menuItem.expenses-rules',
                    //     type: 'nav-item'
                    // },
                    {
                        action: 'read',
                        subject: 'Expenseapprovals',
                        uri: '/settings/expenses/expenses-approvals',
                        label: 'sidebar.menuItem.expenses-approvals',
                        type: 'nav-item'
                    }
                ]
            },
            {
                action: 'read',
                subject: 'Systemsettings',
                uri: '/settings/system-settings',
                label: 'sidebar.menuItem.system-settings',
                type: 'collapsible',
                icon: <LightbulbIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        action: 'read',
                        subject: 'Leavetypes',
                        uri: '/settings/leave-types',
                        label: 'sidebar.menuItem.leave-types',
                        type: 'nav-item'
                    },
                    {
                        action: 'read',
                        subject: 'Incometypes',
                        uri: '/settings/income-types',
                        label: 'sidebar.menuItem.income-types',
                        type: 'nav-item'
                    },
                    {
                        action: 'read',
                        subject: 'Apikeys',
                        uri: '/settings/apikeys',
                        label: 'sidebar.menuItem.api-manager',
                        type: 'nav-item'
                    },
                    {
                        action: 'read',
                        subject: 'Smtps',
                        uri: '/settings/smtps',
                        label: 'sidebar.menuItem.email-smtp-settings',
                        type: 'nav-item'
                    }
                    // {
                    //     uri: '/settings/sms-notifications-settings',
                    //     label: 'sidebar.menuItem.sms-notifications-settings',
                    //     type: 'nav-item'
                    // }
                ]
            }
        ]
    }
];

export default menus;
