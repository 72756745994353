import React from 'react';

import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import { useMutation } from 'react-query';
import { smtpsServices } from 'app/services/smtps-services';

import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { useSnackbar } from 'notistack';

const validationSchema = yup.object({
    smtp_name: yup.string('Enter smtp name').required('SMTP name is required'),
    email_address: yup.string().required('You must enter the email address'),
    password: yup.string().required('You must enter the email password'),
    smtp_host: yup.string().required('You must enter the SMTP Host'),
    smtp_port: yup.string().required('You must enter the SMTP Port'),
    content_type: yup.string().required('Please select content type'),
    smtp_encryption: yup.string().required('Please select encryption')
});

const smtp_encryptions = [
    { id: 'off', smtp_encryption_name: 'Off' },
    { id: 'SSL', smtp_encryption_name: 'SSL' },
    { id: 'TLS', smtp_encryption_name: 'TLS' }
];

const content_types = [
    { id: 'Text', content_type_name: 'Text' },
    { id: 'HTML', content_type_name: 'HTML' }
];

const EditForm = ({ listItem, onUpdate, hideDialog }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [checked, setChecked] = React.useState(listItem?.active);

    const { mutate: editSmtpMutation, isLoading } = useMutation(
        smtpsServices.updateSmtp,
        {
            onSuccess: () => {
                onUpdate();

                enqueueSnackbar('Smtps has been updated successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onEdit = async (smtp) => {
        try {
            editSmtpMutation({
                id: listItem.id,
                ...smtp
            });
        } catch (error) {}
    };

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            <Formik
                validateOnChange={true}
                initialValues={{
                    smtp_name: listItem?.smtp_name,
                    email_address: listItem?.email_address,
                    password: listItem?.password?.encryptedPassword,
                    smtp_host: listItem?.smtp_host,
                    smtp_port: listItem?.smtp_port,
                    content_type: listItem?.content_type,
                    smtp_encryption: listItem?.smtp_encryption,
                    active: checked
                }}
                validationSchema={validationSchema}
                onSubmit={async (data, { setSubmitting }) => {
                    await onEdit(data);
                }}
            >
                {({
                    isSubmitting,
                    errors,
                    touched,
                    values,
                    setFieldValue,
                    ...restProps
                }) => (
                    <Form
                        style={{ textAlign: 'left' }}
                        noValidate
                        autoComplete="off"
                    >
                        <Div sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="smtp_name"
                                        name="smtp_name"
                                        label="SMTP Name"
                                        placeholder="Enter SMTP Name"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="email_address"
                                        name="email_address"
                                        label="SMTP Email Address"
                                        placeholder="Enter email address for the email account here"
                                        inputProps={{
                                            autoComplete: 'new-password'
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="password"
                                        name="password"
                                        label="Password"
                                        placeholder="Enter the password for the email account here"
                                        type={'password'}
                                        inputProps={{
                                            autoComplete: 'new-password'
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="smtp_host"
                                        name="smtp_host"
                                        label="SMTP Host"
                                        placeholder="Enter SMTP host IP here"
                                        type={'text'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="smtp_port"
                                        name="smtp_port"
                                        label="SMTP Port"
                                        placeholder="Enter SMTP port here"
                                        type={'number'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Field name="content_type">
                                        {({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                id="content_type"
                                                width={'100%'}
                                                size="small"
                                                defaultValue={{
                                                    id: listItem?.content_type,
                                                    content_type_name:
                                                        listItem?.content_type.toUpperCase()
                                                }}
                                                options={content_types}
                                                autoHighlight
                                                getOptionLabel={(option) =>
                                                    option?.content_type_name
                                                        ? option.content_type_name
                                                        : ''
                                                }
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) => option.id === value.id}
                                                renderOption={(
                                                    props,
                                                    option
                                                ) => (
                                                    <Box
                                                        component="li"
                                                        sx={{
                                                            '& > img': {
                                                                mr: 2,
                                                                flexShrink: 0
                                                            }
                                                        }}
                                                        {...props}
                                                    >
                                                        {
                                                            option.content_type_name
                                                        }
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Choose content type"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete:
                                                                'new-password'
                                                        }}
                                                        error={
                                                            touched?.content_type &&
                                                            !!errors.content_type
                                                        }
                                                        helperText={
                                                            touched?.content_type &&
                                                            errors.content_type
                                                        }
                                                    />
                                                )}
                                                onChange={
                                                    (event, newValue) => {
                                                        values.content_type =
                                                            newValue?.id;
                                                    }
                                                    // Set the new value in the form field
                                                }
                                                value={
                                                    content_types.find(
                                                        (option) =>
                                                            option.id ===
                                                            values.content_type
                                                    ) || ''
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Field name="smtp_encryption">
                                        {({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                id="smtp_encryption"
                                                width={'100%'}
                                                size="small"
                                                options={smtp_encryptions}
                                                defaultValue={{
                                                    id: listItem?.smtp_encryption,
                                                    smtp_encryption:
                                                        listItem?.smtp_encryption.toUpperCase()
                                                }}
                                                autoHighlight
                                                getOptionLabel={(option) =>
                                                    option?.smtp_encryption_name
                                                        ? option.smtp_encryption_name
                                                        : ''
                                                }
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) => option.id === value.id}
                                                renderOption={(
                                                    props,
                                                    option
                                                ) => (
                                                    <Box
                                                        component="li"
                                                        sx={{
                                                            '& > img': {
                                                                mr: 2,
                                                                flexShrink: 0
                                                            }
                                                        }}
                                                        {...props}
                                                    >
                                                        {
                                                            option.smtp_encryption_name
                                                        }
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Choose content type"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete:
                                                                'new-password'
                                                        }}
                                                        error={
                                                            touched?.smtp_encryption &&
                                                            !!errors.smtp_encryption
                                                        }
                                                        helperText={
                                                            touched?.smtp_encryption &&
                                                            errors.smtp_encryption
                                                        }
                                                    />
                                                )}
                                                onChange={
                                                    (event, newValue) => {
                                                        values.smtp_encryption =
                                                            newValue?.id;
                                                    }
                                                    // Set the new value in the form field
                                                }
                                                value={
                                                    smtp_encryptions.find(
                                                        (option) =>
                                                            option.id ===
                                                            values.smtp_encryption
                                                    ) || ''
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    Activate as the default email sender:{' '}
                                    <Switch
                                        name="active"
                                        checked={checked}
                                        onChange={(event) => {
                                            setFieldValue(
                                                'active',
                                                event.target.checked
                                            );
                                            setChecked(event.target.checked);
                                        }}
                                        inputProps={{
                                            'aria-label': 'controlled'
                                        }}
                                        value={values.active}
                                    />{' '}
                                    (
                                    {checked ? (
                                        <em> Active </em>
                                    ) : (
                                        <em> Not Active </em>
                                    )}
                                    )
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    margin: '30px auto'
                                }}
                                container
                                alignItems="right"
                                justify="flex-end"
                                justifyContent="right"
                            >
                                <Button
                                    size="large"
                                    sx={{ mb: 3 }}
                                    onClick={() => hideDialog()}
                                >
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isLoading}
                                >
                                    Update
                                </LoadingButton>
                            </Grid>
                        </Div>
                    </Form>
                )}
            </Formik>
        </Div>
    );
};
/* Todo smtpsItem, onSave prop define */
export default EditForm;
