import React from 'react';
import Grid from '@mui/material/Grid';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import PaidIcon from '@mui/icons-material/Paid';
import FlightIcon from '@mui/icons-material/Flight';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { useMutation, useQuery } from 'react-query';
import SummaryCard from '../SummaryCard';
import { expensesServices } from 'app/services/expenses-services';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const ExpenseSummary = ({}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [summary, setSummary] = React.useState(false);

    React.useEffect(() => {
        const cancelTokenAgents = axios.CancelToken.source();
        const agentToken = { cancelToken: cancelTokenAgents.token };

        const fetchSummary = async () => {
            try {
                const res = await expensesServices.getAllExpensesSummary(
                    {},
                    agentToken
                );

                console.log(res);

                setSummary(res.summary);
            } catch (error) {
                console.log(error);
                // enqueueSnackbar(error, {
                //     variant: 'error'
                // });
            }
        };

        fetchSummary();

        return () => {
            cancelTokenAgents.cancel();
        };
    }, [expensesServices]);

    let totalSum;
    if (summary) {
        totalSum = Object.values(summary).reduce(
            (acc, currentValue) => acc + currentValue,
            0
        );
    }

    return (
        // {summary && (
        <Grid item xs={12} lg={2} sx={{ marginBottom: '20px' }}>
            <Grid container spacing={3.75}>
                <Grid item xs={12} sm={6} lg={3}>
                    <SummaryCard
                        title={'Expenses'}
                        value={totalSum}
                        icon={<AccountBalanceWalletIcon />}
                        color="#38B8F2"
                        colorFaded="#843CF6"
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <SummaryCard
                        title={'Travel'}
                        value={summary?.travel}
                        icon={<FlightIcon />}
                        color="#fd7f20"
                        colorFaded="#fed4b3"
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <SummaryCard
                        title={'Advances'}
                        value={summary?.advance}
                        icon={<PaidIcon />}
                        color="#654e53"
                        colorFaded="#b7a1a6"
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <SummaryCard
                        title={'Reports'}
                        value={summary?.purchase}
                        icon={<PriceCheckIcon />}
                        color="#366353"
                        colorFaded="#7bb7a2"
                    />
                </Grid>
            </Grid>
        </Grid>
        // )}
    );
};

export default ExpenseSummary;
