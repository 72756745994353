import React from 'react';
import _ from 'lodash';
import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import FreeAutocomplete from 'app/shared/FreeAutocomplete';

import { useMutation } from 'react-query';
import { studentServices } from 'app/services/student-services';
import { schoolsServices } from 'app/services/schools-services';
import { countriesServices } from 'app/services/countries-services';
import { educationlevelsServices } from 'app/services/educationlevels-services';
import { officeServices } from 'app/services/office-services';

import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboRadioGroup from '@jumbo/components/JumboFormik/JumboRadioGroup';
import axios from 'axios';
import {
    capitalizeAllWords,
    getCurrentDate,
    getNextYearDate
} from 'app/utils/appHelpers';

const validationSchema = yup.object({
    given_name: yup
        .string('Enter given name')
        .required('Given name is required'),
    surname: yup.string('Enter surname').required('Surname is required'),
    dob: yup
        .string('Enter date of birth')
        .required('Date of birth is required'),
    gender: yup.string('Select gender').required('Gender is required'),
    email_address: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    phone_number: yup
        .string('Enter phone number')
        // .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone number is required'),
    // nationality: yup
    //     .string('Enter nationality')
    //     .required('Nationality is required'),
    // country_of_birth: yup
    //     .string('Enter Country Of Birth')
    //     .required('Country Of Birth is required'),
    // city_of_birth: yup
    //     .string('Enter city of birth')
    //     .required('City of birth is required'),
    // city_of_residence: yup
    //     .string('Enter city of residence')
    //     .required('City of residence is required'),
    // country_of_residence: yup
    //     .string('Enter Country of residense')
    //     .required('Country of residense is required'),
    // id_no: yup.string('Enter ID No.').required('ID No. is required'),
    // passport_number: yup
    //     .string('Enter Passport number')
    //     .required('Passport number is required'),
    // passport_date_of_issue: yup
    //     .string('Enter Passport date of issue')
    //     .required('Passport date of issue is required'),
    // passport_date_of_expiry: yup
    //     .string('Enter Passport date of expiryoffice')
    //     .required('Passport date of expiryoffice is required'),
    officeId: yup.string('Select office').required('Office is required'),
    // address: yup
    //     .string('Enter residential address')
    //     .required('Residential address is required'),
    // guardian_first_name: yup
    //     .string('Enter guardian first name')
    //     .required('First name is required'),
    // guardian_last_name: yup
    //     .string('Enter guardian Last name')
    //     .required('Last name is required'),
    // guardian_email_address: yup
    //     .string('Enter guardian email address')
    //     .required('email address is required'),
    // guardian_phone_number: yup
    //     .string('Enter guardian offPhone numberice')
    //     .required('Phone number is required'),
    // guardian_profession: yup
    //     .string('Enter guardian Profession')
    //     .required('Profession is required'),
    // mother_first_name: yup
    //     .string("Enter mother's first name")
    //     .required('First name is required'),
    // mother_last_name: yup
    //     .string("Enter mother's Surname")
    //     .required('Surname is required'),
    // mother_email_address: yup
    //     .string("Enter mother's email address")
    //     .required('email address is required'),
    // mother_phone_number: yup
    //     .string("Enter mother's Phone number")
    //     .required('Phone number is required'),
    // mother_profession: yup
    //     .string("Enter mother's Profession")
    //     .required('Profession is required'),

    high_school: yup
        .string('Enter high school name')
        .nullable()
        .required('High School name is required'),
    highest_qualification: yup
        .string('Enter highest qualification')
        .required('Highest qualification is required')
});

const CreateForm = ({ item: studentItem, onSave, hideDialog, userDetails }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [fieldDob, setDob] = React.useState(dayjs(getCurrentDate(false)));
    const [issueDate, setIssueDate] = React.useState(
        dayjs(getCurrentDate(false))
    );
    const [expiryDate, setExpiryDate] = React.useState(
        dayjs(getNextYearDate(false))
    );
    const [phone, setPhone] = React.useState('+254');
    const [guardianPhone, setGuardianPhone] = React.useState('+254');
    const [motherPhone, setMotherPhone] = React.useState('+254');

    const [birthCountry, setBirthCountry] = React.useState('');
    const [residenceCountry, setResidenceCountry] = React.useState('');
    const [officeId, setOfficeId] = React.useState('');
    const [highestQualification, setHighestQualification] = React.useState('');
    const [designatedOffice, setDesignatedOffice] = React.useState('');

    const [countries, setCountries] = React.useState(false);
    const [qualifications, setQualifications] = React.useState(false);
    const [offices, setOffices] = React.useState(false);
    const [schools, setSchools] = React.useState([]);

    const { mutate: addStudentMutation, isLoading } = useMutation(
        studentServices.addStudent,
        {
            onSuccess: () => {
                onSave();
                enqueueSnackbar('Student has been created successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (student) => {
        try {
            addStudentMutation({ ...student });
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelTokenCountries = axios.CancelToken.source();
        const cTokenCountries = { cancelToken: cancelTokenCountries.token };
        const fetchCountries = async () => {
            try {
                const res = await countriesServices.getAllCountries(
                    cTokenCountries
                );

                setCountries(res.countries);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchCountries();

        return () => {
            cancelTokenCountries.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenEducationlevels = axios.CancelToken.source();
        const cTokenEducationlevels = {
            cancelToken: cancelTokenEducationlevels.token
        };
        const fetchEducationlevels = async () => {
            try {
                const res = await educationlevelsServices.getAllEducationlevels(
                    cTokenEducationlevels
                );
                setQualifications(res.educationlevels);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchEducationlevels();

        return () => {
            cancelTokenEducationlevels.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenOffices = axios.CancelToken.source();
        const cTokenOffices = { cancelToken: cancelTokenOffices.token };
        const fetchOffices = async () => {
            try {
                const res = await officeServices.getAllOffices(cTokenOffices);
                let finalOffices = res.offices;

                if (
                    userDetails?.officeIds &&
                    userDetails?.role_name !== 'administrator'
                ) {
                    finalOffices = _.filter(res.offices, (office) =>
                        _.includes(userDetails.officeIds, office.id)
                    );
                }

                setOffices(finalOffices);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchOffices();

        return () => {
            cancelTokenOffices.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenSchools = axios.CancelToken.source();
        const cTokenSchools = { cancelToken: cancelTokenSchools.token };
        const fetchSchools = async () => {
            try {
                const res = await schoolsServices.getAllSchools(cTokenSchools);
                let finalSchools = res.schools;

                setSchools(finalSchools);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchSchools();

        return () => {
            cancelTokenSchools.cancel();
        };
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {(!countries || !offices || !qualifications) && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {countries && offices && qualifications && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        given_name: '',
                        surname: '',
                        dob: fieldDob,
                        gender: '',
                        email_address: '',
                        phone_number: phone,
                        nationality: '',
                        country_of_birth: birthCountry,
                        city_of_birth: '',
                        country_of_residence: residenceCountry,
                        city_of_residence: '',
                        id_no: '',
                        passport_number: '',
                        passport_date_of_issue: issueDate,
                        passport_date_of_expiry: expiryDate,
                        officeId: officeId,
                        address: '',
                        guardian_first_name: '',
                        guardian_last_name: '',
                        guardian_email_address: '',
                        guardian_phone_number: '',
                        guardian_profession: '',
                        mother_first_name: '',
                        mother_last_name: '',
                        mother_email_address: '',
                        mother_phone_number: '',
                        mother_profession: '',
                        high_school: '',
                        highest_qualification: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onCreate(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            {/* <Div>{values.high_school}</Div> */}
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <JumboSelectField
                                            width="100%"
                                            size={'small'}
                                            id="officeId"
                                            name="officeId"
                                            label="Select Office"
                                            value={designatedOffice}
                                            placeholder="Select office"
                                            onChange={(event) =>
                                                setDesignatedOffice(
                                                    event.target.value
                                                )
                                            }
                                        >
                                            <MenuItem key={'officeId'} value="">
                                                <em>Select Office</em>
                                            </MenuItem>

                                            {offices.map((office) => (
                                                <MenuItem
                                                    key={office.id}
                                                    value={office.id}
                                                >
                                                    {capitalizeAllWords(
                                                        office.office_name
                                                    )}
                                                </MenuItem>
                                            ))}
                                        </JumboSelectField>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="given_name"
                                            name="given_name"
                                            label="Given Name"
                                            placeholder="Enter first name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="surname"
                                            name="surname"
                                            label="Surname"
                                            placeholder="Enter surname"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="email_address"
                                            name="email_address"
                                            label="Email Address"
                                            placeholder="Enter email address"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MuiTelInput
                                            fullWidth
                                            size={'small'}
                                            id="phone_number"
                                            name="phone_number"
                                            label="Phone Number"
                                            value={phone}
                                            placeholder="Enter phone number"
                                            preferredCountries={['KE', 'ZM']}
                                            defaultCountry="KE"
                                            onChange={(newValue) => {
                                                setPhone(newValue);
                                                values.phone_number = newValue;
                                            }}
                                        />
                                        {touched.phone_number &&
                                        errors.phone_number ? (
                                            <FormHelperText
                                                style={{
                                                    color: 'red'
                                                }}
                                            >
                                                {errors.phone_number}
                                            </FormHelperText>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <MobileDatePicker
                                                label="Date Of Birth"
                                                inputFormat="DD/MM/YYYY"
                                                value={fieldDob}
                                                onChange={(newValue) => {
                                                    setDob(newValue);
                                                    values.dob = newValue;
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <JumboTextField
                                                            fullWidth
                                                            size={'small'}
                                                            value={fieldDob}
                                                            name="dob"
                                                            {...params}
                                                        />
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboRadioGroup
                                            row
                                            labelid="gender-row"
                                            label="Gender :"
                                            aria-labelledby="gender-row"
                                            name="gender"
                                        >
                                            <FormControlLabel
                                                value="male"
                                                control={<Radio size="small" />}
                                                label="Male"
                                            />
                                            <FormControlLabel
                                                value="female"
                                                control={<Radio size="small" />}
                                                label="Female"
                                            />
                                        </JumboRadioGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <Field name="country_of_birth">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    id="country_of_birth"
                                                    sx={{
                                                        width: 500,
                                                        maxWidth: '100%'
                                                    }}
                                                    size="small"
                                                    options={countries}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.country_name
                                                            ? option.country_name
                                                            : null
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                '& > img': {
                                                                    mr: 2,
                                                                    flexShrink: 0
                                                                }
                                                            }}
                                                            {...props}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                                                                alt=""
                                                            />
                                                            {
                                                                option?.country_name
                                                            }
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Choose country of birth"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.country_of_birth &&
                                                                !!errors.country_of_birth
                                                            }
                                                            helperText={
                                                                touched?.country_of_birth &&
                                                                errors.country_of_birth
                                                            }
                                                        />
                                                    )}
                                                    onChange={
                                                        (event, newValue) => {
                                                            values.country_of_birth =
                                                                newValue?.id;
                                                        }
                                                        // Set the new value in the form field
                                                    }
                                                    value={
                                                        countries.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.country_of_birth
                                                        ) || null
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="city_of_birth"
                                            name="city_of_birth"
                                            label="City Of Birth"
                                            placeholder="Enter city of birth"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Field name="country_of_residence">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    id="country_of_residence"
                                                    sx={{
                                                        width: 500,
                                                        maxWidth: '100%'
                                                    }}
                                                    size="small"
                                                    options={countries}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.country_name
                                                            ? option.country_name
                                                            : null
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                '& > img': {
                                                                    mr: 2,
                                                                    flexShrink: 0
                                                                }
                                                            }}
                                                            {...props}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                                                                alt=""
                                                            />
                                                            {
                                                                option?.country_name
                                                            }
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Choose country of residence"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.country_of_residence &&
                                                                !!errors.country_of_residence
                                                            }
                                                            helperText={
                                                                touched?.country_of_residence &&
                                                                errors.country_of_residence
                                                            }
                                                        />
                                                    )}
                                                    onChange={
                                                        (event, newValue) => {
                                                            values.country_of_residence =
                                                                newValue?.id;
                                                        }
                                                        // Set the new value in the form field
                                                    }
                                                    value={
                                                        countries.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.country_of_residence
                                                        ) || null
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="city_of_residence"
                                            name="city_of_residence"
                                            label="City Of residence"
                                            placeholder="Enter residential city"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="nationality"
                                            name="nationality"
                                            label="Nationality"
                                            placeholder="Enter nationality"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="address"
                                            name="address"
                                            label="Residential address"
                                            placeholder="Enter residential physical address"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="id_no"
                                            name="id_no"
                                            label="ID No."
                                            placeholder="Enter ID Number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="passport_number"
                                            name="passport_number"
                                            label="Passport Number"
                                            placeholder="Enter Passport Number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <MobileDatePicker
                                                label="Passport Issue Date"
                                                inputFormat="DD/MM/YYYY"
                                                value={issueDate}
                                                onChange={(newValue) => {
                                                    setIssueDate(newValue);
                                                    values.passport_issue_date =
                                                        newValue;
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <JumboTextField
                                                            fullWidth
                                                            size={'small'}
                                                            value={issueDate}
                                                            name="passport_issue_date"
                                                            {...params}
                                                        />
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <MobileDatePicker
                                                label="Passport expiry Date"
                                                inputFormat="DD/MM/YYYY"
                                                value={expiryDate}
                                                onChange={(newValue) => {
                                                    setExpiryDate(newValue);
                                                    values.passport_expiry_date =
                                                        newValue;
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <JumboTextField
                                                            fullWidth
                                                            size={'small'}
                                                            value={expiryDate}
                                                            name="passport_expiry_date"
                                                            {...params}
                                                        />
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FreeAutocomplete
                                            fullWidth
                                            size={'small'}
                                            id="high_school"
                                            name="high_school"
                                            field_name="school_name"
                                            label="High School Attended"
                                            options={schools}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="high_school"
                                            name="high_school"
                                            label="High School Attended"
                                            placeholder="Enter attended high school name"
                                        />
                                    </Grid> */}

                                    <Grid item xs={12} md={6}>
                                        <JumboSelectField
                                            width="100%"
                                            size={'small'}
                                            id="highest_qualification"
                                            name="highest_qualification"
                                            label="Select highest qualification"
                                            value={highestQualification}
                                            placeholder="Select highest qualification"
                                            onChange={(event) =>
                                                setHighestQualification(
                                                    event.target.value
                                                )
                                            }
                                        >
                                            <MenuItem
                                                key={'highest_qualification'}
                                                value=""
                                            >
                                                <em>
                                                    Select Highest Qualification
                                                </em>
                                            </MenuItem>

                                            {qualifications.map((level) => (
                                                <MenuItem
                                                    key={level.id}
                                                    value={level.id}
                                                >
                                                    {level.education_level_name}
                                                </MenuItem>
                                            ))}
                                        </JumboSelectField>
                                    </Grid>
                                </Grid>
                                <Divider
                                    component={'div'}
                                    sx={{ margin: '8px auto' }}
                                />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={2}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="guardian_first_name"
                                            name="guardian_first_name"
                                            label="Father's/Guardian's First Name"
                                            placeholder="First name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="guardian_last_name"
                                            name="guardian_last_name"
                                            label="Father's/Guardian's Last Name"
                                            placeholder="Last name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="guardian_email_address"
                                            name="guardian_email_address"
                                            label="Father's/Guardian's Email"
                                            placeholder="Email address"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <MuiTelInput
                                            fullWidth
                                            size={'small'}
                                            id="guardian_phone_number"
                                            name="guardian_phone_number"
                                            label="Father's/Guardian's Phone No."
                                            value={guardianPhone}
                                            placeholder="Phone number"
                                            preferredCountries={['KE', 'ZM']}
                                            defaultCountry="KE"
                                            onChange={(newValue) => {
                                                setGuardianPhone(newValue);
                                                values.guardian_phone_number =
                                                    newValue;
                                            }}
                                        />
                                        {touched.guardian_phone_number &&
                                        errors.guardian_phone_number ? (
                                            <FormHelperText
                                                style={{
                                                    color: 'red'
                                                }}
                                            >
                                                {errors.guardian_phone_number}
                                            </FormHelperText>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="guardian_profession"
                                            name="guardian_profession"
                                            label="Guardian's Profession"
                                            placeholder="Profession"
                                        />
                                    </Grid>
                                </Grid>
                                <Divider
                                    component={'div'}
                                    sx={{ margin: '8px auto' }}
                                />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={2}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="mother_first_name"
                                            name="mother_first_name"
                                            label="Mother's First Name"
                                            placeholder="First name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="mother_last_name"
                                            name="mother_last_name"
                                            label="Mother's Last Name"
                                            placeholder="Last name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="mother_email_address"
                                            name="mother_email_address"
                                            label="Mother's Email"
                                            placeholder="Email address"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <MuiTelInput
                                            fullWidth
                                            size={'small'}
                                            id="mother_phone_number"
                                            name="mother_phone_number"
                                            label="Mother's Phone No."
                                            value={motherPhone}
                                            placeholder="Phone number"
                                            preferredCountries={['KE', 'ZM']}
                                            defaultCountry="KE"
                                            onChange={(newValue) => {
                                                setMotherPhone(newValue);
                                                values.mother_phone_number =
                                                    newValue;
                                            }}
                                        />
                                        {touched.mother_phone_number &&
                                        errors.mother_phone_number ? (
                                            <FormHelperText
                                                style={{
                                                    color: 'red'
                                                }}
                                            >
                                                {errors.mother_phone_number}
                                            </FormHelperText>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="mother_profession"
                                            name="mother_profession"
                                            label="Mother's Profession"
                                            placeholder="Profession"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                    >
                                        Create Student Account
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo studentItem, onSave prop define */
export default CreateForm;
