const today = new Date();
const currentYear = today.getFullYear();

console.log(new Date(currentYear, 3, 0));

export const calendarData = {
    events: [
        {
            title: 'John Doe',
            allDay: true,
            start: new Date(currentYear, 3, 0),
            end: new Date(currentYear, 3, 1)
        },
        {
            title: 'Michael Mehes',
            allDay: true,
            start: new Date(currentYear, 3, 0),
            end: new Date(currentYear, 5, 1)
        }
    ]
};

export const cultures = [
    { id: 'en', title: 'en' },
    { id: 'en-GB', title: 'en-GB' },
    { id: 'es', title: 'es' },
    { id: 'fr', title: 'fr' },
    { id: 'ar-AE', title: 'ar-AE' }
];
