import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const reportsServices = {};

reportsServices.getAllReports = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/reports',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

reportsServices.getReports = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/reports', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });

    console.log(data);

    return data;
};

reportsServices.getReportDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/reports/${id}`, {
        id: id
    });
    console.log('report');
    console.log(data);
    console.log(data.report);
    // console.log(JSON.parse(data.report));
    // console.log(data.report.first_name);

    return data;
};

reportsServices.addReport = async (report) => {
    const config = !report?.files
        ? {}
        : {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          };

    const { data } = await jwtAuthAxios.post(
        `/reports`,
        report.formikData,
        config
    );
    return data;
};

reportsServices.approveReport = async (report) => {
    const { data } = await jwtAuthAxios.patch(
        `/reports/approve/${report.reportId}`,
        report
    );
    return data;
};

reportsServices.issueReport = async (report) => {
    const { data } = await jwtAuthAxios.patch(
        `/reports/issue/${report.reportId}`,
        report
    );
    return data;
};

reportsServices.updateReport = async (report) => {
    const { data } = await jwtAuthAxios.patch(
        `/reports/${report.reportId}`,
        report
    );
    return data;
};

reportsServices.cancelReport = async (report) => {
    const { data } = await jwtAuthAxios.patch(
        `/reports/cancel/${report.reportId}`,
        report
    );
    return data;
};

reportsServices.deleteReport = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/reports/${id}`, {
        id: id
    });
    return data;
};

reportsServices.deleteMultipleReports = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/reports`, {
        data: ids
    });
    return data;
};

export { reportsServices };
