import React from 'react';
import { useParams } from 'react-router-dom';
import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList';
import { surveyService } from '../../../../../services/survey-services';
import SurveyItem from './SurveyItem';
import JumboListToolbar from '@jumbo/components/JumboList/components/JumboListToolbar';
import BulkActions from './BulkActions';
import { Card } from '@mui/material';
import JumboSearch from '@jumbo/components/JumboSearch';
import useSurveysApp from '../../hooks/useSurveysApp';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import ListIcon from '@mui/icons-material/List';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';

const SurveysList = () => {
    const params = useParams();
    const listRef = React.useRef();
    const { refreshSurveysList, setSurveysListRefresh, setSelectedSurveys } =
        useSurveysApp();
    const [view, setView] = React.useState('list');

    const [queryOptions, setQueryOptions] = React.useState({
        queryKey: 'surveys',
        queryParams: { category: params.category, id: params.id },
        countKey: 'count',
        dataKey: 'surveysList'
    });

    React.useEffect(() => {
        setQueryOptions((state) => ({
            ...state,
            queryParams: {
                ...state.queryParams,
                category: params.category,
                id: params.id
            }
        }));
    }, [params]);

    const renderSurvey = React.useCallback(
        (survey) => {
            return <SurveyItem survey={survey} view={view} />;
        },
        [view]
    );

    React.useEffect(() => {
        if (refreshSurveysList) {
            listRef.current.refresh();
            setSurveysListRefresh(false);
        }
    }, [refreshSurveysList]);

    const handleOnChange = React.useCallback((keywords) => {
        setQueryOptions((state) => ({
            ...state,
            queryParams: {
                ...state.queryParams,
                keywords: keywords
            }
        }));
    }, []);
    return (
        <JumboRqList
            ref={listRef}
            wrapperComponent={Card}
            service={surveyService.getSurveys}
            primaryKey={'id'}
            queryOptions={queryOptions}
            itemsPerPage={8}
            itemsPerPageOptions={[8, 15, 20]}
            renderItem={renderSurvey}
            componentElement={'div'}
            sx={view === 'grid' ? { p: (theme) => theme.spacing(1, 3, 3) } : {}}
            wrapperSx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column'
            }}
            toolbar={
                <JumboListToolbar
                    hideItemsPerPage={true}
                    bulkActions={<BulkActions />}
                    actionTail={<></>}
                >
                    <JumboSearch
                        onChange={handleOnChange}
                        sx={{
                            display: { xs: 'none', md: 'block' }
                        }}
                    />
                </JumboListToolbar>
            }
            onSelectionChange={setSelectedSurveys}
            view={view}
        />
    );
};

export default SurveysList;
