import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const advancesServices = {};

advancesServices.getAllAdvances = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/advances',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

advancesServices.getAdvances = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/advances', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });

    console.log(data);

    return data;
};

advancesServices.getAdvanceDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/advances/${id}`, {
        id: id
    });
    // console.log('service');
    // console.log(data);
    // console.log(data.advance.first_name);
    // console.log(JSON.parse(data.advance));
    // console.log(data.advance.first_name);

    return data;
};

advancesServices.addAdvance = async (advance) => {
    const config = !advance?.files
        ? {}
        : {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          };

    const { data } = await jwtAuthAxios.post(
        `/advances`,
        advance.formikData,
        config
    );
    return data;
};

advancesServices.approveAdvance = async (advance) => {
    const { data } = await jwtAuthAxios.patch(
        `/advances/approve/${advance.advanceId}`,
        advance
    );
    return data;
};

advancesServices.issueAdvance = async (advance) => {
    const { data } = await jwtAuthAxios.patch(
        `/advances/issue/${advance.advanceId}`,
        advance
    );
    return data;
};

advancesServices.updateAdvance = async (advance) => {
    const { data } = await jwtAuthAxios.patch(
        `/advances/${advance.advanceId}`,
        advance
    );
    return data;
};

advancesServices.cancelAdvance = async (advance) => {
    const { data } = await jwtAuthAxios.patch(
        `/advances/cancel/${advance.advanceId}`,
        advance
    );
    return data;
};

advancesServices.deleteAdvance = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/advances/${id}`, {
        id: id
    });
    return data;
};

advancesServices.deleteMultipleAdvances = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/advances`, {
        data: ids
    });
    return data;
};

export { advancesServices };
