import React from 'react';
import { useField } from 'formik';
import FormHelperText from '@mui/material/FormHelperText';
import { FormControl, InputLabel } from '@mui/material';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import Select from '@mui/material/Select';

const JumboSelectField = (props) => {
    const { theme } = useJumboTheme();
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    return (
        <React.Fragment>
            <FormControl sx={{ width: props.width }} size="small">
                <InputLabel id={props.id}>{props.label}</InputLabel>
                <Select {...props} {...field} error={!!errorText} />
                {/* {props.children} */}
                {!props.disabled && (
                    <FormHelperText style={{ color: theme.palette.error.main }}>
                        {errorText}
                    </FormHelperText>
                )}
            </FormControl>
        </React.Fragment>
    );
};

export default JumboSelectField;
