import React from 'react';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SchoolIcon from '@mui/icons-material/School';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FolderItem from './FolderItem';
import { useParams } from 'react-router-dom';
import StyledMenu from '../../../../../shared/StyledMenu';

const folders = [
    {
        icon: <FeedbackIcon fontSize={'small'} />,
        label: 'All Surveys',
        slug: 'all',
        path: '/app/surveys/all'
    },
    {
        icon: <SchoolIcon fontSize={'small'} />,
        label: 'Enrollments Surveys',
        slug: 'enrollment',
        path: '/app/surveys/enrollment'
    },
    {
        icon: <EventAvailableIcon fontSize={'small'} />,
        label: 'Events/Trainings Surveys',
        slug: 'event',
        path: '/app/surveys/event'
    }
];

const FoldersList = () => {
    const params = useParams();
    return (
        <StyledMenu sx={{ mb: 2 }}>
            {folders.map((folder) => (
                <FolderItem
                    key={folder.slug}
                    path={folder.path}
                    label={folder.label}
                    icon={folder.icon}
                    selected={folder.slug === params?.category}
                />
            ))}
        </StyledMenu>
    );
};

export default React.memo(FoldersList);
