import React from 'react';

import Grid from '@mui/material/Grid';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Alert, Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { useMutation } from 'react-query';
import { approvalsServices } from 'app/services/approvals-services';
import { employeeServices } from 'app/services/employee-services';
import { requirementServices } from 'app/services/requirement-services';

import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import axios from 'axios';

import CircularProgress from '@mui/material/CircularProgress';

import _ from 'lodash';

const validationSchema = yup.object({
    module_name: yup
        .string('Enter display name')
        .required('Display name is required'),
    module: yup.string('Enter module').required('module is required'),
    // description: yup.string('Enter expense approval description').required('module is required'),
    admins: yup
        .array('Please select expense admins')
        .of(
            yup.object().shape({
                id: yup.string(),
                first_name: yup.string()
            })
        )
        .min(1, 'Please select atleast one admin')
        .required('Please select  atleast one admin')
        .typeError('Please select an option from the provided list'),
    approval_type: yup
        .string('Enter approval type')
        .required('Approval type is required')
    // description: yup
    //     .string('Enter approval description')
    //     .required('Approval description is required is required')
});

const APPROVAL_TYPES = [
    { id: 'NO_APPROVAL', approval_type: 'No Approval' },
    { id: 'ADMIN', approval_type: 'Admin Approval' },
    { id: 'HIERARCHICAL', approval_type: 'Hierarchical' },
    { id: 'CUSTOM', category_name: 'CUSTOM' }
];

const APPROVAL_LEVELS = [
    { id: 1, approval_level: 'One Level' },
    { id: 2, approval_level: 'Two Levels' },
    { id: 3, approval_level: 'Three Levels' }
];

const CreateForm = ({ onSave, hideDialog }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [employees, setEmployees] = React.useState(false);

    const { mutate: addApprovalMutation, isLoading } = useMutation(
        approvalsServices.addApproval,
        {
            onSuccess: () => {
                onSave();
                enqueueSnackbar('Approval has been created successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (approval) => {
        console.log(approval);
        if (
            approval.approval_type === 'hierarchical' &&
            approval.approval_level === ''
        ) {
            enqueueSnackbar(
                'Please select levels of approval (no of managers in the hierachy)',
                {
                    variant: 'success'
                }
            );
            return true;
        }

        if (
            approval.module === 'travel' &&
            (approval.per_diem === '' || approval.per_diem === 0)
        ) {
            enqueueSnackbar('Please select enter the per diem value', {
                variant: 'success'
            });
            return true;
        }

        // return;

        try {
            addApprovalMutation({ ...approval });
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };
        const fetchEmployees = async () => {
            try {
                const res = await employeeServices.getAllEmployees(cToken);

                const finalOptions = _.map(res.employeesList, (employee) => {
                    const {
                        id,
                        first_name,
                        last_name,
                        email_address,
                        phone_number
                    } = employee;

                    return {
                        id,
                        first_name,
                        last_name,
                        email_address,
                        phone_number
                    };
                });

                console.log('finalOptions');
                console.log(finalOptions);

                setEmployees(finalOptions);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchEmployees();

        return () => {
            cancelToken.cancel();
        };
    }, []);

    console.log('Employees');
    console.log(employees);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {!employees && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {employees && employees.length === 0 && (
                <Alert variant="warning">Missing Employees data</Alert>
            )}

            {employees && employees.length > 0 && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        module_name: '',
                        module: '',
                        approval_type: '',
                        approval_level: '',
                        admins: []
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onCreate(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            {/* <div>{JSON.stringify(errors)}</div>
                            <div>{JSON.stringify(values.admins)}</div> */}
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="module"
                                            name="module"
                                            label="Module"
                                            placeholder="Enter Module Name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="module_name"
                                            name="module_name"
                                            label="Display Name"
                                            placeholder="Enter Module Display name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Field name="admins">
                                            {({ field, form }) => (
                                                <Autocomplete
                                                    {...field}
                                                    multiple
                                                    id="admins"
                                                    sx={{
                                                        width: '100%',
                                                        maxWidth: '100%'
                                                    }}
                                                    size="small"
                                                    options={employees}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.first_name
                                                            ? `${option.first_name} ${option.last_name}`
                                                            : ''
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            {...props}
                                                        >
                                                            {`${option.first_name} ${option.last_name} - ${option.email_address}`}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Admins"
                                                            placeholder="Choose admin"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                form.touched
                                                                    .admins &&
                                                                !!form.errors
                                                                    .admins
                                                            }
                                                            helperText={
                                                                form.touched
                                                                    .admins &&
                                                                form.errors
                                                                    .admins
                                                            }
                                                        />
                                                    )}
                                                    onChange={(
                                                        event,
                                                        newValue
                                                    ) => {
                                                        form.setFieldValue(
                                                            'admins',
                                                            newValue
                                                        );
                                                    }}
                                                    value={form.values.admins}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    {values.module === 'travel' && (
                                        <Grid item xs={12} md={12}>
                                            <JumboTextField
                                                fullWidth
                                                size={'small'}
                                                id="per_diem"
                                                name="per_diem"
                                                label="Per Diem (KES)"
                                                placeholder="Enter Per Diem"
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12} md={12}>
                                        <Field name="approval_type">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    id="approval_type"
                                                    sx={{
                                                        width: '100%',
                                                        maxWidth: '100%'
                                                    }}
                                                    size="small"
                                                    options={APPROVAL_TYPES}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.approval_type
                                                            ? option.approval_type
                                                            : ''
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            {...props}
                                                        >
                                                            {
                                                                option.approval_type
                                                            }
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Approval Type"
                                                            placeholder="Choose approval type"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.approval_type &&
                                                                !!errors.approval_type
                                                            }
                                                            helperText={
                                                                touched?.approval_type &&
                                                                errors.approval_type
                                                            }
                                                        />
                                                    )}
                                                    onChange={
                                                        (event, newValue) => {
                                                            values.approval_type =
                                                                newValue?.id;
                                                        }
                                                        // Set the new value in the form field
                                                    }
                                                    value={
                                                        APPROVAL_TYPES.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.approval_type
                                                        ) || ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    {values.approval_type ===
                                        'HIERARCHICAL' && (
                                        <Grid item xs={12} md={12}>
                                            <Field name="approval_level">
                                                {({ field }) => (
                                                    <Autocomplete
                                                        {...field}
                                                        id="approval_level"
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: '100%'
                                                        }}
                                                        size="small"
                                                        options={
                                                            APPROVAL_LEVELS
                                                        }
                                                        autoHighlight
                                                        getOptionLabel={(
                                                            option
                                                        ) =>
                                                            option?.approval_level
                                                                ? option.approval_level
                                                                : ''
                                                        }
                                                        isOptionEqualToValue={(
                                                            option,
                                                            value
                                                        ) =>
                                                            option.id ===
                                                            value.id
                                                        }
                                                        renderOption={(
                                                            props,
                                                            option
                                                        ) => (
                                                            <Box
                                                                component="li"
                                                                {...props}
                                                            >
                                                                {
                                                                    option.approval_level
                                                                }
                                                            </Box>
                                                        )}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Approval Levels"
                                                                placeholder="Choose approval level"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    autoComplete:
                                                                        'new-password'
                                                                }}
                                                                error={
                                                                    touched?.approval_level &&
                                                                    !!errors.approval_level
                                                                }
                                                                helperText={
                                                                    touched?.approval_level &&
                                                                    errors.approval_level
                                                                }
                                                            />
                                                        )}
                                                        onChange={
                                                            (
                                                                event,
                                                                newValue
                                                            ) => {
                                                                values.approval_level =
                                                                    newValue?.id;
                                                            }
                                                            // Set the new value in the form field
                                                        }
                                                        value={
                                                            APPROVAL_LEVELS.find(
                                                                (option) =>
                                                                    option.id ===
                                                                    values.approval_level
                                                            ) || ''
                                                        }
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                    >
                                        Save
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo approvalItem, onSave prop define */
export default CreateForm;
