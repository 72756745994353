import React, { useState } from 'react';
import _ from 'lodash';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { useField, useFormikContext } from 'formik';

export default function ContactAutocomplete({
    label = 'Recipients',
    name,
    prefix,
    contacts
}) {
    const { setFieldValue } = useFormikContext();

    const [field, meta, helpers] = useField(name);
    const errorText = meta.error && meta.touched ? meta.error : '';

    return (
        <Autocomplete
            {...field}
            multiple
            id={name}
            options={_.map(
                contacts,
                (contact) =>
                    `${
                        contact?.name
                            ? `${contact?.name} <${contact?.email_address}>`
                            : `${contact?.email_address}`
                    } `
            )}
            // defaultValue={field.value || []}
            value={field.value || []}
            getOptionLabel={(option) => option || ''}
            freeSolo
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                        size="small"
                    />
                ))
            }
            onChange={(_, newValue) => {
                helpers.setValue(newValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label={label}
                    placeholder="Type email and press enter or select from the options"
                    error={meta?.touched && meta?.error}
                    helperText={errorText}
                    // InputProps={{
                    //     startAdornment: (
                    //         <InputAdornment position="start">
                    //             {prefix}
                    //         </InputAdornment>
                    //     )
                    // }}
                />
            )}
        />
    );
}
