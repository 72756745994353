import jwtAuthAxios from './auth/jwtAuth';

const dashboardsServices = {};

dashboardsServices.getDashboardSummary = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/dashboards/summary',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

dashboardsServices.getPopularAgents = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/dashboards/popular-agents',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

dashboardsServices.getLeadsSummary = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/dashboards/leads-summary',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

dashboardsServices.getDepositsSummary = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/dashboards/deposits-summary',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

export { dashboardsServices };
