import React from 'react';
import Avatar from '@mui/material/Avatar';
import ContentHeader from 'app/layouts/shared/headers/ContentHeader';
import List from '@mui/material/List';
import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { Chip } from '@mui/material';

const Item = styled('div')({
    textAlign: 'center'
});

const Header = ({ studentDetails }) => {
    const navigate = useNavigate();
    const profilePic = studentDetails?.profile_pic
        ? process.env.REACT_APP_BACKEND + '/' + studentDetails?.profile_pic
        : null;

    const handleClick = () => {
        navigate('/home/students');
    };

    return (
        <ContentHeader
            back={
                <Chip
                    icon={<ArrowBackIosIcon />}
                    label="Back to Students"
                    color="secondary"
                    variant="outlined"
                    onClick={handleClick}
                />
            }
            avatar={
                <Avatar
                    sx={{ width: 72, height: 72 }}
                    alt={
                        studentDetails?.given_name +
                        ' ' +
                        studentDetails?.surname
                    }
                    src={profilePic}
                />
            }
            title={studentDetails?.given_name + ' ' + studentDetails?.surname}
            subheader={
                <Typography
                    fontSize={12}
                    variant={'body1'}
                    color={'inherit'}
                    mt={0.5}
                >
                    {/* {studentDetails?.roleId?.role_name} */}
                </Typography>
            }
            children={
                <Stack
                    direction={'row'}
                    justifyContent={'space-evenly'}
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                    sx={{
                        mx: 1
                    }}
                >
                    <Item>
                        <Typography variant={'h6'} color={'inherit'} mb={0}>
                            {studentDetails?.visa_applications
                                ? studentDetails?.visa_applications.length
                                : 0}
                        </Typography>
                        <Typography variant={'body1'} fontSize={12}>
                            Visa Applications
                        </Typography>
                    </Item>
                    <Item>
                        <Typography variant={'h6'} color={'inherit'} mb={0}>
                            {studentDetails?.uni_applications
                                ? studentDetails?.uni_applications.length
                                : 0}
                        </Typography>
                        <Typography variant={'body1'} fontSize={12}>
                            Uni Applications
                        </Typography>
                    </Item>
                </Stack>
            }
            tabs={
                <List
                    disablePadding
                    sx={{
                        display: 'flex',
                        minWidth: 0
                    }}
                >
                    {/* <StyledMenuItem>Timeline</StyledMenuItem>
                    <StyledMenuItem>About</StyledMenuItem>
                    <StyledMenuItem>Photos</StyledMenuItem>
                    <StyledMenuItem>Friends</StyledMenuItem>
                    <StyledMenuItem>More</StyledMenuItem> */}
                </List>
            }
            action={
                ''
                // <Button
                //     disableRipple
                //     variant="text"
                //     startIcon={<SettingsIcon />}
                //     sx={{
                //         color: 'inherit',
                //         textTransform: 'none',
                //         '&:hover': {
                //             backgroundColor: 'transparent'
                //         }
                //     }}
                // >
                //     Settings
                // </Button>
            }
            sx={{
                position: 'relative',
                zIndex: 1,

                '& .MuiCardHeader-action': {
                    alignSelf: 'center'
                }
            }}
        />
    );
};

export default Header;
