const LeadStatus = [
    {
        id: 0,
        status: 'Inactive',
        name: 'Inactive',
        slug: 'Inactive',
        message: 'Lead is Inactive',
        color: '#E0CDFF'
    },
    {
        id: 1,
        status: 'Prospect',
        name: 'Prospect',
        slug: 'Prospect',
        message: 'Lead in Prospect Status',
        color: '#E0CDFF'
    },
    {
        id: 2,
        status: 'Validation',
        name: 'Validation',
        slug: 'Validation',
        message: 'Lead Info under Validation',
        color: '#ffd0cd'
    },
    {
        id: 3,
        status: 'Consulting',
        name: 'Consulting',
        slug: 'Consulting',
        message: 'Agents are consulting',
        color: '#E0CDFF'
    },
    {
        id: 4,
        status: 'Uni Application',
        name: 'Uni Application',
        slug: 'Uni Application',
        message: 'College Application Under way',
        color: '#E0CDFF'
    },
    {
        id: 4.1,
        status: 'Uni Application Submitted',
        name: 'Uni Application Submitted',
        slug: 'Uni Application Submitted',
        message: '',
        color: '#ffd0cd',
        hide: true
    },
    {
        id: 4.2,
        status: 'Uni Application Resubmitted',
        name: 'Uni Application Resubmitted',
        slug: 'Uni Application Resubmitted',
        message: '',
        color: '#cdffcf'
    },
    {
        id: 4.3,
        status: 'Uni Application Rejected',
        name: 'Uni Application Rejected',
        slug: 'Uni Application Rejected',
        message: '',
        color: '#ffd0cd'
    },
    {
        id: 4.4,
        status: 'Uni Application Approved',
        name: 'Uni Application Approved',
        slug: 'Uni Application Approved',
        message: '',
        color: '#cdffcf'
    },
    {
        id: 4.5,
        status: 'Tuition Deposit Payment',
        name: 'Tuition Deposit Payment',
        slug: 'Tuition Deposit Payment',
        message: '',
        color: '#E0CDFF'
    },
    {
        id: 4.6,
        status: 'Tuition Deposit Paid',
        name: 'Tuition Deposit Paid',
        slug: 'Tuition Deposit Paid',
        message: '',
        color: '#cdffcf'
    },
    {
        id: 5,
        status: 'Visa Application',
        name: 'Visa Application',
        slug: 'Visa Application',
        message: '',
        color: '#E0CDFF'
    },
    {
        id: 5.1,
        status: 'Visa Application Submitted',
        name: 'Visa Application Submitted',
        slug: 'Visa Application Submitted',
        message: '',
        color: '#ffd0cd',
        hide: true
    },
    {
        id: 5.2,
        status: 'Visa Application Resubmitted',
        name: 'Visa Application Resubmitted',
        slug: 'Visa Application Resubmitted',
        message: '',
        color: '#E0CDFF'
    },
    {
        id: 5.3,
        status: 'Visa Application Rejected',
        name: 'Visa Application Rejected',
        slug: 'Visa Application Rejected',
        message: '',
        color: '#ffd0cd'
    },
    {
        id: 5.4,
        status: 'Visa Application Accepted',
        name: 'Visa Application Accepted',
        slug: 'Visa Application Accepted',
        message: '',
        color: '#cdffcf'
    },
    {
        id: 6,
        status: 'Accomodation Application',
        name: 'Accomodation Application',
        slug: 'Accomodation Application',
        message: '',
        color: '#E0CDFF',
        hide: true
    },
    {
        id: 6.1,
        status: 'Accomodation Application Submitted',
        name: 'Accomodation Application Submitted',
        slug: 'Accomodation Application Submitted',
        message: '',
        color: '#ffd0cd',
        hide: true
    },
    {
        id: 6.2,
        status: 'Accomodation Application Resubmitted',
        name: 'Accomodation Application Resubmitted',
        slug: 'Accomodation Application Resubmitted',
        message: '',
        color: '#E0CDFF',
        hide: true
    },
    {
        id: 6.3,
        status: 'Accomodation Application Rejected',
        name: 'Accomodation Application Rejected',
        slug: 'Accomodation Application Rejected',
        message: '',
        color: '#ffd0cd',
        hide: true
    },
    {
        id: 6.4,
        status: 'Accomodation Application Approved',
        name: 'Accomodation Application Approved',
        slug: 'Accomodation Application Approved',
        message: '',
        color: '#cdffcf',
        hide: true
    },
    {
        id: 6.5,
        status: 'Self Applied Accomodation',
        name: 'Self Applied Accomodation',
        slug: 'Self Applied Accomodation',
        message: 'Student applied for accomodation on their own',
        color: '#cdffcf',
        hide: true
    },
    {
        id: 7,
        status: 'Travelling',
        name: 'Travelling',
        slug: 'Travelling',
        message: 'Student is Travelling',
        color: '#E0CDFF',
        hide: false
    },
    {
        id: 8,
        status: 'Enrolled',
        name: 'Enrolled',
        slug: 'Enrolled',
        message: 'Student enrolled',
        color: '#cdffcf',
        hide: false
    },
    {
        id: 9,
        status: 'Lost',
        name: 'Lost',
        slug: 'Lost',
        message: 'Lead was lost',
        color: '#ffd0cd',
        hide: false
    },
    {
        id: 10,
        status: 'Disqualified',
        name: 'Disqualified',
        slug: 'Disqualified',
        message: 'Lead has been disqualified',
        color: '#ffd0cd',
        hide: false
    },
    {
        id: 11,
        status: 'Closed',
        name: 'Closed',
        slug: 'Closed',
        message: 'Lead has been closed',
        color: '#eee',
        hide: false
    }
];

export default LeadStatus;
