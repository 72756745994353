import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
    alpha,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Div from '@jumbo/shared/Div';
import KeyIcon from '@mui/icons-material/Key';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import BusinessIcon from '@mui/icons-material/Business';
import PercentIcon from '@mui/icons-material/Percent';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import RoomIcon from '@mui/icons-material/Room';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import _ from 'lodash';
import { formatNumber } from 'app/utils/appHelpers';
import IncomeStatus from 'app/pages/statuses/IncomeStatus';

const Detail = ({ item, onClose }) => {
    const [paymentStatus, setPaymentStatus] = React.useState({
        id: 0,
        name: 'Unknown',
        slug: 'Uknown',
        color: '#ca404f'
    });

    const profilePic = item?.studentId?.profile_pic
        ? process.env.REACT_APP_BACKEND + '/' + item?.studentId?.profile_pic
        : null;

    useEffect(() => {
        setPaymentStatus(
            _.find(IncomeStatus, (status) => status.id === item?.status)
        );
    }, [item]);

    return (
        <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
            <CardHeader
                title={`${item?.studentId?.given_name} ${item?.studentId?.surname}`}
                subheader={''}
                avatar={<Avatar src={profilePic} />}
                action={
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
            />
            <List disablePadding>
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <BusinessIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                Selected Institution
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {item?.institutionId?.institution_name}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <HistoryEduIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                Selected Course
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {item?.leadId?.selectedCourseId?.course_name ||
                                    item?.leadId?.selectedCourseName ||
                                    item?.leadId?.selectedCourseName}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <SquareFootIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                First Year Fee
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {formatNumber(
                                    item?.leadId?.firstYearTuitionFee ||
                                        item?.firstYearTuitionFee ||
                                        0
                                )}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <PercentIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                Deposit
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {formatNumber(item?.amount)}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <RoomIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                Office
                            </Typography>
                        }
                        secondary={
                            <>
                                <Typography variant={'h5'} mb={0}>
                                    {item?.officeId?.office_name}
                                </Typography>
                                <Typography
                                    variant={'body1'}
                                    color={'text.secondary'}
                                >
                                    Created By:{' '}
                                    {`${item?.creator?.first_name} ${item?.creator?.last_name} `}
                                </Typography>
                            </>
                        }
                    />
                </ListItem>
            </List>
            <Stack spacing={1} direction={'row'} sx={{ px: 4, py: 2 }}></Stack>
        </Div>
    );
};

export default Detail;
