import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import JumboApp from '@jumbo/components/JumboApp';
import AppLayout from './AppLayout';
import JumboTheme from '@jumbo/components/JumboTheme';
import AppRoutes from './AppRoutes';
import configureStore, { history } from './redux/store';
import JumboDialog from '@jumbo/components/JumboDialog';
import JumboDialogProvider from '@jumbo/components/JumboDialog/JumboDialogProvider';
import { SnackbarProvider } from 'notistack';
import './services/apis';
import AppProvider from './AppProvider';
import { config } from './config/main';
import JumboRTL from '@jumbo/JumboRTL/JumboRTL';
import Div from '@jumbo/shared/Div';
import { CircularProgress } from '@mui/material';
import JumboAuthProvider from '../@jumbo/components/JumboAuthProvider/JumboAuthProvider';

//Ability Context
import { AbilityContext } from './AppAbility';
import ability from './ability';

// import GlobalStyles from '@mui/material/GlobalStyles';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

const store = configureStore();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <BrowserRouter history={history}>
                    {/* <GlobalStyles styles={{ baseBg: { color: 'grey' } }} /> */}
                    <AppProvider>
                        <AbilityContext.Provider value={ability}>
                            <JumboApp activeLayout={config.defaultLayout}>
                                <JumboTheme init={config.theme}>
                                    <JumboRTL>
                                        <JumboDialogProvider>
                                            <SnackbarProvider
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center'
                                                }}
                                                maxSnack={3}
                                            >
                                                <JumboDialog
                                                    theme={config.theme}
                                                />
                                            </SnackbarProvider>

                                            <SnackbarProvider
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center'
                                                }}
                                                maxSnack={3}
                                            >
                                                <JumboAuthProvider>
                                                    <AppLayout>
                                                        <Suspense
                                                            fallback={
                                                                <Div
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        minWidth: 0,
                                                                        alignItems:
                                                                            'center',
                                                                        alignContent:
                                                                            'center',
                                                                        height: '100%'
                                                                    }}
                                                                >
                                                                    <CircularProgress
                                                                        sx={{
                                                                            m: '-40px auto 0'
                                                                        }}
                                                                    />
                                                                </Div>
                                                            }
                                                        >
                                                            <AppRoutes />
                                                        </Suspense>
                                                    </AppLayout>
                                                </JumboAuthProvider>
                                            </SnackbarProvider>
                                        </JumboDialogProvider>
                                    </JumboRTL>
                                </JumboTheme>
                            </JumboApp>
                        </AbilityContext.Provider>
                    </AppProvider>
                </BrowserRouter>
            </Provider>
        </QueryClientProvider>
    );
}

export default App;
