import React from 'react';

import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';

import LoadingButton from '@mui/lab/LoadingButton';

// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field, useFormikContext } from 'formik';
import FreeAutocomplete from 'app/shared/FreeAutocomplete';

import _ from 'lodash';

const CourseSelect = ({ options }) => {
    const [finalCourses, setFinalCourses] = React.useState(options);

    const { values, setValues, touched, errors, setErrors, submitForm } =
        useFormikContext();

    return (
        <Div sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <FreeAutocomplete
                        fullWidth
                        size={'small'}
                        id="option_one"
                        name="option_one"
                        placeholder="Type course name and press enter or select from the options"
                        field_name="course_name"
                        label={
                            values.status === 4
                                ? '1st Choice (Mandatory)'
                                : 'Select Course'
                        }
                        options={finalCourses}
                    />
                </Grid>
                {(values.option_one || values.option_two) &&
                    values.status === 4 && (
                        <Grid item xs={12} md={12}>
                            <FreeAutocomplete
                                fullWidth
                                size={'small'}
                                id="option_two"
                                name="option_two"
                                field_name="course_name"
                                label="2nd Choice (Optional)"
                                options={finalCourses}
                                placeholder="Type course name and press enter or select from the options"
                            />
                        </Grid>
                    )}
                {(values.option_two || values.option_three) &&
                    values.status === 4 && (
                        <Grid item xs={12} md={12}>
                            <FreeAutocomplete
                                fullWidth
                                size={'small'}
                                id="option_three"
                                name="option_three"
                                field_name="course_name"
                                label="3rd Choice (Optional)"
                                options={finalCourses}
                                placeholder="Type course name and press enter or select from the options"
                            />
                        </Grid>
                    )}
            </Grid>
        </Div>
    );
};

export default CourseSelect;
