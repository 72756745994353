import React from 'react';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import ArticleIcon from '@mui/icons-material/Article';
import { useMutation } from 'react-query';
import { studentServices } from 'app/services/student-services';
import { officeServices } from 'app/services/office-services';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import axios from 'axios';
import { capitalizeAllWords } from 'app/utils/appHelpers';

const validationSchema = yup.object({
    officeId: yup.string('Select the office').required('Office is required')
    // lead: yup
    //     .string('Select whether to create students as a lead or not')
    //     .required('Lead status is required')
});

const TEMPLATE_LINK = `${process.env.REACT_APP_BACKEND}/uploads/templates/csv/students_csv_template.csv`;

const UploadCSVForm = ({
    item: studentItem,
    onSave,
    hideDialog,
    userDetails
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const [officeId, setOfficeId] = React.useState('');
    const [offices, setOffices] = React.useState(false);
    const [file, setFile] = React.useState('');
    const [array, setArray] = React.useState([]);
    const [checked, setChecked] = React.useState(false);

    const fileReader = new FileReader();

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    const { mutate: uploadStudentsCSVMutation, isLoading } = useMutation(
        studentServices.uploadStudentsCSV,
        {
            onSuccess: (res) => {
                console.log(res);
                onSave();
                enqueueSnackbar(res.message, {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (data) => {
        console.log('data');
        console.log(data.csv);

        if (!file) {
            enqueueSnackbar('Please upload a csv file.', {
                variant: 'error'
            });
            return;
        }
        try {
            const formData = new FormData();
            formData.append('officeId', data.officeId);
            formData.append('lead', data.lead);
            formData.append('students_csv', file);
            uploadStudentsCSVMutation(formData);
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelTokenOffices = axios.CancelToken.source();
        const cTokenOffices = { cancelToken: cancelTokenOffices.token };
        const fetchOffices = async () => {
            try {
                const res = await officeServices.getAllOffices(cTokenOffices);
                let finalOffices = res.offices;

                if (
                    userDetails?.officeIds &&
                    userDetails?.role_name !== 'administrator'
                ) {
                    finalOffices = _.filter(res.offices, (office) =>
                        _.includes(userDetails.officeIds, office.id)
                    );
                }

                setOffices(finalOffices);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchOffices();

        return () => {
            cancelTokenOffices.cancel();
        };
    }, []);

    React.useEffect(() => {
        const csvFileToArray = (string) => {
            const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
            const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

            const array = csvRows.map((i) => {
                const values = i.split(',');
                const obj = csvHeader.reduce((object, header, index) => {
                    object[header] = values[index];
                    return object;
                }, {});
                return obj;
            });

            setArray(array);
        };

        if (file) {
            fileReader.onload = function (event) {
                const text = event.target.result;
                csvFileToArray(text);
            };

            fileReader.readAsText(file);
        }
    }, [file]);

    const headerKeys = Object.keys(Object.assign({}, ...array));

    // console.log('array');
    // console.log(array);
    console.log(file);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            <Div sx={{ flexGrow: 1 }}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                        {!offices && (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                width={'100%'}
                            >
                                <CircularProgress color="inherit" />
                            </Box>
                        )}

                        {offices && (
                            <Formik
                                validateOnChange={true}
                                initialValues={{
                                    officeId: '',
                                    lead: '',
                                    csv: ''
                                }}
                                validationSchema={validationSchema}
                                onSubmit={async (data, { setSubmitting }) => {
                                    await onCreate(data);
                                }}
                            >
                                {({
                                    isSubmitting,
                                    errors,
                                    touched,
                                    values,
                                    setFieldValue,
                                    ...restProps
                                }) => (
                                    <Form
                                        style={{ textAlign: 'left' }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <Div sx={{ flexGrow: 1 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={12}>
                                                    <JumboSelectField
                                                        width="100%"
                                                        size={'small'}
                                                        id="officeId"
                                                        name="officeId"
                                                        label="Select Office"
                                                        value={officeId}
                                                        placeholder="Select office"
                                                        onChange={(event) =>
                                                            setOfficeId(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem
                                                            key={'officeId'}
                                                            value=""
                                                        >
                                                            <em>
                                                                Select Office
                                                            </em>
                                                        </MenuItem>

                                                        {offices.map(
                                                            (office) => (
                                                                <MenuItem
                                                                    key={
                                                                        office.id
                                                                    }
                                                                    value={
                                                                        office.id
                                                                    }
                                                                >
                                                                    {capitalizeAllWords(
                                                                        office.office_name
                                                                    )}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </JumboSelectField>
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    {/* <Typography
                                                        variant={'h6'}
                                                        color={'text.secondary'}
                                                        mb={0.5}
                                                    > */}
                                                    Select CSV File
                                                    {/* </Typography> */}
                                                    <TextField
                                                        fullWidth
                                                        size={'small'}
                                                        id="csv"
                                                        name="csv"
                                                        type="file"
                                                        accept=".csv"
                                                        placeholder="Upload CSV file"
                                                        onChange={
                                                            handleOnChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    Upload & create as leads:{' '}
                                                    <Switch
                                                        name="lead"
                                                        checked={checked}
                                                        onChange={(event) => {
                                                            setFieldValue(
                                                                'lead',
                                                                event.target
                                                                    .checked
                                                            );
                                                            setChecked(
                                                                event.target
                                                                    .checked
                                                            );
                                                        }}
                                                        inputProps={{
                                                            'aria-label':
                                                                'controlled'
                                                        }}
                                                        value={values.lead}
                                                    />{' '}
                                                    (
                                                    {checked ? (
                                                        <em> Yes </em>
                                                    ) : (
                                                        <em> No </em>
                                                    )}
                                                    )
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                sx={{
                                                    margin: '30px auto'
                                                }}
                                                container
                                                alignItems="right"
                                                justify="flex-end"
                                                justifyContent="right"
                                            >
                                                <Button
                                                    size="large"
                                                    sx={{ mb: 3 }}
                                                    onClick={() => hideDialog()}
                                                >
                                                    Cancel
                                                </Button>
                                                <LoadingButton
                                                    type="submit"
                                                    variant="contained"
                                                    size="large"
                                                    sx={{ mb: 3 }}
                                                    loading={isLoading}
                                                >
                                                    Upload
                                                </LoadingButton>
                                            </Grid>
                                        </Div>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Alert variant="filled" severity="info">
                            <Typography
                                variant={'h2'}
                                color={'inherit'}
                                mb={0.5}
                            >
                                Instructions
                            </Typography>
                            <Div
                                variant={'body1'}
                                // color={'inherit'}
                                mb={0.5}
                            >
                                <Typography variant={'body1'}>
                                    {' '}
                                    1. Download the attached template (below the
                                    instructions)
                                </Typography>
                                <Typography variant={'body1'}>
                                    2. Fill in the rows with data accordingly.
                                    Date MUST be of the format: YYYY-MM-DD
                                </Typography>
                                <Typography variant={'body1'} mb={0.5}>
                                    3. Upload the filled CSV file on the form on
                                    the left
                                </Typography>
                                <Typography variant={'body1'}>
                                    NOTE: DO NOT modify/insert/delete the
                                    columns
                                </Typography>
                            </Div>

                            <Button
                                component={Link}
                                target="_blank"
                                rel="noreferrer"
                                to={TEMPLATE_LINK}
                                role="button"
                                variant={'outlined'}
                                color="primary"
                                sx={{
                                    marginTop: '20px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    verticalAlign: 'bottom',
                                    cursor: 'pointer'
                                    // '&:hover': {
                                    //     color: 'white'
                                    // }
                                }}
                            >
                                <ArticleIcon />
                                Download Deposits CSV Template
                            </Button>
                        </Alert>
                    </Grid>
                </Grid>
            </Div>
        </Div>
    );
};
/* Todo studentItem, onSave prop define */
export default UploadCSVForm;
