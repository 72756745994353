import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
    alpha,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from '@mui/material';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Div from '@jumbo/shared/Div';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import Chip from '@mui/material/Chip';
import moment from 'moment';

import BadgeIcon from '@mui/icons-material/Badge';
import SchoolIcon from '@mui/icons-material/School';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import BookIcon from '@mui/icons-material/Book';
import TimerIcon from '@mui/icons-material/Timer';
import AutoModeIcon from '@mui/icons-material/AutoMode';

import { colorForBgColor } from '@jumbo/utils';
import _ from 'lodash';
import ApplicationStatus from 'app/pages/statuses/application-status/ApplicationStatus';

const Detail = ({ item, onClose }) => {
    const [selectedStatus, setSelectedStatus] = React.useState(
        _.find(ApplicationStatus, (status) => status.id === item?.status)
    );

    const bgColor = '#aaa';
    const color = colorForBgColor(bgColor);

    React.useEffect(() => {
        if (item?.status !== undefined) {
            setSelectedStatus(
                _.find(
                    ApplicationStatus,
                    (status) => status.id === item?.status
                )
            );
        }
    }, [item]);

    return (
        <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
            <CardHeader
                title={'Uni/College Application'}
                subheader={''}
                // avatar={<Avatar src={item?.profile_pic} />}

                action={
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
            />
            <List disablePadding>
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <BadgeIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                Student Name
                            </Typography>
                        }
                        secondary={
                            <>
                                <Typography variant={'h5'} mb={0}>
                                    {item?.studentId?.given_name +
                                        ' ' +
                                        item?.studentId?.surname}
                                </Typography>
                                <Typography
                                    variant={'body1'}
                                    color={'text.secondary'}
                                    sx={{
                                        display: {
                                            xs: 'block',
                                            sm: 'block'
                                        }
                                    }}
                                >
                                    {item?.studentId?.passport_number}
                                </Typography>
                            </>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <SchoolIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                University/College
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {item?.institutionId?.institution_name}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <LocationCityIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                City
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {item?.institutionId?.city_name}
                                {', '}
                                {item?.countryId?.country_name}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <BookIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                Course(s)
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {item?.courseId ? (
                                    _.map(item?.courseId, (req) => (
                                        <Typography>
                                            <Chip
                                                key={req.id}
                                                label={req.course_name}
                                                sx={{
                                                    bgcolor: bgColor,
                                                    color: color
                                                }}
                                                spacing={5}
                                                size={'small'}
                                            />
                                        </Typography>
                                    ))
                                ) : (
                                    <em>No Course Info</em>
                                )}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <TimerIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                Application Due Date
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                {moment(item?.due_date).format('MMMM DD')}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={'li'} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, 0.15)
                            }}
                        >
                            <AutoModeIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                mb={0.5}
                            >
                                Status
                            </Typography>
                        }
                        secondary={
                            <Typography variant={'h5'} mb={0}>
                                <JumboChipsGroup
                                    chips={
                                        selectedStatus
                                            ? [
                                                  {
                                                      id: 1,
                                                      name: selectedStatus.status,
                                                      slug: selectedStatus.status,
                                                      color: selectedStatus.color
                                                  }
                                              ]
                                            : [
                                                  [
                                                      {
                                                          id: 1,
                                                          name: 'Unknown',
                                                          slug: 'unknown',
                                                          color: '#eee'
                                                      }
                                                  ]
                                              ]
                                    }
                                    mapKeys={{ label: 'name' }}
                                    spacing={1}
                                    size={'small'}
                                    max={1}
                                />
                            </Typography>
                        }
                    />
                </ListItem>
            </List>
            <Stack spacing={1} direction={'row'} sx={{ px: 4, py: 2 }}>
                {/* <IconButton
                    size={'large'}
                    sx={{
                        backgroundColor: (theme) => theme.palette.grey[400],
                        color: 'common.white',

                        '&:hover': {
                            backgroundColor: 'primary.main'
                        }
                    }}
                >
                    <ForumOutlinedIcon fontSize={'medium'} color={'inherit'} />
                </IconButton>
                <IconButton
                    size={'large'}
                    sx={{
                        backgroundColor: (theme) => theme.palette.grey[400],
                        color: 'common.white',

                        '&:hover': {
                            backgroundColor: 'primary.main'
                        }
                    }}
                >
                    <LocalPhoneIcon fontSize={'medium'} color={'inherit'} />
                </IconButton> */}
            </Stack>
        </Div>
    );
};

export default Detail;
