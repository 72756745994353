import jwtAuthAxios from './auth/jwtAuth';
import JumboUtils from './auth/JumboUtils';

class JwtService extends JumboUtils.EventEmitter {
    init() {
        this.setInterceptors();
        // this.handleAuthentication();
    }

    setInterceptors = () => {
        console.log('Set Interceptors');
        jwtAuthAxios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                console.error('JWTAXIOS');
                console.error(error);
                const response = error.response || {};

                if (error.code === 'ERR_NETWORK') {
                    return Promise.reject(
                        response.data?.message || 'A network error occurred'
                    );
                }

                if (response.status === 403) {
                    console.log('Initiating Logout - When Forbidden');
                    this.logout();
                    // You can throw an error message here if needed.
                }

                return Promise.reject(
                    response.data?.message ||
                        'The service is unavailable at the moment'
                );
            }
        );
    };

    getCurrentUser = async () => {
        try {
            const { data } = await jwtAuthAxios.get('/auth/user');
            // console.log('JwtAuthAxios Sign In', data);
            return data;
        } catch (error) {
            // this.emit('onAutoLogout', 'access_token expired');
            throw error;
        }
    };

    //loginCreds must be {email: "abc@example.com", password: "ABC123DEF"}
    signIn = async (loginCreds) => {
        try {
            const { data } = await jwtAuthAxios.post('/auth/login', loginCreds);
            return data;
        } catch (error) {
            // this.logout();
            console.log('Sign In error');
            console.log(error);
            throw error;
        }
    };

    resetPassword = async (loginCreds) => {
        try {
            const { data } = await jwtAuthAxios.patch(
                '/auth/reset-password',
                loginCreds
            );
            return data;
        } catch (error) {
            // this.logout();
            throw error;
        }
    };

    changeResetPassword = async (loginCreds) => {
        try {
            const { data } = await jwtAuthAxios.patch(
                '/auth/reset',
                loginCreds
            );
            return data;
        } catch (error) {
            // this.logout();
            throw error;
        }
    };

    setAuthToken = (token) => {
        if (token) {
            jwtAuthAxios.defaults.headers.common['Authorization'] =
                'Bearer ' + token;
            sessionStorage.setItem('token', token);
        } else {
            delete jwtAuthAxios.defaults.headers.common['Authorization'];
            sessionStorage.removeItem('token');
            localStorage.removeItem('token');
        }
    };

    getAuthToken = () => {
        return sessionStorage.getItem('token');
    };

    logout = () => {
        console.log('this.logout() - Initiating Logout - When Forbidden');
        // this.setAuthToken(null);
        this.emit('onLogout', 'Logged out');
    };
}

const instance = new JwtService();

export default instance;
