import React from 'react';
import FolderItem from 'app/shared/Lists/FolderItem/FolderItem';
import { useNavigate, useParams } from 'react-router-dom';
import StyledMenu from '../../../../../shared/StyledMenu';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import DescriptionIcon from '@mui/icons-material/Description';
import PaidIcon from '@mui/icons-material/Paid';
import FlightIcon from '@mui/icons-material/Flight';
import PaymentIcon from '@mui/icons-material/Payment';

import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PeopleIcon from '@mui/icons-material/People';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

const folders = [
    {
        icon: <RequestPageIcon fontSize={'small'} />,
        label: 'Requests',
        slug: 'requests',
        path: '/app/expenses/requests'
    },
    {
        icon: <AccountBalanceWalletIcon fontSize={'small'} />,
        label: 'All Expenses',
        slug: 'all',
        path: '/app/expenses/all'
    },

    {
        icon: <FlightIcon fontSize={'small'} />,
        label: 'Travel',
        slug: 'travel',
        path: '/app/expenses/travel'
    },
    {
        icon: <ShoppingCartCheckoutIcon fontSize={'small'} />,
        label: 'Purchases',
        slug: 'purchase',
        path: '/app/expenses/purchase'
    },
    {
        icon: <PaidIcon fontSize={'small'} />,
        label: 'Advances',
        slug: 'advances',
        path: '/app/expenses/advances'
    },
    {
        icon: <PaymentIcon fontSize={'small'} />,
        label: 'Reimbursements',
        slug: 'reimbursements',
        path: '/app/expenses/reimbursements'
    },
    {
        icon: <DescriptionIcon fontSize={'small'} />,
        label: 'Reports',
        slug: 'reports',
        path: '/app/expenses/reports'
    },
    {
        icon: <PeopleIcon fontSize={'small'} />,
        label: 'By Employees',
        slug: 'employees',
        path: '/app/expenses/employees/:employeeId',
        children: true,
        allowed: ['hr', 'regional', 'director', 'administrator']
    }
];

const FoldersList = () => {
    const params = useParams();
    const navigate = useNavigate();

    const { authUser } = useJumboAuth();

    const actionHandler = (path) => {
        navigate(path);
    };

    const employees = [
        {
            icon: false,
            label: 'John Roma',
            slug: 'employees',
            path: `/app/expenses/employees/${'9000002222000000000000000'}`
        },
        {
            icon: false,
            label: 'Almeira',
            slug: 'employees',
            path: `/app/expenses/employees/${'87897878979878789798797879'}`
        }
    ];

    return (
        <StyledMenu sx={{ mb: 2 }}>
            {folders.map(
                (folder) =>
                    (!folder?.allowed ||
                        folder?.allowed?.includes(authUser?.role)) && (
                        <FolderItem
                            key={folder.slug}
                            path={folder.path}
                            label={folder.label}
                            icon={folder.icon}
                            selected={folder.slug === params?.category}
                            children={folder?.children ? employees : false}
                            childrenAction={actionHandler}
                        />
                    )
            )}
        </StyledMenu>
    );
};

export default React.memo(FoldersList);
