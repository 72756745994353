import React from 'react';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FoldersList from '../FoldersList';
import LabelsList from '../LabelsList';
import Slide from '@mui/material/Slide';
import Div from '@jumbo/shared/Div';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import ContactForm from '../ContactForm';
import useContactsApp from '../../hooks/useContactsApp';
import { ASSET_AVATARS } from '../../../../../utils/constants/paths';
import { getAssetPath } from '../../../../../utils/appHelpers';

import useJumboAuth from '@jumbo/hooks/useJumboAuth';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const ContactsAppSidebar = () => {
    const { authUser } = useJumboAuth();
    console.log('AuthUser Contacts');
    console.log(authUser);
    const { showDialog, hideDialog } = useJumboDialog();
    const { setContactsListRefresh } = useContactsApp();

    const handleContactAdd = React.useCallback(() => {
        hideDialog();
        setContactsListRefresh(true);
    }, [hideDialog, setContactsListRefresh]);

    const profilePic = authUser
        ? process.env.REACT_APP_BACKEND + '/' + authUser?.profile_pic
        : null;

    const showAddContactDialog = React.useCallback(() => {
        showDialog({
            title: 'Add new contact',
            content: <ContactForm onSave={handleContactAdd} />,
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true
        });
    }, [handleContactAdd, showDialog]);

    return (
        <React.Fragment>
            <Div
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 3
                }}
            >
                <Avatar
                    sx={{ width: 60, height: 60, mr: 2 }}
                    alt={authUser?.first_name}
                    src={profilePic}
                />
                <Div sx={{ flex: '1 1 auto' }}>
                    <Typography variant={'h5'} mb={0.35}>
                        {`${authUser?.first_name} ${authUser?.last_name}`}
                    </Typography>
                    <Typography variant={'body1'} color={'text.secondary'}>
                        {`${authUser?.email_address}`}
                    </Typography>
                </Div>
            </Div>
            <Button
                fullWidth
                disableElevation
                variant={'contained'}
                startIcon={<PersonAddIcon />}
                sx={{
                    mb: 2,
                    '& .MuiSvgIcon-root': {
                        fontSize: '1.5rem'
                    }
                }}
                onClick={showAddContactDialog}
            >
                Add Contact
            </Button>
            <FoldersList />
            {/* <LabelsList/> */}
        </React.Fragment>
    );
};
export default React.memo(ContactsAppSidebar);
