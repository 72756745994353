import React from 'react';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';
import WorkIcon from '@mui/icons-material/Work';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import Link from '@mui/material/Link';
import {
    capitalizeAllWords,
    formatNumber
} from '../../../../../utils/appHelpers';

const Compensation = ({ userDetails }) => {
    console.log('Compensation');
    console.log(userDetails);

    return (
        <JumboCardQuick title={'Organization & Compensation'} noWrapper>
            <List disablePadding sx={{ mb: 2 }}>
                <ListItem
                    alignItems="flex-start"
                    sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                >
                    <ListItemIcon
                        sx={{ minWidth: 36, color: 'text.secondary' }}
                    >
                        <SupervisedUserCircleIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="body1" color="text.secondary">
                                Manager (Reporting To)
                            </Typography>
                        }
                        secondary={
                            <Link variant="body1" href="#" underline="none">
                                {userDetails?.reportTo?.first_name ? (
                                    `
                                    ${userDetails?.reportTo?.first_name} ${userDetails?.reportTo?.last_name}`
                                ) : (
                                    <i>Not Assigned</i>
                                )}
                            </Link>
                        }
                    />
                </ListItem>
                <ListItem
                    alignItems="flex-start"
                    sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                >
                    <ListItemIcon
                        sx={{ minWidth: 36, color: 'text.secondary' }}
                    >
                        <WorkIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="body1" color="text.secondary">
                                Role/Designation
                            </Typography>
                        }
                        secondary={
                            <Link variant="body1" href="#" underline="none">
                                {userDetails?.designationId
                                    ?.designation_name ? (
                                    capitalizeAllWords(
                                        userDetails?.designationId
                                            ?.designation_name
                                    )
                                ) : (
                                    <i>Not Set</i>
                                )}
                            </Link>
                        }
                    />
                </ListItem>
                <ListItem
                    alignItems="flex-start"
                    sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                >
                    <ListItemIcon
                        sx={{ minWidth: 36, color: 'text.secondary' }}
                    >
                        <PaidIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="body1" color="text.secondary">
                                Salary
                            </Typography>
                        }
                        secondary={
                            <Link variant="body1" href="#" underline="none">
                                {formatNumber(userDetails.salary)}
                            </Link>
                        }
                    />
                </ListItem>
            </List>
        </JumboCardQuick>
    );
};

export default Compensation;
