import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import RefreshIcon from '@mui/icons-material/Refresh';

const ReimbursementStatus = [
    {
        id: 0,
        status: 'Cancelled',
        name: 'Cancelled',
        slug: 'Cancelled',
        color: '#ffd0cd',
        type: 'default',
        icon: <CancelIcon />
    },
    {
        id: 1,
        status: 'Pending',
        name: 'Pending',
        slug: 'Pending',
        color: '#ffd0cd',
        type: 'warning',
        icon: <HourglassEmptyIcon />
    },
    {
        id: 2,
        status: 'Partial',
        name: 'Partial',
        slug: 'Partial',
        color: '#ffd0cd',
        type: 'warning',
        icon: <RefreshIcon />
    },
    {
        id: 3,
        status: 'Under Review',
        name: 'Under Review',
        slug: 'Under Review',
        color: '#ffd0cd',
        type: 'warning',
        icon: <ClearIcon />
    },
    {
        id: 4,
        status: 'Reimbursed',
        name: 'Reimbursed',
        slug: 'Reimbursed',
        color: '#cdffcf',
        type: 'success',
        icon: <CheckCircleIcon />
    }
];

export default ReimbursementStatus;
