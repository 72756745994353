import React from 'react';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FoldersList from '../FoldersList';
import LabelsList from '../LabelsList';
import Slide from '@mui/material/Slide';
import Div from '@jumbo/shared/Div';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import SurveyForm from '../SurveyForm';
import useSurveysApp from '../../hooks/useSurveysApp';
import UserProfileCard1 from 'app/shared/widgets/UserProfileCard1';
import { ASSET_AVATARS } from '../../../../../utils/constants/paths';
import { getAssetPath } from '../../../../../utils/appHelpers';

import useJumboAuth from '@jumbo/hooks/useJumboAuth';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const SurveysAppSidebar = () => {
    const { authUser } = useJumboAuth();
    console.log('AuthUser Surveys');
    console.log(authUser);
    const { showDialog, hideDialog } = useJumboDialog();
    const { setSurveysListRefresh } = useSurveysApp();

    const handleSurveyAdd = React.useCallback(() => {
        hideDialog();
        setSurveysListRefresh(true);
    }, [hideDialog, setSurveysListRefresh]);

    const profilePic = authUser
        ? process.env.REACT_APP_BACKEND + '/' + authUser?.profile_pic
        : null;

    const showAddSurveyDialog = React.useCallback(() => {
        showDialog({
            title: 'Add new survey',
            content: <SurveyForm onSave={handleSurveyAdd} />,
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true
        });
    }, [handleSurveyAdd, showDialog]);

    return (
        <React.Fragment>
            <Div
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 3
                }}
            >
                {/* <Avatar
                    sx={{ width: 60, height: 60, mr: 2 }}
                    alt={authUser?.first_name}
                    src={profilePic}
                />
                <Div sx={{ flex: '1 1 auto' }}>
                    <Typography variant={'h5'} mb={0.35}>
                        {`${authUser?.first_name} ${authUser?.last_name}`}
                    </Typography>
                    <Typography variant={'body1'} color={'text.secondary'}>
                        {`${authUser?.email_address}`}
                    </Typography>
                </Div> */}
                <UserProfileCard1
                    cardPic={profilePic}
                    cardName={authUser?.first_name}
                    cardDesignation={
                        authUser?.designation_name || authUser?.role_name
                    }
                />
            </Div>
            {/* <Button
                fullWidth
                disableElevation
                variant={'contained'}
                startIcon={<PersonAddIcon />}
                sx={{
                    mb: 2,
                    '& .MuiSvgIcon-root': {
                        fontSize: '1.5rem'
                    }
                }}
                onClick={showAddSurveyDialog}
            >
                Add Survey
            </Button> */}
            <FoldersList />
            {/* <LabelsList/> */}
        </React.Fragment>
    );
};
export default React.memo(SurveysAppSidebar);
