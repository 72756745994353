import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';

import Students from './metrics/Students';
import Leads from './metrics/Leads';
import LeadsValues from './metrics/LeadsValues';
import OnlineQueries from './metrics/OnlineQueries';

import { useSnackbar } from 'notistack';
import { dashboardsServices } from 'app/services/dashboards-services';

import PopularAgents from './metrics/PopularAgents';
import DealsAnalytics from './metrics/DealsAnalytics';
import RecentActivities1 from './metrics/RecentActivities1';
import axios from 'axios';
import EarningExpenses from './metrics/EarningExpenses';

const ListingDashboard = () => {
    const [summary, setSummary] = useState(false);
    const [popularAgents, setPopularAgents] = useState(false);
    const [loadingPopularAgents, setLoadingPopularAgents] = useState(true);

    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };

        const fetchSummary = async () => {
            try {
                const res = await dashboardsServices.getDashboardSummary(
                    cToken
                );
                setSummary(res.summary);
            } catch (error) {
                // enqueueSnackbar(error, {
                //     variant: 'error'
                // });
            }
        };

        fetchSummary();

        return () => {
            cancelToken.cancel();
        };
    }, [dashboardsServices]);

    React.useEffect(() => {
        const cancelTokenAgents = axios.CancelToken.source();
        const agentToken = { cancelToken: cancelTokenAgents.token };

        const fetchPopularAgents = async () => {
            try {
                const res = await dashboardsServices.getPopularAgents(
                    agentToken
                );

                console.log('Popular Agents');
                console.log(res);

                setPopularAgents(res.popularAgents);
            } catch (error) {
                // console.log(error);
                // enqueueSnackbar(error, {
                //     variant: 'error'
                // });
            }

            setLoadingPopularAgents(false);
        };

        fetchPopularAgents();

        return () => {
            cancelTokenAgents.cancel();
        };
    }, [dashboardsServices]);

    return (
        <Grid container spacing={3.75}>
            {!summary && (
                <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={12}
                    sx={{ justifyContent: 'center' }}
                >
                    <CircularProgress />
                </Grid>
            )}
            {summary && (
                <>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Students students={summary.students} />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Leads leads={summary.leads} />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <LeadsValues leads={summary.leads_values} />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <OnlineQueries queries={summary.online_queries} />
                    </Grid>
                </>
            )}

            {loadingPopularAgents && (
                <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={12}
                    sx={{ justifyContent: 'center' }}
                >
                    <CircularProgress />
                </Grid>
            )}

            {!loadingPopularAgents && (
                <Grid item xs={12}>
                    <PopularAgents popularAgents={popularAgents} />
                </Grid>
            )}

            <Grid item xs={12} md={6}>
                <DealsAnalytics />
            </Grid>

            <Grid item xs={12} lg={6}>
                <EarningExpenses />
                {/* <RecentActivities1 scrollHeight={556} /> */}
            </Grid>
        </Grid>
    );
};

export default ListingDashboard;
