import React from 'react';
import { Alert, CircularProgress, Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import EarningExpensesChart from './EarningExpensesChart';
import { Card, CardContent, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { dashboardsServices } from 'app/services/dashboards-services';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { formatNumber } from 'app/utils/appHelpers';

const EarningExpenses = () => {
    const { enqueueSnackbar } = useSnackbar();

    const [selectedYear, setSelectedYear] = React.useState(
        new Date().getFullYear()
    );
    const [activeChart, setActiveChart] = React.useState('deposits');
    const [loadingDepositsSummary, setLoadingDepositsSummary] =
        React.useState(true);
    const [depositsSummary, setDepositsSummary] = React.useState(false);
    const [allSums, setAllSums] = React.useState({
        month: 'Total',
        deposits: 0,
        leads: 0
    });

    React.useEffect(() => {
        const cancelTokenAgents = axios.CancelToken.source();
        const agentToken = { cancelToken: cancelTokenAgents.token };
        const fetchDepositsSummary = async () => {
            try {
                const res = await dashboardsServices.getDepositsSummary(
                    agentToken
                );

                setDepositsSummary(res.monthlyData);
            } catch (error) {
                // console.log(error);
                // enqueueSnackbar(error, {
                //     variant: 'error'
                // });
            }

            setLoadingDepositsSummary(false);
        };

        fetchDepositsSummary();

        return () => {
            cancelTokenAgents.cancel();
        };
    }, [dashboardsServices]);

    React.useEffect(() => {
        if (depositsSummary && depositsSummary.length > 0) {
            const sumAllValues = depositsSummary.reduce(
                (accumulator, currentValue) => {
                    accumulator.deposits += currentValue.deposits;
                    accumulator.leads += currentValue.leads;
                    return accumulator;
                },
                { month: 'Total', deposits: 0, leads: 0 }
            );

            setAllSums(sumAllValues);
        }
    }, [depositsSummary]);

    return (
        <Card
            title={`Leads Summary ${selectedYear}`}
            subheader={`${allSums.deposits} Deposits`}
        >
            <CardContent
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    mb: '5px',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between'
                }}
            >
                <Stack direction={'row'} spacing={3}>
                    <Div>
                        <Typography variant={'h3'} fontWeight={'500'} mb={0.5}>
                            {formatNumber(allSums.deposits)}
                        </Typography>
                        <Typography variant={'body1'} mb={2}>
                            Deposits
                        </Typography>
                    </Div>
                    <Div>
                        <Typography variant={'h3'} fontWeight={'500'} mb={0.5}>
                            {formatNumber(allSums.leads)}
                        </Typography>
                        <Typography variant={'body1'} mb={2}>
                            Leads
                        </Typography>
                    </Div>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                    <Button
                        variant={
                            activeChart === 'deposits'
                                ? 'contained'
                                : 'outlined'
                        }
                        size={'small'}
                        onClick={() => setActiveChart('deposits')}
                    >
                        Deposits
                    </Button>
                    <Button
                        variant={
                            activeChart === 'leads' ? 'contained' : 'outlined'
                        }
                        disableElevation
                        color={'secondary'}
                        size={'small'}
                        onClick={() => setActiveChart('leads')}
                    >
                        Leads
                    </Button>
                </Stack>
            </CardContent>
            {loadingDepositsSummary && (
                <Div sx={{ width: '100%', justifyContent: 'center' }}>
                    <CircularProgress />
                </Div>
            )}
            {!loadingDepositsSummary &&
                depositsSummary &&
                depositsSummary.length > 0 && (
                    <EarningExpensesChart
                        activeChart={activeChart}
                        loadingDepositsSummary={loadingDepositsSummary}
                        depositsSummary={depositsSummary}
                    />
                )}
        </Card>
    );
};

export default EarningExpenses;
