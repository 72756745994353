import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import { ArrowWrapper } from '@jumbo/components/JumboVerticalNavbar/style';
import JumboNavIdentifier from '@jumbo/components/JumboVerticalNavbar/JumboNavIdentifier';
import { Popover } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import FolderItem from '../FolderItem/FolderItem';

const menuBefore = {
    left: 0,
    top: 0,
    content: `''`,
    position: 'absolute',
    display: 'inline-block',
    width: '4px',
    height: '100%',
    backgroundColor: 'transparent'
};

export default function CollapsibleList({ item }) {
    const [isMiniAndClosed, setIsMiniAndClosed] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openPopover = Boolean(anchorEl);
    const { t } = useTranslation();

    const handlePopoverOpen = React.useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handlePopoverClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    // const label = React.useMemo(() => {
    //     return translate ? t(item.label) : item.label;
    // }, [item, translate, t]);

    const label = item.label;

    if (!item) return null;

    const subMenus =
        item.children && item.children.length > 0 ? item.children : null;

    console.log('subMenus');
    console.log(subMenus);

    const { children, ...itemDetails } = item;
    return (
        <List
            disablePadding
            sx={{
                // mr: isMiniAndClosed ? 0 : 2,
                // pb: 2
                // backgroundColor: '#101010',
                // ml: '-15px'
                p: 0,
                m: 0
            }}
        >
            <ListItemButton
                component={'li'}
                onClick={() => setOpen(!open)}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{
                    // p: (theme) => (!isMiniAndClosed ? theme.spacing(1, 0) : 0),
                    borderRadius: isMiniAndClosed ? '50%' : '0',
                    margin: 0,
                    ...(isMiniAndClosed
                        ? { width: 40, height: 40, justifyContent: 'center' }
                        : {}),
                    overflow: 'hidden',
                    '&:hover': {
                        color: (theme) =>
                            theme?.palette?.nav?.action?.hover || 'secondary',
                        backgroundColor: (theme) =>
                            theme?.palette?.nav?.background?.hover || '#eee',
                        ...(!isMiniAndClosed
                            ? {
                                  '&::before': {
                                      ...menuBefore,
                                      backgroundColor: (theme) =>
                                          theme?.palette?.nav?.tick?.hover ||
                                          '#eee'
                                  }
                              }
                            : {})
                    },
                    ...(!isMiniAndClosed ? { '&::before': menuBefore } : {})
                }}
            >
                {!isMiniAndClosed && (
                    <MenuItem
                        disableRipple
                        disablePadding
                        sx={{ p: 0 }}
                        selected={item.selected}
                    >
                        {item.icon && (
                            <ListItemIcon sx={{ color: 'inherit' }}>
                                {item?.icon}
                            </ListItemIcon>
                        )}
                        <ListItemText
                            primary={label}
                            sx={{
                                m: 0,
                                '& .MuiTypography-root': {
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }
                            }}
                        />
                    </MenuItem>
                )}
            </ListItemButton>
            {subMenus && !isMiniAndClosed && (
                <Collapse
                    component={'li'}
                    in={open}
                    timeout="auto"
                    unmountOnExit
                >
                    <List sx={{ ml: '40px' }}>
                        {subMenus.map((child, index) => {
                            const eventKey = new Date().valueOf();
                            return <FolderItem {...child} />;
                        })}
                    </List>
                </Collapse>
            )}
            {subMenus && isMiniAndClosed && (
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: 'none'
                    }}
                    open={openPopover}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <List disablePadding>
                        {subMenus.map((child, index) => {
                            const eventKey = new Date().valueOf();
                            return <FolderItem {...child} />;
                        })}
                    </List>
                </Popover>
            )}
        </List>
    );
}
