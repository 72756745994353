import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useMutation } from 'react-query';
import { employeeServices } from 'app/services/employee-services';
import { roleServices } from 'app/services/role-services';
import { designationServices } from 'app/services/designation-services';
import { officeServices } from 'app/services/office-services';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboRadioGroup from '@jumbo/components/JumboFormik/JumboRadioGroup';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { capitalizeAllWords } from 'app/utils/appHelpers';

const validationSchema = yup.object({
    first_name: yup
        .string('Enter first name')
        .required('First name is required'),
    middle_name: yup
        .string('Enter middle name')
        .required('Middle name is required'),
    last_name: yup.string('Enter last name').required('Last name is required'),
    dob: yup
        .string('Enter date of birth')
        .required('Date of birth is required'),
    gender: yup.string('Select gender').required('Gender is required'),
    email_address: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    phone_number: yup
        .string('Enter phone number')
        // .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone number is required'),
    address: yup
        .string('Enter residential address')
        .required('City/Residential address is required'),
    university_attended: yup
        .string('Enter the university attended')
        .required('University/College attended is required'),
    id_no: yup
        .string('Enter Governement Identification number')
        .required('ID No. is required'),
    kra_pin: yup.string('Enter KRA Pin').required('KRA Pin is required'),
    roleId: yup.string('Select Role').required('Role is required'),
    designationId: yup
        .string('Select Designation')
        .required('Designation is required'),
    officeId: yup
        .array('Please select designated offices')
        .of(
            yup.object().shape({
                id: yup.string(),
                office_name: yup.string()
            })
        )
        .min(1, 'Please select atleast one office')
        .required('Please select  atleast one office')
        .typeError('Please select an office from the provided list'),
    primaryOfficeId: yup
        .string('Select primary designated working office')
        .required('Primary Designated Office is required'),
    reportTo: yup
        .string("Select employee's manager")
        .required("Employee's manager is required"),
    user_account: yup
        .string('Select whether to create a user account or not')
        .required('Employee status is required'),
    employment_date: yup
        .string('Enter employment date')
        .required('Employee date is required'),
    salary: yup
        .string('Enter employee salary')
        .required('Employee salary is required')
});

const EditForm = ({ listItem, onUpdate, hideDialog }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [employmentDate, setEmploymentDate] = React.useState(
        dayjs('2023-01-01')
    );
    const [showEmpDetails, setShowEmpDetails] = React.useState(
        listItem?.employee
    );
    const [employees, setEmployees] = React.useState(false);
    const [fieldDob, setDob] = React.useState(dayjs(listItem.dob));
    const [phone, setPhone] = React.useState(listItem.phone_number);
    const [role, setRole] = React.useState(
        listItem?.roleId ? listItem.roleId : ''
    );
    // const [designatedOffice, setDesignatedOffice] = React.useState(
    //     listItem?.offices
    //         ? listItem.offices[0].id.length > 1
    //             ? 'All'
    //             : listItem?.primaryOfficeId
    //         : ''
    // );
    const [designation, setDesignation] = React.useState(
        listItem?.designationId ? listItem.designationId : ''
    );

    const [roles, setRoles] = React.useState(false);
    const [designations, setDesignations] = React.useState(false);
    const [offices, setOffices] = React.useState(false);

    const { mutate: editEmployeeMutation, isLoading } = useMutation(
        employeeServices.updateEmployee,
        {
            onSuccess: () => {
                onUpdate();

                enqueueSnackbar('Employee has been updated successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onEdit = async (employee) => {
        console.log('employee');
        console.log(employee);
        console.log(listItem._id);
        try {
            editEmployeeMutation({ id: listItem._id, ...employee });
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelTokenRoles = axios.CancelToken.source();
        const cTokenRoles = { cancelToken: cancelTokenRoles.token };
        const fetchRoles = async () => {
            try {
                const res = await roleServices.getAllRoles(cTokenRoles);
                setRoles(res.roles);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchRoles();

        return () => {
            cancelTokenRoles.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenDesignations = axios.CancelToken.source();
        const cTokenDesignations = {
            cancelToken: cancelTokenDesignations.token
        };
        const fetchDesignations = async () => {
            try {
                const res = await designationServices.getAllDesignations(
                    cTokenDesignations
                );
                setDesignations(res.designations);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchDesignations();

        return () => {
            cancelTokenDesignations.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenOffices = axios.CancelToken.source();
        const cTokenOffices = { cancelToken: cancelTokenOffices.token };
        const fetchOffices = async () => {
            try {
                const res = await officeServices.getAllOffices(cTokenOffices);
                setOffices(
                    _.map(res.offices, (office) => ({
                        id: office.id,
                        office_name: office.office_name
                    }))
                );
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchOffices();

        return () => {
            cancelTokenOffices.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };
        const fetchEmployees = async () => {
            try {
                const res = await employeeServices.getAllEmployees(cToken);

                const finalOptions = _.map(res.employeesList, (employee) => {
                    const {
                        _id: id,
                        first_name,
                        last_name,
                        email_address,
                        phone_number
                    } = employee;

                    return {
                        id,
                        first_name,
                        last_name,
                        email_address,
                        phone_number
                    };
                });

                setEmployees(finalOptions);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchEmployees();

        return () => {
            cancelToken.cancel();
        };
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {(!roles || !designations || !offices || !employees) && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {roles && designations && offices && employees && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        first_name: listItem.first_name,
                        middle_name: listItem.middle_name,
                        last_name: listItem.last_name,
                        dob: fieldDob,
                        gender: listItem.gender.toLowerCase(),
                        email_address: listItem.email_address,
                        phone_number: phone,
                        address: listItem?.address ? listItem?.address : '',
                        university_attended: listItem?.university_attended
                            ? listItem?.university_attended
                            : '',
                        id_no: listItem.id_no,
                        kra_pin: listItem.kra_pin,
                        designationId: listItem?.designationId,
                        roleId: listItem?.roleId,
                        officeId: _.map(listItem.offices, (office) => ({
                            id: office._id,
                            office_name: office.office_name
                        })),
                        primaryOfficeId: listItem?.primaryOfficeId || null,

                        // listItem?.primaryOffice
                        //     ? {
                        //           ...listItem?.primaryOffice[0],
                        //           id: listItem?.primaryOffice[0]?._id
                        //       }
                        //     : null,
                        reportTo: listItem?.reportTo || null,
                        user_account: !listItem.user_account_active
                            ? 'no'
                            : 'yes',
                        employment_date: employmentDate,
                        salary: listItem.salary
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onEdit(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="first_name"
                                            name="first_name"
                                            label="First Name"
                                            placeholder="Enter first name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="middle_name"
                                            name="middle_name"
                                            label="Middle Name"
                                            placeholder="Enter middle name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="last_name"
                                            name="last_name"
                                            label="Last Name"
                                            placeholder="Enter last name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <MobileDatePicker
                                                label="Date Of Birth"
                                                inputFormat="DD/MM/YYYY"
                                                value={fieldDob}
                                                onChange={(newValue) => {
                                                    setDob(newValue);
                                                    values.dob = newValue;
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <JumboTextField
                                                            fullWidth
                                                            size={'small'}
                                                            value={fieldDob}
                                                            name="dob"
                                                            {...params}
                                                        />
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboRadioGroup
                                            row
                                            labelid="gender-row"
                                            label="Gender :"
                                            aria-labelledby="gender-row"
                                            name="gender"
                                        >
                                            <FormControlLabel
                                                value="male"
                                                control={<Radio size="small" />}
                                                label="Male"
                                            />
                                            <FormControlLabel
                                                value="female"
                                                control={<Radio size="small" />}
                                                label="Female"
                                            />
                                        </JumboRadioGroup>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="email_address"
                                            name="email_address"
                                            label="Email Address"
                                            placeholder="Enter email address"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MuiTelInput
                                            fullWidth
                                            size={'small'}
                                            id="phone_number"
                                            name="phone_number"
                                            label="Phone Number"
                                            value={phone}
                                            placeholder="Enter phone number"
                                            preferredCountries={['KE', 'ZM']}
                                            defaultCountry="KE"
                                            onChange={(newValue) => {
                                                setPhone(newValue);
                                                values.phone_number = newValue;
                                            }}
                                        />
                                        {touched.phone_number &&
                                        errors.phone_number ? (
                                            <FormHelperText
                                                style={{
                                                    color: 'red'
                                                }}
                                            >
                                                {errors.phone_number}
                                            </FormHelperText>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="address"
                                            name="address"
                                            label="City/Residential address"
                                            placeholder="Enter residential address"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="university_attended"
                                            name="university_attended"
                                            label="University/College Attended"
                                            placeholder="Enter University/College name attended"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="id_no"
                                            name="id_no"
                                            label="ID No."
                                            placeholder="Enter ID Number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="kra_pin"
                                            name="kra_pin"
                                            label="KRA Pin"
                                            placeholder="Enter KRA Pin"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboSelectField
                                            width="100%"
                                            size={'small'}
                                            id="roleId"
                                            name="roleId"
                                            label="Role"
                                            value={role}
                                            placeholder="Select role"
                                            onChange={(event) =>
                                                setRole(event.target.value)
                                            }
                                        >
                                            <MenuItem key={'roleId'} value="">
                                                <em>Select Role</em>
                                            </MenuItem>
                                            {roles.map((role) => (
                                                <MenuItem
                                                    key={role.id}
                                                    value={role.id}
                                                >
                                                    {capitalizeAllWords(
                                                        role.role_name
                                                    )}
                                                </MenuItem>
                                            ))}
                                        </JumboSelectField>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboSelectField
                                            width="100%"
                                            size={'small'}
                                            id="designationId"
                                            name="designationId"
                                            label="Designation"
                                            value={designation}
                                            placeholder="Select designation"
                                            onChange={(event) =>
                                                setDesignation(
                                                    event.target.value
                                                )
                                            }
                                        >
                                            <MenuItem
                                                key={'designationId'}
                                                value=""
                                            >
                                                <em>
                                                    Select designation/title
                                                </em>
                                            </MenuItem>
                                            {designations.map((designation) => (
                                                <MenuItem
                                                    key={designation.id}
                                                    value={designation.id}
                                                >
                                                    {capitalizeAllWords(
                                                        designation.designation_name
                                                    )}
                                                </MenuItem>
                                            ))}
                                        </JumboSelectField>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <MobileDatePicker
                                                label="Employment Date"
                                                inputFormat="DD/MM/YYYY"
                                                value={employmentDate}
                                                onChange={(newValue) =>
                                                    setEmploymentDate(newValue)
                                                }
                                                renderInput={(params) => (
                                                    <JumboTextField
                                                        fullWidth
                                                        size={'small'}
                                                        id="employment_date"
                                                        name="employment_date"
                                                        value={employmentDate}
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field name="officeId">
                                            {({ field, form }) => (
                                                <Autocomplete
                                                    {...field}
                                                    multiple
                                                    id="officeId"
                                                    sx={{
                                                        width: '100%'
                                                    }}
                                                    size="small"
                                                    options={offices}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.office_name
                                                            ? option.office_name
                                                            : ''
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                '& > img': {
                                                                    mr: 2,
                                                                    flexShrink: 0
                                                                }
                                                            }}
                                                            {...props}
                                                        >
                                                            {option.office_name}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Select Designated Office(s)"
                                                            placeholder="Choose designated office(s)"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.officeId &&
                                                                !!errors.officeId
                                                            }
                                                            helperText={
                                                                touched?.officeId &&
                                                                errors.officeId
                                                            }
                                                        />
                                                    )}
                                                    onChange={(
                                                        event,
                                                        newValue
                                                    ) => {
                                                        form.setFieldValue(
                                                            'officeId',
                                                            newValue
                                                        );
                                                    }}
                                                    value={form.values.officeId}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    {values?.officeId &&
                                        values.officeId.length > 0 && (
                                            <Grid item xs={12} md={6}>
                                                <Field name="primaryOfficeId">
                                                    {({ field }) => (
                                                        <Autocomplete
                                                            {...field}
                                                            id="primaryOfficeId"
                                                            sx={{
                                                                width: '100%'
                                                            }}
                                                            size="small"
                                                            options={
                                                                values.officeId ||
                                                                []
                                                            }
                                                            autoHighlight
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option?.office_name
                                                                    ? option.office_name
                                                                    : ''
                                                            }
                                                            isOptionEqualToValue={(
                                                                option,
                                                                value
                                                            ) => {
                                                                return (
                                                                    option.id ===
                                                                    value.id
                                                                );
                                                            }}
                                                            renderOption={(
                                                                props,
                                                                option
                                                            ) => (
                                                                <Box
                                                                    component="li"
                                                                    sx={{
                                                                        '& > img':
                                                                            {
                                                                                mr: 2,
                                                                                flexShrink: 0
                                                                            }
                                                                    }}
                                                                    {...props}
                                                                >
                                                                    {
                                                                        option.office_name
                                                                    }
                                                                </Box>
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Select  primary designated Office(s)"
                                                                    placeholder="Choose primary designated office(s)"
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        autoComplete:
                                                                            'new-password'
                                                                    }}
                                                                    error={
                                                                        touched?.primaryOfficeId &&
                                                                        !!errors.primaryOfficeId
                                                                    }
                                                                    helperText={
                                                                        touched?.primaryOfficeId &&
                                                                        errors.primaryOfficeId
                                                                    }
                                                                />
                                                            )}
                                                            onChange={
                                                                (
                                                                    event,
                                                                    newValue
                                                                ) => {
                                                                    values.primaryOfficeId =
                                                                        newValue?.id;
                                                                }
                                                                // Set the new value in the form field
                                                            }
                                                            value={
                                                                values.officeId.find(
                                                                    (option) =>
                                                                        option.id ===
                                                                        values.primaryOfficeId
                                                                ) || null
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                            </Grid>
                                        )}

                                    <Grid item xs={12} md={6}>
                                        <Field name="reportTo">
                                            {({ field, form }) => (
                                                <Autocomplete
                                                    {...field}
                                                    id="reportTo"
                                                    sx={{
                                                        width: '100%',
                                                        maxWidth: '100%'
                                                    }}
                                                    size="small"
                                                    options={employees}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.first_name
                                                            ? `${option.first_name} ${option.last_name}`
                                                            : ''
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => {
                                                        return (
                                                            option.id ===
                                                            value.id
                                                        );
                                                    }}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            {...props}
                                                            key={option.id}
                                                        >
                                                            {`${option.first_name} ${option.last_name} - ${option.email_address}`}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Employees Manager"
                                                            placeholder="Select Employees Manager"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                form.touched
                                                                    .reportTo &&
                                                                !!form.errors
                                                                    .reportTo
                                                            }
                                                            helperText={
                                                                form.touched
                                                                    .reportTo &&
                                                                form.errors
                                                                    .reportTo
                                                            }
                                                        />
                                                    )}
                                                    onChange={(
                                                        event,
                                                        newValue
                                                    ) => {
                                                        values.reportTo =
                                                            newValue.id;
                                                    }}
                                                    value={
                                                        employees.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.reportTo
                                                        ) || null
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="salary"
                                            name="salary"
                                            label="Salary"
                                            type="number"
                                            min="0"
                                            step="any"
                                            placeholder="Enter employee salary"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboRadioGroup
                                            row
                                            labelid="employee-row"
                                            label="Create User Account ?"
                                            aria-labelledby="employee-row"
                                            name="user_account"
                                        >
                                            <FormControlLabel
                                                value="yes"
                                                control={<Radio size="small" />}
                                                label="Yes"
                                            />
                                            <FormControlLabel
                                                value="no"
                                                control={<Radio size="small" />}
                                                label="No"
                                            />
                                        </JumboRadioGroup>
                                    </Grid>
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                        disabled={!!errors.length}
                                    >
                                        Update
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo employeeItem, onSave prop define */
export default EditForm;
