import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const visaapplicationsServices = {};

visaapplicationsServices.getAllVisaapplications = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/visa-applications',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

visaapplicationsServices.getVisaapplications = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/visa-applications', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

visaapplicationsServices.getVisaapplicationDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(
        `/visa-applications/visa-application/${id}`,
        {
            id: id
        }
    );

    return data;
};

visaapplicationsServices.addVisaapplication = async (visaapplications) => {
    const { data } = await jwtAuthAxios.post(
        `/visa-applications`,
        visaapplications
    );
    return data;
};

visaapplicationsServices.updateVisaapplication = async (visaapplications) => {
    // console.log('Service Visaapplication');
    // console.log(visaapplications);
    const { data } = await jwtAuthAxios.patch(
        `/visa-applications/${visaapplications.id}`,
        visaapplications
    );
    return data;
};

visaapplicationsServices.deleteVisaapplication = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/visa-applications/${id}`, {
        id: id
    });
    return data;
};

visaapplicationsServices.deleteMultipleVisaapplications = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/visa-applications`, {
        data: ids
    });
    return data;
};

export { visaapplicationsServices };
