import React from 'react';

import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import ApplicationStatus from 'app/pages/statuses/application-status/ApplicationStatus';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { useFormikContext, Formik, Form, Field } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import {
    capitalizeAllWords,
    getCurrentDate,
    getNextYearDate
} from 'app/utils/appHelpers';
import _ from 'lodash';

const validationSchema = yup.object({
    status: yup.string('Select status').required('Status is required'),
    applicationId: yup
        .string('Enter application ID/Code')
        .required('Application ID/Code name is required'),
    courseId: yup
        .array('Please select a course')
        .of(
            yup.object().shape({
                value: yup.string(),
                label: yup.string()
            })
        )
        .min(1, 'Please select atleast 1 course')
        .required('Please select applied courses')
        .typeError('Please select a valid course'),
    acceptance_date: yup
        .string('Enter date of application')
        .required('Application date is required')
    // comment: yup.string('Enter Comment').required('Comment is required')
});

const AcceptUniapplication = ({
    listItem,
    userDetails,
    uniApplications,
    setActiveSchema,
    setInitialFormValues
}) => {
    // const { enqueueSnackbar } = useSnackbar();

    const { values, setValues, touched, errors, setErrors, submitForm } =
        useFormikContext();

    const [customizingCourses, setCustomizingCourses] = React.useState(false);
    const [courses, setCourses] = React.useState([]);
    const [finalCourses, setFinalCourses] = React.useState([]);
    const [applicationId, setApplicationId] = React.useState('');
    const [applications, setApplications] = React.useState(uniApplications);
    const [fieldDate, setFieldDate] = React.useState(
        dayjs(getCurrentDate(false))
    );

    React.useEffect(() => {
        if (fieldDate) {
            setValues({
                acceptance_date: fieldDate
            });
        }
    }, [fieldDate]);

    React.useEffect(() => {
        setActiveSchema(validationSchema);

        setValues({
            status: 4.4,
            leadId: listItem.id,
            acceptance_date: fieldDate
        });
    }, []);

    React.useEffect(() => {
        if (applicationId) {
            const selectedApplication = _.find(
                uniApplications,
                (application) => application?.id === applicationId
            );

            setFinalCourses(
                _.map(selectedApplication.selected_courses, (course) => ({
                    course_name: course
                }))
            );
        }

        setCustomizingCourses(false);
    }, [applicationId]);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%'
            }}
        >
            {!applications && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {applications && (
                <Div sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Field name="applicationId">
                                {({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        id="applicationId"
                                        sx={{
                                            width: 500,
                                            maxWidth: '100%'
                                        }}
                                        size="small"
                                        options={applications}
                                        autoHighlight
                                        getOptionLabel={(option) =>
                                            option?.applicationCode
                                                ? option.applicationCode
                                                : ''
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        renderOption={(props, option) => {
                                            const state = _.find(
                                                ApplicationStatus,
                                                (status) =>
                                                    status.id === option.status
                                            );
                                            return (
                                                <Box
                                                    component="li"
                                                    sx={{
                                                        '& > img': {
                                                            mr: 2,
                                                            flexShrink: 0
                                                        },
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                            'space-between'
                                                    }}
                                                    {...props}
                                                >
                                                    <Typography
                                                        sx={{
                                                            marginRight: '8px'
                                                        }}
                                                    >
                                                        {option.applicationCode}
                                                    </Typography>
                                                    <Chip
                                                        color={state.type}
                                                        label={state.status}
                                                        size={'small'}
                                                    />
                                                </Box>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Application"
                                                placeholder="Choose an application"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password'
                                                }}
                                                error={
                                                    touched?.applicationId &&
                                                    !!errors.applicationId
                                                }
                                                helperText={
                                                    touched?.applicationId &&
                                                    errors.applicationId
                                                }
                                            />
                                        )}
                                        onChange={
                                            (event, newValue) => {
                                                setCustomizingCourses(true);
                                                values.applicationId =
                                                    newValue?.id;
                                                values.applicationCode =
                                                    newValue?.applicationCode;
                                                values.countryId =
                                                    newValue?.countryId.id;
                                                values.institutionId =
                                                    newValue?.institutionId.id;
                                                values.institution_name =
                                                    newValue?.institutionId.institution_name;
                                                values.country_name =
                                                    newValue?.countryId.country_name;
                                                values.application_requirements =
                                                    newValue?.application_requirements;
                                                values.offer_waiting_period =
                                                    newValue?.offer_waiting_period ||
                                                    10;
                                                values.application_fee =
                                                    newValue?.application_fee ||
                                                    1;

                                                setApplicationId(newValue?.id);
                                                values.courseId = [];
                                                setErrors({});
                                            }
                                            // Set the new value in the form field
                                        }
                                        value={
                                            applications.find(
                                                (option) =>
                                                    option.id ===
                                                    values.applicationId
                                            ) || ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                    label="Acceptance Date"
                                    inputFormat="DD/MM/YYYY"
                                    value={fieldDate}
                                    onChange={(newValue) => {
                                        setFieldDate(newValue);
                                        values.acceptance_date = newValue;
                                    }}
                                    renderInput={(params) => {
                                        return (
                                            <JumboTextField
                                                fullWidth
                                                size={'small'}
                                                value={fieldDate}
                                                name="acceptance_date"
                                                {...params}
                                            />
                                        );
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        {applicationId && (
                            <Grid item xs={12} md={12}>
                                {customizingCourses && (
                                    <Div
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%'
                                        }}
                                    >
                                        <CircularProgress
                                            color="inherit"
                                            size={24}
                                        />
                                        <Typography
                                            sx={{ marginLeft: '16px' }}
                                            color="default"
                                        >
                                            Loading Courses please wait ...
                                        </Typography>
                                    </Div>
                                )}
                                {!customizingCourses && (
                                    <Field name="courseId">
                                        {({ field }) => (
                                            <Autocomplete
                                                multiple
                                                {...field}
                                                id="courseId"
                                                size={'small'}
                                                options={finalCourses}
                                                getOptionLabel={(option) => {
                                                    return (
                                                        option.course_name || ''
                                                    );
                                                }}
                                                renderOption={(
                                                    props,
                                                    option
                                                ) => (
                                                    <Box
                                                        component="li"
                                                        sx={{
                                                            '& > img': {
                                                                mr: 2,
                                                                flexShrink: 0
                                                            }
                                                        }}
                                                        {...props}
                                                    >
                                                        {option.course_name}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Choose the accepted/approved Course"
                                                        placeholder="Choose accepted/approved Course"
                                                        error={
                                                            touched?.courseId &&
                                                            !!errors.courseId
                                                        }
                                                        helperText={
                                                            touched?.courseId &&
                                                            errors.courseId
                                                        }
                                                    />
                                                )}
                                                onChange={(event, newValue) =>
                                                    field.onChange({
                                                        target: {
                                                            name: field.name,
                                                            value: newValue
                                                        }
                                                    })
                                                }
                                                // value={values.courseId || ''}
                                            />
                                        )}
                                    </Field>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Div>
            )}
        </Div>
    );
};
/* Todo uniapplicationItem, onSave prop define */
export default AcceptUniapplication;
