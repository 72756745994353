import React from 'react';
import { Button, Typography } from '@mui/material';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Slide from '@mui/material/Slide';
import { Link } from 'react-router-dom';
import GetAppIcon from '@mui/icons-material/GetApp'; // Icon for downloading
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'; // PDF icon
import ImageIcon from '@mui/icons-material/Image'; // Image icon
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

import _ from 'lodash';
import { capitalizeAllWords } from 'app/utils/appHelpers';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const Documents = ({ lead }) => {
    const { showDialog, hideDialog } = useJumboDialog();

    const docs = lead?.documents || [];

    const downloadHandler = (document) => {
        console.log('Downloading:', document.document_file);

        // Get the file URL from document_file
        const fileURL = `${process.env.REACT_APP_BACKEND}/${document.document_file}`;

        console.log('fileURL');
        console.log(fileURL);

        // Trigger file download using fetch API
        fetch(fileURL)
            .then((response) => {
                // Check if the response is successful (status code 200-299)
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }

                console.log('response');
                console.log(response);
                // Convert the response to blob
                return response.body.blob();
            })
            .then((blob) => {
                // Create a temporary URL for the blob object
                const url = window.URL.createObjectURL(new Blob([blob]));
                // Create a link element
                const link = document.createElement('a');
                // Set the href attribute to the blob URL
                link.href = url;
                // Set the download attribute with the document name
                link.setAttribute('download', document.document_name);
                // Append the link to the body
                document.body.appendChild(link);
                // Simulate a click on the link to start the download
                link.click();
                // Remove the link from the DOM
                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                console.error(
                    'There was a problem with the fetch operation:',
                    error
                );
            });
    };

    const getIconForType = (type) => {
        switch (type) {
            case 'pdf':
                return <PictureAsPdfIcon />;
            case 'jpg':
                return <ImageIcon />;
            case 'jpeg':
                return <ImageIcon />;
            case 'png':
                return <ImageIcon />;
            case 'webp':
                return <ImageIcon />;
            case 'image':
                return <ImageIcon />;
            default:
                return null;
        }
    };

    const getTypeFromName = (name) => {
        console.log(name);
        const splitName = name.split('.'); // Split the name at the dot (.)
        if (splitName.length > 1) {
            return splitName[splitName.length - 1]; // Get the last part after the dot (file extension)
        }
        return null;
    };

    return (
        <JumboCardQuick
            title={'Documents'}
            subheader={`List of lead/student related files`}
            action={
                <Stack
                    direction={'row'}
                    justifyContent={'space-evenly'}
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                    sx={{
                        mx: 1
                    }}
                >
                    {/* <Button
                        variant="contained"
                        size="small"
                        onClick={handleUpdateDocumentsForm}
                    >
                        Update Preferred Courses
                    </Button> */}
                </Stack>
            }
            headerSx={{ pb: 0 }}
            sx={{ mb: 2 }}
        >
            <Typography variant="body1" mb={2}></Typography>

            <List
                disablePadding
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: 0,
                    maxHeight: '200px',
                    overflowY: 'scroll'
                }}
            >
                {docs.length > 0 ? (
                    docs.map((doc, index) => (
                        <MenuItem
                            key={index}
                            // onClick={() => downloadHandler(doc)}
                            component={Link}
                            target="_blank"
                            rel="noreferrer"
                            to={`${process.env.REACT_APP_BACKEND}/${doc.document_file}`}
                            role="button"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: '#f5f5f5', // Hover effect
                                    color: '#843CF6'
                                }
                            }}
                        >
                            {/* {`Doc Type ${getTypeFromName(doc.document_file)}`} */}
                            {getIconForType(getTypeFromName(doc.document_file))}
                            <Typography
                                ml={1}
                                sx={{ width: '80%', overFlowX: 'hidden' }}
                            >
                                {capitalizeAllWords(doc.document_name)}
                            </Typography>
                            <GetAppIcon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    downloadHandler(doc);
                                }}
                                sx={{ ml: 'auto' }}
                            />
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem>No Documents Available</MenuItem>
                )}
            </List>
        </JumboCardQuick>
    );
};

export default Documents;
