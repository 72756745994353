import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const roleServices = {};

roleServices.getAllRoles = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/roles',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

roleServices.getRoles = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/roles', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

roleServices.getRoleDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/roles/role/${id}`, {
        id: id
    });

    return data;
};

roleServices.addRole = async (role) => {
    const { data } = await jwtAuthAxios.post(`/roles`, role);
    return data;
};

roleServices.updateRole = async (role) => {
    // console.log('Service Role');
    // console.log(role);
    const { data } = await jwtAuthAxios.patch(`/roles/${role.id}`, role);
    return data;
};

roleServices.deleteRole = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/roles/${id}`, {
        id: id
    });
    return data;
};

roleServices.deleteMultipleRoles = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/roles`, {
        data: ids
    });
    return data;
};

export { roleServices };
