import React from 'react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { useMutation } from 'react-query';
import { employeeServices } from '../../../../../../services/employee-services';
import useEmployeesApp from '../../hooks/useEmployeesApp';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { useSnackbar } from 'notistack';
import { Tooltip } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import _ from 'lodash';

const BulkActions = () => {
    const { selectedList, setListRefresh } = useEmployeesApp();
    const { showDialog, hideDialog } = useJumboDialog();
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: removeEmployees, isLoading } = useMutation(
        employeeServices.deleteMultipleEmployees,
        {
            onSuccess: () => {
                hideDialog();
                setListRefresh(true);
                enqueueSnackbar('Employees have been deleted successfully.', {
                    variant: 'success'
                });
            }
        }
    );

    const handleBulkDelete = React.useCallback(() => {
        // enqueueSnackbar('Deletion has been disabled for this view', {
        //     variant: 'warning'
        // });

        // return;

        showDialog({
            variant: 'confirm',
            title: 'Delete!',
            content:
                'This action cannot be undone. Are you sure you want to proceed?  Select Yes to Continue',
            onYes: () => {
                removeEmployees(_.map(selectedList, (item) => item.id));
            },
            onNo: hideDialog
        });
    }, [selectedList, removeEmployees, hideDialog]);

    return (
        <Stack
            direction={'row'}
            sx={{ backgroundColor: 'transparent', ml: -2 }}
        >
            <Tooltip title={'Delete'}>
                <IconButton onClick={handleBulkDelete}>
                    <DeleteOutlineIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};

export default BulkActions;
