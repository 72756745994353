import React from 'react';
import Header from './Header';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';

import { alpha } from '@mui/material/styles';
import About from './components/About';
import StudentProfileSidebar from './StudentProfileSidebar';

import JumboListWrapper from '@jumbo/components/JumboList/components/JumboListWrapper';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';

import { CircularProgress, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import Div from '@jumbo/shared/Div';

import { useParams } from 'react-router-dom';

import { useQuery, useQueryClient } from 'react-query';
import { studentServices } from 'app/services/student-services';

import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { getAssetPath } from 'app/utils/appHelpers';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import Applications from './components/Applications';
import PreferredCourses from './components/PreferredCourses';
import Enrollments from './components/Enrollments';

const StudentProfile = () => {
    const { authUser } = useJumboAuth();

    const { theme } = useJumboTheme();
    const params = useParams();
    const studentId = params.id.toString();

    const fetchStudentDetails = async () => {
        const data = await studentServices.getStudentDetails(studentId);

        return data;
    };

    const queryClient = useQueryClient();

    const { isLoading, data, error } = useQuery(`student-${studentId}`, () =>
        fetchStudentDetails(studentId)
    );

    const invalidateAndRefetch = () => {
        queryClient.invalidateQueries(`student-${studentId}`);
    };

    if (isLoading) {
        return (
            <JumboListWrapper
                component={Card}
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Div
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: (theme) => theme.spacing(3),
                        m: 'auto'
                    }}
                >
                    <CircularProgress />
                    <Typography variant={'h6'} color={'text.secondary'} mt={2}>
                        Loading data
                    </Typography>
                </Div>
            </JumboListWrapper>
        );
    }

    if (error) {
        return (
            <JumboListWrapper
                component={Card}
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Div
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: (theme) => theme.spacing(3),
                        m: 'auto'
                    }}
                >
                    <Typography variant={'h6'} color={'text.secondary'} mt={2}>
                        {error}
                    </Typography>
                </Div>
            </JumboListWrapper>
        );
    }

    if (data) {
        return (
            <JumboContentLayout
                header={<Header studentDetails={data.student} />}
                sidebar={
                    <StudentProfileSidebar
                        studentDetails={data.student}
                        authUser={authUser}
                    />
                }
                layoutOptions={{
                    header: {
                        sx: {
                            mt: -4,
                            mb: -7.25,
                            mx: { xs: -4, lg: -6 },
                            p: {
                                xs: theme.spacing(6, 4, 11),
                                lg: theme.spacing(6, 6, 11)
                            },
                            background: `#002447 url(${getAssetPath(
                                `${ASSET_IMAGES}/profile-bg.jpg`,
                                '1920x580'
                            )}) no-repeat center`,
                            backgroundSize: 'cover',
                            color: 'common.white',
                            position: 'relative',

                            '&::after': {
                                display: 'inline-block',
                                position: 'absolute',
                                content: `''`,
                                inset: 0,
                                backgroundColor: alpha(
                                    theme.palette.common.black,
                                    0.65
                                )
                            }
                        }
                    },
                    sidebar: {
                        sx: {
                            mr: 3.75,
                            width: { xs: '100%', lg: '33%' },
                            [theme.breakpoints.down('lg')]: {
                                minHeight: 0,
                                mr: 0,
                                order: 2
                            }
                        }
                    },
                    wrapper: {
                        sx: {
                            [theme.breakpoints.down('lg')]: {
                                flexDirection: 'column'
                            }
                        }
                    },
                    main: {
                        sx: {
                            [theme.breakpoints.down('lg')]: {
                                minHeight: 0
                            }
                        }
                    }
                }}
            >
                <About studentDetails={data.student} />
                <Enrollments studentDetails={data.student} />
                <PreferredCourses
                    studentDetails={data.student}
                    invalidateAndRefetch={invalidateAndRefetch}
                />
                <Applications studentDetails={data.student} />
            </JumboContentLayout>
        );
    } else {
        return (
            <JumboListNoDataPlaceholder>
                <NoDataPlaceholder />
            </JumboListNoDataPlaceholder>
        );
    }
};

export default StudentProfile;
