import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { useMutation } from 'react-query';
import { userServices } from 'app/services/user-services';
import { roleServices } from 'app/services/role-services';
import { designationServices } from 'app/services/designation-services';
import { officeServices } from 'app/services/office-services';

import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboRadioGroup from '@jumbo/components/JumboFormik/JumboRadioGroup';
import axios from 'axios';
import { capitalizeAllWords } from 'app/utils/appHelpers';

// const phoneRegExp =/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
// const MAX_FILE_SIZE = 102400 * 20; //100KB

// const validFileExtensions = {
//     image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp']
// };

// function isValidFileType(fileName, fileType) {
//     return (
//         fileName &&
//         validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1
//     );
// }
// const ALLOWED_SIZE = Math.floor(MAX_FILE_SIZE / 1000);

const validationSchema = yup.object({
    first_name: yup
        .string('Enter first name')
        .required('First name is required'),
    middle_name: yup
        .string('Enter middle name')
        .required('Middle name is required'),
    last_name: yup.string('Enter last name').required('Last name is required'),
    dob: yup
        .string('Enter date of birth')
        .required('Date of birth is required'),
    gender: yup.string('Select gender').required('Gender is required'),
    email_address: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    phone_number: yup
        .string('Enter phone number')
        // .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone number is required'),
    address: yup
        .string('Enter residential address')
        .required('Residential address is required'),
    university_attended: yup
        .string('Enter the university attended')
        .required('University/College attended is required'),
    id_no: yup
        .string('Enter Governement Identification number')
        .required('ID No. is required'),
    kra_pin: yup.string('Enter KRA Pin').required('KRA Pin is required'),

    roleId: yup.string('Select Role').required('Role is required'),
    designationId: yup
        .string('Select Designation')
        .required('Designation is required'),
    officeId: yup
        .string('Select designated working office')
        .required('Designated Office is required'),
    employee: yup
        .string('Select whether user is employee or not')
        .required('Employee status is required'),
    employment_date: yup
        .string('Enter employment date')
        .required('Employee date is required'),
    salary: yup
        .string('Enter employee salary')
        .required('Employee salary is required')
    // profile_pic: yup
    //     .mixed()
    //     .required('Required')
    //     .test('is-valid-type', 'Not a valid image type', (value) =>
    //         isValidFileType(value && value.name.toLowerCase(), 'image')
    //     )
    //     .test(
    //         'is-valid-size',
    //         'Photo Maximum allowed size is ' + ALLOWED_SIZE + 'MB',
    //         (value) => value && value.size <= MAX_FILE_SIZE
    //     )
});

const CreateForm = ({ item: userItem, onSave, hideDialog }) => {
    const [employmentDate, setEmploymentDate] = React.useState(
        dayjs('2023-01-01')
    );
    const { enqueueSnackbar } = useSnackbar();
    const [showEmpDetails, setShowEmpDetails] = React.useState(false);

    const [fieldDob, setDob] = React.useState(dayjs('2000-01-01'));
    const [phone, setPhone] = React.useState('+254');

    const [role, setRole] = React.useState('');
    const [designatedOffice, setDesignatedOffice] = React.useState('');
    const [designation, setDesignation] = React.useState('');

    const [roles, setRoles] = React.useState(false);
    const [designations, setDesignations] = React.useState(false);
    const [offices, setOffices] = React.useState(false);

    const { mutate: addUserMutation, isLoading } = useMutation(
        userServices.addUser,
        {
            onSuccess: () => {
                onSave();
                enqueueSnackbar('User has been created successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (user) => {
        try {
            addUserMutation({ ...user });
        } catch (error) {}
    };

    const employeeRadioChangeHandler = (event) => {
        if (event.target.value === 'yes') {
            setShowEmpDetails(true);
            setDesignatedOffice('none');
        } else {
            setShowEmpDetails(false);
            setDesignatedOffice('none');
        }
    };

    React.useEffect(() => {
        const cancelTokenRoles = axios.CancelToken.source();
        const cTokenRoles = { cancelToken: cancelTokenRoles.token };
        const fetchRoles = async () => {
            try {
                const res = await roleServices.getAllRoles(cTokenRoles);
                setRoles(res.roles);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchRoles();

        return () => {
            cancelTokenRoles.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenDesignations = axios.CancelToken.source();
        const cTokenDesignations = {
            cancelToken: cancelTokenDesignations.token
        };
        const fetchDesignations = async () => {
            try {
                const res = await designationServices.getAllDesignations(
                    cTokenDesignations
                );
                setDesignations(res.designations);
            } catch (error) {
                console.log('--------> error');
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchDesignations();

        return () => {
            cancelTokenDesignations.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenOffices = axios.CancelToken.source();
        const cTokenOffices = { cancelToken: cancelTokenOffices.token };
        const fetchOffices = async () => {
            try {
                const res = await officeServices.getAllOffices(cTokenOffices);
                setOffices(res.offices);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchOffices();

        return () => {
            cancelTokenOffices.cancel();
        };
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {(!roles || !designations || !offices) && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {roles && designations && offices && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        first_name: '',
                        middle_name: '',
                        last_name: '',
                        dob: fieldDob,
                        gender: '',
                        email_address: '',
                        phone_number: phone,
                        address: '',
                        university_attended: '',
                        id_no: '',
                        kra_pin: '',
                        designationId: '',
                        roleId: '',
                        officeId: 'none',
                        employee: '',
                        employment_date: employmentDate,
                        salary: 0
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onCreate(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="first_name"
                                            name="first_name"
                                            label="First Name"
                                            placeholder="Enter first name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="middle_name"
                                            name="middle_name"
                                            label="Middle Name"
                                            placeholder="Enter middle name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="last_name"
                                            name="last_name"
                                            label="Last Name"
                                            placeholder="Enter last name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <MobileDatePicker
                                                label="Date Of Birth"
                                                inputFormat="DD/MM/YYYY"
                                                value={fieldDob}
                                                onChange={(newValue) => {
                                                    setDob(newValue);
                                                    values.dob = newValue;
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <JumboTextField
                                                            fullWidth
                                                            size={'small'}
                                                            value={fieldDob}
                                                            name="dob"
                                                            {...params}
                                                        />
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboRadioGroup
                                            row
                                            labelid="gender-row"
                                            label="Gender :"
                                            aria-labelledby="gender-row"
                                            name="gender"
                                        >
                                            <FormControlLabel
                                                value="male"
                                                control={<Radio size="small" />}
                                                label="Male"
                                            />
                                            <FormControlLabel
                                                value="female"
                                                control={<Radio size="small" />}
                                                label="Female"
                                            />
                                        </JumboRadioGroup>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="email_address"
                                            name="email_address"
                                            label="Email Address"
                                            placeholder="Enter email address"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MuiTelInput
                                            fullWidth
                                            size={'small'}
                                            id="phone_number"
                                            name="phone_number"
                                            label="Phone Number"
                                            value={phone}
                                            placeholder="Enter phone number"
                                            preferredCountries={['KE', 'ZM']}
                                            defaultCountry="KE"
                                            onChange={(newValue) => {
                                                setPhone(newValue);
                                                values.phone_number = newValue;
                                            }}
                                        />
                                        {touched.phone_number &&
                                        errors.phone_number ? (
                                            <FormHelperText
                                                style={{
                                                    color: 'red'
                                                }}
                                            >
                                                {errors.phone_number}
                                            </FormHelperText>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="address"
                                            name="address"
                                            label="City/Residential address"
                                            placeholder="Enter residential address"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="university_attended"
                                            name="university_attended"
                                            label="University/College Attended"
                                            placeholder="Enter University/College name attended"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="id_no"
                                            name="id_no"
                                            label="ID No."
                                            placeholder="Enter ID Number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="kra_pin"
                                            name="kra_pin"
                                            label="KRA Pin"
                                            placeholder="Enter KRA Pin"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboSelectField
                                            width="100%"
                                            size={'small'}
                                            id="roleId"
                                            name="roleId"
                                            label="Role"
                                            value={role}
                                            placeholder="Select role"
                                            onChange={(event) =>
                                                setRole(event.target.value)
                                            }
                                        >
                                            <MenuItem key={'roleId'} value="">
                                                <em>Select Role</em>
                                            </MenuItem>
                                            {roles.map((role) => (
                                                <MenuItem
                                                    key={role.id}
                                                    value={role.id}
                                                >
                                                    {capitalizeAllWords(
                                                        role.role_name
                                                    )}
                                                </MenuItem>
                                            ))}
                                        </JumboSelectField>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboSelectField
                                            width="100%"
                                            size={'small'}
                                            id="designationId"
                                            name="designationId"
                                            label="Designation"
                                            value={designation}
                                            placeholder="Select designation"
                                            onChange={(event) =>
                                                setDesignation(
                                                    event.target.value
                                                )
                                            }
                                        >
                                            <MenuItem
                                                key={'designationId'}
                                                value=""
                                            >
                                                <em>
                                                    Select designation/title
                                                </em>
                                            </MenuItem>
                                            {designations.map((designation) => (
                                                <MenuItem
                                                    key={designation.id}
                                                    value={designation.id}
                                                >
                                                    {capitalizeAllWords(
                                                        designation.designation_name
                                                    )}
                                                </MenuItem>
                                            ))}
                                        </JumboSelectField>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboRadioGroup
                                            row
                                            labelid="employee-row"
                                            label="User is company Employee ?"
                                            aria-labelledby="employee-row"
                                            name="employee"
                                        >
                                            <FormControlLabel
                                                value="yes"
                                                control={<Radio size="small" />}
                                                label="Yes"
                                                onChange={
                                                    employeeRadioChangeHandler
                                                }
                                            />
                                            <FormControlLabel
                                                value="no"
                                                control={<Radio size="small" />}
                                                label="No"
                                                onChange={
                                                    employeeRadioChangeHandler
                                                }
                                            />
                                        </JumboRadioGroup>
                                    </Grid>
                                    {showEmpDetails && (
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <MobileDatePicker
                                                        label="Employment Date"
                                                        inputFormat="DD/MM/YYYY"
                                                        value={employmentDate}
                                                        onChange={(newValue) =>
                                                            setEmploymentDate(
                                                                newValue
                                                            )
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <JumboTextField
                                                                fullWidth
                                                                size={'small'}
                                                                id="employment_date"
                                                                name="employment_date"
                                                                value={
                                                                    employmentDate
                                                                }
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <JumboSelectField
                                                    width="100%"
                                                    size={'small'}
                                                    id="officeId"
                                                    name="officeId"
                                                    label="Designated Office"
                                                    value={designatedOffice}
                                                    placeholder="Select designated office"
                                                    onChange={(event) =>
                                                        setDesignatedOffice(
                                                            event.target.value
                                                        )
                                                    }
                                                >
                                                    <MenuItem
                                                        key={'officeId'}
                                                        value=""
                                                    >
                                                        <em>
                                                            Select designated
                                                            Office
                                                        </em>
                                                    </MenuItem>
                                                    {!showEmpDetails && (
                                                        <MenuItem
                                                            key={'officeIdn'}
                                                            value="none"
                                                        >
                                                            <em>
                                                                Select
                                                                designated
                                                                Office
                                                            </em>
                                                        </MenuItem>
                                                    )}
                                                    <MenuItem
                                                        key={'all'}
                                                        value="All"
                                                    >
                                                        Assign To All Offices
                                                    </MenuItem>

                                                    {offices.map((office) => (
                                                        <MenuItem
                                                            key={office.id}
                                                            value={office.id}
                                                        >
                                                            {capitalizeAllWords(
                                                                office.office_name
                                                            )}
                                                        </MenuItem>
                                                    ))}
                                                </JumboSelectField>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <JumboTextField
                                                    fullWidth
                                                    size={'small'}
                                                    id="salary"
                                                    name="salary"
                                                    label="Salary"
                                                    type="number"
                                                    min="0"
                                                    step="any"
                                                    placeholder="Enter employee salary"
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                    >
                                        Create Account
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo userItem, onSave prop define */
export default CreateForm;
