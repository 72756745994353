import React from 'react';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import ArticleIcon from '@mui/icons-material/Article';
import { useMutation } from 'react-query';
import { schoolsServices } from 'app/services/schools-services';
import { institutionsServices } from 'app/services/institutions-services';
import { MuiFileInput } from 'mui-file-input';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import axios from 'axios';
import { capitalizeAllWords } from 'app/utils/appHelpers';
import JumboFileInput from '@jumbo/components/JumboFormik/JumboFileInput';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const validationSchema = yup.object({});

const TEMPLATE_LINK = `${process.env.REACT_APP_BACKEND}/uploads/templates/csv/schools_csv_template.csv`;

const UploadCSVForm = ({
    item: schoolItem,
    onSave,
    hideDialog,
    userDetails
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const [file, setFile] = React.useState('');
    const [array, setArray] = React.useState([]);
    const [checked, setChecked] = React.useState(false);

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    const { mutate: uploadSchoolsCSVMutation, isLoading } = useMutation(
        schoolsServices.uploadSchoolsCSV,
        {
            onSuccess: (res) => {
                console.log(res);
                onSave();
                enqueueSnackbar(res.message, {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (data) => {
        if (!file) {
            enqueueSnackbar('Please upload a csv file.', {
                variant: 'error'
            });
            return;
        }
        try {
            const formData = new FormData();
            formData.append('schools_csv', file);
            uploadSchoolsCSVMutation(formData);
        } catch (error) {}
    };

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            <Div sx={{ flexGrow: 1 }}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                        <Formik
                            validateOnChange={true}
                            initialValues={{
                                csv: ''
                            }}
                            validationSchema={validationSchema}
                            onSubmit={async (data, { setSubmitting }) => {
                                await onCreate(data);
                            }}
                        >
                            {({
                                isSubmitting,
                                errors,
                                touched,
                                values,
                                setFieldValue,
                                setErrors,
                                ...restProps
                            }) => (
                                <Form
                                    style={{ textAlign: 'left' }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Div sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={12}>
                                                <Typography
                                                    variant={'h6'}
                                                    color={'text.   primary'}
                                                    mb={0.5}
                                                >
                                                    Upload Schools CSV File
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    size={'small'}
                                                    id="csv"
                                                    name="csv"
                                                    type="file"
                                                    accept=".csv"
                                                    placeholder="Upload CSV file"
                                                    onChange={handleOnChange}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            sx={{
                                                margin: '30px auto'
                                            }}
                                            container
                                            alignItems="right"
                                            justify="flex-end"
                                            justifyContent="right"
                                        >
                                            <Button
                                                size="large"
                                                sx={{ mb: 3 }}
                                                onClick={() => hideDialog()}
                                            >
                                                Cancel
                                            </Button>
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                size="large"
                                                sx={{ mb: 3 }}
                                                loading={isLoading}
                                            >
                                                Upload
                                            </LoadingButton>
                                        </Grid>
                                    </Div>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Alert variant="filled" severity="info">
                            <Typography
                                variant={'h2'}
                                color={'inherit'}
                                mb={0.5}
                            >
                                Instructions
                            </Typography>
                            <Div>
                                <Typography variant={'body1'}>
                                    {' '}
                                    1. Download the attached template
                                </Typography>
                                <Typography variant={'body1'}>
                                    2. Fill in the rows with data accordingly.
                                </Typography>
                                <Typography variant={'body1'} mb={0.5}>
                                    3. Upload the filled CSV file on the form on
                                    the left
                                </Typography>
                                <Typography variant={'body1'}>
                                    NOTE: DO NOT modify/insert/delete the
                                    columns
                                </Typography>
                            </Div>

                            <Button
                                component={Link}
                                target="_blank"
                                rel="noreferrer"
                                to={TEMPLATE_LINK}
                                role="button"
                                variant={'outlined'}
                                color="primary"
                                sx={{
                                    marginTop: '20px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    verticalAlign: 'bottom',
                                    cursor: 'pointer'
                                    // '&:hover': {
                                    //     color: 'white'
                                    // }
                                }}
                            >
                                <ArticleIcon />
                                Download Schools CSV Template
                            </Button>
                        </Alert>
                    </Grid>
                </Grid>
            </Div>
        </Div>
    );
};
/* Todo schoolItem, onSave prop define */
export default UploadCSVForm;
