const ApplicationStatus = [
    {
        id: 0,
        status: 'Cancelled',
        name: 'Cancelled',
        slug: 'Cancelled',
        color: '#eee',
        type: 'default'
    },
    {
        id: 1,
        status: 'In Progress',
        name: 'In Progress',
        slug: 'In Progress',
        color: '#ffd0cd',
        type: 'info'
    },
    {
        id: 2,
        status: 'Resubmitted',
        name: 'Resubmitted',
        slug: 'Resubmitted',
        color: '#cdffcf',
        type: 'warning'
    },

    {
        id: 3,
        status: 'Rejected',
        name: 'Rejected',
        slug: 'Rejected',
        color: '#ffd0cd',
        type: 'error'
    },
    {
        id: 4,
        status: 'Accepted/Approved',
        name: 'Accepted/Approved',
        slug: 'Accepted/Approved',
        color: '#cdffcf',
        type: 'success'
    }
];

export default ApplicationStatus;
