import jwtAuthAxios from './auth/jwtAuth';

const countriesServices = {};

countriesServices.getAllCountries = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/countries',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

countriesServices.getCountries = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/countries', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

countriesServices.getCountryDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/countries/country/${id}`, {
        id: id
    });

    return data;
};

countriesServices.addCountry = async (country) => {
    const { data } = await jwtAuthAxios.post(`/countries`, country);
    return data;
};

countriesServices.updateCountry = async (country) => {
    // console.log('Service Country');
    // console.log(country);
    const { data } = await jwtAuthAxios.patch(
        `/countries/${country.id}`,
        country
    );
    return data;
};

countriesServices.deleteCountry = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/countries/${id}`, {
        id: id
    });
    return data;
};

countriesServices.deleteMultipleCountries = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/countries`, {
        data: ids
    });
    return data;
};

export { countriesServices };
