import React from 'react';
import { Button, Typography } from '@mui/material';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Slide from '@mui/material/Slide';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import CourseCreateForm from 'app/pages/home/Students/StudentProfile/components/CourseCreateForm';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const PreferredCourses = ({ studentDetails, invalidateAndRefetch }) => {
    const { showDialog, hideDialog } = useJumboDialog();

    const handleUpdatePreferredCoursesForm = (event) => {
        showDialog({
            title: 'Update Preferred Courses',
            content: (
                <CourseCreateForm
                    studentDetails={studentDetails}
                    onUpdate={invalidateAndRefetch}
                    hideDialog={hideDialog}
                />
            ),
            maxWidth: 'sm',
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true
        });
    };

    return (
        <JumboCardQuick
            title={'Preferred Courses'}
            subheader={`List Of preferred Courses`}
            action={
                <Stack
                    direction={'row'}
                    justifyContent={'space-evenly'}
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                    sx={{
                        mx: 1
                    }}
                >
                    {/* <Button
                        variant="contained"
                        size="small"
                        onClick={handleUpdatePreferredCoursesForm}
                    >
                        Update Preferred Courses
                    </Button> */}
                </Stack>
            }
            headerSx={{ pb: 0 }}
            sx={{ mb: 2 }}
        >
            <Typography variant="body1" mb={2}></Typography>

            <List
                disablePadding
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: 0,
                    maxHeight: '300px',
                    overflowY: 'scroll'
                }}
            >
                {studentDetails?.preferred_courses?.option_one && (
                    <MenuItem sx={{ width: '98%', whiteSpace: 'pre-wrap' }}>
                        <strong>1: </strong>
                        {studentDetails?.preferred_courses?.option_one}
                    </MenuItem>
                )}

                {studentDetails?.preferred_courses?.option_two && (
                    <MenuItem sx={{ width: '98%', whiteSpace: 'pre-wrap' }}>
                        <strong>2: </strong>
                        {studentDetails?.preferred_courses?.option_two}
                    </MenuItem>
                )}

                {studentDetails?.preferred_courses?.option_three && (
                    <MenuItem sx={{ width: '98%', whiteSpace: 'pre-wrap' }}>
                        <strong>3: </strong>
                        {studentDetails?.preferred_courses?.option_three}
                    </MenuItem>
                )}

                {!studentDetails?.preferred_courses?.option_one &&
                    !studentDetails?.preferred_courses?.option_two &&
                    !studentDetails?.preferred_courses?.option_three && (
                        <MenuItem>No courses info yet</MenuItem>
                    )}
            </List>
        </JumboCardQuick>
    );
};

export default PreferredCourses;
