import React from 'react';
import ListingDashboard from '../pages/dashboards/overview/ListingDashboard';
import Page from '@jumbo/shared/Page';
import authRoles from 'app/config/authRoles';

const dashboardRoutes = {
    settings: {
        layout: {}
    },
    auth: authRoles?.consultant,
    routes: [
        {
            action: 'read',
            subject: 'Dashboard',
            conditions: {},
            path: '/dashboards/overview',
            element: <Page component={ListingDashboard} />
        }
    ]
};

export default dashboardRoutes;
