import React from 'react';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'app/AppAbility';
import {
    ListItemText,
    Tooltip,
    Typography,
    ListItemAvatar
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Div from '@jumbo/shared/Div';
import styled from '@emotion/styled';
import Span from '@jumbo/shared/Span';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Slide from '@mui/material/Slide';
import Edit from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { userServices } from 'app/services/user-services';
import { useMutation } from 'react-query';
import {
    capitalizeAllWords,
    capitalizeFirstLetter
} from 'app/utils/appHelpers';

import moment from 'moment';
import useUsersApp from '../../hooks/useUsersApp';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import EditForm from '../EditForm';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

const Item = styled(Span)(({ theme }) => ({
    padding: theme.spacing(0, 1)
}));

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const ListItem = ({ listItem }) => {
    const { theme } = useJumboTheme();
    const { authUser } = useJumboAuth();

    const ability = useAbility(AbilityContext);
    const allowRead = ability.can('read', 'User');
    const allowEdit = ability.can('update', 'User');
    const allowDelete = ability.can('delete', 'User');

    const { setListRefresh } = useUsersApp();
    const { showDialog, hideDialog } = useJumboDialog();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const handleItemClick = () => {
        navigate(`/settings/users/user/${listItem.id.toString()}`);
        return;
    };

    const deleteMutation = useMutation(userServices.deleteUser, {
        onSuccess: () => {
            hideDialog();
            setListRefresh(true);
            enqueueSnackbar('User has been deleted successfully.', {
                variant: 'success'
            });
        },
        onError: (error) => {
            hideDialog();
            enqueueSnackbar(error, {
                variant: 'error'
            });

            return false;
        }
    });
    const itemMoveToTrash = React.useCallback(
        (userId) => {
            showDialog({
                variant: 'confirm',
                title: 'Delete!',
                content: 'Are you sure? Select Yes to Continue',
                onYes: () => {
                    deleteMutation.mutate(userId);
                },
                onNo: hideDialog
            });
        },
        [deleteMutation, hideDialog]
    );

    const handleUpdatedItem = React.useCallback(() => {
        hideDialog();
        setListRefresh(true);
    }, [hideDialog]);

    const handleUpdateForm = (event) => {
        showDialog({
            title: 'Edit User',
            content: (
                <EditForm
                    listItem={listItem}
                    authUser={authUser}
                    onUpdate={handleUpdatedItem}
                    hideDialog={hideDialog}
                />
            ),
            maxWidth: 'lg',
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true
        });
    };

    const profilePic = listItem
        ? process.env.REACT_APP_BACKEND + '/' + listItem?.profile_pic
        : null;

    return (
        <React.Fragment>
            <JumboListItem
                componentElement={'div'}
                itemData={listItem}
                secondaryAction={null}
                sx={{
                    cursor: 'pointer',
                    borderTop: 1,
                    borderColor: 'divider',
                    '&:hover': {
                        bgcolor: 'action.hover',

                        '& .ListAction': {
                            width: { sm: '100%' },
                            opacity: { sm: 1 }
                        },

                        '& .ListTextExtra': {
                            visibility: { sm: 'hidden' },
                            opacity: { sm: 0 }
                        }
                    }
                }}
            >
                <ListItemAvatar onClick={() => allowRead && handleItemClick()}>
                    <Avatar
                        alt={`${listItem?.first_name} ${listItem?.last_name}`}
                        src={profilePic}
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Typography variant={'body1'} component={'div'}>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                sx={{
                                    minWidth: 0
                                    //background: 'yellow'
                                }}
                            >
                                <Item
                                    onClick={() =>
                                        allowRead && handleItemClick()
                                    }
                                    sx={{
                                        flexBasis: {
                                            xs: '70%',
                                            sm: '22%',
                                            md: '22%'
                                        }
                                        //background: '#eee'
                                    }}
                                >
                                    <Typography
                                        variant={'h5'}
                                        fontSize={14}
                                        lineHeight={1.25}
                                        mb={0}
                                        noWrap
                                    >
                                        {listItem?.role_name === 'hr'
                                            ? listItem?.role_name.toUpperCase()
                                            : capitalizeFirstLetter(
                                                  listItem?.first_name +
                                                      ' ' +
                                                      listItem?.last_name
                                              )}
                                    </Typography>
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        sx={{
                                            display: {
                                                xs: 'block',
                                                sm: 'block'
                                            }
                                        }}
                                    >
                                        {capitalizeAllWords(
                                            listItem?.roleId?.role_name
                                        )}
                                    </Typography>
                                </Item>
                                <Item
                                    onClick={() =>
                                        allowRead && handleItemClick()
                                    }
                                    sx={{
                                        flexBasis: { sm: '16%', md: '16%' },
                                        display: { xs: 'none', sm: 'block' }
                                        //background: 'green'
                                    }}
                                >
                                    <Typography variant={'body1'} noWrap>
                                        {listItem?.phone_number}
                                    </Typography>
                                </Item>
                                <Item
                                    onClick={() =>
                                        allowRead && handleItemClick()
                                    }
                                    sx={{
                                        flexBasis: { sm: '32%', md: '32%' },
                                        display: { xs: 'none', sm: 'block' }
                                        //background: '#333'
                                    }}
                                >
                                    <Typography variant={'body1'}>
                                        {listItem?.email_address}
                                    </Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { sm: '30%', md: '30%' },
                                        display: { xs: 'none', sm: 'block' }
                                        //background: 'red'
                                    }}
                                >
                                    <Div>
                                        {listItem?.officeId ? (
                                            <JumboChipsGroup
                                                chips={
                                                    listItem?.officeId.length
                                                        ? _.map(
                                                              listItem?.officeId,
                                                              ({
                                                                  id,
                                                                  office_name
                                                              }) => ({
                                                                  id,
                                                                  name: office_name,
                                                                  slug: office_name,
                                                                  color: '#E0CDFF'
                                                              })
                                                          )
                                                        : [
                                                              {
                                                                  id:
                                                                      Math.random() *
                                                                      10000000,
                                                                  name: 'No Office Assigned',
                                                                  slug: 'inactive',
                                                                  color: '#ca404f'
                                                              }
                                                          ]
                                                }
                                                mapKeys={{ label: 'name' }}
                                                spacing={1}
                                                size={'small'}
                                                max={1}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </Div>
                                </Item>
                                {/* <Item
                                    sx={{
                                        flexBasis: { sm: '10%', md: '10%' },
                                        display: { xs: 'none', sm: 'block' }
                                        //background: '#333'
                                    }}
                                >
                                    <Stack
                                        spacing={2}
                                        direction={'row'}
                                        alignItems={'center'}
                                        sx={{ textAlign: 'center' }}
                                    >
                                        <Item>
                                            <Typography variant={'h6'} mb={0.5}>
                                                {listItem?.leads
                                                    ? listItem?.leads.length
                                                    : 0}
                                            </Typography>
                                            <Typography
                                                variant={'body1'}
                                                color="text.secondary"
                                            >
                                                Leads
                                            </Typography>
                                        </Item>
                                    </Stack>
                                </Item> */}
                            </Stack>
                        </Typography>
                    }
                />

                <Div
                    sx={{
                        width: 110,
                        display: 'flex',
                        flexShrink: '0',
                        position: 'relative',
                        justifyContent: 'flex-end',
                        // background: '#333',s

                        [theme.breakpoints.down('sm')]: {
                            width: '100%',
                            justifyContent: 'space-between'
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            flex: 1,
                            transition: 'all 0.5s ease',

                            [theme.breakpoints.down('sm')]: {
                                justifyContent: 'flex-start',
                                ml: 6
                            }
                        }}
                        className={'ListTextExtra'}
                    >
                        <JumboChipsGroup
                            chips={
                                listItem?.user_account_active
                                    ? [
                                          {
                                              id: 1,
                                              name: 'Active',
                                              slug: 'active',
                                              color: '#E0CDFF'
                                          }
                                      ]
                                    : [
                                          {
                                              id: 1,
                                              name: 'Disabled',
                                              slug: 'disabled',
                                              color: '#ca404f'
                                          }
                                      ]
                            }
                            mapKeys={{ label: 'name' }}
                            spacing={1}
                            size={'small'}
                            max={1}
                        />

                        <Typography
                            variant={'body1'}
                            color={'text.secondary'}
                            ml={1}
                        >
                            {moment(listItem?.date_created).format('MMM DD')}
                        </Typography>
                    </Div>
                    <Div
                        className="ListAction"
                        sx={{
                            display: 'flex',

                            [theme.breakpoints.up('sm')]: {
                                position: 'absolute',
                                top: '50%',
                                width: 0,
                                opacity: 0,
                                overflow: 'hidden',
                                transition: 'all 0.5s ease',
                                justifyContent: 'flex-end',
                                transform: 'translateY(-50%)'
                            }
                        }}
                    >
                        <Span sx={{ height: 36, overflow: 'hidden' }}>
                            {allowRead && (
                                <Tooltip title="View Details">
                                    <IconButton
                                        onClick={() =>
                                            allowRead && handleItemClick()
                                        }
                                    >
                                        <VisibilityIcon fontSize={'small'} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {allowEdit && (
                                <Tooltip title="Edit">
                                    <IconButton onClick={handleUpdateForm}>
                                        <Edit fontSize={'small'} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {allowDelete && (
                                <Tooltip title="Delete">
                                    <IconButton
                                        onClick={() =>
                                            itemMoveToTrash(listItem?.id)
                                        }
                                    >
                                        <DeleteIcon
                                            fontSize={'small'}
                                            sx={{ color: '#ca404f' }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Span>
                    </Div>
                </Div>
            </JumboListItem>
        </React.Fragment>
    );
};

export default ListItem;
