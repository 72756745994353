import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const leadsServices = {};

leadsServices.getAllLeads = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/leads',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

leadsServices.getLeadsSummary = async (queryOptions, signal) => {
    let params = {};
    if (queryOptions?.queryParams?.officeId) {
        params = { officeId: queryOptions?.queryParams?.officeId };
    }
    const { data } = await jwtAuthAxios.get('/leads/summary', {
        params: { ...params }
    });

    // console.log(data);

    return data;
};

leadsServices.getLeads = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/leads', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });

    // console.log(data);

    return data;
};

leadsServices.getLeadDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/leads/lead/${id}`, {
        id: id
    });
    // console.log('service');
    // console.log(data);
    // console.log(data.lead.first_name);
    // console.log(JSON.parse(data.lead));
    // console.log(data.lead.first_name);

    return data;
};

leadsServices.addLead = async (lead) => {
    const { data } = await jwtAuthAxios.post(`/leads`, lead);
    return data;
};

leadsServices.updateLead = async (lead) => {
    const config = !lead?.files
        ? {}
        : {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          };

    const { data } = await jwtAuthAxios.patch(
        `/leads/${lead.id}`,
        lead.formikData,
        config
    );
    return data;
};

leadsServices.deleteLead = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/leads/${id}`, {
        id: id
    });
    return data;
};

leadsServices.exportLeads = async (options, signal) => {
    const { data } = await jwtAuthAxios.get(
        '/leads',
        {
            params: {
                exportAction: true
            }
        },
        { ...signal }
    );

    return data;
};

leadsServices.deleteMultipleLeads = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/leads`, {
        data: ids
    });
    return data;
};

export { leadsServices };
