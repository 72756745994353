import React from 'react';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Link from '@mui/material/Link';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';

const Contacts = ({ userDetails }) => {
    return (
        <JumboCardQuick title={'Contact'} noWrapper>
            <List disablePadding sx={{ mb: 2 }}>
                <ListItem
                    alignItems="flex-start"
                    sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                >
                    <ListItemIcon
                        sx={{ minWidth: 36, color: 'text.secondary' }}
                    >
                        <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="body1" color="text.secondary">
                                Name
                            </Typography>
                        }
                        secondary={
                            <Link variant="body1" href="#" underline="none">
                                {userDetails.first_name +
                                    ' ' +
                                    userDetails.middle_name +
                                    ' ' +
                                    userDetails.last_name}
                            </Link>
                        }
                    />
                </ListItem>
                <ListItem
                    alignItems="flex-start"
                    sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                >
                    <ListItemIcon
                        sx={{ minWidth: 36, color: 'text.secondary' }}
                    >
                        <EmailOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="body1" color="text.secondary">
                                Email
                            </Typography>
                        }
                        secondary={
                            <Link variant="body1" href="#" underline="none">
                                {userDetails.email_address}
                            </Link>
                        }
                    />
                </ListItem>

                <ListItem
                    alignItems="flex-start"
                    sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                >
                    <ListItemIcon
                        sx={{ minWidth: 36, color: 'text.secondary' }}
                    >
                        <LocalPhoneOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="body1" color="text.secondary">
                                Phone
                            </Typography>
                        }
                        secondary={
                            <Typography variant="body1" color="text.primary">
                                {userDetails.phone_number}
                            </Typography>
                        }
                    />
                </ListItem>
            </List>
        </JumboCardQuick>
    );
};

export default Contacts;
