import React, { useState } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { saveAs } from 'file-saver';
import * as XLSX from 'sheetjs-style';
import moment from 'moment';

export default function ExcelExport({ queryService, options, file_name }) {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);

    const handleExport = React.useCallback(async () => {
        setIsLoading(true);
        const cancelToken = axios.CancelToken.source();
        const token = { cancelToken: cancelToken.token };
        try {
            const res = await queryService(options, token);
            let data = res[options.dataKey];
            console.log('Export Data');
            console.log(data);

            if (data && data.length > 0) {
                const ws = XLSX.utils.json_to_sheet(data);
                const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, {
                    bookType: 'xlsx',
                    type: 'array'
                });
                const exportData = new Blob([excelBuffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
                });

                saveAs(
                    exportData,
                    `${file_name}_${moment(new Date()).format(
                        'YYYY-MM-DD'
                    )}.xlsx`
                );
            }
        } catch (error) {
            console.log(error);
            enqueueSnackbar(error, {
                variant: 'error'
            });
        }

        setIsLoading(false);
    }, [queryService, options, file_name]);

    return (
        <LoadingButton
            disableElevation
            variant={'contained'}
            startIcon={<GetAppIcon />}
            sx={{
                mb: 1,
                '& .MuiSvgIcon-root': {
                    fontSize: '1.5rem'
                }
            }}
            color="info"
            loading={isLoading}
            onClick={handleExport}
        >
            Export
        </LoadingButton>
    );
}
