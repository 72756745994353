import React from 'react';
import { Card, CardContent, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Div from '@jumbo/shared/Div';
import { alpha } from '@mui/material/styles';
import { ASSET_IMAGES } from '../../../utils/constants/paths';
import { getAssetPath } from '../../../utils/appHelpers';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import authServices from '../../../services/auth-services';
import { useSnackbar } from 'notistack';

const validationSchema = yup.object({
    new_password: yup.string().required('Please enter password'),
    confirm_password: yup
        .string()
        .oneOf([yup.ref('new_password'), null], 'Passwords must match')
});

const Reset = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();

    const onReset = async (formData) => {
        try {
            const data = await authServices.changeResetPassword({
                ...formData,
                ...params
            });

            enqueueSnackbar(
                'Password reset successful. Sign in with your new credentials.',
                {
                    variant: 'success'
                }
            );

            // basicAlert('error', 'Sign In Failed', err);
            // setAuthToken(data?.token);
            setTimeout(() => {
                navigate('/user/login');
            }, 500);
        } catch (err) {
            console.log(err);
            enqueueSnackbar('err', {
                variant: 'error'
            });
        }
    };

    return (
        <Div sx={{ width: 720, maxWidth: '100%', margin: 'auto', p: 4 }}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        background: `#0267a0 url(${getAssetPath(
                            `${ASSET_IMAGES}/widgets/keith-luke.jpg`
                        )}) no-repeat center`,
                        backgroundSize: 'cover',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#0267a0', 0.65)
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%',
                            minHeight: { md: 320 }
                        }}
                    >
                        <Div sx={{ mb: 2 }}>
                            <Typography
                                variant={'h3'}
                                color={'inherit'}
                                fontWeight={500}
                                mb={3}
                            >
                                Reset Password
                            </Typography>
                            <Typography
                                variant={'body1'}
                                mb={1}
                                sx={{ maxWidth: 270 }}
                            >
                                By entering your registered email address you
                                will receive reset password link, Kindly follow
                                instruction.
                            </Typography>
                        </Div>
                        <Div sx={{ mt: 'auto' }}>
                            <Link
                                href="#"
                                underline="none"
                                sx={{ display: 'inline-flex' }}
                            >
                                <img
                                    src={`${ASSET_IMAGES}/IEC-logo-128.png`}
                                    alt="IEC Consultants"
                                />
                            </Link>
                        </Div>
                    </Div>
                </CardContent>
                <CardContent sx={{ flex: 1, p: 4 }}>
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            height: '100%'
                        }}
                    >
                        <Div sx={{ mb: 2 }}>
                            <Formik
                                validateOnChange={true}
                                initialValues={{
                                    new_password: '',
                                    confirm_password: ''
                                }}
                                validationSchema={validationSchema}
                                onSubmit={async (data, { setSubmitting }) => {
                                    setSubmitting(true);
                                    await onReset(data);
                                    setSubmitting(false);
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Form
                                        style={{ textAlign: 'left' }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <Div sx={{}}>
                                            <JumboTextField
                                                type="email"
                                                name="username"
                                                required
                                                fullWidth
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                                hidden
                                                sx={{ display: 'none' }}
                                            />
                                        </Div>
                                        <Div sx={{ mb: 3 }}>
                                            <JumboTextField
                                                id="new_password"
                                                name="new_password"
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                                label="Enter New Password"
                                                placeholder="New Password"
                                                type="password"
                                                fullWidth
                                            />
                                        </Div>
                                        <Div sx={{ mb: 3 }}>
                                            <JumboTextField
                                                id="confirm_password"
                                                name="confirm_password"
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                                label="Enter Confirm Password"
                                                placeholder="Confirm Password"
                                                type="password"
                                                fullWidth
                                            />
                                        </Div>
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{ mb: 3 }}
                                            loading={isSubmitting}
                                        >
                                            Reset Password
                                        </LoadingButton>
                                    </Form>
                                )}
                            </Formik>
                        </Div>
                        <Typography variant={'body1'} mt={'auto'}>
                            Already have an account?{' '}
                            <Link
                                to={'/user/login'}
                                underline={'none'}
                                sx={{ cursor: 'pointer' }}
                            >
                                Login Now
                            </Link>
                            {/* Or <Link href={"#/"} underline={'none'}>Create New</Link> */}
                        </Typography>
                    </Div>
                </CardContent>
            </Card>
        </Div>
    );
};

export default Reset;
