import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
    alpha,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Chip,
    CircularProgress,
    Alert,
    Button
} from '@mui/material';

import NumbersIcon from '@mui/icons-material/Numbers';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'; //Employees
import DescriptionIcon from '@mui/icons-material/Description';
import Span from '@jumbo/shared/Span';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import Div from '@jumbo/shared/Div';
import moment from 'moment';
import _ from 'lodash';
import { capitalizeAllWords, formatNumber } from 'app/utils/appHelpers';
import ReportStatus from 'app/pages/statuses/ReportStatus';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from 'react-query';
import { reportsServices } from 'app/services/reports-services';
import { useSnackbar } from 'notistack';
import MySwal from 'app/hooks/MySwal/MySwal';

const ReportDetail = ({
    report,
    authUser,
    onSave = () => {},
    onClose = () => {}
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const { REACT_APP_BACKEND } = process.env;
    const profilePic = report?.employeeId?.profile_pic
        ? process.env.REACT_APP_BACKEND + '/' + report?.employeeId?.profile_pic
        : null;

    const handleDownload = (file) => {
        const link = document.createElement('a');
        link.href = `${REACT_APP_BACKEND}/${file}`;
        // link.download = file;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
    };

    const [reportStatus, setReportStatus] = React.useState([
        {
            id: 0,
            name: 'Inactive',
            slug: 'inactive',
            color: '#ca404f'
        }
    ]);

    const { mutate: cancelReportMutation, isLoading: isCancelling } =
        useMutation(reportsServices.cancelReport, {
            onSuccess: () => {
                enqueueSnackbar(
                    'Report status has been updated successfully.',
                    {
                        variant: 'success'
                    }
                );
                onSave();
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(
                    error?.message ? error.message : error.toString(),
                    {
                        variant: 'error'
                    }
                );

                return false;
            }
        });

    const onCancel = async (reportId) => {
        try {
            cancelReportMutation({
                reportId
            });
        } catch (error) {}
    };

    const confirmCancel = (id) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Cancel it!',
            cancelButtonText: 'No, Keep it!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                onCancel(id);
            } else {
                MySwal.fire(
                    'We keeping it',
                    'Your report is still active :)',
                    'info'
                );
            }
        });
    };

    React.useEffect(() => {
        setReportStatus(
            _.find(ReportStatus, (status) => status.id === report?.status)
        );
    }, [report]);

    return (
        <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
            <CardHeader
                title={`${report?.report_name}`}
                subheader={
                    <Div
                        sx={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography
                            variant={'body1'}
                            color={'text.secondary'}
                            mr={0.5}
                        >
                            {`${report?.employeeId?.first_name} ${report?.employeeId?.last_name}`}
                        </Typography>
                        <JumboChipsGroup
                            chips={
                                report
                                    ? [reportStatus]
                                    : [
                                          {
                                              id: 0,
                                              name: 'Inactive',
                                              slug: 'inactive',
                                              color: '#ca404f'
                                          }
                                      ]
                            }
                            mapKeys={{ label: 'name' }}
                            spacing={1}
                            size={'small'}
                            max={1}
                        />
                    </Div>
                }
                avatar={<Avatar src={profilePic} />}
                action={
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List disablePadding>
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <NumbersIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Report ID #
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {`IEC-${String(
                                            report?.report_id
                                        ).padStart(8, '0')}`}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <AccountBalanceWalletIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Related Expense
                                    </Typography>
                                }
                                secondary={
                                    <Div
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <Typography variant={'h5'} mb={0}>
                                            {report?.expenseId?.expense_name}
                                        </Typography>
                                        <Divider sx={{ my: '5px' }} />

                                        <Div
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                verticalAlign: 'middle',
                                                alignItems: 'center',
                                                my: '5px'
                                            }}
                                        >
                                            <Typography
                                                variant={'h5'}
                                                sx={{
                                                    width: '50%',
                                                    // fontWeight: 300,
                                                    my: '5px',
                                                    color: 'primary.main'
                                                }}
                                            >
                                                Expense Amount
                                            </Typography>
                                            <Typography
                                                variant={'h5'}
                                                mb={0}
                                                color="text.secondary"
                                                sx={{
                                                    width: '50%',
                                                    textAlign: 'right',
                                                    my: '5px',
                                                    color: 'primary.main'
                                                }}
                                            >
                                                {formatNumber(
                                                    report?.expenseId
                                                        ?.total_amount
                                                )}
                                            </Typography>
                                        </Div>
                                    </Div>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <AccountBalanceWalletIcon
                                        sx={{
                                            color: 'primary.light'
                                        }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Submitted Expenses
                                    </Typography>
                                }
                                secondary={
                                    <Div
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        {report?.expenses &&
                                            _.map(
                                                report?.expenses,
                                                (expense) => (
                                                    <Div
                                                        key={expense?.id}
                                                        sx={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            verticalAlign:
                                                                'middle',
                                                            alignItems:
                                                                'center',
                                                            my: '5px'
                                                        }}
                                                    >
                                                        <Typography
                                                            variant={'h5'}
                                                            sx={{
                                                                width: '33%',
                                                                fontWeight: 400,
                                                                my: '5px'
                                                            }}
                                                        >
                                                            {
                                                                expense?.expense_type_name
                                                            }
                                                        </Typography>
                                                        <Typography
                                                            variant={'h5'}
                                                            color="text.secondary"
                                                            sx={{
                                                                width: '33%',
                                                                textAlign:
                                                                    'right',
                                                                my: '5px'
                                                            }}
                                                        >
                                                            {expense?.amount
                                                                ? formatNumber(
                                                                      expense?.amount
                                                                  )
                                                                : ''}
                                                        </Typography>
                                                        <Span
                                                            sx={{
                                                                width: '33%',
                                                                textAlign:
                                                                    'right'
                                                            }}
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                size="small"
                                                                color="info"
                                                                onClick={() =>
                                                                    handleDownload(
                                                                        expense?.document_file
                                                                    )
                                                                }
                                                            >
                                                                Receipt
                                                            </Button>
                                                        </Span>
                                                    </Div>
                                                )
                                            )}
                                    </Div>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <MonetizationOnIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Total Amount Submitted
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {formatNumber(
                                            report?.total_amount || 0
                                        )}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <MonetizationOnIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Advance
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {formatNumber(
                                            report?.advance_amount || 0
                                        )}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <MonetizationOnIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Reimbursement
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {formatNumber(
                                            report?.reimbursement_amount || 0
                                        )}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                        <ListItem sx={{ px: 4 }}>
                            <ListItemAvatar sx={{ minWidth: 66 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 48,
                                        width: 48,
                                        bgcolor: (theme) =>
                                            alpha(
                                                theme.palette.primary.main,
                                                0.15
                                            )
                                    }}
                                >
                                    <DescriptionIcon
                                        sx={{ color: 'primary.light' }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        mb={0.5}
                                    >
                                        Purpose
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant={'h5'} mb={0}>
                                        {report?.purpose}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component={'li'} />
                    </List>
                </Grid>
            </Grid>
        </Div>
    );
};

export default ReportDetail;
