import React, { forwardRef } from 'react';
import { useField } from 'formik';
import JumboAvatarDropzone from '@jumbo/components/JumboAvatarDropzone';

const JumboAvatarField = forwardRef((props, ref) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';

    return (
        <JumboAvatarDropzone
            src={field.value}
            {...props}
            ref={ref}
            {...field}
            helperText={errorText}
            error={!!errorText}
        />
    );
});

export default JumboAvatarField;
