export const categories = [
    { id: `Certificate`, category_name: `Certificate` },
    { id: `Foundation`, category_name: `Foundation` },
    {
        id: `University Transfer Program I`,
        category_name: `University Transfer Program I`
    },
    { id: `International Year Zero`, category_name: `International Year Zero` },
    {
        id: `University Transfer Program II`,
        category_name: `University Transfer Program II`
    },
    { id: `International Year 1`, category_name: `International Year 1` },
    { id: `Global First Year`, category_name: `Global First Year` },
    { id: `Associate Degree`, category_name: `Associate Degree` },
    { id: `Diploma`, category_name: `Diploma` },
    { id: `Advanced Diploma`, category_name: `Advanced Diploma` },
    { id: `Bachelor's Degree`, category_name: `Bachelor's Degree` },
    {
        id: `Post-Graduate Certificate`,
        category_name: `Post-Graduate Certificate`
    },
    {
        id: `Post-Baccalaureate Certificate`,
        category_name: `Post-Baccalaureate Certificate`
    },
    { id: `Post-Graduate Diploma`, category_name: `Post-Graduate Diploma` },
    {
        id: `Post-Baccalaureate Diploma`,
        category_name: `Post-Baccalaureate Diploma`
    },
    { id: `Master's Degree`, category_name: `Master's Degree` }
];

export const COURSE_TYPES = [
    {
        id: 'Foundation (Pre-University)',
        category_name: 'Foundation (Pre-University)'
    },
    { id: 'Diploma', category_name: 'Diploma' },
    { id: 'Degree', category_name: 'Degree' },
    { id: 'Masters', category_name: 'Masters' },
    { id: 'Non-Credit', category_name: 'Non-Credit' },
    { id: 'Phd', category_name: 'Phd' },
    {
        id: 'Post-Diploma Certificate & Certificate bundle',
        category_name: 'Post-Diploma Certificate & Certificate bundle'
    },
    {
        id: 'Others',
        category_name: 'Others'
    }
];
