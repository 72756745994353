import React from 'react';

import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { useMutation } from 'react-query';
import { schoolsServices } from 'app/services/schools-services';
import { countriesServices } from 'app/services/countries-services';
import { requirementServices } from 'app/services/requirement-services';

import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import axios from 'axios';

import CircularProgress from '@mui/material/CircularProgress';

import _ from 'lodash';

const validationSchema = yup.object({
    school_name: yup
        .string('Enter school name')
        .required('School name is required'),
    // phone_number: yup
    //     .string('Enter the school contact phone number')
    //     .required('Phone number is required'),
    // email_address: yup
    //     .string('Enter school email')
    //     .email('Enter a valid email')
    //     .required('Email is required'),
    // address: yup
    //     .string('Enter the school address')
    //     .required('School address is required'),
    countryId: yup
        .string('Select country')
        .required('Country is required')
        .typeError('Select country from the available options'),
    city_name: yup
        .string('Enter the school location city')
        .required('City is required')

    // contact_first_name: yup
    //     .string('Enter contact person first name')
    //     .required('Contact person first name is required'),
    // contact_last_name: yup
    //     .string('Enter contact person last name')
    //     .required('Contact person last name is required'),
    // contact_phone_number: yup
    //     .string('Enter contact person phone number')
    //     .required('Contact person Phone number is required'),
    // contact_email_address: yup
    //     .string('Enter contact person email address')
    //     .email('Enter a valid email address')
    //     .required('Contact person email address is required')
});

const CreateForm = ({ onSave, hideDialog }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [countries, setCountries] = React.useState(false);
    const [requirements, setRequirements] = React.useState(false);
    const [countryId, setCountry] = React.useState(null);
    const [phone, setPhone] = React.useState('+254');
    const [contactPhone, setContactPhone] = React.useState('+254');

    const { mutate: addSchoolMutation, isLoading } = useMutation(
        schoolsServices.addSchool,
        {
            onSuccess: () => {
                onSave();
                enqueueSnackbar('School has been created successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (school) => {
        try {
            addSchoolMutation({ ...school });
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };
        const fetchCountries = async () => {
            try {
                const res = await countriesServices.getAllCountries(cToken);

                setCountries(res.countries);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchCountries();

        return () => {
            cancelToken.cancel();
        };
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {!countries && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {countries && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        school_name: '',
                        phone_number: '',
                        email_address: '',
                        address: '',
                        countryId: null,
                        city_name: '',
                        contact_first_name: '',
                        contact_last_name: '',
                        contact_phone_number: '',
                        contact_email_address: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onCreate(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            {/* <div>{JSON.stringify(errors)}</div> */}
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="school_name"
                                            name="school_name"
                                            label="School Name"
                                            placeholder="Enter School Name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field name="countryId">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    id="countryId"
                                                    sx={{
                                                        width: 500,
                                                        maxWidth: '100%'
                                                    }}
                                                    size="small"
                                                    options={countries}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.country_name
                                                            ? option.country_name
                                                            : null
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                '& > img': {
                                                                    mr: 2,
                                                                    flexShrink: 0
                                                                }
                                                            }}
                                                            {...props}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                                                                alt=""
                                                            />
                                                            {
                                                                option.country_name
                                                            }
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Country"
                                                            placeholder="Choose a country"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.countryId &&
                                                                !!errors.countryId
                                                            }
                                                            helperText={
                                                                touched?.countryId &&
                                                                errors.countryId
                                                            }
                                                        />
                                                    )}
                                                    onChange={
                                                        (event, newValue) => {
                                                            values.countryId =
                                                                newValue?.id;
                                                        }
                                                        // Set the new value in the form field
                                                    }
                                                    value={
                                                        countries.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.countryId
                                                        ) || null
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="city_name"
                                            name="city_name"
                                            label="Enter City"
                                            placeholder="Type the school location city"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="address"
                                            name="address"
                                            label="Physical Address"
                                            placeholder="Enter the physical address here"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MuiTelInput
                                            fullWidth
                                            size={'small'}
                                            id="phone_number"
                                            name="phone_number"
                                            label="Phone Number"
                                            value={phone}
                                            placeholder="Enter phone number"
                                            preferredCountries={['KE', 'ZM']}
                                            defaultCountry="KE"
                                            onChange={(newValue) => {
                                                setPhone(newValue);
                                                values.phone_number = newValue;
                                            }}
                                        />
                                        {touched.phone_number &&
                                        errors.phone_number ? (
                                            <FormHelperText
                                                style={{
                                                    color: 'red'
                                                }}
                                            >
                                                {errors.phone_number}
                                            </FormHelperText>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="email_address"
                                            name="email_address"
                                            label="Email Address"
                                            placeholder="Enter School Name"
                                            type="email"
                                        />
                                    </Grid>
                                </Grid>
                                <Divider
                                    component={'div'}
                                    sx={{ margin: '20px auto' }}
                                />
                                <Typography variant="h5" component="h5">
                                    Contact Person Details
                                </Typography>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="contact_first_name"
                                            name="contact_first_name"
                                            label="First Name"
                                            placeholder="Enter Contact First Name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="contact_last_name"
                                            name="contact_last_name"
                                            label="Last Name"
                                            placeholder="Enter Contact Last Name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MuiTelInput
                                            fullWidth
                                            size={'small'}
                                            id="contact_phone_number"
                                            name="contact_phone_number"
                                            label="Phone Number"
                                            value={contactPhone}
                                            placeholder="Enter Contact person phone number"
                                            preferredCountries={[
                                                'KE',
                                                'ZM',
                                                'AU',
                                                'US',
                                                'UK',
                                                'CA',
                                                'MY'
                                            ]}
                                            defaultCountry="KE"
                                            onChange={(newValue) => {
                                                setContactPhone(newValue);
                                                values.contact_phone_number =
                                                    newValue;
                                            }}
                                        />
                                        {touched.contact_phone_number &&
                                        errors.contact_phone_number ? (
                                            <FormHelperText
                                                style={{
                                                    color: 'red'
                                                }}
                                            >
                                                {errors.contact_phone_number}
                                            </FormHelperText>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="contact_email_address"
                                            name="contact_email_address"
                                            label="Email Address"
                                            placeholder="Enter contact person email address"
                                            type="email"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                    >
                                        Save
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo schoolItem, onSave prop define */
export default CreateForm;
