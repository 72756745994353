import Page from '@jumbo/shared/Page';

import UsersList from '../pages/settings/Users/UsersList';
import UserProfile from 'app/pages/settings/Users/UserProfile';
import RolesList from 'app/pages/settings/Roles/RolesList';
import DesignationsList from 'app/pages/settings/Designations/DesignationsList';

import EducationlevelsList from 'app/pages/settings/Educationlevels/EducationlevelsList';
import LeavetypesList from 'app/pages/settings/Leavetypes/LeavetypesList';
import ExpensetypesList from 'app/pages/settings/Expensetypes/ExpensetypesList';
import IncometypesList from 'app/pages/settings/Incometypes/IncometypesList';
import RequirementsList from 'app/pages/settings/Requirements/RequirementsList';
import CountriesList from 'app/pages/settings/Countries/CountriesList';
import InstitutionsList from 'app/pages/settings/Institutions/InstitutionsList';
import SchoolsList from 'app/pages/settings/Schools/SchoolsList';
import CoursesList from 'app/pages/settings/Courses/CoursesList';
import ApikeysList from 'app/pages/settings/Apikeys/ApikeysList';
import SmtpsList from 'app/pages/settings/Smtps/SmtpsList';
import authRoles from 'app/config/authRoles';

import ApprovalsList from 'app/pages/settings/Approvals/ApprovalsList';

const settingsRoutes = {
    settings: {
        layout: {}
    },
    auth: authRoles?.consultant,
    routes: [
        {
            action: 'read',
            subject: 'Users',
            path: '/settings/users',
            element: <Page component={UsersList} />
        },
        {
            action: 'read',
            subject: 'User',
            path: '/settings/users/user/:id',
            element: <Page component={UserProfile} />
        },
        {
            action: 'read',
            subject: 'Roles',
            path: '/settings/roles',
            element: <Page component={RolesList} />
        },
        {
            action: 'read',
            subject: 'Designations',
            path: '/settings/designations',
            element: <Page component={DesignationsList} />
        },
        {
            action: 'read',
            subject: 'Educationlevels',
            path: '/settings/education-levels',
            element: <Page component={EducationlevelsList} />
        },
        {
            action: 'read',
            subject: 'Requirements',
            path: '/settings/requirements',
            element: <Page component={RequirementsList} />
        },
        {
            action: 'read',
            subject: 'Institutions',
            path: '/settings/institutions',
            element: <Page component={InstitutionsList} />
        },
        {
            action: 'read',
            subject: 'Schools',
            path: '/settings/schools',
            element: <Page component={SchoolsList} />
        },
        {
            action: 'read',
            subject: 'Countries',
            path: '/settings/countries',
            element: <Page component={CountriesList} />
        },
        {
            action: 'read',
            subject: 'Courses',
            path: '/settings/courses',
            element: <Page component={CoursesList} />
        },
        {
            action: 'read',
            subject: 'Leavetypes',
            path: '/settings/leave-types',
            element: <Page component={LeavetypesList} />
        },
        {
            action: 'read',
            subject: 'Expensetypes',
            path: '/settings/expense-types',
            element: <Page component={ExpensetypesList} />
        },
        {
            action: 'read',
            subject: 'Expenseapprovals',
            path: '/settings/expenses/expenses-approvals',
            element: <Page component={ApprovalsList} />
        },
        {
            action: 'read',
            subject: 'Incometypes',
            path: '/settings/income-types',
            element: <Page component={IncometypesList} />
        },
        {
            action: 'read',
            subject: 'Apikeys',
            path: '/settings/apikeys',
            element: <Page component={ApikeysList} />
        },
        {
            action: 'read',
            subject: 'Smtps',
            path: '/settings/smtps',
            element: <Page component={SmtpsList} />
        }
    ]
};
export default settingsRoutes;
