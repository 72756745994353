import React from 'react';

import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Div from '@jumbo/shared/Div';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useMutation } from 'react-query';
import { uniapplicationsServices } from 'app/services/uniapplications-services';
import { institutionsServices } from 'app/services/institutions-services';
import { studentServices } from 'app/services/student-services';
import { officeServices } from 'app/services/office-services';
import { coursesServices } from 'app/services/courses-services';
import { countriesServices } from 'app/services/countries-services';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';

import { useFormikContext, Formik, Form, Field } from 'formik';

import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import axios from 'axios';

import {
    capitalizeAllWords,
    getCurrentDate,
    getNextYearDate
} from 'app/utils/appHelpers';

import _ from 'lodash';

const validationSchema = yup.object({
    status: yup.string('Select status').required('Status is required'),
    uniapplicationId: yup
        .string('Select related uni/college application')
        .required('Uni Application ID is required'),
    applicationCode: yup
        .string('Enter application ID/Code')
        .required('Application ID/Code is required'),
    application_fee: yup
        .string('Enter application fee & provide receipt')
        .required('Application fee & provide receipt name is required'),
    application_date: yup
        .string('Enter date of application')
        .required('Application date is required')
});

const Visaapplication = ({
    listItem,
    userDetails,
    setActiveSchema,
    setInitialFormValues
}) => {
    console.log('UniApplication');
    console.log(listItem);

    const { enqueueSnackbar } = useSnackbar();

    const { values, setValues, touched, errors, setErrors, submitForm } =
        useFormikContext();

    const [countries, setCountries] = React.useState(false);
    const [customizingCourses, setCustomizingCourses] = React.useState(false);
    const [institutions, setInstitutions] = React.useState(false);
    const [institutionId, setInstitutionId] = React.useState('');
    const [feeCurrency, setFeeCurrency] = React.useState('');

    const [requirements, setRequirements] = React.useState(false);
    const [officeId, setOfficeId] = React.useState('');
    const [courses, setCourses] = React.useState([]);
    const [finalCourses, setFinalCourses] = React.useState([]);
    const [defaultCourses, setDefaultCourses] = React.useState(false);
    const [fieldDate, setFieldDate] = React.useState(
        dayjs(getCurrentDate(false))
    );

    React.useEffect(() => {
        const cancelInstToken = axios.CancelToken.source();
        const cInstToken = { cancelToken: cancelInstToken.token };
        const fetchInstitutions = async () => {
            try {
                const res = await institutionsServices.getAllInstitutions(
                    cInstToken
                );

                setInstitutions(res.institutions);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchInstitutions();

        return () => {
            cancelInstToken.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenCourses = axios.CancelToken.source();
        const cTokenCourses = {
            cancelToken: cancelTokenCourses.token
        };
        const fetchCourses = async () => {
            try {
                const res = await coursesServices.getAllCourses(cTokenCourses);

                setCourses(res.courses);
                setFinalCourses(res.courses);

                //   const reqs = listItem?.courseId
                //     ? _.filter(res.courses, (obj) =>
                //         _.includes(_.map(listItem.courseId, "id"), obj.id)
                //       )
                //     : [];

                //   setDefaultCourses(reqs);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchCourses();

        return () => {
            cancelTokenCourses.cancel();
        };
    }, []);

    React.useEffect(() => {
        if (institutionId) {
            setFinalCourses(
                _.filter(
                    courses,
                    (course) => course.institutionId.id === institutionId
                )
            );
        }

        setCustomizingCourses(false);
    }, [institutionId]);

    React.useEffect(() => {
        if (fieldDate) {
            values.application_date = fieldDate;
        }

        setCustomizingCourses(false);
    }, [fieldDate]);

    React.useEffect(() => {
        setActiveSchema(validationSchema);

        setValues({
            status: 5,
            leadId: listItem.id,
            applicationCode: '',
            countryId: listItem?.selectedInstitutionCountryId?.id,
            country_name: listItem?.selectedInstitutionCountryId?.country_name,
            visa_waiting_period:
                listItem?.selectedInstitutionCountryId?.visa_waiting_period,
            institutionId: listItem?.selectedInstitutionId?.id,
            institution_name: listItem?.selectedInstitutionId?.institution_name,
            uniapplicationId: listItem?.selectedUniApplicationId?.id,
            uniapplicationCode:
                listItem?.selectedUniApplicationId?.applicationCode,
            courseId: listItem?.selectedCourseId,
            course_name: listItem?.selectedCourseId?.course_name,
            visa_fee_currency: listItem?.selectedInstitutionCountryId
                ?.visa_fee_currency
                ? listItem?.selectedInstitutionCountryId?.visa_fee_currency
                      ?.currency
                : 0,
            application_fee: listItem?.selectedInstitutionCountryId?.visa_fee
                ? listItem?.selectedInstitutionCountryId?.visa_fee
                : 0,
            application_date: fieldDate,
            comment: ''
        });

        setErrors({});
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%'
            }}
        >
            {/* {(!listItem?.selectedUniApplicationId ||
                !listItem?.firstYearTuitionFee ||
                !listItem?.selectedCourseId) && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )} */}

            {(!listItem?.selectedUniApplicationId ||
                !listItem?.firstYearTuitionFee ||
                !listItem?.selectedCourseName) && (
                <Alert severity="error">
                    <Typography
                        variant={'h6'}
                        sx={{ fontWeight: '700', width: '100%' }}
                    >
                        No Acceptance Offer found for this lead
                    </Typography>
                    Please, First Select the accepted offer under{' '}
                    <strong>
                        <em>Tuition Deposit Payment</em>
                    </strong>{' '}
                    Option/Status and select the application/course the student
                    prefers from the acceptance offer and provide information on
                    the tuition fee to be paid before coming to this step
                </Alert>
            )}

            {listItem?.selectedUniApplicationId &&
                listItem?.firstYearTuitionFee &&
                listItem?.selectedCourseName && (
                    <Div sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <JumboTextField
                                    fullWidth
                                    size={'small'}
                                    id="uniapplicationCode"
                                    name="uniapplicationCode"
                                    label="Associated Uni/College Application ID"
                                    placeholder="Uni/College ApplicationID"
                                    readOnly
                                    type={'text'}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <JumboTextField
                                    fullWidth
                                    size={'small'}
                                    id="institution_name"
                                    name="institution_name"
                                    label="Institution"
                                    placeholder="Institution Name"
                                    readOnly
                                    type={'text'}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <JumboTextField
                                    fullWidth
                                    size={'small'}
                                    id="country_name"
                                    name="country_name"
                                    label="Destination Country"
                                    placeholder="country Name"
                                    readOnly
                                    type={'text'}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <JumboTextField
                                    fullWidth
                                    size={'small'}
                                    id="applicationCode"
                                    name="applicationCode"
                                    label="Application Code/Serial No./ID"
                                    placeholder="Enter application ID/Serial No./Code/Tracking No. here"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <JumboTextField
                                    fullWidth
                                    size={'small'}
                                    id="application_fee"
                                    name="application_fee"
                                    label="Application Fee"
                                    placeholder="Enter the application fee here"
                                    readOnly
                                    type={'number'}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {/* {values.visa_fee_currency} */}
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <MobileDatePicker
                                        label="Application Date"
                                        inputFormat="DD/MM/YYYY"
                                        disableFuture
                                        value={fieldDate}
                                        onChange={(newValue) => {
                                            setFieldDate(newValue);
                                            values.application_date = newValue;
                                        }}
                                        renderInput={(params) => {
                                            return (
                                                <JumboTextField
                                                    fullWidth
                                                    size={'small'}
                                                    value={fieldDate}
                                                    name="application_date"
                                                    {...params}
                                                />
                                            );
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Div>
                )}
        </Div>
    );
};
/* Todo uniapplicationItem, onSave prop define */
export default Visaapplication;
