import React, { useEffect, useState } from 'react';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'app/AppAbility';
import {
    ListItemText,
    Tooltip,
    Typography,
    ListItemAvatar,
    Chip
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Div from '@jumbo/shared/Div';
import styled from '@emotion/styled';
import Span from '@jumbo/shared/Span';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Slide from '@mui/material/Slide';
import Edit from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import PaidIcon from '@mui/icons-material/Paid';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import { incomesServices } from 'app/services/incomes-services';
import { useMutation } from 'react-query';
import { capitalizeFirstLetter, formatNumber } from 'app/utils/appHelpers';

import moment from 'moment';
import useIncomesApp from '../../hooks/useIncomesApp';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import EditForm from '../EditForm';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import { useSnackbar } from 'notistack';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import _ from 'lodash';
import IncomeStatus from 'app/pages/statuses/IncomeStatus';
import Detail from '../Detail';

const Item = styled(Span)(({ theme }) => ({
    padding: theme.spacing(0, 1)
}));

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const ListItem = ({ listItem }) => {
    const { authUser } = useJumboAuth();
    const { theme } = useJumboTheme();
    const { setListRefresh } = useIncomesApp();
    const { showDialog, hideDialog } = useJumboDialog();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const ability = useAbility(AbilityContext);
    const allowRead = ability.can('read', 'Deposit');
    const allowEdit = ability.can('update', 'Deposit');
    const allowDelete = ability.can('delete', 'Deposit');

    const [paymentStatus, setPaymentStatus] = React.useState({
        id: 0,
        name: 'Unknown',
        slug: 'Uknown',
        color: '#ca404f'
    });

    const deleteMutation = useMutation(incomesServices.deleteIncome, {
        onSuccess: () => {
            hideDialog();
            setListRefresh(true);
            enqueueSnackbar('Income has been deleted successfully.', {
                variant: 'success'
            });
        },
        onError: (error) => {
            hideDialog();
            enqueueSnackbar(error, {
                variant: 'error'
            });

            return false;
        }
    });

    const updateStatusMutation = useMutation(incomesServices.updateStatus, {
        onSuccess: () => {
            handleUpdatedItem();
            enqueueSnackbar('Status has been updated successfully.', {
                variant: 'success'
            });
        },
        onError: (error) => {
            enqueueSnackbar(error, {
                variant: 'error'
            });
        }
    });

    const itemMoveToTrash = React.useCallback(
        (incomeId) => {
            showDialog({
                variant: 'confirm',
                title: 'Delete!',
                content: 'Are you sure? Select Yes to Continue',
                onYes: () => {
                    deleteMutation.mutate(incomeId);
                },
                onNo: hideDialog
            });
        },
        [deleteMutation, hideDialog]
    );

    const handleUpdatedItem = React.useCallback(() => {
        hideDialog();
        setListRefresh(true);
    }, [hideDialog]);

    const handleUpdateForm = (event) => {
        showDialog({
            title: 'Update Income',
            content: (
                <EditForm
                    listItem={listItem}
                    onUpdate={handleUpdatedItem}
                    hideDialog={hideDialog}
                    userDetails={authUser}
                />
            ),
            maxWidth: 'md',
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true
        });
    };

    const showDetails = React.useCallback(() => {
        showDialog({
            content: <Detail item={listItem} onClose={hideDialog} />
        });
    }, [showDialog, listItem]);

    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case 'mark_paid':
                showDialog({
                    variant: 'confirm',
                    title: 'Are you sure about this?',
                    content: 'Confirm to continue with update',
                    onYes: () =>
                        updateStatusMutation.mutate({
                            id: listItem?.id,
                            status: 4
                        }),
                    onNo: hideDialog
                });
                break;
            case 'mark_unpaid':
                showDialog({
                    variant: 'confirm',
                    title: 'Are you sure about this?',
                    content: 'Confirm "Yes" to continue with update',
                    onYes: () =>
                        updateStatusMutation.mutate({
                            id: listItem?.id,
                            status: 1
                        }),
                    onNo: hideDialog
                });
                break;
        }
    };

    const profilePic = listItem?.studentId?.profile_pic
        ? process.env.REACT_APP_BACKEND + '/' + listItem?.studentId?.profile_pic
        : null;

    useEffect(() => {
        setPaymentStatus(
            _.find(IncomeStatus, (status) => status.id === listItem?.status)
        );
    }, [listItem]);

    return (
        <React.Fragment>
            <JumboListItem
                componentElement={'div'}
                itemData={listItem}
                secondaryAction={
                    <JumboDdMenu
                        icon={<MoreHorizIcon />}
                        menuItems={[
                            listItem?.status === 1
                                ? {
                                      icon: <PaidIcon />,
                                      title: 'Mark As Paid',
                                      action: 'mark_paid'
                                  }
                                : listItem?.status === 4
                                ? {
                                      icon: <PaidIcon />,
                                      title: 'Mark As Not Paid',
                                      action: 'mark_unpaid'
                                  }
                                : {}
                        ]}
                        onClickCallback={handleItemAction}
                    />
                }
                sx={{
                    cursor: 'pointer',
                    borderTop: 1,
                    borderColor: 'divider',
                    '&:hover': {
                        bgcolor: 'action.hover',

                        '& .ListAction': {
                            width: { sm: '100%' },
                            opacity: { sm: 1 }
                        },

                        '& .ListTextExtra': {
                            visibility: { sm: 'hidden' },
                            opacity: { sm: 0 }
                        }
                    }
                }}
            >
                <ListItemAvatar onClick={showDetails}>
                    <Avatar
                        alt={`${listItem?.studentId?.given_name} ${listItem?.studentId?.surname}`}
                        src={profilePic}
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Typography variant={'body1'} component={'div'}>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                sx={{
                                    minWidth: 0
                                    ////background: 'yellow'
                                }}
                            >
                                <Item
                                    onClick={showDetails}
                                    sx={{
                                        flexBasis: {
                                            xs: '70%',
                                            sm: '23%',
                                            md: '23%'
                                        }
                                        ////background: '#eee'
                                    }}
                                >
                                    <Typography
                                        variant={'h5'}
                                        fontSize={14}
                                        lineHeight={1.25}
                                        mb={0}
                                        noWrap
                                    >
                                        {`${listItem?.studentId?.given_name} ${listItem?.studentId?.surname}`}
                                    </Typography>
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        sx={{
                                            display: {
                                                xs: 'block',
                                                sm: 'block'
                                            }
                                        }}
                                    >
                                        {listItem?.studentId?.passport_number}
                                    </Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { sm: '32%', md: '32%' },
                                        display: { xs: 'none', sm: 'block' },
                                        minWidth: '25%'
                                        // background: 'green'
                                    }}
                                >
                                    <Typography variant={'body1'} noWrap>
                                        {
                                            listItem?.institutionId
                                                ?.institution_name
                                        }
                                    </Typography>
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        sx={{
                                            display: {
                                                xs: 'block',
                                                sm: 'block'
                                            }
                                        }}
                                    >
                                        {
                                            listItem?.leadId?.selectedCourseId
                                                ?.course_name
                                        }
                                    </Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { sm: '25%', md: '25%' },
                                        display: { xs: 'none', sm: 'block' },
                                        justifyContent: 'right'
                                    }}
                                >
                                    <Typography
                                        variant={'body1'}
                                        sx={{
                                            width: '100%',
                                            justifyContent: 'right',
                                            textAlign: 'right'
                                        }}
                                    >
                                        Commission:{' '}
                                        <strong>
                                            {formatNumber(listItem?.amount)}
                                        </strong>
                                    </Typography>
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        sx={{
                                            display: {
                                                xs: 'block',
                                                sm: 'block'
                                            },
                                            justifyContent: 'right',
                                            textAlign: 'right'
                                        }}
                                    >
                                        1st Yr Fee:{' '}
                                        {listItem?.leadId?.firstYearTuitionFee
                                            ? formatNumber(
                                                  listItem?.leadId
                                                      ?.firstYearTuitionFee
                                              )
                                            : ''}
                                    </Typography>
                                </Item>
                                <Item
                                    onClick={showDetails}
                                    sx={{
                                        flexBasis: { sm: '20%', md: '20%' },
                                        display: { xs: 'none', sm: 'block' },
                                        justifyContent: 'right',
                                        textAlign: 'right'
                                    }}
                                >
                                    <Chip
                                        label={listItem?.officeId?.office_name}
                                        color="info"
                                        size="small"
                                    />
                                </Item>

                                {/* <Item
                                    sx={{
                                        flexBasis: { sm: '10%', md: '10%' },
                                        display: { xs: 'none', sm: 'block' }
                                        ////background: '#333'
                                    }}
                                >
                                    <Stack
                                        spacing={2}
                                        direction={'row'}
                                        alignItems={'center'}
                                        sx={{ textAlign: 'center' }}
                                    >
                                        <Item>
                                            <Typography variant={'h6'} mb={0.5}>
                                                {listItem?.leads
                                                    ? listItem?.leads.length
                                                    : 0}
                                            </Typography>
                                            <Typography
                                                variant={'body1'}
                                                color="text.secondary"
                                            >
                                                Leads
                                            </Typography>
                                        </Item>
                                    </Stack>
                                </Item> */}
                            </Stack>
                        </Typography>
                    }
                />

                <Div
                    sx={{
                        width: 150,
                        display: 'flex',
                        flexShrink: '0',
                        position: 'relative',
                        justifyContent: 'flex-end',
                        // //background: '#333',s

                        [theme.breakpoints.down('sm')]: {
                            width: '100%',
                            justifyContent: 'space-between'
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            flex: 1,
                            transition: 'all 0.5s ease',

                            [theme.breakpoints.down('sm')]: {
                                justifyContent: 'flex-start',
                                ml: 6
                            }
                        }}
                        className={'ListTextExtra'}
                    >
                        <JumboChipsGroup
                            chips={[paymentStatus]}
                            mapKeys={{ label: 'name' }}
                            spacing={1}
                            size={'small'}
                            max={1}
                        />

                        <Typography
                            variant={'body1'}
                            color={'text.secondary'}
                            ml={1}
                        >
                            {moment(listItem?.date_created).format('MMM DD')}
                        </Typography>
                    </Div>
                    <Div
                        className="ListAction"
                        sx={{
                            display: 'flex',

                            [theme.breakpoints.up('sm')]: {
                                position: 'absolute',
                                top: '50%',
                                width: 0,
                                opacity: 0,
                                overflow: 'hidden',
                                transition: 'all 0.5s ease',
                                justifyContent: 'flex-end',
                                transform: 'translateY(-50%)'
                            }
                        }}
                    >
                        <Span sx={{ height: 36, overflow: 'hidden' }}>
                            {allowRead && (
                                <Tooltip title="View Details">
                                    <IconButton onClick={showDetails}>
                                        <VisibilityIcon fontSize={'small'} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {allowEdit && (
                                <Tooltip title="Edit">
                                    <IconButton onClick={handleUpdateForm}>
                                        <Edit fontSize={'small'} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {/* {allowDelete && (
                                <Tooltip title="Delete">
                                    <IconButton
                                        onClick={() =>
                                            itemMoveToTrash(listItem?.id)
                                        }
                                    >
                                        <DeleteIcon
                                            fontSize={'small'}
                                            sx={{ color: '#ca404f' }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            )} */}
                        </Span>
                    </Div>
                </Div>
            </JumboListItem>
        </React.Fragment>
    );
};

export default ListItem;
