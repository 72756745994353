import React from 'react';
import AdvancesAppContext from './AdvancesAppContext';
import { CONTACT_APP_ACTIONS } from './utils/constants';

const init = (appState) => appState;

const advancesAppReducer = (state, action) => {
    switch (action.type) {
        case CONTACT_APP_ACTIONS.SET_SELECTED_ITEMS:
            return {
                ...state,
                selectedAdvances: action?.payload
            };
        case CONTACT_APP_ACTIONS.SET_CONTACTS_LIST_REFRESH:
            return {
                ...state,
                refreshAdvancesList: action.payload
            };
        case CONTACT_APP_ACTIONS.SET_LABELS_LIST_REFRESH:
            return {
                ...state,
                refreshLabelsList: action.payload
            };
        default:
            return state;
    }
};

const AdvancesAppProvider = ({ children }) => {
    const [advancesApp, setAdvancesApp] = React.useReducer(
        advancesAppReducer,
        {
            selectedAdvances: [],
            refreshAdvancesList: false,
            refreshLabelsList: false
        },
        init
    );

    const setSelectedAdvances = React.useCallback(
        (advances) => {
            setAdvancesApp({
                type: CONTACT_APP_ACTIONS.SET_SELECTED_ITEMS,
                payload: advances
            });
        },
        [setAdvancesApp]
    );

    const setAdvancesListRefresh = React.useCallback(
        (refresh) => {
            setAdvancesApp({
                type: CONTACT_APP_ACTIONS.SET_CONTACTS_LIST_REFRESH,
                payload: refresh
            });
        },
        [setAdvancesApp]
    );

    const setLabelsListRefresh = React.useCallback(
        (refresh) => {
            setAdvancesApp({
                type: CONTACT_APP_ACTIONS.SET_LABELS_LIST_REFRESH,
                payload: refresh
            });
        },
        [setAdvancesApp]
    );

    const contextValue = React.useMemo(
        () => ({
            ...advancesApp,
            setSelectedAdvances: setSelectedAdvances,
            setAdvancesListRefresh: setAdvancesListRefresh,
            setLabelsListRefresh: setLabelsListRefresh
        }),
        [advancesApp]
    );
    return (
        <AdvancesAppContext.Provider value={contextValue}>
            {children}
        </AdvancesAppContext.Provider>
    );
};

export default AdvancesAppProvider;
