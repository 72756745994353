import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { useField, useFormikContext } from 'formik';

// Assuming you have a options array
const categories = [
    { id: 1, type: 'Custom' },
    { id: 2, type: 'Students' },
    { id: 3, type: 'By Event' }
];

export default function OptionsAutocomplete({
    label = '"Recipients Category"',
    optionLabel = 'type',
    name,
    size = 'small',
    optionId,
    setOptionId,
    options = categories,
    multiple = false
}) {
    const { setFieldValue } = useFormikContext();

    const [meta, helpers] = useField(name);
    const errorText = meta.error && meta.touched ? meta.error : '';

    React.useEffect(() => {
        if (name === 'optionId') {
            setFieldValue('students', null);
            setFieldValue('eventId', null);
            setFieldValue('to', null);
        }
    }, [optionId]);

    return (
        <Autocomplete
            multiple={multiple}
            value={
                multiple ? (Array.isArray(optionId) ? optionId : []) : optionId
            }
            // renderTags={renderTagsOption}
            onChange={(_, newValue) => {
                setFieldValue(name, newValue);
                setOptionId(newValue);
            }}
            options={options}
            getOptionLabel={(option) => `${option[optionLabel]}`}
            isOptionEqualToValue={(option, value) => option.id === value.id} // Customize equality
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    name={name}
                    size={size}
                    variant="standard"
                    error={meta?.touched && meta?.error}
                    helperText={errorText}
                />
            )}
        />
    );
}
