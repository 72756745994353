import React from 'react';
import { Card, CardContent, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Div from '@jumbo/shared/Div';
import { alpha } from '@mui/material/styles';
import { ASSET_IMAGES } from '../../../utils/constants/paths';
import { getAssetPath } from '../../../utils/appHelpers';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import authServices from '../../../services/auth-services';
import { useSnackbar } from 'notistack';

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required')
});

const ResetPassword = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const onReset = async (email, password) => {
        try {
            const data = await authServices.resetPassword({
                email_address: email
            });

            enqueueSnackbar(
                'Password reset successful. Check your mail for instructions.',
                {
                    variant: 'success'
                }
            );

            // basicAlert('error', 'Sign In Failed', err);
            // setAuthToken(data?.token);
            setTimeout(() => {
                navigate('/user/login');
            }, 500);
        } catch (err) {
            // basicAlert('error', 'Sign In Failed', err);
            enqueueSnackbar(err, {
                variant: 'error'
            });
        }
    };

    return (
        <Div sx={{ width: 720, maxWidth: '100%', margin: 'auto', p: 4 }}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        background: `#0267a0 url(${getAssetPath(
                            `${ASSET_IMAGES}/widgets/keith-luke.jpg`
                        )}) no-repeat center`,
                        backgroundSize: 'cover',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#0267a0', 0.65)
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%',
                            minHeight: { md: 320 }
                        }}
                    >
                        <Div sx={{ mb: 2 }}>
                            <Typography
                                variant={'h3'}
                                color={'inherit'}
                                fontWeight={500}
                                mb={3}
                            >
                                Reset Password
                            </Typography>
                            <Typography
                                variant={'body1'}
                                mb={1}
                                sx={{ maxWidth: 270 }}
                            >
                                By entering your registered email address you
                                will receive reset password link, Kindly follow
                                instruction.
                            </Typography>
                        </Div>
                        <Div sx={{ mt: 'auto' }}>
                            <Link
                                href="#"
                                underline="none"
                                sx={{ display: 'inline-flex' }}
                            >
                                <img
                                    src={`${ASSET_IMAGES}/IEC-logo-128.png`}
                                    alt="IEC Consultants"
                                />
                            </Link>
                        </Div>
                    </Div>
                </CardContent>
                <CardContent sx={{ flex: 1, p: 4 }}>
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            height: '100%'
                        }}
                    >
                        <Div sx={{ mb: 2 }}>
                            <Formik
                                validateOnChange={true}
                                initialValues={{
                                    email: '',
                                    password: ''
                                }}
                                validationSchema={validationSchema}
                                onSubmit={async (data, { setSubmitting }) => {
                                    setSubmitting(true);
                                    await onReset(data.email, data.password);
                                    setSubmitting(false);
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Form
                                        style={{ textAlign: 'left' }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <Div sx={{ mt: 1, mb: 3 }}>
                                            <JumboTextField
                                                fullWidth
                                                name="email"
                                                label="Email"
                                                placeholder="Enter email address"
                                            />
                                        </Div>
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{ mb: 3 }}
                                            loading={isSubmitting}
                                        >
                                            Reset Password
                                        </LoadingButton>

                                        <Typography
                                            textAlign={'center'}
                                            variant={'body1'}
                                            mb={1}
                                        >
                                            Don't remember your email?{' '}
                                            <Link underline="none" to="#">
                                                Contact Support
                                            </Link>
                                        </Typography>
                                    </Form>
                                )}
                            </Formik>
                        </Div>
                        <Typography variant={'body1'} mt={'auto'}>
                            Already have an account?{' '}
                            <Link
                                to={'/user/login'}
                                underline={'none'}
                                sx={{ cursor: 'pointer' }}
                            >
                                Login Now
                            </Link>
                            {/* Or <Link href={"#/"} underline={'none'}>Create New</Link> */}
                        </Typography>
                    </Div>
                </CardContent>
            </Card>
        </Div>
    );
};

export default ResetPassword;
