import jwtAuthAxios from './auth/jwtAuth';

const contactService = {};

contactService.getAllContacts = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/contacts',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );
    return data;
};

contactService.getContacts = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];
    const { data } = await jwtAuthAxios.get('/contacts', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

contactService.getLabels = async () => {
    const { data } = await jwtAuthAxios.get('/contacts/labels');
    return data;
};

contactService.add = async (contact) => {
    const { data } = await jwtAuthAxios.post('/contacts', contact);
    return data;
};

contactService.update = async (contact) => {
    console.log('update');
    const { data } = await jwtAuthAxios.patch(
        `/contacts/${contact.id}`,
        contact
    );
    return data;
};

contactService.delete = async (contact) => {
    const { data } = await jwtAuthAxios.delete(`/contacts/${contact.id}`);
    return data;
};

contactService.deleteMultiple = async (selectedIDs) => {
    const { data } = await jwtAuthAxios.delete('/contacts', {
        data: selectedIDs
    });
    return data;
};

contactService.addLabel = async (label) => {
    const { data } = await jwtAuthAxios.post('/contacts/add-label', label);
    return data;
};

contactService.updateLabel = async (label) => {
    const { data } = await jwtAuthAxios.put('/contacts/update-label', label);
    return data;
};

contactService.deleteLabel = async (label) => {
    const { data } = await jwtAuthAxios.delete('/contacts/delete-label', {
        params: { id: label.id }
    });
    return data;
};

contactService.assignLabel = async (params) => {
    const { data } = await jwtAuthAxios.put('/contacts/assign-label', {
        contactIDs: params?.contactIDs ?? [],
        labelIDs: params?.labelIDs ?? []
    });
    return data;
};

export { contactService };
