import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const officeServices = {};

officeServices.getAllOffices = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/offices',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

officeServices.getOffices = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/offices', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

officeServices.getOfficeDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/offices/office/${id}`, {
        id: id
    });

    return data;
};

officeServices.addOffice = async (office) => {
    const { data } = await jwtAuthAxios.post(`/offices`, office);
    return data;
};

officeServices.updateOffice = async (office) => {
    const { data } = await jwtAuthAxios.patch(`/offices/${office.id}`, office);
    return data;
};

officeServices.deleteOffice = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/offices/${id}`, {
        id: id
    });
    return data;
};

officeServices.deleteMultipleOffices = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/offices`, {
        data: ids
    });
    return data;
};

export { officeServices };
