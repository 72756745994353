import React, { useCallback, useState, useEffect } from 'react';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'app/AppAbility';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder';
import JumboListWrapper from '@jumbo/components/JumboList/components/JumboListWrapper';
import NotAllowed from 'app/shared/NotAllowed';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import _ from 'lodash';

import Slide from '@mui/material/Slide';

import Button from '@mui/material/Button';
import { Add } from '@mui/icons-material';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Div from '@jumbo/shared/Div';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CalendarWrapper from './CalendarWrapper';
import { useTranslation } from 'react-i18next';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import CreateForm from './CreateForm';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useSnackbar } from 'notistack';

import { calendarData } from './data';
import { eventsServices } from 'app/services/events-services';
import Detail from './Detail/Detail';
import { LoadingButton } from '@mui/lab';

// const { events } = calendarData;
const today = new Date();
const currentYear = today.getFullYear();
const currentMonth = today.getMonth();
const currentDate = today.getDate();

const localizer = momentLocalizer(moment);

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const EventsCalendar = () => {
    const [events, setEvents] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const { authUser } = useJumboAuth();
    const { hideDialog, showDialog } = useJumboDialog();

    const ability = useAbility(AbilityContext);
    const allowReadList = ability.can('read', 'Events');
    const allowAdd = ability.can('create', 'Event');
    const allowBulkDel = ability.can('delete', 'Event');

    const { t } = useTranslation();

    const refreshEvents = useCallback(async () => {
        try {
            const res = await eventsServices.getAllEvents();

            console.log('Events');
            console.log(res);

            if (res?.events) {
                const events = _.map(
                    res.events,
                    ({
                        id,
                        userId,
                        event_name,
                        location,
                        cityId,
                        city_name,
                        start_date,
                        end_date,
                        description,
                        officeId,
                        school_name,
                        participantsContacts,
                        meetingLink
                    }) => ({
                        id,
                        title: `${event_name}`,
                        userId: userId?.id,
                        eventOwner: `${userId.first_name} ${userId.last_name}`,
                        eventName: `${event_name}`,
                        cityId: cityId,
                        city_name: city_name,
                        location: location,
                        allDay: false,
                        participantsContacts,
                        meetingLink,
                        start: new Date(start_date),
                        end: new Date(end_date),
                        description,
                        officeId: officeId?.id,
                        school_name
                    })
                );

                setEvents(events);
            }
        } catch (error) {
            enqueueSnackbar(error, {
                variant: 'error'
            });
        }
    }, []);

    const handleCreateForm = React.useCallback(
        (data) => {
            showDialog({
                title: 'Create Event',
                content: (
                    <CreateForm
                        // onSave={handleRequestTimeoff}
                        hideDialog={hideDialog}
                        refreshEvents={refreshEvents}
                        start={data?.start}
                        end={data?.end}
                    />
                ),
                maxWidth: 'sm',
                TransitionComponent: Transition,
                disableDefaultClose: true,
                closeAction: true
            });
        },
        [showDialog]
    );

    const showEventDetails = React.useCallback(
        (event) => {
            showDialog({
                content: (
                    <Detail
                        item={event}
                        userDetails={authUser}
                        onClose={hideDialog}
                        refreshEvents={refreshEvents}
                    />
                )
            });
        },
        [showDialog]
    );

    useEffect(() => {
        refreshEvents();
    }, []);

    if (!allowReadList) {
        return (
            <JumboListWrapper
                component={Card}
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <JumboListNoDataPlaceholder>
                    <NotAllowed />
                </JumboListNoDataPlaceholder>
            </JumboListWrapper>
        );
    }

    if (!allowReadList) {
        return (
            <JumboListWrapper
                component={Card}
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <JumboListNoDataPlaceholder>
                    <NotAllowed />
                </JumboListNoDataPlaceholder>
            </JumboListWrapper>
        );
    }

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'
                    // background: '#333'
                }}
            >
                <Typography variant={'h1'} mb={3}>
                    Events Calendar
                </Typography>
                {allowAdd && (
                    <Button
                        disableElevation
                        variant={'contained'}
                        startIcon={<Add />}
                        sx={{
                            mb: 3,
                            '& .MuiSvgIcon-root': {
                                fontSize: '1.5rem'
                            }
                        }}
                        onClick={handleCreateForm}
                    >
                        Create Event
                    </Button>
                )}
            </Box>
            <Card>
                <CardContent>
                    <CalendarWrapper>
                        <Calendar
                            localizer={localizer}
                            events={events}
                            selectable
                            defaultView="month"
                            scrollToTime={new Date(1970, 1, 1, 6)}
                            defaultDate={
                                new Date(currentYear, currentMonth, currentDate)
                            }
                            onSelectEvent={(event) => showEventDetails(event)}
                            onSelectSlot={(slotInfo) => {
                                allowAdd &&
                                    handleCreateForm({
                                        start: slotInfo.start,
                                        end: slotInfo.end
                                    });
                            }}
                            style={{ height: 600 }}
                        />
                    </CalendarWrapper>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default EventsCalendar;
