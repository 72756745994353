import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const requirementServices = {};

requirementServices.getAllRequirements = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/requirements',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

requirementServices.getRequirements = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/requirements', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

requirementServices.getRequirementDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/requirements/requirement/${id}`, {
        id: id
    });

    return data;
};

requirementServices.addRequirement = async (requirement) => {
    const { data } = await jwtAuthAxios.post(`/requirements`, requirement);
    return data;
};

requirementServices.updateRequirement = async (requirement) => {
    // console.log('Service Requirement');
    // console.log(requirement);
    const { data } = await jwtAuthAxios.patch(
        `/requirements/${requirement.id}`,
        requirement
    );
    return data;
};

requirementServices.deleteRequirement = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/requirements/${id}`, {
        id: id
    });
    return data;
};

requirementServices.deleteMultipleRequirements = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/requirements`, {
        data: ids
    });
    return data;
};

export { requirementServices };
