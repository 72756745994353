import React, { useState } from 'react';
import _ from 'lodash';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { useField, useFormikContext } from 'formik';

export default function FormikAutocomplete({
    label = 'Label',
    fullWidth = true,
    placeholder = 'Select Option',
    name,
    field_name,
    prefix,
    variant = 'outlined',
    options,
    size = 'small',
    multiple = false,
    onChange = false,
    freeSolo = false
}) {
    const { setFieldValue, values } = useFormikContext();

    const [field, meta, helpers] = useField(name);
    const errorText = meta.error && meta.touched ? meta.error : '';

    const handleKeyUp = (event) => {
        if (!multiple && event.key !== 'Enter' && freeSolo) {
            helpers.setValue(event.target.value);

            console.log('Update on key up');

            if (onChange) {
                onChange(event.target.value);
            }
        }
    };

    console.log('===============================');
    console.log(values.officeId);
    console.log(values.employeeId);
    console.log(meta?.error && meta?.touched);

    return (
        <Autocomplete
            {...field}
            fullWidth={fullWidth}
            multiple={multiple}
            id={name}
            options={options}
            autoHighlight
            getOptionLabel={(option) =>
                option[field_name] ? option[field_name].toString() : null
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
                <Box
                    component="li"
                    sx={{
                        '& > img': {
                            mr: 2,
                            flexShrink: 0
                        }
                    }}
                    {...props}
                >
                    {option[field_name]}
                </Box>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        variant="outlined"
                        label={option[field_name]}
                        {...getTagProps({ index })}
                        size="small"
                    />
                ))
            }
            onChange={(_, newValue) => {
                helpers.setValue(newValue);

                if (onChange) {
                    console.log('Update Inputs');
                    console.log(newValue);
                    onChange(newValue);
                }
            }}
            renderInput={(params) => (
                <TextField
                    fullWidth={fullWidth}
                    width="100%"
                    {...params}
                    variant={variant}
                    label={label}
                    size={size}
                    placeholder={placeholder}
                    error={meta?.error && meta?.touched}
                    helperText={errorText}
                    onKeyUp={handleKeyUp}
                />
            )}
        />
    );
}
