import React from 'react';

import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ContactAutocomplete from 'app/shared/FreeAutocomplete/ContactAutocomplete';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

import { useMutation } from 'react-query';
import { eventsServices } from 'app/services/events-services';
import { userServices } from 'app/services/user-services';
import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import axios from 'axios';

import _ from 'lodash';

const validationSchema = yup.object({
    event_name: yup
        .string('Enter event name')
        .required('Event name is required'),
    location: yup.string('Enter event name').required('Event name is required'),
    city_name: yup.string('Enter city').required('City is required'),
    start_date: yup
        .string('Enter start date')
        .required('Start Date is required'),
    end_date: yup.string('Enter end date').required('End Date is required')
    // description: yup
    //     .string('Enter event description')
    //     .required('description is required'),
});

const CreateForm = ({
    onSave = () => {},
    hideDialog,
    refreshEvents,
    start,
    end
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const [events, setEvents] = React.useState(false);
    const [finalContacts, setFinalContacts] = React.useState(false);
    const [startDate, setStartDate] = React.useState(new Date(start));
    const [endDate, setEndDate] = React.useState(new Date(end));

    // const addTimeOff = () => {};

    const { mutate: addEventMutation, isLoading } = useMutation(
        eventsServices.addEvent,
        {
            onSuccess: () => {
                onSave();
                enqueueSnackbar('Event has been created successfully.', {
                    variant: 'success'
                });

                refreshEvents();
                hideDialog();
                return true;
            },

            onError: (error) => {
                console.log('error on create form');
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (data) => {
        try {
            addEventMutation({ ...data });
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };
        const fetchEvents = async () => {
            try {
                const res = await eventsServices.getAllEvents(cToken);

                setEvents(res.events);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchEvents();

        return () => {
            cancelToken.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenContacts = axios.CancelToken.source();
        const cTokenContacts = { cancelToken: cancelTokenContacts.token };
        const fetchContacts = async () => {
            try {
                const users = await userServices.getAllUsers(cTokenContacts);
                let contactsOptions = _.map(
                    _.filter(
                        users.usersList,
                        (contact) => !!contact?.email_address
                    ),
                    (contact) => ({
                        id: contact?.id ? contact?.id : null,
                        type: 'USER',
                        name: `${contact.first_name}`,
                        email_address: contact.email_address
                    })
                );
                setFinalContacts([
                    {
                        id: `ALL`,
                        name: `ALL`,
                        type: `ALL`,
                        email_address: 'All Employees'
                    },
                    ...contactsOptions
                ]);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchContacts();

        return () => {
            cancelTokenContacts.cancel();
        };
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {!events && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {events && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        event_name: '',
                        participants: '',
                        meetingLink: '',
                        start_date: startDate,
                        end_date: endDate,
                        location: '',
                        city_name: '',
                        school_name: '',
                        description: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onCreate(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        setFieldError,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="event_name"
                                            name="event_name"
                                            label="Enter event title"
                                            placeholder="Type the event name/title here"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <ContactAutocomplete
                                            label="Participants/Invitees (Optional)"
                                            name="participants"
                                            prefix=""
                                            contacts={finalContacts}
                                            placeholder="Select/Enter Invitees (Optional)"
                                            // variant="contained"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                        >
                                            <MobileDateTimePicker
                                                label="Start Date"
                                                inputFormat="dd/MM/yyyy hh:mm"
                                                value={startDate}
                                                onChange={(newValue) => {
                                                    setStartDate(newValue);
                                                    values.start_date =
                                                        newValue;

                                                    if (endDate) {
                                                        if (
                                                            newValue > endDate
                                                        ) {
                                                            setFieldError(
                                                                'start_date',
                                                                'Start Date/Time cannot be greater than End Date/Time'
                                                            );
                                                        }
                                                    }
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <JumboTextField
                                                            fullWidth
                                                            size={'small'}
                                                            value={startDate}
                                                            name="start_date"
                                                            {...params}
                                                        />
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                        >
                                            <MobileDateTimePicker
                                                label="End Date"
                                                inputFormat="dd/MM/yyyy hh:mm"
                                                value={endDate}
                                                onChange={(newValue) => {
                                                    setEndDate(newValue);
                                                    values.end_date = newValue;

                                                    if (startDate) {
                                                        if (
                                                            newValue < startDate
                                                        ) {
                                                            setFieldError(
                                                                'end_date',
                                                                'End Date/Time cannot be prior to the event Start Date/Time'
                                                            );
                                                        }
                                                    }
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <JumboTextField
                                                            fullWidth
                                                            size={'small'}
                                                            value={endDate}
                                                            name="end_date"
                                                            {...params}
                                                        />
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="location"
                                            name="location"
                                            label="Enter event location"
                                            placeholder="Enter event location"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="meetingLink"
                                            name="meetingLink"
                                            label="Enter meeting link here if a remote meeting/event"
                                            placeholder="Enter meeting link (webex/google meets etc)"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="city_name"
                                            name="city_name"
                                            label="Enter city name"
                                            placeholder="Enter city name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="school_name"
                                            name="school_name"
                                            label="School name (If Applicable)"
                                            placeholder="Enter school name if event is in/for a school"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="description"
                                            name="description"
                                            label="Enter description"
                                            placeholder="Type description here"
                                            multiline
                                            rows={3}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                    >
                                        Submit
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo officeItem, onSave prop define */
export default CreateForm;
