import React, { useState } from 'react';
import _ from 'lodash';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { useField, useFormikContext } from 'formik';

export default function FreeAutocomplete({
    label = 'Recipients',
    fullWidth = true,
    placeholder = 'Type email and press enter or select from the options',
    name,
    field_name,
    prefix,
    variant = 'outlined',
    options,
    size,
    multiple = false,
    onChange = false,
    freeSolo = true
}) {
    console.log('freeSolo');
    console.log(freeSolo);

    const { setFieldValue } = useFormikContext();

    const [field, meta, helpers] = useField(name);
    const errorText = meta.error && meta.touched ? meta.error : '';

    const handleKeyUp = (event) => {
        if (!multiple && event.key !== 'Enter' && freeSolo) {
            helpers.setValue(event.target.value);

            console.log('Update on key up');

            if (onChange) {
                onChange(event.target.value);
            }
        }
    };

    return (
        <Autocomplete
            {...field}
            fullWidth={fullWidth}
            multiple={multiple}
            id={name}
            options={
                freeSolo
                    ? _.map(options, (option) => option[field_name])
                    : options
            }
            // defaultValue={field.value || []}
            value={field.value || null}
            getOptionLabel={(option) => option || null}
            freeSolo={freeSolo}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                        size="small"
                    />
                ))
            }
            onChange={(_, newValue) => {
                helpers.setValue(newValue);

                if (onChange) {
                    console.log('Update selected office');
                    console.log(newValue);
                    onChange(newValue);
                }
            }}
            renderInput={(params) => (
                <TextField
                    fullWidth={fullWidth}
                    width="100%"
                    {...params}
                    variant={variant}
                    label={label}
                    size={size}
                    placeholder={placeholder}
                    error={meta?.touched && meta?.error}
                    helperText={errorText}
                    onKeyUp={handleKeyUp}
                />
            )}
        />
    );
}
