import React from 'react';
import RequestsAppContext from './RequestsAppContext';
import { CONTACT_APP_ACTIONS } from './utils/constants';

const init = (appState) => appState;

const requestsAppReducer = (state, action) => {
    switch (action.type) {
        case CONTACT_APP_ACTIONS.SET_SELECTED_ITEMS:
            return {
                ...state,
                selectedRequests: action?.payload
            };
        case CONTACT_APP_ACTIONS.SET_CONTACTS_LIST_REFRESH:
            return {
                ...state,
                refreshRequestsList: action.payload
            };
        case CONTACT_APP_ACTIONS.SET_LABELS_LIST_REFRESH:
            return {
                ...state,
                refreshLabelsList: action.payload
            };
        default:
            return state;
    }
};

const RequestsAppProvider = ({ children }) => {
    const [requestsApp, setRequestsApp] = React.useReducer(
        requestsAppReducer,
        {
            selectedRequests: [],
            refreshRequestsList: false,
            refreshLabelsList: false
        },
        init
    );

    const setSelectedRequests = React.useCallback(
        (requests) => {
            setRequestsApp({
                type: CONTACT_APP_ACTIONS.SET_SELECTED_ITEMS,
                payload: requests
            });
        },
        [setRequestsApp]
    );

    const setRequestsListRefresh = React.useCallback(
        (refresh) => {
            setRequestsApp({
                type: CONTACT_APP_ACTIONS.SET_CONTACTS_LIST_REFRESH,
                payload: refresh
            });
        },
        [setRequestsApp]
    );

    const setLabelsListRefresh = React.useCallback(
        (refresh) => {
            setRequestsApp({
                type: CONTACT_APP_ACTIONS.SET_LABELS_LIST_REFRESH,
                payload: refresh
            });
        },
        [setRequestsApp]
    );

    const contextValue = React.useMemo(
        () => ({
            ...requestsApp,
            setSelectedRequests: setSelectedRequests,
            setRequestsListRefresh: setRequestsListRefresh,
            setLabelsListRefresh: setLabelsListRefresh
        }),
        [requestsApp]
    );
    return (
        <RequestsAppContext.Provider value={contextValue}>
            {children}
        </RequestsAppContext.Provider>
    );
};

export default RequestsAppProvider;
