import React from 'react';
import Grid from '@mui/material/Grid';
import Contacts from './components/Contacts';
import Compensation from './components/Compensation';

const UserProfileSidebar = ({ userDetails, authUser }) => {
    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} md={6} lg={12}>
                <Contacts userDetails={userDetails} />
            </Grid>
            {(authUser.role_name === 'administrator' ||
                (authUser.role_name === 'hr' && userDetails.employee) ||
                (authUser.userId === userDetails.id && authUser.employee)) && (
                <Grid item xs={12} md={6} lg={12}>
                    <Compensation userDetails={userDetails} />
                </Grid>
            )}
        </Grid>
    );
};

export default UserProfileSidebar;
