import React from 'react';
import SurveysAppContext from './SurveysAppContext';
import { CONTACT_APP_ACTIONS } from './utils/constants';

const init = (appState) => appState;

const surveysAppReducer = (state, action) => {
    switch (action.type) {
        case CONTACT_APP_ACTIONS.SET_SELECTED_ITEMS:
            return {
                ...state,
                selectedSurveys: action?.payload
            };
        case CONTACT_APP_ACTIONS.SET_CONTACTS_LIST_REFRESH:
            return {
                ...state,
                refreshSurveysList: action.payload
            };
        case CONTACT_APP_ACTIONS.SET_LABELS_LIST_REFRESH:
            return {
                ...state,
                refreshLabelsList: action.payload
            };
        default:
            return state;
    }
};

const SurveysAppProvider = ({ children }) => {
    const [surveysApp, setSurveysApp] = React.useReducer(
        surveysAppReducer,
        {
            selectedSurveys: [],
            refreshSurveysList: false,
            refreshLabelsList: false
        },
        init
    );

    const setSelectedSurveys = React.useCallback(
        (surveys) => {
            setSurveysApp({
                type: CONTACT_APP_ACTIONS.SET_SELECTED_ITEMS,
                payload: surveys
            });
        },
        [setSurveysApp]
    );

    const setSurveysListRefresh = React.useCallback(
        (refresh) => {
            setSurveysApp({
                type: CONTACT_APP_ACTIONS.SET_CONTACTS_LIST_REFRESH,
                payload: refresh
            });
        },
        [setSurveysApp]
    );

    const setLabelsListRefresh = React.useCallback(
        (refresh) => {
            setSurveysApp({
                type: CONTACT_APP_ACTIONS.SET_LABELS_LIST_REFRESH,
                payload: refresh
            });
        },
        [setSurveysApp]
    );

    const contextValue = React.useMemo(
        () => ({
            ...surveysApp,
            setSelectedSurveys: setSelectedSurveys,
            setSurveysListRefresh: setSurveysListRefresh,
            setLabelsListRefresh: setLabelsListRefresh
        }),
        [surveysApp]
    );
    return (
        <SurveysAppContext.Provider value={contextValue}>
            {children}
        </SurveysAppContext.Provider>
    );
};

export default SurveysAppProvider;
