import React from 'react';
import SmtpsAppContext from './SmtpsAppContext';
import { APP_ACTIONS } from './utils/constants';

const init = (appState) => appState;

const AppReducer = (state, action) => {
    switch (action.type) {
        case APP_ACTIONS.SET_SELECTED_ITEMS:
            return {
                ...state,
                selectedList: action?.payload
            };
        case APP_ACTIONS.SET_LIST_REFRESH:
            return {
                ...state,
                refreshList: action.payload
            };
        case APP_ACTIONS.SET_LABELS_LIST_REFRESH:
            return {
                ...state,
                refreshLabelsList: action.payload
            };
        default:
            return state;
    }
};
const SmtpsAppProvider = ({ children }) => {
    const [listApp, setApp] = React.useReducer(
        AppReducer,
        {
            selectedList: [],
            refreshList: false,
            refreshLabelsList: false
        },
        init
    );
    const activeConversation = React.useRef();
    const setSelectedList = React.useCallback(
        (records) => {
            setApp({
                type: APP_ACTIONS.SET_SELECTED_ITEMS,
                payload: records
            });
        },
        [setApp]
    );

    const setListRefresh = React.useCallback(
        (refresh) => {
            setApp({
                type: APP_ACTIONS.SET_LIST_REFRESH,
                payload: refresh
            });
        },
        [setApp]
    );

    const setLabelsListRefresh = React.useCallback(
        (refresh) => {
            setApp({
                type: APP_ACTIONS.SET_LABELS_LIST_REFRESH,
                payload: refresh
            });
        },
        [setApp]
    );

    const contextValue = {
        ...listApp,
        activeConversation,
        setSelectedList: setSelectedList,
        setListRefresh: setListRefresh,
        setLabelsListRefresh: setLabelsListRefresh
    };
    return (
        <SmtpsAppContext.Provider value={contextValue}>
            {children}
        </SmtpsAppContext.Provider>
    );
};
export default SmtpsAppProvider;
