import React from 'react';
import { Ability, AbilityBuilder } from '@casl/ability';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'app/AppAbility';
import {
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    IconButton,
    Typography
} from '@mui/material';

import Stack from '@mui/material/Stack';
import { Facebook, Google, Twitter } from '@mui/icons-material';
import Div from '@jumbo/shared/Div';
import { alpha } from '@mui/material/styles';
import { ASSET_IMAGES } from '../../../utils/constants/paths';
import { getAssetPath } from '../../../utils/appHelpers';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import LoadingButton from '@mui/lab/LoadingButton';
import authServices from '../../../services/auth-services';
import { Link, useNavigate } from 'react-router-dom';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { useSnackbar } from 'notistack';

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup.string('Enter your password').required('Password is required')
});

const Login2 = ({ disableSmLogin }) => {
    const ability = useAbility(AbilityContext);
    const { setAuthToken } = useJumboAuth();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const updateAbilityPermissions = React.useCallback(
        async (ability, permissions) => {
            ability.update(permissions);
        },
        []
    );

    const onSignIn = async (email, password) => {
        try {
            const data = await authServices.signIn({
                email_address: email,
                password
            });

            updateAbilityPermissions(ability, data.permissions);
            setAuthToken(data?.token);
            setTimeout(() => {
                navigate('/');
            }, 1000);
        } catch (error) {
            console.log('login error');
            console.log(error);
            enqueueSnackbar(error, {
                variant: 'error'
            });
        }
    };

    return (
        <Div
            sx={{
                width: 720,
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        background: `#0267a0 url(${getAssetPath(
                            `${ASSET_IMAGES}/widgets/pexels-element.jpg`,
                            '640x428'
                        )}) no-repeat center`,
                        backgroundSize: 'cover',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#0267a0', 0.65)
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%'
                        }}
                    >
                        <Div sx={{ mb: 2 }}>
                            <Typography
                                variant={'h3'}
                                color={'inherit'}
                                fontWeight={500}
                                mb={3}
                            >
                                Sign In
                            </Typography>
                            <Typography variant={'body1'} mb={2}>
                                We provide our students transparent, up-to date
                                information on the all available options, to
                                help them make the right decisions as per their
                                aspiration and budget.
                            </Typography>
                        </Div>

                        <Div sx={{ mt: 'auto' }}>
                            <Link
                                to="#"
                                underline="none"
                                sx={{ display: 'inline-flex' }}
                            >
                                <img
                                    src={`${ASSET_IMAGES}/IEC-logo-128.png`}
                                    alt="IEC Consultants"
                                />
                            </Link>
                        </Div>
                    </Div>
                </CardContent>
                <CardContent sx={{ flex: 1, p: 4 }}>
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (data, { setSubmitting }) => {
                            setSubmitting(true);
                            await onSignIn(data.email, data.password);
                            setSubmitting(false);
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form
                                style={{ textAlign: 'left' }}
                                noValidate
                                autoComplete="off"
                            >
                                <Div sx={{ mt: 1, mb: 3 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="email"
                                        label="Email"
                                        placeholder="Enter email address"
                                    />
                                </Div>
                                <Div sx={{ mt: 1, mb: 2 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        placeholder="Enter password"
                                    />
                                </Div>
                                <Div sx={{ mb: 2 }}>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label="Remember me"
                                    />
                                </Div>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isSubmitting}
                                >
                                    Sign In
                                </LoadingButton>
                            </Form>
                        )}
                    </Formik>
                    <Typography variant={'body1'} mt={'auto'}>
                        Forgot your password?{' '}
                        <Link
                            to={'/user/reset-password'}
                            color={'primary'}
                            underline={'none'}
                            sx={{ cursor: 'pointer' }}
                        >
                            Reset password
                        </Link>
                    </Typography>
                </CardContent>
            </Card>
        </Div>
    );
};

export default Login2;
