import React from 'react';
import { Grid } from '@mui/material';
import { Card } from '@mui/material';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import ExpensesAppSidebar from './components/ExpensesAppSidebar';
import ExpensesList from './components/ExpensesList';

import ExpenseSummary from './components/ExpenseSummary';
import ExpensesAppProvider from './ExpensesAppProvider';
import RequestsAppProvider from './RequestsAppProvider';
import AdvancesAppProvider from './AdvancesAppProvider';
import ReimbursementsAppProvider from './ReimbursementsAppProvider';
import PageHeader from '../../../layouts/shared/headers/PageHeader/PageHeader';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import { Stack, useMediaQuery } from '@mui/material';
import ExpenseFab from './components/ExpenseFab';
import FolderDropdown from './components/FolderDropdown';

import { useParams } from 'react-router-dom';

import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import RequestsList from './components/RequestsList';
import RequestDetailWrapper from './components/RequestDetail/RequestDetailWrapper';
import AdvancesList from './components/AdvancesList';
import AdvanceDetailWrapper from './components/AdvanceDetail/AdvanceDetailWrapper';
import ReportsList from './components/ReportsList';
import ReportDetailWrapper from './components/ReportDetail/ReportDetailWrapper';
import ReimbursementsList from './components/ReimbursementsList';
import ReimbursementDetailWrapper from './components/ReimbursementDetail/ReimbursementDetailWrapper';

const ExpensesApp = () => {
    const { authUser } = useJumboAuth();
    const { theme } = useJumboTheme();
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(
        () => ({
            sidebar: {
                sx: {
                    [theme.breakpoints.up('lg')]: {
                        position: 'sticky',
                        zIndex: 5,
                        top: 96,
                        minHeight: 'auto'
                    },
                    [theme.breakpoints.down('lg')]: {
                        display: 'none'
                    }
                }
            },
            wrapper: {
                sx: {
                    alignItems: 'flex-start'
                }
            }
        }),
        [theme]
    );

    const params = useParams();
    const { category } = params;
    const requestID = params.id || null;

    return (
        <ExpensesAppProvider>
            <RequestsAppProvider>
                <AdvancesAppProvider>
                    <ReimbursementsAppProvider>
                        <JumboContentLayout
                            sidebar={<ExpensesAppSidebar />}
                            header={
                                <PageHeader
                                    title={'Expenses'}
                                    subheader={'Expense & Approvals Management'}
                                />
                            }
                            layoutOptions={layoutOptions}
                        >
                            {lg && (
                                <Stack
                                    spacing={2}
                                    direction={'row'}
                                    sx={{ mb: 3, mt: -2 }}
                                >
                                    <FolderDropdown />
                                    {/* <LabelDropdown /> */}
                                    <ExpenseFab />
                                </Stack>
                            )}
                            <ExpenseSummary />

                            {['all', 'purchase', 'travel'].includes(
                                category
                            ) && <ExpensesList />}
                            {category === 'requests' && !requestID && (
                                <RequestsList />
                            )}
                            {requestID && category === 'requests' && (
                                <RequestDetailWrapper requestID={requestID} />
                            )}

                            {category === 'advances' && !requestID && (
                                <AdvancesList />
                            )}
                            {requestID && category === 'advances' && (
                                <AdvanceDetailWrapper requestID={requestID} />
                            )}

                            {category === 'reimbursements' && !requestID && (
                                <ReimbursementsList />
                            )}
                            {requestID && category === 'reimbursements' && (
                                <ReimbursementDetailWrapper
                                    requestID={requestID}
                                />
                            )}

                            {category === 'reports' && !requestID && (
                                <ReportsList />
                            )}
                            {requestID && category === 'reports' && (
                                <ReportDetailWrapper requestID={requestID} />
                            )}
                        </JumboContentLayout>
                    </ReimbursementsAppProvider>
                </AdvancesAppProvider>
            </RequestsAppProvider>
        </ExpensesAppProvider>
    );
};
export default ExpensesApp;
