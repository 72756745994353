import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const performanceServices = {};

performanceServices.getAllPerformances = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/performances',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

performanceServices.getPerformances = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/performances', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

performanceServices.getPerformanceDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/performances/performance/${id}`, {
        id: id
    });
    // console.log('service');
    // console.log(data);
    // console.log(data.performance.first_name);
    // console.log(JSON.parse(data.performance));
    // console.log(data.performance.first_name);

    return data;
};

performanceServices.addKPIsTargets = async (performance) => {
    const { data } = await jwtAuthAxios.post(`/performances`, performance);
    return data;
};

performanceServices.updatePerformance = async (performance) => {
    const { data } = await jwtAuthAxios.patch(
        `/performances/${performance.id}`,
        performance
    );
    return data;
};

performanceServices.deletePerformance = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/performances/${id}`, {
        id: id
    });
    return data;
};

performanceServices.deleteMultiplePerformances = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/performances`, {
        data: ids
    });
    return data;
};

export { performanceServices };
