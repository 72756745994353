import React from 'react';

import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useMutation } from 'react-query';
import { uniapplicationsServices } from 'app/services/uniapplications-services';
import { institutionsServices } from 'app/services/institutions-services';
import { studentServices } from 'app/services/student-services';
import { officeServices } from 'app/services/office-services';
import { coursesServices } from 'app/services/courses-services';
import { countriesServices } from 'app/services/countries-services';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';

import { useFormikContext, Formik, Form, Field } from 'formik';
import FreeAutocomplete from 'app/shared/FreeAutocomplete';
//import FreeAutocompleteMultiple from 'app/shared/FreeAutocomplete/Multiple';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import axios from 'axios';
import CourseSelect from './CourseSelect';

import {
    capitalizeAllWords,
    getCurrentDate,
    getNextYearDate
} from 'app/utils/appHelpers';

import _ from 'lodash';

const validationSchema = yup.object({
    status: yup.string('Select status').required('Status is required'),
    applicationCode: yup
        .string('Enter application ID/Code')
        .required('Application ID/Code name is required'),
    institutionId: yup
        .string('Select Institution')
        .required('Institution is required'),
    // courseId: yup
    //     .array('Please select a course')
    //     .of(
    //         yup.object().shape({
    //             value: yup.string(),
    //             label: yup.string()
    //         })
    //     )
    //     .min(1, 'Please select atleast 1 course')
    //     .required('Please select applied courses')
    //     .typeError('Please select a valid course'),
    option_one: yup
        .string('Enter course applied for')
        .required('Course is required'),
    application_fee: yup
        .string('Enter application fee & provide receipt')
        .required('Application fee & provide receipt name is required'),
    application_date: yup
        .string('Enter date of application')
        .required('Application date is required')
});

const Uniapplication = ({
    listItem,
    userDetails,
    setActiveSchema,
    setInitialFormValues
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const { values, setValues, touched, errors, setErrors, submitForm } =
        useFormikContext();

    const [countries, setCountries] = React.useState(false);
    const [customizingCourses, setCustomizingCourses] = React.useState(false);
    const [institutions, setInstitutions] = React.useState(false);
    const [institutionId, setInstitutionId] = React.useState('');
    const [feeCurrency, setFeeCurrency] = React.useState('');

    const [requirements, setRequirements] = React.useState(false);
    const [officeId, setOfficeId] = React.useState('');
    const [courses, setCourses] = React.useState([]);
    const [finalCourses, setFinalCourses] = React.useState([]);
    const [defaultCourses, setDefaultCourses] = React.useState(false);
    const [fieldDate, setFieldDate] = React.useState(
        dayjs(getCurrentDate(false))
    );

    React.useEffect(() => {
        const cancelInstToken = axios.CancelToken.source();
        const cInstToken = { cancelToken: cancelInstToken.token };
        const fetchInstitutions = async () => {
            try {
                const res = await institutionsServices.getAllInstitutions(
                    cInstToken
                );

                setInstitutions(res.institutions);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchInstitutions();

        return () => {
            cancelInstToken.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenCourses = axios.CancelToken.source();
        const cTokenCourses = {
            cancelToken: cancelTokenCourses.token
        };
        const fetchCourses = async () => {
            try {
                const res = await coursesServices.getAllCourses(cTokenCourses);

                setCourses(res.courses);
                setFinalCourses(res.courses);

                //   const reqs = listItem?.courseId
                //     ? _.filter(res.courses, (obj) =>
                //         _.includes(_.map(listItem.courseId, "id"), obj.id)
                //       )
                //     : [];

                //   setDefaultCourses(reqs);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        // fetchCourses();

        return () => {
            cancelTokenCourses.cancel();
        };
    }, []);

    React.useEffect(() => {
        // if (institutionId) {
        //     setFinalCourses(
        //         _.filter(
        //             courses,
        //             (course) => course?.institutionId?.id === institutionId
        //         )
        //     );
        // }
        // setCustomizingCourses(false);
    }, [institutionId]);

    React.useEffect(() => {
        if (fieldDate) {
            values.application_date = fieldDate;
        }

        setCustomizingCourses(false);
    }, [fieldDate]);

    React.useEffect(() => {
        setActiveSchema(validationSchema);

        setValues({
            status: 4,
            leadId: listItem.id,
            application_date: fieldDate,
            option_one: '',
            option_two: '',
            option_three: ''
        });

        setErrors({});
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%'
            }}
        >
            {(!courses || !institutions) && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {courses && institutions && (
                <Div sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={12} md={12}>
                            <JumboSelectField
                                width="100%"
                                size={'small'}
                                id="officeId"
                                name="officeId"
                                label="Select Office"
                                value={designatedOffice}
                                placeholder="Select office"
                                onChange={(event) =>
                                    setDesignatedOffice(event.target.value)
                                }
                            >
                                <MenuItem key={'officeId'} value="">
                                    <em>Select Office</em>
                                </MenuItem>

                                {offices.map((office) => (
                                    <MenuItem key={office.id} value={office.id}>
                                        {capitalizeAllWords(office.office_name)}
                                    </MenuItem>
                                ))}
                            </JumboSelectField>
                        </Grid> */}
                        <Grid item xs={12} md={6}>
                            <JumboTextField
                                fullWidth
                                size={'small'}
                                id="applicationCode"
                                name="applicationCode"
                                label="Application Code/Serial No./ID"
                                placeholder="Enter application ID/Serial No./Code/Tracking No. here"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field name="institutionId">
                                {({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        id="institutionId"
                                        sx={{
                                            width: 500,
                                            maxWidth: '100%'
                                        }}
                                        size="small"
                                        options={institutions}
                                        autoHighlight
                                        getOptionLabel={(option) =>
                                            option?.institution_name
                                                ? option.institution_name
                                                : ''
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                sx={{
                                                    '& > img': {
                                                        mr: 2,
                                                        flexShrink: 0
                                                    }
                                                }}
                                                {...props}
                                            >
                                                {option.institution_name}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Institution"
                                                placeholder="Choose a institution"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password'
                                                }}
                                                error={
                                                    touched?.institutionId &&
                                                    !!errors.institutionId
                                                }
                                                helperText={
                                                    touched?.institutionId &&
                                                    errors.institutionId
                                                }
                                            />
                                        )}
                                        onChange={
                                            (event, newValue) => {
                                                // setCustomizingCourses(true);
                                                values.institutionId =
                                                    newValue?.id;
                                                values.institution_name =
                                                    newValue?.institution_name;
                                                values.countryId =
                                                    newValue?.countryId?.id;
                                                values.country_name =
                                                    newValue?.countryId?.country_name;
                                                values.application_requirements =
                                                    newValue?.application_requirements;
                                                values.offer_waiting_period =
                                                    newValue?.offer_waiting_period ||
                                                    10;
                                                values.application_fee =
                                                    newValue?.application_fee ||
                                                    1;
                                                setFeeCurrency(
                                                    newValue
                                                        ?.application_fee_currency
                                                        ?.currency || 'USD'
                                                );
                                                setRequirements(
                                                    newValue?.application_requirements
                                                );

                                                setInstitutionId(newValue?.id);
                                                values.courseId = [];
                                                setErrors({});
                                            }
                                            // Set the new value in the form field
                                        }
                                        value={
                                            institutions.find(
                                                (option) =>
                                                    option?.id ===
                                                    values?.institutionId
                                            ) || ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {customizingCourses && (
                                <Div
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%'
                                    }}
                                >
                                    <CircularProgress
                                        color="inherit"
                                        size={24}
                                    />
                                    <Typography
                                        sx={{ marginLeft: '16px' }}
                                        color="default"
                                    >
                                        Loading Courses please wait ...
                                    </Typography>
                                </Div>
                            )}
                            {!customizingCourses && (
                                <CourseSelect />
                                // <FreeAutocomplete
                                //     fullWidth
                                //     multiple
                                //     size={'small'}
                                //     id="course_name"
                                //     name="course_name"
                                //     field_name="course_name"
                                //     label="Enter Course"
                                //     options={finalCourses}
                                // />
                                // <Field name="courseId">
                                //     {({ field }) => (
                                //         <Autocomplete
                                //             {...field}
                                //             multiple
                                //             id="courseId"
                                //             size={'small'}
                                //             options={finalCourses}
                                //             getOptionLabel={(option) => {
                                //                 return option.course_name || '';
                                //             }}
                                //             renderOption={(props, option) => (
                                //                 <Box
                                //                     component="li"
                                //                     sx={{
                                //                         '& > img': {
                                //                             mr: 2,
                                //                             flexShrink: 0
                                //                         }
                                //                     }}
                                //                     {...props}
                                //                 >
                                //                     {option.course_name}
                                //                 </Box>
                                //             )}
                                //             renderInput={(params) => (
                                //                 <TextField
                                //                     {...params}
                                //                     variant="outlined"
                                //                     label="Select Course"
                                //                     placeholder="Courses"
                                //                     error={
                                //                         touched?.courseId &&
                                //                         !!errors.courseId
                                //                     }
                                //                     helperText={
                                //                         touched?.courseId &&
                                //                         errors.courseId
                                //                     }
                                //                 />
                                //             )}
                                //             onChange={(event, newValue) =>
                                //                 field.onChange({
                                //                     target: {
                                //                         name: field.name,
                                //                         value: newValue
                                //                     }
                                //                 })
                                //             }
                                //             // value={values.courseId || ''}
                                //         />
                                //     )}
                                // </Field>
                            )}
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <JumboTextField
                                fullWidth
                                size={'small'}
                                id="application_fee"
                                name="application_fee"
                                label="Application Fee"
                                placeholder="Enter the application fee here"
                                readOnly
                                type={'number'}
                                value={values.application_fee}
                                inputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {feeCurrency}
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                    label="Application Date"
                                    inputFormat="DD/MM/YYYY"
                                    value={fieldDate}
                                    onChange={(newValue) => {
                                        setFieldDate(newValue);
                                        values.application_date = newValue;
                                    }}
                                    renderInput={(params) => {
                                        return (
                                            <JumboTextField
                                                fullWidth
                                                size={'small'}
                                                value={fieldDate}
                                                name="application_date"
                                                {...params}
                                            />
                                        );
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Div>
            )}
        </Div>
    );
};
/* Todo uniapplicationItem, onSave prop define */
export default Uniapplication;
