import React from 'react';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import { List } from '@mui/material';
import { goals } from './data';
import EventItem from './EventItem';

const Events = ({ sx, userDetails }) => {
    return (
        <JumboCardQuick
            title={'Performance Review'}
            subheader={`What ${userDetails.first_name} is up to`}
            wrapperSx={{ p: 0 }}
            headerSx={{
                borderBottom: 1,
                borderBottomColor: 'divider'
            }}
            sx={sx}
        >
            <List disablePadding>
                {goals.map((goal, index) => (
                    <EventItem goal={goal} key={index} />
                ))}
            </List>
        </JumboCardQuick>
    );
};

export default Events;
