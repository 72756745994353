import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import JumboRadioGroup from '@jumbo/components/JumboFormik/JumboRadioGroup';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useMutation } from 'react-query';
import { requestsServices } from 'app/services/requests-services';
import { countriesServices } from 'app/services/countries-services';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import axios from 'axios';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

import _ from 'lodash';

const validationSchema = yup.object({
    type: yup
        .string('Select travel type name')
        .required('Travel type is required'),
    // country_of_origin: yup
    //     .string('Select the country you are departing from')
    //     .required('Country of departure is required'),
    // country_of_destination: yup
    //     .string('Select the destination country')
    //     .required('Country of destination is required'),
    // // gender: yup.string('Select gender').required('Gender is required'),
    // city_of_origin: yup
    //     .string('Select the city you are departing from')
    //     .required('City of departure is required'),
    // city_of_destination: yup
    // .string('Select the destination city')
    // .required('City of destination is required'),
    departure_date: yup
        .string('Enter departure date')
        .required('Departure date is required'),
    purpose: yup
        .string('Enter travel purpose')
        .required('Purpose for travel is required'),
    // return_date: yup
    //     .string('Enter departure date')
    //     .required('Departure date is required'),
    trip_type: yup.string('Select trip type').required('Trip type is required'),
    fare_amount: yup
        .string('Enter fare amount')
        .required('Fare amount is required')
});

const TYPES = [
    {
        id: 'International',
        type_name: 'International'
    },
    {
        id: 'Local',
        type_name: 'Local'
    }
];

const TravelForm = ({ onSave, hideDialog, authUser }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [countries, setCountries] = React.useState(false);
    const [feeCurrency, setFeeCurrency] = React.useState(
        authUser?.primaryCurrency?.currency || 'KES'
    );
    const [feeCurrencyName, setFeeCurrencyName] = React.useState(
        authUser?.primaryCurrency?.currency_name || 'Kenyan Shillings'
    );
    const [departureDate, setDepartureDate] = React.useState(dayjs(new Date()));
    const [accomodationRequired, setAccomodationRequired] =
        React.useState(false);
    const [returnDate, setReturnDate] = React.useState(dayjs(new Date()));
    const [travelType, setTravelType] = React.useState(false);

    const { mutate: submitRequestMutation, isLoading } = useMutation(
        requestsServices.addRequest,
        {
            onSuccess: () => {
                enqueueSnackbar('Request has been submitted successfully.', {
                    variant: 'success'
                });
                onSave();
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (request) => {
        if (request.travel_type === 'Local') {
            if (
                request.city_of_origin === '' ||
                request.city_of_destination === ''
            ) {
                enqueueSnackbar('Please enter City Of Origin and Destination', {
                    variant: 'warning'
                });
                return;
            }
        }

        if (request.travel_type === 'International') {
            if (
                request.country_of_origin === '' ||
                request.country_of_destination === ''
            ) {
                enqueueSnackbar(
                    'Please enter Country Of Origin and Destination',
                    {
                        variant: 'warning'
                    }
                );
                return;
            }
        }

        try {
            let postData = {
                formikData: { ...request, module: 'travel' },
                files: false
            };
            submitRequestMutation(postData);
        } catch (error) {}
    };

    const handleDateChange = () => {
        const startTime = new Date(departureDate).getTime();
        const endTime = new Date(returnDate).getTime();

        if (isNaN(startTime) || isNaN(endTime)) {
            return;
        }

        if (startTime >= endTime) {
            return;
        }

        let dayDifference =
            new Date(returnDate).getDate() - new Date(departureDate).getDate();

        const timeDifference = Math.abs(endTime - startTime);
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));

        // console.log('Check accomodation Requirement');
        // console.log(new Date(departureDate));
        // console.log(new Date(returnDate));
        // console.log(dayDifference);
        // console.log(hours);

        // if (hours > 24 || dayDifference > 0) {
        if (dayDifference > 0) {
            setAccomodationRequired(true);
        } else {
            setAccomodationRequired(false);
        }
    };

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };
        const fetchCountries = async () => {
            try {
                const res = await countriesServices.getAllCountries(cToken);

                setCountries(res.countries);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchCountries();

        return () => {
            cancelToken.cancel();
        };
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {!countries && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {countries && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        type: '',
                        trip_type: '',
                        country_of_origin: '',
                        country_of_origin_name: '',
                        country_of_destination: '',
                        country_of_destination_name: '',
                        city_of_origin: '',
                        city_of_destination: '',
                        departure_date: departureDate,
                        return_date: returnDate,
                        currency: feeCurrency,
                        currency_name: feeCurrencyName,
                        purpose: '',
                        fare_amount: 0,
                        accomodation_fees: 0,
                        miscellaneous: 0,
                        taxi_fees: 0
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onCreate(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        setFieldValue,
                        setFieldError,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            <Grid item xs={12} md={6}>
                                <Field name="type">
                                    {({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            id="type"
                                            sx={{
                                                marginBottom: '8px'
                                            }}
                                            size="small"
                                            options={TYPES}
                                            autoHighlight
                                            getOptionLabel={(option) =>
                                                option?.type_name
                                                    ? option.type_name
                                                    : ''
                                            }
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => option.id === value.id}
                                            renderOption={(props, option) => (
                                                <Box
                                                    component="li"
                                                    sx={{
                                                        '& > img': {
                                                            mr: 2,
                                                            flexShrink: 0
                                                        }
                                                    }}
                                                    {...props}
                                                >
                                                    {option.type_name}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Travel Type"
                                                    placeholder="Choose travel type"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        autoComplete:
                                                            'new-password'
                                                    }}
                                                    error={
                                                        touched?.type &&
                                                        !!errors.type
                                                    }
                                                    helperText={
                                                        touched?.type &&
                                                        errors.type
                                                    }
                                                />
                                            )}
                                            onChange={(event, newValue) => {
                                                values.type = newValue?.id;
                                                setTravelType(newValue?.id);
                                            }}
                                            value={
                                                TYPES.find(
                                                    (option) =>
                                                        option.id ===
                                                        values.type
                                                ) || null
                                            }
                                        />
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <JumboRadioGroup
                                    row
                                    labelid="trip_type-row"
                                    label="Trip Type :"
                                    aria-labelledby="trip_type-row"
                                    name="trip_type"
                                    // sx={{
                                    //     display: 'flex',
                                    //     flexDirection: 'row',
                                    //     width: '100%',
                                    //     JustifyContent: 'space-between'
                                    // }}
                                >
                                    <FormControlLabel
                                        value="one way"
                                        control={<Radio size="small" />}
                                        label="One Way"
                                    />
                                    <FormControlLabel
                                        value="round trip"
                                        control={<Radio size="small" />}
                                        label="Round Trip"
                                    />
                                    {travelType === 'Local' && (
                                        <FormControlLabel
                                            value="multi city"
                                            control={<Radio size="small" />}
                                            label="Multi City"
                                            sx={{
                                                display: `${
                                                    travelType === 'Local'
                                                        ? 'block'
                                                        : 'none'
                                                }`
                                            }}
                                        />
                                    )}
                                    {travelType === 'International' && (
                                        <FormControlLabel
                                            value="multi country"
                                            control={<Radio size="small" />}
                                            label="Multi Country"
                                        />
                                    )}
                                </JumboRadioGroup>
                            </Grid>

                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    {travelType === 'International' && (
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <Field name="country_of_origin">
                                                    {({ field }) => (
                                                        <Autocomplete
                                                            {...field}
                                                            id="country_of_origin"
                                                            sx={{
                                                                width: '100%',
                                                                marginTop: '8px'
                                                            }}
                                                            size="small"
                                                            options={countries}
                                                            autoHighlight
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option?.country_name
                                                                    ? option.country_name
                                                                    : ''
                                                            }
                                                            isOptionEqualToValue={(
                                                                option,
                                                                value
                                                            ) =>
                                                                option.id ===
                                                                value.id
                                                            }
                                                            renderOption={(
                                                                props,
                                                                option
                                                            ) => (
                                                                <Box
                                                                    component="li"
                                                                    sx={{
                                                                        '& > img':
                                                                            {
                                                                                mr: 2,
                                                                                flexShrink: 0
                                                                            }
                                                                    }}
                                                                    {...props}
                                                                >
                                                                    <img
                                                                        loading="lazy"
                                                                        width="20"
                                                                        src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                                                                        srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                                                                        alt=""
                                                                    />
                                                                    {
                                                                        option.country_name
                                                                    }
                                                                </Box>
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Departing From"
                                                                    placeholder="Choose departing country"
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        autoComplete:
                                                                            'new-password'
                                                                    }}
                                                                    error={
                                                                        touched?.countryId &&
                                                                        !!errors.countryId
                                                                    }
                                                                    helperText={
                                                                        touched?.countryId &&
                                                                        errors.countryId
                                                                    }
                                                                />
                                                            )}
                                                            onChange={
                                                                (
                                                                    event,
                                                                    newValue
                                                                ) => {
                                                                    values.country_of_origin =
                                                                        newValue?.id;
                                                                    values.country_of_origin_name =
                                                                        newValue?.country_name;
                                                                }
                                                                // Set the new value in the form field
                                                            }
                                                            value={
                                                                countries.find(
                                                                    (option) =>
                                                                        option.id ===
                                                                        values.country_of_origin
                                                                ) || null
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Field name="country_of_destination">
                                                    {({ field }) => (
                                                        <Autocomplete
                                                            {...field}
                                                            id="country_of_destination"
                                                            sx={{
                                                                width: '100%',
                                                                marginTop: '8px'
                                                            }}
                                                            size="small"
                                                            options={countries}
                                                            autoHighlight
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option?.country_name
                                                                    ? option.country_name
                                                                    : ''
                                                            }
                                                            isOptionEqualToValue={(
                                                                option,
                                                                value
                                                            ) =>
                                                                option.id ===
                                                                value.id
                                                            }
                                                            renderOption={(
                                                                props,
                                                                option
                                                            ) => (
                                                                <Box
                                                                    component="li"
                                                                    sx={{
                                                                        '& > img':
                                                                            {
                                                                                mr: 2,
                                                                                flexShrink: 0
                                                                            }
                                                                    }}
                                                                    {...props}
                                                                >
                                                                    <img
                                                                        loading="lazy"
                                                                        width="20"
                                                                        src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                                                                        srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                                                                        alt=""
                                                                    />
                                                                    {
                                                                        option.country_name
                                                                    }
                                                                </Box>
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Destination"
                                                                    placeholder="Choose departing country"
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        autoComplete:
                                                                            'new-password'
                                                                    }}
                                                                    error={
                                                                        touched?.countryId &&
                                                                        !!errors.countryId
                                                                    }
                                                                    helperText={
                                                                        touched?.countryId &&
                                                                        errors.countryId
                                                                    }
                                                                />
                                                            )}
                                                            onChange={
                                                                (
                                                                    event,
                                                                    newValue
                                                                ) => {
                                                                    values.country_of_destination =
                                                                        newValue?.id;
                                                                    values.country_of_destination_name =
                                                                        newValue?.country_name;
                                                                }
                                                                // Set the new value in the form field
                                                            }
                                                            value={
                                                                countries.find(
                                                                    (option) =>
                                                                        option.id ===
                                                                        values.country_of_destination
                                                                ) || null
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                            </Grid>
                                        </>
                                    )}
                                    {travelType === 'Local' && (
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <JumboTextField
                                                    fullWidth
                                                    size={'small'}
                                                    sx={{
                                                        marginTop: '8px'
                                                    }}
                                                    id="city_of_origin"
                                                    name="city_of_origin"
                                                    label="City Of Origin"
                                                    placeholder="Type the city of Origin"
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <JumboTextField
                                                    fullWidth
                                                    size={'small'}
                                                    sx={{
                                                        marginTop: '8px'
                                                    }}
                                                    id="city_of_destination"
                                                    name="city_of_destination"
                                                    label="Destination City"
                                                    placeholder="Type the Destination City"
                                                />
                                            </Grid>
                                        </>
                                    )}

                                    <Grid item xs={12} md={6}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <MobileDateTimePicker
                                                label="Departure Date"
                                                inputFormat="YYYY-MM-DD HH:mm"
                                                closeOnSelect={true}
                                                value={departureDate}
                                                onChange={(newValue) => {
                                                    setDepartureDate(newValue);
                                                    values.departure_date =
                                                        newValue;
                                                    handleDateChange();

                                                    if (newValue > returnDate) {
                                                        console.log(
                                                            'Departure Date is greater than  return date'
                                                        );
                                                        setFieldError(
                                                            'return_date',
                                                            'Return date must be after departure date'
                                                        );
                                                    }
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <JumboTextField
                                                            fullWidth
                                                            size={'small'}
                                                            sx={{
                                                                marginTop: '8px'
                                                            }}
                                                            value={
                                                                departureDate
                                                            }
                                                            name="departure_date"
                                                            {...params}
                                                        />
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    {values.trip_type !== 'one way' && (
                                        <Grid item xs={12} md={6}>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                            >
                                                <MobileDateTimePicker
                                                    label="Return Date"
                                                    closeOnSelect={true}
                                                    inputFormat="YYYY-MM-DD HH:mm"
                                                    value={returnDate}
                                                    onChange={(newValue) => {
                                                        if (
                                                            newValue <
                                                            departureDate
                                                        ) {
                                                            setFieldError(
                                                                'return_date',
                                                                'Return date must be after departure date'
                                                            );
                                                        } else {
                                                            setReturnDate(
                                                                newValue
                                                            );
                                                            handleDateChange();
                                                            values.return_date =
                                                                newValue;
                                                        }
                                                    }}
                                                    renderInput={(params) => {
                                                        return (
                                                            <JumboTextField
                                                                fullWidth
                                                                size={'small'}
                                                                sx={{
                                                                    marginTop:
                                                                        '8px'
                                                                }}
                                                                value={
                                                                    returnDate
                                                                }
                                                                name="return_date"
                                                                {...params}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    )}

                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            sx={{
                                                marginTop: '8px'
                                            }}
                                            id="fare_amount"
                                            name="fare_amount"
                                            type="number"
                                            label="Fare Amount"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {feeCurrency}
                                                    </InputAdornment>
                                                )
                                            }}
                                            placeholder="Enter travel expense fare amount here"
                                        />
                                    </Grid>
                                    {((travelType === 'Local' &&
                                        accomodationRequired) ||
                                        travelType === 'International') && (
                                        <Grid item xs={12} md={6}>
                                            <JumboTextField
                                                fullWidth
                                                size={'small'}
                                                sx={{
                                                    marginTop: '8px'
                                                }}
                                                id="accomodation_fees"
                                                name="accomodation_fees"
                                                type="number"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            {feeCurrency}
                                                        </InputAdornment>
                                                    )
                                                }}
                                                label="Accomodation Fees (Rate Per Night)"
                                                placeholder="Enter accomodation/hotel charges"
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            sx={{
                                                marginTop: '8px'
                                            }}
                                            id="taxi_fees"
                                            name="taxi_fees"
                                            type="number"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {feeCurrency}
                                                    </InputAdornment>
                                                )
                                            }}
                                            label="Taxi Fees"
                                            placeholder="Enter taxi charges amount"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            sx={{
                                                marginTop: '8px'
                                            }}
                                            id="miscellaneous"
                                            name="miscellaneous"
                                            type="number"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {feeCurrency}
                                                    </InputAdornment>
                                                )
                                            }}
                                            label="Miscellaneous"
                                            placeholder="Enter miscellaneous amount"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            sx={{
                                                marginTop: '8px'
                                            }}
                                            id="purpose"
                                            name="purpose"
                                            label="Purpose"
                                            placeholder="Enter purpose for travel"
                                            multiline
                                            rows={2}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                    >
                                        Submit Request
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo officeItem, onSave prop define */
export default TravelForm;
