import React from 'react';
import _ from 'lodash';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import LabelIcon from '@mui/icons-material/Label';
import Link from '@mui/material/Link';

const Territories = ({ userDetails, authUser }) => {
    return (
        <JumboCardQuick
            title={'Assigned Territories'}
            subheader={`List of Countries ${
                userDetails?.id === authUser?.userId
                    ? 'You are managing'
                    : `${userDetails?.first_name} is managing`
            }`}
            sx={{ marginBottom: '24px' }}
            noWrapper
        >
            <List disablePadding sx={{ mb: 2 }}>
                {(!userDetails?.assignedTerritories ||
                    userDetails?.assignedTerritories.length === 0) && (
                    <ListItem
                        alignItems="flex-start"
                        sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                    >
                        No Territories Assigned
                    </ListItem>
                )}
                {userDetails?.assignedTerritories &&
                    _.map(userDetails?.assignedTerritories, (territory) => (
                        <ListItem
                            key={territory?.id}
                            alignItems="flex-start"
                            sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                        >
                            <ListItemIcon
                                sx={{ minWidth: 36, color: 'text.secondary' }}
                            >
                                <LabelIcon
                                    fontSize={'small'}
                                    sx={{
                                        verticalAlign: 'middle',
                                        mt: '-4px',
                                        mr: 1
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant="body1"
                                        color="text.secondary"
                                    >
                                        {territory?.country_name}
                                    </Typography>
                                }
                                secondary={''}
                            />
                        </ListItem>
                    ))}
            </List>
        </JumboCardQuick>
    );
};

export default Territories;
