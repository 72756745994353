import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const coursesServices = {};

coursesServices.getAllCourses = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/courses',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

coursesServices.getCourses = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/courses', {
        params: {
            page: parseInt(page),
            limit: parseInt(limit),
            ...queryParams
        }
    });
    return data;
};

coursesServices.getCourseDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/courses/course/${id}`, {
        id: id
    });

    return data;
};

coursesServices.addCourse = async (course) => {
    const { data } = await jwtAuthAxios.post(`/courses`, course);
    return data;
};

coursesServices.uploadCoursesCSV = async (csvData) => {
    const { data } = await jwtAuthAxios.post(`/courses/upload-csv`, csvData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return data;
};
coursesServices.updateCourse = async (course) => {
    // console.log('Service Course');
    // console.log(course);
    const { data } = await jwtAuthAxios.patch(`/courses/${course.id}`, course);
    return data;
};

coursesServices.deleteCourse = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/courses/${id}`, {
        id: id
    });
    return data;
};

coursesServices.deleteMultipleCourses = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/courses`, {
        data: ids
    });
    return data;
};

export { coursesServices };
