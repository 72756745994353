import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const accomodationapplicationsServices = {};

accomodationapplicationsServices.getAllAccomodationapplications = async (
    signal
) => {
    const { data } = await jwtAuthAxios.get(
        '/accomodation-applications',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

accomodationapplicationsServices.getAccomodationapplications = async ({
    queryKey
}) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/accomodation-applications', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

accomodationapplicationsServices.getAccomodationapplicationDetails = async (
    id
) => {
    const { data } = await jwtAuthAxios.get(
        `/accomodation-applications/accomodation-application/${id}`,
        {
            id: id
        }
    );

    return data;
};

accomodationapplicationsServices.addAccomodationapplication = async (
    accomodationapplications
) => {
    const { data } = await jwtAuthAxios.post(
        `/accomodation-applications`,
        accomodationapplications
    );
    return data;
};

accomodationapplicationsServices.updateAccomodationapplication = async (
    accomodationapplications
) => {
    // console.log('Service Accomodationapplication');
    // console.log(accomodationapplications);
    const { data } = await jwtAuthAxios.patch(
        `/accomodation-applications/${accomodationapplications.id}`,
        accomodationapplications
    );
    return data;
};

accomodationapplicationsServices.deleteAccomodationapplication = async (id) => {
    const { data } = await jwtAuthAxios.delete(
        `/accomodation-applications/${id}`,
        {
            id: id
        }
    );
    return data;
};

accomodationapplicationsServices.deleteMultipleAccomodationapplications =
    async (ids) => {
        const { data } = await jwtAuthAxios.delete(
            `/accomodation-applications`,
            {
                data: ids
            }
        );
        return data;
    };

export { accomodationapplicationsServices };
