import React from 'react';
import ExpensesAppContext from './ExpensesAppContext';
import { CONTACT_APP_ACTIONS } from './utils/constants';

const init = (appState) => appState;

const expensesAppReducer = (state, action) => {
    switch (action.type) {
        case CONTACT_APP_ACTIONS.SET_SELECTED_ITEMS:
            return {
                ...state,
                selectedExpenses: action?.payload
            };
        case CONTACT_APP_ACTIONS.SET_CONTACTS_LIST_REFRESH:
            return {
                ...state,
                refreshExpensesList: action.payload
            };
        case CONTACT_APP_ACTIONS.SET_LABELS_LIST_REFRESH:
            return {
                ...state,
                refreshLabelsList: action.payload
            };
        default:
            return state;
    }
};

const ExpensesAppProvider = ({ children }) => {
    const [expensesApp, setExpensesApp] = React.useReducer(
        expensesAppReducer,
        {
            selectedExpenses: [],
            refreshExpensesList: false,
            refreshLabelsList: false
        },
        init
    );

    const setSelectedExpenses = React.useCallback(
        (expenses) => {
            setExpensesApp({
                type: CONTACT_APP_ACTIONS.SET_SELECTED_ITEMS,
                payload: expenses
            });
        },
        [setExpensesApp]
    );

    const setExpensesListRefresh = React.useCallback(
        (refresh) => {
            setExpensesApp({
                type: CONTACT_APP_ACTIONS.SET_CONTACTS_LIST_REFRESH,
                payload: refresh
            });
        },
        [setExpensesApp]
    );

    const setLabelsListRefresh = React.useCallback(
        (refresh) => {
            setExpensesApp({
                type: CONTACT_APP_ACTIONS.SET_LABELS_LIST_REFRESH,
                payload: refresh
            });
        },
        [setExpensesApp]
    );

    const contextValue = React.useMemo(
        () => ({
            ...expensesApp,
            setSelectedExpenses: setSelectedExpenses,
            setExpensesListRefresh: setExpensesListRefresh,
            setLabelsListRefresh: setLabelsListRefresh
        }),
        [expensesApp]
    );
    return (
        <ExpensesAppContext.Provider value={contextValue}>
            {children}
        </ExpensesAppContext.Provider>
    );
};

export default ExpensesAppProvider;
