import React, { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { Grid } from '@mui/material';
import Chip from '@mui/material/Chip';
import Div from '@jumbo/shared/Div';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import JumboRadioGroup from '@jumbo/components/JumboFormik/JumboRadioGroup';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useMutation } from 'react-query';
import { requestsServices } from 'app/services/requests-services';
import { expensesServices } from 'app/services/expenses-services';
import { expensetypesServices } from 'app/services/expensetypes-services';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import axios from 'axios';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Alert from '@mui/material/Alert';
import ExpenseStatus from 'app/pages/statuses/ExpenseStatus';
import _ from 'lodash';
import Span from '@jumbo/shared/Span';
import ExpenseDocumentsUpload from './ExpenseDocumentsUpload/ExpenseDocumentsUpload';
import { formatNumber } from 'app/utils/appHelpers';

const validationSchema = yup.object({
    expenseId: yup
        .string('Select expense type')
        .required('Expense type is required')
});

const ExpenseReportForm = ({ onSave, hideDialog, userDetails, authUser }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [expenseStatus, setExpenseStatus] = React.useState([
        {
            id: 0,
            name: 'Inactive',
            slug: 'inactive',
            color: '#ca404f'
        }
    ]);

    const [documentCount, setDocumentCount] = useState(1);
    const [documentIds, setDocumentIds] = useState([]);
    const [documentsInputList, setDocumentsInputList] = useState([]);

    const [expenses, setExpenses] = React.useState(false);
    const [expensetypes, setExpensetypes] = React.useState(false);
    const [selectedExpense, setSelectedExpense] = React.useState(false);
    const [feeCurrency, setFeeCurrency] = React.useState(
        authUser?.primaryCurrency?.currency || 'KES'
    );
    const [feeCurrencyName, setFeeCurrencyName] = React.useState(
        authUser?.primaryCurrency?.currency_name || 'Kenyan Shillings'
    );
    const [totalExpenseAmount, setTotalExpenseAmount] = React.useState(0);
    const [requiredDate, setRequiredDate] = React.useState(dayjs(new Date()));

    const { mutate: submitRequestMutation, isLoading } = useMutation(
        requestsServices.addRequest,
        {
            onSuccess: () => {
                enqueueSnackbar('Request has been submitted successfully.', {
                    variant: 'success'
                });
                onSave();
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (request) => {
        try {
            submitRequestMutation(request);
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };
        const fetchExpenses = async () => {
            try {
                const res = await expensesServices.getAllExpenses(
                    { approved: 'APPROVED' },
                    cToken
                );

                const finalOptions = _.map(
                    _.filter(res.expenses, (expense) =>
                        [1, 1.4, 1.5, 1.6, 1.7, 3].includes(expense.status)
                    ),
                    (expense) => {
                        const {
                            id,
                            expense_id,
                            expense_name,
                            total_amount,
                            status
                        } = expense;

                        return {
                            id,
                            expense_id,
                            expense_name,
                            total_amount,
                            status
                        };
                    }
                );

                setExpenses(finalOptions);
            } catch (error) {
                console.log('compon', error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchExpenses();

        return () => {
            cancelToken.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };
        const fetchExpensetypes = async () => {
            try {
                const res = await expensetypesServices.getAllExpensetypes(
                    cToken
                );

                setExpensetypes(res.expensetypes);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchExpensetypes();

        return () => {
            cancelToken.cancel();
        };
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {!expenses && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {expenses && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        expenseId: '',
                        expenseName: '',
                        currency: feeCurrency,
                        currency_name: feeCurrencyName,
                        total_amount: 0,
                        purpose: 'Expense Report Submission',
                        required_date: requiredDate
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        let docError = false;
                        let docNameError = '';
                        let docFileError = '';
                        _.forEach(documentsInputList, (doc) => {
                            const docNameFieldName = `document_name_${doc.id}`;
                            const amountField = `amount_${doc.id}`;
                            const docFileFieldName = `document_file_${doc.id}`;

                            console.log(doc.document_name);
                            console.log(doc.document_file);

                            if (
                                doc.document_name === '' &&
                                (doc.amount !== '' || doc.document_file !== '')
                            ) {
                                docNameError = 'Expense Type(s)';
                                docError = true;
                            }

                            if (
                                (doc.document_file !== '' ||
                                    doc.document_name !== '') &&
                                doc.amount === ''
                            ) {
                                docFileError = 'Amount(s)';
                                docError = true;
                            }

                            if (
                                (doc.amount !== '' ||
                                    doc.document_name !== '') &&
                                doc.document_file === ''
                            ) {
                                docFileError = 'Valid file(s)';
                                docError = true;
                            }

                            if (
                                (doc.document_name !== '' ||
                                    doc.amount !== '') &&
                                !/(pdf|jpg|jpeg|png)$/i.test(
                                    doc.document_file_link
                                )
                            ) {
                                docFileError = 'Valid file(s)';
                                docError = true;
                            }
                        });

                        if (docError) {
                            enqueueSnackbar(
                                `Document names, amounts & files in PDF/JPG/JPEG/PNG format are required.`,
                                {
                                    variant: 'warning'
                                }
                            );

                            return;
                        }

                        let postData;

                        if (
                            !documentsInputList[0].document_name ||
                            !documentsInputList[0].amount ||
                            !documentsInputList[0].document_file
                        ) {
                            enqueueSnackbar(
                                `You must provide atleast 1 expense type/receipt with amount for the expense.`,
                                {
                                    variant: 'warning'
                                }
                            );

                            return;
                        } else {
                            const formData = new FormData();

                            formData.append('module', 'report');

                            Object.keys(values).forEach((key) => {
                                if (!key.startsWith('document_file_')) {
                                    formData.append(key, values[key]);
                                }
                            });

                            if (documentsInputList.length) {
                                _.forEach(documentsInputList, (doc) => {
                                    if (doc.document_name) {
                                        formData.append(
                                            'expense_documents',
                                            doc.document_file
                                        );
                                    }
                                });

                                formData.append(
                                    'doc_names',
                                    JSON.stringify(
                                        _.map(
                                            documentsInputList,
                                            (doc) => doc.document_name
                                        )
                                    )
                                );

                                formData.append(
                                    'doc_amounts',
                                    JSON.stringify(
                                        _.map(
                                            documentsInputList,
                                            (doc) => doc.amount
                                        )
                                    )
                                );
                            }

                            postData = {
                                formikData: formData,
                                files: true
                            };
                        }

                        await onCreate(postData);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        setFieldValue,
                        setFieldError,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={1.5}>
                                    <Grid item xs={12} md={12}>
                                        <Field name="expenseId">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    sx={{}}
                                                    size="small"
                                                    options={expenses}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.expense_name
                                                            ? option.expense_name
                                                            : ''
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => {
                                                        const selStatus =
                                                            _.find(
                                                                ExpenseStatus,
                                                                (status) =>
                                                                    status.id ===
                                                                    option?.status
                                                            );

                                                        console.log(selStatus);
                                                        return (
                                                            <Box
                                                                component="li"
                                                                {...props}
                                                                sx={{
                                                                    width: '100%',
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                    justifyContent:
                                                                        'space-between',
                                                                    '& > img': {
                                                                        mr: 2,
                                                                        flexShrink: 0
                                                                    }
                                                                }}
                                                            >
                                                                ID #{' '}
                                                                <Span
                                                                    sx={{
                                                                        color: 'primary.main'
                                                                    }}
                                                                >
                                                                    {
                                                                        option?.expense_id
                                                                    }
                                                                </Span>
                                                                {' - '}
                                                                {
                                                                    option.expense_name
                                                                }{' '}
                                                                <Chip
                                                                    color={
                                                                        selStatus?.type
                                                                    }
                                                                    size="small"
                                                                    label={
                                                                        selStatus?.status
                                                                    }
                                                                />
                                                            </Box>
                                                        );
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Related Expense"
                                                            placeholder="Choose expense"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.expenseId &&
                                                                !!errors.expenseId
                                                            }
                                                            helperText={
                                                                touched?.expenseId &&
                                                                errors.expenseId
                                                            }
                                                        />
                                                    )}
                                                    onChange={(
                                                        event,
                                                        newValue
                                                    ) => {
                                                        values.expenseId =
                                                            newValue?.id;
                                                        values.expenseName =
                                                            newValue?.expense_name;
                                                        setTotalExpenseAmount(
                                                            newValue?.total_amount
                                                        );
                                                        setSelectedExpense(
                                                            newValue
                                                        );
                                                        setFeeCurrency(
                                                            newValue?.currency
                                                        );
                                                        setFeeCurrencyName(
                                                            newValue?.currency_name
                                                        );
                                                        values.currency =
                                                            newValue?.currency;
                                                        values.currency_name =
                                                            newValue?.currency_name;
                                                    }}
                                                    value={
                                                        expenses.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.expenseId
                                                        ) || null
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    {!selectedExpense && (
                                        <Grid item xs={12} md={12}>
                                            <Alert severity="info">
                                                No Expense Selected
                                            </Alert>
                                        </Grid>
                                    )}
                                    {selectedExpense && (
                                        <>
                                            <Grid
                                                item
                                                xs={3}
                                                md={3}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row'
                                                }}
                                            >
                                                <Typography
                                                    variant={''}
                                                    sx={{
                                                        textAlign: 'left',
                                                        fontWeight: 600
                                                    }}
                                                >
                                                    Expense Amount
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                md={3}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row'
                                                }}
                                            >
                                                <Typography
                                                    variant={''}
                                                    sx={{
                                                        textAlign: 'left',
                                                        fontWeight: 600
                                                    }}
                                                >
                                                    {formatNumber(
                                                        selectedExpense?.total_amount
                                                    )}
                                                </Typography>
                                            </Grid>
                                            {selectedExpense?.advanceId && (
                                                <>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        md={3}
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row'
                                                        }}
                                                    >
                                                        <Typography
                                                            variant={''}
                                                            sx={{
                                                                textAlign:
                                                                    'left',
                                                                fontWeight: 600
                                                            }}
                                                        >
                                                            Advance:
                                                        </Typography>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={3}
                                                        md={3}
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row'
                                                        }}
                                                    >
                                                        <Typography
                                                            variant={''}
                                                            sx={{
                                                                textAlign:
                                                                    'left',
                                                                fontWeight: 400
                                                            }}
                                                        >
                                                            {formatNumber(
                                                                selectedExpense
                                                                    ?.advanceId
                                                                    ?.total_amount
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    )}
                                    {selectedExpense && (
                                        <Grid item xs={12} md={12}>
                                            <ExpenseDocumentsUpload
                                                item={selectedExpense}
                                                newItem={false}
                                                options={expensetypes}
                                                field_name={'expense_type_name'}
                                                documentCount={documentCount}
                                                feeCurrency={feeCurrency}
                                                setDocumentCount={
                                                    setDocumentCount
                                                }
                                                documentIds={documentIds}
                                                setDocumentIds={setDocumentIds}
                                                documentsInputList={
                                                    documentsInputList
                                                }
                                                setDocumentsInputList={
                                                    setDocumentsInputList
                                                }
                                                loggedInUser={userDetails}
                                            />
                                        </Grid>
                                    )}
                                    <Grid
                                        sx={{
                                            margin: '30px auto'
                                        }}
                                        container
                                        alignItems="right"
                                        justify="flex-end"
                                        justifyContent="right"
                                    >
                                        <Button
                                            size="large"
                                            sx={{ mb: 3 }}
                                            onClick={() => hideDialog()}
                                        >
                                            Cancel
                                        </Button>
                                        <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{ mb: 3 }}
                                            loading={isLoading}
                                        >
                                            Submit Request
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo officeItem, onSave prop define */
export default ExpenseReportForm;
