import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const depositsServices = {};

depositsServices.getAllDepositsSummary = async (conditions, signal) => {
    const { data } = await jwtAuthAxios.get(
        '/deposits/summary',
        {
            params: {
                page: false,
                limit: false,
                searchText: '',
                approved: conditions?.approved || ''
            }
        },
        { ...signal }
    );

    return data;
};

depositsServices.getAllDeposits = async (conditions, signal) => {
    const { data } = await jwtAuthAxios.get(
        '/deposits',
        {
            params: {
                page: false,
                limit: false,
                searchText: '',
                approved: conditions?.approved || ''
            }
        },
        { ...signal }
    );

    return data;
};

depositsServices.getDeposits = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/deposits', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });

    return data;
};

depositsServices.getDepositDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/deposits/${id}`, {
        id: id
    });

    return data;
};

depositsServices.addDeposit = async (deposit) => {
    const { data } = await jwtAuthAxios.post(`/deposits`, deposit);
    return data;
};

depositsServices.approveDeposit = async (deposit) => {
    const { data } = await jwtAuthAxios.patch(
        `/deposits/approve/${deposit.depositId}`,
        deposit
    );
    return data;
};

depositsServices.updateDeposit = async (deposit) => {
    const { data } = await jwtAuthAxios.patch(
        `/deposits/${deposit.depositId}`,
        deposit
    );
    return data;
};

depositsServices.cancelDeposit = async (deposit) => {
    const { data } = await jwtAuthAxios.patch(
        `/deposits/cancel/${deposit.depositId}`,
        deposit
    );
    return data;
};

depositsServices.deleteDeposit = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/deposits/${id}`, {
        id: id
    });
    return data;
};

depositsServices.deleteMultipleDeposits = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/deposits`, {
        data: ids
    });
    return data;
};

depositsServices.getLabels = async () => {
    const { data } = await jwtAuthAxios.get('/deposits/labels');
    return data;
};

depositsServices.add = async (contact) => {
    const { data } = await jwtAuthAxios.post('/deposits', contact);
    return data;
};

depositsServices.update = async (contact) => {
    const { data } = await jwtAuthAxios.patch(
        `/deposits/${contact.id}`,
        contact
    );
    return data;
};

depositsServices.addLabel = async (label) => {
    const { data } = await jwtAuthAxios.post('/deposits/add-label', label);
    return data;
};

depositsServices.updateLabel = async (label) => {
    const { data } = await jwtAuthAxios.put('/deposits/update-label', label);
    return data;
};

depositsServices.deleteLabel = async (label) => {
    const { data } = await jwtAuthAxios.delete('/deposits/delete-label', {
        params: { id: label.id }
    });
    return data;
};

depositsServices.assignLabel = async (params) => {
    const { data } = await jwtAuthAxios.put('/deposits/assign-label', {
        contactIDs: params?.contactIDs ?? [],
        labelIDs: params?.labelIDs ?? []
    });
    return data;
};

export { depositsServices };
