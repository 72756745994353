import Page from '@jumbo/shared/Page';
import LeadsList from 'app/pages/home/Leads/LeadsList';
import StudentsList from 'app/pages/home/Students/StudentsList';
import StudentProfile from 'app/pages/home/Students/StudentProfile';
import InstitutionsList from 'app/pages/settings/Institutions/InstitutionsList';
import CoursesList from 'app/pages/settings/Courses/CoursesList';
import UniapplicationsList from 'app/pages/home/Uniapplications/UniapplicationsList';
import AccomodationapplicationsList from 'app/pages/home/Accomodationapplications/AccomodationapplicationsList';
import VisaapplicationsList from 'app/pages/home/Visaapplications/VisaapplicationsList';
import EventsCalendar from 'app/pages/home/Events/EventsCalendar';
import authRoles from 'app/config/authRoles';

const homeRoutes = {
    settings: {
        layout: {}
    },

    auth: authRoles?.consultant,
    routes: [
        {
            action: 'read',
            subject: 'Students',
            path: '/home/students/:id?',
            element: <Page component={StudentsList} />
        },
        {
            action: 'read',
            subject: 'Leads',
            path: '/home/leads/:id?',
            element: <Page component={LeadsList} />
        },
        {
            action: 'read',
            subject: 'Lead',
            path: '/home/students/student/:id',
            element: <Page component={StudentProfile} />
        },
        {
            action: 'read',
            subject: 'Student',
            path: '/home/students/student/:id',
            element: <Page component={StudentProfile} />
        },
        {
            action: 'read',
            subject: 'Uniapplications',
            path: '/home/uni-applications/:id?',
            element: <Page component={UniapplicationsList} />
        },
        {
            action: 'read',
            subject: 'Accomodationapplications',
            path: '/home/accomodation-applications/:id?',
            element: <Page component={AccomodationapplicationsList} />
        },
        {
            action: 'read',
            subject: 'Visaapplications',
            path: '/home/visa-applications/:id?',
            element: <Page component={VisaapplicationsList} />
        },
        {
            action: 'read',
            subject: 'Courses',
            path: '/home/courses/:id?',
            element: <Page component={CoursesList} />
        },
        {
            action: 'read',
            subject: 'Institutions',
            path: '/home/institutions/:id?',
            element: <Page component={InstitutionsList} />
        },
        {
            action: 'read',
            subject: 'Eventscalendar',
            path: '/home/events-calender',
            element: <Page component={EventsCalendar} />
        },
        {
            action: 'read',
            subject: 'Eventslist',
            path: '/home/events-list',
            element: <Page component={EventsCalendar} />
        }
    ]
};
export default homeRoutes;
