import React from 'react';
import Alert from '@mui/material/Alert';
import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import ApplicationStatus from 'app/pages/statuses/application-status/ApplicationStatus';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { useFormikContext, useFormik, Formik, Form, Field } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import {
    capitalizeAllWords,
    getCurrentDate,
    getNextYearDate
} from 'app/utils/appHelpers';
import _ from 'lodash';

const validationSchema = yup.object({
    status: yup.string('Select status').required('Status is required'),
    applicationId: yup
        .string('Enter application ID/Code')
        .required('Application ID/Code name is required'),
    tuition_deposit_amount: yup
        .string('Enter amount deposited')
        .required('Deposit amount is required'),
    deposit_payment_date: yup
        .string('Enter date of deposit')
        .required('Deposit date is required')
    // comment: yup.string('Enter Comment').required('Comment is required')
});

const DepositPayment = ({
    listItem,
    userDetails,
    uniApplications,
    setActiveSchema,
    setInitialFormValues
}) => {
    // const { enqueueSnackbar } = useSnackbar();

    const { values, setValues, touched, errors, setErrors, submitForm } =
        useFormikContext();
    const [applicationId, setApplicationId] = React.useState('');
    const [applications, setApplications] = React.useState(uniApplications);
    const [fieldDate, setFieldDate] = React.useState(
        dayjs(getCurrentDate(false))
    );

    React.useEffect(() => {
        if (fieldDate) {
            setValues({
                status: 4.6,
                leadId: listItem.id,
                depositType: 'tuition',
                first_year_tuition_fee: listItem.firstYearTuitionFee,
                countryId: listItem?.selectedInstitutionCountryId?.id,
                country_name:
                    listItem?.selectedInstitutionCountryId?.institution_name,
                institutionId: listItem?.selectedInstitutionId?.id,
                institution_name:
                    listItem?.selectedInstitutionId?.institution_name,
                applicationId: listItem?.selectedUniApplicationId?.id,
                applicationCode:
                    listItem?.selectedUniApplicationId?.applicationCode,
                courseId: listItem?.selectedCourseId,
                course_name: listItem?.selectedCourseId?.course_name,
                deposit_payment_date: fieldDate,
                transactionId: values.transactionId,
                tuition_deposit_amount: values.tuition_deposit_amount,
                comment: values.comment
            });
        }
    }, [fieldDate]);

    React.useEffect(() => {
        setActiveSchema(validationSchema);

        setValues({
            status: 4.6,
            leadId: listItem.id,
            depositType: 'tuition',
            transactionId: '',
            first_year_tuition_fee: listItem.firstYearTuitionFee,
            countryId: listItem?.selectedInstitutionCountryId?.id,
            country_name: listItem?.selectedInstitutionCountryId?.country_name,
            institutionId: listItem?.selectedInstitutionId?.id,
            institution_name: listItem?.selectedInstitutionId?.institution_name,
            applicationId: listItem?.selectedUniApplicationId?.id,
            applicationCode:
                listItem?.selectedUniApplicationId?.applicationCode,
            courseId: listItem?.selectedCourseId,
            course_name: listItem?.selectedCourseName,
            tuition_deposit_amount: 0,
            deposit_payment_date: fieldDate,
            comment: ''
        });
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%'
            }}
        >
            {/* {(!listItem?.selectedUniApplicationId ||
                !listItem?.firstYearTuitionFee ||
                !listItem?.selectedCourseId) && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )} */}

            {(!listItem?.selectedUniApplicationId ||
                !listItem?.firstYearTuitionFee ||
                !listItem?.selectedCourseName) && (
                <Alert severity="error">
                    <Typography
                        variant={'h6'}
                        sx={{ fontWeight: '700', width: '100%' }}
                    >
                        The preferred Course/Institution has not been selected
                        or picked.
                    </Typography>
                    Please, First Select the previous step/status{' '}
                    <strong>
                        <em>Tuition Deposit Payment</em>
                    </strong>{' '}
                    Option and select the application/course the student prefers
                    and provide information on the tuition fee to be paid before
                    coming to this step
                </Alert>
            )}

            {listItem?.selectedUniApplicationId &&
                listItem?.firstYearTuitionFee &&
                listItem?.selectedCourseName && (
                    <Div sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <JumboTextField
                                    fullWidth
                                    size={'small'}
                                    id="applicationCode"
                                    name="applicationCode"
                                    label="Application ID"
                                    placeholder="ApplicationID"
                                    readOnly
                                    type={'text'}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <JumboTextField
                                    fullWidth
                                    size={'small'}
                                    id="institution_name"
                                    name="institution_name"
                                    label="Institution"
                                    placeholder="Institution Name"
                                    readOnly
                                    type={'text'}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <JumboTextField
                                    fullWidth
                                    size={'small'}
                                    id="course_name"
                                    name="course_name"
                                    label="Selected Course"
                                    placeholder="Selected Course"
                                    readOnly
                                    type={'text'}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <JumboTextField
                                    fullWidth
                                    size={'small'}
                                    id="first_year_tuition_fee"
                                    name="first_year_tuition_fee"
                                    label="First Year Tuition Fee"
                                    placeholder="Enter first year tuition fee"
                                    readOnly
                                    type={'number'}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <JumboTextField
                                    fullWidth
                                    size={'small'}
                                    id="transactionId"
                                    name="transactionId"
                                    label="Payment/Transaction ID"
                                    placeholder="Enter payment ID/Receipt Number"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <JumboTextField
                                    fullWidth
                                    size={'small'}
                                    id="tuition_deposit_amount"
                                    name="tuition_deposit_amount"
                                    label="Tuition Deposit Amount"
                                    placeholder="Enter the deposit amount here"
                                    readOnly
                                    type={'number'}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {''}
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <MobileDatePicker
                                        label="Payment Date"
                                        inputFormat="DD/MM/YYYY"
                                        disableFuture
                                        value={fieldDate}
                                        onChange={(newValue) => {
                                            setFieldDate(newValue);
                                            values.deposit_payment_date =
                                                newValue;
                                        }}
                                        renderInput={(params) => {
                                            return (
                                                <JumboTextField
                                                    fullWidth
                                                    size={'small'}
                                                    value={fieldDate}
                                                    name="deposit_payment_date"
                                                    {...params}
                                                />
                                            );
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Div>
                )}
        </Div>
    );
};
/* Todo uniapplicationItem, onSave prop define */
export default DepositPayment;
