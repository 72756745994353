import React from 'react';
import { notificationIcons } from './notificationIcons';
import Avatar from '@mui/material/Avatar';

import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import { getDateElements } from '@jumbo/utils';
import Span from '@jumbo/shared/Span';
import moment from 'moment';
import Div from '@jumbo/shared/Div';
import { Link, useNavigate } from 'react-router-dom';

const NotificationItem = ({ item }) => {
    const navigate = useNavigate();

    const userPic = item?.notificationobjectId?.actor?.profile_pic
        ? process.env.REACT_APP_BACKEND +
          '/' +
          item?.notificationobjectId?.actor?.profile_pic
        : null;

    let timeAgo = moment(item?.notificationobjectId?.date_created).fromNow();

    return (
        <ListItemButton
            component={'li'}
            alignItems={'flex-start'}
            onClick={() => navigate(item?.notificationobjectId?.link)}
        >
            <Div
                component={Link}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    p: (theme) => theme.spacing(0.5)
                }}
                target="_blank"
                rel="noopener noreferrer"
                to={item?.notificationobjectId?.link}
            >
                <ListItemAvatar>
                    <Avatar src={userPic} />
                </ListItemAvatar>
                <ListItemText>
                    {/* <Link underline={'none'} href="#/">
                    {item.user.name}
                </Link>{' '} */}
                    {item?.notificationobjectId?.message}
                    <Typography
                        component="span"
                        sx={{
                            display: 'flex',
                            fontSize: '90%',
                            mt: 0.5
                        }}
                    >
                        {/* {notificationIcons[item.type]} */}
                        <Span sx={{ color: 'text.secondary', ml: 1 }}>
                            {/* {
                            getDateElements(
                                item?.notificationobjectId?.date_created
                            ).time
                        } */}

                            {timeAgo}
                        </Span>
                    </Typography>
                </ListItemText>
            </Div>
        </ListItemButton>
    );
};

export default NotificationItem;
