import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const smtpsServices = {};

smtpsServices.getAllSmtps = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/smtps',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

smtpsServices.getSmtps = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/smtps', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};

smtpsServices.getSmtpDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/smtps/smtp/${id}`, {
        id: id
    });

    return data;
};

smtpsServices.addSmtp = async (smtps) => {
    const { data } = await jwtAuthAxios.post(`/smtps`, smtps);
    return data;
};

smtpsServices.updateSmtp = async (smtps) => {
    // console.log('Service Smtp');
    // console.log(smtps);
    const { data } = await jwtAuthAxios.patch(`/smtps/${smtps.id}`, smtps);
    return data;
};

smtpsServices.deleteSmtp = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/smtps/${id}`, {
        id: id
    });
    return data;
};

smtpsServices.deleteMultipleSmtps = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/smtps`, {
        data: ids
    });
    return data;
};

export { smtpsServices };
