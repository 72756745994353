import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const employeeServices = {};

employeeServices.getAllEmployees = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/employees',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

employeeServices.getEmployees = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/employees', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });

    console.log('data.length');
    console.log(data);
    console.log(data.employeesList.length);
    return data;
};

employeeServices.getEmployeeDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/employees/employee/${id}`, {
        id: id
    });
    // console.log('service');
    // console.log(data);
    // console.log(data.employee.first_name);
    // console.log(JSON.parse(data.employee));
    // console.log(data.employee.first_name);

    return data;
};

employeeServices.addEmployee = async (employee) => {
    const { data } = await jwtAuthAxios.post(`/employees/signup`, employee);
    return data;
};

employeeServices.updateEmployee = async (employee) => {
    const { data } = await jwtAuthAxios.patch(
        `/employees/${employee.id}`,
        employee
    );
    return data;
};

employeeServices.deleteEmployee = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/employees/${id}`, {
        id: id
    });
    return data;
};

employeeServices.deleteMultipleEmployees = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/employees`, {
        data: ids
    });
    return data;
};

export { employeeServices };
