import React from 'react';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { surveyService } from '../../../../../services/survey-services';
import { useSnackbar } from 'notistack';
// import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Form, Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboAvatarField from '@jumbo/components/JumboFormik/JumboAvatarField';
import Div from '@jumbo/shared/Div';
import { MuiTelInput } from 'mui-tel-input';

const validationSchema = yup.object({
    first_name: yup
        .string('Enter your first name')
        .required('First name is required'),
    last_name: yup
        .string('Enter your last name')
        .required('Last name is required'),
    email_address: yup
        .string('Enter your email address')
        .email('Enter a valid email address')
        .required('Email is required'),
    phone_number: yup
        .string('Enter phone number')
        // .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone number is required')
});
const initialValues = {
    first_name: '',
    last_name: '',
    phone_number: '',
    email_address: '',
    officeId: '',
    email_address: '',
    institutionId: '',
    profile_pic: ''
};

const SurveyForm = ({ survey, onSave }) => {
    // const Swal = useSwalWrapper();
    const { enqueueSnackbar } = useSnackbar();

    const [phone, setPhone] = React.useState(survey?.phone_number || '+254');

    const addSurveyMutation = useMutation(surveyService.add, {
        onSuccess: () => {
            onSave();
            // Swal.fire({
            //     position: 'top-end',
            //     icon: 'success',
            //     title: 'Survey has been added successfully.',
            //     showConfirmButton: false,
            //     timer: 1500
            // });
            enqueueSnackbar('Survey has been added successfully.', {
                variant: 'success'
            });
        },
        onError: (error) => {
            console.log(error);
            enqueueSnackbar(error.message, {
                variant: 'error'
            });
        }
    });

    const updateSurveyMutation = useMutation(surveyService.update, {
        onSuccess: () => {
            onSave();
            enqueueSnackbar('Survey has been updated successfully.', {
                variant: 'success'
            });
        },
        onError: (error) => {
            enqueueSnackbar(error, {
                variant: 'error'
            });
        }
    });

    const saveMutation = React.useMemo(() => {
        return survey?.id ? updateSurveyMutation : addSurveyMutation;
    }, [survey, updateSurveyMutation, addSurveyMutation]);

    const onSurveySave = (data, { setSubmitting }) => {
        setSubmitting(true);
        saveMutation.mutate(data);
        setSubmitting(false);
    };
    return (
        <Formik
            validateOnChange={true}
            initialValues={survey?.id ? survey : initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onSurveySave}
        >
            {({ isSubmitting, setFieldValue, values }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            '& .MuiTextField-root': {
                                mb: 3
                            }
                        }}
                    >
                        <JumboAvatarField
                            name={'profile_pic'}
                            alt={'user profile pic'}
                            onFileSelection={(file) =>
                                setFieldValue('profile_pic', file)
                            }
                            sx={{
                                width: 60,
                                height: 60,
                                margin: '0 auto 24px'
                            }}
                        />
                        <JumboTextField
                            fullWidth
                            size={'small'}
                            id="first_name"
                            name="first_name"
                            label="First Name"
                            placeholder="Enter first name"
                        />
                        <JumboTextField
                            fullWidth
                            size={'small'}
                            id="last_name"
                            name="last_name"
                            label="Last Name"
                            placeholder="Enter last name"
                        />
                        <JumboTextField
                            fullWidth
                            size={'small'}
                            id="email_address"
                            name="email_address"
                            label="Email Address"
                            placeholder="Enter email address"
                        />

                        <MuiTelInput
                            fullWidth
                            size={'small'}
                            id="phone_number"
                            name="phone_number"
                            label="Phone Number"
                            value={phone}
                            placeholder="Enter phone number"
                            preferredCountries={['KE', 'ZM']}
                            defaultCountry="KE"
                            onChange={(newValue) => {
                                setPhone(newValue);
                                values.phone_number = newValue;
                            }}
                        />
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                            loading={isSubmitting || saveMutation.isLoading}
                        >
                            Save
                        </LoadingButton>
                    </Div>
                </Form>
            )}
        </Formik>
    );
};
SurveyForm.defaultProps = {
    onSave: () => {}
};
export default SurveyForm;
