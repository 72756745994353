import React from 'react';
import ContactsApp from '../pages/apps/contacts/ContactsApp';
import ExpensesApp from '../pages/apps/expenses/ExpensesApp';
import SurveysApp from '../pages/apps/surveys/SurveysApp';
import Page from '@jumbo/shared/Page';
import authRoles from 'app/config/authRoles';

const appsRoutes = {
    settings: {
        layout: {}
    },
    auth: authRoles?.consultant,
    routes: [
        {
            action: 'read',
            subject: 'Expenses',
            path: '/app/expenses/:category',
            element: <Page component={ExpensesApp} />
        },
        {
            action: 'read',
            subject: 'Expenses',
            path: '/app/expenses/:category/:id',
            element: <Page component={ExpensesApp} />
        },
        {
            action: 'read',
            subject: 'Contacts',
            path: '/app/contacts/:category',
            element: <Page component={ContactsApp} />
        },
        {
            action: 'read',
            subject: 'Contacts',
            path: '/app/contacts/:category/:id',
            element: <Page component={ContactsApp} />
        },
        {
            action: 'read',
            subject: 'Surveys',
            path: '/app/surveys/:category',
            element: <Page component={SurveysApp} />
        },
        {
            action: 'read',
            subject: 'Surveys',
            path: '/app/surveys/:category/:id',
            element: <Page component={SurveysApp} />
        }
    ]
};

export default appsRoutes;
