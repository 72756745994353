import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const expensesServices = {};

expensesServices.getAllExpensesSummary = async (conditions, signal) => {
    const { data } = await jwtAuthAxios.get(
        '/expenses/summary',
        {
            params: {
                page: false,
                limit: false,
                searchText: '',
                approved: conditions?.approved || ''
            }
        },
        { ...signal }
    );

    return data;
};

expensesServices.getAllExpenses = async (conditions, signal) => {
    const { data } = await jwtAuthAxios.get(
        '/expenses',
        {
            params: {
                page: false,
                limit: false,
                searchText: '',
                approved: conditions?.approved || ''
            }
        },
        { ...signal }
    );

    return data;
};

expensesServices.getExpenses = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];

    const { data } = await jwtAuthAxios.get('/expenses', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });

    console.log(data);

    return data;
};

expensesServices.getExpenseDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/expenses/${id}`, {
        id: id
    });
    // console.log('service');
    // console.log(data);
    // console.log(data.expense.first_name);
    // console.log(JSON.parse(data.expense));
    // console.log(data.expense.first_name);

    return data;
};

expensesServices.addExpense = async (expense) => {
    const { data } = await jwtAuthAxios.post(`/expenses`, expense);
    return data;
};

expensesServices.approveExpense = async (expense) => {
    const { data } = await jwtAuthAxios.patch(
        `/expenses/approve/${expense.expenseId}`,
        expense
    );
    return data;
};

expensesServices.updateExpense = async (expense) => {
    const { data } = await jwtAuthAxios.patch(
        `/expenses/${expense.expenseId}`,
        expense
    );
    return data;
};

expensesServices.cancelExpense = async (expense) => {
    const { data } = await jwtAuthAxios.patch(
        `/expenses/cancel/${expense.expenseId}`,
        expense
    );
    return data;
};

expensesServices.deleteExpense = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/expenses/${id}`, {
        id: id
    });
    return data;
};

expensesServices.deleteMultipleExpenses = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/expenses`, {
        data: ids
    });
    return data;
};

expensesServices.getLabels = async () => {
    const { data } = await jwtAuthAxios.get('/expenses/labels');
    return data;
};

expensesServices.add = async (contact) => {
    const { data } = await jwtAuthAxios.post('/expenses', contact);
    return data;
};

expensesServices.update = async (contact) => {
    const { data } = await jwtAuthAxios.patch(
        `/expenses/${contact.id}`,
        contact
    );
    return data;
};

expensesServices.addLabel = async (label) => {
    const { data } = await jwtAuthAxios.post('/expenses/add-label', label);
    return data;
};

expensesServices.updateLabel = async (label) => {
    const { data } = await jwtAuthAxios.put('/expenses/update-label', label);
    return data;
};

expensesServices.deleteLabel = async (label) => {
    const { data } = await jwtAuthAxios.delete('/expenses/delete-label', {
        params: { id: label.id }
    });
    return data;
};

expensesServices.assignLabel = async (params) => {
    const { data } = await jwtAuthAxios.put('/expenses/assign-label', {
        contactIDs: params?.contactIDs ?? [],
        labelIDs: params?.labelIDs ?? []
    });
    return data;
};

export { expensesServices };
