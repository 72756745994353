import jwtAuthAxios from './auth/jwtAuth';
// import { config } from '../config/main';

const approvalsServices = {};

approvalsServices.getAllApprovals = async (signal) => {
    const { data } = await jwtAuthAxios.get(
        '/approvals',
        {
            params: {
                page: false,
                limit: false,
                searchText: ''
            }
        },
        { ...signal }
    );

    return data;
};

approvalsServices.getApprovals = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];
    console.log('requesting approvals');
    const { data } = await jwtAuthAxios.get('/approvals', {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });

    console.log(data);

    return data;
};

approvalsServices.getApprovalDetails = async (id) => {
    const { data } = await jwtAuthAxios.get(`/approvals/approval/${id}`, {
        id: id
    });

    return data;
};

approvalsServices.addApproval = async (approval) => {
    const { data } = await jwtAuthAxios.post(`/approvals`, approval);
    return data;
};

approvalsServices.updateApproval = async (approval) => {
    // console.log('Service Approval');
    // console.log(approval);
    const { data } = await jwtAuthAxios.patch(
        `/approvals/${approval.id}`,
        approval
    );
    return data;
};

approvalsServices.deleteApproval = async (id) => {
    const { data } = await jwtAuthAxios.delete(`/approvals/${id}`, {
        id: id
    });
    return data;
};

approvalsServices.deleteMultipleApprovals = async (ids) => {
    const { data } = await jwtAuthAxios.delete(`/approvals`, {
        data: ids
    });
    return data;
};

export { approvalsServices };
