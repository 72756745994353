import React from 'react';
// import { agents } from './data';
import AgentDetail from './AgentDetail';
import { Alert, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

const PopularAgents = ({ popularAgents: agents }) => {
    const { t } = useTranslation();

    const agentsCount = agents.length;
    const gridCols = agentsCount < 4 ? 12 / agentsCount : 3;

    return (
        <React.Fragment>
            <Typography variant={'h4'} sx={{ mb: 2 }}>
                {t('widgets.title.popularAgents')}
            </Typography>
            {(!agents || agents.length === 0) && (
                <Alert severity="info">No agent info available</Alert>
            )}
            {agents.length > 0 && (
                <Grid container spacing={3.75}>
                    {agents.map((agent, index) => (
                        <Grid item xs={12} sm={6} lg={gridCols} key={index}>
                            <AgentDetail agentDetail={agent} />
                        </Grid>
                    ))}
                </Grid>
            )}
        </React.Fragment>
    );
};
export default PopularAgents;
