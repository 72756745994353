import React from 'react';
import Div from '@jumbo/shared/Div';
import { Button, Typography } from '@mui/material';
import { ASSET_IMAGES } from '../../utils/constants/paths';
import { useNavigate } from 'react-router-dom';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

const NotAllowed = ({ children }) => {
    const { setAuthToken } = useJumboAuth();
    const navigate = useNavigate();
    if (children) return children;

    const handleNavigation = () => {
        setAuthToken(null);
        navigate('/user/login');
    };

    return (
        <Div sx={{ textAlign: 'center', p: 3, m: 'auto' }}>
            <img
                alt={'Not Found'}
                src={`${ASSET_IMAGES}/pages/not_found.svg`}
                width="300"
                style={{ verticalAlign: 'middle' }}
            />
            <Typography variant={'h3'} color={'text.secondary'} mt={2}>
                Not Authorized
            </Typography>
            {/* <Button variant="outlined" onClick={handleNavigation}>
                Go To Sign In
            </Button> */}
        </Div>
    );
};

export default NotAllowed;
