import React from 'react';
import _ from 'lodash';
import { Typography } from '@mui/material';
import Span from '@jumbo/shared/Span';
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator
} from '@mui/lab';
import moment from 'moment';
import RequestStatus from 'app/pages/statuses/RequestStatus';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

const ActivitiesItem = ({ item, authUser }) => {
    // const { authUser } = useJumboAuth();
    const [requestStatus, setRequestStatus] = React.useState(
        _.find(RequestStatus, (status) => status.id === item?.approval_status)
    );

    // console.log('Approvers');
    // console.log(authUser);
    // console.log(item);

    const currentDate = moment();
    const inputDate = moment(item?.approval_date);
    const hoursDiff = currentDate.diff(inputDate, 'hours');
    const thresholdHours = 48;

    let displayText;

    if (hoursDiff < thresholdHours) {
        displayText = inputDate.fromNow();
    } else {
        displayText = inputDate.format('MMM DD, YYYY, h:mm A');
    }

    return (
        <TimelineItem
            sx={{
                '&::before': {
                    display: 'none'
                }
            }}
        >
            <TimelineSeparator>
                <TimelineDot
                    sx={{
                        p: '6px',
                        m: 0,
                        bgcolor: requestStatus.color,
                        color: 'white'
                    }}
                >
                    {requestStatus?.icon}
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
                sx={{
                    p: (theme) => theme.spacing(0.5, 0),
                    ml: 2.5
                }}
                component={'div'}
            >
                <Typography variant="h5" mb={0.5}>
                    <Span sx={{ color: 'primary.main' }}>
                        {item?.approverId?.id === authUser?.userId
                            ? 'You'
                            : item?.approver_name}
                    </Span>{' '}
                    {requestStatus?.status} the request
                </Typography>
                <Typography variant={'h6'} color={'text.secondary'}>
                    {displayText}
                </Typography>
                <Typography variant={'h6'} color={'text.primary'}>
                    {item?.comment}
                </Typography>
            </TimelineContent>
        </TimelineItem>
    );
};
/* Todo item prop define */

{
    /* <Typography component={"div"}> */
}
{
    /* <Typography color={"text.primary"} mb={2}></Typography> */
}
export default ActivitiesItem;
