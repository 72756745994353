import React from 'react';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EngineeringIcon from '@mui/icons-material/Engineering';

const MotherContacts = ({ studentDetails }) => {
    return (
        <JumboCardQuick title={`Mother's Contacts`} noWrapper sx={{ mb: 3.75 }}>
            {!studentDetails.mother_contacts?.first_name &&
                !studentDetails.mother_contacts?.last_name && (
                    <Alert severity="info">No Info Available</Alert>
                )}{' '}
            {(studentDetails.mother_contacts?.first_name ||
                studentDetails.mother_contacts?.last_name) && (
                <List disablePadding sx={{ mb: 2 }}>
                    <ListItem
                        alignItems="flex-start"
                        sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                    >
                        <ListItemIcon
                            sx={{ minWidth: 36, color: 'text.secondary' }}
                        >
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                >
                                    Name
                                </Typography>
                            }
                            secondary={
                                <Link variant="body1" href="#" underline="none">
                                    {studentDetails?.mother_contacts?.first_name
                                        ? studentDetails?.mother_contacts
                                              ?.first_name +
                                          ' ' +
                                          studentDetails.mother_contacts
                                              ?.last_name
                                        : ''}
                                </Link>
                            }
                        />
                    </ListItem>
                    <ListItem
                        alignItems="flex-start"
                        sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                    >
                        <ListItemIcon
                            sx={{ minWidth: 36, color: 'text.secondary' }}
                        >
                            <EmailOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                >
                                    Email
                                </Typography>
                            }
                            secondary={
                                <Link variant="body1" href="#" underline="none">
                                    {
                                        studentDetails?.mother_contacts
                                            ?.email_address
                                    }
                                </Link>
                            }
                        />
                    </ListItem>

                    <ListItem
                        alignItems="flex-start"
                        sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                    >
                        <ListItemIcon
                            sx={{ minWidth: 36, color: 'text.secondary' }}
                        >
                            <LocalPhoneOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                >
                                    Phone
                                </Typography>
                            }
                            secondary={
                                <Typography
                                    variant="body1"
                                    color="text.primary"
                                >
                                    {
                                        studentDetails?.mother_contacts
                                            ?.phone_number
                                    }
                                </Typography>
                            }
                        />
                    </ListItem>
                    <ListItem
                        alignItems="flex-start"
                        sx={{ p: (theme) => theme.spacing(0.5, 3) }}
                    >
                        <ListItemIcon
                            sx={{ minWidth: 36, color: 'text.secondary' }}
                        >
                            <EngineeringIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                >
                                    Profession
                                </Typography>
                            }
                            secondary={
                                <Typography
                                    variant="body1"
                                    color="text.primary"
                                >
                                    {
                                        studentDetails?.mother_contacts
                                            ?.profession
                                    }
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
            )}
        </JumboCardQuick>
    );
};

export default MotherContacts;
