import React from 'react';
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemAvatar,
    Card,
    CardHeader,
    CardContent,
    Checkbox,
    Divider,
    Tooltip,
    Typography,
    Avatar,
    Stack
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import IconButton from '@mui/material/IconButton';
import BusinessIcon from '@mui/icons-material/Business';
import PhoneIcon from '@mui/icons-material/Phone';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import styled from '@emotion/styled';
import Slide from '@mui/material/Slide';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import Div from '@jumbo/shared/Div';
import { surveyService } from '../../../../../services/survey-services';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import SurveyForm from '../SurveyForm';
import useSurveysApp from '../../hooks/useSurveysApp';
import { useMutation } from 'react-query';
import SurveyDetail from '../SurveyDetail';
import JumboGridItem from '@jumbo/components/JumboList/components/JumboGridItem';
import { useSnackbar } from 'notistack';
import { capitalizeFirstLetter } from 'app/utils/appHelpers';

const Item = styled(Span)(({ theme }) => ({
    minWidth: 0,
    flexGrow: 0,
    padding: theme.spacing(0, 1)
}));

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

const SurveyItem = ({ survey, view }) => {
    const { enqueueSnackbar } = useSnackbar();

    const profilePic = survey?.profile_pic
        ? process.env.REACT_APP_BACKEND + '/' + survey?.profile_pic
        : null;

    const { showDialog, hideDialog } = useJumboDialog();
    const { setSurveysListRefresh } = useSurveysApp();
    const [favorite, setFavorite] = React.useState(survey?.starred);
    const deleteSurveyMutation = useMutation(surveyService.delete, {
        onSuccess: () => {
            hideDialogAndRefreshSurveysList();
            enqueueSnackbar('Survey has been deleted successfully.', {
                variant: 'success'
            });
        },
        onError: (error) => {
            enqueueSnackbar(error, {
                variant: 'error'
            });
        }
    });

    const hideDialogAndRefreshSurveysList = React.useCallback(() => {
        hideDialog();
        setSurveysListRefresh(true);
    }, [hideDialog, setSurveysListRefresh]);

    const showSurveyDetail = React.useCallback(() => {
        showDialog({
            content: <SurveyDetail survey={survey} onClose={hideDialog} />,
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true
        });
    }, [showDialog, survey]);

    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case 'view':
                showSurveyDetail();
                break;
            case 'delete':
                showDialog({
                    variant: 'confirm',
                    title: 'Are you sure about deleting this survey?',
                    content: "You won't be able to recover this survey later",
                    onYes: () => deleteSurveyMutation.mutate(survey),
                    onNo: hideDialog
                });
        }
    };
    return (
        <React.Fragment>
            <JumboListItem
                componentElement={'div'}
                itemData={survey}
                secondaryAction={
                    <JumboDdMenu
                        icon={<MoreHorizIcon />}
                        menuItems={[
                            {
                                icon: <VisibilityIcon />,
                                title: 'View',
                                action: 'view'
                            }
                            // {
                            //     icon: <EditIcon />,
                            //     title: 'Edit',
                            //     action: 'edit'
                            // },
                            // {
                            //     icon: <DeleteIcon />,
                            //     title: 'Delete',
                            //     action: 'delete'
                            // }
                        ]}
                        onClickCallback={handleItemAction}
                    />
                }
                sx={{
                    cursor: 'pointer',
                    borderTop: 1,
                    borderColor: 'divider',
                    '&:hover': {
                        bgcolor: 'action.hover'
                    }
                }}
            >
                {/* <ListItemIcon sx={{ minWidth: 40 }}>
                    {favorite ? (
                        <Tooltip title={'Starred'}>
                            <StarIcon
                                fontSize={'small'}
                                sx={{ color: 'warning.main' }}
                                onClick={() => setFavorite(!favorite)}
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip title={'Not starred'}>
                            <StarBorderIcon
                                fontSize={'small'}
                                sx={{ color: 'text.secondary' }}
                                onClick={() => setFavorite(!favorite)}
                            />
                        </Tooltip>
                    )}
                </ListItemIcon> */}
                {/* <ListItemAvatar onClick={showSurveyDetail}>
                    <Avatar alt={survey?.first_name} src={profilePic} />
                </ListItemAvatar> */}
                <ListItemText
                    onClick={showSurveyDetail}
                    primary={
                        <Typography variant={'body1'} component={'div'}>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                sx={{ minWidth: 0 }}
                            >
                                <Item
                                    sx={{
                                        flexBasis: {
                                            xs: '100%',
                                            sm: '50%',
                                            md: '25%'
                                        }
                                    }}
                                >
                                    <Typography
                                        variant={'h5'}
                                        fontSize={14}
                                        lineHeight={1.25}
                                        mb={0}
                                        noWrap
                                    >
                                        {survey?.studentId?.given_name}{' '}
                                        {survey?.studentId?.surname}
                                    </Typography>
                                    <Typography
                                        variant={'body1'}
                                        noWrap
                                        color={'text.secondary'}
                                        sx={{
                                            display: { sm: 'none' }
                                        }}
                                    >
                                        {survey?.studentId?.email_address}
                                    </Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { sm: '12%', md: '12%' },
                                        display: { xs: 'none', sm: 'block' }
                                    }}
                                >
                                    <Stack
                                        spacing={2}
                                        direction={'row'}
                                        alignItems={'center'}
                                        sx={{ textAlign: 'center' }}
                                    >
                                        <Item>
                                            <Typography variant={'h6'} mb={0.5}>
                                                {capitalizeFirstLetter(
                                                    survey?.type
                                                )}
                                            </Typography>
                                            <Typography
                                                variant={'body1'}
                                                color="text.secondary"
                                            >
                                                Category
                                            </Typography>
                                        </Item>
                                    </Stack>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { sm: '50%', md: '18%' },
                                        display: { xs: 'block', sm: 'block' }
                                    }}
                                >
                                    <Stack
                                        spacing={2}
                                        direction={'row'}
                                        alignItems={'center'}
                                        sx={{ textAlign: 'center' }}
                                    >
                                        <Item>
                                            <Typography
                                                variant={'h6'}
                                                mb={0.5}
                                                color="text.primary"
                                            >
                                                {survey?.nps}
                                            </Typography>
                                            <Typography
                                                variant={'body1'}
                                                color="text.secondary"
                                            >
                                                NPS
                                            </Typography>
                                        </Item>
                                    </Stack>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { md: '45%' },
                                        display: { xs: 'none', md: 'block' }
                                    }}
                                >
                                    <Typography variant={'body1'}>
                                        {survey?.improvement_comment}
                                    </Typography>
                                </Item>
                                {/* <Item
                                    sx={{
                                        flexBasis: { md: '22%' },
                                        display: { xs: 'none', md: 'block' }
                                    }}
                                >
                                    <JumboChipsGroup
                                        chips={survey?.labels}
                                        mapKeys={{ label: 'name' }}
                                        spacing={1}
                                        size={'small'}
                                        max={1}
                                    />
                                </Item> */}
                            </Stack>
                        </Typography>
                    }
                />
            </JumboListItem>
        </React.Fragment>
    );
};
/* Todo survey, view prop define */
export default SurveyItem;
