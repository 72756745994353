import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import CollapsibleList from 'app/shared/Lists/CollapsibleList/CollapsibleList';

const FolderItem = ({
    icon,
    label,
    path,
    count,
    selected,
    children = false,
    childrenAction = false
}) => {
    return (
        <>
            {!children && (
                <MenuItem
                    disableRipple
                    sx={{ p: 0, mb: 2 }}
                    selected={selected}
                >
                    <Link underline={'none'} component={RouterLink} to={path}>
                        {icon && (
                            <ListItemIcon sx={{ color: 'inherit' }}>
                                {icon}
                            </ListItemIcon>
                        )}
                        <ListItemText>{label}</ListItemText>
                        {count > 0 && <Chip size={'small'} label={count} />}
                    </Link>
                </MenuItem>
            )}
            {children && (
                <CollapsibleList
                    item={{
                        icon,
                        label,
                        path,
                        count,
                        selected,
                        children,
                        childrenAction
                    }}
                />
            )}
        </>
    );
};
/* Todo prop define */
export default FolderItem;
