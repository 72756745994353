import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import RefreshIcon from '@mui/icons-material/Refresh';

const ReportStatus = [
    {
        id: 0,
        status: 'Cancelled',
        name: 'Cancelled',
        slug: 'Cancelled',
        color: '#ffd0cd',
        type: 'default',
        icon: <CancelIcon />
    },
    {
        id: 1,
        status: 'Reimbursement',
        name: 'Reimbursement',
        slug: 'Reimbursement',
        color: '#ffd0cd',
        type: 'warning',
        icon: <HourglassEmptyIcon />
    },
    {
        id: 2,
        status: 'Advance Reimbursement',
        name: 'Advance Reimbursement',
        slug: 'Advance Reimbursement',
        color: '#ffd0cd',
        type: 'warning',
        icon: <RefreshIcon />
    },
    {
        id: 3,
        status: 'Closed/Violations',
        name: 'Closed/Violations',
        slug: 'Closed/Violations',
        color: '#ffd0cd',
        type: 'warning',
        icon: <ClearIcon />
    },
    {
        id: 4,
        status: 'Closed',
        name: 'Closed',
        slug: 'Closed',
        color: '#cdffcf',
        type: 'success',
        icon: <CheckCircleIcon />
    }
];

export default ReportStatus;
