import React from 'react';

import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import { useMutation } from 'react-query';
import { expensetypesServices } from 'app/services/expensetypes-services';

import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { useSnackbar } from 'notistack';

const validationSchema = yup.object({
    expense_type_name: yup
        .string('Enter expense type name')
        .required('Expense type name is required')
});

const EditForm = ({ listItem, onUpdate, hideDialog }) => {
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: editExpensetypeMutation, isLoading } = useMutation(
        expensetypesServices.updateExpensetype,
        {
            onSuccess: () => {
                onUpdate();

                enqueueSnackbar('Expensetypes has been updated successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onEdit = async (expensetype) => {
        try {
            editExpensetypeMutation({
                id: listItem.id,
                ...expensetype
            });
        } catch (error) {}
    };

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            <Formik
                validateOnChange={true}
                initialValues={{
                    expense_type_name: listItem.expense_type_name
                }}
                validationSchema={validationSchema}
                onSubmit={async (data, { setSubmitting }) => {
                    await onEdit(data);
                }}
            >
                {({ isSubmitting, errors, touched, values, ...restProps }) => (
                    <Form
                        style={{ textAlign: 'left' }}
                        noValidate
                        autoComplete="off"
                    >
                        <Div>{isLoading}</Div>
                        <Div sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <JumboTextField
                                        fullWidth
                                        size={'small'}
                                        id="expense_type_name"
                                        name="expense_type_name"
                                        label="Expensetypes Name"
                                        placeholder="Enter expensetypes name"
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    margin: '30px auto'
                                }}
                                container
                                alignItems="right"
                                justify="flex-end"
                                justifyContent="right"
                            >
                                <Button
                                    size="large"
                                    sx={{ mb: 3 }}
                                    onClick={() => hideDialog()}
                                >
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isLoading}
                                >
                                    Update
                                </LoadingButton>
                            </Grid>
                        </Div>
                    </Form>
                )}
            </Formik>
        </Div>
    );
};
/* Todo expensetypesItem, onSave prop define */
export default EditForm;
