import React from 'react';

import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useMutation } from 'react-query';
import { accomodationapplicationsServices } from 'app/services/accomodationapplications-services';
import { institutionsServices } from 'app/services/institutions-services';
import { studentServices } from 'app/services/student-services';
import { officeServices } from 'app/services/office-services';
import { coursesServices } from 'app/services/courses-services';
import { countriesServices } from 'app/services/countries-services';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';

import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import axios from 'axios';

import {
    capitalizeAllWords,
    getCurrentDate,
    getNextYearDate
} from 'app/utils/appHelpers';

import _ from 'lodash';

const validationSchema = yup.object({
    applicationCode: yup
        .string('Enter application ID/Code')
        .required('Application ID/Code name is required'),
    studentId: yup.string('Select Student').required('Student is required'),
    institutionId: yup
        .string('Select Institution')
        .required('Institution is required'),
    // courseId: yup.object('Select Course').required('Course is required'),
    officeId: yup.string('Select Office').required('Office is required'),
    // requirements: yup
    //     .string('Provide/Upload Requirements')
    //     .required('Requirements are required'),
    application_fee: yup
        .string('Enter application fee & provide receipt')
        .required('Application fee & provide receipt name is required'),
    application_date: yup
        .string('Enter date of application')
        .required('Application date is required')
    // comment: yup.string('Enter Comment').required('Comment name is required'),
    // acceptance_documents: yup.string('Provide acceptance documents').required('Acceptance documents name are required'),
    // attachments: yup.string('Enter application ID/Code').required('Application ID/Code name is required'),
    // status: yup.string('Enter application ID/Code').required('Application ID/Code name is required'),
});

const CreateForm = ({ onSave, hideDialog, userDetails }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [students, setStudents] = React.useState(false);
    const [countries, setCountries] = React.useState(false);
    const [institutions, setInstitutions] = React.useState(false);
    const [institutionId, setInstitutionId] = React.useState('');
    const [feeCurrency, setFeeCurrency] = React.useState('');

    const [accomodationType, setAccomodationType] = React.useState('');
    const [requirements, setRequirements] = React.useState(false);
    const [officeId, setOfficeId] = React.useState('');
    const [offices, setOffices] = React.useState(false);
    const [courses, setCourses] = React.useState(false);
    const [defaultCourses, setDefaultCourses] = React.useState(false);
    const [designatedOffice, setDesignatedOffice] = React.useState('');
    const [fieldDate, setFieldDate] = React.useState(
        dayjs(getCurrentDate(false))
    );

    const { mutate: addAccomodationapplicationMutation, isLoading } =
        useMutation(
            accomodationapplicationsServices.addAccomodationapplication,
            {
                onSuccess: () => {
                    onSave();
                    enqueueSnackbar(
                        'Application has been saved successfully.',
                        {
                            variant: 'success'
                        }
                    );
                    return true;
                },

                onError: (error) => {
                    enqueueSnackbar(error, {
                        variant: 'error'
                    });

                    return false;
                }
            }
        );

    const onCreate = async (accomodationapplication) => {
        try {
            addAccomodationapplicationMutation({ ...accomodationapplication });
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelStudentToken = axios.CancelToken.source();
        const cStudentToken = { cancelToken: cancelStudentToken.token };
        const fetchStudents = async () => {
            try {
                const res = await studentServices.getAllStudents(cStudentToken);

                setStudents(res.studentsList);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchStudents();

        return () => {
            cancelStudentToken.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelInstToken = axios.CancelToken.source();
        const cInstToken = { cancelToken: cancelInstToken.token };
        const fetchInstitutions = async () => {
            try {
                const res = await institutionsServices.getAllInstitutions(
                    cInstToken
                );

                setInstitutions(res.institutions);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchInstitutions();

        return () => {
            cancelInstToken.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenOffices = axios.CancelToken.source();
        const cTokenOffices = { cancelToken: cancelTokenOffices.token };
        const fetchOffices = async () => {
            try {
                const res = await officeServices.getAllOffices(cTokenOffices);
                let finalOffices = res.offices;

                if (
                    userDetails?.officeIds &&
                    userDetails?.role_name !== 'administrator'
                ) {
                    finalOffices = _.filter(res.offices, (office) =>
                        _.includes(userDetails.officeIds, office.id)
                    );
                }

                setOffices(finalOffices);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchOffices();

        return () => {
            cancelTokenOffices.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelTokenCourses = axios.CancelToken.source();
        const cTokenCourses = {
            cancelToken: cancelTokenCourses.token
        };
        const fetchCourses = async () => {
            try {
                const res = await coursesServices.getAllCourses(cTokenCourses);

                setCourses(res.courses);

                //   const reqs = listItem?.courseId
                //     ? _.filter(res.courses, (obj) =>
                //         _.includes(_.map(listItem.courseId, "id"), obj.id)
                //       )
                //     : [];

                //   setDefaultCourses(reqs);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchCourses();

        return () => {
            cancelTokenCourses.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };
        const fetchCountries = async () => {
            try {
                const res = await countriesServices.getAllCountries(cToken);
                setCountries(res.countries);
            } catch (error) {
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchCountries();

        return () => {
            cancelToken.cancel();
        };
    }, []);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {(!countries ||
                !offices ||
                !courses ||
                !institutions ||
                !students) && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {countries && offices && courses && institutions && students && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        applicationCode: '',
                        accomodationType: '',
                        studentId: '',
                        student_name: '',
                        countryId: '',
                        institutionId: '',
                        // courseId: [],
                        offer_waiting_period: '',
                        officeId: '',
                        requirements: '',
                        application_fee: '',
                        application_fee_currency: feeCurrency,
                        application_date: fieldDate,
                        comment: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        await onCreate(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>{JSON.stringify(errors)}</div>
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <JumboSelectField
                                            width="100%"
                                            size={'small'}
                                            id="officeId"
                                            name="officeId"
                                            label="Select Office"
                                            value={designatedOffice}
                                            placeholder="Select office"
                                            onChange={(event) =>
                                                setDesignatedOffice(
                                                    event.target.value
                                                )
                                            }
                                        >
                                            <MenuItem key={'officeId'} value="">
                                                <em>Select Office</em>
                                            </MenuItem>

                                            {offices.map((office) => (
                                                <MenuItem
                                                    key={office.id}
                                                    value={office.id}
                                                >
                                                    {capitalizeAllWords(
                                                        office.office_name
                                                    )}
                                                </MenuItem>
                                            ))}
                                        </JumboSelectField>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="applicationCode"
                                            name="applicationCode"
                                            label="Application Code/Serial No./ID"
                                            placeholder="Enter application ID/Serial No./Code/Tracking No. here"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field name="studentId">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    id="studentId"
                                                    sx={{
                                                        width: 500,
                                                        maxWidth: '100%'
                                                    }}
                                                    size="small"
                                                    options={students}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.given_name
                                                            ? option.given_name +
                                                              ' ' +
                                                              option.surname +
                                                              ' - ' +
                                                              option.passport_number
                                                                ? option.passport_number
                                                                : option.id_no
                                                            : ''
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                '& > img': {
                                                                    mr: 2,
                                                                    flexShrink: 0
                                                                }
                                                            }}
                                                            {...props}
                                                        >
                                                            {option.given_name +
                                                                ' ' +
                                                                option.surname}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Student"
                                                            placeholder="Choose a student"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.studentId &&
                                                                !!errors.studentId
                                                            }
                                                            helperText={
                                                                touched?.studentId &&
                                                                errors.studentId
                                                            }
                                                        />
                                                    )}
                                                    onChange={
                                                        (event, newValue) => {
                                                            console.log(
                                                                'Student'
                                                            );
                                                            console.log(
                                                                newValue
                                                            );

                                                            values.studentId =
                                                                newValue?.id;

                                                            // field.onChange(
                                                            //     event
                                                            // );

                                                            values.student_name =
                                                                newValue?.given_name +
                                                                ' ' +
                                                                newValue?.surname;
                                                        }
                                                        // Set the new value in the form field
                                                    }
                                                    value={
                                                        students.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.studentId
                                                        ) || ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field name="institutionId">
                                            {({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    id="institutionId"
                                                    sx={{
                                                        width: 500,
                                                        maxWidth: '100%'
                                                    }}
                                                    size="small"
                                                    options={institutions}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                        option?.institution_name
                                                            ? option.institution_name
                                                            : ''
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                '& > img': {
                                                                    mr: 2,
                                                                    flexShrink: 0
                                                                }
                                                            }}
                                                            {...props}
                                                        >
                                                            {
                                                                option.institution_name
                                                            }
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Institution"
                                                            placeholder="Choose a institution"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete:
                                                                    'new-password'
                                                            }}
                                                            error={
                                                                touched?.institutionId &&
                                                                !!errors.institutionId
                                                            }
                                                            helperText={
                                                                touched?.institutionId &&
                                                                errors.institutionId
                                                            }
                                                        />
                                                    )}
                                                    onChange={
                                                        (event, newValue) => {
                                                            values.institutionId =
                                                                newValue?.id;
                                                            values.countryId =
                                                                newValue?.countryId.id;
                                                            values.application_requirements =
                                                                newValue?.application_requirements;
                                                            values.offer_waiting_period =
                                                                newValue?.offer_waiting_period ||
                                                                10;
                                                            values.application_fee =
                                                                newValue?.application_fee ||
                                                                1;
                                                            setFeeCurrency(
                                                                newValue
                                                                    ?.application_fee_currency
                                                                    ?.currency ||
                                                                    'USD'
                                                            );
                                                            setRequirements(
                                                                newValue?.application_requirements
                                                            );
                                                        }
                                                        // Set the new value in the form field
                                                    }
                                                    value={
                                                        institutions.find(
                                                            (option) =>
                                                                option.id ===
                                                                values.institutionId
                                                        ) || ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboSelectField
                                            width="100%"
                                            size={'small'}
                                            id="accomodationType"
                                            name="accomodationType"
                                            label="Accomodation Type"
                                            value={accomodationType}
                                            placeholder="Select Accomodation Type"
                                            onChange={(event) =>
                                                setAccomodationType(
                                                    event.target.value
                                                )
                                            }
                                        >
                                            <MenuItem
                                                key={'accomodationType'}
                                                value=""
                                            >
                                                <em>
                                                    Select Accomodation Type
                                                </em>
                                            </MenuItem>

                                            <MenuItem
                                                key={'In Campus'}
                                                value={'In Campus'}
                                            >
                                                In Campus
                                            </MenuItem>
                                            <MenuItem
                                                key={'Off Campus'}
                                                value={'Off Campus'}
                                            >
                                                Off Campus
                                            </MenuItem>
                                        </JumboSelectField>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="application_fee"
                                            name="application_fee"
                                            label="Application Fee"
                                            placeholder="Enter the application fee here"
                                            readOnly
                                            type={'number'}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {feeCurrency}
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <MobileDatePicker
                                                label="Application Date"
                                                inputFormat="DD/MM/YYYY"
                                                value={fieldDate}
                                                onChange={(newValue) => {
                                                    setFieldDate(newValue);
                                                    values.application_date =
                                                        newValue;
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <JumboTextField
                                                            fullWidth
                                                            size={'small'}
                                                            value={fieldDate}
                                                            name="application_date"
                                                            {...params}
                                                        />
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <JumboTextField
                                            fullWidth
                                            size={'small'}
                                            id="comment"
                                            name="comment"
                                            label="Comment"
                                            placeholder="Enter comment here"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                    >
                                        Save
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo accomodationapplicationItem, onSave prop define */
export default CreateForm;
