import React from 'react';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import Div from '@jumbo/shared/Div';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import { data } from './data';
import EventItem from './EventItem';
import Alert from '@mui/material/Alert';

const Applications = ({ sx, studentDetails }) => {
    const [tab, setTab] = React.useState('1');

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };
    return (
        <JumboCardQuick
            title={'Applications'}
            action={
                <TabContext value={tab}>
                    <Div
                        sx={{
                            marginTop: -2.25,
                            marginBottom: -2.5,

                            '& .MuiTab-root': {
                                py: 2.5
                            }
                        }}
                    >
                        <TabList onChange={handleTabChange} aria-label="">
                            <Tab label="University" value="1" />
                            <Tab label="Visa" value="2" />
                            <Tab label="Accomodations" value="3" />
                        </TabList>
                    </Div>
                </TabContext>
            }
            headerSx={{
                borderBottom: 1,
                borderColor: 'divider'
            }}
            sx={{ mb: 3.75 }}
        >
            <TabContext value={tab}>
                <TabPanel
                    sx={{ maxHeight: '300px', overflowY: 'scroll' }}
                    value="1"
                >
                    {(!studentDetails?.uni_applications ||
                        studentDetails?.uni_applications.length === 0) && (
                        <Alert severity="info">
                            No University/Collage Applications yet
                        </Alert>
                    )}
                    {studentDetails?.uni_applications &&
                        studentDetails?.uni_applications.length > 0 && (
                            <List disablePadding>
                                {studentDetails?.uni_applications?.map(
                                    (item, index) => (
                                        <EventItem item={item} key={index} />
                                    )
                                )}
                            </List>
                        )}
                </TabPanel>
                <TabPanel
                    sx={{ maxHeight: '300px', overflowY: 'scroll' }}
                    value="2"
                >
                    {(!studentDetails?.visa_applications ||
                        studentDetails?.visa_applications.length === 0) && (
                        <Alert severity="info">No Visa Applications yet</Alert>
                    )}
                    {studentDetails?.visa_applications &&
                        studentDetails?.visa_applications.length > 0 && (
                            <List disablePadding>
                                {studentDetails?.visa_applications?.map(
                                    (item, index) => (
                                        <EventItem item={item} key={index} />
                                    )
                                )}
                            </List>
                        )}
                </TabPanel>
                <TabPanel
                    sx={{ maxHeight: '300px', overflowY: 'scroll' }}
                    value="3"
                >
                    {(!studentDetails?.accomodation_applications ||
                        studentDetails?.accomodation_applications.length ===
                            0) && (
                        <Alert severity="info">
                            No Accomodation Applications yet
                        </Alert>
                    )}
                    {studentDetails?.accomodation_applications &&
                        studentDetails?.accomodation_applications.length >
                            0 && (
                            <List disablePadding>
                                {studentDetails?.accomodation_applications?.map(
                                    (item, index) => (
                                        <EventItem item={item} key={index} />
                                    )
                                )}
                            </List>
                        )}
                </TabPanel>
            </TabContext>
        </JumboCardQuick>
    );
};

export default Applications;
