import React, { useEffect } from 'react';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'app/AppAbility';
import {
    ListItemText,
    Tooltip,
    Typography,
    ListItemAvatar,
    Chip
} from '@mui/material';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import Avatar from '@mui/material/Avatar';
import UpdateIcon from '@mui/icons-material/Update';
import Div from '@jumbo/shared/Div';
import styled from '@emotion/styled';
import Span from '@jumbo/shared/Span';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Slide from '@mui/material/Slide';
import Edit from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { leadsServices } from 'app/services/leads-services';
import { useMutation } from 'react-query';
import { capitalizeFirstLetter } from 'app/utils/appHelpers';

import moment from 'moment';
import useLeadsApp from '../../hooks/useLeadsApp';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import EditForm from '../../../../Students/StudentsList/components/EditForm';
import UpdateForm from '../UpdateForm';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import { useSnackbar } from 'notistack';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import _ from 'lodash';
import LeadStatus from 'app/pages/statuses/LeadStatus';
import LeadInfo from '../LeadInfo';

const Item = styled(Span)(({ theme }) => ({
    padding: theme.spacing(0, 1)
}));

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction={'down'} ref={ref} {...props} />;
});

// Prospect: a person in the database who has not interacted with anyone
// Open - Not Contacted: an MQL that needs to be acted on by sales
// Working: sales is attempting to contact, qualify, and create an opportunity
// Meeting Set: sales has set a qualifying meeting
// Opportunity Created: sales created an opportunity
// Customer: an opportunity was closed-won
// Opportunity Lost: an opportunity was closed-lost
// Not a Target: the person does not fit the profile of someone who would buy the product at this time (to be revisited if new product lines/features added)
// Disqualified: the person no longer is with a company or isn't who they claimed to be when they filled out a form
// Nurture: the person is a potential target but not ready to buy at this time
// Inactive Customer: account churned

const ListItem = ({ listItem }) => {
    const { authUser } = useJumboAuth();
    const { theme } = useJumboTheme();
    const { setListRefresh } = useLeadsApp();
    const { showDialog, hideDialog } = useJumboDialog();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const ability = useAbility(AbilityContext);
    const allowRead = ability.can('read', 'Lead');
    const allowEdit = ability.can('update', 'Lead');
    const allowDelete = ability.can('delete', 'Lead');

    const [leadStatus, setLeadStatus] = React.useState({
        id: 0,
        name: 'Inactive',
        slug: 'inactive',
        color: '#ca404f'
    });

    const handleItemClick = React.useCallback(() => {
        // navigate(`/home/students/student/${listItem.studentId.id.toString()}`);
        // return;

        showDialog({
            title: 'Lead Details',
            subheader: (
                <JumboChipsGroup
                    chips={[leadStatus]}
                    mapKeys={{ label: 'name' }}
                    spacing={1}
                    size={'small'}
                    max={1}
                />
            ),
            content: (
                <LeadInfo
                    listItem={listItem}
                    onUpdate={handleUpdatedItem}
                    hideDialog={hideDialog}
                    userDetails={authUser}
                />
            ),
            maxWidth: 'xl',
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true
        });
    }, [leadStatus, listItem]);

    const deleteMutation = useMutation(leadsServices.deleteLead, {
        onSuccess: () => {
            hideDialog();
            setListRefresh(true);
            enqueueSnackbar('Lead has been deleted successfully.', {
                variant: 'success'
            });
        },
        onError: (error) => {
            hideDialog();
            enqueueSnackbar(error, {
                variant: 'error'
            });

            return false;
        }
    });

    const itemMoveToTrash = React.useCallback(
        (leadId) => {
            showDialog({
                variant: 'confirm',
                title: 'Delete!',
                content: 'Are you sure? Select Yes to Continue',
                onYes: () => {
                    deleteMutation.mutate(leadId);
                },
                onNo: hideDialog
            });
        },
        [deleteMutation, hideDialog]
    );

    const handleUpdatedItem = React.useCallback(() => {
        hideDialog();
        setListRefresh(true);
    }, [hideDialog]);

    const handleUpdateForm = () => {
        showDialog({
            title: 'Update Lead Info',
            content: (
                <EditForm
                    listItem={listItem.studentId}
                    onUpdate={handleUpdatedItem}
                    hideDialog={hideDialog}
                    userDetails={authUser}
                />
            ),
            maxWidth: 'xl',
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true
        });
    };

    const leadUpdateHandler = (event) => {
        showDialog({
            title: 'Update Lead',
            content: (
                <UpdateForm
                    listItem={listItem}
                    onUpdate={handleUpdatedItem}
                    hideDialog={hideDialog}
                    userDetails={authUser}
                />
            ),
            maxWidth: 'md',
            TransitionComponent: Transition,
            disableDefaultClose: true,
            closeAction: true
        });
    };

    const profilePic = listItem?.studentId?.profile_pic
        ? process.env.REACT_APP_BACKEND + '/' + listItem?.studentId?.profile_pic
        : null;

    useEffect(() => {
        setLeadStatus(
            _.find(LeadStatus, (status) => status.id === listItem?.status)
        );
    }, [listItem]);

    return (
        <React.Fragment>
            <JumboListItem
                componentElement={'div'}
                itemData={listItem}
                secondaryAction={null}
                sx={{
                    cursor: 'pointer',
                    borderTop: 1,
                    borderColor: 'divider',
                    '&:hover': {
                        bgcolor: 'action.hover',

                        '& .ListAction': {
                            width: { sm: '100%' },
                            opacity: { sm: 1 }
                        },

                        '& .ListTextExtra': {
                            visibility: { sm: 'hidden' },
                            opacity: { sm: 0 }
                        }
                    }
                }}
            >
                <ListItemAvatar onClick={handleItemClick}>
                    <Avatar
                        alt={`${listItem?.given_name} ${listItem?.surname}`}
                        src={profilePic}
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Typography variant={'body1'} component={'div'}>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                sx={{
                                    minWidth: 0
                                    ////background: 'yellow'
                                }}
                            >
                                <Item
                                    onClick={handleItemClick}
                                    sx={{
                                        flexBasis: {
                                            xs: '70%',
                                            sm: '25%',
                                            md: '25%'
                                        },
                                        minWidth: '25%'
                                        ////background: '#eee'
                                    }}
                                >
                                    <Typography
                                        variant={'h5'}
                                        fontSize={14}
                                        lineHeight={1.25}
                                        mb={0}
                                        noWrap
                                    >
                                        {listItem?.role_name === 'hr'
                                            ? listItem?.role_name.toUpperCase()
                                            : capitalizeFirstLetter(
                                                  listItem?.studentId
                                                      ?.given_name +
                                                      ' ' +
                                                      listItem?.studentId
                                                          ?.surname
                                              )}
                                    </Typography>
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        sx={{
                                            display: {
                                                xs: 'block',
                                                sm: 'block'
                                            }
                                        }}
                                    >
                                        {listItem?.studentId
                                            ?.passport_number ? (
                                            listItem?.studentId?.passport_number
                                        ) : listItem?.studentId?.id_no ? (
                                            listItem?.studentId?.id_no
                                        ) : (
                                            <em>No Passport/ID</em>
                                        )}
                                    </Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { sm: '7%', md: '7%' },
                                        display: { xs: 'none', sm: 'block' },
                                        minWidth: '7%'
                                        //background: '#333'
                                    }}
                                >
                                    <Typography variant={'body1'}>
                                        {listItem?.studentId?.gender}
                                    </Typography>
                                </Item>

                                <Item
                                    onClick={handleItemClick}
                                    sx={{
                                        flexBasis: { sm: '24%', md: '24%' },
                                        display: { xs: 'none', sm: 'block' },
                                        minWidth: '20%'
                                        //background: 'green'
                                    }}
                                >
                                    <Typography variant={'body1'} noWrap>
                                        {listItem?.studentId?.phone_number}
                                    </Typography>
                                    <Typography
                                        variant={'body1'}
                                        color={'text.secondary'}
                                        sx={{
                                            display: {
                                                xs: 'block',
                                                sm: 'block'
                                            }
                                        }}
                                    >
                                        {listItem?.studentId?.email_address}
                                    </Typography>
                                </Item>
                                <Item
                                    onClick={handleItemClick}
                                    sx={{
                                        flexBasis: { sm: '23%', md: '23%' },
                                        display: { xs: 'none', sm: 'block' },
                                        minWidth: '27%'
                                        // background: '#333'
                                    }}
                                >
                                    {listItem?.officeId ? (
                                        <JumboChipsGroup
                                            chips={
                                                listItem?.officeId
                                                    ? [
                                                          {
                                                              id: listItem
                                                                  ?.officeId
                                                                  ?.id,
                                                              name: listItem
                                                                  ?.officeId
                                                                  ?.office_name,
                                                              slug: listItem
                                                                  ?.officeId
                                                                  ?.office_name,
                                                              color: '#E0CDFF'
                                                          }
                                                      ]
                                                    : [
                                                          {
                                                              id:
                                                                  Math.random() *
                                                                  10000000,
                                                              name: 'No Office Assigned',
                                                              slug: 'inactive',
                                                              color: '#ca404f'
                                                          }
                                                      ]
                                            }
                                            mapKeys={{ label: 'name' }}
                                            spacing={1}
                                            size={'small'}
                                            max={1}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </Item>
                                <Item
                                    onClick={handleItemClick}
                                    sx={{
                                        flexBasis: { sm: '26%', md: '26%' },
                                        display: { xs: 'none', sm: 'block' },
                                        justifyContent: 'left',
                                        textAlign: 'left'
                                    }}
                                >
                                    {listItem?.studentId?.high_school ? (
                                        <Chip
                                            label={
                                                listItem?.studentId?.high_school
                                            }
                                            color="info"
                                            size="small"
                                        />
                                    ) : (
                                        ''
                                    )}
                                </Item>
                            </Stack>
                        </Typography>
                    }
                />

                <Div
                    sx={{
                        width: 150,
                        display: 'flex',
                        flexShrink: '0',
                        position: 'relative',
                        justifyContent: 'flex-end',
                        // //background: '#333',s

                        [theme.breakpoints.down('sm')]: {
                            width: '100%',
                            justifyContent: 'space-between'
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            flex: 1,
                            transition: 'all 0.5s ease',

                            [theme.breakpoints.down('sm')]: {
                                justifyContent: 'flex-start',
                                ml: 6
                            }
                        }}
                        className={'ListTextExtra'}
                    >
                        <JumboChipsGroup
                            chips={
                                listItem?.status
                                    ? [leadStatus]
                                    : [
                                          {
                                              id: 0,
                                              name: 'Inactive',
                                              slug: 'inactive',
                                              color: '#ca404f'
                                          }
                                      ]
                            }
                            mapKeys={{ label: 'name' }}
                            spacing={1}
                            size={'small'}
                            max={1}
                        />

                        <Typography
                            variant={'body1'}
                            color={'text.secondary'}
                            ml={1}
                        >
                            {moment(listItem?.date_created).format('MMM DD')}
                        </Typography>
                    </Div>
                    <Div
                        className="ListAction"
                        sx={{
                            display: 'flex',

                            [theme.breakpoints.up('sm')]: {
                                position: 'absolute',
                                top: '50%',
                                width: 0,
                                opacity: 0,
                                overflow: 'hidden',
                                transition: 'all 0.5s ease',
                                justifyContent: 'flex-end',
                                transform: 'translateY(-50%)'
                            }
                        }}
                    >
                        <Span sx={{ height: 36, overflow: 'hidden' }}>
                            {allowEdit && listItem?.active && (
                                <Tooltip title="Update Lead">
                                    <IconButton onClick={leadUpdateHandler}>
                                        <CloudSyncIcon fontSize={'small'} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {allowRead && (
                                <Tooltip title="View Details">
                                    <IconButton onClick={handleItemClick}>
                                        <VisibilityIcon fontSize={'small'} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {allowEdit && (
                                <Tooltip title="Edit">
                                    <IconButton onClick={handleUpdateForm}>
                                        <Edit fontSize={'small'} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {/* {allowDelete && ( 
                                // <Tooltip title="Delete">
                                //     <IconButton
                                //         onClick={() =>
                                //             itemMoveToTrash(listItem?.id)
                                //         }
                                //     >
                                //         <DeleteIcon
                                //             fontSize={'small'}
                                //             sx={{ color: '#ca404f' }}
                                //         />
                                //     </IconButton>
                                // </Tooltip>
                            // )}*/}
                        </Span>
                    </Div>
                </Div>
            </JumboListItem>
        </React.Fragment>
    );
};

export default ListItem;
