import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import { ListItem, ListItemIcon, ListItemText, Switch } from '@mui/material';
import WifiIcon from '@mui/icons-material/Wifi';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import MicIcon from '@mui/icons-material/Mic';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import _ from 'lodash';

import {
    capitalizeAllWords,
    capitalizeFirstLetter
} from 'app/utils/appHelpers';

const PermissionsList = ({
    permissions,
    setPermissions,
    checked,
    setChecked,
    disableChange = false,
    ...props
}) => {
    const [checkAll, setCheckAll] = React.useState(false);

    const handleToggle = ({ action, subject, humanName, status }) => {
        if (!disableChange) {
            const currentIndex = _.findIndex(checked, {
                action: action,
                subject: subject
            });
            const newChecked = [...checked];

            if (currentIndex === -1) {
                newChecked.push({ action, subject, humanName, status: true });
            } else {
                newChecked.splice(currentIndex, 1);
            }

            setChecked(newChecked);
        } else {
            setChecked(checked);
        }
    };

    useEffect(() => {
        if (!disableChange) {
            if (checkAll) {
                setChecked(
                    _.map(permissions, (permission) => ({
                        ...permission,
                        status: true
                    }))
                );
            } else {
                setChecked([]);
            }
        }
    }, [checkAll]);

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper'
            }}
            subheader={<></>}
        >
            {!disableChange && (
                <ListItem sx={{ width: '100%', background: '#eee' }}>
                    <ListItemText
                        id={`switch-list-label-check-all`}
                        primary={`Check All`}
                    />
                    <Switch
                        edge="end"
                        onChange={() => setCheckAll(!checkAll)}
                        checked={checkAll}
                        inputProps={{
                            'aria-labelledby': `switch-list-label-check-all`
                        }}
                    />
                </ListItem>
            )}

            {_.map(permissions, ({ action, subject, humanName, status }) => (
                <ListItem key={`${action}-${subject}`}>
                    <ListItemText
                        id={`switch-list-label-${action}-${subject}`}
                        primary={`${
                            action === 'read'
                                ? 'View'
                                : capitalizeFirstLetter(action)
                        } ${
                            humanName && humanName.length
                                ? capitalizeAllWords(humanName)
                                : subject
                        }`}
                    />
                    <Switch
                        edge="end"
                        onChange={() =>
                            handleToggle({
                                action,
                                subject,
                                humanName,
                                status
                            })
                        }
                        checked={
                            _.findIndex(checked, {
                                action: action,
                                subject: subject
                            }) !== -1
                        }
                        inputProps={{
                            'aria-labelledby': `switch-list-label-${action}-${subject}`
                        }}
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default PermissionsList;
