import React, { useState, useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Div from '@jumbo/shared/Div';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Button, Typography, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik'; // Import Formik context
import FreeAutocomplete from 'app/shared/FreeAutocomplete';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import _ from 'lodash';

function DocumentComponent({
    el,
    documentsInputList,
    setDocumentsInputList,
    index,
    feeCurrency,
    handleAddClick,
    handleRemoveClick,
    totalComponents,
    document_id = '',
    document_name = '',
    amount = '',
    document_file = '',
    document_file_link = '',
    label = 'Expense Name',
    field_name = 'expense_type_name',
    options = []
}) {
    const { values, setFieldValue, touched, errors } = useFormikContext(); // Access Formik context
    const [replaceDoc, setReplaceDoc] = useState(el?.dbId ? false : true);

    const { REACT_APP_BACKEND } = process.env;

    const [documentName, setDocumentName] = useState(document_name);
    const [expenseAmount, setExpenseAmount] = useState(amount);
    const [documentFile, setDocumentFile] = useState(document_file);
    const [documentFileLink, setDocumentFileLink] =
        useState(document_file_link);

    useEffect(() => {
        const index = _.findIndex(documentsInputList, { id: el.id });
        if (index !== -1) {
            documentsInputList[index].document_name = documentName
                ? documentName
                : '';

            documentsInputList[index].amount = expenseAmount
                ? expenseAmount
                : '';
            documentsInputList[index].document_file = documentFile
                ? documentFile
                : '';
            documentsInputList[index].document_file_link = documentFile?.name
                ? documentFile?.name
                : '';

            setDocumentsInputList(documentsInputList);
        }
    }, [documentName, expenseAmount, documentFile]);

    const docnameField = `document_name_${el.id}`;
    const expenseAmountField = `expenseAmount_${el.id}`;
    const docfileField = `document_file_${el.id}`;

    return (
        <Div sx={{ flexGrow: 1, mb: '8px' }}>
            <Grid container spacing={1}>
                <Grid item xs={4} md={4}>
                    <FreeAutocomplete
                        fullWidth={true}
                        freeSolo={false}
                        size={'small'}
                        id={`document_name-${el.id}`}
                        name={docnameField}
                        placeholder="Enter document name or description"
                        field_name={field_name}
                        label={label}
                        options={options}
                        onChange={(value) => {
                            setFieldValue(docnameField, value);
                            setDocumentName(value);
                        }}
                    />
                </Grid>
                <Grid item xs={3} md={3}>
                    <TextField
                        className="mb-16"
                        label="Receipt Amount"
                        placeholder="Enter receipt expenseAmount"
                        autoFocus
                        error={!!errors[expenseAmountField]}
                        helperText={errors[expenseAmountField]}
                        id={`document_name-${el.id}`}
                        variant="outlined"
                        fullWidth
                        size="small"
                        type="number"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {feeCurrency}
                                </InputAdornment>
                            ),
                            min: 1
                        }}
                        required
                        onChange={(e) => {
                            setFieldValue(expenseAmountField, e.target.value);
                            setExpenseAmount(e.target.value);
                        }}
                        value={expenseAmount}
                    />
                </Grid>
                <Grid item xs={4} md={4}>
                    <TextField
                        className="mb-16"
                        placeholder="Upload File"
                        error={!!errors[docfileField]}
                        helperText={errors[docfileField]}
                        id={`document_file-${el.id}`}
                        variant="outlined"
                        fullWidth
                        size="small"
                        type="file"
                        accept={'.pdf, .jpg, .jpeg, .png'}
                        onChange={(e) => {
                            setFieldValue(
                                docfileField,
                                e?.target?.files[0] || ''
                            );
                            setDocumentFile(e?.target?.files[0] || '');
                            setDocumentFileLink(
                                e?.target?.files[0]?.name || ''
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={1} md={1}>
                    {totalComponents - 1 !== index && (
                        <Tooltip title="Delete/remove Item">
                            <Button
                                className="tool"
                                type="button"
                                color="error"
                                onClick={() => handleRemoveClick(el.id)}
                            >
                                <ClearIcon />
                            </Button>
                        </Tooltip>
                    )}

                    {totalComponents - 1 === index && (
                        <Tooltip title="Add More">
                            <Button color="success" onClick={handleAddClick}>
                                <AddIcon />
                            </Button>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>
        </Div>
    );
}

export default DocumentComponent;
