import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { useMutation } from 'react-query';
import { employeeServices } from 'app/services/employee-services';
import { performanceServices } from 'app/services/performance-services';
import { roleServices } from 'app/services/role-services';
import { designationServices } from 'app/services/designation-services';
import { officeServices } from 'app/services/office-services';

import { useSnackbar } from 'notistack';
// import { SnackbarProvider } from 'notistack';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import FormikAutocomplete from 'app/shared/FormikAutocomplete';
import axios from 'axios';
import { capitalizeAllWords } from 'app/utils/appHelpers';

const validationSchema = yup.object({
    officeId: yup
        .array('Please select designated offices')
        .of(
            yup.object().shape({
                id: yup.string(),
                office_name: yup.string()
            })
        )
        .min(1, 'Please select atleast one office')
        .required('Please select  atleast one office')
        .typeError('Please select an office from the provided list'),
    employeeId: yup
        .array('Please select employee(s)')
        .of(
            yup.object().shape({
                id: yup.string(),
                employee_name: yup.string()
            })
        )
        .min(1, 'Please select atleast one employee')
        .required('Please select  atleast one employee')
        .typeError('Please select an employee from the provided list'),
    period: yup
        .object()
        .shape({
            id: yup.string(),
            period: yup.string()
        })
        .required('Please select period')
        .typeError('Please select period'),
    year: yup
        .object()
        .shape({
            id: yup.string(),
            year: yup.string()
        })
        .required('Please select year')
        .typeError('Please select year'),
    depositsTarget: yup
        .string('Enter deposits targets')
        .required('Enter deposits targets'),
    enrollmentsTarget: yup
        .string('Enter enrollments targets')
        .required('Enter enrollments targets')
});

const currentYear = new Date().getFullYear(); // Get the current year

const YEARS = _.map(_.range(5), (index) => ({
    id: currentYear + index,
    year: currentYear + index
}));

const PERIODS = [
    { id: 'year', period: 'Yearly' }
    // { id: 'month', period: 'month' },
    // { id: 'quarter', period: 'quarter' }
];

const CreateForm = ({
    item: employeeItem,
    onSave,
    hideDialog,
    userDetails
}) => {
    const [employmentDate, setEmploymentDate] = React.useState(
        dayjs('2023-01-01')
    );
    const { enqueueSnackbar } = useSnackbar();
    const [offices, setOffices] = React.useState(false);
    const [employees, setEmployees] = React.useState(false);
    const [finalEmployees, setFinalEmployees] = React.useState(false);
    const [selectedOffice, setSelectedOffice] = React.useState([]);
    const [periods, setPeriods] = React.useState(PERIODS);
    const [selectedPeriod, setSelectedPeriod] = React.useState(false);
    const [years, setYears] = React.useState(YEARS);
    const [selectedYear, setSelectedYear] = React.useState(
        new Date().getFullYear()
    );

    const { mutate: addKPIsTargetsMutation, isLoading } = useMutation(
        performanceServices.addKPIsTargets,
        {
            onSuccess: () => {
                onSave();
                enqueueSnackbar('KPIs have been created successfully.', {
                    variant: 'success'
                });
                return true;
            },

            onError: (error) => {
                enqueueSnackbar(error, {
                    variant: 'error'
                });

                return false;
            }
        }
    );

    const onCreate = async (data) => {
        if (data.depositsTarget === 0 && data.enrollmentsTarget === 0) {
            enqueueSnackbar('All KPI targets cannot be zeros', {
                variant: 'warning'
            });
            return;
        }

        try {
            addKPIsTargetsMutation({
                ...data,
                period: data?.period?.id,
                year: data?.year?.id
            });
        } catch (error) {}
    };

    React.useEffect(() => {
        const cancelTokenOffices = axios.CancelToken.source();
        const cTokenOffices = { cancelToken: cancelTokenOffices.token };
        const fetchOffices = async () => {
            try {
                const res = await officeServices.getAllOffices(cTokenOffices);

                let finalOffices = res.offices;

                if (
                    userDetails?.officeIds &&
                    userDetails?.role_name !== 'administrator' &&
                    userDetails?.role_name !== 'director'
                ) {
                    finalOffices = _.filter(res.offices, (office) =>
                        _.includes(userDetails.officeIds, office.id)
                    );
                }

                setOffices(
                    _.map(finalOffices, (office) => ({
                        id: office.id,
                        office_name: office.office_name
                    }))
                );
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchOffices();

        return () => {
            cancelTokenOffices.cancel();
        };
    }, []);

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cToken = { cancelToken: cancelToken.token };
        const fetchEmployees = async () => {
            try {
                const res = await employeeServices.getAllEmployees(cToken);

                const finalOptions = _.map(res.employeesList, (employee) => {
                    const {
                        id,
                        user_id,
                        first_name,
                        last_name,
                        email_address,
                        phone_number,
                        primaryOfficeId
                    } = employee;

                    return {
                        id,
                        employee_name: `ID # ${
                            user_id ? user_id : ''
                        } - ${first_name} ${last_name}`,
                        email_address,
                        phone_number,
                        primaryOfficeId: primaryOfficeId?.id
                    };
                });

                setEmployees([
                    { id: 'all', employee_name: 'All Employees' },
                    ...finalOptions
                ]);
                setFinalEmployees([
                    { id: 'all', employee_name: 'All Employees' },
                    ...finalOptions
                ]);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        };

        fetchEmployees();

        return () => {
            cancelToken.cancel();
        };
    }, []);

    React.useEffect(() => {
        const matchingOffices = _.map(selectedOffice, (office) => office?.id);

        if (selectedOffice && selectedOffice.length > 0) {
            setFinalEmployees(
                _.filter(
                    employees,
                    (employee) =>
                        matchingOffices.includes(employee?.primaryOfficeId) ||
                        employee?.id === 'all'
                )
            );
        } else {
            setFinalEmployees(employees);
        }
    }, [selectedOffice]);

    return (
        <Div
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}
        >
            {(!employees || !offices) && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            {employees && offices && (
                <Formik
                    validateOnChange={true}
                    initialValues={{
                        officeId: [],
                        employeeId: [],
                        period: null,
                        year: { id: selectedYear, year: selectedYear },
                        depositsTarget: 0,
                        enrollmentsTarget: 0
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                        console.log('On Submit');
                        await onCreate(data);
                    }}
                >
                    {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        setFieldValue,
                        ...restProps
                    }) => (
                        <Form
                            style={{ textAlign: 'left' }}
                            noValidate
                            autoComplete="off"
                        >
                            {/* <Div>{JSON.stringify(errors)}</Div> */}
                            {/* <Div>{JSON.stringify(values)}</Div> */}
                            <Div sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <FormikAutocomplete
                                            multiple={true}
                                            fullWidth={true}
                                            freeSolo={false}
                                            size={'small'}
                                            id={`officeId`}
                                            name={'officeId'}
                                            placeholder="Select office(s)"
                                            field_name={'office_name'}
                                            label={'Select office(s'}
                                            options={offices || []}
                                            onChange={(value) => {
                                                console.log('SetFieldValue');
                                                setFieldValue('employeeId', []);
                                                setSelectedOffice(value);
                                                // values.employeeId = [];
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <FormikAutocomplete
                                            multiple={true}
                                            fullWidth={true}
                                            freeSolo={false}
                                            size={'small'}
                                            value={values.employeeId}
                                            id={`employeeId`}
                                            name={'employeeId'}
                                            placeholder="Select Employee(s)"
                                            field_name={'employee_name'}
                                            label={'Select Employee(s'}
                                            options={finalEmployees || []}
                                            onChange={(value) => {
                                                const all = _.find(
                                                    value,
                                                    (emp) => emp?.id === 'all'
                                                );

                                                if (all) {
                                                    setFieldValue(
                                                        'employeeId',
                                                        [employees[0]]
                                                    );
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <FormikAutocomplete
                                            fullWidth={true}
                                            freeSolo={false}
                                            size={'small'}
                                            id={`period`}
                                            name={'period'}
                                            placeholder="Select Period"
                                            field_name={'period'}
                                            label={'Select Period'}
                                            options={periods || []}
                                            onChange={(value) => {
                                                setSelectedPeriod(value);
                                            }}
                                        />
                                    </Grid>
                                    {selectedPeriod?.id === 'year' && (
                                        <Grid item xs={12} md={12}>
                                            <FormikAutocomplete
                                                fullWidth={true}
                                                freeSolo={false}
                                                size={'small'}
                                                id={`year`}
                                                name={'year'}
                                                placeholder="Select Year"
                                                field_name={'year'}
                                                label={'Select Year'}
                                                options={years || []}
                                            />
                                        </Grid>
                                    )}
                                    {selectedPeriod && (
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <JumboTextField
                                                    fullWidth
                                                    size={'small'}
                                                    id="depositsTarget"
                                                    name="depositsTarget"
                                                    type="number"
                                                    label="Deposits Target"
                                                    placeholder="Deposits Target"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <JumboTextField
                                                    fullWidth
                                                    size={'small'}
                                                    id="enrollmentsTarget"
                                                    name="enrollmentsTarget"
                                                    type="number"
                                                    label="Enrollments Target"
                                                    placeholder="Enrollments Target"
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                                <Grid
                                    sx={{
                                        margin: '30px auto'
                                    }}
                                    container
                                    alignItems="right"
                                    justify="flex-end"
                                    justifyContent="right"
                                >
                                    <Button
                                        size="large"
                                        sx={{ mb: 3 }}
                                        onClick={() => hideDialog()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                    >
                                        Submit
                                    </LoadingButton>
                                </Grid>
                            </Div>
                        </Form>
                    )}
                </Formik>
            )}
        </Div>
    );
};
/* Todo employeeItem, onSave prop define */
export default CreateForm;
